import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { Plus } from "react-feather";
import {
  DISPLAY_LOCATION_EVENT_FORM,
  DISPLAY_LOCATION_EXPANDED_EVENT,
  type TagsProps,
} from "../tagsVariables";
import TagsSelector from "../tagsSelector";
import TagOption from "../tagOption";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { hasReachedTagsLimit } from "../../../lib/tagsFunctions";
import { EVENT_FORM_ID } from "../../../services/elementIDVariables";

export type TagsPillProps = {
  displayLocation:
    | typeof DISPLAY_LOCATION_EXPANDED_EVENT
    | typeof DISPLAY_LOCATION_EVENT_FORM;
  isReadOnly?: boolean;
} & Omit<TagsProps, "displayLocation">;

const TagsPill: React.FC<TagsPillProps> = (props) => {
  const {
    displayLocation,
    tags,
    selectorDisplayIndex,
    setSelectorDisplayIndex,
    isReadOnly = false,
  } = props;

  const getWindowWidth = () => {
    const { innerWidth: width } = window;
    return width;
  };

  const isDarkMode: boolean = useSelector((state: any) => state.isDarkMode);
  const [containerWidth, setContainerWidth] = useState<number | null>(null);
  const [windowWidth, setWindowWidth] = useState<number>(getWindowWidth());
  const tagsRef = useRef<Array<HTMLDivElement | null>>([]);

  const renderSelector = () => {
    const expandedEventWrapper = document.getElementById("event-expanded");
    const popupExpandedEventWrapper = document.getElementById(
      "POP_UP_CONTAINER_ID"
    );
    const eventFormWrapper = document.getElementById(EVENT_FORM_ID);
    let refBounds;
    /* Return if any of the following is true */
    /* Display location not matching */
    /* No expanded event wrapper */
    /* selected index isn't a number */
    /* Missing container width */
    if (
      displayLocation !== selectorDisplayIndex.displayLocation ||
      (displayLocation === DISPLAY_LOCATION_EXPANDED_EVENT &&
        !expandedEventWrapper &&
        !popupExpandedEventWrapper) ||
      (displayLocation === DISPLAY_LOCATION_EVENT_FORM && !eventFormWrapper) ||
      typeof selectorDisplayIndex.index !== "number" ||
      !containerWidth
    ) {
      return;
    }

    /* selected index doesn't exist on tagsRef or not able to get bounds */
    const convertAddButtonIndex = selectorDisplayIndex.index;
    refBounds = tagsRef.current[convertAddButtonIndex]?.getBoundingClientRect();
    if (!refBounds) {
      return;
    }

    /* Calculation explained via comments */
    let adjustedRenderLeft;
    let adjustedRenderTop;
    const FORM_X_OFFSET = 28;
    const renderLeft = containerWidth - (windowWidth - refBounds.x);
    const renderTop = refBounds.top + 40;
    const SELECTOR_CONTAINER_WIDTH = 160;

    /* Default position */
    if (
      displayLocation === DISPLAY_LOCATION_EVENT_FORM ||
      (displayLocation === DISPLAY_LOCATION_EXPANDED_EVENT &&
        !!expandedEventWrapper)
    ) {
      /* Move to the right if it's going off screen */
      /* refBounds.left + SELECTOR_CONTAINER_WIDTH = left side of the buttom + width of the popup container */
      if (refBounds.left + SELECTOR_CONTAINER_WIDTH > windowWidth) {
        /* windowWidth - refBounds.right = remaining space on screen */
        /* remaining space on screen - SELECTOR_CONTAINER_WIDTH */
        /* If positive: how much space is left on screen once more */
        /* If negative: the amount that is going off screen -> This should always be negative if we enter the if statement  so we invert */
        const distanceOffScren = -(
          windowWidth -
          refBounds.right -
          SELECTOR_CONTAINER_WIDTH
        );

        /* Container width is the width of the side bar container */
        /* Since we are pushing left, we need to subtract from the containerWidth */
        /* Subtracting the first (windowWidth - refBounds.right) moves the start of our box to the right of the tag. */
        /* Subtracting the distanceOffScreen will now push the popup to fit perfectly into the container */
        /* Subtracting the second (windowWidth - refBounds.right) moves the box so that the right side of the box aligns with the right side of the tag */
        adjustedRenderLeft =
          containerWidth -
          2 * (windowWidth - refBounds.right) -
          distanceOffScren;

        if (displayLocation === DISPLAY_LOCATION_EVENT_FORM) {
          adjustedRenderLeft += FORM_X_OFFSET + 4;
        }
      }

      if (
        displayLocation === DISPLAY_LOCATION_EVENT_FORM &&
        !adjustedRenderLeft
      ) {
        adjustedRenderLeft = renderLeft + FORM_X_OFFSET;
      }
    } else {
      /* This is relative to the popup container, so we don't need to get exact X and Y */
      const popupRefBounds = popupExpandedEventWrapper!.getBoundingClientRect();
      adjustedRenderLeft = refBounds.x - popupRefBounds.x;
      adjustedRenderTop = refBounds.y - popupRefBounds.y + 40;
    }

    let portalLocation: HTMLElement | null = null;
    if (displayLocation === DISPLAY_LOCATION_EVENT_FORM) {
      portalLocation = eventFormWrapper;
    }
    if (displayLocation === DISPLAY_LOCATION_EXPANDED_EVENT) {
      portalLocation = expandedEventWrapper
        ? expandedEventWrapper
        : popupExpandedEventWrapper;
    }

    return !!portalLocation
      ? createPortal(
          <div
            className={classNames(
              isDarkMode ? "dark-mode" : "",
              `paint-colors-container ${displayLocation}`
            )}
          >
            <TagsSelector
              {...props}
              style={{
                left: adjustedRenderLeft ? adjustedRenderLeft : renderLeft,
                top: adjustedRenderTop ? adjustedRenderTop : renderTop,
              }}
            />
          </div>,
          portalLocation
        )
      : null;
  };

  /* Handles width for panel and page */
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(getWindowWidth());
    };

    const container =
      displayLocation === DISPLAY_LOCATION_EVENT_FORM
        ? document.getElementById("email-and-color-section")
        : document.getElementById("event-panel-wrapper");
    if (
      container &&
      container.offsetWidth &&
      container.offsetWidth !== containerWidth
    ) {
      setContainerWidth(container.offsetWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [containerWidth, displayLocation]);

  /* Set current ref when paint colors size changes */
  useEffect(() => {
    tagsRef.current = tagsRef.current.slice(0, tags.length + 1);
  }, [tags]);

  return (
    <div
      className={classNames(
        "flex flex-wrap items-center paint-colors-menu relative",
        displayLocation ?? "",
        containerWidth && displayLocation === DISPLAY_LOCATION_EXPANDED_EVENT
          ? "width-300px-important"
          : ""
      )}
      // style={containerWidth && displayLocation === DISPLAY_LOCATION_EXPANDED_EVENT ? { width: `calc(${containerWidth}px - 2.5rem)` } : {}}
      key={`paint-color-sapdas[das]-${selectorDisplayIndex}`}
    >
      {tags
        .concat([
          {
            color: "",
            color_id: "",
            name: "",
            user_smart_tag_id: "",
          },
        ])
        .map((p_c: Tag, idx: number) => {
          const isLastIndex = idx === tags.length;
          if (isReadOnly && isLastIndex) {
            // this is the add tags button
            // if read only -> do not show
            return null;
          }
          return (
            <div
              className="relative paint-colors-labels-container"
              key={`paint-colors-${displayLocation}-${p_c.user_smart_tag_id}`}
              ref={(element) => (tagsRef.current[idx] = element)}
            >
              <div
                className="flex paint-colors-label-container"
                onClick={(e) => {
                  if (isReadOnly) {
                    return;
                  }
                  setSelectorDisplayIndex({ displayLocation, index: idx });
                }}
              >
                {/* Add tag button */}
                {isLastIndex ? (
                  <>
                    <div className="flex items-center mr-1">
                      <Plus size={12} />
                    </div>
                    <div className="paint-colors-label select-none">
                      {hasReachedTagsLimit(tags) ? "Edit Tags" : "Add Tag"}
                    </div>
                  </>
                ) : (
                  <TagOption
                    displayLocation={displayLocation}
                    eventTags={tags}
                    tag={p_c}
                    shouldRenderCheckbox={false}
                  />
                )}
              </div>
            </div>
          );
        })}
      {renderSelector()}
    </div>
  );
};

export default TagsPill;
