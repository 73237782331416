import React, { Component } from "react";
import { Calendar } from "rbc-fork-react-big-calendar";
import overlap from "rbc-fork-react-big-calendar/lib/utils/layout-algorithms/overlap";
import { withRouter } from "react-router-dom";
import { connect, batch } from "react-redux";
import { constructRequestURL } from "../services/api";
import withDragAndDrop from "rbc-fork-react-big-calendar/lib/addons/dragAndDrop";
import { Plus } from "react-feather";
import MainCalendarToolbar from "./mainCalendarToolBar";
import CustomDayHeader from "./customDayHeader";
import EventModalPopup from "./eventModalPopup";
import SendEmailUpdateModal from "./sendEmailUpdateModal";
import CustomButton from "./customButton";
import Broadcast from "../broadcasts/broadcast";
import db from "../services/db";
import CustomWeek from "./customWeekView";
import {
  determineEventBackgroundColor,
  convertDateIntoEpochUnixWeek,
  getFirstDayOfWeekJsDate,
  getLastDayOfWeekJsDate,
  updateEventsTime,
  DoesEventHaveOtherAttendees,
  createAbbreviationForTimeZone,
  getEmailBasedOnCalendarId,
  doesEventHaveModifyPermissionAndIsNotAnOrangizer,
  hasArrayChangedSize,
  constructQueryParams,
  fetchEvent,
  removeGoogleAutoGeneratedKeys,
  filterOutModalFromArray,
  hasStateOrPropsChanged,
  determineScrollToHour,
  handleError,
  updateToStartOfNextDayIfLastSlot,
  createAmPmBarWithHourDiff,
  determineRBCEventEndWithEventStart,
  getFirstDayOfMonthlyCalendarJSDate,
  getLastDayOfMonthlyCalendarJSDate,
  FormatIntoJSDate,
  getGoogleEventId,
  addAbbrevationToTimeZone,
  darkenColor,
  isEventInThePast,
  getRRuleStringFromRecurrence,
  lightenColor,
  getTimeInAnchorTimeZone,
  convertToTimeZone,
  determineSyncWindow,
  RoundToClosestMinuteJSDate,
  isBeforeMinute,
  isSameOrAfterDay,
  isSameOrBeforeDay,
  convertEmailToName,
  isMac,
  formatTimeForBackendJsDate,
  isAfterMinute,
  getNextUpcomingEvent,
  removeDuplicatesFromArray,
  isValidTimeZone,
  isValidJSDate,
  getDiffEvents,
  doesWindowLocationIncludeString,
  hasStopEventPropagation,
  getDeviceTimeZone,
  resetGuessTimeZone,
  constructEmailData,
  getLastElementOfArray,
  isEditable,
  omitNullOrUndefinedProps,
  getCurrentTimeInCurrentTimeZone,
  dimDarkModeColor,
  createFadedColorIfIndexDoesNotExist,
  isUserOnlyPersonWhoAcceptedAndRestDeclined,
  guessTimeZone,
  getEventsEtag,
} from "../services/commonUsefulFunctions";
import GoogleCalendarService, {
  TRANSPARENCY_RESOURCE,
  COLOR_ID_RESOURCE,
  ATTENDEE_EVENT_NEEDS_ACTION,
  DEFAULT_GOOGLE_DO_NOT_SEND_UPDATE,
  GOOGLE_UPDATES,
  ATTENDEE_EVENT_TENTATIVE,
} from "../services/googleCalendarService";
import {
  createWindow,
  determineRBCLocalizer,
  doTemporaryTimeZonesExist,
  getRecentTimeZoneGroups,
  createTimeZoneGroupLabel,
  determineShadedCalendarHours,
  createAmPmBarWithTemporaryEvents,
  getMostLeftHandTimeZone,
  shouldUpdateOrderedTimeZonesFromBackend,
} from "../lib/stateManagementFunctions";
import StyleConstants, {
  BACKEND_MONTH,
  CAN_NOT_UPDATE_EVENT_MESSAGE,
  BLUE_BUTTON,
  WHITE_BUTTON,
  SLOTS_AVAILABILITY_SELECTION,
  FADED_GREY_TEXT_COLOR_DARK_MODE,
  DARK_MODE_BACKGROUND_COLOR,
  MEDIUM_GRAY,
  BACKEND_DAY_VIEW,
  BACKEND_4_DAY_VIEW,
  ROLLING_SEVEN_DAY,
  BACKEND_WEEK,
  IS_EDITABLE,
  EVENT_CALENDAR_EMAIL,
  SELF_ATTENDING_STATUS,
  ONLY_PERSON_ATTENDING_REST_DECLINED,
  FADED_COLOR,
  NORMAL_COLOR,
  DARK_MODE_FADED_COLOR,
  GENERIC_ERROR_MESSAGE,
  AVAILABILITY_GROUP_VOTE_CONTAINER_ID,
  SET_DISAPPEARING_NOTIFICATION_MESSAGE,
  MERGED_EVENTS,
  ISO_DATE_FORMAT,
  SET_GLOBAL_SHORT_CUT_SUGGESTION,
  ARROW_UP,
  ARROW_LEFT,
  ADD_SLOT,
  UPDATE_SLOT,
  BOOKING_LINK,
  BACKEND_PERSONAL_LINK,
  BACKEND_GROUP_VOTE_LINK,
  SLOTS_BACKGROUND_COLOR,
  NEEDS_ACTION_STATUS,
  DEFAULT_FONT_COLOR,
  SECOND_IN_MS,
  RSVP_WITHOUT_SHOW_AS,
  ACTION_MODE,
  FADED_WHITE_MODE_COLOR_FOR_DIMMED_PAST_EVENTS,
} from "../services/globalVariables";
import CustomEvent from "./customEvent";
import TimeGutterHeader from "./timeGutterHeader";
import TimeSlotWrapper from "./timeSlotWrapper";
import ShortcutHoverHint from "./shortcutHoverHint";
import GlobalKeyMapTile from "./globalKeyMapTile";
import ReferToVimcal from "./referToVimcal";
import _ from "underscore";
import WarningUpdateRecurringEvent from "./warningUpdateRecurringEvent";
import AvailabilityIcon from "./availability-icon-svg";
import CustomMonthEvent from "./customMonthEvent";
import {
  isSameDay,
  setHours,
  formatISO,
  startOfHour,
  startOfDay,
  startOfMinute,
  parseISO,
  set,
  isSameWeek,
  subWeeks,
  addWeeks,
  addMinutes,
  isSameMinute,
  differenceInDays,
  differenceInMinutes,
  addDays,
  isSameMonth,
  differenceInCalendarDays,
  subDays,
  format,
  getISODay,
  endOfDay,
} from "date-fns";
import CustomMonthlyDateHeader from "./customMonthlyDateHeader";
import CustomDayOfWeekMonthlyHeader from "./customDayOfWeekMonthlyHeader";
import {
  getOriginalRecurringEventFromIndex,
  updatedRecurrenceAfterEventStartChange,
  trimOriginalRecurrenceRule,
  isEventFirstRecurringInstance,
  resetOriginalRecurringEventIndex,
  createRecurrenceCutOffDate,
} from "../lib/recurringEventFunctions";
import { mergeSameEvents } from "../services/mergeEventFunctions";
import {
  isMergedEvent,
  isTextColorTooBrightAgainstWhiteBackground,
  shouldShowReducedHeightTransparentMergedEvent,
  shouldShowTransparentMergedBackground,
  createStaticInformation,
  getEventStaticInfo,
  determineMergedEventBackground,
  clearEventStaticCache,
  getAllUserCalendarIDsFromEventsList,
  isTemporaryEvent,
  isAvailabilityEvent,
  TYPE_FOCUS_BLOCK,
  isEventTypeFocusBlock,
  isOutOfOfficeEvent,
  isOutlookNonOrganizer,
  isOutlookEvent,
  getEventStartValue,
  getEventEndValue,
  shouldDisplayEvent,
  getEventAttendeeDomains,
  isBusyEvent,
  getDefaultStartDateTime,
  getDefaultEndDateTime,
  isEventWithinExactTimeRange,
  isWorkplaceEvent,
  isAllDayWorkLocationEvent,
  getEventUniqueKey,
  isDeclinedEvent,
  isAllDayEvent,
  isPreviewOutlookEvent,
  isGoogleEvent,
  isCancelledEvent,
  createCurrentEventUpdatedAtTime,
  isColorTooDarkAgainstDarkBackground,
  getFontColorForBackgroundColor,
  isShowAsBirthdayEvent,
  hasEventBeenUpdated,
  isEventHiddenEvent,
} from "../lib/eventFunctions";
import classNames from "classnames";
import { filterOutInvalidTimeZones, isExpandedMultiDayEvent } from "../lib/timeFunctions";
import BackendBroadcasts from "../broadcasts/backendBroadcasts";
import {
  createCurrentWeekEventHotKeys,
  updateListOfEvents,
  formatEventsList,
  moveEventsWithArrowKey,
} from "../lib/webWorkerFunctions";
import mainCalendarBroadcast from "../broadcasts/mainCalendarBroadcast";
import {
  getDragStartAndEnd,
  isEventSlotAllDayEvent,
  isInsideRBCSlotRange,
  isOnClickSlot,
  isOnSelectSlot,
  isSelectedSlotAllDayEvent,
  protectMidnightCarryOver,
} from "../lib/rbcFunctions";
import { useDistroListDictionary, useEventHotKeysIndex, useOOOBusyEventsDictionaryStore } from "../services/stores/eventsData";
import {
  useAllCalendars,
  useAllLoggedInUsers,
  useMasterAccount,
} from "../services/stores/SharedAccountData";
import {
  getActiveCalendarsIDsFromAllCalendars,
  determineCalendarColor,
  getCurrentUserDefaultColor,
  getUserCalendarIDFromEmail,
  getEmailFromUserCalendarID,
  getUserCalendar,
  getUserEmailFromEvent,
  getMatchingUserFromEvent,
  getCalendarFromUserCalendarID,
  getFirstMatchingEditableCalendarFromEmail,
  isCalendarSelected,
  filterForAllWritableCalendars,
  findBestGuessWritableCalendar,
  getCalendarUserEmail,
  getAllPrimaryCalendarsFromAllCalendars,
  getActiveSelectedCalendars,
  getEmailToCalendarOrderIndex,
  doesCalendarHaveEditableRole,
  isResourceCalendarEmail,
  orderMainCalendarEvents,
} from "../lib/calendarFunctions";
import EnrichedContactsContainer from "../components/enrichedContactsContainer";
import {
  getClientEventID,
  getEventAttendees,
  getEventColorID,
  getEventCreator, getEventEnd,
  getEventICalUID,
  getEventID,
  getEventMasterEventID,
  getEventOrganizer,
  getEventRawData,
  getEventRecurrence,
  getEventStart,
  getEventStatus,
  getEventTitle,
  getEventUITitle,
  getEventUpdatedAt,
  getEventUserCalendarID,
  getEventUserEmail,
  getEventUserEventID,
  getGCalEventId,
} from "../services/eventResourceAccessors";
import { getCalendarColorHex, getCalendarEmail, getCalendarIsPrimary, getCalendarUserCalendarID } from "../services/calendarAccessors";
import { isTextTemplate } from "../services/templateFunctions";
import { FEATURE_TRACKING_ACTIONS, trackEvent, trackFeatureUsage, trackUserInfo, USER_INFO_ACTIONS } from "./tracking";
import { useAppTimeZones, useHideRightHandSidebar } from "../services/stores/appFunctionality";
import {
  blurCalendar,
  getEventClientRect,
  getOOOBusyColumnRanking,
  getPopUpEvent,
  getPreviewEvent,
  isActionModeCreateAvailability,
  isActionModeUpsertEvent,
  isAppInTaskMode,
  isAvailabilityPanelShowing,
  isGroupVoteDetailPageOpen,
  isInActionMode,
  isInExpandedViewState,
  isInMonthlyView,
  isPersonalLinkPanelOpen,
  isReUseSlotsButtonShowing,
  isSelectColorPopup,
  shouldHideRightHandSide,
  shouldTruncateRightHandPanel,
  triggerRefreshWithOnlineCheck,
} from "../services/appFunctions";
import { isVersionV2 } from "../services/versionFunctions";
import availabilityBroadcast from "../broadcasts/availabilityBroadcast";
import { useAvailabilityStore } from "../services/stores/availabilityStores";
import { createTemporaryEvent, getFreeTimesGivenBusySlots, isGroupVoteEvent, isOutstandingSlotEvent, isSameSlot, isTemporaryAIEvent, splitSlotIntoDuration } from "../lib/availabilityFunctions";
import ContactsContainer from "./contact/contactsContainer";
import contactBroadcast from "../broadcasts/contactBroadcast";
import { getMatchingUserFromAllUsers, getUserAccountUpdatedAtTime, getUserEmail, getUserToken } from "../lib/userFunctions";
import { isFakeMimicEvent } from "../lib/mimicEventUpdate";
import { APP_SETTINGS } from "../lib/vimcalVariables";
import layoutBroadcast from "../broadcasts/layoutBroadcast";
import { getMatchingUIUserForEvent, shouldUseTagColorForGoogleEvent } from "../lib/tagsFunctions";
import eventFormBroadcast from "../broadcasts/eventFormBroadcast";
import { useTemporaryStateStore } from "../services/stores/temporaryStateStores";
import backendBroadcasts from "../broadcasts/backendBroadcasts";
import { getEventTagColor } from "../lib/tagsFunctions";
import tagsBroadcast from "../broadcasts/tagsBroadcast";
import { isInternalTeamUser } from "../lib/featureFlagFunctions";
import { useAppSettings } from "../services/stores/settings";
import { sanitizeStringAndLinkify } from "../lib/jsVariables";
import { getDefaultGroupVoteClickDuration, getGroupVoteDurationOnDrag, getLastSelectedDate } from "../lib/localData";
import MainCalendarEventsReaderHelper from "./availability/mainCalendarEventsReaderHelper";
import { DEXIE_EVENT_COLUMNS, isDBEventItemWithinWindow } from "../lib/dbFunctions";
import { getSelectedDayWithBackup } from "../lib/syncFunctions";
import { ONBOARDING_GLOW_TYPE } from "./onboarding/sharedFunctions";
import { TEMPORARY_EVENT_TYPES, createEventFormFindTimeTemporaryEvent, createEventFormTemporaryEvent, doesArrayContainFindTimeEvent, isEventFormTemporaryEvent, isFindTimeEventFormEvent, isFlashingEvent } from "../lib/temporaryEventFunctions";
import { addDefaultToArray, arraysAreEqual, getLastArrayElement, hasMultipleOccurrencesOfStringInArray, immutablySortArray, isStringArraysEqual, mergeInPreviewOutlookEvents, removeMatchingPreviewEvents, stringArrayContainsIgnoringCase } from "../lib/arrayFunctions";
import ReverseSlotsFilterReader from "./availability/reverseSlotsFilterReader";
import { shouldUseTagColorAsEventColorForOutlookEvent } from "../lib/outlookFunctions";
import { doesArrayContainMeetWithEvents, isMeetWithEvent } from "../lib/meetWithFunctions";
import groupVoteBroadcast from "../broadcasts/groupVoteBroadcast";
import { MAIN_CALENDAR_ID } from "../services/elementIDVariables";
import { AVAILABILITY_BROADCAST_VALUES, BACKEND_BROADCAST_VALUES, BROADCAST_VALUES, EVENT_FORM_BROADCAST_VALUES, LAYOUT_BROADCAST_VALUES, MAIN_CALENDAR_BROADCAST_VALUES } from "../lib/broadcastValues";
import { getDefaultBackgroundColor, getDefaultMonthlyCalendarOutOfRangeColor, getTentativeBackgroundStyle, shouldShowTransparentEventBackgroundInMonthlyView } from "../lib/styleFunctions";
import { useOutlookCategoriesStore } from "../services/stores/outlookCategoriesStore";
import { recurringEventSingleResourceUpdate } from "./mainCalendar/recurringEvents";
import { getDefaultHeaders } from "../lib/fetchFunctions";
import { TEST_PREVIEW_OUTLOOK_LOCATION, isTestingPreviewOutlook } from "../services/testFunctions";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey, isNullOrUndefined, isTypeString } from "../services/typeGuards";
import { getAccountHideWeekend, getAccountRawCalendarView, getDefaultMeetingLength, getDefaultUserTimeZone, getMasterAccountShouldHideAllDayBusyColumn, getMasterAccountShouldHideOOOColumn, getUserAccountOrderedTimeZone, getWorkHours, shouldDimPastEvents, shouldHideWorkingLocationEvents, shouldMergeEvents, shouldUseTimeZoneOverride } from "../lib/settingsFunctions";
import GenericErrorModalContent from "./genericErrorModalContent";
import { isGreatThanUpdatedAt, isSameEmail } from "../lib/stringFunctions";
import { createUUID } from "../services/randomFunctions";
import { getEventHoldID } from "../services/holdFunctions";
import { getHoldDetailsID } from "../services/maestro/maestroAccessors";
import { useUserTimeZoneIndexStore } from "../services/stores/userData";
import { getDateTimeFormat } from "../lib/dateFunctions";
import { determineDefaultModalStyle } from "../lib/modalFunctions";
import { clearEventStyleCache, getEventStyleCache, removePropertiesFromEventStyleCache, addEventStyleToCache } from "../lib/stylesCache";
import { getAllExecutives, isUserMaestroUser, shouldHideDelegatedUser } from "../services/maestroFunctions";
import MainCalendarSplitViewHeader from "./mainCalendar/mainCalendarSplitViewHeader";
import { OUTLOOK_OOO_BACKGROUND_COLOR } from "../services/outlookColors";
import broadcast from "../broadcasts/broadcast";

const CALENDAR_COMPONENTS = {
  toolbar: MainCalendarToolbar,
  event: CustomEvent,
  day: {
    header: CustomDayHeader,
  },
  week: {
    header: CustomDayHeader,
  },
  work_week: {
    header: CustomDayHeader,
  },
  month: {
    event: CustomMonthEvent,
    header: CustomDayOfWeekMonthlyHeader,
    dateHeader: CustomMonthlyDateHeader,
  },
  timeSlotWrapper: TimeSlotWrapper,
  timeGutterHeader: TimeGutterHeader,
};

const UPDATE_RECURRING_EVENT_MODAL = "warningUpdateRecurringEvent";
const UPDATE_RECURRING_TRANSPARENCY_MODAL =
  "UPDATE_RECURRING_TRANSPARENCY_MODAL";
const ERROR = "Error";
const UPDATE_RECURRING_COLOR_MODAL = "update_recurring_color_modal";
const UPDATE_RECURRING_TAGS_MODAL = "update_recurring_tags_modal";
const UPDATE_RECURRING_CATEGORIES_MODAL = "update_recurring_categories_modal";

// this is more of a general modal for recurring events
const UPDATE_SINGLE_PROPERTY_ON_RECURRING_EVENT_MODAL =  "update_single_property_modal";
const BILLING_UNAVAILABLE = "billing_unavailable";

const createEventKeyMapHint = {
  left: "-8px",
  top: "-8px",
};

const createAvailabilityHint = {
  marginLeft: "-50px",
  top: "-50px",
  width: "max-content",
};

const createAvailabilityKeyMapHint = {
  left: "-8px",
  top: "-8px",
};

const createEventHint = { marginLeft: "-20px", top: "-50px" };

const availabilityButtonStyle = {
  width: 48,
  height: 48,
  borderRadius: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  border: "none",
};

const {
  temporary,
  attendee_event_attending,
  attendee_event_tentative,
  EDIT_RECURRING_INSTANCE_ONLY,
  EDIT_RECURRING_FOLLOWING_EVENTS,
  EDIT_RECURRING_ALL_INSTANCES,
} = GoogleCalendarService;

const SHOW_DIFFERENT_TIME_ZONE_WARNING = "time_zone_warning";
const DRAG_EVENT = "drag_event";
const REFER_TO_VIMCAL = "refer_to_vimcal";

const DnDCalendar = withDragAndDrop(Calendar);

class MainCalendar extends Component {
  constructor(props) {
    super(props);

    const defaultUserTimeZone = this.getDefaultUserTimeZone();
    this._hasWeeklyCalendarScrolled = false;
    this._timeZone = defaultUserTimeZone; // to update timezone synchronously
    this._isInitialSync = true;

    this._mimicDeletedUserEventIDs = []; // list of events that are in the process of being deleted
    this._deletedEventAtMap = new Map();

    // need these two to be synchronous
    this._meetWithEvents = [];
    this._eventFormEvents = [];
    this._glowButtonTimeout = null;

    this.state = {
      invalidRecurrences: {},
      formattedEvents: [],
      formattedEventsWithTemporaryEvents: [],
      displayEvents: [], // for now, only used for hiding all day work events
      shouldShowTimeZoneModal: false,
      shouldDisplayModal: false,
      modalWidth: 300,
      modalTitle: "",
      modalContent: null,
      oldTimeZone: "",
      currentDefaultTimeZone: defaultUserTimeZone,
      hideBigCalendar: false,
      eventToBeUpdated: null,
      shadedAvailabiityHours: this.getCalendarShadedHours(),
      modalArray: [],
      originalEvent: null,
      isMouseOverMonthDate: false,
      lastGuessTimeZone: getDeviceTimeZone(),
      isMac: isMac(),
      resourceMap: [],
      localizer: determineRBCLocalizer(
        parseInt(this.props.weekStart || 0),
        this.props.dateFieldOrder,
      ),
      calendarFormats: {
        timeGutterFormat: "ha",
        // dayFormat: 'EEE Mo', // Mon 1st
        dayFormat: (date) => {
          return date.toISOString();
        },
        // dayRangeHeaderFormat: 'D MMMM dddd',
        eventTimeRangeFormat: _.noop,
        selectRangeFormat: (slots) =>
          this.timeRangeFormat(slots, props.format24HourTime),
      },
      calendarMessages: {
        showMore: (total) => `${total} more`,
      },
      calendarViews: {
        week: this.isCalendarView(7) ? true : CustomWeek,
        work_week: true,
        day: true,
        month: true,
      },
      currentTimeIndicator: getCurrentTimeInCurrentTimeZone(defaultUserTimeZone),
      renderCount: 0, // used to trigger re-render
      glowOnButton: null, // ONBOARDING_GLOW_TYPE
    };

    this.returnToDefaultTimeZone = this.returnToDefaultTimeZone.bind(this);
    this.setWeeklyCalendarTimeZone = this.setWeeklyCalendarTimeZone.bind(this);
    this.onEventDrop = this.onEventDrop.bind(this);
    this.eventStyleGetter = this.eventStyleGetter.bind(this);
    this.slotStyleGetter = this.slotStyleGetter.bind(this);
    this.onEventResize = this.onEventResize.bind(this);
    this.onSelectTimeSlot = this.onSelectTimeSlot.bind(this);
    this.onSelectEvent = this.onSelectEvent.bind(this);
    this.removeTemporaryEvents = this.removeTemporaryEvents.bind(this);
    this.onCreateAvailabilitySlot = this.onCreateAvailabilitySlot.bind(this);
    this.onDoubleClickEvent = this.onDoubleClickEvent.bind(this);
    this.determineOnSelectSlotMethod =
      this.determineOnSelectSlotMethod.bind(this);
    this.toggleAvailabilityMode = this.toggleAvailabilityMode.bind(this);
    this.addXHours = this.addXHours.bind(this);
    this.cancelSelectAvailability = this.cancelSelectAvailability.bind(this);
    this.setModalContent = this.setModalContent.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.updateListOfEvents = this.updateListOfEvents.bind(this);
    this.onClickCreateEvent = this.onClickCreateEvent.bind(this);
    this.onNavigation = this.onNavigation.bind(this);
    this.determineDraggableAccessor =
      this.determineDraggableAccessor.bind(this);
    this.determineOnDoubleClick = this.determineOnDoubleClick.bind(this);
    this.determineOnSelectEvent = this.determineOnSelectEvent.bind(this);
    this.getTemporaryMeetWithEventFormAndMimicEvents =
      this.getTemporaryMeetWithEventFormAndMimicEvents.bind(this);
    this.updateWeekIfTimeZoneChangesToday =
      this.updateWeekIfTimeZoneChangesToday.bind(this);
    this.emptyEventsInMainCalendar = this.emptyEventsInMainCalendar.bind(this);
    this.warnCanNotEditEvent = this.warnCanNotEditEvent.bind(this);
    this.shouldBeSelected = this.shouldBeSelected.bind(this);
    this.filterOutActiveCalendars = this.filterOutActiveCalendars.bind(this);
    this.setUpPopup = this.setUpPopup.bind(this);
    this.wipeOutEvents = this.wipeOutEvents.bind(this);
    this.controlEventsWithArrowKeys =
      this.controlEventsWithArrowKeys.bind(this);
    this.updateEventWithMultipleAttendees =
      this.updateEventWithMultipleAttendees.bind(this);
    this.determineModalsBeforeSavingEvent =
      this.determineModalsBeforeSavingEvent.bind(this);
    this.createEventsHotKeysIndexOfCurrentWeek =
      this.createEventsHotKeysIndexOfCurrentWeek.bind(this);
    this.temporarilyRemoveWeeklyCalendarAndUpdateEvent =
      this.temporarilyRemoveWeeklyCalendarAndUpdateEvent.bind(this);
    this.getEventStaticInfo = this.getEventStaticInfo.bind(this);
    this.wipeOutEventsAndAddEvents = this.wipeOutEventsAndAddEvents.bind(this);
    this.updateRecurringEventResponse =
      this.updateRecurringEventResponse.bind(this);
    this.determineModalCloseMethod = this.determineModalCloseMethod.bind(this);
    this.putBackOriginalEventAndRemoveTemporaryEvent =
      this.putBackOriginalEventAndRemoveTemporaryEvent.bind(this);
    this.showErrorMessage = this.showErrorMessage.bind(this);
    this.determineResizableAccessor =
      this.determineResizableAccessor.bind(this);
    this.customDayLayout = this.customDayLayout.bind(this);
    this.updateRecurrenceColor = this.updateRecurrenceColor.bind(this);
    this.updateRecurrenceCategory = this.updateRecurrenceCategory.bind(this);
    this.setColorRecurringModal = this.setColorRecurringModal.bind(this);
    this.setCategoriesRecurringModal = this.setCategoriesRecurringModal.bind(this);
    this.setTagsRecurringModal = this.setTagsRecurringModal.bind(this);
    this.fetchEventsFromIndexDB = this.fetchEventsFromIndexDB.bind(this);
    this.refetchEventsFromDBOnTimeZoneChange =
      this.refetchEventsFromDBOnTimeZoneChange.bind(this);
    this.setWeekStart = this.setWeekStart.bind(this);
    this.addTemporarySearchedEvents =
      this.addTemporarySearchedEvents.bind(this);
    this.openModalDefaultTimeZone = this.openModalDefaultTimeZone.bind(this);
    this.timeRangeFormat = this.timeRangeFormat.bind(this);
    this.onClickShowMore = this.onClickShowMore.bind(this);
    this.onViewChange = this.onViewChange.bind(this);
    this.remountCalendar = this.remountCalendar.bind(this);
    this.resetCalendarScroll = this.resetCalendarScroll.bind(this);
    this.onMouseOverMonthDate = this.onMouseOverMonthDate.bind(this);
    this.showUnavailableBillingMessage =
      this.showUnavailableBillingMessage.bind(this);
    this.onDeleteAvailabilitySlot = this.onDeleteAvailabilitySlot.bind(this);
    this.getEventsInCurrentView = this.getEventsInCurrentView.bind(this);
    this.resetEvents = this.resetEvents.bind(this);
    this.setLastSelectedEvent = this.setLastSelectedEvent.bind(this);
    this.removeAllAnchorTimeZones = this.removeAllAnchorTimeZones.bind(this);
    this.reformatAllEvents = this.reformatAllEvents.bind(this);
    this.setRecurringEventTransparency =
      this.setRecurringEventTransparency.bind(this);
    this.updateRecurrenceTransparency =
      this.updateRecurrenceTransparency.bind(this);
    this.setLastDeclinedEvent = this.setLastDeclinedEvent.bind(this);
    this.removeEvent = this.removeEvent.bind(this);
    this.updateNowTime = this.updateNowTime.bind(this);
    this.removeIdsFromStyleIndex = this.removeIdsFromStyleIndex.bind(this);
    this.cancelCreateFocusBlock = this.cancelCreateFocusBlock.bind(this);
    this.removeHoverPopUpEvent = this.removeHoverPopUpEvent.bind(this);
    this.removePreviewEvent = this.removePreviewEvent.bind(this);
    this.setPreviewEvent = this.setPreviewEvent.bind(this);
    this.removeHoverEvent = this.removeHoverEvent.bind(this);
    this.removePopUpEvent = this.removePopUpEvent.bind(this);
    this.addMimicDeletedEvent = this.addMimicDeletedEvent.bind(this);
    this.removeMimicDeletedEvent = this.removeMimicDeletedEvent.bind(this);
    this.setTemporaryEvents = this.setTemporaryEvents.bind(this);
    this.updateEventIntoWeeklyCalendar = this.updateEventIntoWeeklyCalendar.bind(this);
    this.addEvent = this.addEvent.bind(this);
    this.triggerMainCalendarRerender = this.triggerMainCalendarRerender.bind(this);
    this.changeViewToDayView = this.changeViewToDayView.bind(this);
    this.setEventsInMainCalendar = this.setEventsInMainCalendar.bind(this);
    this.removeMultipleEvents = this.removeMultipleEvents.bind(this);
    this.toggleOnGlowOnButton = this.toggleOnGlowOnButton.bind(this);
    this.removGlowOnButton = this.removGlowOnButton.bind(this);
    this.showFreeTimesForUser = this.showFreeTimesForUser.bind(this);
    this.deleteRecurringEvents = this.deleteRecurringEvents.bind(this);
    this.updateForOwnVersionOfEvent = this.updateForOwnVersionOfEvent.bind(this);
    this.updateRecurrenceOnUpdateSingleProperty = this.updateRecurrenceOnUpdateSingleProperty.bind(this);
    this.remountCalendarWithDelay = this.remountCalendarWithDelay.bind(this);
    this.removeUnSelectedTimeZone = this.removeUnSelectedTimeZone.bind(this);
    this.recurringEventSingleResourceUpdate = recurringEventSingleResourceUpdate.bind(this);
    this.addOutlookPreviewEvent = this.addOutlookPreviewEvent.bind(this);
    this.setHoverPreviewEvent = this.setHoverPreviewEvent.bind(this);
    this.replaceOutlookEventWithFullEvent = this.replaceOutlookEventWithFullEvent.bind(this);
    this.removeAllEvents = this.removeAllEvents.bind(this);
    this.isEditGroupVoteEvent = this.isEditGroupVoteEvent.bind(this);
    this.updateEventsHoldDetails = this.updateEventsHoldDetails.bind(this);
    this.updateLastUpdatedAtForDeletedUserEventIDs = this.updateLastUpdatedAtForDeletedUserEventIDs.bind(this);
    this.resetMainCalendarCache = this.resetMainCalendarCache.bind(this);
    this.removeFindTimeEvents = this.removeFindTimeEvents.bind(this);

    Broadcast.subscribe(
      "RETURN_TO_DEFAULT_TIME_ZONE",
      this.returnToDefaultTimeZone,
    );
    Broadcast.subscribe(
      "REMOVE_ALL_ANCHOR_TIME_ZONES",
      this.removeAllAnchorTimeZones,
    );
    Broadcast.subscribe("SELECT_TIME_ZONE", this.setWeeklyCalendarTimeZone);
    Broadcast.subscribe("CLOSE_WEEKLY_CALENDAR_MODAL", this.closeModal);
    Broadcast.subscribe(
      "TOGGLE_AVAILABILITY_MODE",
      this.toggleAvailabilityMode,
    );
    mainCalendarBroadcast.subscribe(MAIN_CALENDAR_BROADCAST_VALUES.ADD_EVENT_INTO_WEEKLY_CALENDAR, this.addEvent);
    mainCalendarBroadcast.subscribe(MAIN_CALENDAR_BROADCAST_VALUES.REMOVE_EVENT_FROM_WEEKLY_CALENDAR, this.removeEvent);
    mainCalendarBroadcast.subscribe(MAIN_CALENDAR_BROADCAST_VALUES.UPDATE_EVENT_INTO_WEEKLY_CALENDAR, this.updateEventIntoWeeklyCalendar);
    mainCalendarBroadcast.subscribe(
      MAIN_CALENDAR_BROADCAST_VALUES.UPDATE_LIST_OF_EVENTS_INTO_WEEKLY_CALENDAR,
      this.updateListOfEvents,
    );
    Broadcast.subscribe("REMOVE_TEMPORARY_EVENTS", this.removeTemporaryEvents);
    Broadcast.subscribe("NAVIGATE_DATE", this.onNavigation);
    Broadcast.subscribe(
      "EMPTY_EVENTS_AND_EVENTS_INDEX",
      this.emptyEventsInMainCalendar,
    );
    Broadcast.subscribe(
      "CANCEL_SELECT_AVAILABILITY",
      this.cancelSelectAvailability,
    );
    Broadcast.subscribe(
      "DELETE_AVAILABILITY_SLOT",
      this.onDeleteAvailabilitySlot,
    );
    mainCalendarBroadcast.subscribe(MAIN_CALENDAR_BROADCAST_VALUES.REMOUNT_CALENDAR, this.remountCalendar);
    Broadcast.subscribe("WIPE_OUT_EVENTS", this.wipeOutEvents); // only used for switch account
    Broadcast.subscribe(
      "MOVE_EVENT_WITH_ARROW_KEYS",
      this.controlEventsWithArrowKeys,
    );
    Broadcast.subscribe(
      "UPDATE_HOTKEY_EVENTS_MAPPING",
      this.createEventsHotKeysIndexOfCurrentWeek,
    );
    Broadcast.subscribe(
      "TEMPORARILY_REMOVE_WEEKLY_CALENDAR_EVENT_AND_UPDATE_EVENT",
      this.temporarilyRemoveWeeklyCalendarAndUpdateEvent,
    );
    Broadcast.subscribe(
      MAIN_CALENDAR_BROADCAST_VALUES.WIPE_OUT_EVENTS_AND_ADD_EVENTS,
      this.wipeOutEventsAndAddEvents,
    );
    Broadcast.subscribe("SHOW_REFER_TO_VIMCAL_MODAL", () =>
      this.setModalContent("Refer to Vimcal 🎁", 500, REFER_TO_VIMCAL),
    );
    Broadcast.subscribe("SHOW_ERROR_MESSAGE", this.showErrorMessage);
    Broadcast.subscribe(
      BROADCAST_VALUES.DISPLAY_RECURRING_COLOR_MODAL,
      this.setColorRecurringModal,
    );
    Broadcast.subscribe(
      BROADCAST_VALUES.DISPLAY_RECURRING_CATEGORIES_MODAL,
      this.setCategoriesRecurringModal,
    );
    Broadcast.subscribe(
      "DISPLAY_RECURRING_TRANSPARENCY_MODAL",
      this.setRecurringEventTransparency,
    );
    tagsBroadcast.subscribe(
      "DISPLAY_RECURRING_TAGS_MODAL",
      this.setTagsRecurringModal,
    );
    mainCalendarBroadcast.subscribe(
      MAIN_CALENDAR_BROADCAST_VALUES.INITIALIZE_EVENTS_IN_WEEKLY_CALENDAR,
      this.fetchEventsFromIndexDB,
    );
    Broadcast.subscribe("SET_WEEK_START_WEEKLY_CALENDAR", this.setWeekStart);
    mainCalendarBroadcast.subscribe(
      MAIN_CALENDAR_BROADCAST_VALUES.ADD_TEMPORARY_SEARCHED_EVENTS,
      this.addTemporarySearchedEvents,
    );
    Broadcast.subscribe(
      "OPEN_MODAL_ON_UPDATE_DEFAULT_TIME_ZONE",
      this.openModalDefaultTimeZone,
    );
    Broadcast.subscribe("RESET_CALENDAR_SCROLL", this.resetCalendarScroll);
    Broadcast.subscribe("ON_MOUSE_OVER_MONTH_DATE", this.onMouseOverMonthDate);
    Broadcast.subscribe(
      "UNAVAILABLE_BILLING_MODAL",
      this.showUnavailableBillingMessage,
    );
    Broadcast.subscribe(
      "GET_EVENTS_IN_CURRENT_VIEW",
      this.getEventsInCurrentView,
    );
    Broadcast.subscribe("RESET_EVENTS", this.resetEvents);
    Broadcast.subscribe("SET_LAST_SELECTED_EVENT", this.setLastSelectedEvent);
    mainCalendarBroadcast.subscribe(
      "SET_LAST_DECLINED_EVENT",
      this.setLastDeclinedEvent,
    );
    Broadcast.subscribe("REFORMAT_ALL_EVENTS", this.reformatAllEvents);
    mainCalendarBroadcast.subscribe(
      "UPDATE_MAIN_CALENDAR_CURRENT_TIME",
      this.updateNowTime,
    );
    mainCalendarBroadcast.subscribe(
      "REMOVE_IDS_FROM_STYLE_INDEX",
      this.removeIdsFromStyleIndex,
    );
    mainCalendarBroadcast.subscribe("CANCEL_CREATE_FOCUS_BLOCK", this.cancelCreateFocusBlock);
    mainCalendarBroadcast.subscribe("REMOVE_PREVIEW_EVENT", this.removePreviewEvent);
    mainCalendarBroadcast.subscribe("SET_PREVIEW_EVENT", this.setPreviewEvent);
    mainCalendarBroadcast.subscribe("REMOVE_HOVER_EVENT", this.removeHoverEvent);
    mainCalendarBroadcast.subscribe("REMOVE_POPUP_EVENT", this.removePopUpEvent);
    mainCalendarBroadcast.subscribe(MAIN_CALENDAR_BROADCAST_VALUES.ADD_MIMIC_DELETE_EVENT, this.addMimicDeletedEvent);
    mainCalendarBroadcast.subscribe("REMOVE_MIMIC_DELETE_EVENT", this.removeMimicDeletedEvent);
    mainCalendarBroadcast.subscribe("SET_TEMPORARY_EVENTS", this.setTemporaryEvents);
    mainCalendarBroadcast.subscribe("TRIGGER_MAIN_CALENDAR_RERENDER", this.triggerMainCalendarRerender);
    mainCalendarBroadcast.subscribe("SET_MAIN_CALENDAR_TO_DAY_VIEW", this.changeViewToDayView);
    mainCalendarBroadcast.subscribe("SET_POPUP_EVENT", this.setUpPopup);
    mainCalendarBroadcast.subscribe(MAIN_CALENDAR_BROADCAST_VALUES.REMOVE_MULTIPLE_EVENTS, this.removeMultipleEvents);
    mainCalendarBroadcast.subscribe("TOGGLE_ON_GLOW_BUTTON", this.toggleOnGlowOnButton);
    mainCalendarBroadcast.subscribe("REMOVE_GLOW_ON_BUTTON", this.removGlowOnButton);
    mainCalendarBroadcast.subscribe(MAIN_CALENDAR_BROADCAST_VALUES.SHOW_FREE_TIMES_FOR_USER, this.showFreeTimesForUser);
    mainCalendarBroadcast.subscribe("DELETE_RECURRING_EVENTS", this.deleteRecurringEvents);
    mainCalendarBroadcast.subscribe("UPDATE_FOR_OWN_VERSION_OF_EVENT", this.updateForOwnVersionOfEvent);
    mainCalendarBroadcast.subscribe(MAIN_CALENDAR_BROADCAST_VALUES.REMOUNT_CALENDAR_WITH_DELAY, this.remountCalendarWithDelay);
    mainCalendarBroadcast.subscribe(MAIN_CALENDAR_BROADCAST_VALUES.REMOVE_UNSELECTED_TIME_ZONE, this.removeUnSelectedTimeZone);
    mainCalendarBroadcast.subscribe(MAIN_CALENDAR_BROADCAST_VALUES.ADD_OUTLOOK_PREVIEW_EVENTS, this.addOutlookPreviewEvent);
    mainCalendarBroadcast.subscribe(MAIN_CALENDAR_BROADCAST_VALUES.SET_HOVER_POPUP_EVENT, this.setHoverPreviewEvent);
    mainCalendarBroadcast.subscribe(MAIN_CALENDAR_BROADCAST_VALUES.REPLACE_OUTLOOK_PREVIEW_EVENT, this.replaceOutlookEventWithFullEvent);
    mainCalendarBroadcast.subscribe(MAIN_CALENDAR_BROADCAST_VALUES.REMOVE_ALL_EVENTS, this.removeAllEvents);
    mainCalendarBroadcast.subscribe(MAIN_CALENDAR_BROADCAST_VALUES.UPDATE_EVENTS_HOLD_DETAILS, this.updateEventsHoldDetails);
    mainCalendarBroadcast.subscribe(MAIN_CALENDAR_BROADCAST_VALUES.UPDATE_LAST_UPDATED_AT_FOR_DELETED_USER_EVENT_IDS, this.updateLastUpdatedAtForDeletedUserEventIDs);
    mainCalendarBroadcast.subscribe(MAIN_CALENDAR_BROADCAST_VALUES.ON_SELECT_TIME_SLOT, this.onSelectTimeSlot);
    mainCalendarBroadcast.subscribe(MAIN_CALENDAR_BROADCAST_VALUES.RESET_MAIN_CALENDAR_CACHE, this.resetMainCalendarCache);
    mainCalendarBroadcast.subscribe(MAIN_CALENDAR_BROADCAST_VALUES.REMOVE_FIND_TIME_EVENTS, this.removeFindTimeEvents);
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return hasStateOrPropsChanged(
      this.state,
      nextState,
      this.props,
      nextProps,
      ["match", "location"],
      false,
    );
  }

  componentDidMount() {
    this._isMounted = true;
    this.removePreviewEvent();

    if (doesWindowLocationIncludeString("billing_unavailable=true")) {
      this.props.history.push("home");
      setTimeout(() => {
        if (this._isMounted) {
          this.showUnavailableBillingMessage();
        }
      }, 50);
    } else if (doesWindowLocationIncludeString("reroute=feedback")) {
      this.props.history.push("/home");

      setTimeout(() => {
        if (this._isMounted) {
          layoutBroadcast.publish(LAYOUT_BROADCAST_VALUES.TOGGLE_PROVIDE_FEEDBACK);
        }
      }, 50);
    }
    this._dbFetchEventsId = createUUID();

    this.initializeCalendarEvents(this._dbFetchEventsId);

    if (
      this.props.defaultBrowserTimeZone &&
      this.props.defaultBrowserTimeZone !== this.state.currentDefaultTimeZone
    ) {
      this.openModalDefaultTimeZone();
    }
    const {
      lastSelectedTimeZone,
      resetLastSelectedTimeZone,
      orderedTimeZones,
      setOrderedTimeZones,
      lastOrderedTimeZonesUpdatedAt,
    } = this.props.appTimeZone;
    if (lastSelectedTimeZone) {
      resetLastSelectedTimeZone();
    }
    const {
      currentUser,
    } = this.props;
    const {
      masterAccount,
    } = this.props.masterAccount;
    const accountOrderedTimeZone = getUserAccountOrderedTimeZone({
      masterAccount,
      currentUser,
    });
    const lastUpdatedAtOrderedTimeZones = getUserAccountUpdatedAtTime({
      currentUser,
      masterAccount,
    });
    if (!isStringArraysEqual(orderedTimeZones, accountOrderedTimeZone)
      && shouldUpdateOrderedTimeZonesFromBackend({
        backendUpdatedAt: lastUpdatedAtOrderedTimeZones,
        localUpdatedAt: lastOrderedTimeZonesUpdatedAt,
      })
    ) {
      setOrderedTimeZones(accountOrderedTimeZone);
    }

    // useful for rewind
    this.trackUserTimeZoneInfo();
  }

  componentWillUnmount() {
    this._isMounted = false;

    Broadcast.unsubscribe("RETURN_TO_DEFAULT_TIME_ZONE");
    Broadcast.unsubscribe("REMOVE_ALL_ANCHOR_TIME_ZONES");
    Broadcast.unsubscribe("SELECT_TIME_ZONE");
    Broadcast.unsubscribe("CLOSE_WEEKLY_CALENDAR_MODAL");
    mainCalendarBroadcast.unsubscribe(MAIN_CALENDAR_BROADCAST_VALUES.ADD_EVENT_INTO_WEEKLY_CALENDAR);
    mainCalendarBroadcast.unsubscribe(MAIN_CALENDAR_BROADCAST_VALUES.REMOVE_EVENT_FROM_WEEKLY_CALENDAR);
    mainCalendarBroadcast.unsubscribe(MAIN_CALENDAR_BROADCAST_VALUES.UPDATE_EVENT_INTO_WEEKLY_CALENDAR);
    mainCalendarBroadcast.unsubscribe(MAIN_CALENDAR_BROADCAST_VALUES.UPDATE_LIST_OF_EVENTS_INTO_WEEKLY_CALENDAR);
    Broadcast.unsubscribe("REMOVE_TEMPORARY_EVENTS");
    Broadcast.unsubscribe("NAVIGATE_DATE");
    Broadcast.unsubscribe("EMPTY_EVENTS_AND_EVENTS_INDEX");
    Broadcast.unsubscribe("CANCEL_SELECT_AVAILABILITY");
    Broadcast.unsubscribe("DELETE_AVAILABILITY_SLOT");
    mainCalendarBroadcast.unsubscribe(MAIN_CALENDAR_BROADCAST_VALUES.REMOUNT_CALENDAR);
    Broadcast.unsubscribe("WIPE_OUT_EVENTS");
    Broadcast.unsubscribe("MOVE_EVENT_WITH_ARROW_KEYS");
    Broadcast.unsubscribe("UPDATE_HOTKEY_EVENTS_MAPPING");
    Broadcast.unsubscribe(
      "TEMPORARILY_REMOVE_WEEKLY_CALENDAR_EVENT_AND_UPDATE_EVENT",
    );
    Broadcast.unsubscribe(MAIN_CALENDAR_BROADCAST_VALUES.WIPE_OUT_EVENTS_AND_ADD_EVENTS);
    Broadcast.unsubscribe("SHOW_REFER_TO_VIMCAL_MODAL");
    Broadcast.unsubscribe("SHOW_ERROR_MESSAGE");
    Broadcast.unsubscribe(BROADCAST_VALUES.DISPLAY_RECURRING_COLOR_MODAL);
    Broadcast.unsubscribe("DISPLAY_RECURRING_TRANSPARENCY_MODAL");
    mainCalendarBroadcast.unsubscribe(MAIN_CALENDAR_BROADCAST_VALUES.INITIALIZE_EVENTS_IN_WEEKLY_CALENDAR);
    Broadcast.unsubscribe("SET_WEEK_START_WEEKLY_CALENDAR");
    mainCalendarBroadcast.unsubscribe(MAIN_CALENDAR_BROADCAST_VALUES.ADD_TEMPORARY_SEARCHED_EVENTS);
    Broadcast.unsubscribe("OPEN_MODAL_ON_UPDATE_DEFAULT_TIME_ZONE");
    Broadcast.unsubscribe("RESET_CALENDAR_SCROLL");
    Broadcast.unsubscribe("ON_MOUSE_OVER_MONTH_DATE");
    Broadcast.unsubscribe("GET_EVENTS_IN_CURRENT_VIEW");
    Broadcast.unsubscribe("RESET_EVENTS");
    Broadcast.unsubscribe("SET_LAST_SELECTED_EVENT");
    mainCalendarBroadcast.unsubscribe("SET_LAST_DECLINED_EVENT");
    Broadcast.unsubscribe("REFORMAT_ALL_EVENTS");
    Broadcast.unsubscribe("UNAVAILABLE_BILLING_MODAL");
    mainCalendarBroadcast.unsubscribe("UPDATE_MAIN_CALENDAR_CURRENT_TIME");
    mainCalendarBroadcast.unsubscribe("REMOVE_IDS_FROM_STYLE_INDEX");
    mainCalendarBroadcast.unsubscribe("CANCEL_CREATE_FOCUS_BLOCK");
    mainCalendarBroadcast.unsubscribe("REMOVE_PREVIEW_EVENT");
    mainCalendarBroadcast.unsubscribe("SET_PREVIEW_EVENT");
    mainCalendarBroadcast.unsubscribe("REMOVE_HOVER_EVENT");
    mainCalendarBroadcast.unsubscribe("REMOVE_POPUP_EVENT");
    mainCalendarBroadcast.unsubscribe(MAIN_CALENDAR_BROADCAST_VALUES.ADD_MIMIC_DELETE_EVENT);
    mainCalendarBroadcast.unsubscribe("REMOVE_MIMIC_DELETE_EVENT");
    mainCalendarBroadcast.unsubscribe("SET_TEMPORARY_EVENTS");
    tagsBroadcast.unsubscribe("DISPLAY_RECURRING_TAGS_MODAL");
    mainCalendarBroadcast.unsubscribe("TRIGGER_MAIN_CALENDAR_RERENDER");
    mainCalendarBroadcast.unsubscribe("SET_MAIN_CALENDAR_TO_DAY_VIEW");
    mainCalendarBroadcast.unsubscribe("SET_POPUP_EVENT");
    Broadcast.unsubscribe("TOGGLE_AVAILABILITY_MODE");
    mainCalendarBroadcast.unsubscribe(MAIN_CALENDAR_BROADCAST_VALUES.REMOVE_MULTIPLE_EVENTS);
    mainCalendarBroadcast.unsubscribe("TOGGLE_ON_GLOW_BUTTON");
    mainCalendarBroadcast.unsubscribe("REMOVE_GLOW_ON_BUTTON");
    mainCalendarBroadcast.unsubscribe(MAIN_CALENDAR_BROADCAST_VALUES.SHOW_FREE_TIMES_FOR_USER);
    mainCalendarBroadcast.unsubscribe("DELETE_RECURRING_EVENTS");
    mainCalendarBroadcast.unsubscribe("UPDATE_FOR_OWN_VERSION_OF_EVENT");
    mainCalendarBroadcast.unsubscribe(MAIN_CALENDAR_BROADCAST_VALUES.REMOUNT_CALENDAR_WITH_DELAY);
    mainCalendarBroadcast.unsubscribe(MAIN_CALENDAR_BROADCAST_VALUES.REMOVE_UNSELECTED_TIME_ZONE);
    mainCalendarBroadcast.unsubscribe(MAIN_CALENDAR_BROADCAST_VALUES.ADD_OUTLOOK_PREVIEW_EVENTS);
    mainCalendarBroadcast.unsubscribe(MAIN_CALENDAR_BROADCAST_VALUES.SET_HOVER_POPUP_EVENT);
    mainCalendarBroadcast.unsubscribe(MAIN_CALENDAR_BROADCAST_VALUES.REPLACE_OUTLOOK_PREVIEW_EVENT);
    mainCalendarBroadcast.unsubscribe(MAIN_CALENDAR_BROADCAST_VALUES.REMOVE_ALL_EVENTS);
    mainCalendarBroadcast.unsubscribe(MAIN_CALENDAR_BROADCAST_VALUES.UPDATE_EVENTS_HOLD_DETAILS);
    mainCalendarBroadcast.unsubscribe(MAIN_CALENDAR_BROADCAST_VALUES.UPDATE_LAST_UPDATED_AT_FOR_DELETED_USER_EVENT_IDS);
    mainCalendarBroadcast.unsubscribe(MAIN_CALENDAR_BROADCAST_VALUES.ON_SELECT_TIME_SLOT);
    mainCalendarBroadcast.unsubscribe(MAIN_CALENDAR_BROADCAST_VALUES.RESET_MAIN_CALENDAR_CACHE);
    mainCalendarBroadcast.unsubscribe(MAIN_CALENDAR_BROADCAST_VALUES.REMOVE_FIND_TIME_EVENTS);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.selectedCalendarView !== prevProps.selectedCalendarView ||
      hasArrayChangedSize(
        this.state.formattedEventsWithTemporaryEvents,
        prevState.formattedEventsWithTemporaryEvents,
      )
    ) {
      // If should update mapping
      this.createEventsHotKeysIndexOfCurrentWeek();
    }

    if (this.shouldUpdateFindTimeEvents(prevProps, prevState)) {
      // update find time events
      const {
        findTimeUser,
        findTimeDuration,
      } = this.props.temporaryStateStore;
      this.showFreeTimesForUser({
        inputUser: findTimeUser,
        inputDuration: findTimeDuration,
      });
    }

    const hasSplitViewChanged = this.props.appSettings.isSplitView !== prevProps.appSettings.isSplitView;
    if (prevProps.temporaryEvents !== this.props.temporaryEvents || this.props.mimicEventsList !== prevProps.mimicEventsList) {
      // temporaryEvents here are immutable
      this.setUpdatedTemporaryEvents();
    } else if (
      (this.state.formattedEventsWithTemporaryEvents !==
        prevState.formattedEventsWithTemporaryEvents ||
        this.props.selectedCalendarView !== prevProps.selectedCalendarView ||
        this.props.selectedDay !== prevProps.selectedDay ||
        this.props.emailToNameIndex !== prevProps.emailToNameIndex ||
        this.props.eventFormEmails !== prevProps.eventFormEmails ||
        this.props.allCalendars?.allCalendars !== prevProps.allCalendars?.allCalendars ||
        hasSplitViewChanged
      ) &&
      this.isInSplitView()
    ) {
      this.updateResourceMapping();
    }

    const guessedTimeZone = getDeviceTimeZone();
    if (
      this.state.lastGuessTimeZone &&
      guessedTimeZone &&
      guessedTimeZone !== this.state.lastGuessTimeZone
    ) {
      BackendBroadcasts.publish("UPDATE_USER_TIME_ZONE");
      triggerRefreshWithOnlineCheck();
    }

    if (
      this.props.selectedDay &&
      prevProps.selectedDay &&
      this.props.selectedDay !== prevProps.selectedDay
    ) {
      let eventsInView = this.resetEvents();
      if (this.props.selectedCalendarView !== BACKEND_MONTH) {
        this.createEventsHotKeysIndexOfCurrentWeek(eventsInView);
      }
    }

    if (
      this.shouldUpdateRBCCalendarView(
        prevProps.selectedCalendarView,
        this.props.selectedCalendarView,
      )
    ) {
      this.updateCalendarViews();
    }

    const { allCalendars } = this.props.allCalendars;

    if (allCalendars !== prevProps.allCalendars?.allCalendars) {
      // if calendar updates -> reset styles to prevent old cache
      clearEventStaticCache();
      this.setState({ renderCount: this.state.renderCount + 1 });
    }

    const {
      masterAccount: prevPropsMasterAccount,
    } = prevProps.masterAccount;
    const {
      masterAccount: currentPropsMasterAccount,
    } = this.props.masterAccount;
    const {
      currentUser: prevPropsCurrentUser,
    } = prevProps;
    const {
      currentUser: currentPropsCurrentUser,
    } = this.props;

    const previousDefaultTimeZone = getDefaultUserTimeZone({ masterAccount: prevPropsMasterAccount, user: prevPropsCurrentUser });
    const updatedDefaultTimeZone = getDefaultUserTimeZone({ masterAccount: currentPropsMasterAccount, user: currentPropsCurrentUser });
    if (previousDefaultTimeZone !== updatedDefaultTimeZone) {
      // this this here to avoid race condition
      this.setState({ currentTimeIndicator: this.getCurrentTimeIndicatorTime(updatedDefaultTimeZone) }, () => {
        this.remountCalendar();
        this.trackUserTimeZoneInfo();
        this.refetchEventsFromDBOnTimeZoneChange({
          prevTimeZone: previousDefaultTimeZone,
          timeZone: updatedDefaultTimeZone,
          timeZoneLabel: previousDefaultTimeZone,
        });
      });
    }

    if (shouldMergeEvents({ masterAccount: currentPropsMasterAccount })
      !== shouldMergeEvents({ masterAccount: prevPropsMasterAccount })
      || hasSplitViewChanged // reset merge if split view changes
    ) {
      // need to reset the events. Need to put it here due to race condition
      this.resetEvents();
    }

    if (!_.isEqual(
      this.props.outlookCategoriesStore?.outlookCategories,
      prevProps.outlookCategoriesStore?.outlookCategories,
    )) {
      clearEventStyleCache();
      this.reformatAllEvents();
    }

    if (this.state.formattedEventsWithTemporaryEvents !== prevState.formattedEventsWithTemporaryEvents
      || (
        getMasterAccountShouldHideAllDayBusyColumn({ masterAccount: currentPropsMasterAccount }) !==
        getMasterAccountShouldHideAllDayBusyColumn({ masterAccount: prevPropsMasterAccount })
      )
      || (
        getMasterAccountShouldHideOOOColumn({ masterAccount: currentPropsMasterAccount }) !==
        getMasterAccountShouldHideOOOColumn({ masterAccount: prevPropsMasterAccount })
      )
    ) {
      this.updateOOOAndBusyEventsRange();
    }

    if (this.state.formattedEventsWithTemporaryEvents !== prevState.formattedEventsWithTemporaryEvents
      || shouldHideWorkingLocationEvents({
        masterAccount: currentPropsMasterAccount,
        user: currentPropsCurrentUser,
      }) !== shouldHideWorkingLocationEvents({
        masterAccount: prevPropsMasterAccount,
        user: prevPropsCurrentUser,
      })
    ) {
      this.updateDisplayEvents();
    }
  }

  render() {
    const {
      formattedEventsWithTemporaryEvents,
      hideBigCalendar,
    } = this.state;
    return (
      <div
        id={MAIN_CALENDAR_ID}
        className={classNames(
          "week-view relative",
          this.determineMainCalendarWidthClassName(),
          "select-none",
        )}
      >
        {hideBigCalendar ? null : this.renderBigCalendar()}
        <MainCalendarEventsReaderHelper
          mainCalendarEvents={formattedEventsWithTemporaryEvents}
        />
        <ReverseSlotsFilterReader
          mainCalendarEvents={formattedEventsWithTemporaryEvents}
        />

        <div
          className={classNames(
            "quick-actions-toolbar",
            this.shouldDisplayActionButtons()
              ? "quick-actions-toolbar-shown"
              : "quick-actions-toolbar-hidden",
          )}
        >
          <span className="relative">
            {this.renderCreateAvailabilityButton()}
          </span>

          <span className="relative">{this.renderCreateEventButton()}</span>
        </div>

        <ContactsContainer
          events={formattedEventsWithTemporaryEvents}
        />

        <EnrichedContactsContainer
          events={formattedEventsWithTemporaryEvents}
        />

        {this.renderModal()}
      </div>
    );
  }

  //================
  // RENDER METHODS
  //================

  renderUnavailableBillingMessage() {
    return (
      <div className="weekly-calendar-modal-content">
        <div>
          {"Your billing information is associated with a team or another type of plan. " +
            "Please contact aloha@vimcal.com if this is a mistake."}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginTop: 20,
          }}
        >
          <CustomButton
            buttonType={WHITE_BUTTON}
            onClick={this.closeModal}
            label="OK"
            shouldFocus={true}
          />
        </div>
      </div>
    );
  }

  renderBigCalendar() {
    // minimum props: localizer, events, views, defaultView
    const shouldDisplayResources = this.shouldDisplayResources();

    const {
      anchorTimeZones,
      currentHoverEvent,
      isDarkMode,
      popupEvent,
      hoverPopupEvent,
      currentPreviewedEvent,
      selectedCalendarView,
      selectedDay,
      currentUser,
      temporaryTimeZones,
      format24HourTime,
    } = this.props;

    const {
      calendarMessages,
      resourceMap,
      calendarFormats,
      calendarViews,
      formattedEventsWithTemporaryEvents,
      localizer,
      renderCount,
    } = this.state;

    const {
      masterAccount,
    } = this.props.masterAccount;
    const {
      hoveredEventID,
      hoveredSlotsSlug,
    } = this.props.temporaryStateStore;
    const {
      orderedTimeZones,
    } = this.props.appTimeZone;

    const calendarView = this.determineDefaultCalendarView();
    const {
      OOOBusyEventsColorAndRange,
    } = this.props.OOOBusyEventsDictionaryStore;
    const {
      isSplitView,
    } = this.props.appSettings;
    return (
      <DnDCalendar
        OOOBusyEventsColorAndRange={OOOBusyEventsColorAndRange}
        date={selectedDay ?? getLastSelectedDate()}
        className={this.determineDndClassName(shouldDisplayResources)}
        events={this.shouldHideWorkingLocationEvents() ? this.state.displayEvents: formattedEventsWithTemporaryEvents} // events that should be displayed
        formattedEventsWithTemporaryEvents={formattedEventsWithTemporaryEvents} // to trigger a re-render
        localizer={localizer}
        onEventDrop={this.onEventDrop}
        draggableAccessor={this.determineDraggableAccessor}
        resizableAccessor={this.determineResizableAccessor}
        onEventResize={this.onEventResize}
        view={calendarView}
        inputState={calendarView} // to cause re-render
        onView={this.onViewChange}
        resizable
        toolbar={true}
        step={15}
        timeslots={4}
        formats={calendarFormats}
        views={calendarViews}
        components={CALENDAR_COMPONENTS}
        onSelectEvent={this.determineOnSelectEvent} // clicking on event
        onDoubleClickEvent={this.determineOnDoubleClick}
        onNavigate={this.onNavigation}
        eventPropGetter={this.eventStyleGetter}
        slotPropGetter={this.slotStyleGetter}
        startAccessor="eventStart"
        endAccessor="rbcEventEnd"
        keyAccessor={getEventUniqueKey}
        // endAccessor="eventEnd"
        allDayAccessor={this.isInMonthlyView() ? "allDay" : "displayAsAllDay"}
        getNow={this.addXHours}
        selectable={true}
        onSelectSlot={this.determineOnSelectSlotMethod} // clicking an empty area
        showMultiDayTimes={true}
        selectedCalendarView={selectedCalendarView}
        scrollToTime={this.determineInitialScroll()}
        dayLayoutAlgorithm={this.customDayLayout}
        resourceAccessor="resourceId"
        resources={shouldDisplayResources ? resourceMap : null}
        resourceIdAccessor="resourceId"
        resourceTitleAccessor="resourceTitle"
        messages={calendarMessages}
        onShowMore={this.onClickShowMore}
        currentPreviewedEvent={currentPreviewedEvent} // make sure rbc re-renders to reflect when current preview/hover changes
        currentHoverEvent={currentHoverEvent}
        darkMode={isDarkMode}
        popupEvent={popupEvent}
        hoverPopupEvent={hoverPopupEvent}
        dimPastEvents={shouldDimPastEvents({ masterAccount })}
        anchorTimeZones={anchorTimeZones}
        hoveredEventID={hoveredEventID}
        hoveredSlotsSlug={hoveredSlotsSlug}
        currentUser={currentUser}
        masterAccount={masterAccount}
        temporaryTimeZonesLength={temporaryTimeZones?.length ?? 0}
        renderCount={renderCount}
        orderedTimeZones={orderedTimeZones}
        format24HourTime={format24HourTime}
        isSplitView={isSplitView}
      />
    );
  }

  onMouseOverMonthDate(date, isMouseEnter) {
    this.props.setAgendaDay(date);

    this.setState({ isMouseOverMonthDate: isMouseEnter });
  }

  determineDefaultCalendarView() {
    if (this.isCalendarView(BACKEND_MONTH)) {
      return "month";
    } else if (
      this.props.selectedCalendarView &&
      this.props.selectedCalendarView !== 7
    ) {
      return "week";
    } else {
      if (this.props.shouldOnlyShowWorkWeek) {
        return "work_week";
      } else {
        return "week";
      }
    }
  }

  updateResourceMapping() {
    let resourceMap = [];

    const {
      currentUser,
      eventFormEmails,
      temporaryEvents,
    } = this.props;
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    const {
      masterAccount
    } = this.props.masterAccount;
    const {
      allCalendars,
    } = this.props.allCalendars;

    const allEvents = addDefaultToArray(this.state.formattedEvents).concat(addDefaultToArray(temporaryEvents));
    const execEmails = new Set(getAllExecutives({allLoggedInUsers}).map(user => getUserEmail(user))); // using set is O1 vs On for array
    const isVimcalEAUser = isUserMaestroUser(masterAccount);
    const allResourceIDsUnordered = removeDuplicatesFromArray(
      [...allEvents.map(event => event.resourceId),
        ...addDefaultToArray(eventFormEmails),
        ...addDefaultToArray(getActiveSelectedCalendars(allCalendars).map(calendar => getCalendarEmail(calendar)).filter(email => !isResourceCalendarEmail(email))),
      ],
    );
    const emailToCalendarOrderIndex = getEmailToCalendarOrderIndex({
      emails: allResourceIDsUnordered,
      allCalendars,
    });
    const allResourceIDs = immutablySortArray(allResourceIDsUnordered, (a, b) => {
      if (isSameEmail(a, getUserEmail(currentUser))) {
        // put current user first
        return -1;
      }
      if (isSameEmail(b, getUserEmail(currentUser))) {
        // put current user first
        return 1;
      }

      const displayNameA = this.convertEmailToName(a) + ` (${a})`;
      const displayNameB = this.convertEmailToName(b) + ` (${b})`;
      if (isVimcalEAUser && execEmails.size > 0) {
        const isExecA = execEmails.has(a);
        const isExecB = execEmails.has(b);
        if (isExecA && isExecB) {
          // if both are execs -> we order by calendar order
          if (emailToCalendarOrderIndex[a] < emailToCalendarOrderIndex[b]) {
            return -1;
          }
          if (emailToCalendarOrderIndex[a] > emailToCalendarOrderIndex[b]) {
            return 1;
          }
        }

        if (isExecA && !isExecB) {
          return -1;
        }
        if (!isExecA && isExecB) {
          return 1;
        }
        if (isExecB && isExecA) {
          return displayNameA.localeCompare(displayNameB);
        }
      }
      return displayNameA.localeCompare(displayNameB);
    });

    const allDisplayNames = allResourceIDs.map(resourceId => {
      return this.convertEmailToName(resourceId);
    });
    const isNameDuplicate = (name) => {
      return hasMultipleOccurrencesOfStringInArray(allDisplayNames, name);
    };
    allResourceIDs.forEach((resourceId, index) => {
      const convertedName = this.convertEmailToName(resourceId);
      const displayName = isNameDuplicate(convertedName) ? (convertedName + ` (${resourceId})`) : convertedName;
      resourceMap = resourceMap.concat({
        resourceId,
        displayName,
        resourceTitle: <MainCalendarSplitViewHeader
          displayName={displayName}
          email={resourceId}
          index={index}
        />,
      });
    });

    if (isEmptyArrayOrFalsey(resourceMap) 
      && isEmptyArrayOrFalsey(this.state.resourceMap)
    ) {
      // empty arrays won't equal since they're pointing at different refs
      return;
    }
    if (!this.hasResourceMappingChanged(resourceMap)) {
      // has not updated
      return;
    }

    this.setState({ resourceMap });
  }

  timeRangeFormat(slots, format24HourTime) {
    const { start, end } = slots;

    const formatStyle = getDateTimeFormat(format24HourTime);
    return `${format(start, formatStyle)} - ${format(end, formatStyle)}`;
  }

  determineInitialScroll() {
    // Wacky method since if you go back or forward a week -> it scrolls ot time again
    // Hence why we return undefined. Null scrolls to end of day
    let currentTimeZone = this._timeZone;
    if (
      this._hasTimeZoneChanged ||
      (!this._hasWeeklyCalendarScrolled &&
        isSameWeek(this.props.selectedDay, new Date()))
    ) {
      this._hasWeeklyCalendarScrolled = true;
      this._hasTimeZoneChanged = false;

      if (
        isActionModeUpsertEvent(this.props.actionMode) &&
        this.props.temporaryEvents?.[0]?.eventStart
      ) {
        // scroll to temporary event
        let temporaryEvent = this.props.temporaryEvents[0];
        let scrollToHour = determineScrollToHour(temporaryEvent.eventStart);

        return startOfHour(setHours(new Date(), scrollToHour));
      } else if (currentTimeZone === this.props.defaultBrowserTimeZone) {
        // scroll to current time
        let currentTime = convertToTimeZone(new Date(), {
          timeZone: currentTimeZone || this.getDefaultUserTimeZone(),
        });
        let scrollToHour = determineScrollToHour(currentTime);

        return startOfHour(setHours(new Date(), scrollToHour));
      } else {
        const {
          masterAccount,
        } = this.props.masterAccount;
        // scroll to first overlap time
        const {
          temporaryTimeZones,
          currentUser,
          defaultBrowserTimeZone,
          anchorTimeZones,
        } = this.props;
        let scrollTime = doTemporaryTimeZonesExist(
          temporaryTimeZones,
        )
          ? createAmPmBarWithTemporaryEvents({
            timeZone: currentTimeZone,
            anchorTimeZones,
            temporaryTimeZones,
            defaultBrowserTimeZone,
            defaultTimeZone: this.getLeftHandTimeZone(),
            masterAccount,
            user: currentUser,
          })
          : createAmPmBarWithHourDiff({
            timeZone: currentTimeZone,
            otherTimeZone: defaultBrowserTimeZone,
            masterAccount,
            user: currentUser,
          });

        if (scrollTime?.start < 18) {
          return startOfHour(setHours(new Date(), scrollTime.start));
        }

        return startOfHour(setHours(new Date(), 8));
      }
    } else if (!this._hasWeeklyCalendarScrolled) {
      // for scrolling to different week and refresh
      this._hasWeeklyCalendarScrolled = true;
      let currentTime = convertToTimeZone(new Date(), {
        timeZone: currentTimeZone || this.getDefaultUserTimeZone(),
      });
      let scrollToHour = determineScrollToHour(currentTime);

      return startOfHour(setHours(new Date(), scrollToHour));
    } else {
      return undefined;
    }
  }

  customDayLayout(params) {
    return overlap({ ...params, minimumStartDifference: 15 });
  }

  renderEmpty() {
    return <div></div>;
  }

  // create event and availability buttons
  shouldDisplayActionButtons() {
    return !this.isAppInTaskMode();
  }

  renderCreateEventButton() {
    return (
      <ShortcutHoverHint
        above
        style={createEventHint}
        title={"Create"}
        shortcut={"C"}
      >
        <GlobalKeyMapTile style={createEventKeyMapHint} shortcut={"C"} />

        <button
          ref="button"
          onClick={this.onClickCreateEvent}
          className={classNames(
            "hoverable-button create-event-button opacity-1-important",
            this.state.glowOnButton === ONBOARDING_GLOW_TYPE.CREATE_EVENT_BUTTON
              ? "vimcal-purple-glow" // create-event-attention-animation
              : "",
          )}
          style={availabilityButtonStyle}
        >
          <Plus strokeWidth={1.5} color={"white"} size="24" />
        </button>
      </ShortcutHoverHint>
    );
  }

  renderCreateAvailabilityButton() {
    const onClickAvailability = (e) => {
      this.removGlowOnButton();
      hasStopEventPropagation(e);
      mainCalendarBroadcast.publish(
        SET_GLOBAL_SHORT_CUT_SUGGESTION,
        "A",
        "share availability",
      );
      this.toggleAvailabilityMode();
    };

    return (
      <ShortcutHoverHint
        above
        style={createAvailabilityHint}
        title={"Select availability"}
        shortcut={"A"}
      >
        <GlobalKeyMapTile style={createAvailabilityKeyMapHint} shortcut={"A"} />

        <button
          ref="button"
          className={classNames(
            "hoverable-button",
            "create-availability-button",
            "opacity-1-important",
            this.state.glowOnButton === ONBOARDING_GLOW_TYPE.CREATE_AVAILABILITY_BUTTON
              ? "vimcal-purple-glow" // availability-attention-animationn
              : "",
          )}
          style={availabilityButtonStyle}
          onClick={onClickAvailability}
        >
          <AvailabilityIcon />
        </button>
      </ShortcutHoverHint>
    );
  }

  renderModal() {
    return (
      <EventModalPopup
        isOpen={this.state.shouldDisplayModal}
        onRequestClose={this.determineModalCloseMethod}
        width={this.state.modalWidth}
        title={this.state.modalTitle}
        style={determineDefaultModalStyle(
          this.props.isDarkMode,
        )}
      >
        {this.renderModalContent()}
      </EventModalPopup>
    );
  }

  determineModalCloseMethod() {
    if (this.state.modalArray.length > 0) {
      return this.putBackOriginalEventAndRemoveTemporaryEvent();
    } else {
      return this.closeModal();
    }
  }

  renderError() {
    return <GenericErrorModalContent onClose={this.closeModal} />;
  }

  updateEventWithMultipleAttendees(shouldSendUpdate, message) {
    let e = this.state.eventToBeUpdated;
    let updatedEvent = _.clone(e);

    if (e) {
      if (shouldSendUpdate) {
        updatedEvent["doNotSendUpdate"] = false;
      } else {
        updatedEvent["doNotSendUpdate"] = true;
      }

      if (message) {
        updatedEvent["message"] = message;
        trackEvent({
          category: "email",
          action: "email_attendees_drag_main_calendar",
          label: `attendees_count_${getEventAttendees(e?.event)?.length}`,
          userToken: getUserToken(this.props.currentUser),
        });
      }

      let updatedModal = filterOutModalFromArray(
        this.state.modalArray,
        DRAG_EVENT,
      );

      this.setState(
        {
          modalArray: updatedModal,
          shouldDisplayModal: false,
          eventToBeUpdated: updatedEvent,
        },
        () => this.determineWhichModalToShowBeforeSaving(updatedEvent),
      );
    } else {
      this.closeModal();
    }
  }

  renderModalContent() {
    const { eventToBeUpdated, modalContent } = this.state;

    switch (modalContent) {
      case DRAG_EVENT:
        return (
          <SendEmailUpdateModal
            onClickDismiss={this.putBackOriginalEventAndRemoveTemporaryEvent}
            onClickDoNotSend={() =>
              this.updateEventWithMultipleAttendees(false)
            }
            sendUpdate={(message) =>
              this.updateEventWithMultipleAttendees(true, message)
            }
          />
        );
      case UPDATE_RECURRING_COLOR_MODAL:
        return (
          <WarningUpdateRecurringEvent
            cancel={this.closeModal}
            onClick={this.updateRecurrenceColor}
            withoutThisAndFollowing={this.isColorEventEmailSameAsCreator()}
          />
        );
      case UPDATE_RECURRING_TAGS_MODAL:
        return (
          <WarningUpdateRecurringEvent
            cancel={this.closeModal}
            onClick={(response) => this.updateRecurrenceOnUpdateSingleProperty(response, GOOGLE_UPDATES.NONE)}
            withoutThisAndFollowing={this.isColorEventEmailSameAsCreator()}
          />
        );
      case UPDATE_RECURRING_CATEGORIES_MODAL:
        return (
          <WarningUpdateRecurringEvent
            cancel={this.closeModal}
            onClick={this.updateRecurrenceCategory}
            withoutThisAndFollowing={this.isColorEventEmailSameAsCreator()}
          />
        );
      case UPDATE_SINGLE_PROPERTY_ON_RECURRING_EVENT_MODAL:
        return (
          <WarningUpdateRecurringEvent
            cancel={this.closeModal}
            onClick={this.updateRecurrenceOnUpdateSingleProperty}
            withoutThisAndFollowing={this.isColorEventEmailSameAsCreator()}
          />
        );
      case UPDATE_RECURRING_TRANSPARENCY_MODAL:
        return (
          <WarningUpdateRecurringEvent
            cancel={this.closeModal}
            onClick={this.updateRecurrenceTransparency}
            withoutThisAndFollowing={isOutlookEvent(this.state.updatedRecurringEvent)} // not ready to show this yet
          />
        );
      case UPDATE_RECURRING_EVENT_MODAL:
        return (
          <WarningUpdateRecurringEvent
            cancel={this.putBackOriginalEventAndRemoveTemporaryEvent}
            onClick={this.updateRecurringEventResponse}
            withoutAllInstances={this.shouldHideUpdateRecurringAll()}
            withoutThisAndFollowing={isOutlookNonOrganizer(eventToBeUpdated?.event)}
          />
        );
      case REFER_TO_VIMCAL:
        return <ReferToVimcal closeModal={this.closeModal} />;
      case ERROR:
        return this.renderError();
      case SHOW_DIFFERENT_TIME_ZONE_WARNING:
        return (
          <div className="weekly-calendar-modal-content">
            <div>
              {`We've detected that you're now in a different time zone and have set all your events to ${addAbbrevationToTimeZone({
                timeZone: this.props.defaultBrowserTimeZone,
              })}.`}
            </div>

            <div className="flex items-center mt-6">
              To change your default time zone, click
              <div
                className="cursor-pointer text-color-link ml-1"
                onClick={() => {
                  this.closeModal();
                  layoutBroadcast.publish(APP_SETTINGS.OPEN_SETTINGS_MODAL, { initialContent: APP_SETTINGS.PREFERENCES });
                }}
              >
                here
              </div>
            </div>

            <div className="weekly-calendar-modal-content-button-options">
              <CustomButton
                shouldFocus={true}
                buttonType={BLUE_BUTTON}
                onClick={this.closeModal}
                addPaddingToRight={true}
                label="OK"
              />
            </div>
          </div>
        );
      case BILLING_UNAVAILABLE:
        return this.renderUnavailableBillingMessage();
      default:
        return null;
    }
  }

  //================
  // EVENT HANDLERS
  //================

  returnToDefaultTimeZone() {
    const {
      temporaryTimeZones,
      currentUser,
    } = this.props;
    const leftHandTimeZone = this.getLeftHandTimeZone();
    if (temporaryTimeZones?.length > 1) {
      // only update recent here if there are more than 1 temporary time zones
      let lastTimeZoneGroups = getRecentTimeZoneGroups(currentUser);
      const getTemporaryTimeZones = () => {
        const filtered = removeDuplicatesFromArray(temporaryTimeZones.filter(tz => tz !== leftHandTimeZone));
        return filtered.slice(-4);
      };
      const newTimeZoneGroup = {
        ts: new Date().toISOString(),
        value: getTemporaryTimeZones(),
      };
      let updatedTimeZoneGroups = [newTimeZoneGroup];
      const updatedGroupLabel = createTimeZoneGroupLabel(newTimeZoneGroup);
      if (lastTimeZoneGroups) {
        lastTimeZoneGroups = lastTimeZoneGroups.filter(
          (group) => createTimeZoneGroupLabel(group) !== updatedGroupLabel,
        );
        updatedTimeZoneGroups =
          updatedTimeZoneGroups.concat(lastTimeZoneGroups);
      }

      Broadcast.publish(
        "UPDATE_RECENTLY_SEARCHED_TIME_ZONES",
        updatedTimeZoneGroups,
        true,
      );
    }

    this.setWeeklyCalendarTimeZone({
      timeZone: this.getLeftHandTimeZone(),
      isRemoveTimeZone: true,
    });

    if (this.props.currentTimeZone !== this.props.currentTimeZoneLabel) {
      this._hasWeeklyCalendarScrolled = false;
    }
  }

  getLeftHandTimeZone() {
    const {
      lastSelectedTimeZone,
    } = this.props.appTimeZone;
    const {
      defaultBrowserTimeZone,
      temporaryTimeZones,
    } = this.props;
    return getMostLeftHandTimeZone({
      lastSelectedTimeZone,
      defaultBrowserTimeZone: defaultBrowserTimeZone || this.getDefaultUserTimeZone(),
      temporaryTimeZones,
    });
  }

  removeAllAnchorTimeZones() {
    const {
      resetLastSelectedTimeZone,
    } = this.props.appTimeZone;
    resetLastSelectedTimeZone();
    this.returnToDefaultTimeZone();
    layoutBroadcast.publish("UPDATE_ANCHOR_TIME_ZONES", { timeZones: [], user: this.props.currentUser });
  }

  determineDndClassName(shouldDisplayResources = false) {
    const {
      masterAccount,
    } = this.props.masterAccount;
    const isHidingWeekends = getAccountHideWeekend({ masterAccount });

    return classNames(
      "height-100vh-important",
      {
        "select-on-edit-mode": isActionModeUpsertEvent(this.props.actionMode),
        "dnd-month-view": this.isCalendarView(BACKEND_MONTH),
        "dnd-resource-view": shouldDisplayResources,
        "split-calendar-container": this.isInSplitView() && !this.isCalendarViewDayView(),
        "four-day-view": this.isCalendarView(4),
        "five-day-view": this.isCalendarView(7) && isHidingWeekends,
        "seven-day-view": (this.isCalendarView(7) && !isHidingWeekends) || this.isCalendarView(ROLLING_SEVEN_DAY),
      },
    );
  }

  putBackOriginalEventAndRemoveTemporaryEvent() {
    if (
      this.props.temporaryEvents &&
      this.props.temporaryEvents[0] &&
      getEventUserEventID(this.props.temporaryEvents[0]) ===
      getEventUserEventID(this.state.eventToBeUpdated?.event)
    ) {
      this.addEvent({
        event: this.state.eventToBeUpdated.event,
        removeTemporaryEvent: true,
      });
    }

    this.setState({
      shouldDisplayModal: false,
      eventToBeUpdated: null,
      modalArray: [],
      originalEvent: null,
    });
  }

  controlEventsWithArrowKeys(key) {
    const isTimeInView = (time, start, end) => {
      return isAfterMinute(time, start) && isBeforeMinute(time, end);
    };

    const determineEvent = () => {
      const previewEvent = getPreviewEvent({
        popupEvent: this.props.popupEvent,
        currentPreviewedEvent: this.props.currentPreviewedEvent,
      });

      if (previewEvent) {
        return previewEvent;
      }

      const { start, end } = this.getStartEndDateOfCurrentView();
      const eventsInView = this.getEventsInCurrentView();

      if (this.state.lastSelectedEventId) {
        const lastSelectedEvent = eventsInView.find(
          (e) => getGCalEventId(e) === this.state.lastSelectedEventId,
        );
        if (
          lastSelectedEvent &&
          isTimeInView(lastSelectedEvent.eventStart, start, end)
        ) {
          return lastSelectedEvent;
        }
      } else if (this.state.lastDeclinedEvent) {
        const { lastDeclinedEvent } = this.state;
        if (
          lastDeclinedEvent &&
          isTimeInView(lastDeclinedEvent.eventStart, start, end)
        ) {
          return lastDeclinedEvent;
        }
      }

      if (!isTimeInView(new Date(), start, end)) {
        // today not in view -> select first event after current time
        return getNextUpcomingEvent(eventsInView) || eventsInView[0];
      }

      return null;
    };

    const determineEventList = () => {
      const allUnmergedEvents = this.state.formattedEvents.concat(
        this.getTemporaryMeetWithEventFormAndMimicEvents(),
      );
      const { start, end } = this.getStartEndDateOfCurrentView();

      const determineFilterStartEnd = () => {
        const subOneWeek = subWeeks(start, 1);
        const addOneWeek = addWeeks(end, 1);
        if (key === ARROW_UP || key === ARROW_LEFT) {
          return { filterStart: subOneWeek, filterEnd: end };
        } else {
          return { filterStart: start, filterEnd: addOneWeek };
        }
      };

      const {
        filterStart,
        filterEnd,
      } = determineFilterStartEnd();

      let filteredEvents = [];
      allUnmergedEvents.forEach((e) => {
        if (
          isSameOrBeforeDay(e.eventStart, filterEnd) &&
          isSameOrAfterDay(e.eventEnd, filterStart)
        ) {
          filteredEvents = filteredEvents.concat(e);
        }
      });

      return this.mergeSameEvents({ eventList: filteredEvents, fromWhere: "_controlEventsWithArrowKeys" });
    };

    const event = determineEvent();

    const { newEvent } = moveEventsWithArrowKey({
      currentPreviewedEvent: event,
      formattedEventsWithTemporaryEvents: orderMainCalendarEvents(determineEventList()),
      shouldOnlyShowWorkWeek: this.props.shouldOnlyShowWorkWeek,
      key,
    });

    if (this.state.lastDeclinedEvent) {
      this.setState({
        lastDeclinedEvent: null,
      });
    }
    this.setPreviewEventAndScroll(newEvent);
  }

  setPreviewEventAndScroll(newPreviewedEvent) {
    if (isEmptyObjectOrFalsey(newPreviewedEvent)) {
      return;
    }

    const eventStartDate = newPreviewedEvent.eventStart;

    // Check for if in different week
    if (
      eventStartDate &&
      !this.isDayInCurrentView(eventStartDate, this.props.selectedDay)
    ) {
      let newSelectedDay = this.determineNewSelectedDate(eventStartDate);
      this.props.selectDay(newSelectedDay);
      Broadcast.publish(
        "UPDATE_MONTHLY_CALENDAR_START_OF_MONTH",
        newSelectedDay,
      );
      this.createEventsHotKeysIndexOfCurrentWeek();
    }

    let element = document.getElementById(getEventUserEventID(newPreviewedEvent));

    // Check for if element is out of the page (out of bound)
    if (
      element &&
      element.getBoundingClientRect() &&
      (element.getBoundingClientRect().top < 200 ||
        element.getBoundingClientRect().bottom > window.innerHeight - 20)
    ) {
      this.scrollToTime(newPreviewedEvent.eventStart);
    }

    this.setPreviewEvent(newPreviewedEvent);

    element = null;
  }

  setHoverPreviewEvent(event) {
    if (isEmptyObjectOrFalsey(event)) {
      return;
    }

    if (shouldTruncateRightHandPanel(this.props.hideRightHandSidebar)) {
      this.setHoverPopupEvent(event);
    } else {
      this.props.setCurrentHoverEvent(event);
    }
  }

  setPreviewEvent(event) {
    if (isEmptyObjectOrFalsey(event)) {
      return;
    }

    if (shouldTruncateRightHandPanel(this.props.hideRightHandSidebar)) {
      this.setUpPopup(event);
    } else {
      this.props.setPreviewedEvent(event);
    }
  }

  determineNewSelectedDate(date) {
    // forward or back x day view until the date is in the view
    const daysChange = (view) => {
      const daysDiff = differenceInCalendarDays(date, this.props.selectedDay);
      const daysDiffAbsoluteValue = Math.abs(daysDiff);
      const changeDays = Math.ceil(daysDiffAbsoluteValue / view) * view;
      return daysDiff < 0
        ? subDays(this.props.selectedDay, changeDays)
        : addDays(this.props.selectedDay, changeDays);
    };

    if (this.IsUserCalendarView(BACKEND_DAY_VIEW)) {
      return date;
    } else if (this.IsUserCalendarView(BACKEND_WEEK)) {
      return date;
    } else if (this.IsUserCalendarView(BACKEND_MONTH)) {
      return date;
    } else if (this.IsUserCalendarView(BACKEND_4_DAY_VIEW)) {
      return daysChange(4);
    } else if (this.IsUserCalendarView(ROLLING_SEVEN_DAY)) {
      return daysChange(7);
    }
  }

  IsUserCalendarView(view) {
    const {
      masterAccount,
    } = this.props.masterAccount;
    return getAccountRawCalendarView({ masterAccount }) === view;
  }

  isDayInCurrentView(day) {
    // Need to check day, 4 day, rolling 7 day, week, and month
    if (this.IsUserCalendarView(BACKEND_DAY_VIEW)) {
      return isSameDay(day, this.props.selectedDay);
    } else if (this.IsUserCalendarView(BACKEND_4_DAY_VIEW)) {
      return (
        isSameOrAfterDay(day, this.props.selectedDay) &&
        isSameOrBeforeDay(day, addDays(this.props.selectedDay, 3))
      );
    } else if (this.IsUserCalendarView(ROLLING_SEVEN_DAY)) {
      return (
        isSameOrAfterDay(day, this.props.selectedDay) &&
        isSameOrBeforeDay(day, addDays(this.props.selectedDay, 6))
      );
    } else if (this.IsUserCalendarView(BACKEND_WEEK)) {
      return isSameWeek(day, this.props.selectedDay, {
        weekStartsOn: this.props.weekStart ? parseInt(this.props.weekStart) : 0,
      });
    } else if (this.IsUserCalendarView(BACKEND_MONTH)) {
      return isSameMonth(day, this.props.selectedDay, {
        weekStartsOn: this.props.weekStart ? parseInt(this.props.weekStart) : 0,
      });
    } else {
      return false;
    }
  }

  scrollToTime(time, hardScroll = false) {
    let scrollToHour = determineScrollToHour(time);

    Broadcast.publish(`SCROLL_TO_HOUR_${scrollToHour}`, hardScroll);
  }

  onNavigation(date, view, action) {
    this.props.selectDay(date);
    Broadcast.publish("UPDATE_MONTHLY_CALENDAR_START_OF_MONTH", date);
  }

  onSelectEvent(event) {
    if (isSelectColorPopup(this.props.popupEvent)) {
      this.props.removePopupEvent();
    } else if (!event.isTemporary) {
      batch(() => {
        if (!isInExpandedViewState()) {
          this.props.history.push("/home/expanded");
        }

        if (!isEmptyObjectOrFalsey(this.props.currentHoverEvent)) {
          this.props.removeCurrentHoverEvent();
        }

        blurCalendar();

        if (isTemporaryEvent(event)) {
          return;
        }

        this.setPreviewEvent(event);
      });
    }
  }

  onDoubleClickEvent(event) {
    if (isTemporaryEvent(event) || isAvailabilityEvent(event)) {
      return;
    }
    batch(() => {
      this.props.setPreviewedEvent(event);
      this.removePopUpEvent();
      const {
        actionMode,
      } = this.props;
      const {
        reverseSlotsText,
      } = this.props.temporaryStateStore;
      const {
        allCalendars,
      } = this.props.allCalendars;

      if (isEditable({
        event: event,
        allCalendars,
        actionMode,
        reverseSlotsText,
      })) {
        this.props.setActionMode(ACTION_MODE.UPSERT_EVENT);
      }
    });
  }

  onClickShowMore(props, date) {
    // onclick show more sets the day to onView to "day" (console log onViewChange to see)
    // therefore, we need to explicitly pass back "week" as the view with a update otherwise the calendar is going to think it's still monthly view
    // the this._hasTimeZoneChanged is to trigger auto scroll
    this.changeViewToDayView(date);
  }

  triggerMainCalendarRerender() {
    this.setState({renderCount: this.state.renderCount + 1});
  }

  getMatchingCalendarFromDraggedSlot(slot) {
    const {
      allCalendars,
    } = this.props.allCalendars;
    const {
      currentUser,
    } = this.props;
    const {
      masterAccount,
    } = this.props.masterAccount;
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    const calendarFromEmail = getFirstMatchingEditableCalendarFromEmail({
      allCalendars,
      email: slot?.resourceId ?? getUserEmail(currentUser),
    });
    if (!slot?.resourceId // do not want to enter in here if it's a day view drag with
      && !isCalendarSelected(calendarFromEmail)
    ) {
      const writableCalendars = filterForAllWritableCalendars(allCalendars);
      const bestGuessWritableCalendar = findBestGuessWritableCalendar({
        writableCalendars,
        currentUser,
        masterAccount,
        allLoggedInUsers,
        allCalendars,
      });
      if (bestGuessWritableCalendar) {
        return bestGuessWritableCalendar;
      }
    }
    return calendarFromEmail;
  }

  onSelectTimeSlot(slot) {
    // check if slot email has access to create event
    const { currentUser } = this.props;
    const {
      allLoggedInUsers
    } = this.props.allLoggedInUsers;

    const matchingCalendar = this.getMatchingCalendarFromDraggedSlot(slot);

    if (
      slot.resourceId &&
      !doesCalendarHaveEditableRole(matchingCalendar)
    ) {
      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        "You can not schedule events for this calendar.",
      );
      return;
    }
    if (isEmptyObjectOrFalsey(matchingCalendar)) {
      return;
    }

    let draggedSlot = _.clone(slot);

    const slotEnd = updateToStartOfNextDayIfLastSlot(slot.end);
    draggedSlot.end = slotEnd;

    const rbcEventEnd = determineRBCEventEndWithEventStart(slot.start, slotEnd);

    const accountUserCalendarID = getCalendarUserCalendarID(matchingCalendar);

    const temporaryEvent = createEventFormTemporaryEvent({
      summaryUpdatedWithVisibility: "(No title)",
      isTemporary: true,
      defaultStartTime: formatISO(slot.start),
      defaultEndTime: formatISO(slotEnd),
      eventStart: slot.start,
      eventEnd: slotEnd,
      rbcEventEnd,
      status: temporary,
      raw_json: { status: temporary },
      calendarId: accountUserCalendarID,
      user_calendar_id: accountUserCalendarID,
      resourceId: slot.resourceId || getCalendarEmail(matchingCalendar),
      matchingCalendar, // for the event form
      id: createUUID(),
    });

    const isAllDay = isSelectedSlotAllDayEvent(slot);

    if (this.isCalendarView(BACKEND_MONTH) || isAllDay) {
      temporaryEvent.displayAsAllDay = true;
      temporaryEvent.allDay = true;
    }

    if (isAllDay) {
      // All day event -> do nothing
    } else if (isOnClickSlot(slot) && !isSameMinute(slot.start, slot.end)) {
      const getUser = () => {
        if (!isSameEmail(getUserEmail(currentUser), getCalendarUserEmail(matchingCalendar))) {
          const matchingCalendarUser = getMatchingUserFromAllUsers({ 
            allUsers: allLoggedInUsers, 
            userEmail: getCalendarUserEmail(matchingCalendar),
          });
          if (!isEmptyObjectOrFalsey(matchingCalendarUser)) {
            return matchingCalendarUser;
          }
        }
        return currentUser;
      };

      const { masterAccount } = this.props.masterAccount;
      const defaultMeetingLength = getDefaultMeetingLength({ masterAccount, user: getUser() });
      const newEnd = addMinutes(temporaryEvent.eventStart, defaultMeetingLength);

      temporaryEvent.eventEnd = newEnd;
      temporaryEvent.defaultEndTime = formatISO(newEnd);
      temporaryEvent.rbcEventEnd = newEnd;
      draggedSlot.end = newEnd;
    } else if (differenceInMinutes(slotEnd, slot.start) < 15) {
      // 2 minute bug (can't duplicate)
      const newEnd = addMinutes(temporaryEvent.eventStart, 15);

      temporaryEvent.eventEnd = newEnd;
      draggedSlot.end = newEnd;
    }

    const temporaryEvents =
      this.getTemporaryMeetWithEventFormAndMimicEvents();

    const temporaryEventsWithStaticInformation =
      this.updateEventsStaticInformation(temporaryEvents);

    this.setState(
      {
        formattedEventsWithTemporaryEvents:
          this.filterOutEventsNotInCurrentView(
            this.mergeSameEvents({
              eventList: this.state.formattedEvents.concat(
                temporaryEventsWithStaticInformation,
              ),
              fromWhere: "_onSelectTimeSlot",
            }),
          ),
        shouldRender: true,
      },
      () => {
        if (isActionModeUpsertEvent(this.props.actionMode)) {
          this.props.setTemporaryEvent([temporaryEvent]);
          slot.isMove = true;
          eventFormBroadcast.publish(EVENT_FORM_BROADCAST_VALUES.ON_CLICK_MAIN_CALENDAR_SLOT_WITH_OPEN_EVENT_FORM, slot);
        } else {
          this.props.createNewEventFromSelectSlot(draggedSlot, temporaryEvent);
        }
      },
    );
  }

  setWeeklyCalendarTimeZone({
    timeZone,
    keepSecondaryTimeZone = false,
    isToggleTimeZone = false, // when user click in gutter to change to another one of the anchor time zones
    isRemoveTimeZone = false,
    groupTimeZone = null,
    editingEventProviderId = null,
    doNotConcatTimeZones = false, // prevent user from adding multiple time zones together
    updatedFilteredTemporaryTimeZone,
    keepCurrentTimeZone = false,
    customMessage,
  }) {
    if (!isValidTimeZone(timeZone)) {
      return;
    }
    trackFeatureUsage({
      action: `${FEATURE_TRACKING_ACTIONS.TIME_TRAVEL}::setWeeklyCalendarTimeZone`,
      userToken: getUserToken(this.props.currentUser),
    });

    trackEvent({
      category: "feature_usage_details",
      action: FEATURE_TRACKING_ACTIONS.TIME_TRAVEL,
      label: timeZone,
      userToken: getUserToken(this.props.currentUser),
    });

    const {
      currentTimeZone,
      currentTimeZoneLabel,
    } = this.props;

    const prevTimeZone = _.clone(currentTimeZone);
    const prevTimeZoneLabel = _.clone(currentTimeZoneLabel);

    let newTimeZoneLabel = timeZone;
    if (!keepSecondaryTimeZone && isRemoveTimeZone) {
      newTimeZoneLabel = null;
    }

    const defaultUserTimeZone = this.getDefaultUserTimeZone();

    if (!isToggleTimeZone && newTimeZoneLabel && timeZone !== defaultUserTimeZone) {
      resetGuessTimeZone();
      // check if there are more than 4 time zones
      let temporaryTimeZones = this.props.temporaryTimeZones || [];
      temporaryTimeZones = removeDuplicatesFromArray(
        temporaryTimeZones.concat(timeZone),
      );
      if (temporaryTimeZones.length > 5) {
        Broadcast.publish(
          SET_DISAPPEARING_NOTIFICATION_MESSAGE,
          "You can not set more than 5 temporary time zones.",
        );
        return;
      }
    }

    const {
      setLastSelectedTimeZone,
    } = this.props.appTimeZone;

    const shouldAddTemporaryTimeZone = () => {
      if (isEmptyArrayOrFalsey(this.props.temporaryTimeZones)) {
        return timeZone !== this.props.currentTimeZone;
      }
      return timeZone !== this.getLeftHandTimeZone();
    };

    let updatedTemporaryTimeZones = addDefaultToArray(this.props.temporaryTimeZones);
    batch(() => {
      this._timeZone = timeZone;
      if (!keepCurrentTimeZone) {
        this.props.setTimeZone(timeZone);
      }
      
      if (isToggleTimeZone) {
        // do not add it to temporary if it's just a toggle
        if (!doTemporaryTimeZonesExist(updatedTemporaryTimeZones)) {
          setLastSelectedTimeZone(timeZone);
        }
        if (!isEmptyArrayOrFalsey(updatedFilteredTemporaryTimeZone)) {
          // removed time zone from temporary
          this.props.setTemporaryTimeZones(updatedFilteredTemporaryTimeZone);
        }
      } else {
        if (isEmptyArrayOrFalsey(updatedTemporaryTimeZones)) {
          updatedTemporaryTimeZones = [this.props.currentTimeZone];
        }
        if (!newTimeZoneLabel) {
          // nothing to add
          updatedTemporaryTimeZones = [];
          this.props.setTemporaryTimeZones([]);
        } else if (shouldAddTemporaryTimeZone()) {
          if (groupTimeZone?.length > 0) {
            const updatedTimeZones = filterOutInvalidTimeZones(removeDuplicatesFromArray(
              updatedTemporaryTimeZones.concat(groupTimeZone),
            ));
            this.props.setTemporaryTimeZones(updatedTimeZones);
            updatedTemporaryTimeZones = updatedTimeZones;
          } else if (doNotConcatTimeZones) {
            const updatedTimeZones = [timeZone];
            this.props.setTemporaryTimeZones(updatedTimeZones);
            updatedTemporaryTimeZones = updatedTimeZones;
          } else {
            // adding time zone to temporary
            const updatedTimeZones = filterOutInvalidTimeZones(removeDuplicatesFromArray(
              updatedTemporaryTimeZones.concat(timeZone),
            ));
            this.props.setTemporaryTimeZones(updatedTimeZones);
            updatedTemporaryTimeZones = updatedTimeZones;
          }
        } else if (groupTimeZone?.length > 0) {
          // this will by pass the check above for is same time zone
          // if group includes the same time zone as above then will never show group time zone
          const updatedTimeZones = filterOutInvalidTimeZones(removeDuplicatesFromArray(
            updatedTemporaryTimeZones.concat(groupTimeZone),
          ));
          this.props.setTemporaryTimeZones(updatedTimeZones);
          updatedTemporaryTimeZones = updatedTimeZones;
        }
      }

      if (customMessage) {
        Broadcast.publish(
          SET_DISAPPEARING_NOTIFICATION_MESSAGE,
          customMessage
        );
      } else if (!isReUseSlotsButtonShowing()) {
        Broadcast.publish(
          SET_DISAPPEARING_NOTIFICATION_MESSAGE,
          `All your events are now in ${createAbbreviationForTimeZone(timeZone)}`,
        );
      }

      !keepSecondaryTimeZone &&
        this.props.setCurrentTimeZoneLabel(newTimeZoneLabel);

      if (isToggleTimeZone) {
        // do nothing - do nothing toggle on or off top menu bar
      } else if (updatedTemporaryTimeZones?.length > 0) {
        this.props.setShouldShowTopBar(true);
      } else if (
        isEmptyArrayOrFalsey(updatedTemporaryTimeZones) &&
        isEmptyArrayOrFalsey(this.props.eventFormEmails)
      ) {
        this.props.setShouldShowTopBar(false);
      }
    });

    this.refetchEventsFromDBOnTimeZoneChange({
      editingEventProviderId,
      prevTimeZone,
      timeZone,
      timeZoneLabel: keepSecondaryTimeZone ? prevTimeZoneLabel : newTimeZoneLabel,
      updatedTemporaryTimeZones,
    });

    Broadcast.publish(
      "UPDATE_SECONDARY_CALENDAR_EVENTS_ON_TIME_ZONE_CHANGE",
      prevTimeZone,
      timeZone,
    );

    // Have to unmount and mount to force the current time bar to re-render
    this.updateWeekIfTimeZoneChangesToday(timeZone);
    Broadcast.publish("REFORMAT_AGENDA_INDEX_WITH_NEXT_EVENT", timeZone);
    this.closeModal();
  }

  updateWeekIfTimeZoneChangesToday(timeZone) {
    if (
      isSameDay(
        this.props.selectedDay,
        convertToTimeZone(new Date(), { timeZone: this.props.currentTimeZone }),
      ) &&
      isSameDay(
        this.props.selectedDay,
        convertToTimeZone(new Date(), { timeZone }),
      )
    ) {
      const updatedDay = convertToTimeZone(new Date(), { timeZone });
      this.props.selectDay(updatedDay);
      Broadcast.publish("UPDATE_MONTHLY_CALENDAR_START_OF_MONTH", updatedDay);
    }
  }

  updateSidePanelEvent() {
    if (!isEmptyObjectOrFalsey(this.props.currentPreviewedEvent)) {
      let formattedEvent = this.state.formattedEventsWithTemporaryEvents.filter(
        (event) =>
          getEventUserEventID(event) === getEventUserEventID(this.props.currentPreviewedEvent),
      );

      if (formattedEvent[0]) {
        this.setPreviewEvent(formattedEvent[0]);
      }
    } else if (!isEmptyObjectOrFalsey(this.props.currentHoverEvent)) {
      let formattedEvent = this.state.formattedEventsWithTemporaryEvents.filter(
        (event) =>
          getEventUserEventID(event) === getEventUserEventID(this.props.currentHoverEvent),
      );

      if (formattedEvent[0]) {
        this.props.setCurrentHoverEvent(formattedEvent[0]);
      }
    }
  }

  isColorEventEmailSameAsCreator() {
    return (
      getEventCreator(this.state.updatedRecurringEvent)?.email !==
      getUserEmail(this.props.currentUser)
    );
  }

  onEventResize(e) {
    // bug with rbc:
    // https://github.com/jquense/react-big-calendar/issues/1598
    // if resize on multi-day events, it crashes with invalid date
    let eventClone = _.clone(e);
    if (!isValidJSDate(e.start) || !isValidJSDate(e.end)) {
      return;
    }

    if (differenceInMinutes(e.end, e.start) < 15) {
      eventClone.end = addMinutes(e.start, 15);
    }

    if (e.event.isAvailability) {
      this.onMoveAvailabilityEvent({
        start: eventClone.start,
        end: eventClone.end,
        index: eventClone.event.index,
        event: e.event,
      });
    } else if (isTemporaryEvent(e?.event)) {
      // Push new time to event form
      Broadcast.publish("CHANGE_EDIT_FORM_TIME", eventClone);
    } else if (
      !isInActionMode(this.props.actionMode) &&
      this.getEventStaticInfo(e.event, IS_EDITABLE)
    ) {
      const {
        calculatedDragStart,
        calculatedDragEnd,
      } = getDragStartAndEnd({
        dragStart: e.start,
        dragEnd: e.end,
        event: e.event,
      });
      if (calculatedDragStart) {
        eventClone.start = calculatedDragStart;
      }

      if (calculatedDragEnd) {
        eventClone.end = calculatedDragEnd;
      }

      this.determineModalsBeforeSavingEvent(eventClone);
    }
  }

  isSlotInPast({start, end}) {
    const {
      currentTimeZone,
    } = this.props;
    const currentTimeInCurrentTimeZone = getCurrentTimeInCurrentTimeZone(currentTimeZone);
    return isBeforeMinute(start, currentTimeInCurrentTimeZone) &&
      isBeforeMinute(end, currentTimeInCurrentTimeZone);
  }

  onMoveAvailabilityEvent({
    start,
    end,
    index,
    event,
  }) {
    if (!isBeforeMinute(start, end)) {
      return;
    }
    if (this.isSlotInPast({start, end})) {
      const isGroupVote = isGroupVoteDetailPageOpen();
      if (isGroupVote) {
        Broadcast.publish(
          SET_DISAPPEARING_NOTIFICATION_MESSAGE,
          "The dragged Slot is in the past",
        );
      } else {
        Broadcast.publish(
          SET_DISAPPEARING_NOTIFICATION_MESSAGE,
          "You can not select a Slot in the past.",
        );
      }
      return;
    }

    const updatedEvent = createTemporaryEvent({
      startTime: start,
      endTime: end,
      index: index,
      isGroupVote: isGroupVoteEvent(event),
      resourceId: event.resourceId,
    });

    if (isGroupVoteDetailPageOpen()) {
      groupVoteBroadcast.publish("SET_USER_DRAGGED_SLOTS", {
        originalSlot: event,
        newSlot: updatedEvent,
        type: UPDATE_SLOT,
      });
    } else if (isAvailabilityPanelShowing()) {
      availabilityBroadcast.publish("SET_USER_DRAGGED_SLOTS", {
        originalSlot: event,
        newSlot: updatedEvent,
        type: UPDATE_SLOT,
      });
    }

    const listWithoutChangedEvent = this.props.temporaryEvents
      ? this.props.temporaryEvents.filter((slot) => !isSameSlot(event, slot))
      : [];

    const updatedEventList = [...listWithoutChangedEvent, updatedEvent, ...this.getMeetWithEventFormAndMimicEvents()];

    const temporaryEventWithStaticInformation =
      this.updateEventsStaticInformation(updatedEventList);

    this.setState({
      formattedEventsWithTemporaryEvents: this.filterOutEventsNotInCurrentView(
        this.mergeSameEvents({
          eventList: this.state.formattedEvents.concat(temporaryEventWithStaticInformation),
          fromWhere: "_onMoveAvailabilityEvent",
        }),
      ),
    });

    this.setTemporaryEvents(updatedEventList);
  }

  setTemporaryEvents(events) {
    if (isGroupVoteDetailPageOpen()) {
      availabilityBroadcast.publish(AVAILABILITY_BROADCAST_VALUES.SET_GROUP_VOTE_SLOT, events);
    }

    this.props.setTemporaryEvent(events);
  }

  determineModalsBeforeSavingEvent(e) {
    this.stashUpdatedEventAndCreateModals(e);
  }

  updateRecurringEventResponse(response) {
    let e = this.state.eventToBeUpdated;
    let updatedEvent = _.clone(e);

    if (!e) {
      this.closeModal();

      return;
    }

    let updateRepeatType = response;

    let updatedModal = filterOutModalFromArray(
      this.state.modalArray,
      UPDATE_RECURRING_EVENT_MODAL,
    );
    updatedEvent["updateRepeatType"] = updateRepeatType;

    this.setState(
      {
        modalArray: updatedModal,
        shouldDisplayModal: false,
        eventToBeUpdated: updatedEvent,
      },
      () => {
        this.determineWhichModalToShowBeforeSaving(updatedEvent);
      },
    );
  }

  stashUpdatedEventAndCreateModals(e) {
    this.removeEventAndAddInTemporaryEvent(e);

    let modals = [];
    const { event } = e;

    if (getEventMasterEventID(event)) {
      modals = modals.concat({
        modalTitle: "Edit recurring event",
        modalWidth: 350,
        modalContent: UPDATE_RECURRING_EVENT_MODAL,
        shouldDisplayModal: true,
      });
    }

    const {
      allCalendars,
    } = this.props.allCalendars;
    if (
      DoesEventHaveOtherAttendees(event, allCalendars)
      && isGoogleEvent(event)
    ) {
      modals = modals.concat({
        modalTitle: "Would you like to send update emails to existing guests?",
        modalWidth: 350,
        modalContent: DRAG_EVENT,
        shouldDisplayModal: true,
      });
    }

    if (modals.length > 0) {
      // should be -> determineWhichModalToShow
      this.setState({ modalArray: modals, eventToBeUpdated: e }, () =>
        this.determineWhichModalToShowBeforeSaving(e),
      );
    } else {
      this.determineWhichModalToShowBeforeSaving(e);
    }
  }

  determineWhichModalToShowBeforeSaving(e) {
    if (this.state.modalArray.length > 0) {
      let currentModal = this.state.modalArray[0];

      this.setModalContent(
        currentModal.modalTitle,
        currentModal.modalWidth,
        currentModal.modalContent,
      );

      return;
    }

    if (this.state.shouldDisplayModal) {
      this.closeModal();
    }

    if (e.updateRepeatType === EDIT_RECURRING_FOLLOWING_EVENTS) {
      this.updateFollowingRecurringInstances(e);
    } else if (e.updateRepeatType === EDIT_RECURRING_ALL_INSTANCES) {
      this.updateAllRecurringInstances(e);
    } else {
      // update single event
      const { event, start, end, message, doNotSendUpdate } = e;
      let eventData = this.constructUpdateEventDataV2({ startTime: start, endTime: end, event, message });

      if (message) {
        const emailData = this.constructEmailData({ event, eventStart: start, eventEnd: end });
        eventData = Object.assign({}, emailData, eventData);
      }
      this.updateSingleEvent({
        event,
        updatedProperties: eventData,
        // If doNotSendUpdate is null or undefined, use the default value.
        sendUpdates: (doNotSendUpdate === false) ? GOOGLE_UPDATES.ALL : undefined,
      });
    }
  }

  onEventDrop(e) {
    const { event } = e;

    if (event.isAvailability) {
      this.onMoveAvailabilityEvent({
        start: e.start,
        end: e.end,
        index: event.index,
        event,
      });
    } else if (isTemporaryEvent(event)) {
      let end = e.end;

      if (event.allDay || event.displayAsAllDay) {
        let dayDiff = differenceInDays(
          startOfDay(event.eventEnd),
          startOfDay(event.eventStart),
        );

        if (dayDiff > 0 && !isOutlookEvent(event)) {
          dayDiff = dayDiff - 1;
        }

        end = addDays(e.start, dayDiff);
      }

      Broadcast.publish("CHANGE_EDIT_FORM_TIME", { start: e.start, end: end });
    } else if (this.getEventStaticInfo(event, IS_EDITABLE)) {
      const updatedEventInfo = _.clone(e);

      if (e.event.allDay || e.event.displayAsAllDay) {
        const dayDiff = differenceInDays(
          startOfDay(event.eventEnd),
          startOfDay(event.eventStart),
        );

        updatedEventInfo.end = addDays(e.start, dayDiff);
      } else {
        const { calculatedDragStart, calculatedDragEnd } = getDragStartAndEnd({
          dragStart: e.start,
          dragEnd: e.end,
          event,
        });

        if (calculatedDragStart) {
          updatedEventInfo.start = calculatedDragStart;
        }

        if (calculatedDragEnd) {
          updatedEventInfo.end = calculatedDragEnd;
        }
      }

      this.determineModalsBeforeSavingEvent(updatedEventInfo, true);
    }
  }

  cancelSelectAvailability() {
    batch(() => {
      this.props.setActionMode(null);
      this.props.removePreviewedEvent();
      this.props.removeCurrentHoverEvent();
      this.removeTemporaryEvents();
      this.setTemporaryEvents(null);

      if (!isEmptyObjectOrFalsey(this.props.currentUser.availability_settings)) {
        this.props.setAvailabilitySelectedMinutes(
          this.props.currentUser.availability_settings.duration,
        );
      }

      if (this.props.eventFormEmails?.length > 0) {
        Broadcast.publish("REMOVE_ALL_SELECTED_TEMPORARY_CALENDARS");
      }

      if (this.props.currentTimeZoneLabel) {
        this.returnToDefaultTimeZone();
      }
    });

    mainCalendarBroadcast.publish("REMOVE_GLOBAL_SHORT_CUT_SUGGESTION");
  }

  //=================
  // PRIVATE METHODS
  //=================

  setStartOfTheWeek(weekStart = null) {
    let startOfWeekIntger = weekStart;

    if (isNullOrUndefined(startOfWeekIntger)) {
      startOfWeekIntger = this.props.weekStart || 0;
    }

    this.setState({
      localizer: determineRBCLocalizer(
        parseInt(startOfWeekIntger),
        this.props.dateFieldOrder,
      ),
    });
  }

  showErrorMessage() {
    this.setModalContent("Oops!", 400, ERROR);
  }

  showUnavailableBillingMessage() {
    this.setModalContent(
      "Billing information unavailable",
      490,
      BILLING_UNAVAILABLE,
    );
  }

  getEventStaticInfo(event, key) {
    const { allCalendars } = this.props.allCalendars;
    const {
      currentUser,
    } = this.props;
    const {
      allLoggedInUsers
    } = this.props.allLoggedInUsers;
    const {
      masterAccount,
    } = this.props.masterAccount;

    return getEventStaticInfo({
      event,
      key,
      currentUser,
      currentUserDefaultColor: getCurrentUserDefaultColor({
        currentUserEmail: getUserEmail(currentUser),
        allCalendars,
        allLoggedInUsers,
        masterAccount,
      }),
      allCalendars,
      allLoggedInUsers,
      masterAccount,
    });
  }

  updatedFormattedAndTemporaryEventsWithStaticInformation(
    formattedEvents,
    temporaryEvents,
    calendarIds = {},
  ) {
    const formattedEventsWithStaticInformation =
      this.updateEventsStaticInformation(formattedEvents);

    const temporaryEventsWithStaticInformation =
      this.updateEventsStaticInformation(temporaryEvents);

    const mergedEvents = this.mergeSameEvents({
      eventList: formattedEventsWithStaticInformation.concat(
        temporaryEventsWithStaticInformation,
      ),
      fromWhere: "_updatedFormattedAndTemporaryEventsWithStaticInformation",
    });

    const combinedEvents = this.filterOutEventsNotInCurrentView(mergedEvents);

    /* We check if we need to unmerge events when we have calendarIds */
    const unmergedCombinedEvents = combinedEvents.map((event) => {
      if (event.mergedEvents) {
        /* Check if the calendar ids in the merged events are selected */
        /* We do this by checking if they are in calendarIds (passed in as arg) */
        let shouldUnmerge = false;
        event.mergedEvents.forEach((mergedEvent) => {
          if (isMeetWithEvent(mergedEvent)) {
            // do nothing -> meet with events do not have userCalendarIDs
          } else if (!calendarIds[getEventUserCalendarID(mergedEvent)]) {
            shouldUnmerge = true;
          }
        });

        /* Currently we unmerge if any event is deselected */
        /* Should theoretically unmerge if any of the calendars are unselected */
        /* Currently it if we have 3 calendars and 1 is unselected, the other 2 remain merged (not expected, but it is what we want) */
        /* Need to check more later on */
        if (shouldUnmerge) {
          return _.omit(event, MERGED_EVENTS);
        }
      }
      return event;
    });

    return {
      formattedEventsWithStaticInformation,
      temporaryEventsWithStaticInformation,
      combinedEvents: isEmptyObjectOrFalsey(calendarIds)
        ? combinedEvents
        : unmergedCombinedEvents,
    };
  }

  updateEventsStaticInformation(eventsArray) {
    if (isEmptyArrayOrFalsey(eventsArray)) {
      return [];
    }

    return eventsArray.map((e) => {
      const staticInformation = this.createStaticInformation(e);
      return { ...e, ...staticInformation };
    });
  }

  createStaticInformation(e) {
    const {
      currentUser,
    } = this.props;
    const {
      allCalendars,
    } = this.props.allCalendars;
    const {
      masterAccount,
    } = this.props.masterAccount;
    const { outlookCategories } = this.props.outlookCategoriesStore;
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    return createStaticInformation({
      e,
      currentUser,
      allCalendars,
      where: "mainCalendar",
      outlookCategories,
      allLoggedInUsers,
      masterAccount,
    });
  }

  wipeOutEvents() {
    this.setState({
      formattedEvents: [],
      formattedEventsWithTemporaryEvents: [],
    });
  }

  initializeCalendarEvents(initCalendarInt, inputAllCalendars = null) {
    if (isTestingPreviewOutlook(TEST_PREVIEW_OUTLOOK_LOCATION.MAIN_CALENDAR)) {
      return;
    }
    //Get it from indexDB first
    const { allCalendars } = this.props.allCalendars;

    const currentAllCalendars =
      inputAllCalendars || allCalendars || {};

    const {
      dbWindowStartJSDate,
      dbWindowEndJSDate,
    } = this.createEventsWindow();
    const {
      eventFormEmails,
      currentUser,
    } = this.props;

    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    let originalEventsArray = this.state.formattedEvents;

    let fetchPromises = [];
    let allFetchedEvents = [];

    const filteredUsers = allLoggedInUsers.filter(user => !shouldHideDelegatedUser({user, allCalendars}));
    filteredUsers.forEach((user) => {
      const userActiveCalendars = getActiveCalendarsIDsFromAllCalendars({
        allCalendars: getUserCalendar(currentAllCalendars, getUserEmail(user)),
        currentUserEmail: getUserEmail(currentUser),
        isInitialSync: false, // no reason to sync with this._isInitialSync since on events coming in from initial sync, it's going to get wiped anyway
      });

      const fetchPromise = db
        .fetch(getUserEmail(user))
        .events.where(DEXIE_EVENT_COLUMNS.CALENDAR_ID)
        .startsWithAnyOfIgnoreCase(userActiveCalendars)
        .and((item) => {
          return (
            isDBEventItemWithinWindow({
              item,
              windowStart: dbWindowStartJSDate,
              windowEnd: dbWindowEndJSDate,
            }) &&
            (!eventFormEmails ||
              (eventFormEmails && !stringArrayContainsIgnoringCase(eventFormEmails, item.calendarId)))
          );
        })
        .toArray()
        .then((response) => {
          // initCalendarInt is to make sure we don't update the calendar when a new init calendar has been started
          if (!this._isMounted || this._dbFetchEventsId !== initCalendarInt) {
            return;
          }

          allFetchedEvents = allFetchedEvents.concat(response);
        })
        .catch((err) => {
          handleError(err);
        });
      fetchPromises = fetchPromises.concat(fetchPromise);
    });

    Promise.all(fetchPromises).then(() => {
      if (!this._isMounted) {
        return;
      }
      if (this._isInitialSync) {
        this._isInitialSync = false;
      }
      if (this._dbFetchEventsId !== initCalendarInt) {
        return;
      }

      const calendarEvents = this.filterOutGoogleBirthdayEvents(allFetchedEvents.map((e) => {
        return e.event;
      }));

      this.formatWeeklyCalendarEvents({
        calendarEvents,
        prevPropCurrentTimeZone: null,
        isInitialLoad: true,
        originalFormattedEvents: originalEventsArray,
        inputAllCalendars: currentAllCalendars,
      });
    });
  }

  createEventsHotKeysIndexOfCurrentWeek(events = null) {
    const { setEventHotKeysIndex } = this.props.eventIndexStore;
    const updatedEventIndex = createCurrentWeekEventHotKeys({
      events: events || this.getEventsInCurrentView(),
    });
    setEventHotKeysIndex(updatedEventIndex);
    Broadcast.publish("UPDATE_EVENT_INDEX_HOT_KEYS", updatedEventIndex);
  }

  fetchEventsFromIndexDB(allCalendars = null) {
    this._dbFetchEventsId = createUUID();
    this.initializeCalendarEvents(this._dbFetchEventsId, allCalendars);
  }

  updateTemporaryEventsOnTimeZoneChange(prevTimeZone, newTimeZone) {
    let updatedEvents = [];
    let meetWithEvents = this._meetWithEvents || [];
    let temporaryEvents = this.props.temporaryEvents || [];
    let eventFormEvents = this._eventFormEvents || [];

    if (isAvailabilityPanelShowing()) {
      availabilityBroadcast.publish("UPDATE_USER_DRAGGED_SLOTS_TIME_ZONE", { prevTimeZone, newTimeZone });
    }

    batch(() => {
      if (meetWithEvents?.length > 0) {
        const updatedSidePanelEmailEvents = updateEventsTime({
          events: meetWithEvents,
          prevTimeZone,
          newTimeZone,
        });
        updatedEvents = updatedEvents.concat(updatedSidePanelEmailEvents);
        this._meetWithEvents = updatedSidePanelEmailEvents;
      }

      const {
        mimicEventsList,
      } = this.props;
      // do not use this.getMimicEvents() since getMimicEvents() will filter out events that are not active. We want to update all mimic events
      if (mimicEventsList?.length > 0) {
        const updatedMimicEventsList = updateEventsTime({
          events: mimicEventsList,
          prevTimeZone,
          newTimeZone,
        });
        updatedEvents = updatedEvents.concat(updatedMimicEventsList);
        this.props.setMimicEventsList(updatedMimicEventsList);
      }

      if (temporaryEvents?.length > 0) {
        const updatedTemporaryEvents = updateEventsTime({
          events: temporaryEvents,
          prevTimeZone,
          newTimeZone,
        });
        updatedEvents = updatedEvents.concat(updatedTemporaryEvents);
        this.setTemporaryEvents(updatedTemporaryEvents);
      }

      if (eventFormEvents?.length > 0) {
        const updatedEventFormEvents = updateEventsTime({
          events: eventFormEvents,
          prevTimeZone,
          newTimeZone,
        });
        updatedEvents = updatedEvents.concat(updatedEventFormEvents);

        this._eventFormEvents = updatedEventFormEvents;
      }
    });

    return updatedEvents;
  }

  setWeekStart(weekStart) {
    this.setStartOfTheWeek(weekStart);
    this.remountCalendar();
    this.fetchEventsFromIndexDB();
  }

  refetchEventsFromDBOnTimeZoneChange({
    prevTimeZone,
    timeZone = null,
    timeZoneLabel = null,
    updatedTemporaryTimeZones = null,
    editingEventProviderId = null,
  }) {
    this._hasWeeklyCalendarScrolled = false;
    this._hasTimeZoneChanged = true;

    if (doesArrayContainFindTimeEvent(this.props.temporaryEvents)) {
      // reset find time event on time zone change
      this.props.setTemporaryEvent([]);
    }
    const {
      masterAccount,
    } = this.props.masterAccount;
    const {
      currentUser,
      anchorTimeZones,
    } = this.props;

    this.setState(
      {
        hideBigCalendar: true,
        shadedAvailabiityHours: determineShadedCalendarHours({ // returns {start: 15, end: 19}
          timeZone,
          timeZoneLabel,
          inputDefaultBrowserTimeZone: this.getDefaultUserTimeZone(),
          temporaryTimeZones: updatedTemporaryTimeZones ?? this.props.temporaryTimeZones,
          anchorTimeZones,
          defaultTimeZone: this.getDefaultUserTimeZone(),
          masterAccount,
          user: currentUser,
        }),
        currentTimeIndicator: this.getCurrentTimeIndicatorTime(timeZone),
      },
      () => {
        this.setState({ hideBigCalendar: false });
      },
    );

    const activeCalendarIds = this.getActiveCalendarsIDsFromAllCalendars();

    if (isEmptyArrayOrFalsey(activeCalendarIds)) {
      return;
    }
    const {
      setPreviousTimeZoneOnMainCalendarUpdate,
    } = this.props.temporaryStateStore;
    setPreviousTimeZoneOnMainCalendarUpdate(prevTimeZone);

    const {
      dbWindowStartJSDate,
      dbWindowEndJSDate,
    } = this.createEventsWindow();

    let eventFormEmails = this.props.eventFormEmails;

    this._dbFetchEventsId = createUUID();
    const lastDbFetchEventsId = this._dbFetchEventsId;

    let originalEventsArray = this.state.formattedEvents;

    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const {
      allCalendars,
    } = this.props.allCalendars;

    let fetchPromises = [];
    let allFetchedEvents = [];
    const filteredUsers = allLoggedInUsers.filter(user => !shouldHideDelegatedUser({user, allCalendars}));
    filteredUsers.forEach((user) => {
      const fetchPromise = db
        .fetch(getUserEmail(user))
        .events.where(DEXIE_EVENT_COLUMNS.CALENDAR_ID)
        .startsWithAnyOfIgnoreCase(activeCalendarIds)
        .and((item) => {
          return (
            isDBEventItemWithinWindow({
              item,
              windowStart: dbWindowStartJSDate,
              windowEnd: dbWindowEndJSDate,
            }) &&
            (!eventFormEmails ||
              (eventFormEmails && !stringArrayContainsIgnoringCase(eventFormEmails, item.calendarId)))
          );
        })
        .toArray()
        .then((response) => {
          if (
            !this._isMounted ||
            this._dbFetchEventsId !== lastDbFetchEventsId
          ) {
            return;
          }

          allFetchedEvents = allFetchedEvents.concat(response);
        })
        .catch((err) => {
          handleError(err);
        });
      fetchPromises = fetchPromises.concat(fetchPromise);
    });

    Promise.all(fetchPromises).then(() => {
      setPreviousTimeZoneOnMainCalendarUpdate(null);
      if (!this._isMounted) {
        return;
      }
      
      if (this._dbFetchEventsId !== lastDbFetchEventsId) {
        return;
      }

      let calendarEvents = allFetchedEvents.map((e) => {
        return e.event;
      });

      // Prevent bug where changing time zones refresh causes old event to show
      // Only when editing an event
      if (editingEventProviderId) {
        calendarEvents = calendarEvents.filter(e => getGCalEventId(e) !== editingEventProviderId);
      }
      calendarEvents = this.filterOutGoogleBirthdayEvents(calendarEvents);

      this.formatWeeklyCalendarEvents({
        calendarEvents,
        prevPropCurrentTimeZone: prevTimeZone,
        originalFormattedEvents: originalEventsArray,
      });
    });
  }

  getActiveCalendarsIDsFromAllCalendars() {
    const {
      allCalendars,
    } = this.props.allCalendars;
    const {
      currentUser,
    } = this.props;
    return getActiveCalendarsIDsFromAllCalendars({
      allCalendars,
      currentUserEmail: getUserEmail(currentUser),
    });
  }

  getActiveCalendarIdsMapping() {
    // reproduces:
    // {
    //   "3c445d5932092548051c2a8b0c0c70f6": false,
    //   "8ea720689c01e586295a7f00ac8ce29d": true,
    // }
    const activeCalendarIds = {};
    const {
      allCalendars,
    } = this.props.allCalendars;
    Object.keys(allCalendars).forEach((key) => {
      activeCalendarIds[key] = isCalendarSelected(allCalendars[key]);
    });
    return activeCalendarIds;
  }

  filterOutActiveCalendars(events) {
    if (isEmptyArrayOrFalsey(events)) {
      return [];
    }
    // filter out active events
    const activeCalendarIds = new Set(this.getActiveCalendarsIDsFromAllCalendars());

    return events.filter(
      (e) => !activeCalendarIds.has(getEventUserCalendarID(e) || e.calendarId),
    );
  }

  filterEventsOnActiveCalendars(events) {
    const activeCalendarIds = new Set(this.getActiveCalendarsIDsFromAllCalendars());
    return events.filter(
      (e) => activeCalendarIds.has(getEventUserCalendarID(e) || e.calendarId),
    );
  }

  getMeetWithEventFormAndMimicEvents() {
    const meetWithEvents = this.filterOutActiveCalendars(this._meetWithEvents);
    const eventFormEvents = this.filterOutActiveCalendars(this._eventFormEvents);
    const mimicEvents = this.getMimicEvents();
    return [...meetWithEvents, ...eventFormEvents, ...mimicEvents];
  }

  getTemporaryMeetWithEventFormAndMimicEvents() {
    return [...this.getTemporaryEvents(), ...this.getMeetWithEventFormAndMimicEvents()];
  }

  setUpdatedTemporaryEvents() {
    const updatedTemporaryEvents = this.props.temporaryEvents;
    const allTemporaryEvents = this.getTemporaryMeetWithEventFormAndMimicEvents();

    const temporaryEventsWithStaticInformation =
      this.updateEventsStaticInformation(allTemporaryEvents);

    if (
      isActionModeUpsertEvent(this.props.actionMode) &&
      updatedTemporaryEvents?.length === 1 &&
      getEventUserEventID(updatedTemporaryEvents[0]) &&
      this.state.formattedEvents.some(event => getEventUserEventID(event) === getEventUserEventID(updatedTemporaryEvents[0]))
    ) {
      // original event that's a temporary event still exist in weekly calendar
      const updatedEvent = updatedTemporaryEvents[0];

      this.removeEvent({
        deleteEvent: updatedEvent,
        addTemporaryEvent: updatedEvent,
      });
    } else {
      this.setState({
        formattedEventsWithTemporaryEvents:
          this.filterOutEventsNotInCurrentView(
            this.mergeSameEvents({
              eventList: this.state.formattedEvents.concat(
                temporaryEventsWithStaticInformation,
              ),
              fromWhere: "_setUpdatedTemporaryEvents",
            }),
          ),
      });
    }
  }

  formatWeeklyCalendarEvents({
    calendarEvents,
    prevPropCurrentTimeZone,
    isInitialLoad = false,
    originalFormattedEvents,
    inputAllCalendars,
  }) {
    const {
      allCalendars,
    } = this.props.allCalendars;
    const {
      currentTimeZone,
      showDeclinedEvents,
    } = this.props;
    let { events } = formatEventsList({
      calendarEvents,
      currentTimeZone,
      currentUserEmail: getUserEmail(this.props.currentUser),
      showDeclinedEvents,
      allCalendars: inputAllCalendars ?? allCalendars,
    });
    const currentFormattedEvents = this.state.formattedEvents;

    const originalEventsEtag = getEventsEtag(originalFormattedEvents);
    const currentEventsEtag = getEventsEtag(currentFormattedEvents);
    const hasAnyEventsBeenUpdated = !arraysAreEqual(originalEventsEtag, currentEventsEtag);
    if (
      hasAnyEventsBeenUpdated
    ) {
      // 1) Get events that have changed
      // 2) only update if update time is more recent
      // 3) format those new events
      const diffEvents = getDiffEvents(
        originalFormattedEvents,
        currentFormattedEvents,
      );
      events = this.updateEventsList(events, diffEvents);
    }

    let temporaryEvents;
    if (isInitialLoad) {
      temporaryEvents = this.getTemporaryMeetWithEventFormAndMimicEvents();
    } else {
      temporaryEvents = this.updateTemporaryEventsOnTimeZoneChange(
        prevPropCurrentTimeZone,
        this.props.currentTimeZone,
      );
    }

    const temporaryEventsIdArray = temporaryEvents
      ? temporaryEvents.map((e) => e.uniqueEtag)
      : [];

    // filter out for events that are currently updating
    events = this.filterOutCurrentlyUpdatingEvents(events);

    const updatedEvents =
      temporaryEventsIdArray.length > 0
        ? events.filter((e) => !temporaryEventsIdArray.includes(e.uniqueEtag))
        : events;

    const { formattedEventsWithStaticInformation, combinedEvents } =
      this.updatedFormattedAndTemporaryEventsWithStaticInformation(
        updatedEvents,
        temporaryEvents,
      );

    this.setState(
      {
        formattedEvents: formattedEventsWithStaticInformation,
        formattedEventsWithTemporaryEvents: combinedEvents,
      },
      () => {
        if (isInitialLoad) {
          return;
        }

        this.updateSidePanelEvent();
        this.createEventsHotKeysIndexOfCurrentWeek();
      },
    );
  }

  updateEventsList(originalEventsList = [], updatedEventsList) {
    if (isEmptyArrayOrFalsey(updatedEventsList)) {
      return originalEventsList || [];
    }

    if (
      (isEmptyArrayOrFalsey(originalEventsList)) &&
      updatedEventsList
    ) {
      return updatedEventsList;
    }

    let eventsIndex = originalEventsList.map((e) => getEventUserEventID(e));

    let newList = originalEventsList;

    updatedEventsList.forEach((e) => {
      const index = eventsIndex.indexOf(getEventUserEventID(e));

      if (index >= 0) {
        // Only update if new event was updated after
        if (hasEventBeenUpdated(newList[index], e)) {
          const formattedE = FormatIntoJSDate(e, this.props.currentTimeZone);
          newList[index] = formattedE;
        }
      } else {
        // event doesn't exist in newList yet
        const formattedE = FormatIntoJSDate(e, this.props.currentTimeZone);

        newList = newList.concat(formattedE);
        eventsIndex = eventsIndex.concat(getEventUserEventID(formattedE));
      }
    });

    return newList;
  }

  determineOnSelectEvent(event) {
    if (isOutstandingSlotEvent(event)) {
      return;
    }

    if (isFindTimeEventFormEvent(event)) {
      const {
        resetHoveredEventID,
      } = this.props.temporaryStateStore;
      resetHoveredEventID();

      this.onSelectTimeSlot({
        start: event.eventStart,
        end: event.eventEnd,
      }); // create event
      return;
    }

    if (isTemporaryAIEvent(event)) {
      const {
        completionID,
      } = this.props.temporaryStateStore;
      backendBroadcasts.publish("SEND_LLM_FEEDBACK", { completionID, quality: 1 });
      layoutBroadcast.publish("RESET_REVERSED_SLOTS_TEXT", false);
      this.onSelectTimeSlot({ start: event.eventStart, end: event.eventEnd });
    } else if (shouldTruncateRightHandPanel(this.props.hideRightHandSidebar)) {
      this.setUpPopup(event);
    } else if (!this.isAppInTaskMode()) {
      this.onSelectEvent(event);
    } else {
      if (isTemporaryEvent(event)) {
        return;
      }

      this.setUpPopup(event);
    }
  }

  isAppInTaskMode() {
    const {
      actionMode,
    } = this.props;
    const {
      reverseSlotsText,
    } = this.props.temporaryStateStore;
    return isAppInTaskMode({
      actionMode,
      reverseSlotsText,
    });
  }

  onViewChange(view) {
    // Need this function so react big calendar doesn't throw error
  }

  setHoverPopupEvent(event) {
    const eventClientRect = getEventClientRect(event);
    batch(() => {
      if (eventClientRect) {
        this.props.setHoverPopupEvent({
          location: eventClientRect,
          event: event,
        });
      }
    });
  }

  setUpPopup(event) {
    const eventClientRect = getEventClientRect(event);
    batch(() => {
      if (eventClientRect) {
        this.props.setPopupView({
          location: eventClientRect,
          event: event,
        });
      }

      if (this.props.hoverPopupEvent) {
        this.props.removeHoverPopupEvent();
      }
    });
  }

  determineOnDoubleClick(event) {
    if (isFindTimeEventFormEvent(event)) {
      return;
    }
    if (isTemporaryEvent(event)) {
      return;
    }
    if (isOutstandingSlotEvent(event)) {
      return;
    }
    if (isActionModeUpsertEvent(this.props.actionMode)) {
      this.setUpPopup(event);
    } else {
      if (this.getEventStaticInfo(event, IS_EDITABLE)) {
        this.onDoubleClickEvent(event);
      } else {
        this.warnCanNotEditEvent();
      }
    }
  }

  warnCanNotEditEvent() {
    Broadcast.publish(
      SET_DISAPPEARING_NOTIFICATION_MESSAGE,
      CAN_NOT_UPDATE_EVENT_MESSAGE,
    );
  }

  isEditGroupVoteEvent(event) {
    return (
      isAvailabilityEvent(event) &&
      isGroupVoteEvent(event) &&
      event?.isTemporary &&
      !event?.isNew
    );
  }

  determineDraggableAccessor(event) {
    if (isEventHiddenEvent(event)) {
      return false;
    }
    if (this.isEditGroupVoteEvent(event)) {
      return false;
    }
    if (isFakeMimicEvent(event)) {
      return false;
    }

    if (isWorkplaceEvent(event)) {
      return false;
    }

    if (isTemporaryAIEvent(event)) {
      return false;
    }

    if (isOutstandingSlotEvent(event)) {
      return false;
    }

    if (isGroupVoteEvent(event)) {
      return true;
    }

    if (this.isCalendarView(BACKEND_MONTH)) {
      return false;
    }

    if (isActionModeUpsertEvent(this.props.actionMode)) {
      return isTemporaryEvent(event);
    }

    if (isActionModeCreateAvailability(this.props.actionMode)) {
      return event.isAvailability;
    }

    return this.getEventStaticInfo(event, IS_EDITABLE);
  }

  determineResizableAccessor(event) {
    if (isEventHiddenEvent(event)) {
      return false;
    }
    if (isWorkplaceEvent(event)) {
      return false;
    }
    if (isFakeMimicEvent(event)) {
      return false;
    }
    if (isTemporaryAIEvent(event)) {
      return false;
    }

    if (isOutstandingSlotEvent(event)) {
      return false;
    }

    if (this.isCalendarView(BACKEND_MONTH)) {
      return false;
    }

    if (event.allDay || event.displayAsAllDay) {
      return false;
    }

    if (isExpandedMultiDayEvent(event)) {
      // can not resize until bug is fixed
      // https://github.com/jquense/react-big-calendar/issues/1598
      return false;
    }

    return true;
  }

  onClickCreateEvent(e) {
    this.removGlowOnButton();
    hasStopEventPropagation(e);
    if (isEmptyObjectOrFalsey(this.props.allCalendars.allCalendars)) {
      return;
    }

    mainCalendarBroadcast.publish(
      SET_GLOBAL_SHORT_CUT_SUGGESTION,
      "C",
      "create an event",
    );
    batch(() => {
      this.removePreviewEvent();
      this.props.setActionMode(ACTION_MODE.UPSERT_EVENT);
    });
  }

  replaceOutlookEventWithFullEvent(event) {
    if (isEmptyObjectOrFalsey(event)) {
      return;
    }
    const {
      formattedEvents,
    } = this.state;
    if (!formattedEvents.some(event => isPreviewOutlookEvent(event))) {
      return;
    }
    const eventID = getEventID(event);
    const updatedEventsList = formattedEvents
      .filter(e => getEventID(e) !== eventID)
      .concat(event);
    this.setEventsInMainCalendar(updatedEventsList);
  }

  addOutlookPreviewEvent({
    previewEvents,
  }) {
    if (isEmptyArrayOrFalsey(previewEvents)) {
      return;
    }
    const {
      formattedEvents,
    } = this.state;
    const filteredPreviewEvents = this.filterOutCurrentlyUpdatingEvents(previewEvents);
    const updatedEventsList = mergeInPreviewOutlookEvents({
      formattedEvents,
      previewOutlookEvents: filteredPreviewEvents,
    });
    this.setEventsInMainCalendar(updatedEventsList);
  }

  wipeOutEventsAndAddEvents({
    eventList,
    calendarIds = this.getActiveCalendarIdsMapping(),
    lastSyncAtUTC,
    deleteEventUserEmails, // only remove events with matching userEmail
    windowForRemovingEvents, // {timeMin, timeMax} -> keep events if out of this window
  }) {
    if (!eventList) {
      // error check
      return;
    }

    const shouldSkipFilter = (e) => {
      return e?.isTemporary || isMeetWithEvent(e);
    };

    const filterEventsForActiveCalendars = (events) => {
      return events.filter(
        (e) => {
          if (isShowAsBirthdayEvent(e)) {
            return false;
          }
          // note order of operation matters here
          if (this.isPreviousDeletedVersionOfEvent(e)) {
            return false;
          }
          if (calendarIds[getEventUserCalendarID(e)]) {
            return true;
          }
          if (shouldSkipFilter(e)) {
            return true;
          }
          return false;
        },
      );
    };
    const filteredEventsList = this.filterOutCurrentlyUpdatingEvents(filterEventsForActiveCalendars(eventList));

    // events that should not be overridden
    let existingEventsThatShouldBeKept = [];
    this.state.formattedEvents.forEach(event => {
      const isUpdatedAfterUpdate = lastSyncAtUTC && getEventUpdatedAt(event) > lastSyncAtUTC;
      const isNotPartOfDeletedEmail = deleteEventUserEmails?.length > 0
        && !stringArrayContainsIgnoringCase(deleteEventUserEmails, getEventUserEmail(event));
      const isOutsideOfWindow = windowForRemovingEvents?.timeMin
        && windowForRemovingEvents?.timeMax
        && !isEventWithinExactTimeRange({
          event,
          timeRangeStart: windowForRemovingEvents.timeMin,
          timeRangeEnd: windowForRemovingEvents.timeMax,
        });
      if (isUpdatedAfterUpdate || isNotPartOfDeletedEmail || isOutsideOfWindow) {
        existingEventsThatShouldBeKept = existingEventsThatShouldBeKept.concat(event);
      }
    });
    this.enrichCompaniesForEvents(filteredEventsList);

    // split out event
    const userCalendarIDList = getAllUserCalendarIDsFromEventsList(filteredEventsList);

    // only delete events whos userCalendarID came in filteredEventsList
    const filteredCurrentEventsList = this.state.formattedEvents.filter(
      (e) => !userCalendarIDList.includes(getEventUserCalendarID(e)),
    );

    // To prevent collision from initializeCalendarEvents
    this._dbFetchEventsId = createUUID();

    const updatedAtIndex = {}; // keeps track of which event has already been updated
    this.state.formattedEvents.forEach((e) => {
      // updated_at is in "2022-11-17T16:29:01.504Z" format so we can just use simple string comparison
      updatedAtIndex[getEventUserEventID(e)] = getEventUpdatedAt(e);
    });

    const mimicDeletedEvents = new Set(this._mimicDeletedUserEventIDs); // mimic deleted event slash events in mid flights
    const {
      showDeclinedEvents,
    } = this.props;

    let filteredEvents = filteredEventsList.filter((event) => {
      let email = this.getEmailFromEvent(event);
      const userEventID = getEventUserEventID(event);

      const isSameAsTemporaryEvent = this.isSameAsTemporaryEvent(event);

      if (isActionModeUpsertEvent(this.props.actionMode) && isSameAsTemporaryEvent) {
        // event has been updated so if we exit event form -> takes latest event
        Broadcast.publish("UPDATE_EVENT_FORM_ORIGINAL_EVENT", event);
        return false;
      } else if (isSameAsTemporaryEvent && this.getFirstTemporaryEvent() && isFakeMimicEvent(this.getFirstTemporaryEvent())) {
        // always take temporary event
        // event is in the process of updating so don't want to wipe over it
        return false;
      } else if (updatedAtIndex[userEventID] && (!getEventUpdatedAt(event) || updatedAtIndex[userEventID] > getEventUpdatedAt(event))) {
        // the updated at either does not exist or is older than the event in the list
        return false;
      } else if (mimicDeletedEvents.has(userEventID)) {
        return false;
      }

      return shouldDisplayEvent({
        showDeclinedEvents,
        event,
        email,
      }) && !isSameAsTemporaryEvent;
    }).concat(filteredCurrentEventsList);

    if (existingEventsThatShouldBeKept.length > 0) {
      // add in events that was upserted while we were waiting for the response
      const existingEventsThatShouldBeKeptUserEventID = existingEventsThatShouldBeKept.map((e) => getEventUserEventID(e));
      filteredEvents = filteredEvents
        .filter((e) => {
          if (existingEventsThatShouldBeKeptUserEventID.includes(getEventUserEventID(e))) {
            const matchingEvent = existingEventsThatShouldBeKept.find((existingEvent) => getEventUserEventID(existingEvent) === getEventUserEventID(e));
            if (matchingEvent && getEventUpdatedAt(e) > getEventUpdatedAt(matchingEvent)) {
              // the event in the filteredEventsList is more updated -> remove the event from existingEventsThatShouldBeKept
              existingEventsThatShouldBeKept = existingEventsThatShouldBeKept.filter(e => getEventUserEventID(e) !== getEventUserEventID(matchingEvent));
              return true;
            } else {
              // use the existingEventsThatShouldBeKept event
              return false;
            }
          }

          return true;
        });

      if (existingEventsThatShouldBeKept.length > 0) {
        // if there are still events left in existingEventsThatShouldBeKept, then we need to add them to the list
        filteredEvents = filteredEvents.concat(existingEventsThatShouldBeKept);
      }
    }

    // add temporary events
    const allTemporaryEvents =
      this.getTemporaryMeetWithEventFormAndMimicEvents();

    const { formattedEventsWithStaticInformation, combinedEvents } =
      this.updatedFormattedAndTemporaryEventsWithStaticInformation(
        filteredEvents,
        allTemporaryEvents,
        calendarIds,
      );

    /* Set filtered vars to destructed values from above */
    let filteredFormattedEventsWithStaticInformation =
      formattedEventsWithStaticInformation;
    let filteredCombinedEvents = combinedEvents;

    /* If we have calendar ids, we filter */
    if (!isEmptyObjectOrFalsey(calendarIds)) {
      /* Filter events for those whose selected override are true (in calendarIds object) */
      filteredFormattedEventsWithStaticInformation = filterEventsForActiveCalendars(formattedEventsWithStaticInformation);

      /* Filter events for those whose selected override are true (in calendarIds object) */
      filteredCombinedEvents = filterEventsForActiveCalendars(combinedEvents);
    }

    // add deleted events to map
    const cancelledEvents = eventList.filter(e => isCancelledEvent(e));
    this.updateLastUpdatedAtForDeletedEvents(cancelledEvents);

    this.setState({
      formattedEvents: filteredFormattedEventsWithStaticInformation,
      formattedEventsWithTemporaryEvents: filteredCombinedEvents,
    });
  }

  // updateListOfEvents includes handling deletion from webhooks for google events.
  // deletion on outlook webhooks is handled by this.removeMultipleEvents
  updateListOfEvents({
    listOfEvents,
    shouldRemoveTemporaryEvents = false,
  }) {
    let temporaryEvents = (shouldRemoveTemporaryEvents && this.props.temporaryEvents?.length > 0)
      ? this.removeTemporaryEvents(true)
      : this.getTemporaryMeetWithEventFormAndMimicEvents();

    const inputEvents = this.filterOutGoogleBirthdayEvents(addDefaultToArray(listOfEvents));
    this.enrichCompaniesForEvents(inputEvents);
    const mimicDeletedEvents = new Set(this._mimicDeletedUserEventIDs); // mimic deleted event slash events in mid flights
    const filteredListOfEvents = this.filterOutCurrentlyUpdatingEvents(inputEvents.filter((event) => {
      if (this.isPreviousDeletedVersionOfEvent(event)) {
        return false;
      }
      const userEventID = getEventUserEventID(event);
      return !mimicDeletedEvents.has(userEventID);
    }));

    const {
      mimicEventsList,
    } = this.props;
    if (!isEmptyArrayOrFalsey(mimicEventsList) && !isEmptyArrayOrFalsey(filteredListOfEvents)) {
      const userEventIDsSet = new Set(filteredListOfEvents.map(e => getEventUserEventID(e)));
      const filteredMimicEvents = mimicEventsList.filter(e => !userEventIDsSet.has(getEventUserEventID(e)));
      if (mimicEventsList.length !== filteredMimicEvents.length) {
        // remove mimic events that are in the userEventIDs list
        this.props.setMimicEventsList(filteredMimicEvents);
      }
    }

    const {
      currentPreviewedEvent,
      currentHoverEvent,
      showDeclinedEvents,
      currentUser,
    } = this.props;
    const {
      allCalendars,
    } = this.props.allCalendars;
    const {
      updatedCurrentPreviewEvent,
      updatedCurrentHoverEvent,
      updatedEventsArray,
      eventFormOriginalEvent,
    } = updateListOfEvents({
      listOfEvents: filteredListOfEvents,
      currentEventsArray: this.state.formattedEvents,
      currentPreviewedEvent,
      currentHoverEvent,
      showDeclinedEvents,
      currentUserEmail: getUserEmail(currentUser),
      temporaryEvents: this.props.temporaryEvents,
      allCalendars,
    });

    clearEventStyleCache();

    if (isActionModeUpsertEvent(this.props.actionMode) && eventFormOriginalEvent) {
      Broadcast.publish(
        "UPDATE_EVENT_FORM_ORIGINAL_EVENT",
        eventFormOriginalEvent,
      );
    } else if (
      updatedCurrentPreviewEvent &&
      !isEmptyObjectOrFalsey(this.props.currentPreviewedEvent)
    ) {
      const eventCalendarEmail = this.getEmailFromEvent(
        updatedCurrentPreviewEvent,
        true,
      );

      if (
        getEventUserEventID(this.props.currentPreviewedEvent) !==
        getEventUserEventID(updatedCurrentPreviewEvent)
      ) {
        // Do nothing -> event changed since update
      } else if (
        !shouldDisplayEvent({
          showDeclinedEvents: this.props.showDeclinedEvents,
          event: updatedCurrentPreviewEvent,
          email: eventCalendarEmail,
        })
      ) {
        // current preview event has been deleted
        batch(() => {
          this.props.setPreviewedEvent(null);
          this.props.removePopupEvent();
          this.props.history.push("/home");
        });
      } else if (
        updatedCurrentPreviewEvent.uniqueEtag !==
        this.props.currentPreviewedEvent.uniqueEtag
      ) {
        this.setPreviewEvent(updatedCurrentPreviewEvent);
      }
    } else if (
      updatedCurrentHoverEvent &&
      !isEmptyObjectOrFalsey(this.props.currentHoverEvent)
    ) {
      const eventCalendarEmail = this.getEmailFromEvent(
        updatedCurrentHoverEvent,
        true,
      );

      if (
        getEventUserEventID(this.props.currentHoverEvent) !==
        getEventUserEventID(updatedCurrentHoverEvent)
      ) {
        // Do nothing -> event changed since update
      } else if (
        !shouldDisplayEvent({
          showDeclinedEvents: this.props.showDeclinedEvents,
          event: updatedCurrentHoverEvent,
          email: eventCalendarEmail,
        })
      ) {
        this.props.setCurrentHoverEvent(null);
      } else if (
        updatedCurrentHoverEvent.uniqueEtag !==
        this.props.currentHoverEvent.uniqueEtag
      ) {
        this.props.setCurrentHoverEvent(updatedCurrentHoverEvent);
      }
    }

    const temporaryEventsIdArray = temporaryEvents
      ? temporaryEvents.map((e) => getEventUserEventID(e))
      : [];
    let updatedEvents = updatedEventsArray;
    if (
      temporaryEventsIdArray?.length > 0 &&
      updatedEventsArray?.length > 0
    ) {
      updatedEvents = updatedEventsArray.filter(
        (e) => e && !temporaryEventsIdArray.includes(getEventUserEventID(e)),
      );
    }

    const hasPreviewOutlookEvents = updatedEvents.some(e => isPreviewOutlookEvent(e));
    if (hasPreviewOutlookEvents) {
      updatedEvents = removeMatchingPreviewEvents({
        formattedEvents: updatedEvents,
        updatedEvents: listOfEvents,
      });
    }

    const { formattedEventsWithStaticInformation, combinedEvents } =
      this.updatedFormattedAndTemporaryEventsWithStaticInformation(
        updatedEvents,
        temporaryEvents,
      );

    const cancelledEvents = addDefaultToArray(listOfEvents).filter(e => isCancelledEvent(e));
    this.updateLastUpdatedAtForDeletedEvents(cancelledEvents);

    this.setState({
      formattedEvents: formattedEventsWithStaticInformation,
      formattedEventsWithTemporaryEvents: combinedEvents,
    });
  }

  filterForEventsInWeek(
    e,
    todayLeftWindow,
    todayRightWindow,
    leftWindowSelectedDay,
    rightWindowSelectedDay,
  ) {
    return (
      (e.epochUnixWeek >= leftWindowSelectedDay &&
        e.epochUnixWeek <= rightWindowSelectedDay) ||
      (e.epochUnixWeek >= todayLeftWindow &&
        e.epochUnixWeek <= todayRightWindow)
    );
  }

  remountCalendar() {
    this._hasWeeklyCalendarScrolled = false;

    this.setState({ hideBigCalendar: true }, () => {
      this.setState({ hideBigCalendar: false });
    });
  }

  removeUnSelectedTimeZone(updatedTimeZone) {
    // this.setState({shadedAvailabiityHours: this.props.defaultBrowserTimeZone});
    if (isEmptyArrayOrFalsey(updatedTimeZone)) {
      return;
    }
    const {
      masterAccount,
    } = this.props.masterAccount;
    const {
      currentTimeZone,
      anchorTimeZones,
      currentUser,
    } = this.props;
    const shadedAvailabiityHours = determineShadedCalendarHours({ // returns {start: 15, end: 19}
      timeZone: currentTimeZone,
      timeZoneLabel: currentTimeZone,
      inputDefaultBrowserTimeZone: this.getDefaultUserTimeZone(),
      temporaryTimeZones: updatedTimeZone,
      anchorTimeZones,
      defaultTimeZone: this.getDefaultUserTimeZone(),
      masterAccount,
      user: currentUser,
    });
    this.setState({
      shadedAvailabiityHours,
    }, () => {
      // need to set after state so we re-render slots wrapper
      this.props.setTemporaryTimeZones(updatedTimeZone);
    });
  }

  remountCalendarWithDelay() {
    this._hasWeeklyCalendarScrolled = false;

    this.setState({ hideBigCalendar: true });
    setTimeout(() => {
      this.setState({ hideBigCalendar: false });
    }, 0.1 * SECOND_IN_MS);
  }

  resetCalendarScroll() {
    this._hasWeeklyCalendarScrolled = false;
  }

  emptyEventsInMainCalendar() {
    let { todayLeftWindow, todayRightWindow } = this.createEventsWindow();

    let leftWindowSelectedDay;
    let rightWindowSelectedDay;

    if (this.isCalendarView(BACKEND_MONTH)) {
      leftWindowSelectedDay = convertDateIntoEpochUnixWeek(
        getFirstDayOfMonthlyCalendarJSDate(
          this.props.selectedDay,
          this.props.weekStart,
        ),
      );
      rightWindowSelectedDay = convertDateIntoEpochUnixWeek(
        getLastDayOfMonthlyCalendarJSDate(
          this.props.selectedDay,
          this.props.weekStart,
        ),
      );
    } else {
      leftWindowSelectedDay = convertDateIntoEpochUnixWeek(
        getFirstDayOfWeekJsDate(
          subWeeks(this.props.selectedDay, 1),
          this.props.weekStart,
        ),
      );
      rightWindowSelectedDay = convertDateIntoEpochUnixWeek(
        getLastDayOfWeekJsDate(
          addWeeks(this.props.selectedDay, 1),
          this.props.weekStart,
        ),
      );
    }

    let updatedFormattedEvents = this.state.formattedEvents;

    updatedFormattedEvents = updatedFormattedEvents.filter((e) =>
      this.filterForEventsInWeek(
        e,
        todayLeftWindow,
        todayRightWindow,
        leftWindowSelectedDay,
        rightWindowSelectedDay,
      ),
    );
    let temporaryEvents = this.getTemporaryMeetWithEventFormAndMimicEvents();

    let { formattedEventsWithStaticInformation, combinedEvents } =
      this.updatedFormattedAndTemporaryEventsWithStaticInformation(
        updatedFormattedEvents,
        temporaryEvents,
      );

    this.setState({
      formattedEvents: formattedEventsWithStaticInformation,
      formattedEventsWithTemporaryEvents: combinedEvents,
    });
  }

  setEventsInMainCalendar(listOfEvents) {
    if (isEmptyArrayOrFalsey(listOfEvents)) {
      return;
    }

    const temporaryEvents =
      this.getTemporaryMeetWithEventFormAndMimicEvents();

    const { formattedEventsWithStaticInformation, combinedEvents } =
      this.updatedFormattedAndTemporaryEventsWithStaticInformation(
        listOfEvents,
        temporaryEvents,
      );

    this.setState({
      formattedEvents: formattedEventsWithStaticInformation,
      formattedEventsWithTemporaryEvents: combinedEvents,
    });
  }

  addEvent(eventData) {
    if (!eventData?.event) {
      // sanity check
      return;
    }
    let newEvent = eventData.event;
    if (this.isPreviousDeletedVersionOfEvent(newEvent)) {
      return;
    }
    // check to see if we should add this event in
    let email = this.getEmailFromEvent(newEvent);

    if (
      !shouldDisplayEvent({
        showDeclinedEvents: this.props.showDeclinedEvents,
        event: newEvent,
        email,
      })
    ) {
      return;
    }

    if (
      eventData.timeZone &&
      eventData.timeZone !== this.props.currentTimeZone
    ) {
      newEvent = FormatIntoJSDate(newEvent, this.props.currentTimeZone);
    }

    const updatedFormattedEvents = [...(this.state.formattedEvents.filter(e => getEventUserEventID(e) !== getEventUserEventID(newEvent))), newEvent];

    this.enrichCompaniesForEvents([newEvent]);

    if (isActionModeUpsertEvent(this.props.actionMode)) {
      // do nothing -> otherwise we delete the current even that's being edited
    } else if (eventData.removeTemporaryEvent) {
      this.props.setTemporaryEvent(this.getUpdatedTemporaryEventsWithoutMimicEvent());
    }

    const temporaryEvents =
      this.getTemporaryMeetWithEventFormAndMimicEvents();

    const { formattedEventsWithStaticInformation, combinedEvents } =
      this.updatedFormattedAndTemporaryEventsWithStaticInformation(
        updatedFormattedEvents,
        temporaryEvents,
      );

    clearEventStyleCache();
    this.setState({
      formattedEvents: formattedEventsWithStaticInformation,
      formattedEventsWithTemporaryEvents: combinedEvents,
    }, () => {
      clearEventStyleCache();
    });
  }

  getUpdatedTemporaryEventsWithoutMimicEvent() {
    const {
      temporaryEvents,
    } = this.props;
    if (isEmptyArrayOrFalsey(temporaryEvents)) {
      return null;
    }

    const filteredEvents = temporaryEvents.filter(e => {
      if (isFakeMimicEvent(e)) {
        return false;
      }
      if (isFlashingEvent(e)) {
        return false;
      }
      return true;
    });

    if (isEmptyArrayOrFalsey(filteredEvents)) {
      return null;
    }

    return filteredEvents;
  }

  enrichCompaniesForEvents(events) {
    if (isEmptyArrayOrFalsey(events)) {
      return;
    }

    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    let allDomains = [];
    events.forEach(event => {
      const domains = getEventAttendeeDomains({ event, allLoggedInUsers });
      allDomains = allDomains.concat(domains);
    });
    backendBroadcasts.publish(BACKEND_BROADCAST_VALUES.FETCH_DOMAIN_SUMMARIES, allDomains);
  }

  removeMultipleEvents({userEventIDs}) {
    if (isEmptyArrayOrFalsey(userEventIDs)) {
      return;
    }
    const userEventIDsSet = new Set(userEventIDs);

    const {
      mimicEventsList,
    } = this.props;
    if (!isEmptyArrayOrFalsey(mimicEventsList)) {
      const filteredMimicEvents = mimicEventsList.filter(e => !userEventIDsSet.has(getEventUserEventID(e)));
      if (mimicEventsList.length !== filteredMimicEvents.length) {
        // remove mimic events that are in the userEventIDs list
        this.props.setMimicEventsList(filteredMimicEvents);
      }
    }

    const updatedFormattedEvents = this.state.formattedEvents.filter(e => !userEventIDsSet.has(getEventUserEventID(e)));

    const temporaryEvents =
      this.getTemporaryMeetWithEventFormAndMimicEvents();
    const { formattedEventsWithStaticInformation, combinedEvents } =
    this.updatedFormattedAndTemporaryEventsWithStaticInformation(
      updatedFormattedEvents,
      temporaryEvents,
    );

    this.setState({
      formattedEvents: formattedEventsWithStaticInformation,
      formattedEventsWithTemporaryEvents: combinedEvents,
    });
  }

  removeEvent(eventData) {
    if (!eventData) {
      // early exit
      return;
    }

    const {
      deleteEvent,
      addTemporaryEvent,
      updateEventParam, // param to pass into MAIN_CALENDAR_BROADCAST_VALUES.UPDATE_EVENT_INTO_WEEKLY_CALENDAR / updateEventIntoWeeklyCalendar
      addEventParam, // param to pass into MAIN_CALENDAR_BROADCAST_VALUES.ADD_EVENT_INTO_WEEKLY_CALENDAR / this.addEvent
    } = eventData;

    const eventId = getEventUserEventID(deleteEvent);
    if (isCancelledEvent(deleteEvent)) {
      this.updateLastUpdatedAtForDeletedEvents([deleteEvent]);
    }

    const updatedFormattedEvents = this.state.formattedEvents.filter(e => getEventUserEventID(e) !== eventId);

    let temporaryEvents =
      this.getTemporaryMeetWithEventFormAndMimicEvents();

    if (addTemporaryEvent) {
      temporaryEvents = temporaryEvents
        .filter((e) => getEventUserEventID(addTemporaryEvent) !== getEventUserEventID(e))
        .concat(addTemporaryEvent);

      this.setTemporaryEvents([addTemporaryEvent]);
    }

    const { formattedEventsWithStaticInformation, combinedEvents } =
      this.updatedFormattedAndTemporaryEventsWithStaticInformation(
        updatedFormattedEvents,
        temporaryEvents,
      );

    this.setState({
      formattedEvents: formattedEventsWithStaticInformation,
      formattedEventsWithTemporaryEvents: combinedEvents,
    }, () => {
      // call after setting state to avoid race condition
      if (updateEventParam) {
        this.updateEventIntoWeeklyCalendar(updateEventParam);
      } else if (addEventParam) {
        this.addEvent(addEventParam);
      }
    });
  }

  // Add to currentPreview
  updateEventIntoWeeklyCalendar(eventData) {
    const {
      formattedEvents,
    } = this.state;

    const { currentTimeZone, actionMode, setTemporaryEvent, showDeclinedEvents } = this.props;

    if (!eventData.event) {
      return;
    }

    let newEvent = eventData.event;

    if (isActionModeUpsertEvent(actionMode) && this.isSameAsTemporaryEvent(newEvent)) {
      // event has been updated so if we exit event form -> takes latest event
      Broadcast.publish("UPDATE_EVENT_FORM_ORIGINAL_EVENT", newEvent);
      return;
    }

    // Check if declined
    const email = this.getEmailFromEvent(newEvent);

    if (!shouldDisplayEvent({
      showDeclinedEvents,
      event: newEvent,
      email,
    })) {
      this.removeEvent({ deleteEvent: newEvent });
      return;
    }

    if (eventData.timeZone && eventData.timeZone !== currentTimeZone) {
      newEvent = FormatIntoJSDate(newEvent, currentTimeZone);
    }

    const getUpdatedFormattedEvents = () => {
      const updatedFormattedEvents = [...formattedEvents];
      const matchingEventIndex = updatedFormattedEvents.findIndex(e => getEventUserEventID(e) === getEventUserEventID(newEvent));
      if (matchingEventIndex >= 0) {
        if (hasEventBeenUpdated(formattedEvents[matchingEventIndex], newEvent)) {
          // only update if it exists and has been updated
          updatedFormattedEvents[matchingEventIndex] = newEvent;
        }
      } else {
        //if not in array -> add
        updatedFormattedEvents.push(newEvent);
      }
      return updatedFormattedEvents;
    };

    const updatedFormattedEvents = getUpdatedFormattedEvents();

    this.enrichCompaniesForEvents([newEvent]);

    if (eventData.removeTemporaryEvent) {
      setTemporaryEvent(this.getUpdatedTemporaryEventsWithoutMimicEvent());
    }

    const temporaryEvents = this.getTemporaryMeetWithEventFormAndMimicEvents();
    let { formattedEventsWithStaticInformation, combinedEvents } =
      this.updatedFormattedAndTemporaryEventsWithStaticInformation(
        updatedFormattedEvents,
        temporaryEvents,
      );

    this.setState({
      formattedEvents: formattedEventsWithStaticInformation,
      formattedEventsWithTemporaryEvents: combinedEvents,
    });
  }

  isReverseSlotMode() {
    const {
      reverseSlotsText,
    } = this.props.temporaryStateStore;
    return !!reverseSlotsText;
  }

  determineOnSelectSlotMethod(slot) {
    if (this.isReverseSlotMode()) {
      this.onSelectTimeSlot(slot); // create event
      layoutBroadcast.publish("RESET_REVERSED_SLOTS_TEXT", false);
    } else if (isActionModeCreateAvailability(this.props.actionMode)) {
      if (!isEmptyObjectOrFalsey(this.props.popupEvent)) {
        this.props.removePopupEvent();
      } else {
        this.onCreateAvailabilitySlot(slot);
      }
    } else if (isActionModeUpsertEvent(this.props.actionMode)) {
      batch(() => {
        if (!isEmptyObjectOrFalsey(this.props.popupEvent)) {
          this.props.removePopupEvent();
        } else {
          eventFormBroadcast.publish(EVENT_FORM_BROADCAST_VALUES.ON_CLICK_MAIN_CALENDAR_SLOT_WITH_OPEN_EVENT_FORM, slot);
        }
      });
    } else if (this.props.isCreateFocusModeBlocks) {
      this.onCreateFocusModeBlocks(slot);
    } else if (this.props.popupEvent) {
      this.props.removePopupEvent();
    } else if (this.props.currentPreviewedEvent && isOnSelectSlot(slot)) {
      this.onSelectTimeSlot(slot);
    } else if (this.props.currentPreviewedEvent && isOnClickSlot(slot)) {
      batch(() => {
        this.props.removePreviewedEvent();
        this.props.history.push("/home");
      });
    } else if (this.state.isMouseOverMonthDate) {
      // do nothing, hovering over monthly date
      if (!this.isCalendarView(BACKEND_MONTH)) {
        this.setState({ isMouseOverMonthDate: false });
      }
    } else {
      this.onSelectTimeSlot(slot);
    }
  }

  setModalContent(title, width, modalContent) {
    this.setState({
      modalTitle: title,
      modalWidth: width,
      modalContent,
      shouldDisplayModal: true,
    });
  }

  closeModal() {
    this.setState({
      shouldDisplayModal: false,
      modalContent: null,
      modalArray: [],
      colorId: null,
      transparency: null,
      originalRecurringEvent: null,
      updatedRecurringEvent: null,
      categories: null,
    });
  }

  getTemporaryEvents(listOfTemporaryEvents = null) {
    if (!isEmptyArrayOrFalsey(listOfTemporaryEvents)) {
      return listOfTemporaryEvents;
    }
    if (!isEmptyArrayOrFalsey(this.props.temporaryEvents)) {
      return this.props.temporaryEvents;
    }
    return [];
  }

  addXHours() {
    return this.state.currentTimeIndicator;
  }

  getCurrentTimeIndicatorTime(timeZone) {
    const {
      currentTimeZone,
    } = this.props;
    return getCurrentTimeInCurrentTimeZone(timeZone ?? currentTimeZone);
  }

  // used to update the current time indicator when it's a new minute
  updateNowTime() {
    this.setState({
      currentTimeIndicator: this.getCurrentTimeIndicatorTime(),
      renderCount: this.state.renderCount + 1,
    });
  }

  toggleAvailabilityMode(type) { // open default type (slots, personal links or group vote)
    if (isActionModeCreateAvailability(this.props.actionMode)) {
      isAvailabilityPanelShowing() &&
        Broadcast.publish("COPY_AVAILABILITY_CONTENT");
    } else if (isActionModeUpsertEvent(this.props.actionMode)) {
      // TODO: either exit edit mode or send user warning that they can't enter availability mode
    } else {
      this.props.setActionMode(ACTION_MODE.CREATE_AVAILABILITY);
      if (type?.value &&
        [BOOKING_LINK, BACKEND_PERSONAL_LINK, BACKEND_GROUP_VOTE_LINK].includes(type?.value)
      ) { // example: SLOTS_AVAILABILITY_SELECTION
        Broadcast.publish("SET_AVAILABILITY_TYPE", type);
      }

      if (this.isCalendarView(BACKEND_MONTH)) {
        this.setState({renderCount: this.state.renderCount + 1}, () => {
          mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.DETERMINE_CALENDAR_VIEW_CHANGE, 7);
        });
      }
    }
    this.removePreviewEvent();
  }

  onCreateFocusModeBlocks(slot) {
    const isAllDay = isSelectedSlotAllDayEvent(slot);

    // TODO: handle all day

    let timeEnd = updateToStartOfNextDayIfLastSlot(slot.end);
    let eventStart = slot.start;
    const {
      currentTimeZone,
    } = this.props;
    const currentTimeInCurrentTimeZone = getCurrentTimeInCurrentTimeZone(currentTimeZone);
    if (isBeforeMinute(eventStart, currentTimeInCurrentTimeZone)) {
      // set start to current time rounded off to nearest 5 minute mark (e.g. 4:23 -> 4:25)
      eventStart = startOfMinute(
        RoundToClosestMinuteJSDate(
          currentTimeInCurrentTimeZone,
          5,
        ),
      );
    }

    if (isOnClickSlot(slot)) {
      timeEnd = addMinutes(
        eventStart,
        30,
      );
    }

    const focusBlock = {
      isTemporary: true,
      isFocusBlock: true,
      eventStart,
      index: this.props.temporaryEvents ? this.props.temporaryEvents.length : 0,
      eventEnd: timeEnd,
      rbcEventEnd: protectMidnightCarryOver(
        determineRBCEventEndWithEventStart(eventStart, timeEnd),
      ),
      status: TYPE_FOCUS_BLOCK,
      raw_json: { status: TYPE_FOCUS_BLOCK }, // TODO: remove later
      id: createUUID(),
      resourceId: getUserEmail(this.props.currentUser),
      summaryUpdatedWithVisibility: "Focus block",
    };

    const updatedTemporaryEventList = this.props.temporaryEvents
      ? [...this.props.temporaryEvents, focusBlock]
      : [focusBlock];

    const temporaryEvents = this.getTemporaryMeetWithEventFormAndMimicEvents();

    const temporaryEventsWithStaticInformation =
      this.updateEventsStaticInformation(temporaryEvents);

    this.setState({
      formattedEventsWithTemporaryEvents: this.filterOutEventsNotInCurrentView(
        this.mergeSameEvents({
          eventList: this.state.formattedEvents.concat(
            temporaryEventsWithStaticInformation,
          ),
          fromWhere: "_onCreateFocusModeBlocks",
        }),
      ),
    });

    this.props.setTemporaryEvent(updatedTemporaryEventList);
  }

  onCreateAvailabilitySlot(slot) {
    const isAllDay = isSelectedSlotAllDayEvent(slot);
    const isGroupVote = isGroupVoteDetailPageOpen();
    const {
      currentUser,
    } = this.props;
    const {
      masterAccount,
    } = this.props.masterAccount;
    const {
      allCalendars,
    } = this.props.allCalendars;

    const getSlotResourceId = () => {
      const calendarFromEmail = this.getMatchingCalendarFromDraggedSlot(slot);
      if (getCalendarIsPrimary(calendarFromEmail)
        && doesCalendarHaveEditableRole(calendarFromEmail)
      ) {
        return getCalendarEmail(calendarFromEmail);
      }

      if (isUserMaestroUser(masterAccount)
        && isCalendarSelected(calendarFromEmail)
      ) {
        const matchingPrimaryCalendarFromSecondary = getAllPrimaryCalendarsFromAllCalendars(allCalendars)
          .find(c => isSameEmail(getCalendarEmail(c), getCalendarEmail(calendarFromEmail)));
        if (matchingPrimaryCalendarFromSecondary && doesCalendarHaveEditableRole(matchingPrimaryCalendarFromSecondary)) {
          return getCalendarEmail(matchingPrimaryCalendarFromSecondary);
        }
      }
      return getUserEmail(currentUser);
    };

    if (!isGroupVote && isAllDay) {
      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        "You can not select all day slots for availability.",
      );
      return;
    }

    if (isGroupVote) {
      // do nothing
    } else if (
      isPersonalLinkPanelOpen() ||
      !!document.getElementById(AVAILABILITY_GROUP_VOTE_CONTAINER_ID)
    ) {
      // changes personal links to slots
      Broadcast.publish("SET_AVAILABILITY_TYPE", SLOTS_AVAILABILITY_SELECTION);
    }

    const {
      currentTimeZone,
    } = this.props;

    const currentTimeInCurrentTimeZone = getCurrentTimeInCurrentTimeZone(currentTimeZone);

    if (this.isSlotInPast({start: slot.start, end: slot.end})) {
      if (isGroupVote) {
        Broadcast.publish(
          SET_DISAPPEARING_NOTIFICATION_MESSAGE,
          "The dragged Slot is in the past",
        );
      } else {
        Broadcast.publish(
          SET_DISAPPEARING_NOTIFICATION_MESSAGE,
          "You can not select a Slot in the past.",
        );
      }
      return;
    }

    let timeEnd = updateToStartOfNextDayIfLastSlot(slot.end);
    let eventStart = slot.start;
    if (isBeforeMinute(eventStart, currentTimeInCurrentTimeZone)) {
      // set start to current time rounded off to nearest 5 minute mark (e.g. 4:23 -> 4:25)
      eventStart = startOfMinute(
        RoundToClosestMinuteJSDate(
          currentTimeInCurrentTimeZone,
          15,
        ),
      );
    }
    if (isGroupVote && differenceInDays(timeEnd, eventStart) > 1) {
      // multi day select -> select one day for now
      timeEnd = addDays(eventStart, 1);
    }

    if (isOnClickSlot(slot)) {
      if (isGroupVote && getDefaultGroupVoteClickDuration()) {
        timeEnd = addMinutes(
          eventStart,
          getDefaultGroupVoteClickDuration(),
        );
      } else {
        timeEnd = addMinutes(
          eventStart,
          this.props.availabilitySelectedMinutes || 30,
        );
      }
    }

    if (isGroupVote && isAllDay) {
      eventStart = startOfDay(eventStart);
      timeEnd = startOfDay(slot.end);
    }

    const availableSlot = createTemporaryEvent({
      startTime: eventStart,
      endTime: timeEnd,
      index: this.props.temporaryEvents ? this.props.temporaryEvents.length : 0,
      isGroupVote,
      resourceId: getSlotResourceId(),
    });

    if (isGroupVote) {
      groupVoteBroadcast.publish("SET_USER_DRAGGED_SLOTS", {
        newSlot: availableSlot,
        type: ADD_SLOT,
      });
    } else if (isAvailabilityPanelShowing()) {
      availabilityBroadcast.publish("SET_USER_DRAGGED_SLOTS", {
        newSlot: availableSlot,
        type: ADD_SLOT,
      });
    }

    const getUpdatedTemporaryEventsList = () => {
      const {
        breakDuration,
      } = this.props.availabilityStore;

      const groupVoteBreakDuration = getGroupVoteDurationOnDrag();
      if (isEventSlotAllDayEvent(availableSlot)) {
        return this.props.temporaryEvents
          ? [...this.props.temporaryEvents, availableSlot]
          : [availableSlot];
      } else if (isGroupVote && groupVoteBreakDuration) {
        // do not break up if all day slot
        const newSlots = splitSlotIntoDuration({
          breakDuration: groupVoteBreakDuration,
          start: eventStart,
          end: timeEnd,
          currentSlots: this.props.temporaryEvents ?? [],
          isGroupVote,
        });

        return this.props.temporaryEvents
          ? this.props.temporaryEvents.concat(newSlots)
          : newSlots;
      } else if (breakDuration && !isGroupVote) {
        // break it into break duration
        const newSlots = splitSlotIntoDuration({
          breakDuration,
          start: eventStart,
          end: timeEnd,
          currentSlots: this.props.temporaryEvents ?? [],
          isGroupVote,
        });

        return this.props.temporaryEvents
          ? this.props.temporaryEvents.concat(newSlots)
          : newSlots;
      } else {
        return this.props.temporaryEvents
          ? [...this.props.temporaryEvents, availableSlot]
          : [availableSlot];
      }
    };

    const updatedTemporaryEventList = getUpdatedTemporaryEventsList();

    this.setTemporaryEvents(updatedTemporaryEventList);
  }

  onDeleteAvailabilitySlot(slot, deleteOnClick = false) {
    const isEventLastEvent = () => {
      const lastAvailabilityEvent = getLastElementOfArray(
        this.props.temporaryEvents,
      );
      if (!lastAvailabilityEvent) {
        return false;
      }

      return getClientEventID(lastAvailabilityEvent) === getClientEventID(slot);
    };

    if (deleteOnClick && isEventLastEvent()) {
      // only show tip on click delete
      mainCalendarBroadcast.publish(
        SET_GLOBAL_SHORT_CUT_SUGGESTION,
        "Backspace",
        "delete the last selected slot",
      );
    }

    const listWithOutDeletedEvent = this.props.temporaryEvents
      ? this.props.temporaryEvents.filter((event) => getClientEventID(event) !== getClientEventID(slot))
      : [];

    this.setTemporaryEvents(listWithOutDeletedEvent);
  }

  filterForCurrentlyInUseTemporaryEvents() {
    const temporaryEvents = this.props.temporaryEvents;

    if (isEmptyArrayOrFalsey(temporaryEvents)) {
      return [];
    }

    let events = [];

    temporaryEvents.forEach((e) => {
      if (isTextTemplate(e)) {
        // Do nothing
      } else if (
        isActionModeCreateAvailability(this.props.actionMode) &&
        isAvailabilityEvent(e)
      ) {
        events = events.concat(e);
      } else if (isActionModeUpsertEvent(this.props.actionMode) && isTemporaryEvent(e)) {
        events = events.concat(e);
      }
    });

    return events;
  }

  removeTemporaryEvents(shouldReturnTemporaryEvents = false) {
    let otherEvents = this.getMeetWithEventFormAndMimicEvents();

    // do not delete availability events
    const filteredOutTemporaryEvents =
      this.filterForCurrentlyInUseTemporaryEvents();
    if (filteredOutTemporaryEvents?.length > 0) {
      this.setTemporaryEvents(filteredOutTemporaryEvents);
      otherEvents = otherEvents.concat(filteredOutTemporaryEvents);
    } else {
      this.setTemporaryEvents(null);
    }

    if (shouldReturnTemporaryEvents) {
      return otherEvents;
    }

    const temporaryEventsWithStaticInformation =
      this.updateEventsStaticInformation(otherEvents);

    this.setState({
      formattedEventsWithTemporaryEvents: this.filterOutEventsNotInCurrentView(
        this.mergeSameEvents({
          eventList: this.state.formattedEvents.concat(
            temporaryEventsWithStaticInformation,
          ),
          fromWhere: "_removeTemporaryEvents",
        }),
      ),
    });
  }

  temporarilyRemoveWeeklyCalendarAndUpdateEvent(eventData) {
    const { startTime, endTime, event } = eventData;

    const eventObject = { event, start: startTime, end: endTime };

    this.stashUpdatedEventAndCreateModals(eventObject);
  }

  removeEventAndAddInTemporaryEvent(param) {
    const {
      event,
    } = param;
    const backgroundColor = this.determineEventColor({event});
    const isOutlook = isOutlookEvent(param.event);
    const isAllDay = param.event.allDay;
    let end = isOutlook
      ? param.end
      : isAllDay && !isSameDay(param.start, param.end) ? addDays(param.end, 1) : param.end;
    if (isAllDay) {
      end = startOfDay(end);
    }

    const rbcEventEnd = determineRBCEventEndWithEventStart(param.start, end);

    const temporaryFlashingEvent = {
      summaryUpdatedWithVisibility: param.event.summaryUpdatedWithVisibility,
      user_event_id: getEventUserEventID(param.event),
      eventStart: param.start,
      displayAsAllDay: param.event.displayAsAllDay,
      isAllDay,
      eventEnd: end,
      rbcEventEnd,
      isTemporary: true,
      status: temporary,
      raw_json: { status: temporary }, // TODO: remove
      calendarId: getEventUserCalendarID(event),
      user_calendar_id: getEventUserCalendarID(event),
      backgroundColor: backgroundColor,
    };

    const originalEvent = this.state.formattedEventsWithTemporaryEvents.find(event => getEventUserEventID(event) === getEventUserEventID(event));

    if (originalEvent) {
      this.setState({ originalEvent });
    }

    this.removeEvent({ deleteEvent: event });

    batch(() => {
      this.props.setTemporaryEvent([temporaryFlashingEvent]);
      this.removePreviewEvent();
    });
  }

  constructEmailData({ event, eventStart, eventEnd }) {
    const {
      currentUser,
      currentTimeZone,
    } = this.props;
    const {
      masterAccount,
    } = this.props.masterAccount;
    const {
      allCalendars,
    } = this.props.allCalendars;
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    const updatedEvent = { ...event, eventStart, eventEnd };

    return constructEmailData({
      event: updatedEvent,
      currentUser: getMatchingUserFromEvent({
        event,
        allCalendars,
        allLoggedInUsers,
      }) ?? currentUser,
      subject: getEventUITitle(event),
      currentTimeZone,
      masterAccount,
    });
  }

  // to update all following -> delete all following and then create enw event
  async updateFollowingRecurringInstances(e) {
    let { event, start, end } = e;

    let originalRecurrenceEvent = getOriginalRecurringEventFromIndex(
      event,
      this.props.originalRecurrenceEventIndex,
    );

    if (originalRecurrenceEvent) {
      // already have original original recurrence event -> just use that event
      this.updateFollowWithOriginalRecurringEventInstance({
        start,
        end,
        event,
        originalRecurrenceEvent,
        e,
      });
      return;
    }

    // otherwise -> fetch for original recurrence event
    const fetchedOriginalEvent = await this.fetchForOriginalRecurringEvent(event);
    if (!this._isMounted) {
      return;
    }
    if (!fetchedOriginalEvent) {
      this.putBackOriginalEventAndRemoveTemporaryEvent();
      return;
    }

    this.updateFollowWithOriginalRecurringEventInstance({
      start,
      end,
      event,
      originalRecurrenceEvent: fetchedOriginalEvent,
      e,
    });
  }

  updateFollowWithOriginalRecurringEventInstance({
    start,
    end,
    event,
    originalRecurrenceEvent,
    e,
  }) {
    const { message } = e;
    const originalEvent = event;
    const {
      currentTimeZone,
      originalRecurrenceEventIndex,
      selectedCalendarView,
      selectedDay,
      setOriginalRecurrenceEventIndex,
      weekStart,
    } = this.props;
    const { allCalendars } = this.props.allCalendars;
    const isV2 = isVersionV2();

    const eventData = isV2 ?
      this.constructUpdateRecurringEventDataV2({
        startTime: start,
        endTime: end,
        event: originalRecurrenceEvent,
        message,
      }) :
      this.constructUpdateRecurringEventData({
        startTime: start,
        endTime: end,
        event,
        originalRecurrenceEvent,
        isUpdateAllInstances: false,
      });

    const isFirstRecurring = isEventFirstRecurringInstance(getEventStart(event), getEventStart(originalRecurrenceEvent));
    if (isFirstRecurring) {
      this.updateAllRecurringInstances(e);
      return;
    }

    const masterEventId = getEventMasterEventID(event);
    const userCalendarId = getEventUserCalendarID(eventData);
    let updatePath = `calendars/${userCalendarId}/recurring_events/${masterEventId}`;
    let deletePath = isFirstRecurring ? updatePath + "/all" : updatePath;
    if (isV2) {
      updatePath = "recurring_events/following";
      deletePath = isFirstRecurring ? "recurring_events/all" : updatePath;
    }

    const timeWindows = determineSyncWindow({ selectedDay, selectedCalendarView, weekStart });
    const masterEventStartDate = getEventStart(originalRecurrenceEvent);
    // If doNotSendUpdate is null or undefined, use the fallback value.
    const shouldSendUpdate = (e.doNotSendUpdate === false) || !!e?.message;
    // Given a message we set sendUpdates to 'none' to send a vimcal mailer instead of google's mailer
    // TODO: a similar pattern is found in deleteEventButton.js and eventForm/index.js. We should abstract and keep it DRY
    const params = {
      sendUpdates: shouldSendUpdate ? GOOGLE_UPDATES.ALL : DEFAULT_GOOGLE_DO_NOT_SEND_UPDATE,
      timeMin: formatTimeForBackendJsDate(timeWindows.minDate, weekStart, true),
      timeMax: formatTimeForBackendJsDate(timeWindows.maxDate, weekStart, false),
      // V1 specific fields
      ...(!isV2 && {
        google_calendar_id: getEmailFromUserCalendarID(getEventUserCalendarID(event), allCalendars),
        google_event_id: getGoogleEventId(originalRecurrenceEvent),
        conferenceDataVersion: 1,
      }),
      // V2 specific fields
      ...(isV2 && {
        calendar_provider_id: getEmailFromUserCalendarID(getEventUserCalendarID(originalRecurrenceEvent), allCalendars),
        event_provider_id: getGoogleEventId(originalRecurrenceEvent),
        ical_uid: getEventICalUID(originalRecurrenceEvent),
        master_event_id: masterEventId,
        master_event_start_date: masterEventStartDate.dateTime,
      }),
    };

    const ruleString = getRRuleStringFromRecurrence(originalRecurrenceEvent);
    const deleteParams = {
      ...params,
      sendUpdates: GOOGLE_UPDATES.NONE,
      conferenceDataVersion: undefined,
    };

    const deleteQueryParams = constructQueryParams(deleteParams);
    const deleteUrl = `${constructRequestURL(deletePath, isV2)}?${deleteQueryParams}`;

    const deleteCutOff = createRecurrenceCutOffDate(event);

    const organizer = getEventOrganizer(originalRecurrenceEvent);
    const updated_original_recurrence = trimOriginalRecurrenceRule(ruleString, deleteCutOff);
    const deleteRequestBody = {
      organizer: organizer && organizer.self,
      ical_uid: getEventICalUID(originalRecurrenceEvent),
      updated_original_recurrence,
      master_event_id: masterEventId,
      time_zone: currentTimeZone,
    };

    const deletePayloadData = {
      body: JSON.stringify(deleteRequestBody),
      headers: getDefaultHeaders(),
    };

    const queryParams = constructQueryParams(params);
    const updateUrl = `${constructRequestURL(updatePath, isV2)}?${queryParams}`;

    let updatedEventData = _.clone(eventData);
    updatedEventData.organizer = organizer && organizer.self;
    const emailData = this.constructEmailData({ event, eventStart: start, eventEnd: end });

    if (e?.message) {
      updatedEventData["message"] = e.message;
    }

    const payloadData = {
      body: JSON.stringify({ ...updatedEventData, ...emailData }),
      headers: getDefaultHeaders(),
    };

    Broadcast.publish("UPDATE_FOLLOWING_INSTANCES_AS_ORGANIZER", {
      updateUrl,
      deleteUrl,
      deletePayloadData,
      payloadData,
      eventData,
      originalEvent,
      userEmail: getUserEmailFromEvent(event, allCalendars),
    });

    if (isOutlookEvent(originalRecurrenceEvent)) {
      const updatedIndex = resetOriginalRecurringEventIndex({
        currentEvent: event,
        originalRecurrenceEventIndex,
      });

      setOriginalRecurrenceEventIndex(updatedIndex);
    }
  }

  async updateAllRecurringInstances(e) {
    const { event, start, end, message, doNotSendUpdate } = e;
    const { originalRecurrenceEventIndex } = this.props;
    const calendarId = getEventUserCalendarID(event) || event.calendarId;
    // If doNotSendUpdate is null or undefined, do not send updates.
    const shouldSendUpdate = doNotSendUpdate === false;

    const originalRecurrenceEvent = getOriginalRecurringEventFromIndex(
      event,
      originalRecurrenceEventIndex,
    );

    if (originalRecurrenceEvent) {
      this.updateAllrecurringInstancesWithOriginalEvent({
        start,
        end,
        event,
        calendarId,
        originalEvent: originalRecurrenceEvent,
        message,
        shouldSendUpdate,
      });
      return;
    }

    try {
      const fetchedOriginalEvent = await this.fetchForOriginalRecurringEvent(event);
      if (!this._isMounted) {
        return;
      }

      if (!fetchedOriginalEvent) {
        this.putBackOriginalEventAndRemoveTemporaryEvent();
        return;
      }

      this.updateAllrecurringInstancesWithOriginalEvent({
        start,
        end,
        event,
        calendarId,
        originalEvent: fetchedOriginalEvent,
        message,
        shouldSendUpdate,
      });
    } catch (err) {
      if (!this._isMounted) {
        return;
      }

      this.putBackOriginalEventAndRemoveTemporaryEvent();
      handleError(err);
    }
  }

  // TODO: Same logic of eventForm/index.js. These should be an application level function instead of a component level
  // function
  updateAllrecurringInstancesWithOriginalEvent({
    start,
    end,
    event,
    calendarId,
    originalEvent,
    message,
    shouldSendUpdate,
  }) {
    const { selectedCalendarView, selectedDay, weekStart } = this.props;
    const { allCalendars } = this.props.allCalendars;
    const { user_calendar_id } = originalEvent;
    const gcal_event_id = getGCalEventId(originalEvent);
    const isV2 = isVersionV2();
    const masterEventId = getGoogleEventId(originalEvent);

    const path = isV2
      ? "recurring_events/all"
      : `calendars/${user_calendar_id}/recurring_events/${gcal_event_id}/all`;

    // TODO: ASK USER IF WANT TO SEND UPDATE AND INCLUDE NOTE
    const timeWindows = determineSyncWindow({ selectedDay, selectedCalendarView, weekStart });
    const masterEventStartDate = getEventStart(originalEvent);
    const masterEventEndDate = getEventEnd(originalEvent);
    // Given a message we set sendUpdates to 'none' to send a vimcal mailer instead of google's mailer
    let params = {
      sendUpdates: shouldSendUpdate && !message ? GOOGLE_UPDATES.ALL : DEFAULT_GOOGLE_DO_NOT_SEND_UPDATE,
      timeMin: formatTimeForBackendJsDate(timeWindows.minDate, weekStart, true),
      timeMax: formatTimeForBackendJsDate(timeWindows.maxDate, weekStart, false),
      ical_uid: getEventICalUID(originalEvent),
      // V1 specific fields
      ...(!isV2 && {
        google_calendar_id: getEmailFromUserCalendarID(calendarId, allCalendars),
        google_event_id: gcal_event_id,
        conferenceDataVersion: 1,
      }),
      // V2 specific fields
      ...(isV2 && {
        calendar_provider_id: getEmailFromUserCalendarID(getEventUserCalendarID(originalEvent), allCalendars),
        event_provider_id: getGoogleEventId(event),
        master_event_id: masterEventId,
        master_event_start_date: masterEventStartDate.dateTime,
        master_event_end_date: masterEventEndDate.dateTime,
      }),
    };

    // Need to comment out since on creating event, google needs conferenceDataVersion1 to keep event conferencing
    // createParams['conferenceDataVersion'] = 1;
    // if (this.state.conference !== this.state.originalState.conference
    // || (this.state.conference === zoomString && this.state.updatedToPersonalZoomLink)
    // ) {
    //   params['conferenceDataVersion'] = 1;
    // }

    const url = `${constructRequestURL(path, isV2)}?${constructQueryParams(params)}`;
    const eventData = isV2 ? this.constructUpdateRecurringEventDataV2({
      startTime: start,
      endTime: end,
      event: originalEvent,
      message,
      isUpdateAllInstances: true,
    }) : this.constructUpdateRecurringEventData({
      startTime: start,
      endTime: end,
      event,
      originalEvent,
      isUpdateAllInstances: true,
    });

    const emailData = this.constructEmailData({ event, eventStart: start, eventEnd: end });

    const payloadData = {
      body: JSON.stringify({
        ...eventData,
        ...emailData,
        message,
      }),
      headers: getDefaultHeaders(),
    };

    Broadcast.publish("UPDATE_RECURRING_ALL_INSTANCES", {
      url,
      payloadData,
      originalEvent: event,
      lastState: this.state,
      userEmail: getUserEmailFromEvent(event, allCalendars),
    });
    this.removePreviewEvent();
  }

  // For updating all instances of recurring event
  constructStartAndEndWithOriginalEventWithTimeZone({
    startTime,
    endTime,
    originalEvent,
  }) {
    // do not have to worry about all day events since we only call this for
    // NOT dragging event across days and you can drag within the same day for all-day events
    const { currentTimeZone } = this.props;

    const updatedStart = set(parseISO(getEventStartValue(originalEvent)), {
      hours: startTime.getHours(),
      minutes: startTime.getMinutes(),
    });
    const updatedEnd = set(parseISO(getEventEndValue(originalEvent)), {
      hours: endTime.getHours(),
      minutes: endTime.getMinutes(),
    });

    const startObject = {
      dateTime: startOfMinute(
        getTimeInAnchorTimeZone(updatedStart, currentTimeZone),
      ).toISOString(),
      date: null,
      timeZone: currentTimeZone,
    };

    const diffInMinutes = differenceInMinutes(endTime, startTime);
    const newEnd = addMinutes(updatedStart, diffInMinutes);

    const endObject = {
      dateTime: startOfMinute(
        getTimeInAnchorTimeZone(isOutlookEvent(originalEvent) ? newEnd : updatedEnd, currentTimeZone),
      ).toISOString(),
      date: null,
      timeZone: currentTimeZone,
    };

    return { startObject, endObject };
  }

  constructStartAndEnd({
    startTime,
    endTime,
    event,
    returnTimeZone = false,
  }) {
    const { currentTimeZone } = this.props;
    const { allDay } = event;
    const startTimeZone = event.startTimeZone || event.event_start?.timeZone;

    // do not pass back time zone if outlook or all day event
    const hasTimezone = (!_.isEqual(startTimeZone, currentTimeZone) || returnTimeZone)
      && !allDay;

    const getOulookAllDayEventEnd = () => {
      if (isSameDay(startTime, endTime)) {
        return {
          date: formatISO(addDays(startOfDay(endTime), 1), ISO_DATE_FORMAT),
          dateTime: null,
        };
      } else {
        return {
          date: formatISO(endTime, ISO_DATE_FORMAT),
          dateTime: null,
        };
      }
    };

    const getAllDayEndDate = () => {
      if (isOutlookEvent(event)) {
        return getOulookAllDayEventEnd();
      }

      return { date: formatISO(addDays(endTime, 1), ISO_DATE_FORMAT), dateTime: null };
    };

    if (allDay) {
      return {
        start: { date: formatISO(startTime, ISO_DATE_FORMAT), dateTime: null },
        end: getAllDayEndDate(),
      };
    }

    return {
      start: {
        dateTime: allDay ? null : getTimeInAnchorTimeZone(startTime, currentTimeZone).toISOString(),
        date: null,
        timeZone: hasTimezone ? currentTimeZone : undefined,
      },
      end: {
        dateTime: allDay ? null : getTimeInAnchorTimeZone(endTime, currentTimeZone).toISOString(),
        date: null,
        timeZone: hasTimezone ? currentTimeZone : undefined,
      },
    };
  }

  constructUpdateRecurringEventData({
    startTime,
    endTime,
    event,
    originalEvent,
    isUpdateAllInstances = false,
  }) {
    const constructStartAndEndWithTimeZone = () => {
      let startTimeTZ = startOfMinute(
        getTimeInAnchorTimeZone(startTime, this.props.currentTimeZone),
      );
      let endTimeTZ = startOfMinute(
        getTimeInAnchorTimeZone(endTime, this.props.currentTimeZone),
      );

      const isAllDay =
        (this.props.temporaryEvents &&
          this.props.temporaryEvents[0]?.isAllDay) ||
        false;

      let startObject = isAllDay
        ? {
          dateTime: null,
          date: formatISO(startTimeTZ, ISO_DATE_FORMAT),
          timeZone: null,
        }
        : {
          dateTime: startTimeTZ.toISOString(),
          date: null,
          timeZone: this.props.currentTimeZone,
        };

      let endObject = isAllDay
        ? {
          dateTime: null,
          date: formatISO(endTimeTZ, ISO_DATE_FORMAT),
          timeZone: null,
        }
        : {
          dateTime: endTimeTZ.toISOString(),
          date: null,
          timeZone: this.props.currentTimeZone,
        };

      return { startObject, endObject };
    };

    let start;
    let end;

    if (isUpdateAllInstances) {
      let { startObject, endObject } =
        this.constructStartAndEndWithOriginalEventWithTimeZone({
          startTime,
          endTime,
          originalEvent,
        });
      start = startObject;
      end = endObject;
    } else {
      const { startObject, endObject } = constructStartAndEndWithTimeZone();

      start = startObject;
      end = endObject;
    }

    let google_calendar_event = {};

    google_calendar_event = _.clone(getEventRawData(event));

    google_calendar_event["start"] = start;
    google_calendar_event["end"] = end;

    if (getEventRecurrence(originalEvent)) {
      google_calendar_event.recurrence = updatedRecurrenceAfterEventStartChange(
        originalEvent,
        startTime,
      );
    }

    google_calendar_event = removeGoogleAutoGeneratedKeys(
      google_calendar_event,
    );

    let eventData = {
      user_calendar_id: getEventUserCalendarID(event),
      event: {
        google_calendar_event,
      },
    };

    let eventCalendarEmail = this.getEventStaticInfo(
      event,
      EVENT_CALENDAR_EMAIL,
    );

    if (
      doesEventHaveModifyPermissionAndIsNotAnOrangizer(
        event,
        eventCalendarEmail,
      )
    ) {
      eventData.overrideCalendarId = getEventOrganizer(event).email;
    }

    return eventData;
  }

  createParams({ allCalendars, e, isAppVersion2 }) {
    const { event, message } = e;

    // Given a message we set sendUpdates to 'none' to send a vimcal mailer instead of google's mailer
    const params = {
      sendUpdates: e?.doNotSendUpdate || message ? DEFAULT_GOOGLE_DO_NOT_SEND_UPDATE : GOOGLE_UPDATES.ALL,
    };


    if (isAppVersion2) {
      params["calendar_provider_id"] = getEmailFromUserCalendarID(
        getEventUserCalendarID(event),
        allCalendars,
      );
      params["event_provider_id"] = getGoogleEventId(event);
    } else {
      params["google_calendar_id"] = getEmailFromUserCalendarID(
        getEventUserCalendarID(event),
        allCalendars,
      );
      params["google_event_id"] = getGoogleEventId(event);
    }

    return params;
  }

  constructUpdateEventDataV2({ startTime, endTime, event, message, isUpdateAllInstances = false }) {
    const args = { startTime, endTime, event, returnTimeZone: true };
    const { start, end } = this.constructStartAndEnd(args);
    const { startObject, endObject } = this.constructStartAndEndWithOriginalEventWithTimeZone({
      ...args,
      originalEvent: event,
    });

    return omitNullOrUndefinedProps({
      user_event_id: getEventUserEventID(event),
      calendar_event: {
        event_start: isUpdateAllInstances && !this.isDraggingHorizontally() ? startObject : start,
        event_end: isUpdateAllInstances && !this.isDraggingHorizontally() ? endObject : end,
      },
      user_calendar_id: getEventUserCalendarID(event),
      message,
    }, true);
  }

  constructUpdateEventData({
    startTime,
    endTime,
    event,
    message,
  }) {
    const { start, end } = this.constructStartAndEnd({
      startTime,
      endTime,
      event,
    });

    const google_calendar_event = {
      start,
      end,
    };

    const eventData = {
      user_event_id: getEventUserEventID(event),
      event: {
        google_calendar_event,
      },
    };

    if (message) {
      eventData["message"] = message;
    }

    return eventData;
  }

  constructUpdateRecurringEventDataV2({ startTime, endTime, event, message, isUpdateAllInstances = false }) {
    const args = { startTime, endTime, event, returnTimeZone: true };
    const { start, end } = this.constructStartAndEnd(args);
    const { startObject, endObject } = this.constructStartAndEndWithOriginalEventWithTimeZone({
      ...args,
      originalEvent: event,
    });

    const calendarEvent = removeGoogleAutoGeneratedKeys({
      ...event,
      recurrence: this.constructRecurrenceV2(event, startTime),
      master_event_id: undefined,
      raw_json: undefined,
      event_end: isUpdateAllInstances && !this.isDraggingHorizontally() ? endObject : end,
      event_start: isUpdateAllInstances && !this.isDraggingHorizontally() ? startObject : start,
      description: sanitizeStringAndLinkify(event?.description),
      original_start_time: undefined,
      provider_id: undefined,
      ical_uid: undefined,
      colorId: !isOutlookEvent(event) && getEventColorID(event) ? getEventColorID(event) : undefined,
    });

    return omitNullOrUndefinedProps({
      user_event_id: getEventUserEventID(event),
      calendar_event: calendarEvent,
      user_calendar_id: getEventUserCalendarID(event),
      time_zone: this.props.currentTimeZone,
      message,
    }, true);
  }

  constructRecurrenceV2(event, startTime) {
    let recurrence;
    if (!isEmptyObjectOrFalsey(event.recurrence) && Array.isArray(event.recurrence)) {
      recurrence = event.recurrence[0];
    }
    if (!recurrence && getEventRecurrence(event)) {
      recurrence = updatedRecurrenceAfterEventStartChange(event, startTime);
    }
    return Array.isArray(recurrence) ? recurrence[0] : recurrence;
  }

  determineSideBarColor(event) {
    if (
      (getEventColorID(event) && this.shouldBeSelected(event)) ||
      event.isTemporary
    ) {
      const userCalendarID = event.calendarId;

      const { allCalendars } = this.props.allCalendars;
      if (allCalendars[userCalendarID]) {
        return determineCalendarColor(allCalendars[userCalendarID]);
      }
    }

    return "white";
  }

  shouldBeSelected(event) {
    if (isEventTypeFocusBlock(event)) {
      return false;
    } else if (isOutstandingSlotEvent(event)) {
      return false;
    } else if (event.isTemporary && !isFakeMimicEvent(event)) {
      return !isAvailabilityEvent(event);
    } else if (this.props.temporaryEvents) {
      return false;
    } else if (!isEmptyObjectOrFalsey(this.props.popupEvent)) {
      return getEventUserEventID(this.props.popupEvent.event) === getEventUserEventID(event);
    } else if (!isEmptyObjectOrFalsey(this.props.hoverPopupEvent)) {
      return getEventUserEventID(this.props.hoverPopupEvent.event) === getEventUserEventID(event);
    } else if (!isEmptyObjectOrFalsey(this.props.currentPreviewedEvent)) {
      return (
        getEventUserEventID(event) === getEventUserEventID(this.props.currentPreviewedEvent)
      );
    } else if (!isEmptyObjectOrFalsey(this.props.currentHoverEvent)) {
      return getEventUserEventID(this.props.currentHoverEvent) === getEventUserEventID(event);
    }

    return false;
  }

  isEventCurrentPreviewEvent(event) {
    return (
      !isEmptyObjectOrFalsey(this.props.currentPreviewedEvent) &&
      event &&
      getEventUserEventID(this.props.currentPreviewedEvent) === getEventUserEventID(event)
    );
  }

  slotStyleGetter(jsDate, resourceId) {
    const {
      OOOBusyEventsColorAndRange,
    } = this.props.OOOBusyEventsDictionaryStore;
    const {
      isDarkMode,
    } = this.props;
    const style = {};
    let hasOOOStyling = false;
    if (OOOBusyEventsColorAndRange) {
      let columnColor;
      let lastColorRanking = 99999;
      let lastResourceID = null; // last tie breaker if rankings are equal
      const matches = OOOBusyEventsColorAndRange.filter((OOOColorAndRange) => {
        const {
          eventStart,
          eventEnd,
        } = OOOColorAndRange.range;
        const {
          resourceId: OOOResourceId,
        } = OOOColorAndRange;
        // since this gets called for every slot, we want to be super careful with this and want to use as cheap of a function as possible
        if (this.shouldDisplayResources()) {
          return isSameEmail(resourceId, OOOResourceId)
            && isInsideRBCSlotRange({jsDate, eventStart, eventEnd});
        }
        return isInsideRBCSlotRange({jsDate, eventStart, eventEnd});
      });

      matches.forEach((OOOBusyColorAndRange) => {
        const {
          color,
          resourceId: oooBusyResourceID,
          ranking,
        } = OOOBusyColorAndRange;
        if (!color ||
          (lastColorRanking > ranking) ||
          (lastColorRanking === ranking && lastResourceID < oooBusyResourceID) || // sort by email
          (lastColorRanking === ranking && lastResourceID === oooBusyResourceID && color < columnColor) // sort by color (last option)
        ) {
          columnColor = color;
          lastColorRanking = ranking;
          lastResourceID = resourceId;
        }
      });
      if (columnColor) {
        hasOOOStyling = true;
        style.background = columnColor;
        style.opacity = isDarkMode ? 0.12 : 0.2;
      }
    }

    // for shading work hours
    const {
      start,
      end,
    } = this.state.shadedAvailabiityHours;
    const hour = jsDate.getHours();
    const shouldShade =
      hour < start ||
      hour >= end;
    const className = shouldShade ? "time-slot-time-zone-slot-shade" : "";
    if (!hasOOOStyling) {
      // if (isWeekend(jsDate) && isEmptyArrayOrFalsey(this.props.temporaryTimeZones)) {
      //   if (this.props.isDarkMode) {
      //     style.background = "#1C1C24";
      //   } else {
      //     style.background = "#F7F7F7";
      //   }
      //   return { className, style };
      // }
      return { className };
    }
    return {className, style};
  }

  getTagColorGivenColorAndDim({ color, hasEventPassed }) {
    if (!hasEventPassed) {
      return color;
    }

    if (this.props.isDarkMode) {
      return dimDarkModeColor(color);
    }
    return createFadedColorIfIndexDoesNotExist(color, true);
  }

  determineEventColor({
    event,
    isCurrentPreviewEvent,
    hasEventPassed,
    defaultColor = null,
  }) {
    const normalColor =
      defaultColor || this.getEventStaticInfo(event, NORMAL_COLOR);

    const {
      currentUser,
    } = this.props;
    const {
      allCalendars,
    } = this.props.allCalendars;
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    const {
      masterAccount,
    } = this.props.masterAccount;
    /* Google handles colors without this.  Outlook needs this explicityly written. */
    const matchingUser = getMatchingUIUserForEvent({
      event,
      allCalendars,
      allLoggedInUsers,
      masterAccount,
      currentUser,
    });
    const tagColor = getEventTagColor({
      event,
      defaultColor: normalColor,
      user: matchingUser,
      currentUser,
      allLoggedInUsers,
      masterAccount,
      allCalendars,
    }); // if there's no tags -> use default color
    if (isOutlookEvent(event) && shouldUseTagColorAsEventColorForOutlookEvent({
      event,
      user: matchingUser,
      currentUser,
      allLoggedInUsers,
      masterAccount,
      allCalendars,
    })) {
      return this.getTagColorGivenColorAndDim({ color: tagColor, hasEventPassed });
    }

    /* Apply smart tag color if event color is the same as calendar color */
    if (
      shouldUseTagColorForGoogleEvent({
        event,
        allCalendars,
        user: matchingUser,
        currentUser,
        allLoggedInUsers,
        masterAccount,
      })
    ) {
      // there are tags but the default color is false
      // or even if the user has explicitly set the color but there's a priority tag -> still use priority tag color
      return this.getTagColorGivenColorAndDim({ color: tagColor, hasEventPassed });
    }

    if (isOutOfOfficeEvent(event)) {
      return this.props.isDarkMode
        ? this.getEventStaticInfo(event, DARK_MODE_FADED_COLOR)
        : this.getEventStaticInfo(event, FADED_COLOR);
    }

    if (isGroupVoteEvent(event)) {
      return hasEventPassed ? SLOTS_BACKGROUND_COLOR.DIMMED : SLOTS_BACKGROUND_COLOR.DEFAULT;
    } else if (isCurrentPreviewEvent) {
      if (this.props.isDarkMode) {
        return hasEventPassed
          ? lightenColor(this.getEventStaticInfo(event, DARK_MODE_FADED_COLOR))
          : lightenColor(normalColor);
      }
      return hasEventPassed
        ? darkenColor(this.getEventStaticInfo(event, FADED_COLOR))
        : darkenColor(normalColor);
    } else if (hasEventPassed) {
      return this.props.isDarkMode
        ? this.getEventStaticInfo(event, DARK_MODE_FADED_COLOR)
        : this.getEventStaticInfo(event, FADED_COLOR);
    } else {
      return normalColor;
    }
  }

  removeIdsFromStyleIndex(keysArray) {
    const filteredArray = removeDuplicatesFromArray(keysArray).filter(
      (id) => !!id,
    );
    removePropertiesFromEventStyleCache(filteredArray);
  }

  hasEventPassed({ event }) {
    const {
      currentTimeZone,
    } = this.props;
    return isEventInThePast({event, currentTimeZone});
  }

  eventStyleGetter(event, start, end, isSelected) {
    const { uniqueEtag } = event;

    // if it's merged and every event is transparent -> we treat it as if it's a single event for styling purposes.
    const isMerged = isMergedEvent(event) && !this.isEveryMergedEventTransparent(event);

    const {
      masterAccount,
    } = this.props.masterAccount;
    const {
      isDarkMode,
      hoverPopupEvent,
      popupEvent,
    } = this.props;
    const shouldPrint = false;

    const poppedUpEvent = getPopUpEvent({
      hoverPopupEvent,
      popupEvent,
    });

    // conditional properties based on states, etc
    const addConditionalStylingandClassNames = ({className, style}) => {
      return {
        className:
          classNames(
            className,
            isTemporaryAIEvent(event) ? "duration-300 xl-blur" : "",
            this.isMatchingHoveredEventID(event) ? "availability-color-override" : "",
            isTemporaryAIEvent(event) ? this.getTemporaryAIEventClassName(event) : "",
            this.getTemporaryEventFormEventClassName(event),
            this.getOutstandingSlotEventClassName(event),
            isAllDayWorkLocationEvent(event) ? "all-day-work-location-event" : "",
            poppedUpEvent ? "z-index-2" : "",
          ),
        style,
      };
    };

    const cachedStyle = getEventStyleCache(uniqueEtag);
    const isCurrentPreviewEvent = this.isEventCurrentPreviewEvent(event);
    const shouldBringEventToForeGround = (() => {
      if (isEventFormTemporaryEvent(event)) {
        return true;
      }
      if (isCurrentPreviewEvent) {
        return true;
      }
      const poppedUpEvent = getPopUpEvent({popupEvent});
      if (isEmptyObjectOrFalsey(poppedUpEvent)) {
        return false;
      }
      return getEventUserEventID(poppedUpEvent) === getEventUserEventID(event);
    })();
    const getUpfrontEventZIndex = () => {
      if (poppedUpEvent) {
        return 3;
      }
      return 1;
    };

    // TODO: Investigate this condition. Linear gradients are used on tentative events,
    // so this condition could fail for tentative events.
    if (
      uniqueEtag &&
      !isMerged &&
      cachedStyle &&
      !cachedStyle?.style?.background?.includes(
        "linear-gradient",
      ) // do not use linear gradient cache for non merged events
    ) {
      shouldPrint && console.log("used_cache_", {uniqueEtag, matchedStyle: cachedStyle});
      // return addConditionalStylingandClassNames(cachedStyle);
      if (shouldBringEventToForeGround && cachedStyle?.style) {
        const {
          style,
        } = cachedStyle;
        return addConditionalStylingandClassNames({
          ...(cachedStyle),
          style: {
            ...(style || {}),
            zIndex: getUpfrontEventZIndex(),
          },
        });
      }
      return addConditionalStylingandClassNames(cachedStyle);
    }

    const isEventSelected = this.shouldBeSelected(event);
    const defaultColor = this.getEventStaticInfo(event, NORMAL_COLOR);

    const selfAttendingStatus = this.getEventStaticInfo(
      event,
      SELF_ATTENDING_STATUS,
    );

    const hasEventPassed =
      shouldDimPastEvents({ masterAccount }) && this.hasEventPassed({ event }) && !isEventFormTemporaryEvent(event);

    const color = this.determineEventColor({
      event,
      isCurrentPreviewEvent,
      hasEventPassed,
      defaultColor,
    });

    const onlyPersonAttending = this.getEventStaticInfo(
      event,
      ONLY_PERSON_ATTENDING_REST_DECLINED,
    );

    const backgroundColor = determineEventBackgroundColor({
      status: selfAttendingStatus,
      color,
      otherStatus: getEventStatus(event) || null,
      onlyPersonAttending,
      isDarkMode,
      event,
    });

    const getBorderColor = () => {
      if (isAllDayWorkLocationEvent(event)) {
        return "transparent";
      }
      if (!isMerged &&
        (onlyPersonAttending || selfAttendingStatus !== attendee_event_attending)
      ) {
        return color;
      }
      return "white";
    };

    const borderColor = getBorderColor();

    const determineBorderWidth = () => {
      if (isDarkMode && isEventSelected) {
        return "1px";
      } else if (this.isInMonthlyView() && isEventSelected) {
        return "1px";
      } else if (event.displayAsAllDay) {
        if (isDarkMode) {
          return "1px";
        }
        if (this.isInMonthlyView()) {
          return "1px";
        }

        // can remove border for all day events
        if (selfAttendingStatus === ATTENDEE_EVENT_NEEDS_ACTION ||
          isUserOnlyPersonWhoAcceptedAndRestDeclined(
            event,
            selfAttendingStatus,
          )
        ) {
          return "1px";
        }

        return "0px";
      } else {
        return "1px";
      }
    };

    const getMarginTop = () => {
      if (isAllDayWorkLocationEvent(event)) {
        return "0px";
      }
      return event.displayAsAllDay ? "0px" : "-1px";
    };

    let style = {
      backgroundColor,
      borderWidth: determineBorderWidth(),
      borderColor: this.determineBorderColor({
        backgroundColor,
        borderColor,
        isEventSelected,
        onlyPersonAttending,
        hasEventPassed,
        event,
        isMerged,
      }),
      boxShadow: this.determineBoxShadow(isEventSelected),
      color: (event.isAvailability && !isOutstandingSlotEvent(event))
        ? DARK_MODE_BACKGROUND_COLOR
        : this.getTextColor({
          backgroundColor,
          color,
          isCurrentPreviewEvent,
          hasEventPassed,
          event,
        }),
      borderStyle: "solid",
      textDecorationLine:
        isDeclinedEvent(selfAttendingStatus)
          ? "line-through"
          : "none",
      marginTop: getMarginTop(),
    };

    if (isOutlookEvent(event)
      && selfAttendingStatus === ATTENDEE_EVENT_TENTATIVE
      && this.getEventStaticInfo(
        event,
        RSVP_WITHOUT_SHOW_AS,
      ) === NEEDS_ACTION_STATUS
    ) {
      // show as is tenative but has not actually responded yet
      style.borderStyle = "dashed";
      style.borderWidth = 2;
    }

    if (isDarkMode && isEventSelected) {
      // so this doesn't cause the all day container to jump size when selecting all day event
      style.boxSizing = "border-box";
    }

    let isTransparentGradientEvent = false; // transparent event with gradient border

    const isShouldShowTransparentEventBackgroundInMonthlyView = shouldShowTransparentEventBackgroundInMonthlyView({
      event,
      isInMonthlyView: this.isCalendarView(BACKEND_MONTH),
    });

    let isEventInMonthlyViewAndOutOfRange = false;
    if (isShouldShowTransparentEventBackgroundInMonthlyView) {
      const selectedDay = getSelectedDayWithBackup(this.props.selectedDay);
      if (!isSameMonth(event.eventStart, selectedDay) || !isSameMonth(event.eventEnd, selectedDay)) {
        isEventInMonthlyViewAndOutOfRange = true;
        style.backgroundColor = "transparent"; // covers both dark and light mode
      } else {
        style.backgroundColor = getDefaultBackgroundColor(isDarkMode);
      }

      style.color = isDarkMode
        ? StyleConstants.darkModeTextColor
        : StyleConstants.defaultFontColor;
      if (isEventSelected && this.isInMonthlyView()) {
        // nothing -> do not override
      } else if (!isEventSelected || !isDarkMode) {
        style.borderColor = getDefaultBackgroundColor(isDarkMode);
      }
      if (!isEventSelected && isEventInMonthlyViewAndOutOfRange) {
        if (shouldShowTransparentMergedBackground(event)) {
          style.borderColor = getDefaultMonthlyCalendarOutOfRangeColor(isDarkMode);
        } else {
          style.borderColor = "transparent";
        }
      }
    } else if (selfAttendingStatus === attendee_event_tentative) {
      const backgroundPattern = getTentativeBackgroundStyle(color);
      style = _.omit(style, "backgroundColor");
      style.background = backgroundPattern;
    }

    if (this.getEventStaticInfo(event, "shouldTruncateEvent")) {
      style.paddingTop = 0;
    }

    if (isShouldShowTransparentEventBackgroundInMonthlyView) {
      // do nothing
    } else if (isMerged) {
      // if in monthly view -> only show merged if it's an all day or display all day event
      // handle merging background color
      // gradient resource: https://blog.prototypr.io/css-only-multi-color-backgrounds-4d96a5569a20
      let backgroundColors = [
        this.isTransparentBackgroundColor(backgroundColor)
          ? color
          : (style.backgroundColor ?? backgroundColor),
      ];
      style = _.omit(style, "backgroundColor");
      let mergedEventBackgroundColors = [];
      event.mergedEvents.forEach((e) => {
        if (e.uniqueEtag !== event.uniqueEtag) {
          // do not add own background color here
          mergedEventBackgroundColors = mergedEventBackgroundColors.concat(
            this.determineEventColor({
              event: e,
              isCurrentPreviewEvent,
              hasEventPassed,
              defaultColor: this.getEventStaticInfo(e, NORMAL_COLOR),
            }),
          );
        }
      });

      backgroundColors = backgroundColors.concat(mergedEventBackgroundColors);

      if (isShouldShowTransparentEventBackgroundInMonthlyView) {
        // do nothing
        // if shouldShowTransparentMergedBackground: true -> goes down to
        // determineMergedEventBackground(backgroundColors) is true and then inside customMonthlyEvent ->
        // it overlays a white background on top of the graident only showing the border
      } else if (backgroundColors.length === 1) {
        // gradient with one color breaks so just set background color to the only existing color
        style.backgroundColor = backgroundColors[0];
      } else if (isWorkplaceEvent(event) && isAllDayEvent(event)) {
        if (style.background) {
          style = _.omit(style, "background");
        }
        style.backgroundColor = getDefaultBackgroundColor(isDarkMode);
      } else if (determineMergedEventBackground(backgroundColors)){
        isTransparentGradientEvent = true;
        style.background = determineMergedEventBackground(backgroundColors);
      }

      if (shouldShowReducedHeightTransparentMergedEvent(event)) {
        if (this.isCalendarView(BACKEND_MONTH)) {
          style.padding = "1px 1px 0px 1px";
        } else {
          style.paddingTop = 0;
        }
      } else if (
        shouldShowTransparentMergedBackground(event) &&
        (this.isCalendarView(BACKEND_MONTH) || event.displayAsAllDay)
      ) {
        style.height = "100%";
        if (isTransparentGradientEvent && this.isCalendarView(BACKEND_MONTH)) {
          style.padding = "1px 1px 0px 1px";
        } else {
          style.padding = "0px 1px 1px 0px";
        }
      }
    }

    const {
      allCalendars,
    } = this.props.allCalendars;
    if (event.displayAsAllDay) {
      const calendarColor = getCalendarColorHex(getCalendarFromUserCalendarID({
        userCalendarID: getEventUserCalendarID(event),
        allCalendars,
      }));

      const sideBarColor = isAllDayWorkLocationEvent(event)
        ? "transparent"
        : this.getTagColorGivenColorAndDim({color: calendarColor, hasEventPassed});
      if (isTransparentGradientEvent && selfAttendingStatus === NEEDS_ACTION_STATUS) {
        // since we use a gradient border, this needs to be uniquely set
        style.height = "23px";
      } else if (isAllDayWorkLocationEvent(event)) {
        style.borderWidth = "1px";
        style.paddingLeft = "0px";
        if (this.isInMonthlyView()) {
          style.height = "22px";
        } else {
          style.height = "20px";
        }
      } else {
        style.paddingLeft = "8px";
        style.height = "100%";
        if (!this.isInMonthlyView()) {
          style.borderWidth = "1px";
        }
      }

      if (!isAllDayWorkLocationEvent(event)) {
        const existingBorderColor = style.borderColor;
        if (!isEventSelected
          && shouldShowTransparentMergedBackground(event)
          && !this.isCalendarView(BACKEND_MONTH)
        ) {
          style.borderColor = `${existingBorderColor} ${existingBorderColor} ${existingBorderColor} ${sideBarColor ?? existingBorderColor}`;
        }
        style.boxSizing = "border-box";
      }
    }

    // For dev below
    // event.summaryUpdatedWithVisibility.includes('Lewis') && console.log('style', style);
    let styleClassName = isFlashingEvent(event)
      ? "event-form-temporary-event"
      : "";
    if (!this.isInMonthlyView() && isEventHiddenEvent(event)) {
      // do not make events hidden on monthly view
      styleClassName += " hidden-event-container";
      if (color) {
        if (!style.background) {
          // only set the background color if there's no background set
          style.backgroundColor = color;
        }
      }
      if (event.displayAsAllDay) {
        // width is set automatically by dnd for non-all day events
        style.height = "22px";
        style.width = "10px";
      }
      if (!isEventSelected) {
        style.borderColor = isDarkMode ? DARK_MODE_BACKGROUND_COLOR : "white";
      }
    }

    if (uniqueEtag) {
      addEventStyleToCache({uniqueEtag, eventClassnameAndStyle: {className: styleClassName, style}});
    }

    if (shouldPrint) {
      console.log("mainCalendar_style_", { style, event });
    }
    if (shouldBringEventToForeGround) {
      return addConditionalStylingandClassNames({
        className: styleClassName,
        style: {
          ...style,
          zIndex: getUpfrontEventZIndex(),
        },
      });
    }
    return addConditionalStylingandClassNames({className: styleClassName, style});
  }

  getOutstandingSlotEventClassName(event) {
    if (!isOutstandingSlotEvent(event)) {
      return "";
    }

    if (this.isMatchingHoveredSlotsSlug(event)) {
      return "outstanding-slot-event outstanding-slot-event-hovered";
    }

    return "outstanding-slot-event";
  }

  getTemporaryAIEventClassName(event) {
    if (!isTemporaryAIEvent(event)) {
      return "";
    }

    if (this.isMatchingHoveredEventID(event)) {
      return "temporary-availability-border-override";
    }

    return "dash-border-override";
  }

  getTemporaryEventFormEventClassName(event) {
    if (!isFindTimeEventFormEvent(event)) {
      return "";
    }
    const defaultClassNames = "width-100-percent-important left-0px-important z-index-1-important";
    if (this.isMatchingHoveredEventID(event)) {
      return classNames(defaultClassNames, "temporary-availability-border-override");
    }
    const {
      hoveredEventID,
    } = this.props.temporaryStateStore;
    if (hoveredEventID) {
      // dim the other temporary events
      return classNames(defaultClassNames, "dash-border-invisible-override", "dimmed-temporary-event");
    }
    return classNames(defaultClassNames, "dash-border-override", "event-form-temporary-event");
  }

  isMatchingHoveredEventID(event) {
    const {
      hoveredEventID,
    } = this.props.temporaryStateStore;
    return getClientEventID(event) === hoveredEventID;
  }

  isMatchingHoveredSlotsSlug(event) {
    const { hoveredSlotsSlug } = this.props.temporaryStateStore;
    return event.slotSlug && (event.slotSlug === hoveredSlotsSlug);
  }

  isTransparentBackgroundColor(backgroundColor) {
    const calendarBackgroundColor = getDefaultBackgroundColor(this.props.isDarkMode);
    return backgroundColor === calendarBackgroundColor;
  }

  mergeSameEvents({
    eventList,
    inputView = null,
    fromWhere,
  }) {
    const { allCalendars } = this.props.allCalendars;
    const {
      masterAccount,
    } = this.props.masterAccount;
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;

    return mergeSameEvents({
      eventList,
      currentUser: this.props.currentUser,
      mergeBasedOnResourceID: isNullOrUndefined(inputView)
        ? this.isInSplitView()
        : inputView === 1, // for day view -> we don't want to merge events that belong to difference resourceID which is used to split day view
      updatePreviewFunction: (e) => this.updatePreviewEventIfSameEvent(e),
      currentUserUserCalendarID: getUserCalendarIDFromEmail({
        email: getUserEmail(this.props.currentUser),
        allCalendars,
        allLoggedInUsers,
        masterAccount,
      }),
      allCalendars: allCalendars,
      masterAccount,
      fromWhere,
      allLoggedInUsers,
    });
  }

  updatePreviewEventIfSameEvent(event) {
    if (
      !isEmptyObjectOrFalsey(this.props.currentPreviewedEvent) &&
      this.props.currentPreviewedEvent.uniqueEtag === event.uniqueEtag
    ) {
      Broadcast.publish("UPDATE_PREVIEW_EVENT", event);
    }
  }

  determineBoxShadow(isEventSelected) {
    if (!isEventSelected || this.props.isDarkMode || this.isInMonthlyView()) {
      return "";
    }

    return "0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.2)";
  }

  getTextColor(param) {
    let { backgroundColor, color, event, isCurrentPreviewEvent, hasEventPassed } =
      param;

    if (isOutstandingSlotEvent(event)) {
      return "white";
    }
    const {
      isDarkMode,
    } = this.props;

    if (
      backgroundColor === "#ffffff" ||
      backgroundColor === "white" ||
      backgroundColor === StyleConstants.darkModeBackgroundColor
    ) {
      // we want to use color here instead of backgroundColor because background color in this if statement
      // is always goign to be the color of the calendar since this is for "waiting for rsvp" events
      // for these events, we see if the event color has enough contrast against the calendar background
      if (isTextColorTooBrightAgainstWhiteBackground(color) && !isDarkMode) {
        if (hasEventPassed) {
          return FADED_WHITE_MODE_COLOR_FOR_DIMMED_PAST_EVENTS;
        }
        return DEFAULT_FONT_COLOR;
      }
      if (isColorTooDarkAgainstDarkBackground(color) && isDarkMode) {
        if (hasEventPassed) {
          return FADED_GREY_TEXT_COLOR_DARK_MODE;
        }
        return "white";
      }
      return color;
    }

    return getFontColorForBackgroundColor({
      backgroundColor,
      hasEventPassed,
      isCurrentPreviewEvent,
    });
  }

  determineBorderColor({
    backgroundColor,
    borderColor,
    isEventSelected,
    hasEventPassed,
    isMerged,
    event,
  }) {
    const {
      isDarkMode,
    } = this.props;
    if (isEventSelected) {
      if (hasEventPassed) {
        if (isDarkMode) {
          return MEDIUM_GRAY;
        }
        return DEFAULT_FONT_COLOR;
      }
      if (isDarkMode) {
        return "white";
      }
      return DARK_MODE_BACKGROUND_COLOR;
    }

    if (
      !isMerged &&
      (backgroundColor === "white" ||
        backgroundColor === DARK_MODE_BACKGROUND_COLOR)
    ) {
      return borderColor;
    }

    if (isEventSelected) {
      if (shouldShowTransparentMergedBackground(event)) {
        return "transparent";
      }
      return backgroundColor;
    }

    if (isAllDayWorkLocationEvent(event)) {
      if (!this.props.isDarkMode) {
        return "transparent";
      }
      return isEventSelected ? "white" : "transparent";
    }

    return getDefaultBackgroundColor(this.props.isDarkMode);
  }

  // Update color
  setColorRecurringModal(
    colorId,
    originalRecurringEvent,
    updatedRecurringEvent,
  ) {
    this.setState({
      modalTitle: "Update recurring event",
      modalWidth: 350,
      modalContent: UPDATE_RECURRING_COLOR_MODAL,
      colorId: colorId,
      originalRecurringEvent,
      updatedRecurringEvent,
      shouldDisplayModal: true,
    });
  }

  setCategoriesRecurringModal(
    categories,
    originalRecurringEvent,
    updatedRecurringEvent,
  ) {
    this.setState({
      modalTitle: "Update recurring event",
      modalWidth: 350,
      modalContent: UPDATE_RECURRING_CATEGORIES_MODAL,
      categories,
      originalRecurringEvent,
      updatedRecurringEvent,
      shouldDisplayModal: true,
    });
  }

  // things like free/busy and transparency can be edited on the user's version of the event
  updateForOwnVersionOfEvent({
    event,
    updatedProperties,
  }) {
    if (getEventMasterEventID(event)) {
      // is recurring event
      const originalRecurrenceEvent = getOriginalRecurringEventFromIndex(
        event,
        this.props.originalRecurrenceEventIndex,
      );
      this.setState({
        modalTitle: "Update recurring event",
        modalWidth: 350,
        modalContent: UPDATE_SINGLE_PROPERTY_ON_RECURRING_EVENT_MODAL,
        originalRecurringEvent: originalRecurrenceEvent,
        updatedRecurringEvent: event,
        eventData: updatedProperties,
        shouldDisplayModal: true,
      });
      return;
    }
    // single event -> update
    this.updateSingleEvent({
      event,
      updatedProperties,
    });
  }

  setTagsRecurringModal(
    originalRecurringEvent,
    updatedRecurringEvent,
    eventData,
  ) {
    this.setState({
      modalTitle: "Update recurring event",
      modalWidth: 350,
      modalContent: UPDATE_RECURRING_TAGS_MODAL,
      originalRecurringEvent,
      updatedRecurringEvent,
      eventData,
      shouldDisplayModal: true,
    });
  }

  setRecurringEventTransparency(
    transparency,
    originalRecurringEvent,
    updatedRecurringEvent,
  ) {
    this.setState({
      modalTitle: "Update recurring event",
      modalWidth: 350,
      modalContent: UPDATE_RECURRING_TRANSPARENCY_MODAL,
      transparency,
      originalRecurringEvent,
      updatedRecurringEvent,
      shouldDisplayModal: true,
    });
  }

  updateSingleEvent({
    event,
    updatedProperties,
    sendUpdates,
  }) {
    const {
      allCalendars,
    } = this.props.allCalendars;
    const path = "events";
    const params = {
      sendUpdates: sendUpdates ?? DEFAULT_GOOGLE_DO_NOT_SEND_UPDATE,
      calendar_provider_id: getEmailFromUserCalendarID(
        getEventUserCalendarID(event),
        allCalendars,
      ),
      event_provider_id: getGoogleEventId(event),
    };

    const queryParams = constructQueryParams(params);
    const url = constructRequestURL(path, true) + `?${queryParams}`;

    const payloadData = {
      body: JSON.stringify(updatedProperties),
    };
    Broadcast.publish(BROADCAST_VALUES.UPDATE_EVENT, {
      url,
      payloadData,
      originalEvent: event,
      userEmail: getUserEmailFromEvent(event, allCalendars),
    });
    Broadcast.publish(
      SET_DISAPPEARING_NOTIFICATION_MESSAGE,
      "Updating event...",
    );
    this.removePreviewEvent();
    this.closeModal();
    if (this.state.eventToBeUpdated) {
      this.setState({ eventToBeUpdated: null });
    }
  }

  async updateThisAndFollowingEvents({
    event,
    eventData,
  }) {
    const {
      originalRecurringEvent,
    } = this.state;
    let inputOriginalEvent = originalRecurringEvent;
    const {
      allCalendars,
    } = this.props.allCalendars;
    const {
      selectedDay,
      weekStart,
      selectedCalendarView,
    } = this.props;
    if (isEmptyObjectOrFalsey(event)) {
      Broadcast.publish(SET_DISAPPEARING_NOTIFICATION_MESSAGE, GENERIC_ERROR_MESSAGE);
      this.closeModal();
      return;
    }

    if (!originalRecurringEvent) {
      // fetch for original event
      const fetchedOriginalEvent = await this.fetchForOriginalRecurringEvent(event);
      if (!this._isMounted) {
        return;
      }
      if (!fetchedOriginalEvent) {
        Broadcast.publish(SET_DISAPPEARING_NOTIFICATION_MESSAGE, GENERIC_ERROR_MESSAGE);
        this.closeModal();
        return;
      }
      inputOriginalEvent = fetchedOriginalEvent;
    }

    const timeWindows = determineSyncWindow({ selectedDay, selectedCalendarView, weekStart });
    const masterEventStartDate = getEventStart(inputOriginalEvent);
    const masterEventEndDate = getEventEnd(inputOriginalEvent);

    const queryParams = constructQueryParams({
      timeMin: formatTimeForBackendJsDate(timeWindows.minDate, weekStart, true),
      timeMax: formatTimeForBackendJsDate(timeWindows.maxDate, weekStart, false),
      calendar_provider_id: getEmailFromUserCalendarID(getEventUserCalendarID(inputOriginalEvent), allCalendars),
      event_provider_id: getGoogleEventId(event),
      master_event_id: getGoogleEventId(inputOriginalEvent),
      master_event_start_date: masterEventStartDate.dateTime,
      master_event_end_date: masterEventEndDate.dateTime,
    });

    const path = isOutlookEvent(event) ? "recurring_events/following" : "recurring_events/single_resource/following";

    Broadcast.publish(BROADCAST_VALUES.UPDATE_INSTANCES, {
      url: `${constructRequestURL(path, true)}?${queryParams}`,
      body: { ...eventData },
      originalEvent: event,
    }, true);
    this.removePreviewEvent();
    this.closeModal();
  }

  async fetchForOriginalRecurringEvent(event) {
    const {
      allCalendars,
    } = this.props.allCalendars;
    const {
      currentUser,
    } = this.props;
    try {
      const response = await fetchEvent(
        getEventUserCalendarID(event) || event.calendarId,
        getEventMasterEventID(event),
        getEventUserEmail(event) || getUserEmailFromEvent(event, allCalendars) || getUserEmail(currentUser),
      );
      if (!this._isMounted) {
        return;
      }
      if (isEmptyObjectOrFalsey(response)) {
        return;
      }
      const { event: originalEvent } = response;
      return originalEvent;
    } catch (error) {
      handleError(error);
    }
  }

  async updateAllEvents({
    event,
    eventData,
  }) {
    const {
      originalRecurringEvent,
    } = this.state;
    let inputOriginalEvent = originalRecurringEvent;
    const {
      allCalendars,
    } = this.props.allCalendars;
    const {
      selectedDay,
      weekStart,
      selectedCalendarView,
      currentTimeZone,
    } = this.props;
    if (isEmptyObjectOrFalsey(event)) {
      Broadcast.publish(SET_DISAPPEARING_NOTIFICATION_MESSAGE, GENERIC_ERROR_MESSAGE);
      this.closeModal();
      return;
    }

    if (!originalRecurringEvent) {
      // fetch for original event
      const fetchedOriginalEvent = await this.fetchForOriginalRecurringEvent(event);
      if (!this._isMounted) {
        return;
      }
      if (!fetchedOriginalEvent) {
        Broadcast.publish(SET_DISAPPEARING_NOTIFICATION_MESSAGE, GENERIC_ERROR_MESSAGE);
        this.closeModal();
        return;
      }
      inputOriginalEvent = fetchedOriginalEvent;
    }

    const path = isOutlookEvent(event) ? "recurring_events/all" : "recurring_events/single_resource/all";
    const timeWindows = determineSyncWindow({ selectedDay, selectedCalendarView, weekStart });
    const masterEventStartDate = getEventStart(inputOriginalEvent);
    const masterEventEndDate = getEventEnd(inputOriginalEvent);

    const queryParams = constructQueryParams({
      sendUpdates: GOOGLE_UPDATES.NONE,
      timeMin: formatTimeForBackendJsDate(timeWindows.minDate, weekStart, true),
      timeMax: formatTimeForBackendJsDate(timeWindows.maxDate, weekStart, false),
      ical_uid: getEventICalUID(event),
      time_zone: currentTimeZone,
      calendar_provider_id: getEmailFromUserCalendarID(getEventUserCalendarID(inputOriginalEvent), allCalendars),
      event_provider_id: getGoogleEventId(event),
      master_event_id: getGoogleEventId(inputOriginalEvent),
      master_event_start_date: masterEventStartDate.dateTime,
      master_event_end_date: masterEventEndDate.dateTime,
    });

    Broadcast.publish(BROADCAST_VALUES.UPDATE_INSTANCES, {
      url: `${constructRequestURL(path, true)}?${queryParams}`,
      body: { ...eventData },
      originalEvent: event,
    }, true);
    this.removePreviewEvent();
    this.closeModal();
  }

  updateRecurrenceOnUpdateSingleProperty(response, sendUpdates) {
    const { updatedRecurringEvent: event, eventData } = this.state;

    switch (response) {
      case EDIT_RECURRING_INSTANCE_ONLY:
        this.updateSingleEvent({
          event,
          updatedProperties: eventData,
          sendUpdates,
        });
        break;
      case EDIT_RECURRING_FOLLOWING_EVENTS:
        this.updateThisAndFollowingEvents({
          event,
          eventData,
        });
        break;
      case EDIT_RECURRING_ALL_INSTANCES:
        this.updateAllEvents({
          event,
          eventData,
        });
        break;
      default:
        break;
    }

    this.closeModal();
  }

  updateRecurrenceTransparency(response) {
    this.recurringEventSingleResourceUpdate({
      newValue: this.state.transparency,
      resourceName: TRANSPARENCY_RESOURCE,
      response,
    });
  }

  updateRecurrenceColor(response) {
    this.recurringEventSingleResourceUpdate({
      newValue: this.state.colorId,
      resourceName: COLOR_ID_RESOURCE,
      response,
    });
  }

  updateRecurrenceCategory(response) {
    const { categories } = this.state;

    if (Array.isArray(categories)) {
      this.recurringEventSingleResourceUpdate({
        newValue: categories,
        resourceName: "categories",
        response,
      });
    } else {
      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        GENERIC_ERROR_MESSAGE,
      );
    }
  }

  addTemporarySearchedEvents(params) {
    const {
      eventFormEvents,
      meetWithEvents,
      filterEventFormExistingCalendarIds,
      filterMeetWithExistingCalendarIds,
      filterOutEventFormEventsWithCalendarId,
      filterOutMeetWithEventsWithCalendarId,
    } = params;
    // Start off with temporary events if temporary events exist
    let temporaryEvents = this.getTemporaryEvents();

    // if existingCalendarIds exist, need to filter through

    const filterExistingCalendarIds = (events, filterCalendarIds) => {
      if (filterCalendarIds && events?.length > 0) {
        return events.filter((e) => stringArrayContainsIgnoringCase(filterCalendarIds, e.calendarId) || stringArrayContainsIgnoringCase(filterCalendarIds, e.temporaryCalendarId));
      }

      return events ?? [];
    };

    const filterOutExistingCalendarId = (events, filterOutCalendarId) => {
      if (filterOutCalendarId && events?.length > 0) {
        return events.filter((e) => filterOutCalendarId !== e.calendarId);
      }

      return events ?? [];
    };

    if (eventFormEvents) {
      temporaryEvents = temporaryEvents.concat(
        this.filterOutActiveCalendars(eventFormEvents),
      );
      this._eventFormEvents =
        eventFormEvents.length === 0 ? [] : eventFormEvents;
    } else {
      // didn't pass any events back, default to using this._eventFormEvents
      // filter with calendarIds that is passed in
      let updatedEventFormEvents;
      if (filterOutEventFormEventsWithCalendarId) {
        updatedEventFormEvents = filterOutExistingCalendarId(
          this._eventFormEvents,
          filterOutEventFormEventsWithCalendarId,
        );
      } else {
        updatedEventFormEvents = filterExistingCalendarIds(
          this._eventFormEvents,
          filterEventFormExistingCalendarIds,
        );
      }

      temporaryEvents = temporaryEvents.concat(
        this.filterOutActiveCalendars(updatedEventFormEvents),
      );
      this._eventFormEvents = updatedEventFormEvents;
    }

    if (meetWithEvents) {
      temporaryEvents = temporaryEvents.concat(
        this.filterOutActiveCalendars(meetWithEvents),
      );
      this._meetWithEvents = meetWithEvents.length === 0 ? [] : meetWithEvents;
    } else {
      let updatedMeetWithEvents;
      if (filterOutMeetWithEventsWithCalendarId) {
        updatedMeetWithEvents = filterOutExistingCalendarId(
          this._meetWithEvents,
          filterOutMeetWithEventsWithCalendarId,
        );
      } else {
        updatedMeetWithEvents = filterExistingCalendarIds(
          this._meetWithEvents,
          filterMeetWithExistingCalendarIds,
        );
      }
      temporaryEvents = temporaryEvents.concat(
        this.filterOutActiveCalendars(updatedMeetWithEvents),
      );
      this._meetWithEvents = updatedMeetWithEvents;
    }
    const {
      setMeetWithEvents,
      setEventFormEvents,
    } = this.props.temporaryStateStore;
    setMeetWithEvents(this._meetWithEvents);
    setEventFormEvents(this._eventFormEvents);

    const mimicEvents = this.getMimicEvents();
    if (!isEmptyArrayOrFalsey(mimicEvents)) {
      temporaryEvents = temporaryEvents.concat(mimicEvents);
    }

    const temporaryEventsWithStaticInformation =
      this.updateEventsStaticInformation(temporaryEvents);
    this.setState({
      formattedEventsWithTemporaryEvents: this.filterOutEventsNotInCurrentView(
        this.mergeSameEvents({
          eventList: this.state.formattedEvents.concat(
            temporaryEventsWithStaticInformation,
          ),
          fromWhere: "_addTemporarySearchedEvents",
        }),
      ),
    });
  }

  getFirstTemporaryEvent() {
    return this.props.temporaryEvents &&
      this.props.temporaryEvents[0];
  }

  isSameAsTemporaryEvent(event) {
    const firstTemporaryEvent = this.getFirstTemporaryEvent();
    return (
      firstTemporaryEvent &&
      getEventUserEventID(firstTemporaryEvent) &&
      event &&
      getEventUserEventID(event) === getEventUserEventID(firstTemporaryEvent)
    );
  }

  getDefaultUserTimeZone() {
    const {
      currentUser,
    } = this.props;

    const {
      masterAccount,
    } = this.props.masterAccount;

    return getDefaultUserTimeZone({ masterAccount, user: currentUser });
  }

  openModalDefaultTimeZone() {
    const defaultUserTimeZone = this.getDefaultUserTimeZone();
    const {
      currentUser,
    } = this.props;
    const {
      masterAccount,
    } = this.props.masterAccount;

    // TODO: only show this if user explicitly set a default user time zone
    if (shouldUseTimeZoneOverride({ masterAccount, user: currentUser })) {
      // no point in showing modal if user is maestro user and the default time zone is different
      return;
    }

    const { defaultBrowserTimeZone, setDefaultBrowserTimeZone } = this.props;
    this.setState({ oldTimeZone: defaultBrowserTimeZone });

    setDefaultBrowserTimeZone(defaultUserTimeZone);

    this.setModalContent(
      "New time zone detected",
      450,
      SHOW_DIFFERENT_TIME_ZONE_WARNING,
    );
  }

  isCalendarView(view) {
    return view === this.props.selectedCalendarView;
  }

  isCalendarViewDayView() {
    return this.isCalendarView(1);
  }

  getEmailFromEvent(event, skipAddForCurrentUser = false) {
    const email = getEmailBasedOnCalendarId(
      event,
      this.props.allCalendars.allCalendars,
    );

    if (!skipAddForCurrentUser && !email) {
      return getUserEmail(this.props.currentUser);
    }

    return email;
  }

  createEventsWindow() {
    const {
      selectedDay,
      weekStart,
      selectedCalendarView,
    } = this.props;
    return createWindow({
      windowJSDate: getSelectedDayWithBackup(selectedDay),
      isMonth: selectedCalendarView === BACKEND_MONTH,
      weekStart,
      selectedCalendarView,
    });
  }

  isInMonthlyView() {
    const {
      selectedCalendarView,
    } = this.props;
    return isInMonthlyView(selectedCalendarView);
  }

  isInSplitView() {
    const {
      isSplitView,
    } = this.props.appSettings;
    if (this.isInMonthlyView()) {
      return false;
    }
    if (isSplitView) {
      return true;
    }
    return this.isCalendarViewDayView();
  }

  shouldDisplayResources() {
    const {
      resourceMap,
    } = this.state;
    return this.isInSplitView() && resourceMap?.length > 1;
  }

  setLastDeclinedEvent(event) {
    if (isEmptyObjectOrFalsey(event)) {
      return;
    }

    this.setState({
      lastSelectedEventId: null,
      lastDeclinedEvent: event,
    });
  }

  setLastSelectedEvent(event) {
    // used to track events for arrow keys
    if (isEmptyObjectOrFalsey(event)) {
      return;
    }

    this.setState({
      lastSelectedEventId: getGCalEventId(event),
      lastDeclinedEvent: null,
    });
  }

  resetEvents(inputView = null) {
    const temporaryEvents = this.getTemporaryMeetWithEventFormAndMimicEvents();
    const temporaryEventsWithStaticInformation =
      this.updateEventsStaticInformation(temporaryEvents);
    const eventsInView = this.filterOutEventsNotInCurrentView(
      this.mergeSameEvents({
        eventList: this.state.formattedEvents.concat(temporaryEventsWithStaticInformation),
        inputView,
        fromWhere: "_resetEvents",
      }),
      inputView,
    );

    const determineWeekView = () => {
      if (inputView) {
        return 7 === inputView;
      }
      return this.isCalendarView(7);
    };

    this.setState({
      formattedEventsWithTemporaryEvents: eventsInView,
      calendarViews: {
        week: determineWeekView() ? true : CustomWeek,
        work_week: true,
        day: true,
        month: true,
      },
    });
    return eventsInView;
  }

  updateCalendarViews() {
    this.setState({
      calendarViews: {
        week: this.isCalendarView(7) ? true : CustomWeek,
        work_week: true,
        day: true,
        month: true,
      },
    });
  }

  filterOutEventsNotInCurrentView(allEvents, inputView = null) {
    if (isEmptyArrayOrFalsey(allEvents)) {
      return [];
    }

    const { start, end } = this.getStartEndDateOfCurrentView(inputView);

    let events = [];
    let filteredOutEvents = []; // events not in view
    allEvents.forEach((e) => {
      if (
        isSameOrBeforeDay(e.eventStart, end) &&
        isSameOrAfterDay(e.eventEnd, start)
      ) {
        events = events.concat(e);
      } else {
        filteredOutEvents = filteredOutEvents.concat(e);
      }
    });

    this.getContactsFromEvents(filteredOutEvents);

    return orderMainCalendarEvents(events);
  }

  getContactsFromEvents(events) {
    if (isEmptyArrayOrFalsey(events)) {
      return;
    }

    const {
      allCalendars,
    } = this.props.allCalendars;

    contactBroadcast.publish("GET_EVENT_CONTACTS", allCalendars, events);
  }

  getEventsInCurrentView(broadcastLocation = "") {
    let events = this.filterOutEventsNotInCurrentView(
      this.state.formattedEventsWithTemporaryEvents,
    );

    if (broadcastLocation) {
      Broadcast.publish(broadcastLocation, events);
    }

    return events;
  }

  getStartEndDateOfCurrentView(inputView = null, inputSelectedDay = null) {
    const calendarView = inputView ?? this.props.selectedCalendarView;
    const isCalendarView = (view) => {
      return view === calendarView;
    };

    const selectedDay = isValidJSDate(inputSelectedDay)
      ? inputSelectedDay
      : this.props.selectedDay;

    if (isCalendarView(1)) {
      return { start: startOfDay(selectedDay), end: endOfDay(selectedDay) };
    } else if (isCalendarView(4)) {
      return { start: startOfDay(selectedDay), end: endOfDay(addDays(selectedDay, 3)) };
    } else if (isCalendarView(ROLLING_SEVEN_DAY)) {
      return { start: startOfDay(selectedDay), end: endOfDay(addDays(selectedDay, 6)) };
    } else if (isCalendarView(BACKEND_MONTH)) {
      return {
        start: startOfDay(getFirstDayOfMonthlyCalendarJSDate(
          selectedDay,
          this.props.weekStart,
        )),
        end: endOfDay(getLastDayOfMonthlyCalendarJSDate(
          selectedDay,
          this.props.weekStart,
        )),
      };
    } else {
      // default to week view
      return {
        start: startOfDay(getFirstDayOfWeekJsDate(selectedDay, this.props.weekStart)),
        end: endOfDay(getLastDayOfWeekJsDate(selectedDay, this.props.weekStart)),
      };
    }
  }

  determineMainCalendarWidthClassName() {
    if (this.props.isMobileView) {
      return "w-full";
    }
    const {
      reverseSlotsText,
    } = this.props.temporaryStateStore;
    const {
      actionMode,
    } = this.props;

    if (shouldHideRightHandSide({
      actionMode,
      hideRightHandSideBar: this.props.hideRightHandSidebar,
      reverseSlotsText,
    })) {
      return "w-full";
    } else {
      return "main-calendar-day-view";
    }
  }

  reformatAllEvents() {
    clearEventStaticCache();

    const {
      formattedEventsWithStaticInformation,
      combinedEvents,
    } =
      this.updatedFormattedAndTemporaryEventsWithStaticInformation(
        this.state.formattedEvents,
        this.getTemporaryMeetWithEventFormAndMimicEvents(),
      );

    this.setState({
      formattedEvents: formattedEventsWithStaticInformation,
      formattedEventsWithTemporaryEvents: combinedEvents,
    });
  }

  shouldUpdateRBCCalendarView(
    prevSelectedCalendarView,
    currentSelectedCalendarView,
  ) {
    return (
      prevSelectedCalendarView &&
      currentSelectedCalendarView &&
      prevSelectedCalendarView !== currentSelectedCalendarView &&
      (prevSelectedCalendarView === 7 || currentSelectedCalendarView === 7)
    );
  }

  shouldHideUpdateRecurringAll() {
    const { temporaryEvents } = this.props;
    const { eventToBeUpdated } = this.state;

    if (!temporaryEvents || !temporaryEvents[0]?.eventStart || !eventToBeUpdated?.event?.eventStart) {
      return false;
    }

    const preDraggedEvent = eventToBeUpdated.event;
    const currentEvent = temporaryEvents[0];

    return (
      getISODay(preDraggedEvent.eventStart) !==
      getISODay(currentEvent.eventStart)
    );
  }

  isDraggingHorizontally() {
    const { temporaryEvents } = this.props;
    const { eventToBeUpdated } = this.state;

    if (!temporaryEvents || !temporaryEvents[0]?.eventStart || !eventToBeUpdated?.event?.eventStart) {
      return false;
    }

    const preDraggedEvent = eventToBeUpdated.event;
    const currentEvent = temporaryEvents[0];

    return (
      getISODay(preDraggedEvent.eventStart) !==
      getISODay(currentEvent.eventStart)
    );
  }

  cancelCreateFocusBlock() {
    batch(() => {
      this.props.removeCreateFocusModeBlock(false);
      this.props.setTemporaryEvent(null);
    });
  }

  removeHoverPopupOnScroll() {
    const weeklyCalendar = document.getElementsByClassName("rbc-time-content")[0];

    if (!weeklyCalendar) {
      return;
    }

    weeklyCalendar?.removeEventListener("scroll", this.removeHoverPopUpEvent);
    weeklyCalendar?.addEventListener("scroll", this.removeHoverPopUpEvent);
  }

  removeHoverScrollListener() {
    const weeklyCalendar = document.getElementsByClassName("rbc-time-content")[0];

    if (!weeklyCalendar) {
      return;
    }

    weeklyCalendar?.removeEventListener("scroll", this.removeHoverPopUpEvent);
  }

  removePopUpEvent() {
    if (!isEmptyObjectOrFalsey(this.props.popupEvent)) {
      this.props.removePopupEvent();
    }

    this.removeHoverPopUpEvent();
  }

  removeHoverEvent() {
    if (!isEmptyObjectOrFalsey(this.props.currentHoverEvent)) {
      this.props.removeCurrentHoverEvent();
    }

    this.removeHoverPopUpEvent();
  }

  removeHoverPopUpEvent() {
    if (isEmptyObjectOrFalsey(this.props.hoverPopupEvent)) {
      return;
    }

    this.props.removeHoverPopupEvent();
  }

  removePreviewEvent() {
    if (!isEmptyObjectOrFalsey(this.props.currentPreviewedEvent)) {
      this.props.removePreviewedEvent();
    }

    if (!isEmptyObjectOrFalsey(this.props.currentHoverEvent)) {
      this.props.setCurrentHoverEvent(null);
    }

    this.removePopUpEvent();

    this.removeHoverPopUpEvent();
  }

  removeAllEvents() {
    this.setState({
      formattedEvents: [],
      formattedEventsWithTemporaryEvents: [],
    });
  }

  addMimicDeletedEvent(event) {
    const userEventID = getEventUserEventID(event);
    if (!userEventID) {
      return;
    }

    this._mimicDeletedUserEventIDs = this._mimicDeletedUserEventIDs.concat(userEventID);
  }

  removeMimicDeletedEvent(event) {
    this._mimicDeletedUserEventIDs = this._mimicDeletedUserEventIDs.filter(userEventID => userEventID !== getEventUserEventID(event));
  }

  changeViewToDayView(date) {
    this._hasTimeZoneChanged = true;
    // on click show more, RBC auto changes view to day which causes a bunch of UI issues for us
    // we need to create an override view to prevent this
    this.setState({renderCount: this.state.renderCount + 1}, () => {
      const formattedDate = startOfHour(setHours(date, 8));
      mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.DETERMINE_CALENDAR_VIEW_CHANGE, 1, formattedDate);
    });
  }

  toggleOnGlowOnButton(type) {
    this.setState({
      glowOnButton: type,
    }, () => {
      // this._glowButtonTimeout = setTimeout(() => {
      // if (!this._isMounted) {
      //   return;
      // }
      // this.removGlowOnButton();
      // }, 10 * SECOND_IN_MS)
    });
  }

  getAllBusyEvents() {
    return this.state.formattedEvents
      .concat(this._eventFormEvents ?? [])
      .concat(this._meetWithEvents ?? [])
      .concat(this.getMimicEvents())
      .filter(event => isBusyEvent(event));
  }

  async showFreeTimesForUser({
    inputUser,
    inputDuration,
    shouldJumpToFirstDate,
  }) {
    const {
      masterAccount,
    } = this.props.masterAccount;
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    const {
      allCalendars,
    } = this.props.allCalendars;
    const {
      selectedDay,
      currentTimeZone,
      currentUser,
      weekStart,
      selectedCalendarView,
    } = this.props;
    const user = inputUser ?? currentUser;
    // mimics what backend passes back so we can use the same functions to get free busy
    // comes in array of {start: '2023-11-07T04:00:00.000Z', end: '2023-11-07T05:00:00.000z'};
    const allBusyEvents = this.getAllBusyEvents();

    const busySlots = immutablySortArray(allBusyEvents.map(event => {
      return {
        start: getDefaultStartDateTime(event),
        end: getDefaultEndDateTime(event),
        title: getEventTitle(event), // mostly used for testing
      };
    }), (a, b) => parseISO(a.start) - parseISO(b.start));

    const defaultMeetingLength = getDefaultMeetingLength({ masterAccount, user });
    const duration = inputDuration ?? (defaultMeetingLength || 30);

    // freeTimes comes in as an array of objects
    // {
    //   "start_time": "2024-01-22T14:00:00.000Z",
    //   "end_time": "2024-01-22T16:00:00.000Z"
    // }
    const getUserWorkHours = () => {
      const {
        temporaryTimeZones,
      } = this.props;
      if (temporaryTimeZones?.length > 0) {
        const {
          start: startWorkHour,
          end: endWorkHour,
        } = this.getCalendarShadedHours();
        return {startWorkHour, endWorkHour};
      }
      return getWorkHours({ masterAccount, user });
    };
    const freeTimes = getFreeTimesGivenBusySlots({
      masterAccount,
      user,
      currentDate: selectedDay,
      currentTimeZone,
      duration,
      busySlots,
      weekStart,
      selectedCalendarView,
      workHours: getUserWorkHours(),
    });

    const getResourceEmail = () => {
      const userEmail = getUserEmail(user);
      const activeCalendarsEmails = getActiveSelectedCalendars(allCalendars).map(calendar => getCalendarEmail(calendar));
      if (stringArrayContainsIgnoringCase(activeCalendarsEmails, userEmail)) {
        // if matching calendar is active, user userEmail
        return userEmail;
      }
      const writableCalendars = filterForAllWritableCalendars(allCalendars);
      // find best guess since it's not active
      const bestGuessWritableCalendar = findBestGuessWritableCalendar({
        writableCalendars,
        currentUser,
        masterAccount,
        allLoggedInUsers,
        allCalendars,
      });
      if (bestGuessWritableCalendar) {
        return getCalendarEmail(bestGuessWritableCalendar);
      }

      return userEmail;
    };

    const resourceEmail = getResourceEmail();
    const updatedTemporaryEvents = freeTimes.map((freeTime, index) => {
      return createEventFormFindTimeTemporaryEvent({
        startTime: parseISO(freeTime.start_time),
        endTime: parseISO(freeTime.end_time),
        index,
        currentUserEmail: resourceEmail,
      });
    });
    let splittedTimes = [];
    updatedTemporaryEvents.forEach(event => {
      const newTimes = splitSlotIntoDuration({
        breakDuration: duration,
        start: event.eventStart,
        end: event.eventEnd,
        currentSlots: splittedTimes,
        type: TEMPORARY_EVENT_TYPES.CREATE_EVENT_FIND_TIME,
        currentUserEmail: resourceEmail,
      });
      splittedTimes = splittedTimes.concat(newTimes);
    });

    const firstEventStart = splittedTimes[0]?.eventStart;
    if (!firstEventStart) {
      // do nothing
    } else if (!shouldJumpToFirstDate) {
      // do nothing
    } else {
      // jump to first event
      this.props.selectDay(splittedTimes[0].eventStart);
    }

    this.setTemporaryEvents(splittedTimes);
    const {
      setHoveredEventID,
      resetHoveredEventID,
    } = this.props.temporaryStateStore;

    // below is to get rid of flash that's not syncing up
    setHoveredEventID(getClientEventID(getLastArrayElement(splittedTimes)));
    setTimeout(() => {
      if (!this._isMounted) {
        return;
      }
      resetHoveredEventID();
    }, 10);
  }

  // used for save console log testing
  // esp for bugs that are hard to reproduce
  shouldPrintDebug() {
    const {
      currentUser,
    } = this.props;
    return isInternalTeamUser(currentUser);
  }

  isEveryMergedEventTransparent(inputEvent) {
    if (!isMergedEvent(inputEvent)) {
      return false;
    }
    const {
      masterAccount,
    } = this.props.masterAccount;
    const {
      isDarkMode,
    } = this.props;
    const allEvents = [inputEvent].concat(inputEvent.mergedEvents);
    if (isEmptyArrayOrFalsey(allEvents)) {
      return true;
    }
    return allEvents.every(event => {
      const isCurrentPreviewEvent = this.isEventCurrentPreviewEvent(event);
      const hasEventPassed =
        shouldDimPastEvents({ masterAccount }) && this.hasEventPassed({ event });
      const defaultColor = this.getEventStaticInfo(event, NORMAL_COLOR);

      const color = this.determineEventColor({
        event,
        isCurrentPreviewEvent,
        hasEventPassed,
        defaultColor,
      });
      const backgroundColor = determineEventBackgroundColor({
        status: this.getEventStaticInfo(
          event,
          SELF_ATTENDING_STATUS,
        ),
        color,
        otherStatus: getEventStatus(event) || null,
        onlyPersonAttending: this.getEventStaticInfo(
          event,
          ONLY_PERSON_ATTENDING_REST_DECLINED,
        ),
        isDarkMode,
        event,
      });
      return this.isTransparentBackgroundColor(backgroundColor);
    });
  }

  deleteRecurringEvents({
    masterEventID,
    followingDate, // delete following this date
  }) {
    if (!masterEventID) {
      return;
    }
    const { formattedEvents } = this.state;
    const matchingEvents = formattedEvents.filter(e => getEventMasterEventID(e) === masterEventID);
    if (isEmptyArrayOrFalsey(matchingEvents)) {
      return;
    }
    if (!isValidJSDate(followingDate)) {
      // delete all events
      this.removeMultipleEvents({userEventIDs: matchingEvents.map(e => getEventUserEventID(e))});
      return;
    }

    const eventsToDeleteUserEventIDs = matchingEvents
      .filter(e => isSameOrAfterDay(e.eventStart, followingDate))
      .map(e => getEventUserEventID(e));
    this.removeMultipleEvents({userEventIDs: eventsToDeleteUserEventIDs});
  }

  removGlowOnButton() {
    clearTimeout(this._glowButtonTimeout);
    this.setState({
      glowOnButton: null,
    });
  }

  shouldUpdateFindTimeEvents(prevProps, prevState) {
    const {
      temporaryEvents,
    } = this.props;

    if (!isFindTimeEventFormEvent(temporaryEvents?.[0])) {
      return false;
    }
    if (!doesArrayContainMeetWithEvents(this.state.formattedEventsWithTemporaryEvents)) {
      // need this to avoid race conditions where we put temporary events back in
      return false;
    }
    if (!doesArrayContainFindTimeEvent(this.state.formattedEventsWithTemporaryEvents)) {
      return false;
    }
    if (hasArrayChangedSize(this.state.formattedEventsWithTemporaryEvents, prevState.formattedEventsWithTemporaryEvents)) {
      return true;
    }

    return !isSameDay(this.props.selectedDay,  prevProps.selectedDay); // if day changed
  }

  convertEmailToName(email) {
    const {
      currentUser,
      emailToNameIndex,
    } = this.props;
    const {
      masterAccount,
    } = this.props.masterAccount;
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    const {
      allCalendars,
    } = this.props.allCalendars;
    const {
      distroListDictionary,
    } = this.props.distroListDictionary;
    return convertEmailToName({
      email,
      currentUser,
      emailToNameIndex,
      masterAccount,
      allLoggedInUsers,
      allCalendars,
      checkForPrimaryOnly: false,
      distroListDictionary,
    });
  }

  getCalendarShadedHours() {
    const {
      currentTimeZone,
      currentTimeZoneLabel,
      defaultBrowserTimeZone,
      temporaryTimeZones,
      anchorTimeZones,
      currentUser,
    } = this.props;
    const {
      masterAccount,
    } = this.props.masterAccount;
    return determineShadedCalendarHours({
      timeZone: currentTimeZone,
      timeZoneLabel: currentTimeZoneLabel,
      inputDefaultBrowserTimeZone: defaultBrowserTimeZone,
      temporaryTimeZones,
      anchorTimeZones,
      defaultTimeZone: this.getDefaultUserTimeZone(),
      masterAccount,
      user: currentUser,
    });
  }

  updateOOOAndBusyEventsRange() {
    const {
      formattedEventsWithTemporaryEvents,
    } = this.state;
    const {
      masterAccount,
    } = this.props.masterAccount;
    const filteredAllDayEvents = formattedEventsWithTemporaryEvents
      .filter(event => {
        if (isTemporaryEvent(event)) {
          return false;
        }
        if (isGroupVoteEvent(event)) {
          return false;
        }
        if (!event.displayAsAllDay) {
          return false;
        }
        if (isOutOfOfficeEvent(event) && !getMasterAccountShouldHideOOOColumn({ masterAccount })) {
          return true;
        }
        if (isBusyEvent(event) && !getMasterAccountShouldHideAllDayBusyColumn({ masterAccount })) {
          if (isOutOfOfficeEvent(event)) {
            // OOO events are also busy and that logic is handled above
            return false;
          }
          return true;
        }
        return false;
      });
    const updatedOOOBusyEventsColorAndRange = [];
    const {
      setOOOBusyEventsColorAndRange,
      OOOBusyEventsColorAndRange,
      resetOOOBusyEventsColorAndRange,
    } = this.props.OOOBusyEventsDictionaryStore;
    const {
      allCalendars,
    } = this.props.allCalendars;
    const {
      currentUser,
      isDarkMode,
    } = this.props;
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;

    // handle all day
    filteredAllDayEvents.forEach((event) => {
      const {
        eventStart,
        eventEnd,
        resourceId,
      } = event;
      const color = this.getEventStaticInfo(event, NORMAL_COLOR);
      const ranking = getOOOBusyColumnRanking({
        allCalendars,
        event,
        currentUser,
        allLoggedInUsers,
        masterAccount,
      });
      if (isOutlookEvent(event) && isOutOfOfficeEvent(event)) {
        // use outlook's purple event
        const outlookOOOColor = isDarkMode ? OUTLOOK_OOO_BACKGROUND_COLOR.DARK_MODE : OUTLOOK_OOO_BACKGROUND_COLOR.LIGHT_MODE;
        updatedOOOBusyEventsColorAndRange.push({
          color: outlookOOOColor,
          range: {eventStart: startOfDay(eventStart), eventEnd: endOfDay(eventEnd)},
          resourceId, // for day view with resource header
          ranking,
        });
      } else if (isOutlookEvent(event) && isAllDayEvent(event)) {
        updatedOOOBusyEventsColorAndRange.push({
          color,
          range: {eventStart: startOfDay(eventStart), eventEnd: endOfDay(eventEnd)},
          resourceId, // for day view with resource header
          ranking,
        });
      } else if (isGoogleEvent(event) && isAllDayEvent(event) && isBusyEvent(event)) {
        // all day events in google's eventStart and eventEnd doesn't actually go to event start and end
        updatedOOOBusyEventsColorAndRange.push({
          color,
          range: {eventStart: startOfDay(eventStart), eventEnd: endOfDay(eventEnd)},
          resourceId, // for day view with resource header
          ranking,
        });
      } else {
        updatedOOOBusyEventsColorAndRange.push({
          color,
          range: {eventStart, eventEnd},
          resourceId, // for day view with resource header
          ranking,
        });
      }
    });

    if ((isEmptyArrayOrFalsey(updatedOOOBusyEventsColorAndRange) && !OOOBusyEventsColorAndRange) || // no point in check if both are essentially empty
      _.isEqual(updatedOOOBusyEventsColorAndRange, OOOBusyEventsColorAndRange)
    ) {
      return; // to avoid excess update
    }
    if (isEmptyArrayOrFalsey(updatedOOOBusyEventsColorAndRange)) {
      resetOOOBusyEventsColorAndRange();
      return;
    }
    setOOOBusyEventsColorAndRange(updatedOOOBusyEventsColorAndRange);
  }

  shouldHideWorkingLocationEvents() {
    const {
      masterAccount,
    } = this.props.masterAccount;
    const {
      currentUser,
    } = this.props;
    return shouldHideWorkingLocationEvents({
      masterAccount,
      user: currentUser,
    });
  }

  // TODO: this is not the best solution because it will cause double render but ok
  // for now because we only use this if the user is hiding working location events
  updateDisplayEvents() {
    if (!this.shouldHideWorkingLocationEvents()) {
      return;
    }
    const {
      formattedEventsWithTemporaryEvents,
    } = this.state;

    // we can't simply add in events with events.filter because then the pointer to the event will have changed
    this.setState({
      displayEvents: formattedEventsWithTemporaryEvents.filter((event) => !isWorkplaceEvent(event)),
    });
  }

  /* Update hold_details in events when updating group vote / spreadsheet */
  updateEventsHoldDetails({ holdDetails }) {
    if (isEmptyObjectOrFalsey(holdDetails)) {
      return;
    }

    const { formattedEvents } = this.state;

    // do not use produce here since we're mututating the event using static information (createStaticInformation)
    // if we try to change the event, this will create a read only error
    const updatedFormattedEvents = formattedEvents.map(event => {
      /* Prevent null === null from passing */
      if (
        isTypeString(getEventHoldID(event)) &&
        getEventHoldID(event) === getHoldDetailsID({ holdDetails })
      ) {
        /* Update the hold details */
        return {
          ...event,
          hold_details: holdDetails,
        };
      } else {
        return event;
      }
    });
    this.setState({ formattedEvents: updatedFormattedEvents });
  }

  trackUserTimeZoneInfo() {
    const {
      currentUser,
    } = this.props;
    trackUserInfo({
      action: USER_INFO_ACTIONS.GUESSED_TIME_ZONE,
      label: guessTimeZone(),
      userToken: getUserToken(currentUser),
    });
  }

  filterOutGoogleBirthdayEvents(events) {
    return events?.filter(event => !isShowAsBirthdayEvent(event));
  }

  updateLastUpdatedAtForDeletedEvents(events) {
    try {
      if (isEmptyArrayOrFalsey(events)) {
        return;
      }
      events.forEach(event => {
        if (!isCancelledEvent(event)) {
          return;
        }
        const userEventID = getEventUserEventID(event);
        if (!userEventID) {
          return;
        }
        const deletedAtTime = getEventUpdatedAt(event);
        const existingUpdatedAt = this._deletedEventAtMap.get(userEventID);
        if (existingUpdatedAt
            && (existingUpdatedAt === deletedAtTime
              || isGreatThanUpdatedAt(existingUpdatedAt, deletedAtTime)
            )
        ) {
          return;
        }
        this._deletedEventAtMap.set(userEventID, deletedAtTime);
      });
    } catch (error) {
      handleError(error);
    }
  }

  // this is mostly only for outlook webhooks
  updateLastUpdatedAtForDeletedUserEventIDs(userEventIDs) {
    try {
      if (isEmptyArrayOrFalsey(userEventIDs)) {
        return;
      }
      const deletedAtTime = createCurrentEventUpdatedAtTime();
      // getEventUpdatedAt(event) returns either of these two
      // 2024-10-30T18:00:40.119Z
      // 2024-11-02T18:30:21.7899815Z
      userEventIDs.forEach(userEventID => {
        if (!userEventID) {
          return;
        }
        const existingUpdatedAt = this._deletedEventAtMap.get(userEventID);
        if (existingUpdatedAt
            && (existingUpdatedAt === deletedAtTime
              || isGreatThanUpdatedAt(existingUpdatedAt, deletedAtTime)
            )
        ) {
          return;
        }
        this._deletedEventAtMap.set(userEventID, deletedAtTime);
      });
    } catch (error) {
      handleError(error);
    }
  }

  filterOutCurrentlyUpdatingEvents(events) {
    const mimicEvents = this.getMimicEvents();
    if (isEmptyArrayOrFalsey(mimicEvents) || isEmptyArrayOrFalsey(events)) {
      return events;
    }
    const mimicEventUserEventIDs = new Set(mimicEvents.map(e => getEventUserEventID(e)));
    if (!events.some(e => mimicEventUserEventIDs.has(getEventUserEventID(e)))) {
      // none
      return events;
    }
    return events.filter((e) => !mimicEvents.some((m) => getEventUserEventID(m) === getEventUserEventID(e) && isGreatThanUpdatedAt(getEventUpdatedAt(m), getEventUpdatedAt(e))));
  }

  isPreviousDeletedVersionOfEvent(event) {
    try {
      const updatedAt = this._deletedEventAtMap.get(getEventUserEventID(event));
      if (!updatedAt) {
        return false;
      }
      return isGreatThanUpdatedAt(updatedAt, getEventUpdatedAt(event)); // if it's the same, we can still get it pass
    } catch (error) {
      handleError(error);
      return false;
    }
  }

  getMimicEvents() {
    const {
      mimicEventsList,
    } = this.props;
    if (isEmptyArrayOrFalsey(mimicEventsList)) {
      return [];
    }
    return this.filterEventsOnActiveCalendars(addDefaultToArray(mimicEventsList));
  }

  hasResourceMappingChanged(updatedResourceMap) {
    // array of object below {resourceId, displayName, resourceTitle}
    // {resourceId: 'mike@vimcal.com', displayName: 'Michael - work', resourceTitle: {…}
    const mappedString = updatedResourceMap?.map(resource => `${resource?.resourceId} ${resource?.displayName}`).join(",");
    const mappedString2 = this.state.resourceMap?.map(resource => `${resource?.resourceId} ${resource?.displayName}`).join(",");
    return mappedString !== mappedString2;
  }

  resetMainCalendarCache() {
    this._mimicDeletedUserEventIDs = [];
    this._deletedEventAtMap = new Map();
  }

  removeFindTimeEvents() {
    if (!doesArrayContainFindTimeEvent(this.props.temporaryEvents)) {
      return;
    }
    broadcast.publish("REMOVE_TEMPORARY_EVENTS");
  }
}

function mapStateToProps(state) {
  let {
    eventFormEmails,
    popupEvent,
    temporaryEvents,
    currentHoverEvent,
    defaultBrowserTimeZone,
    selectedDay,
    currentTimeZone,
    currentUser,
    currentPreviewedEvent,
    currentTimeZoneLabel,
    isDarkMode,
    availabilitySelectedMinutes,
    weekStart,
    shouldOnlyShowWorkWeek,
    showDeclinedEvents,
    format24HourTime,
    dateFieldOrder,
    selectedCalendarView,
    originalRecurrenceEventIndex,
    emailToNameIndex,
    anchorTimeZones,
    temporaryTimeZones,
    isMobileView,
    isCreateFocusModeBlocks,
    hoverPopupEvent,
    temporarySecondaryCalendarColorsIndex,
    actionMode,
    mimicEventsList,
  } = state;

  return {
    eventFormEmails,
    popupEvent,
    temporaryEvents,
    currentHoverEvent,
    defaultBrowserTimeZone,
    selectedDay,
    currentTimeZone,
    currentUser,
    currentPreviewedEvent,
    currentTimeZoneLabel,
    isDarkMode,
    availabilitySelectedMinutes,
    weekStart,
    shouldOnlyShowWorkWeek,
    showDeclinedEvents,
    format24HourTime,
    dateFieldOrder,
    selectedCalendarView,
    originalRecurrenceEventIndex,
    emailToNameIndex,
    anchorTimeZones,
    temporaryTimeZones,
    isMobileView,
    isCreateFocusModeBlocks,
    hoverPopupEvent,
    temporarySecondaryCalendarColorsIndex,
    actionMode,
    mimicEventsList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectDay: (day) => dispatch({ data: day, type: "SELECT_DAY" }),
    setPreviewedEvent: (event) =>
      dispatch({ data: event, type: "SET_PREVIEWED_EVENT" }),
    setTimeZone: (timeZone) =>
      dispatch({ data: timeZone, type: "SET_TIME_ZONE" }),
    setActionMode: (data) => dispatch({ data: data, type: "SET_ACTION_MODE" }),
    setDefaultBrowserTimeZone: (timeZone) =>
      dispatch({ data: timeZone, type: "STORE_DEFAULT_BROWSER_TIME_ZONE" }),
    setCurrentHoverEvent: (event) =>
      dispatch({ data: event, type: "SET_CURRENT_HOVER_PREVIEWED_EVENT" }),
    setHoverPopupEvent: (event) =>
      dispatch({ data: event, type: "SET_HOVER_POPUP_EVENT" }),
    setTemporaryEvent: (event) =>
      dispatch({ data: event, type: "SET_TEMPORARY_EVENTS" }),
    removePopupEvent: () =>
      dispatch({ type: "REMOVE_POPUP_EVENT" }),
    removePreviewedEvent: (event) =>
      dispatch({ data: event, type: "REMOVE_CURRENT_PREVIEW_EVENT" }),
    setShouldShowTopBar: (data) =>
      dispatch({ data: data, type: "SET_SHOULD_SHOW_TOP_BAR" }),
    setPopupView: (event) => dispatch({ data: event, type: "SET_POPUP_EVENT" }),
    setCurrentTimeZoneLabel: (day) =>
      dispatch({ data: day, type: "SET_TIME_ZONE_LABEL" }),
    removeCurrentHoverEvent: (event) =>
      dispatch({ data: event, type: "REMOVE_CURRENT_HOVER_EVENT" }),
    setAvailabilitySelectedMinutes: (data) =>
      dispatch({ data: data, type: "SET_AVAILABILITY_SELECTED_MINUTES" }),
    removeHoverPopupEvent: () =>
      dispatch({ type: "REMOVE_HOVER_POPUP_EVENT" }),
    setAgendaDay: (event) => dispatch({ data: event, type: "SET_AGENDA_DAY" }),
    setTemporaryTimeZones: (data) =>
      dispatch({ data: data, type: "SET_TEMPORARY_TIME_ZONES" }),
    removeCreateFocusModeBlock: () => dispatch({ type: "REMOVE_CREATE_FOCUS_MODE_BLOCKS" }),
    setOriginalRecurrenceEventIndex: (data) =>
      dispatch({ data, type: "SET_ORIGINAL_RECURRENCE_EVENT_INDEX" }),
    setMimicEventsList: (data) =>
      dispatch({ data, type: "SET_MIMIC_EVENTS_LIST" }),
  };
}

const withStore = (BaseComponent) => (props) => {
  const eventIndexStore = useEventHotKeysIndex();
  const allCalendars = useAllCalendars();
  const allLoggedInUsers = useAllLoggedInUsers();
  const hideRightHandSidebar = useHideRightHandSidebar();
  const availabilityStore = useAvailabilityStore();
  const masterAccount = useMasterAccount();
  const temporaryStateStore = useTemporaryStateStore();
  const appSettings = useAppSettings();
  const appTimeZone = useAppTimeZones();
  const outlookCategoriesStore = useOutlookCategoriesStore();
  const OOOBusyEventsDictionaryStore = useOOOBusyEventsDictionaryStore();
  const distroListDictionary = useDistroListDictionary();
  const userTimeZoneIndexStore = useUserTimeZoneIndexStore();

  return (
    <BaseComponent
      {...props}
      allCalendars={allCalendars}
      eventIndexStore={eventIndexStore}
      allLoggedInUsers={allLoggedInUsers}
      hideRightHandSidebar={hideRightHandSidebar}
      availabilityStore={availabilityStore}
      masterAccount={masterAccount}
      temporaryStateStore={temporaryStateStore}
      appSettings={appSettings}
      appTimeZone={appTimeZone}
      outlookCategoriesStore={outlookCategoriesStore}
      OOOBusyEventsDictionaryStore={OOOBusyEventsDictionaryStore}
      distroListDictionary={distroListDictionary}
      userTimeZoneIndexStore={userTimeZoneIndexStore}
    />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withStore(MainCalendar)));
