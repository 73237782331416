import React, { Component } from "react";
import { withRouter, Route, Switch } from "react-router-dom";
import { connect, batch } from "react-redux";
import EventModalPopup from "../components/eventModalPopup";
import Broadcast from "../broadcasts/broadcast";
import { trackError, trackEvent } from "../components/tracking";
import {
  isMac,
  localData,
  isElectron,
  shouldDisplayDeleteEventButton,
  isEditable,
  hasEventPreventDefault,
  OpenLink,
  hasStateOrPropsChanged,
  loadTheme,
  hasPermissionToModify,
  calculateMarginTopClassname,
  removeDuplicatesFromArray,
  isMobile,
  doesWindowLocationIncludeString,
  hasStopEventPropagation,
  isTooltipCompleted,
  guessTimeZone,
  handleError,
  getCurrentTimeInCurrentTimeZone,
  isValidJSDate,
} from "../services/commonUsefulFunctions";
import StyleConstants, {
  EVENT_FORM_SUMMARY,
  EVENT_FORM_START_DATE_INPUT,
  EVENT_FORM_START_TIME_INPUT,
  EVENT_FORM_END_DATE_INPUT,
  EVENT_FORM_END_TIME_INPUT,
  EVENT_FORM_REPEAT,
  EVENT_FORM_LOCATION,
  EVENT_FORM_CONFERENCE,
  EVENT_FORM_ROOM,
  EVENT_FORM_ATTENDEE,
  EVENT_FORM_GUESTS_CAN,
  EVENT_FORM_COLOR,
  EVENT_FORM_EMAIL,
  EVENT_FORM_NOTIFICATION,
  EVENT_FORM_BUSY_FREE,
  EVENT_FORM_DEFAULT_AVAILABILITY,
  EVENT_FORM_DESCRIPTION,
  CAN_NOT_UPDATE_EVENT_MESSAGE,
  DARK_MODE_THEME,
  LIGHT_MODE_THEME,
  BACKEND_MONTH,
  COPY_AVAILABILITIES_TRIGGER_HOT_KEY,
  PERSONAL_LINK_DETAIL_CONTAINER,
  TYPE_CONFERENCING,
  EVENT_FORM_ALL_DAY,
  ROLLING_SEVEN_DAY,
  LAST_USED_SLOTS_HOT_KEYS,
  BLUE_BUTTON,
  WHITE_BUTTON,
  SECOND_IN_MS,
  SET_DISAPPEARING_NOTIFICATION_MESSAGE,
  PERSONAL_LINK_SELECTION,
  ARROW_UP,
  ARROW_DOWN,
  ARROW_LEFT,
  ARROW_RIGHT,
  OPEN_SIDE_MENU_PANEL_HOTKEY,
  TOGGLE_RIGHT_PANEL_HOTKEY,
  GROUP_VOTE_SELECT_OPTION,
  SLOTS_AVAILABILITY_SELECTION,
  MODAL_BLUR,
  MODAL_BORDER_RADIUS,
  getModalBackgroundColor,
  GENERIC_ERROR_MESSAGE,
  ACTION_MODE,
} from "../services/globalVariables";
import LayoutTopBar from "../components/layoutTopBar";
import DesktopTitleBar from "../components/desktopTitleBar";
import packageJson from "../../../package.json";
import * as Mousetrap from "mousetrap";
import { isSameDay, isValid, format, differenceInMilliseconds } from "date-fns";
import ClassNames from "classnames";
import {
  getAllLoggedInAccountDetails,
  doTemporaryTimeZonesExist,
  getPromotionCode,
  getPromotionType,
  getFirstUnseenPromotion,
  getSavedPromoCode,
  removeSavedPromoCode,
  getAccountState,
} from "../lib/stateManagementFunctions";
import AvailabilityBroadcast from "../broadcasts/availabilityBroadcast";
import { getPreviouslySelectedSlots, isOutlookEvent, isPreviewOutlookEvent } from "../lib/eventFunctions";
import BackendBroadcasts from "../broadcasts/backendBroadcasts";
import ConferencingBroadcasts from "../broadcasts/conferencingBroadcasts";
import CustomButton from "../components/customButton";
import { parseICSString } from "../lib/icsFunctions";
import InstantOpenCommandCenter from "../components/instantOpenCommandCenter";
import RequestPermissionsModal from "../components/requestPermissionsModal";
import AvailabilityLink from "./availabilityLink";
import DisappearingNotificationMessage from "../components/disappearingNotificationMessage";
import CommandCenterContainer from "../components/commandCenterContainer";
import TemplateCommandCenter from "../components/templateCommandCenter";
import ColorCommandCenter from "../components/colorCommandCenter";
import RescheduleCommandCenter from "../components/rescheduleCommandCenter";
import TimeZoneCommandCenter from "../components/timeZoneCommandCenter";
import ContactCommandCenter from "../components/contactCommandCenter";
import MultipleUpcomingEventsModal from "../components/multipleUpcomingEventsModal";
import ShortcutsLegend from "../components/shortcutsLegend";
import GoogleCalendarSettingsModal from "../components/googleCalendarSettingsModal";
import DropZoneContainer from "./dropzone";
import CalendarHomeView from "./calendarHomeView";
import Search from "./search";
import AuthorizedLogin from "./authorizedLogin";
import EditTemplates from "./editTemplates";
import PreloadResources from "../components/preloadResources";
import appBroadcast from "../broadcasts/appBroadcast";
import ShortcutSuggestion from "../components/shortcutSuggestion";
import mainCalendarBroadcast from "../broadcasts/mainCalendarBroadcast";
import HelpCenterContainer from "../components/helpCenter/helpCenterContainer";
import PromotionModalScreen from "../components/promotionModalScreen";
import {
  PRODUCT_HUNT_PROMOTION,
  LEGACY_YC_PROMOTION,
  HUSTLE_FUND_PROMOTION,
  FOCUS_MODE_HOTKEY,
  CALENDAR_PROVIDERS,
  LOCAL_TIME_ZONE,
  APP_SETTINGS,
  BACKEND_SETTINGS_NAMES,
  STANDARD_YC_PROMO,
  ENVIRONMENTS,
  ACCOUNT_STATE_PREPAID,
  ACCOUNT_STATE_NEW_USER,
  ACCOUNT_STATE_PAYING,
} from "../lib/vimcalVariables";
import DefaultChecks from "../components/defaultChecks";
import { useAppTimeZones, useHideRightHandSidebar, useIsMouseMoving, useTutorialWizard } from "../services/stores/appFunctionality";
import { useEventHotKeysIndex } from "../services/stores/eventsData";
import GroupVoteInviteAttendees from "../components/scheduling/groupVoteInviteAttendees";
import GroupVoteCreateEvent from "../components/scheduling/groupVoteCreateEvent";
import { createEventFromGroupVoteLink, createEventFromSlotsHold, fetchPersonalLinks, getPersonalLinkHotKeyIndex } from "../lib/availabilityFunctions";
import {
  useAllCalendars,
  useAllLoggedInUsers,
  useMasterAccount,
} from "../services/stores/SharedAccountData";
import { getUserCalendarIDFromEmail } from "../lib/calendarFunctions";
import { useCustomEvent } from "../services/stores/customEvent";
import ConvertCalendarToUser from "../components/modal/convertCalendarToUser";
import DeleteHoldEvent from "../components/modal/deleteHoldEvent";
import Login from "../../js/views/login/index";
import DownloadIOS from "../components/modal/downloadIOS";
import { tooltipKeys } from "../services/tooltipVariables";
import EmailAttendees from "../components/emailAttendees";
import FocusModeContainer from "../focusMode/focusModeContainer";
import layoutBroadcast from "../broadcasts/layoutBroadcast";
import { getPreloadBackgroundImages } from "../focusMode/sharedFunctions";
import {
  getEventClientRect,
  getPreviewEvent,
  isAppInTaskMode,
  isColorSelectorOpen,
  isInEditTemplatesState,
  isInExpandedViewState,
  isInSearchState,
  isModalOpen,
  isPersonalLinkPanelOpen,
  isRSVPSectionRendered,
  shouldHideRightHandSide,
  isGetUpcomingWeekAvailabiityMessageShowing,
  isUsePreviouslySelectedSlotsMessageShowing,
  shouldTruncateRightHandPanel,
  updateMasterAccountSettingsForFrontendAndBackend,
  isGroupVoteDetailPageOpen,
  isTutorialWizardOpen,
  isCalendarListEventFormFindTimeButtonShowing,
  isEventFormFindTimeButtonShowing,
  isAvailabilityPanelModalOpen,
  isEventFormShowing,
  isTemplateViewShowing,
  isActionModeCreateAvailability,
  isInActionMode,
  isActionModeUpsertEvent,
  isSchedulingAssistantEventPreviewShowing,
} from "../services/appFunctions";
import classNames from "classnames";
import HoverUpcomingEvent from "../components/hoverUpcomingEvent";
import { addEventUserCalendarID, getEventID, getEventUserEmail } from "../services/eventResourceAccessors";
import focusModeBroadcast from "../broadcasts/focusModeBroadcast";
import SlotsAnimation from "../components/availability/resources/slotsAnimation";
import FloatingFocusModeCountdownTimer from "../focusMode/floatingFocusModeCountdownTimer";
import { getMatchingUserFromAllUsers, getUserEmail, getUserToken } from "../lib/userFunctions";
import TrialExpiredModal from "../components/onboarding/trialExpired/trialExpiredModal";
import SettingsModal from "../components/settings/index";
import MetricsWrapper from "../components/metrics";
import { isUserDelegatedUser, isUserMaestroUser } from "../services/maestroFunctions";
import { useGroupVoteStore } from "../services/stores/settings";
import { constructRequestURL } from "../services/api";
import { isVersionV2 } from "../services/versionFunctions";
import Fetcher from "../services/fetcher";
import PasteContainer from "../components/pasteContainer";
import { CALENDAR_TYPE_DROPDOWN_ID, LAYOUT_ID } from "../services/elementIDVariables";
import AISchedulerModal from "../components/specialComponents/aiSchedulerModal";
import pasteBroadcast from "../broadcasts/pasteBroadcast";
import { useTemporaryStateStore } from "../services/stores/temporaryStateStores";
import FreeTimeFinderModal from "../components/specialComponents/freeTimeFinderModal";
import availabilityBroadcast from "../broadcasts/availabilityBroadcast";
import ForwardOutlookEventModal from "../components/modal/forwardOutlookEventModal";
import { useUserCodes } from "../services/stores/userData";
import TeamPlanInvitesContainer from "../components/teamPlans/teamPlanInvitesContainer";
import TeamPlanJoinedModal from "../components/modal/teamPlanJoinedModal";
import BottomLeftModalContainer from "../components/bottomLeftModalContainer";
import { filterOutInvalidTimeZones } from "../lib/timeFunctions";
import { isDisplayMetricsParam } from "../services/queryParamFunctions";
import { PERMISSION_MODAL_TYPES } from "../lib/authFunctions";
import { trackMetricsOpen } from "../components/metrics/metricsAccessorFunctions";
import { isEmptyArray } from "../lib/arrayFunctions";
import broadcast from "../broadcasts/broadcast";
import eventFormBroadcast from "../broadcasts/eventFormBroadcast";
import MaestroZoomLoginInfo from "../components/modal/maestroZoomLoginInfo";
import AffiliateModal from "../components/modal/affiliateModal";
import { LOCAL_DATA_ACTION, getLastSelectedDate, getLocalDataAppVersion, setLocalDataAppVersion } from "../lib/localData";
import { isInternalTeamUser } from "../lib/featureFlagFunctions";
import { APP_BROADCAST_VALUES, AVAILABILITY_BROADCAST_VALUES, BROADCAST_VALUES, EXPANDED_VIEW_BROADCAST_VALUES, LAYOUT_BROADCAST_VALUES, MAIN_CALENDAR_BROADCAST_VALUES, SCHEDULING_ASSISTANT_BROADCAST_VALUES } from "../lib/broadcastValues";
import { LATEST_GROUP_VOTE_LINKS } from "../lib/endpoints";
import OnboardingModal from "../components/modal/onboardingModal";
import AccountDropdown from "../components/accountDropdown";
import { getDefaultModalBorder, getModalBackgroundColorWithExtraOpacity, getModalBackgroundColorWithNoOpacity, getSidePoppedOverModalBorder } from "../lib/styleFunctions";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey } from "../services/typeGuards";
import { NEW_EA_LOGIN_PATH } from "../services/maestro/maestroRouting";
import CalendarDropdownOptions from "../components/calendarDropdownOptions";
import { getAnchorTimeZonesInSettings, getDefaultUserTimeZone } from "../lib/settingsFunctions";
import { fetchLatestMasterAccount, getFullEvent } from "../lib/fetchFunctions";
import expandedEventViewBroadcast from "../broadcasts/expandedEventViewBroadcast";
import AuthErrorModal, { AUTH_ERROR_VARIANTS } from "../components/modal/authErrorModal";
import { determineDefaultModalStyle, MODAL_CONTENT_BOX_SHADOW, MODAL_OVERLAY_Z_INDEXES } from "../lib/modalFunctions";
import Feedback from "../components/feedback/feedback";
import { GOOGLE_INCREMENTAL_AUTH_NAMES } from "../lib/googleFunctions";
import IncrementalPermissionsModal from "../components/modal/incrementalPermissions";
import { isLocal } from "../services/devFunctions";
import schedulingAssistantBroadcast from "../broadcasts/schedulingAssistantBroadcast";

const AVAILABILITY_PREVIEW_MODAL = "availabilityPreview";
const PAST_CONFERENCING = "PAST_CONFERENCING";
const HELP_CENTER = "HELP_CENTER";
const VIMCAL_IOS = "VIMCAL_IOS";
const EMAIL_ATTENDEES = "EMAIL_ATTENDEES";
const EMAIL_ATTENDEES_RUNNING_LATE = "EMAIL_ATTENDEES_RUNNING_LATE";
const STILL_TIMEOUT = 500;
const MULTIPLE_UPCOMING_EVENTS_MODAL = "MULTIPLE_UPCOMING_EVENTS_MODAL";
const UPDATE_CONTACTS_PERMISSIONS = "UPDATE_CONTACTS_PERMISSIONS";
const GOOGLE_CALENDAR_SETTINGS_MODAL = "GOOGLE_CALENDAR_SETTINGS_MODAL";
const CLAIM_PROMOTION = "CLAIM_PROMOTION";
const INVITE_GROUP_LINK_ATTENDEES = "INVITE_GROUP_LINK_ATTENDEES";
const CREATE_EVENT_FROM_BOOKING_LINK = "CREATE_EVENT_FROM_BOOKING_LINK";
const GROUP_VOTE_PREVIEW = "GROUP_VOTE_PREVIEW";
const AVAILABILITY_GROUP_VOTE_LINK_PREVIEW_MODAL =
  "AVAILABILITY_GROUP_VOTE_LINK_PREVIEW_MODAL";
const CONVERT_CALENDAR_TO_USER = "CONVERT_CALENDAR_TO_USER";
const DELETE_HOLD_EVENTS = "DELETE_HOLD_EVENTS";
const TRIAL_IS_OVER_MODAL = "TRIAL_IS_OVER_MODAL";
const SETTINGS_MODAL = "SETTINGS_MODAL";
const METRICS_MODAL = "METRICS_MODAL";
const AI_SCHEDULE_MODAL = "AI_SCHEDULE_MODAL";
const FREE_TIME_FINDER_NOTIFICATION = "FREE_TIME_FINDER_NOTIFICATION";
const FORWARD_OUTLOOK_EVENT = "FORWARD_OUTLOOK_EVENT";
const JOINED_TEAM_PLAN = "JOINED_TEAM_PLAN";
const MAESTRO_ZOOM_LOGIN_MODAL = "MAESTRO_ZOOM_LOGIN_MODAL";
const AFFILIATE_MODAL = "AFFILIATE_MODAL";
const ONBOARDING_MODAL = "ONBOARDING_MODAL";
const VIEW_ACCOUNTS_MODAL = "VIEW_ACCOUNTS_MODAL";
const MAIN_CALENDAR_SELECT_MODAL = "MAIN_CALENDAR_SELECT_MODAL";
const PERSONAL_AUTH_ERROR_MODAL = "PERSONAL_AUTH_ERROR_MODAL";
const EXECUTIVE_AUTH_ERROR_MODAL = "EXECUTIVE_AUTH_ERROR_MODAL";
const FEEDBACK_MODAL = "FEEDBACK_MODAL";

class Layout extends Component {
  constructor(props) {
    super(props);

    this._lastSetMouseTrap = null;

    this.state = {
      shouldShowCommandCenter: false,
      shouldShowTimeZoneCommandCenter: false,
      shouldShowTemplateCommandCenter: false,
      shouldShowContactCommandCenter: false,
      shouldShowRescheduleCommandCenter: false,
      isElectron: isElectron(),
      shouldDisplayModal: false,
      modalWidth: "100%",
      modalTitle: "",
      shouldShowColorCommandCenter: false,
      modalAvailabilityPreviewInformation: {
        duration: 30,
        location: null,
        conferencing: null,
        availabilityEvents: [],
      },
      eventHandlerList: [],
      pastEventTitle: "",
      modalContent: null,
      promotionModal: null,
      commandCenterDefaultText: "",
      modalData: null, // Used to passed any data to the modal.  Used for convert calendar to user.
      emailAttendeesEvent: null,
      shouldDisplayFocusMode: false,
      displaySlotsAnimation: false,
      permissionProvider: null, // the provider for the permission modal
      permissionEmail: null, // the email for the permission modal
      settingsModalInitialContent: null,
    };

    this._onMouseMoveInfo = null;
    this._isMouseMovingLayout = false;
    this._lastPressedPTime = null; // to avoid collision with painter and duplicate
    this._lastPressedVTime = null; // to avoid collision with yank and video
    this._lastPressedLTime = null; // to avoid collision with yank and location
    this._hotKeysEnabled = false; // have not been enabled yet
    this._resizeTimer = null;

    // Need to reset selectedDay every time because redux converts JS date to string which we cant re-use
    const getInitialDate = () => {
      const previousSelectedDate = getLastSelectedDate();
      if (isValidJSDate(previousSelectedDate)) {
        return previousSelectedDate;
      }
      return getCurrentTimeInCurrentTimeZone(this.props.currentTimeZone);
    };

    const date = getInitialDate();
    batch(() => {
      if (this.getGroupVoteRerouteToken()) {
        // do nothing -> wait until did mount
      } else if (
        !doesWindowLocationIncludeString("home") ||
        doesWindowLocationIncludeString("expanded")
      ) {
        this.props.history.push("/home");
      }

      this.props.setIsMobileView(isMobile());

      // Set up the date and timezone of the app
      // Do not need to update weekly calendar events since weekly calendar has not been mounted yet
      // current time zone is initially set in reducer by default through param (state = guessTimeZone())
      const defaultUserTimeZone = getDefaultUserTimeZone({
        masterAccount: this.props.masterAccount.masterAccount,
        user: this.props.currentUser,
      });
      if (!props.currentTimeZone || props.currentTimeZone !== defaultUserTimeZone) {
        props.setTimeZone(defaultUserTimeZone);
      }

      props.selectDay(date);
      this.props.setAgendaDay(getCurrentTimeInCurrentTimeZone(this.props.currentTimeZone));

      if (this.props.anchorTimeZones?.length > 0) {
        this.props.setCurrentTimeZoneLabel(null);
      } else if (this.props.currentTimeZoneLabel) {
        this.props.setShouldShowTopBar(true);
      }

      const allAdditionalTimeZones = getAnchorTimeZonesInSettings({
        user: this.props.currentUser,
        masterAccount: this.props.masterAccount.masterAccount,
      });

      if (allAdditionalTimeZones?.length > 0) {
        this.props.setAnchorTimeZones(allAdditionalTimeZones);
      }
    });

    if (this.props.isDarkMode) {
      loadTheme(DARK_MODE_THEME);
    } else {
      loadTheme(LIGHT_MODE_THEME);
    }

    const {
      hasSetCodes,
      resetUserCodes
    } = this.props.userCodes;
    if (hasSetCodes) {
      // clear user codes if they have been set before
      resetUserCodes();
    }

    // These methods must be bound here above this.globalHandlers
    this.turnOnCommandCenter = this.turnOnCommandCenter.bind(this);
    this.turnOnTemplateCommandCenter =
      this.turnOnTemplateCommandCenter.bind(this);
    this.toggleSearch = this.toggleSearch.bind(this);
    this.closeCommandCenter = this.closeCommandCenter.bind(this);
    this.closeTemplateCommandCenter =
      this.closeTemplateCommandCenter.bind(this);
    this.openGoogleMaps = this.openGoogleMaps.bind(this);
    this.toggleGlobalKeyMap = this.toggleGlobalKeyMap.bind(this);
    this.disableHotKeys = this.disableHotKeys.bind(this);
    this.enableHotKeys = this.enableHotKeys.bind(this);
    this.turnOnContactCommandCenter =
      this.turnOnContactCommandCenter.bind(this);
    this.closeContactCommandCenter = this.closeContactCommandCenter.bind(this);
    this.setLastSetMouseTrap = this.setLastSetMouseTrap.bind(this);
    this.toggleSideMenuBar = this.toggleSideMenuBar.bind(this);
    this.pressAToggleAvailabilityMode =
      this.pressAToggleAvailabilityMode.bind(this);
    this.toggleEditTemplates = this.toggleEditTemplates.bind(this);
    this.openTimeZoneCommandCenter = this.openTimeZoneCommandCenter.bind(this);
    this.openRescheduleCommandCenter =
      this.openRescheduleCommandCenter.bind(this);
    this.closeTimeZoneCommandCenter =
      this.closeTimeZoneCommandCenter.bind(this);
    this.isCommandCenterOn = this.isCommandCenterOn.bind(this);
    this.removeExpandedEvents = this.removeExpandedEvents.bind(this);
    this.deletePreviewedEvent = this.deletePreviewedEvent.bind(this);
    this.handleOnPressBackSpace = this.handleOnPressBackSpace.bind(this);
    this.openLoginToNewAccounts = this.openLoginToNewAccounts.bind(this);
    this.toggleAccount = this.toggleAccount.bind(this);
    this.closeSideMenuBar = this.closeSideMenuBar.bind(this);
    this.determineRefreshAppMethod = this.determineRefreshAppMethod.bind(this);
    this.openConference = this.openConference.bind(this);
    this.moveEventWithArrowKey = this.moveEventWithArrowKey.bind(this);
    this.responseToEvent = this.responseToEvent.bind(this);
    this.eventCanBeDeleted = this.eventCanBeDeleted.bind(this);
    this.globalEscape = this.globalEscape.bind(this);
    this.addKeyMap = this.addKeyMap.bind(this);
    this.setPreviewEvent = this.setPreviewEvent.bind(this);
    this.goToPreviousMonth = this.goToPreviousMonth.bind(this);
    this.goToNextWeek = this.goToNextWeek.bind(this);
    this.pressToday = this.pressToday.bind(this);
    this.goToNextMonth = this.goToNextMonth.bind(this);
    this.goToPreviousWeek = this.goToPreviousWeek.bind(this);
    this.closeRescheduleCommandCenter =
      this.closeRescheduleCommandCenter.bind(this);
    this.displayShortcutsLegend = this.displayShortcutsLegend.bind(this);
    this.hideShortcutsLegend = this.hideShortcutsLegend.bind(this);
    this.redirectURL = this.redirectURL.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.displayAvailabilityPreviewModal =
      this.displayAvailabilityPreviewModal.bind(this);
    this.createEventHandler = this.createEventHandler.bind(this);
    this.onMouseMoveLayout = this.onMouseMoveLayout.bind(this);
    this.commandC = this.commandC.bind(this);
    this.goToEventEditView = this.goToEventEditView.bind(this);
    this.turnOnColorCommandCenter = this.turnOnColorCommandCenter.bind(this);
    this.closeColorCommandCenter = this.closeColorCommandCenter.bind(this);
    this.displayMultipleUpcomingEventsModal =
      this.displayMultipleUpcomingEventsModal.bind(this);
    this.updateDay = this.updateDay.bind(this);
    this.copyAvailabilityLink = this.copyAvailabilityLink.bind(this);
    this.useLastSelectedSlots = this.useLastSelectedSlots.bind(this);
    this.openPastConferencingModal = this.openPastConferencingModal.bind(this);
    this.parseICSFile = this.parseICSFile.bind(this);
    this.onChangeUpload = this.onChangeUpload.bind(this);
    this.closeInstantOpenCommandCenter =
      this.closeInstantOpenCommandCenter.bind(this);
    this.openInstantOpenCommandCenter =
      this.openInstantOpenCommandCenter.bind(this);
    this.openAuthErrorModal = this.openAuthErrorModal.bind(this);
    this.openPermissionsModal = this.openPermissionsModal.bind(this);
    this.openIncrementalPermissionsModal = this.openIncrementalPermissionsModal.bind(this);
    this.openHelpCenter = this.openHelpCenter.bind(this);
    this.updateAnchorTimeZones = this.updateAnchorTimeZones.bind(this);
    this.updateFavicon = this.updateFavicon.bind(this);
    this.openGoogleCalendarSettings =
      this.openGoogleCalendarSettings.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.duplicatePreviewEvent = this.duplicatePreviewEvent.bind(this);
    this.displayPromotionModal = this.displayPromotionModal.bind(this);
    this.turnOnCommandCenterWithDefaultText =
      this.turnOnCommandCenterWithDefaultText.bind(this);
    this.yankLocation = this.yankLocation.bind(this);
    this.yankVideo = this.yankVideo.bind(this);
    this.displayGroupVoteLinkPreviewModal =
      this.displayGroupVoteLinkPreviewModal.bind(this);
    this.openGroupVoteLinkInviteModal =
      this.openGroupVoteLinkInviteModal.bind(this);
    this.openGroupVoteEventPicker = this.openGroupVoteEventPicker.bind(this);
    this.createGroupVoteEvent = this.createGroupVoteEvent.bind(this);
    this.createSmartHoldsEvent = this.createSmartHoldsEvent.bind(this);
    this.openConvertCalendarToUser = this.openConvertCalendarToUser.bind(this);
    this.openDeleteHoldEventsModal = this.openDeleteHoldEventsModal.bind(this);
    this.openiOSModal = this.openiOSModal.bind(this);
    this.togglePersonalLinks = this.togglePersonalLinks.bind(this);
    this.showEmailModal = this.showEmailModal.bind(this);
    this.toggleFocusMode = this.toggleFocusMode.bind(this);
    this.toggleFocusMode = this.toggleFocusMode.bind(this);
    this.toggleOnSlotsAnimation = this.toggleOnSlotsAnimation.bind(this);
    this.toggleOffSlotsAnimation = this.toggleOffSlotsAnimation.bind(this);
    this.toggleRightPanel = this.toggleRightPanel.bind(this);
    this.updateUserDefaultTimeZone = this.updateUserDefaultTimeZone.bind(this);
    this.openTrialIsOverModal = this.openTrialIsOverModal.bind(this);
    this.onCloseTrialIsOverModal = this.onCloseTrialIsOverModal.bind(this);
    this.navigateToNextAccount = this.navigateToNextAccount.bind(this);
    this.navigateToPreviousAccount = this.navigateToPreviousAccount.bind(this);
    this.openSettingsModal = this.openSettingsModal.bind(this);
    this.showMetricsModal = this.showMetricsModal.bind(this);
    this.openAIScheduler = this.openAIScheduler.bind(this);
    this.toggleOnSlots = this.toggleOnSlots.bind(this);
    this.resetReverseSlotsData = this.resetReverseSlotsData.bind(this);
    this.openFreeTimeFinderNotification = this.openFreeTimeFinderNotification.bind(this);
    this.getTimesOverLastFewDays = this.getTimesOverLastFewDays.bind(this);
    this.getAvailableSlots = this.getAvailableSlots.bind(this);
    this.openForwardOutlookModal = this.openForwardOutlookModal.bind(this);
    this.toggleDefaultAvailability = this.toggleDefaultAvailability.bind(this);
    this.displayJoinedTeamModal = this.displayJoinedTeamModal.bind(this);
    this.openEventFormFindTime = this.openEventFormFindTime.bind(this);
    this.openAffiliateModal = this.openAffiliateModal.bind(this);
    this.openGroupVotePreview = this.openGroupVotePreview.bind(this);
    this.openOnboardingModal = this.openOnboardingModal.bind(this);
    this.openAccountsModal = this.openAccountsModal.bind(this);
    this.openMainCalendarToolbarSelectModal = this.openMainCalendarToolbarSelectModal.bind(this);
    this.rerouteToHome = this.rerouteToHome.bind(this);
    this.toggleOnGroupPolls = this.toggleOnGroupPolls.bind(this);
    this.openFeedbackModal = this.openFeedbackModal.bind(this);

    this._reloadComponentTimer = null;

    Broadcast.subscribe(
      "TOGGLE_SHOULD_SHOW_SET_TIME_ZONE",
      this.openTimeZoneCommandCenter
    );
    Broadcast.subscribe("TURN_ON_COMMAND_CENTER", this.turnOnCommandCenter);
    Broadcast.subscribe(
      "TURN_ON_CONTACT_COMMAND_CENTER",
      this.turnOnContactCommandCenter
    );
    Broadcast.subscribe("TOGGLE_SEARCH", this.toggleSearch);
    Broadcast.subscribe(
      BROADCAST_VALUES.OPEN_LOGIN_TO_NEW_ACCOUNTS,
      this.openLoginToNewAccounts
    );
    Broadcast.subscribe("TOGGLE_SHOW_TEMPLATE", this.toggleEditTemplates);
    Broadcast.subscribe(
      "TURN_ON_TEMPLATE_COMMAND_CENTER",
      this.turnOnTemplateCommandCenter
    );
    Broadcast.subscribe("DISABLE_HOT_KEYS", this.disableHotKeys);
    Broadcast.subscribe("ENABLE_HOT_KEYS", this.enableHotKeys);
    layoutBroadcast.subscribe("SET_LAST_MOUSETRAP_BIND_TIME", this.setLastSetMouseTrap);
    Broadcast.subscribe("UPDATE_DAY", this.updateDay);
    Broadcast.subscribe("UPDATE_FAVICON", this.updateFavicon);
    Broadcast.subscribe("GLOBAL_ESCAPE", this.globalEscape);
    Broadcast.subscribe(
      "OPEN_RESCHEDULE_EVENT_COMMAND_CENTER",
      this.openRescheduleCommandCenter
    );
    Broadcast.subscribe("TOGGLE_ACCOUNT", (order) => this.toggleAccount(order));
    layoutBroadcast.subscribe(
      LAYOUT_BROADCAST_VALUES.DISPLAY_AVAILABILITY_PREVIEW_MODAL,
      this.displayAvailabilityPreviewModal
    );
    Broadcast.subscribe("CREATE_EVENT_HANDLER", this.createEventHandler);
    Broadcast.subscribe(
      "TURN_ON_COLOR_COMMAND_CENTER",
      this.turnOnColorCommandCenter
    );
    Broadcast.subscribe(
      "DISPLAY_MULTIPLE_UPCOMING_EVENTS_MODAL",
      this.displayMultipleUpcomingEventsModal
    );
    Broadcast.subscribe("CLOSE_LAYOUT_MODAL", this.closeModal);
    ConferencingBroadcasts.subscribe(
      "OPEN_PAST_CONFERENCING_MODAL",
      this.openPastConferencingModal
    );
    Broadcast.subscribe(BROADCAST_VALUES.PARSE_ICS_FILE, this.parseICSFile);
    Broadcast.subscribe(
      "INSTANT_OPEN_COMMAND_CENTER",
      this.openInstantOpenCommandCenter
    );
    Broadcast.subscribe(
      BROADCAST_VALUES.OPEN_EXECUTIVE_AUTH_ERROR_MODAL,
      () => this.openAuthErrorModal(EXECUTIVE_AUTH_ERROR_MODAL),
    );
    Broadcast.subscribe(
      BROADCAST_VALUES.OPEN_PERSONAL_AUTH_ERROR_MODAL,
      () => this.openAuthErrorModal(PERSONAL_AUTH_ERROR_MODAL),
    );
    Broadcast.subscribe("OPEN_PERMISSIONS_MODAL", this.openPermissionsModal);
    Broadcast.subscribe(BROADCAST_VALUES.OPEN_INCREMENTAL_PERMISSIONS_MODAL, this.openIncrementalPermissionsModal);
    Broadcast.subscribe(
      "OPEN_GOOGLE_CALENDAR_SETTINGS_MODAL",
      this.openGoogleCalendarSettings
    );
    Broadcast.subscribe("OPEN_HELP_CENTER", this.openHelpCenter);
    Broadcast.subscribe("DISPLAY_PROMOTION_MODAL", this.displayPromotionModal);
    Broadcast.subscribe("DISPLAY_TEAM_JOIN_MODAL", this.displayJoinedTeamModal);
    Broadcast.subscribe(
      "TURN_ON_COMMAND_CENTER_WITH_DEFAULT_TEXT",
      this.turnOnCommandCenterWithDefaultText
    );
    AvailabilityBroadcast.subscribe(
      "DISPLAY_GROUP_VOTE_LINK_PREVIEW_MODAL",
      this.displayGroupVoteLinkPreviewModal
    );
    AvailabilityBroadcast.subscribe(
      "OPEN_GROUP_VOTE_LINK_INVITE",
      this.openGroupVoteLinkInviteModal
    );
    AvailabilityBroadcast.subscribe(
      "OPEN_GROUP_VOTE_EVENT_PICKER",
      this.openGroupVoteEventPicker
    );
    AvailabilityBroadcast.subscribe(
      "CREATE_EVENT_FROM_BOOKING_LINK",
      this.createGroupVoteEvent
    );
    AvailabilityBroadcast.subscribe(
      "CREATE_EVENT_FROM_SMART_HOLDS",
      this.createSmartHoldsEvent,
    );
    Broadcast.subscribe("UPDATE_EVENT_INDEX_HOT_KEYS", this.addKeyMap);
    Broadcast.subscribe(
      "OPEN_CONVERT_CALENDAR_MODAL",
      this.openConvertCalendarToUser
    );
    Broadcast.subscribe("DELETE_HOLD_EVENTS", this.openDeleteHoldEventsModal);
    Broadcast.subscribe("OPEN_DOWNLOAD_IOS_MODAL", this.openiOSModal);
    Broadcast.subscribe("OPEN_PERSONAL_LINKS", this.togglePersonalLinks);
    Broadcast.subscribe("TOGGLE_GLOBAL_KEY_MAP", this.toggleGlobalKeyMap);
    Broadcast.subscribe("SHOW_EMAIL_ATTENDEES_MODAL", this.showEmailModal);
    layoutBroadcast.subscribe("TOGGLE_FOCUS_MODE", this.toggleFocusMode);
    layoutBroadcast.subscribe("TOGGLE_ON_SLOTS_ANIMATION", this.toggleOnSlotsAnimation);
    layoutBroadcast.subscribe("TOGGLE_OFF_SLOTS_ANIMATION", this.toggleOffSlotsAnimation);
    layoutBroadcast.subscribe("SHOW_TRIAL_IS_OVER_MODAL", this.openTrialIsOverModal);
    layoutBroadcast.subscribe(APP_SETTINGS.OPEN_SETTINGS_MODAL, this.openSettingsModal);
    layoutBroadcast.subscribe("UPDATE_ANCHOR_TIME_ZONES", this.updateAnchorTimeZones);
    layoutBroadcast.subscribe("UPDATE_USER_DEFAULT_TIME_ZONE", this.updateUserDefaultTimeZone);
    layoutBroadcast.subscribe("SHOW_METRICS_MODAL", this.showMetricsModal);
    layoutBroadcast.subscribe("UPLOAD_AI_SCHEDULER", this.openAIScheduler);
    layoutBroadcast.subscribe(LAYOUT_BROADCAST_VALUES.TOGGLE_ON_SLOTS, this.toggleOnSlots);
    layoutBroadcast.subscribe("RESET_REVERSED_SLOTS_TEXT", this.resetReverseSlotsData);
    layoutBroadcast.subscribe("OPEN_FREE_TIME_FINDER_NOTIFICATION", this.openFreeTimeFinderNotification);
    layoutBroadcast.subscribe("OPEN_FORWARD_OUTLOOK_EVENT_MODAL", this.openForwardOutlookModal);
    layoutBroadcast.subscribe("DISPLAY_TEAM_JOIN_MODAL", this.displayJoinedTeamModal);
    layoutBroadcast.subscribe("HIDE_LEGEND_PANEL", this.hideShortcutsLegend);
    availabilityBroadcast.subscribe("GET_AVAILABLE_SLOTS_FIND_TIME", this.getAvailableSlots);
    layoutBroadcast.subscribe("OPEN_AFFILIATE_MODAL", this.openAffiliateModal);
    layoutBroadcast.subscribe(LAYOUT_BROADCAST_VALUES.GROUP_VOTE_PREVIEW, this.openGroupVotePreview);
    layoutBroadcast.subscribe(LAYOUT_BROADCAST_VALUES.OPEN_ONBOARDING_MODAL, this.openOnboardingModal);
    layoutBroadcast.subscribe(LAYOUT_BROADCAST_VALUES.OPEN_ACCOUNTS, this.openAccountsModal);
    layoutBroadcast.subscribe(LAYOUT_BROADCAST_VALUES.MAIN_CALENDAR_SELECT_TOOLBAR, this.openMainCalendarToolbarSelectModal);
    layoutBroadcast.subscribe(LAYOUT_BROADCAST_VALUES.REROUTE_TO_HOME, this.rerouteToHome);
    layoutBroadcast.subscribe(LAYOUT_BROADCAST_VALUES.TOGGLE_ON_GROUP_POLLS, this.toggleOnGroupPolls);
    layoutBroadcast.subscribe(LAYOUT_BROADCAST_VALUES.TOGGLE_PROVIDE_FEEDBACK, this.openFeedbackModal);
  }

  componentDidMount() {
    this._isMounted = true;

    document.addEventListener("mousemove", this.onMouseMoveLayout);
    window.addEventListener("resize", this.handleResize);

    !isElectron() && document.addEventListener("click", this.redirectURL);

    this.updateFavicon();

    Broadcast.publish("CHANGE_TAB_LABEL", getUserEmail(this.props.currentUser));

    this.checkforAccountStateUpdate();

    batch(() => {
      if (isDisplayMetricsParam()) {
        // has to be home/metrics
        trackMetricsOpen({
          where: "open from metrics param",
          user: this.props.currentUser,
        });
        this.showMetricsModal();
        this.rerouteToHome();
      } else {
        this.checkForRerouteToGroupVote();
      }

      if (!isMac() && this.props.isMac) {
        this.props.setIsMac(false);
      } else if (isMac() && !this.props.isMac) {
        this.props.setIsMac(true);
      }
    });

    if (!getLocalDataAppVersion()) {
      setLocalDataAppVersion(packageJson.version);
    }

    // bind mousetrap
    this.bindMouseTrap();
    this._hotKeysEnabled = true;

    const savedPromoCode = getSavedPromoCode();
    const promotionCode = savedPromoCode ?? getPromotionCode();
    if (promotionCode) {
      appBroadcast.publish("ADD_PROMOTION_CODE", promotionCode);

      if (savedPromoCode) {
        removeSavedPromoCode();
      }
    } else {
      BackendBroadcasts.publish("CHECK_FOR_PROMOTIONS_NOT_SEEN");
    }

    BackendBroadcasts.publish("TRACK_WINDOW_SIZE");
  }

  bindMouseTrap() {
    const isOSMac = isMac();
    const activeCommandCentersKey = isOSMac ? "command" : "ctrl";
    const appIsElectron = isElectron();

    this.addKeyMap();

    Mousetrap.bind(
      [
        `${activeCommandCentersKey}+k`,
        `${activeCommandCentersKey}+K`,
        `${activeCommandCentersKey} k`,
        `${activeCommandCentersKey} K`,
      ],
      this.turnOnCommandCenter
    );
    Mousetrap.bind(
      [
        `${activeCommandCentersKey}+o`,
        `${activeCommandCentersKey}+O`,
        `${activeCommandCentersKey} o`,
        `${activeCommandCentersKey} O`,
      ],
      this.openInstantOpenCommandCenter
    );
    // can not override safari settings
    // Mousetrap.bind(
    //   [`${activeCommandCentersKey}+,`, `${activeCommandCentersKey} ,`], (e) => {
    //     hasStopEventPropagation(e);
    //     hasEventPreventDefault(e); // stop chrome from opening up settings panel for chrome
    //     this.openSettingsModal();
    //   }
    // );
    Mousetrap.bind(["c"], this.createEventHandler);
    Mousetrap.bind(
      [
        `${activeCommandCentersKey}+;`,
        `${activeCommandCentersKey} ;`,
        `${activeCommandCentersKey}+shift+,`,
      ],
      this.turnOnTemplateCommandCenter
    );
    Mousetrap.bind(["Z", "z"], this.openTimeZoneCommandCenter);
    Mousetrap.bind(["w", "W"], () => this.changeCalendarView(7));
    Mousetrap.bind(["d", "D"], () => this.changeCalendarView(1));
    Mousetrap.bind(["4"], () => this.changeCalendarView(4));
    Mousetrap.bind(["7"], () => this.changeCalendarView(ROLLING_SEVEN_DAY));
    Mousetrap.bind(["m", "M"], () => this.changeCalendarView(BACKEND_MONTH));

    Mousetrap.bind(["P", "p"], this.turnOnColorCommandCenter);
    Mousetrap.bind([`${activeCommandCentersKey}+shift+right`], this.navigateToNextAccount);
    Mousetrap.bind([`${activeCommandCentersKey}+shift+left`], this.navigateToPreviousAccount);

    if (appIsElectron) {
      Mousetrap.bind([`${activeCommandCentersKey}+shift+]`], this.navigateToNextAccount);
      Mousetrap.bind([`${activeCommandCentersKey}+shift+[`], this.navigateToPreviousAccount);
    }

    Mousetrap.bind(
      [
        `${activeCommandCentersKey}+j`,
        `${activeCommandCentersKey}+J`,
        `${activeCommandCentersKey} j`,
        `${activeCommandCentersKey} J`,
      ],
      this.turnOnContactCommandCenter
    );
    Mousetrap.bind(
      this.addAbilityToActivateWithCommandAndControlOnIsElectron(
        1,
        appIsElectron
      ),
      (e) => this.toggleAccount(1, e)
    );
    Mousetrap.bind(
      this.addAbilityToActivateWithCommandAndControlOnIsElectron(
        2,
        appIsElectron
      ),
      (e) => this.toggleAccount(2, e)
    );
    Mousetrap.bind(
      this.addAbilityToActivateWithCommandAndControlOnIsElectron(
        3,
        appIsElectron
      ),
      (e) => this.toggleAccount(3, e)
    );
    Mousetrap.bind(
      this.addAbilityToActivateWithCommandAndControlOnIsElectron(
        4,
        appIsElectron
      ),
      (e) => this.toggleAccount(4, e)
    );
    Mousetrap.bind(
      this.addAbilityToActivateWithCommandAndControlOnIsElectron(
        5,
        appIsElectron
      ),
      (e) => this.toggleAccount(5, e)
    );
    Mousetrap.bind(
      this.addAbilityToActivateWithCommandAndControlOnIsElectron(
        6,
        appIsElectron
      ),
      (e) => this.toggleAccount(6, e)
    );
    Mousetrap.bind(
      this.addAbilityToActivateWithCommandAndControlOnIsElectron(
        7,
        appIsElectron
      ),
      (e) => this.toggleAccount(7, e)
    );
    Mousetrap.bind(
      this.addAbilityToActivateWithCommandAndControlOnIsElectron(
        8,
        appIsElectron
      ),
      (e) => this.toggleAccount(8, e)
    );
    Mousetrap.bind(
      this.addAbilityToActivateWithCommandAndControlOnIsElectron(
        9,
        appIsElectron
      ),
      (e) => this.toggleAccount(9, e)
    );
    Mousetrap.bind([ARROW_UP], (e) => this.moveEventWithArrowKey(ARROW_UP, e));
    Mousetrap.bind([ARROW_DOWN], (e) => this.moveEventWithArrowKey(ARROW_DOWN, e));
    Mousetrap.bind([ARROW_LEFT], (e) => this.moveEventWithArrowKey(ARROW_LEFT, e));
    Mousetrap.bind([ARROW_RIGHT], (e) => this.moveEventWithArrowKey(ARROW_RIGHT, e));

    Mousetrap.bind([FOCUS_MODE_HOTKEY], () => this.toggleFocusMode(true));

    // TODO: fix section below -> this section always gets activated
    Mousetrap.bind(["g q", "G Q"], (e) =>
      this.goToEventForm(e, EVENT_FORM_ALL_DAY)
    );
    Mousetrap.bind(["g a", "G A"], (e) =>
      this.goToEventForm(e, EVENT_FORM_ATTENDEE)
    );
    Mousetrap.bind(["g i", "G I"], (e) =>
      this.goToEventForm(e, EVENT_FORM_BUSY_FREE)
    );
    Mousetrap.bind(["g c", "G C"], (e) =>
      this.goToEventForm(e, EVENT_FORM_COLOR)
    );
    Mousetrap.bind(["g v", "G V"], (e) =>
      this.goToEventForm(e, EVENT_FORM_CONFERENCE)
    );
    Mousetrap.bind(["g p", "G P"], (e) =>
      this.goToEventForm(e, EVENT_FORM_DEFAULT_AVAILABILITY)
    );
    Mousetrap.bind(["g k", "G K"], (e) =>
      this.goToEventForm(e, EVENT_FORM_DESCRIPTION)
    );
    Mousetrap.bind(["g o", "G O"], (e) =>
      this.goToEventForm(e, EVENT_FORM_END_DATE_INPUT)
    );
    Mousetrap.bind(["g h", "G H"], (e) =>
      this.goToEventForm(e, EVENT_FORM_END_TIME_INPUT)
    );
    Mousetrap.bind(["g b", "G B"], (e) =>
      this.goToEventForm(e, EVENT_FORM_GUESTS_CAN)
    );
    Mousetrap.bind(["g l", "G L"], (e) =>
      this.goToEventForm(e, EVENT_FORM_LOCATION)
    );
    Mousetrap.bind(["g u", "G U"], (e) =>
      this.goToEventForm(e, EVENT_FORM_NOTIFICATION)
    );
    Mousetrap.bind(["g w", "G W"], (e) =>
      this.goToEventForm(e, EVENT_FORM_REPEAT)
    );
    Mousetrap.bind(["g r", "G R"], (e) =>
      this.goToEventForm(e, EVENT_FORM_ROOM)
    );
    Mousetrap.bind(["g d", "G D"], (e) =>
      this.goToEventForm(e, EVENT_FORM_START_DATE_INPUT)
    );
    Mousetrap.bind(["g t", "G T"], (e) =>
      this.goToEventForm(e, EVENT_FORM_START_TIME_INPUT)
    );
    Mousetrap.bind(["g s", "G S"], (e) =>
      this.goToEventForm(e, EVENT_FORM_SUMMARY)
    );
    Mousetrap.bind(["g g", "G G"], (e) =>
      this.goToEventForm(e, EVENT_FORM_EMAIL)
    );
    Mousetrap.bind(["g e", "G E"], (e) => this.openEmail(e));
    Mousetrap.bind([LAST_USED_SLOTS_HOT_KEYS], this.useLastSelectedSlots);
    Mousetrap.bind(["shift+enter", "shift enter"], this.getTimesOverLastFewDays);

    // copy personal link
    this.bindCopyPersonalLinksToMouseTrap(); //e.g. y1 and yy1
    Mousetrap.bind(["y p", "Y P", "y+p", "Y+P"], this.duplicatePreviewEvent);

    Mousetrap.bind("/", this.toggleSearch);
    Mousetrap.bind(["shift+/", "?"], this.displayShortcutsLegend);
    Mousetrap.bind(["u", "U", "k", "K"], this.goToPreviousWeek);
    Mousetrap.bind(["i", "I", "j", "J"], this.goToNextWeek);

    Mousetrap.bind([OPEN_SIDE_MENU_PANEL_HOTKEY], this.toggleSideMenuBar);
    Mousetrap.bind([TOGGLE_RIGHT_PANEL_HOTKEY], this.toggleRightPanel);
    Mousetrap.bind(["T", "t"], this.pressToday);
    Mousetrap.bind(["E", "e"], this.goToEventEditView);
    Mousetrap.bind(["V", "v"], this.openConference);
    Mousetrap.bind(["N", "n"], this.openUpcomingEvent);
    Mousetrap.bind(["L", "l"], this.openGoogleMaps);
    Mousetrap.bind(["F", "f"], this.toggleGlobalKeyMap);
    Mousetrap.bind(["A", "a"], this.toggleDefaultAvailability);
    Mousetrap.bind(["shift+y", "shift+Y"], this.togglePersonalLinks);
    Mousetrap.bind(["del", "backspace"], this.handleOnPressBackSpace);
    Mousetrap.bind(["escape", "`"], this.globalEscape);
    Mousetrap.bind(["g y", "G Y"], () => this.responseToEvent("ACCEPT_EVENT"));
    Mousetrap.bind(["g m", "G M"], () =>
      this.responseToEvent("MAYBE_ATTENDING_EVENT")
    );
    Mousetrap.bind(["g n", "G N"], () => this.responseToEvent("DECLINE_EVENT"));
    Mousetrap.bind(`'`, () => this.toggleSecondaryTimeZone(false));
    Mousetrap.bind("shift+'", () => this.toggleSecondaryTimeZone(true));
    Mousetrap.bind(["r", "R"], this.openRescheduleCommandCenter);
    Mousetrap.bind(
      [`${isOSMac ? "ctrl" : "alt"}+f`, `${isOSMac ? "ctrl" : "alt"}+F`],
      this.toggleGlobalKeyMap
    );
    Mousetrap.bind(
      ["command+c", "command+C", "ctrl+c", "ctrl+C"],
      this.commandC
    );
    Mousetrap.bind(
      ["command+shift+c", "command++shift+C", "ctrl+shift+c", "ctrl+shift+C"],
      this.copyAvailabilityLink
    );
    Mousetrap.bind(["y l", "Y L", "y+l", "Y+L"], this.yankLocation);
    Mousetrap.bind(["y v", "Y V", "y+v", "Y+V"], this.yankVideo);
    Mousetrap.bind(["shift+a", "shift a"], this.getAvailableSlots);
    Mousetrap.bind(["shift+c"], this.openEventFormFindTime);

    Mousetrap.bind(
      [
        "command+a",
        "command+A",
        "ctrl+a",
        "ctrl+A",
        "command+f",
        "command+F",
        "ctrl+f",
        "ctrl+F",
        "command+w",
        "command+W",
        "ctrl+w",
        "ctrl+W",
        "command+t",
        "command+T",
        "ctrl+t",
        "ctrl+T",
        "command+u",
        "command+U",
        "ctrl+u",
        "ctrl+U",
        "command+i",
        "command+I",
        "ctrl+i",
        "ctrl+I",
        "command+y",
        "command+Y",
        "ctrl+y",
        "ctrl+Y",
        "command+l",
        "command+L",
        "ctrl+l",
        "ctrl+L",
        "command+r",
        "command+R",
        "ctrl+r",
        "ctrl+R",
        "command+e",
        "command+E",
        "ctrl+e",
        "ctrl+E",
      ],
      this.doNothing
    );
  }

  unbindMouseTrap() {
    Mousetrap.reset();
  }

  determineCopyAvailabilityKey(secondaryKey) {
    return [
      `${COPY_AVAILABILITIES_TRIGGER_HOT_KEY} ${secondaryKey}`,
      `${COPY_AVAILABILITIES_TRIGGER_HOT_KEY.toUpperCase()} ${secondaryKey}`,
    ];
  }

  bindCopyPersonalLinksToMouseTrap() {
    // y1
    const keys = [1, 2, 3, 4, 5, 6, 8, 8, 9, 0];
    keys.forEach(k => {
      Mousetrap.bind(this.determineCopyAvailabilityKey(k), (e) =>
        this.copyPersonalLink(k, e)
      );
    });

    // yy2
    keys.forEach(k => {
      Mousetrap.bind(this.determineCopyOnlyAvailabilityLinkKey(k), (e) =>
        this.copyPersonalLink(k, e, true)
      );
    });
  }

  determineCopyOnlyAvailabilityLinkKey(secondaryKey) {
    return [
      `${COPY_AVAILABILITIES_TRIGGER_HOT_KEY} ${COPY_AVAILABILITIES_TRIGGER_HOT_KEY} ${secondaryKey}`,
      `${COPY_AVAILABILITIES_TRIGGER_HOT_KEY.toUpperCase()} ${COPY_AVAILABILITIES_TRIGGER_HOT_KEY.toUpperCase()} ${secondaryKey}`,
    ];
  }

  doNothing() {
    // Do nothing
  }

  changeCalendarView(view) {
    if (isActionModeCreateAvailability(this.props.actionMode) && view === BACKEND_MONTH) {
      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        "You can not go into monthly calendar view when selecting availability."
      );
      return;
    }

    mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.DETERMINE_CALENDAR_VIEW_CHANGE, view);
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return hasStateOrPropsChanged(
      this.state,
      nextState,
      this.props,
      nextProps,
      ["match", "children", "location"],
      null
    );
  }

  componentDidUpdate(prevProps, prevState) {
    // Code for hotkeys for events
    const localAppVersion = getLocalDataAppVersion();

    if (
      localAppVersion &&
      packageJson.version &&
      localAppVersion !== packageJson.version
    ) {
      this.determineRefreshAppMethod(localAppVersion);
    } else if (!localAppVersion && packageJson.version) {
      setLocalDataAppVersion(packageJson.version);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousemove", this.onMouseMoveLayout);
    window.removeEventListener("resize", this.handleResize);

    const { resetEventHotKeysIndex } = this.props.eventIndexStore;
    resetEventHotKeysIndex();

    !isElectron() && document.removeEventListener("click", this.redirectURL);

    this._isMounted = false;

    clearTimeout(this._reloadComponentTimer);
    clearTimeout(this._resizeTimer);
    this._resizeTimer = null;
    this._reloadComponentTimer = null;

    this._onMouseMoveTimer && clearTimeout(this._onMouseMoveTimer);
    this._onMouseMoveTimer = null;

    Broadcast.unsubscribe("TOGGLE_SHOULD_SHOW_SET_TIME_ZONE");
    Broadcast.unsubscribe("TURN_ON_COMMAND_CENTER");
    Broadcast.unsubscribe("TURN_ON_CONTACT_COMMAND_CENTER");
    Broadcast.unsubscribe("TOGGLE_SEARCH");
    Broadcast.unsubscribe(BROADCAST_VALUES.OPEN_LOGIN_TO_NEW_ACCOUNTS);
    Broadcast.unsubscribe("TOGGLE_SHOW_TEMPLATE");
    Broadcast.unsubscribe("TURN_ON_TEMPLATE_COMMAND_CENTER");
    Broadcast.unsubscribe("DISABLE_HOT_KEYS");
    Broadcast.unsubscribe("ENABLE_HOT_KEYS");
    layoutBroadcast.unsubscribe("SET_LAST_MOUSETRAP_BIND_TIME");
    Broadcast.unsubscribe("UPDATE_DAY");
    Broadcast.unsubscribe("UPDATE_FAVICON");
    Broadcast.unsubscribe("GLOBAL_ESCAPE");
    Broadcast.unsubscribe("OPEN_RESCHEDULE_EVENT_COMMAND_CENTER");
    Broadcast.unsubscribe("TOGGLE_ACCOUNT");
    layoutBroadcast.unsubscribe(LAYOUT_BROADCAST_VALUES.DISPLAY_AVAILABILITY_PREVIEW_MODAL);
    Broadcast.unsubscribe("CREATE_EVENT_HANDLER");
    Broadcast.unsubscribe("TURN_ON_COLOR_COMMAND_CENTER");
    Broadcast.unsubscribe("DISPLAY_MULTIPLE_UPCOMING_EVENTS_MODAL");
    Broadcast.unsubscribe("CLOSE_LAYOUT_MODAL");
    Broadcast.unsubscribe("PARSE_ICS_FILE");
    Broadcast.unsubscribe("INSTANT_OPEN_COMMAND_CENTER");
    Broadcast.unsubscribe(BROADCAST_VALUES.OPEN_EXECUTIVE_AUTH_ERROR_MODAL);
    Broadcast.unsubscribe(BROADCAST_VALUES.OPEN_PERSONAL_AUTH_ERROR_MODAL);
    Broadcast.unsubscribe("OPEN_PERMISSIONS_MODAL");
    Broadcast.unsubscribe("OPEN_GOOGLE_CALENDAR_SETTINGS_MODAL");
    Broadcast.unsubscribe("OPEN_HELP_CENTER");
    Broadcast.unsubscribe("DISPLAY_PROMOTION_MODAL");
    Broadcast.unsubscribe("DISPLAY_TEAM_JOIN_MODAL");
    AvailabilityBroadcast.unsubscribe("DISPLAY_GROUP_VOTE_LINK_PREVIEW_MODAL");
    AvailabilityBroadcast.unsubscribe("OPEN_GROUP_VOTE_LINK_INVITE");
    AvailabilityBroadcast.unsubscribe("OPEN_GROUP_VOTE_EVENT_PICKER");
    AvailabilityBroadcast.unsubscribe("CREATE_EVENT_FROM_BOOKING_LINK");
    Broadcast.unsubscribe("UPDATE_EVENT_INDEX_HOT_KEYS");
    Broadcast.unsubscribe("OPEN_CONVERT_CALENDAR_MODAL");
    Broadcast.unsubscribe("DELETE_HOLD_EVENTS");
    Broadcast.unsubscribe("OPEN_DOWNLOAD_IOS_MODAL");
    Broadcast.unsubscribe("OPEN_PERSONAL_LINKS");
    Broadcast.unsubscribe("TOGGLE_GLOBAL_KEY_MAP");
    Broadcast.unsubscribe("SHOW_EMAIL_ATTENDEES_MODAL");
    Broadcast.unsubscribe(
      "TURN_ON_COMMAND_CENTER_WITH_DEFAULT_TEXT",
    );
    layoutBroadcast.unsubscribe("TOGGLE_FOCUS_MODE");
    layoutBroadcast.unsubscribe("TOGGLE_ON_SLOTS_ANIMATION");
    layoutBroadcast.unsubscribe("TOGGLE_OFF_SLOTS_ANIMATION");
    layoutBroadcast.unsubscribe("SHOW_TRIAL_IS_OVER_MODAL");
    layoutBroadcast.unsubscribe(APP_SETTINGS.OPEN_SETTINGS_MODAL);
    layoutBroadcast.unsubscribe("UPDATE_ANCHOR_TIME_ZONES");
    layoutBroadcast.unsubscribe("UPDATE_USER_DEFAULT_TIME_ZONE");
    layoutBroadcast.unsubscribe("SHOW_METRICS_MODAL");
    layoutBroadcast.unsubscribe("UPLOAD_AI_SCHEDULER");
    layoutBroadcast.unsubscribe(LAYOUT_BROADCAST_VALUES.TOGGLE_ON_SLOTS);
    layoutBroadcast.unsubscribe("RESET_REVERSED_SLOTS_TEXT");
    layoutBroadcast.unsubscribe("OPEN_FREE_TIME_FINDER_NOTIFICATION");
    layoutBroadcast.unsubscribe("OPEN_FORWARD_OUTLOOK_EVENT_MODAL");
    layoutBroadcast.unsubscribe("HIDE_LEGEND_PANEL");
    availabilityBroadcast.unsubscribe("GET_AVAILABLE_SLOTS_FIND_TIME");
    Broadcast.unsubscribe(BROADCAST_VALUES.PARSE_ICS_FILE);
    layoutBroadcast.unsubscribe("OPEN_AFFILIATE_MODAL");
    AvailabilityBroadcast.unsubscribe("CREATE_EVENT_FROM_SMART_HOLDS");
    layoutBroadcast.unsubscribe(LAYOUT_BROADCAST_VALUES.GROUP_VOTE_PREVIEW);
    layoutBroadcast.unsubscribe(LAYOUT_BROADCAST_VALUES.OPEN_ONBOARDING_MODAL);
    layoutBroadcast.unsubscribe(LAYOUT_BROADCAST_VALUES.OPEN_ACCOUNTS);
    layoutBroadcast.unsubscribe(LAYOUT_BROADCAST_VALUES.MAIN_CALENDAR_SELECT_TOOLBAR);
    layoutBroadcast.unsubscribe(LAYOUT_BROADCAST_VALUES.REROUTE_TO_HOME);
    layoutBroadcast.unsubscribe(LAYOUT_BROADCAST_VALUES.TOGGLE_ON_GROUP_POLLS);
    layoutBroadcast.unsubscribe(LAYOUT_BROADCAST_VALUES.TOGGLE_PROVIDE_FEEDBACK);
    Broadcast.unsubscribe(BROADCAST_VALUES.OPEN_INCREMENTAL_PERMISSIONS_MODAL);

    // Unmount Mousetrap
    this.unbindMouseTrap();
  }

  render() {
    return (
      <div
        id={LAYOUT_ID}
        className={classNames(this.getContainerClassName())}
      >
        <DropZoneContainer>
          {(this.props.shouldShowGlobalKeyMap ||
            this.props.shouldDisplayMenu) &&
            this.renderCoverOverLay()}
          {this.renderShortcutsLegend()}
          {this.props.shouldShowTopBar ? <LayoutTopBar /> : null}
          {this.state.isElectron && <DesktopTitleBar />}
          {this.props.children}
          {this.renderCommandCenters()}
          <PreloadResources />

          {this.state.displaySlotsAnimation ? <SlotsAnimation /> : null}

          <PreloadResources
            resources={getPreloadBackgroundImages(this.props.currentUser)}
          />
          <BottomLeftModalContainer 
            isParentModalOpen={this.state.shouldDisplayModal}
          />

          <PasteContainer />
          <TeamPlanInvitesContainer />

          <DefaultChecks />
          <Switch>
            <Route path="/search" component={Search} />
            <Route
              path={`/${NEW_EA_LOGIN_PATH}`}
              render={(props) => (
                <Login {...props} authenticated={true} isMaestroNew={true} />
              )}
            />
            <Route path="/new" component={AuthorizedLogin} />
            <Route path="/edit-templates" component={EditTemplates} />
          </Switch>

          <div className={classNames("absolute", this.state.shouldDisplayFocusMode ? "z-50" : "invisible")}>
            <FocusModeContainer
              onClickExit={() => this.toggleFocusMode(false)}
              isShowing={this.state.shouldDisplayFocusMode}
            />
          </div>

          {this.isTrialIsOverModal()
            ? null
            : <HoverUpcomingEvent />
          }

          <FloatingFocusModeCountdownTimer shouldHide={this.state.shouldDisplayFocusMode || !this.shouldHideRightHandSide()} />

          <CalendarHomeView />

          <DisappearingNotificationMessage />
          {this.renderModal()}
          <input
            type="file"
            id="ics-file-select"
            aria-label="File browser example"
            onChange={this.onChangeUpload}
            className="hidden"
          />
        </DropZoneContainer>
        <ShortcutSuggestion />
      </div>
    );
  }

  renderCoverOverLay() {
    return (
      <div
        onClick={
          this.props.shouldDisplayMenu
            ? this.closeSideMenuBar
            : this.toggleGlobalKeyMap
        }
        className={ClassNames(
          "layout-over-lay",
          this.props.shouldDisplayMenu ? "z-index-2" : ""
        )}
      ></div>
    );
  }

  renderCommandCenters() {
    return (
      <div>
        {this.renderMainCommandCenter()}

        {this.renderTemplateCommandCenter()}

        {this.renderContactCommandCenter()}

        {this.renderTimeZoneCommandCenter()}

        {this.renderColorCommandCenter()}

        {this.renderRescheduleCommandCenter()}

        {this.renderInstantOpenCommandCenter()}
      </div>
    );
  }

  renderShortcutsLegend() {
    const {
      shouldShowShortcutsLegend,
      shouldShowTopBar
    } = this.props;
    if (!shouldShowShortcutsLegend) {
      return null;
    }

    const topSpacing = calculateMarginTopClassname(shouldShowTopBar);
    return (
      <div
        className={ClassNames("layout-shortcuts-legend-container", topSpacing)}
      >
        <div
          id="layout-cover-over-lay"
          onClick={this.hideShortcutsLegend}
          className={ClassNames(
            "layout-shortcuts-legend-cover small-blur",
            topSpacing
          )}
        ></div>

        <div className={classNames("layout-display-shortcut-legends-list", topSpacing)}>
          <ShortcutsLegend closeShortcutsLegend={this.hideShortcutsLegend} />
        </div>
      </div>
    );
  }

  renderMainCommandCenter() {
    return this.state.shouldShowCommandCenter ? (
      <CommandCenterContainer
        handleCloseModal={this.closeCommandCenter}
        shouldShowCommandCenter={this.state.shouldShowCommandCenter}
        defaultText={this.state.commandCenterDefaultText}
      />
    ) : null;
  }

  renderTemplateCommandCenter() {
    if (!this.state.shouldShowTemplateCommandCenter) {
      return null;
    }

    if (this.isEmailModel()) {
      return (
        <TemplateCommandCenter
          handleCloseModal={this.closeTemplateCommandCenter}
          shouldShowCommandCenter={this.state.shouldShowTemplateCommandCenter}
          isStickiesTemplate={true}
          onClickCopyText={(text) => {
            layoutBroadcast.publish("PASTE_IN_STICKY", text);
          }}
        />
      );
    }

    return (
      <TemplateCommandCenter
        handleCloseModal={this.closeTemplateCommandCenter}
        shouldShowCommandCenter={this.state.shouldShowTemplateCommandCenter}
        onlyText={false}
        slotsElementForTemplate={this.state.slotsElementForTemplate}
      />
    );
  }

  renderContactCommandCenter() {
    return this.state.shouldShowContactCommandCenter ? (
      <ContactCommandCenter
        handleCloseModal={this.closeContactCommandCenter}
        shouldShowCommandCenter={this.state.shouldShowContactCommandCenter}
      />
    ) : null;
  }

  renderTimeZoneCommandCenter() {
    return this.state.shouldShowTimeZoneCommandCenter ? (
      <TimeZoneCommandCenter
        handleCloseModal={this.closeTimeZoneCommandCenter}
        shouldShowCommandCenter={this.state.shouldShowTimeZoneCommandCenter}
      />
    ) : null;
  }

  renderColorCommandCenter() {
    return this.state.shouldShowColorCommandCenter ? (
      <ColorCommandCenter
        handleCloseModal={this.closeColorCommandCenter}
        shouldShowCommandCenter={this.state.shouldShowColorCommandCenter}
        event={this.getPreviewEvent()}
      />
    ) : null;
  }

  renderRescheduleCommandCenter() {
    return this.state.shouldShowRescheduleCommandCenter ? (
      <RescheduleCommandCenter
        handleCloseModal={this.closeRescheduleCommandCenter}
        shouldShowCommandCenter={this.state.shouldShowRescheduleCommandCenter}
        event={this.getPreviewEvent()}
      />
    ) : null;
  }

  renderInstantOpenCommandCenter() {
    if (!this.state.shouldShowInstantOpenCommandCenter) {
      return null;
    }

    return (
      <InstantOpenCommandCenter
        handleCloseModal={this.closeInstantOpenCommandCenter}
        shouldShowCommandCenter={this.state.shouldShowInstantOpenCommandCenter}
        event={this.getPreviewEvent()}
      />
    );
  }

  onCloseTrialIsOverModal() {
    this.enableHotKeys();
    this.closeModal();
  }

  renderModal() {
    const {
      modalContent
    } = this.state;

    if (this.isTrialIsOverModal()) {
      return (
        <TrialExpiredModal onRequestClose={this.onCloseTrialIsOverModal} />
      );
    } else if (modalContent === AI_SCHEDULE_MODAL) {
      return (
        <AISchedulerModal
          isOpen={true}
          onRequestClose={this.closeModal}
          isDarkMode={this.props.isDarkMode}
          initialReverseSlotsText={this.state.initialReverseSlotsText}
          temporaryStateStore={this.props.temporaryStateStore}
          isLoading={this.state.isReverseSlotsLoadingOnOpen}
        />
      );
    }

    return (
      <EventModalPopup
        isOpen={this.state.shouldDisplayModal}
        onRequestClose={this.closeModal}
        shouldFreeze={this.state.shouldDisableClose}
        width={this.state.modalWidth}
        height={this.state.modalHeight}
        title={this.state.modalTitle}
        style={this.determineModalStyle()}
        onClickBack={this.state.onClickBack}
        skipDisablingHotKeys={this.isTrialIsOverModal()}
        fontSizeClassName={this.isModalSettingsModal() ? "font-size-20" : null}
        exitButtonLocation={this.isModalSettingsModal() ? "absolute top-5 right-5" : null}
        hideTitle={this.shouldHideModalTitle()}
        hideHeader={this.shouldHideModalHeader()}
        contentClassName={this.getModalClassName()}
      >
        {this.renderModalContent()}
      </EventModalPopup>
    );
  }

  shouldHideModalTitle() {
    return this.isModalMetrics() || this.isViewAccountsModal() || this.isModalSettingsModal();
  }

  isFeedbackModal() {
    return this.state.modalContent === FEEDBACK_MODAL;
  }

  isTrialIsOverModal() {
    return this.state.modalContent === TRIAL_IS_OVER_MODAL;
  }

  isAffiliateModal() {
    return this.state.modalContent === AFFILIATE_MODAL;
  }

  shouldHideModalHeader() {
    return this.isFreeTimeFinderNotificationOpen() ||
    this.isViewAccountsModal() ||
    this.isMainCalendarDropdownModal() ||
    this.isFeedbackModal() ||
    this.isGoogleGroupsAuthModal();
  }

  isViewAccountsModal() {
    return this.state.modalContent === VIEW_ACCOUNTS_MODAL;
  }

  isMainCalendarDropdownModal() {
    return this.state.modalContent === MAIN_CALENDAR_SELECT_MODAL;
  }

  isGoogleGroupsAuthModal() {
    return this.state.modalContent === PERMISSION_MODAL_TYPES.UPDATE_GOOGLE_GROUPS_PERMISSIONS;
  }

  renderModalContent() {
    switch (this.state.modalContent) {
      case FEEDBACK_MODAL:
        return <Feedback onClose={this.closeModal} feedbackType={this.state.feedbackType} />;
      case MAIN_CALENDAR_SELECT_MODAL:
        return <CalendarDropdownOptions onClose={this.closeModal} />;
      case VIEW_ACCOUNTS_MODAL:
        return <AccountDropdown onClose={this.closeModal} />;
      case METRICS_MODAL:
        return <MetricsWrapper />;
      case CREATE_EVENT_FROM_BOOKING_LINK:
        return (
          <GroupVoteCreateEvent
            bookingLink={this.state.groupVoteLink}
            closeModal={this.closeModal}
          />
        );
      case GROUP_VOTE_PREVIEW:
        return (
          <GroupVoteCreateEvent
            bookingLink={this.state.groupVoteLink}
            closeModal={this.closeModal}
            isPreview={true}
          />
        );
      case INVITE_GROUP_LINK_ATTENDEES:
        return (
          <GroupVoteInviteAttendees
            token={this.state.groupVoteLinkToken}
            groupVoteLinkURL={this.state.groupVoteLinkURL} // entire url of the group vote
            newAttendeeEmails={this.state.newGroupVoteAttendeeEmails}
            closeModal={this.closeModal}
            isSpreadsheet={this.state.isSpreadsheet}
          />
        );
      case AVAILABILITY_GROUP_VOTE_LINK_PREVIEW_MODAL:
        return (
          <AvailabilityLink
            previewInformation={this.state.modalAvailabilityPreviewInformation}
          />
        );
      case AVAILABILITY_PREVIEW_MODAL:
        return (
          <AvailabilityLink
            previewInformation={this.state.modalAvailabilityPreviewInformation}
          />
        );
      case MULTIPLE_UPCOMING_EVENTS_MODAL:
        return (
          <MultipleUpcomingEventsModal
            upcomingEvents={this.state.upcomingEvents}
            hotkeyType={this.state.hotkeyType}
          />
        );
      case PAST_CONFERENCING:
        return (
          <div>
            "{this.state.pastEventTitle}" is in the past. Do you still want to
            open the conferencing link?
            <div className="display-flex-flex-direction-row align-items-center justify-content-flex-end mt-10">
              <CustomButton
                buttonType={WHITE_BUTTON}
                onClick={this.closeModal}
                label="Cancel"
                addPaddingToRight={true}
              />

              <CustomButton
                shouldFocus={true}
                buttonType={BLUE_BUTTON}
                onClick={() => {
                  Broadcast.publish("OPEN_EVENT_EXPANDED_VIDEO", true);
                  this.closeModal();
                }}
                label="Open"
              />
            </div>
          </div>
        );
      case UPDATE_CONTACTS_PERMISSIONS:
        return (
          <RequestPermissionsModal
            messageType={"contacts"}
            email={this.state.permissionEmail}
            provider={this.state.permissionProvider}
          />
        );
      case PERMISSION_MODAL_TYPES.UPDATE_CALENDAR_PERMISSIONS:
        return (
          <RequestPermissionsModal
            messageType={"calendar"}
            email={this.state.permissionEmail}
            provider={this.state.permissionProvider}
          />
        );
      case PERMISSION_MODAL_TYPES.UPDATE_AUTH_PERMISSIONS:
        return (
          <RequestPermissionsModal
            messageType={"auth"}
            email={this.state.permissionEmail}
            provider={this.state.permissionProvider}
          />
        );
      case PERMISSION_MODAL_TYPES.USER_CONSENT_SCREEN:
        return (
          <RequestPermissionsModal
            messageType={"auth"}
            email={this.state.permissionEmail}
            provider={this.state.permissionProvider}
            showConsentScreen={true}
          />
        );
      case PERMISSION_MODAL_TYPES.UPDATE_EMAIL_PERMISSIONS:
        return (
          <RequestPermissionsModal
            messageType={"email"}
            email={this.state.permissionEmail}
            provider={this.state.permissionProvider}
          />
        );
      case PERMISSION_MODAL_TYPES.UPDATE_GOOGLE_DRIVE_PERMISSIONS:
        return (
          <IncrementalPermissionsModal
            messageType={GOOGLE_INCREMENTAL_AUTH_NAMES.DRIVE}
            email={this.state.permissionEmail}
          />
        );
      case PERMISSION_MODAL_TYPES.UPDATE_GOOGLE_GROUPS_PERMISSIONS:
        return (
          <IncrementalPermissionsModal
            messageType={GOOGLE_INCREMENTAL_AUTH_NAMES.GROUPS}
            email={this.state.permissionEmail}
          />
        );
      case GOOGLE_CALENDAR_SETTINGS_MODAL:
        return <GoogleCalendarSettingsModal closeModal={this.closeModal} />;
      case HELP_CENTER:
        return (
          <HelpCenterContainer
            closeModal={this.closeModal}
            selectedOption={this.state.modalData}
          />
        );
      case CLAIM_PROMOTION:
        return (
          <PromotionModalScreen
            modals={this.state.promotionModal}
            closeModal={this.closeModal}
          />
        );
      case CONVERT_CALENDAR_TO_USER:
        return (
          <ConvertCalendarToUser
            userCalendarId={this.state.modalData}
            closeModal={this.closeModal}
          />
        );
      case DELETE_HOLD_EVENTS:
        return (
          <DeleteHoldEvent
            closeModal={this.closeModal}
            event={this.state.modalData}
          />
        );
      case VIMCAL_IOS:
        return <DownloadIOS />;
      case EMAIL_ATTENDEES:
        return (
          <EmailAttendees
            event={this.state.emailAttendeesEvent}
            closeModal={this.closeModal}
          />
        )
      case EMAIL_ATTENDEES_RUNNING_LATE:
        return (
          <EmailAttendees
            event={this.state.emailAttendeesEvent}
            closeModal={this.closeModal}
            defaultMessage={"Running a few minutes late!"}
          />
        );
      case SETTINGS_MODAL:
        return (
          <SettingsModal
            onClose={this.closeModal}
            initialSetting={this.state.settingsModalInitialContent}
            initialConferencing={this.state.conferencingSetting}
            skipBillingPromotion={this.state.skipBillingPromotion}
            initialSettingsUser={this.state.initialSettingsUser}
            initialSlotsSettingsUser={this.state.initialSlotsSettingsUser}
            scrollToSettingContent={this.state.scrollToSettingContent}
          />
        );
      case FREE_TIME_FINDER_NOTIFICATION:
        return <FreeTimeFinderModal />;
      case FORWARD_OUTLOOK_EVENT:
        return (
          <ForwardOutlookEventModal
            closeModal={this.closeModal}
            outlookForwardOriginalEvent={this.state.outlookForwardOriginalEvent}
            event={this.state.emailAttendeesEvent}
          />
        );
      case JOINED_TEAM_PLAN:
        return (
          <TeamPlanJoinedModal
            adminEmail={this.state.teamPlanJoined.admin_user.email} 
            closeModal={this.closeModal}
          />
        );
      case MAESTRO_ZOOM_LOGIN_MODAL:
        return <MaestroZoomLoginInfo onClose={this.closeModal} />;
      case AFFILIATE_MODAL:
        return <AffiliateModal onClose={this.closeModal} />;
      case ONBOARDING_MODAL:
        return <OnboardingModal onClose={this.closeModal} />;
      case EXECUTIVE_AUTH_ERROR_MODAL:
        return <AuthErrorModal variant={AUTH_ERROR_VARIANTS.EXECUTIVE} />;
      case PERSONAL_AUTH_ERROR_MODAL:
        return <AuthErrorModal variant={AUTH_ERROR_VARIANTS.PERSONAL} />;
      default:
        return null;
    }
  }

  //================
  // EVENT HANDLERS
  //================

  getAvailableSlots(e) {
    hasEventPreventDefault(e);
    this.pressAToggleAvailabilityMode(SLOTS_AVAILABILITY_SELECTION); // always open on slots
    setTimeout(() => {
      availabilityBroadcast.publish("GET_UPCOMING_WEEK_AVAILABILITY");
    }, 0.1 * SECOND_IN_MS);
  }

  toggleDefaultAvailability() {
    this.pressAToggleAvailabilityMode();
  }

  openFreeTimeFinderNotification() {
    return;
    this.setState({
      modalContent: FREE_TIME_FINDER_NOTIFICATION,
      shouldDisplayModal: true,
      modalWidth: "400px",
    });
  }

  isFreeTimeFinderNotificationOpen() {
    return this.state.modalContent === FREE_TIME_FINDER_NOTIFICATION;
  }

  isBottomLeftModal() {
    return this.isFreeTimeFinderNotificationOpen();
  }

  isFreeTimeFinderModalOpen() {
    return this.state.modalContent === AI_SCHEDULE_MODAL;
  }

  openAIScheduler(param) {
    this.setState({
      modalContent: AI_SCHEDULE_MODAL,
      initialReverseSlotsText: param?.initialText,
      isReverseSlotsLoadingOnOpen: param?.isLoading,
    });
  }

  updateUserDefaultTimeZone({
    newTimeZone,
    user,
    isUpdatingExecutiveProfile
  }) {
    if (!newTimeZone) {
      // sanity check
      return;
    }

    const {
      masterAccount
    } = this.props.masterAccount;
    const {
      currentUser
    } = this.props;

    // update masterAccount
    const updatedSettings = {};
    if (newTimeZone === LOCAL_TIME_ZONE) {
      // set time zone to fixed time zone
      const guessedTimeZone = guessTimeZone();
      if (!user || user?.email === this.props.currentUser.email) {
        this.props.setTimeZone(guessedTimeZone);
        this.props.setDefaultBrowserTimeZone(guessedTimeZone);
      }

      updatedSettings[BACKEND_SETTINGS_NAMES.USE_TIME_ZONE_OVERRIDE] = false;
      updatedSettings[BACKEND_SETTINGS_NAMES.TIME_ZONE_OVERRIDE] = null;
    } else {
      if (!newTimeZone) {
        return;
      }

      updatedSettings[BACKEND_SETTINGS_NAMES.USE_TIME_ZONE_OVERRIDE] = true;
      updatedSettings[BACKEND_SETTINGS_NAMES.TIME_ZONE_OVERRIDE] = newTimeZone;

      if (isUserDelegatedUser(currentUser)) {
        if (isUpdatingExecutiveProfile && (!user || user?.email === currentUser.email)) {
          this.props.setTimeZone(newTimeZone);
          this.props.setDefaultBrowserTimeZone(newTimeZone);
        }
      } else if (!user || user?.email === this.props.currentUser.email) {
        this.props.setTimeZone(newTimeZone);
        this.props.setDefaultBrowserTimeZone(newTimeZone);
      }
    }

    if (isEmptyObjectOrFalsey(updatedSettings)) {
      return;
    }

    updateMasterAccountSettingsForFrontendAndBackend({
      masterAccount,
      updatedSettings,
      user,
      isUpdatingExecutiveProfile
    });
  }

  updateAnchorTimeZones({
    timeZones,
    user,
    isUpdatingExecutiveProfile
  }) {
    if (!timeZones) {
      return;
    }

    const {
      currentUser
    } = this.props;

    const filteredTimeZones = removeDuplicatesFromArray(
      filterOutInvalidTimeZones(timeZones)
    );

    const {
      lastSelectedTimeZone,
      resetLastSelectedTimeZone
    } = this.props.appTimeZone;
    if (lastSelectedTimeZone && !filteredTimeZones?.includes(lastSelectedTimeZone)) {
      resetLastSelectedTimeZone();
    }

    if (!filteredTimeZones.includes(this.props.currentTimeZone)) {
      // if deleted time zone that is the current time zone -> reset to back home
      Broadcast.publish("RETURN_TO_DEFAULT_TIME_ZONE");
    }

    const {
      masterAccount
    } = this.props.masterAccount;
    updateMasterAccountSettingsForFrontendAndBackend({
      masterAccount,
      updatedSettings: {
        [BACKEND_SETTINGS_NAMES.ANCHOR_TIME_ZONES]: filteredTimeZones
      },
      user,
      isUpdatingExecutiveProfile
    });

    if (isUserDelegatedUser(currentUser)) {
      if (isUpdatingExecutiveProfile && (!user || user?.email === this.props.currentUser.email)) {
        this.props.setAnchorTimeZones(filteredTimeZones);
      }
    } else if (!user || user?.email === this.props.currentUser.email) {
      this.props.setAnchorTimeZones(filteredTimeZones);
    }
  }

  shouldPauseNavigatingAccounts() {
    const loggedInAccounts = getAllLoggedInAccountDetails();

    const {
      currentUser
    } = this.props;

    if (isEmptyObjectOrFalsey(currentUser)) {
      return true;
    }

    if (!loggedInAccounts || loggedInAccounts.length === 0) {
      appBroadcast.publish("CLICK_LOG_OUT");
      return true;
    }

    if (loggedInAccounts.length === 0 || loggedInAccounts.length === 1) {
      return true;
    }

    return false
  }

  navigateToNextAccount(e) {
    hasEventPreventDefault(e);
    if (this.shouldPauseNavigatingAccounts()) {
      return;
    }

    const loggedInAccounts = getAllLoggedInAccountDetails();

    const {
      currentUser
    } = this.props;

    const currentAccountIndex = loggedInAccounts.findIndex(account => account.email === currentUser.email);
    const newIndex = currentAccountIndex + 1 >= loggedInAccounts.length ? 0 : currentAccountIndex + 1;
    const newAccount = loggedInAccounts[newIndex];

    if (isEmptyObjectOrFalsey(newAccount)) {
      return;
    }

    Broadcast.publish("SWITCH_ACCOUNTS", { account: newAccount });
  }

  isEmailAttendeesModal() {
    return this.state.modalContent === EMAIL_ATTENDEES 
      || this.state.modalContent === EMAIL_ATTENDEES_RUNNING_LATE;
  }

  navigateToPreviousAccount(e) {
    hasEventPreventDefault(e);
    if (this.shouldPauseNavigatingAccounts()) {
      return;
    }

    const loggedInAccounts = getAllLoggedInAccountDetails();

    const {
      currentUser
    } = this.props;

    const currentAccountIndex = loggedInAccounts.findIndex(account => account.email === currentUser.email);
    const newIndex = currentAccountIndex === 0 ? loggedInAccounts.length - 1 : currentAccountIndex - 1;
    const newAccount = loggedInAccounts[newIndex];

    if (isEmptyObjectOrFalsey(newAccount)) {
      return;
    }

    Broadcast.publish("SWITCH_ACCOUNTS", { account: newAccount });
  }

  toggleAccount(order, e) {
    hasEventPreventDefault(e);

    const loggedInAccounts = getAllLoggedInAccountDetails();

    if (!loggedInAccounts || loggedInAccounts.length === 0) {
      trackError({
        category: "log_out_bug",
        errorMessage: `no_logged_in_accounts_on_toggle`,
        userToken: this.props.currentUser.token
      });

      appBroadcast.publish("CLICK_LOG_OUT");
      return;
    }

    if (loggedInAccounts.length >= order) {
      const newAccount = loggedInAccounts[order - 1];

      if (isEmptyObjectOrFalsey(newAccount)) {
        return;
      }

      Broadcast.publish("SWITCH_ACCOUNTS", { account: newAccount });
    }
  }

  moveEventWithArrowKey(key, e) {
    hasEventPreventDefault(e);

    if (isModalOpen() || isColorSelectorOpen()) {
      return;
    }

    const shouldArrowKeysWork = () => {
      if (this.isAppInTaskMode()) {
        return false;
      }
      return (
        window.location.href.includes("home") &&
        !this.isCommandCenterOn()
      );
    };

    if (shouldArrowKeysWork()) {
      Broadcast.publish("MOVE_EVENT_WITH_ARROW_KEYS", key);
    }
  }

  closeSideMenuBar() {
    this.props.setShouldDisplayMenu(false);
  }

  openGoogleMaps() {
    if (this.getPreviewEvent()) {
      this._lastPressedLTime = Date.now();
      Broadcast.publish("OPEN_EVENT_EXPANDED_LOCATION");
    } else {
      if (this.props.shouldShowGlobalKeyMap) {
        this.props.hideGlobalKeyMap();
      }

      this._lastPressedLTime = Date.now();
      Broadcast.publish("OPEN_NEXT_EVENT_LOCATION");
    }
  }

  openConference() {
    if (this.getPreviewEvent()) {
      this._lastPressedVTime = Date.now();
      Broadcast.publish("OPEN_EVENT_EXPANDED_VIDEO");
    } else {
      if (this.props.shouldShowGlobalKeyMap) {
        this.props.hideGlobalKeyMap();
      }

      this._lastPressedVTime = Date.now();
      Broadcast.publish("OPEN_NEXT_EVENT_CONFERENCING");
    }
  }

  openTimeZoneCommandCenter(e = null) {
    hasEventPreventDefault(e);

    Broadcast.publish("HIDE_TOOLTIP_BOX");
    const location = isActionModeUpsertEvent(this.props.actionMode) ? "Event_form" : "home";
    trackEvent({
      category: location,
      action: "turn_on_time_zone_command_center",
      label: "command_center",
      userToken: getUserToken(this.props.currentUser)
    });

    this.handleOpeningCommandCenter({ skipRemovePopup: false });

    this.setState({
      shouldShowTimeZoneCommandCenter: true,
      shouldShowInstantOpenCommandCenter: false,
    });
  }

  updateAnchorTimeZone;

  determineModalStyle() {
    const {
      modalContent,
    } = this.state;
    const {
      isDarkMode,
    } = this.props;
    if (this.isBottomLeftModal()) {
      const getContentBottomLocation = () => {
        return "-182px";
      };
      const getContentLeftLocation = () => {
        return "-182px";
      };
      return {
        overlay: {
          position: "fixed",
          inset: 0,
          backgroundColor: "rgba(0, 0, 0, 0)",
          zIndex: MODAL_OVERLAY_Z_INDEXES.LOW_PRIORITY,
        },
        content: {
          top: "auto",
          left: getContentLeftLocation(),
          right: "auto",
          bottom: getContentBottomLocation(),
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          borderColor: "transparent",
          boxShadow: MODAL_CONTENT_BOX_SHADOW,
          zIndex: 5,
          backgroundColor: getModalBackgroundColor(isDarkMode), // white with opacity rgba(255, 255, 255, 0.7)
          color: isDarkMode
            ? StyleConstants.darkModeModalTextColor
            : StyleConstants.defaultFontColor,
          maxHeight: "90vh",
          backdropFilter: MODAL_BLUR,
          WebkitBackdropFilter: MODAL_BLUR,
          borderRadius: "20px",
          padding: 0,
        },
      };
    }

    if (this.isViewAccountsModal()) {
      const getTop = () => {
        if (this.props.shouldShowTopBar) {
          return isElectron() ? "122px" : "82px";
        }
        return isElectron() ? "92px" : "50px";
      };
      const getBackgroundColor = () => {
        return isElectron()
          ? getModalBackgroundColorWithNoOpacity(isDarkMode)
          : getModalBackgroundColorWithExtraOpacity(isDarkMode);
      };
      return {
        overlay: {
          position: "fixed",
          inset: 0,
          backgroundColor: "rgba(0, 0, 0, 0)",
          zIndex: MODAL_OVERLAY_Z_INDEXES.LOW_PRIORITY,
        },
        content: {...({
          position: "absolute",
          top: getTop(),
          right: "22px",
          zIndex: 5,
          backgroundColor: getBackgroundColor(),
          color: isDarkMode
            ? StyleConstants.darkModeModalTextColor
            : StyleConstants.defaultFontColor,
          maxHeight: "90vh",
          padding: 0,
          overflowX: "hidden",
          overflowY: "auto",
          border: getDefaultModalBorder(isDarkMode),
        }),
        ...getSidePoppedOverModalBorder(isDarkMode),
        },
      };
    }

    if (this.isMainCalendarDropdownModal()) {
      const getTop = () => {
        if (this.props.shouldShowTopBar) {
          return isElectron() ? "128px" : "88px";
        }
        return isElectron() ? "98px" : "56px";
      };
      const getLeft = () => {
        const dropdownOption = document.getElementById(CALENDAR_TYPE_DROPDOWN_ID);
        if (dropdownOption) {
          const elementLeft = dropdownOption.getBoundingClientRect().x;
          return elementLeft - 120;
        }
        return "100px"; // should never get here
      };

      return {
        overlay: {
          position: "fixed",
          inset: 0,
          backgroundColor: "rgba(0, 0, 0, 0)",
          zIndex: MODAL_OVERLAY_Z_INDEXES.LOW_PRIORITY,
        },
        content: {...({
          position: "absolute",
          top: getTop(),
          left: getLeft(),
          zIndex: 5,
          backgroundColor: getModalBackgroundColorWithExtraOpacity(isDarkMode), // white with opacity rgba(255, 255, 255, 0.7)
          color: isDarkMode
            ? StyleConstants.darkModeModalTextColor
            : StyleConstants.defaultFontColor,
          maxHeight: "90vh",
          padding: 0,
          overflowX: "hidden",
          overflowY: "auto",
          border: getDefaultModalBorder(isDarkMode),
        }),
        ...getSidePoppedOverModalBorder(isDarkMode),
        },
      };
    }

    if (this.isAvailabilityPreviewModal()) {
      const determineMaxModalHeight = () => {
        if (
          modalContent === CREATE_EVENT_FROM_BOOKING_LINK ||
          modalContent === AVAILABILITY_GROUP_VOTE_LINK_PREVIEW_MODAL
        ) {
          return "100vh";
        } else {
          return "90vh";
        }
      };

      return {
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.70)",
          zIndex: MODAL_OVERLAY_Z_INDEXES.LOW_PRIORITY,
        },
        content: {
          position: "absolute",
          background: "#ffffff",
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          borderColor: "transparent",
          borderRadius: MODAL_BORDER_RADIUS,
          boxShadow: MODAL_CONTENT_BOX_SHADOW,
          zIndex: 5,
          color: StyleConstants.defaultFontColor,
          maxHeight: determineMaxModalHeight(),
          overflow: this.shouldHideModalOverflow() ? "hidden" : "auto",
          outline: "none",
          padding: "20px",
        },
      };
    }

    const defaultStyle = determineDefaultModalStyle(this.props.isDarkMode);
    if (this.isModalSettingsModal()) {
      defaultStyle.content.height = "90vh";
      defaultStyle.content.maxHeight = "630px";
      defaultStyle.content.padding = "40px";
    } else if (this.isModalMetrics()) {
      defaultStyle.content.height = undefined;
      defaultStyle.content.padding = "20px 20px 0px 20px";
    }

    if (this.isModalSettingsModal() && !this.props.isDarkMode) {
      // we need this for the update profile modal, otherwise, the modal border looks weird
      defaultStyle.content.borderColor = undefined;
    }
    if (this.isEmailAttendeesModal()) {
      defaultStyle.content.overflowY = "auto";
    }

    return defaultStyle;
  }

  isModalSettingsModal() {
    return this.state.modalContent === SETTINGS_MODAL;
  }

  isModalMetrics() {
    return this.state.modalContent === METRICS_MODAL;
  }

  async openRescheduleCommandCenter(e = null) {
    hasEventPreventDefault(e);
    let currentEvent = this.getPreviewEvent(); // can be set later if preview event
    const {
      allCalendars
    } = this.props.allCalendars;

    if (isPreviewOutlookEvent(currentEvent)) {
      const response = await getFullEvent({
        event: currentEvent,
        userEmail: getEventUserEmail(currentEvent),
      });
      if (!this._isMounted) {
        return;
      }
      if (this.isAppInTaskMode()) {
        return;
      }

      if (!response?.event) {
        // if fetch failed -> show error
        Broadcast.publish(
          SET_DISAPPEARING_NOTIFICATION_MESSAGE,
          GENERIC_ERROR_MESSAGE
        );
        return;
      }

      const {
        event: fullEvent,
      } = response;
      if (getEventID(this.getPreviewEvent()) !== getEventID(fullEvent)) {
        // if event has changed -> early return
        return;
      }
      currentEvent = fullEvent;
      expandedEventViewBroadcast.publish(EXPANDED_VIEW_BROADCAST_VALUES.SET_FULL_OUTLOOK_EVENT, fullEvent);
    }

    if (!currentEvent) {
      return;
    } else if (!this.isCurrentEventEditable()) {
      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        CAN_NOT_UPDATE_EVENT_MESSAGE
      );
      return false;
    } else if (
      !hasPermissionToModify(currentEvent, allCalendars)
    ) {
      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        "You don't have permission to reschedule this event.",
      );
    } else if (
      currentEvent &&
      !isInActionMode(this.props.actionMode)
    ) {
      this.setHoverToPreviewEvent();
      this.handleOpeningCommandCenter();

      this.setState({
        shouldShowRescheduleCommandCenter: true,
      });
    }
  }

  turnOnCommandCenterWithDefaultText(defaultText) {
    this.handleOpeningCommandCenter();

    this.setState({
      shouldShowCommandCenter: true,
      commandCenterDefaultText: defaultText || "",
    });
  }

  turnOnCommandCenter(e = null) {
    hasEventPreventDefault(e);

    Broadcast.publish("HIDE_TOOLTIP_BOX");
    const location = isActionModeUpsertEvent(this.props.actionMode) ? "Event_form" : "home";
    trackEvent({
      category: location,
      action: "turn_on_command_center",
      label: "command_center",
      userToken: getUserToken(this.props.currentUser)
    });

    this.handleOpeningCommandCenter();
    this.setState({
      shouldShowCommandCenter: true,
    });
  }

  openiOSModal() {
    this.setState({
      shouldDisplayModal: true,
      modalWidth: 500,
      modalTitle: "Download Vimcal for iOS",
      modalContent: VIMCAL_IOS,
    });
  }

  openHelpCenter(selectedOption = null) {
    const determineWidth = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth > 1500) {
        return 1000;
      } else if (windowWidth > 1200) {
        return 800;
      } else {
        return 600;
      }
    };

    this.setState({
      shouldDisplayModal: true,
      modalWidth: determineWidth(),
      modalTitle: "Help Center",
      modalContent: HELP_CENTER,
      modalData: selectedOption,
    });
  }

  /**
   * @param {typeof EXECUTIVE_AUTH_ERROR_MODAL | typeof PERSONAL_AUTH_ERROR_MODAL} modalContent
   */
  openAuthErrorModal(modalContent) {
    this.setState({
      shouldDisplayModal: true,
      modalWidth: 380,
      modalTitle: "Account already exists",
      modalContent,
    });
  }

  openPermissionsModal({
    modalContent,
    permissionProvider = CALENDAR_PROVIDERS.GOOGLE,
    permissionEmail,
  }) {
    this.setState({
      shouldDisplayModal: true,
      shouldDisableClose: true,
      modalWidth: 380,
      modalTitle: "Update Permissions",
      modalContent,
      permissionProvider,
      permissionEmail,
    });
  }

  openIncrementalPermissionsModal({
    modalContent,
    permissionProvider = CALENDAR_PROVIDERS.GOOGLE,
    permissionEmail,
  }) {
    this.setState({
      shouldDisplayModal: true,
      shouldDisableClose: true,
      modalWidth: 320,
      modalContent,
      permissionProvider,
      permissionEmail,
    });
  }

  openConvertCalendarToUser(userCalendarId) {
    this.setState({
      shouldDisplayModal: true,
      modalWidth: 300,
      modalTitle: "Convert Calendar",
      modalContent: CONVERT_CALENDAR_TO_USER,
      modalData: userCalendarId,
    });
  }

  openDeleteHoldEventsModal(event) {
    this.setState({
      shouldDisplayModal: true,
      shouldDisableClose: true,
      modalWidth: 380,
      modalTitle: "Delete Hold Event(s)",
      modalContent: DELETE_HOLD_EVENTS,
      modalData: event,
    });
  }

  openInstantOpenCommandCenter(e) {
    hasEventPreventDefault(e);
    this.handleOpeningCommandCenter();

    if (isInActionMode(this.props.actionMode)) {
      // do not show anything if currently in edit mode or availability mode
      return;
    }

    if (this.props.currentHoverEvent) {
      batch(() => {
        this.props.setPreviewedEvent(this.props.currentHoverEvent);
        this.props.history.push("/home/expanded");
        this.props.removeCurrentHoverEvent();
      });
    }

    this.setState({
      shouldShowInstantOpenCommandCenter: true,
    });
  }

  isEmailModel() {
    return this.state.modalContent === EMAIL_ATTENDEES || this.state.modalContent === EMAIL_ATTENDEES_RUNNING_LATE;
  }

  turnOnTemplateCommandCenter(e = null, slotsElementForTemplate) {
    hasEventPreventDefault(e);

    const location = isActionModeUpsertEvent(this.props.actionMode) ? "Event_form" : "home";
    trackEvent({
      category: location,
      action: "turn_on_template_command_center",
      label: "command_center",
      userToken: getUserToken(this.props.currentUser)
    });

    if (this.isEmailModel()) {
      // do nothing
    } else {
      // only remove preview event if it's template
      mainCalendarBroadcast.publish("REMOVE_PREVIEW_EVENT");
    }

    this.handleOpeningCommandCenter({ skipRemovePopup: this.isEmailModel() });

    this.setState({
      shouldShowTemplateCommandCenter: true,
      slotsElementForTemplate
    });
  }

  closeCommandCenter() {
    if (isEventFormShowing()) {
      Broadcast.publish("FOCUS_FIELD");
    }

    isTemplateViewShowing() &&
      Broadcast.publish("FOCUS_TEMPLATE_FIELD");

    this.setState({
      shouldShowCommandCenter: false,
      commandCenterDefaultText: "",
    });
  }

  closeTemplateCommandCenter() {
    if (isEventFormShowing()) {
      Broadcast.publish("FOCUS_FIELD");
    }

    if (isTemplateViewShowing()) {
      Broadcast.publish("FOCUS_TEMPLATE_FIELD");
    }

    this.setState({
      shouldShowTemplateCommandCenter: false,
      slotsElementForTemplate: null,
    });
  }

  toggleSearch(e = null) {
    if (!isInActionMode(this.props.actionMode)) {
      hasEventPreventDefault(e);

      if (doesWindowLocationIncludeString("search")) {
        this.props.history.replace("/home");
        mainCalendarBroadcast.publish("REMOVE_GLOBAL_SHORT_CUT_SUGGESTION");
      } else {
        trackEvent({
          category: "home",
          action: "open_search",
          label: "search",
          userToken: getUserToken(this.props.currentUser)
        });

        this.props.history.replace("/search");
        this.removeExpandedEvents();
      }
    }

    if (this.props.shouldShowGlobalKeyMap) {
      this.props.hideGlobalKeyMap();
    }
  }

  openLoginToNewAccounts() {
    Broadcast.publish("CLOSE_WEEKLY_CALENDAR_MODAL");
    const { masterAccount } = this.props.masterAccount;
    if (isUserMaestroUser(masterAccount)) {
      return this.props.history.replace(`/${NEW_EA_LOGIN_PATH}`);
    }
    this.props.history.replace("/new");
  }

  // edit Templates
  toggleEditTemplates(e = null) {
    if (!isInActionMode(this.props.actionMode)) {
      hasEventPreventDefault(e);

      if (doesWindowLocationIncludeString("edit-templates")) {
        this.props.history.replace("/home");
      } else {
        trackEvent({
          category: "home",
          action: "open_edit_template",
          label: "templates",
          userToken: getUserToken(this.props.currentUser)
        });

        this.props.history.replace("/edit-templates");

        this.removeExpandedEvents();
      }
    }
  }

  handleOpeningCommandCenter({ skipRemovePopup = true } = {}) {
    batch(() => {
      if (this.props.shouldDisplayMenu) {
        this.props.setShouldDisplayMenu(false);
      }

      if (this.props.shouldShowShortcutsLegend) {
        this.props.hideShortcutsLegend();
      }

      if (this.props.shouldShowGlobalKeyMap) {
        this.props.hideGlobalKeyMap();
      }

      if (!skipRemovePopup && !isEmptyObjectOrFalsey(this.props.popupEvent)) {
        this.props.removePopupEvent();
      }
    });
  }

  setHoverToPreviewEvent() {
    if (this.props.currentHoverEvent) {
      batch(() => {
        this.props.setPreviewedEvent(this.props.currentHoverEvent);
        this.props.history.push("/home/expanded");

        this.props.removeCurrentHoverEvent();
      });
    } else if (this.props.hoverPopupEvent) {
      batch(() => {
        this.props.setPopupEvent({
          location: this.props.hoverPopupEvent.location,
          event: this.props.hoverPopupEvent.event
        });
        this.props.removeHoverPopupEvent()
      });
    }
  }

  openUpcomingEvent(e) {
    hasEventPreventDefault(e);
    Broadcast.publish("SHOW_NEXT_EVENT");
  }

  //=================
  // PRIVATE METHODS
  //=================

  // For custom event hot keys
  addKeyMap(inputEventHotKeysIndex) {
    if (this.props.selectedCalendarView === BACKEND_MONTH) {
      return;
    }

    const { eventHotKeysIndex } = this.props.eventIndexStore;
    const eventIndex = inputEventHotKeysIndex ?? eventHotKeysIndex;

    if (this.state.eventHandlerList.length > 0) {
      this.state.eventHandlerList.forEach((h) => {
        // Unbind old events
        Mousetrap.unbind(h);
      });
    }

    let updatedEventHandlerList = [];

    !isEmptyObjectOrFalsey(eventIndex) &&
      Object.values(eventIndex).forEach((e) => {
        Mousetrap.bind(e.sequentialHandler, () =>
          this.setPreviewEvent(e.event)
        );
        Mousetrap.bind(e.sequentialHandlerCap, () =>
          this.setPreviewEvent(e.event)
        );

        updatedEventHandlerList = updatedEventHandlerList.concat([
          e.sequentialHandler,
          e.sequentialHandlerCap,
        ]);
      });

    // keep track of events
    this.setState({
      eventHandlerList: updatedEventHandlerList,
    });
  }

  handleResize() {
    clearTimeout(this._resizeTimer);
    this._resizeTimer = null;
    this._resizeTimer = setTimeout(() => {
      if (!this._isMounted) {
        return;
      }
      if (isMobile() !== this.props.isMobileView) {
        this.props.setIsMobileView(isMobile());
        // rbc-slots and gutter does not immediate update on pros change
        mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.REMOUNT_CALENDAR_WITH_DELAY);
  
        const {
          setHideRightHandSide
        } = this.props.hideRightHandSidebar;
  
        if (isMobile() && shouldTruncateRightHandPanel(this.props.hideRightHandSidebar)) {
          setHideRightHandSide(false);
        }
      }

      if (isActionModeUpsertEvent(this.props.actionMode)) {
        eventFormBroadcast.publish("UPTICK_RERENDER_COUNT");
      }
    }, 0.5 * SECOND_IN_MS);
  }

  onMouseMoveLayout(e) {
    if (!e || !e.clientX) {
      return;
    }

    const { setIsMouseMoving } = this.props.useIsMouseMoving;

    if (
      !this._isMouseMovingLayout &&
      (!this._onMouseMoveInfo ||
        Math.abs(e.timeStamp - this._onMouseMoveInfo.timeStamp) < 100 ||
        Math.abs(e.clientX - this._onMouseMoveInfo.clientX) > 5 ||
        Math.abs(e.clientY - this._onMouseMoveInfo.clientY) > 5)
    ) {
      this._isMouseMovingLayout = true;

      setIsMouseMoving(true);
    }

    let updatedInfo = {};

    updatedInfo.clientX = e.clientX;
    updatedInfo.clientY = e.clientY;
    updatedInfo.timeStamp = e.timeStamp;
    this._onMouseMoveInfo = updatedInfo;

    this._onMouseMoveTimer && clearTimeout(this._onMouseMoveTimer);
    this._onMouseMoveTimer = null;

    this._onMouseMoveTimer = setTimeout(() => {
      if (!this._isMounted || !this._isMouseMovingLayout) {
        return;
      }

      setIsMouseMoving(false);
      this._isMouseMovingLayout = false;
    }, STILL_TIMEOUT);
  }

  resetReverseSlotsData(removeBookingLinkData) {
    const {
      resetReverseSlotsData,
      reverseSlotsText,
      resetBookingLinkState
    } = this.props.temporaryStateStore;
    if (reverseSlotsText) {
      resetReverseSlotsData();
    }
    if (removeBookingLinkData) {
      resetBookingLinkState();
    }
  }

  closeModal() {
    if (this.state.modalContent === PAST_CONFERENCING) {
      const { setShouldStayOpen } = this.props.shouldStayOpen;
      setShouldStayOpen(false);
    }

    if (this.state.modalContent === CLAIM_PROMOTION) {
      BackendBroadcasts.publish(
        "UPDATE_SEEN_PROMOTION",
        getFirstUnseenPromotion(this.state.promotionModal)
      );
    }
    Broadcast.publish(BROADCAST_VALUES.CLOSE_ACCOUNTS_DROPDOWN);
    pasteBroadcast.publish("DISCARD_PASTE_TEXT_AI");

    this.setState({
      shouldDisplayModal: false,
      modalContent: null,
      slotsSettingDefaultTab: null,
      emailAttendeesEvent: null,
      permissionProvider: null,
      permissionEmail: null,
      settingsModalInitialContent: null,
      skipBillingPromotion: false,
      initialReverseSlotsText: "",
      isReverseSlotsLoadingOnOpen: false,
      shouldDisableClose: false,
      outlookForwardOriginalEvent: null,
      initialSettingsUser: null,
      newGroupVoteAttendeeEmails: null,
      modalHeight: undefined,
      groupVoteLinkURL: null,
      initialSlotsSettingsUser: null,
      scrollToSettingContent: null,
      isSpreadsheet: false,
      feedbackType: null,
    });
  }

  displayMultipleUpcomingEventsModal(
    upcomingEvents,
    title,
    type = TYPE_CONFERENCING
  ) {
    this.setState({
      shouldDisplayModal: true,
      modalWidth: "450px",
      modalTitle: title || "Multiple upcoming events",
      modalContent: MULTIPLE_UPCOMING_EVENTS_MODAL,
      upcomingEvents: upcomingEvents,
      hotkeyType: type,
    });
  }

  openGoogleCalendarSettings() {
    this.setState({
      shouldDisplayModal: true,
      modalWidth: "450px",
      modalTitle: "Update Google Calendar settings",
      modalContent: GOOGLE_CALENDAR_SETTINGS_MODAL,
    });
  }

  showMetricsModal() {
    this.setState({
      shouldDisplayModal: true,
      modalWidth: "900px",
      modalTitle: "",
      modalContent: METRICS_MODAL,
    }); 
  }
  
  openSettingsModal(param) {
    this.setState({
      shouldDisplayModal: true,
      modalHeight: "100%",
      modalWidth: "908px",
      modalTitle: "",
      modalContent: SETTINGS_MODAL,
      settingsModalInitialContent: param?.initialContent,
      scrollToSettingContent: param?.scrollToSettingContent,
      conferencingSetting: param?.conferencingSetting,
      skipBillingPromotion: param?.skipBillingPromotion,
      initialSettingsUser: param?.initialSettingsUser,
      initialSlotsSettingsUser: param?.initialSlotsSettingsUser,
    });
  }

  openGroupVoteEventPicker(groupVoteLink) {
    if (isEmptyObjectOrFalsey(groupVoteLink)) {
      return;
    }
    this.setState({
      groupVoteLink,
      shouldDisplayModal: true,
      modalWidth: "calc(100%-20px)",
      modalContent: CREATE_EVENT_FROM_BOOKING_LINK,
      modalTitle: "Create event from Group Vote link",
    });
  }

  openGroupVotePreview(groupVoteLink) {
    if (isEmptyObjectOrFalsey(groupVoteLink)) {
      return;
    }
    this.setState({
      groupVoteLink,
      shouldDisplayModal: true,
      modalWidth: "calc(100%-20px)",
      modalContent: GROUP_VOTE_PREVIEW,
      modalTitle: "Preview Group Vote",
    });
  }

  openGroupVoteLinkInviteModal({
    token,
    groupVoteLinkURL,
    newAttendeeEmails,
    isSpreadsheet,
  }) {
    if (!token) {
      return;
    }
    this.setState({
      groupVoteLinkURL,
      groupVoteLinkToken: token,
      shouldDisplayModal: true,
      modalWidth: "100%",
      modalContent: INVITE_GROUP_LINK_ATTENDEES,
      modalTitle: "Share your invite",
      newGroupVoteAttendeeEmails: newAttendeeEmails, // show the diff so user can send email to the new emails
      isSpreadsheet,
    });
  }

  displayGroupVoteLinkPreviewModal(bookingLink) {
    this.setState({
      shouldDisplayModal: true,
      modalWidth: "calc(100%-20px)",
      modalTitle: "Group Vote link",
      modalContent: AVAILABILITY_GROUP_VOTE_LINK_PREVIEW_MODAL,
      modalAvailabilityPreviewInformation: bookingLink,
    });
  }

  displayAvailabilityPreviewModal(previewInfo) {
    this.setState({
      shouldDisplayModal: true,
      modalWidth: "calc(100%-20px)",
      modalTitle: previewInfo.modalTitle || "Availability Preview",
      modalContent: AVAILABILITY_PREVIEW_MODAL,
      modalAvailabilityPreviewInformation: previewInfo,
    });
  }

  openPastConferencingModal(pastEventTitle) {
    const { setShouldStayOpen } = this.props.shouldStayOpen;
    setShouldStayOpen(true);
    this.setState({
      shouldDisplayModal: true,
      modalWidth: 500,
      modalTitle: "Event has ended",
      modalContent: PAST_CONFERENCING,
      pastEventTitle: pastEventTitle || "This event",
    });
  }

  displayShortcutsLegend(e = null) {
    hasEventPreventDefault(e);

    this.props.displayShortcutLegend();
  }

  hideShortcutsLegend() {
    this.props.hideShortcutsLegend();
  }

  redirectURL(e) {
    let currentURL;
    let stage = process.env.REACT_APP_CLIENT_ENV;

    switch (stage) {
      case ENVIRONMENTS.DEV:
        currentURL = "localhost:3000";
        break;
      case ENVIRONMENTS.STAGING:
        currentURL = "calendar-staging.vimcal.com";
        break;
      case ENVIRONMENTS.DOGFOOD:
        currentURL = "calendar-dogfood.vimcal.com";
        break;
      case ENVIRONMENTS.PRODUCTION:
        currentURL = "calendar.vimcal.com";
        break;
      case ENVIRONMENTS.TESTING:
        currentURL = "calendar-testing.vimcal.com";
        break;
      default:
        currentURL = "calendar.vimcal.com";
    }

    if (
      document.activeElement.href &&
      document.activeElement.href.toLowerCase().indexOf(currentURL) === -1
    ) {
      hasEventPreventDefault(e);

      OpenLink(document.activeElement.href);
    }
  }

  updateDay() {
    if (
      !isValid(this.props.selectedDay) ||
      !isSameDay(this.props.selectedDay, new Date())
    ) {
      let todayDate = new Date();
      this.props.selectDay(todayDate);
      Broadcast.publish("UPDATE_MONTHLY_CALENDAR_START_OF_MONTH", todayDate);

      this.props.setAgendaDay(todayDate);

      Broadcast.publish("UPDATE_MENU_BAR_AND_AGENDA");
    }

    this.updateFavicon();
  }

  toggleSecondaryTimeZone(isReverse = false) {
    Broadcast.publish("TOGGLE_SECONDARY_TIME_ZONE_GUTTER_HEADER", isReverse);
  }

  updateFavicon() {
    let favicon = document.getElementById("favicon");

    favicon.href =
      process.env.PUBLIC_URL + `/day${format(new Date(), "d")}.png`;

    favicon = null;
  }

  pressToday() {
    Broadcast.publish("PRESS_TODAY");

    if (this.props.shouldShowGlobalKeyMap) {
      this.props.hideGlobalKeyMap();
    }
  }

  goToPreviousWeek() {
    Broadcast.publish("PREVIOUS_WEEK");

    if (this.props.shouldShowGlobalKeyMap) {
      this.props.hideGlobalKeyMap();
    }
  }

  goToNextWeek() {
    Broadcast.publish("NEXT_WEEK");

    if (this.props.shouldShowGlobalKeyMap) {
      this.props.hideGlobalKeyMap();
    }
  }

  goToPreviousMonth() {
    Broadcast.publish("PREVIOUS_MONTH");

    if (this.props.shouldShowGlobalKeyMap) {
      this.props.hideGlobalKeyMap();
    }
  }

  responseToEvent(response) {
    this.setHoverToPreviewEvent();

    if (isRSVPSectionRendered()) {
      Broadcast.publish(response);
    }

    if (this.props.shouldShowGlobalKeyMap) {
      this.props.hideGlobalKeyMap();
    }
  }

  goToNextMonth() {
    Broadcast.publish("NEXT_MONTH");

    if (this.props.shouldShowGlobalKeyMap) {
      this.props.hideGlobalKeyMap();
    }
  }

  determineRefreshAppMethod(localAppVersion) {
    let newVersion = packageJson.version.split(".");
    let oldVersion = localAppVersion.split(".");

    localData(LOCAL_DATA_ACTION.SET, "appVersion", packageJson.version);

    if (newVersion[0] !== oldVersion[0] || newVersion[1] !== oldVersion[1]) {
      Broadcast.publish("COMPLETELY_REFRESH_APP", this.props.currentUser);
    }
  }

  handleOnPressBackSpace() {
    if (isActionModeCreateAvailability(this.props.actionMode)) {
      return AvailabilityBroadcast.publish("DELETE_LAST_AVAILABILITY_EVENT");
    }

    this.deletePreviewedEvent();
  }

  deletePreviewedEvent() {
    const previewEvent = this.getPreviewEvent();

    if (
      previewEvent &&
      !this.isCommandCenterOn() &&
      !this.isAppInTaskMode() &&
      !isInSearchState() &&
      !isInEditTemplatesState()
    ) {
      if (
        previewEvent &&
        this.eventCanBeDeleted(previewEvent)
      ) {
        Broadcast.publish("CLICK_DELETE");
      } else if (previewEvent) {
        Broadcast.publish(
          SET_DISAPPEARING_NOTIFICATION_MESSAGE,
          CAN_NOT_UPDATE_EVENT_MESSAGE
        );
      }
    }
  }

  eventCanBeDeleted(event) {
    const {
      actionMode,
    } = this.props;
    const {
      reverseSlotsText
    } = this.props.temporaryStateStore;
    const {
      allCalendars
    } = this.props.allCalendars;
    return shouldDisplayDeleteEventButton({
      actionMode,
      event,
      allCalendars,
      reverseSlotsText,
    });
  }

  addAbilityToActivateWithCommandAndControlOnIsElectron(
    otherKey,
    appIsElectron
  ) {
    if (isMac()) {
      return appIsElectron
        ? [`command+${otherKey}`, `ctrl+${otherKey}`]
        : [`ctrl+${otherKey}`];
    } else {
      return `alt+${otherKey}`;
    }
  }

  pressAToggleAvailabilityMode(availabilityType) {
    if (this.isAppInTaskMode()) {
      return;
    }

    Broadcast.publish("TOGGLE_AVAILABILITY_MODE", availabilityType);
    this.removeExpandedEventsAndPushToHome();
  }

  async togglePersonalLinks() {
    Broadcast.publish("HIDE_TOOLTIP_BOX");
    await this.props.setActionMode(ACTION_MODE.CREATE_AVAILABILITY);
    Broadcast.publish("SET_AVAILABILITY_TYPE", PERSONAL_LINK_SELECTION);
  }

  async toggleOnSlots() {
    await this.props.setActionMode(ACTION_MODE.CREATE_AVAILABILITY);
    Broadcast.publish("SET_AVAILABILITY_TYPE", SLOTS_AVAILABILITY_SELECTION);
  }

  async toggleOnGroupPolls() {
    await this.props.setActionMode(ACTION_MODE.CREATE_AVAILABILITY);
    Broadcast.publish("SET_AVAILABILITY_TYPE", GROUP_VOTE_SELECT_OPTION);
  }

  toggleSideMenuBar() {
    if (this.props.shouldDisplayMenu) {
      this.props.setShouldDisplayMenu(false);
    } else {
      this.props.setShouldDisplayMenu(true);
    }
  }

  turnOnContactCommandCenter(e = null) {
    hasEventPreventDefault(e);

    const location = isActionModeUpsertEvent(this.props.actionMode) ? "Event_form" : "home";
    trackEvent({
      category: location,
      action: "turn_on_contact_command_center",
      label: "command_center",
      userToken: getUserToken(this.props.currentUser)
    });

    this.handleOpeningCommandCenter();

    this.setState({
      shouldShowContactCommandCenter: true,
    });
  }

  isCurrentEventOutlookEvent() {
    const currentEvent = this.getPreviewEvent();
    return isOutlookEvent(currentEvent);
  }

  isCurrentEventEditable() {
    const currentEvent = this.getPreviewEvent();
    const {
      actionMode,
    } = this.props;
    const {
      reverseSlotsText
    } = this.props.temporaryStateStore;
    const {
      allCalendars
    } = this.props.allCalendars;
    return isEditable({
      event: currentEvent,
      allCalendars,
      actionMode,
      reverseSlotsText
    });
  }

  turnOnColorCommandCenter(e = null) {
    this._lastPressedPTime = Date.now();
    hasEventPreventDefault(e);
    hasStopEventPropagation(e);

    const currentEvent = this.getPreviewEvent();
    if (!currentEvent) {
      return;
    }
    const isEventEditableForTagging = this.isCurrentEventEditable() 
      || this.isCurrentEventOutlookEvent();
    if (!isEventEditableForTagging) {
      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        CAN_NOT_UPDATE_EVENT_MESSAGE
      );
      return;
    }
    this.setHoverToPreviewEvent();

    const eventBoundingRect = getEventClientRect(currentEvent);
    if (!eventBoundingRect?.right) {
      // sentry error: https://vimcal.sentry.io/issues/4291999236/?project=2190664&query=typeerror&referrer=issue-stream&statsPeriod=14d&stream_index=8
      trackError({
        category: "turn_on_color_command_center_error", 
        errorMessage: JSON.stringify(eventBoundingRect, null, 4), 
        userToken: this.props.currentUser.token
      });
      return;
    }
    const xRenderLocation = Math.ceil(eventBoundingRect.right / 10) * 10;
    const yRenderLocation = Math.ceil(eventBoundingRect.top / 10) * 10;

    this.props.setPopupView({
      location: eventBoundingRect,
      event: currentEvent,
      isColor: true,
      clickLocation: { X: xRenderLocation + 4, Y: yRenderLocation - 5 }, // adjust for padding
      shouldExpandTag: true,
    });

    const location = isActionModeUpsertEvent(this.props.actionMode) ? "Event_form" : "home";
    trackEvent({
      category: location,
      action: "turn_on_color_command_center",
      label: "command_center",
      userToken: getUserToken(this.props.currentUser)
    });
  }

  closeColorCommandCenter() {
    this.setState({ shouldShowColorCommandCenter: false });
  }

  closeInstantOpenCommandCenter() {
    this.setState({ shouldShowInstantOpenCommandCenter: false });
  }

  setLastSetMouseTrap() {
    this._lastSetMouseTrap = new Date();
  }

  closeTimeZoneCommandCenter() {
    let updatedState = { shouldShowTimeZoneCommandCenter: false };

    this.setState(updatedState);
  }

  closeRescheduleCommandCenter() {
    this.setState({ shouldShowRescheduleCommandCenter: false });
  }

  closeContactCommandCenter() {
    if (isEventFormShowing()) {
      Broadcast.publish("FOCUS_FIELD");
    }

    if (isTemplateViewShowing()) {
      Broadcast.publish("FOCUS_TEMPLATE_FIELD");
    }

    this.setState({ shouldShowContactCommandCenter: false });
  }

  disableHotKeys() {
    if (!this._hotKeysEnabled) {
      return;
    }

    this.unbindMouseTrap();
    this._hotKeysEnabled = false;

    this.setLastSetMouseTrap();
  }

  enableHotKeys(isForceBind = false) {
    if (this._hotKeysEnabled && !isForceBind) {
      return;
    }

    this.bindMouseTrap();
    this._hotKeysEnabled = true;

    this.setLastSetMouseTrap();
  }

  commandC() {
    if (isActionModeCreateAvailability(this.props.actionMode)) {
      Broadcast.publish("COPY_AVAILABILITY_CONTENT");
    }
  }

  copyAvailabilityLink(e) {
    hasEventPreventDefault(e);

    if (isActionModeCreateAvailability(this.props.actionMode)) {
      AvailabilityBroadcast.publish("COPY_AVAILABILITY_LINK_ONLY");
    }
  }

  setPreviewEvent(event) {
    if (
      !isInActionMode(this.props.actionMode) &&
      this.props.shouldShowGlobalKeyMap
    ) {
      this.toggleGlobalKeyMap();

      if (shouldTruncateRightHandPanel(this.props.hideRightHandSidebar)) {
        const eventClientRect = getEventClientRect(event);
        if (eventClientRect) {
          this.props.setPopupView({
            location: eventClientRect,
            event: event,
          });
        }
      } else {
        if (!isInExpandedViewState()) {
          this.props.history.push("/home/expanded");
        }

        this.props.setPreviewedEvent(event);
      }
    }
  }

  toggleGlobalKeyMap() {
    this.props.toggleGlobalKeyMap();

    Broadcast.publish("HIDE_TOOLTIP_BOX");
    /* Check if the tooltip has been completed */
    const { masterAccount } = this.props.masterAccount;
    if (!isTooltipCompleted(masterAccount, tooltipKeys.FLASH_HOTKEYS)) {
      Broadcast.publish("MARK_TOOLTIP_COMPLETED", tooltipKeys.FLASH_HOTKEYS);
    }
  }

  removeExpandedEvents() {
    mainCalendarBroadcast.publish("REMOVE_PREVIEW_EVENT");
  }

  rerouteToHome() {
    this.props.history.push("/home");
  }

  removeExpandedEventsAndPushToHome() {
    this.props.history.push("/home");
    mainCalendarBroadcast.publish("REMOVE_PREVIEW_EVENT");
  }

  createEventHandler(e = null) {
    if (this.props.temporaryStateStore.reverseSlotsText) {
      return;
    }

    hasEventPreventDefault(e);

    const { allCalendars } = this.props.allCalendars;

    if (
      !isActionModeCreateAvailability(this.props.actionMode) &&
      !isEmptyObjectOrFalsey(allCalendars)
    ) {
      this.removeExpandedEventsAndPushToHome();
      this.props.setActionMode(ACTION_MODE.UPSERT_EVENT);
    }
  }

  async copyPersonalLink(personalLinkIndex, e, copyOnlyLink = false) {
    hasEventPreventDefault(e);

    const {
      personalLinks,
      currentUser
    } = this.props;
    const shouldPrint = isInternalTeamUser(currentUser); // TODO: delete after investigating

    shouldPrint && console.log("copyPersonalLink_0");

    if (isEmptyArrayOrFalsey(personalLinks)) {
      shouldPrint && console.log("copyPersonalLink_1");
      const newlyFetchedLinks = await fetchPersonalLinks(currentUser);
      if (!this._isMounted || isEmptyArrayOrFalsey(newlyFetchedLinks)) {
        shouldPrint && console.log("copyPersonalLink_early_return_0");
        return;
      }
    }
    shouldPrint && console.log("copyPersonalLink_2");

    if (
      isActionModeCreateAvailability(this.props.actionMode) &&
      isPersonalLinkPanelOpen()
    ) {
      const matchingPersonalLink = personalLinks[getPersonalLinkHotKeyIndex(personalLinkIndex)];
      if (!matchingPersonalLink) {
        shouldPrint && console.log("copyPersonalLink_3");
        return;
      }

      const {
        token
      } = matchingPersonalLink;

      if (!token) {
        shouldPrint && console.log("copyPersonalLink_early_return_4");
        return;
      }

      if (copyOnlyLink) {
        shouldPrint && console.log("copyPersonalLink_5");
        AvailabilityBroadcast.publish(
          `COPY_ONLY_PERSONAL_LINK_${token}`
        );
      } else {
        shouldPrint && console.log("copyPersonalLink_6");
        AvailabilityBroadcast.publish(
          `COPY_PERSONAL_LINK_SLOTS_${token}`
        );
      }
    } else {
      shouldPrint && console.log("copyPersonalLink_7");
      if (copyOnlyLink) {
        shouldPrint && console.log("copyPersonalLink_8");
        AvailabilityBroadcast.publish(
          "COPY_GLOBAL_PERSONAL_LINK_ONLY",
          personalLinkIndex
        );
      } else {
        shouldPrint && console.log("copyPersonalLink_9");
        AvailabilityBroadcast.publish(
          "COPY_GLOBAL_PERSONAL_LINK_SLOTS",
          personalLinkIndex
        );
      }
    }
  }

  goToEventForm(e = null, section) {
    hasEventPreventDefault(e);

    if (this.props.shouldShowGlobalKeyMap) {
      this.props.hideGlobalKeyMap();
    }

    if (isEventFormShowing()) {
      Broadcast.publish("GO_TO_EVENT_FORM_SECTION", section);
    }

    if (this.isAppInTaskMode()) {
      return;
    }

    Broadcast.publish("GO_TO_EVENT_FORM_SECTION_FROM_EXPANDED", section);
  }

  isAppInTaskMode() {
    const {
      reverseSlotsText
    } = this.props.temporaryStateStore;
    const {
      actionMode
    } = this.props;
    return isAppInTaskMode({
      actionMode,
      reverseSlotsText
    });
  }

  openEmail(e) {
    hasEventPreventDefault(e);
    if (this.isAppInTaskMode()) {
      return;
    }

    const previewEvent = this.getPreviewEvent();
    if (previewEvent) {
      Broadcast.publish("SHOW_EMAIL_ATTENDEES_MODAL", previewEvent);
    } else {
      Broadcast.publish("EMAIL_UPCOMING_EVENT");
    }

    if (this.props.shouldShowGlobalKeyMap) {
      this.props.hideGlobalKeyMap();
    }
  }

  goToEventEditView(e = null) {
    hasEventPreventDefault(e);

    if (this.isAppInTaskMode()) {
      return;
    }

    const previewEvent = this.getPreviewEvent();

    if (previewEvent) {
      expandedEventViewBroadcast.publish(EXPANDED_VIEW_BROADCAST_VALUES.EDIT_EXPANDED_EVENT);
    } else {
      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        "You have not selected an event to edit."
      );
    }
  }

  isCommandCenterOn() {
    return (
      this.state.shouldShowCommandCenter ||
      this.state.shouldShowTimeZoneCommandCenter ||
      this.state.shouldShowTemplateCommandCenter ||
      this.state.shouldShowContactCommandCenter ||
      this.state.shouldShowRescheduleCommandCenter ||
      this.state.shouldShowColorCommandCenter
    );
  }

  globalEscape() {
    const shouldPrint = false && isLocal();

    shouldPrint && console.log("in_outer_global_escape", window.location.href);

    const {
      reverseSlotsText,
    } = this.props.temporaryStateStore;
    if (this.props.shouldShowShortcutsLegend) {
      shouldPrint && console.log("esc2");

      this.props.hideShortcutsLegend();
    } else if (this.props.shouldShowGlobalKeyMap) {
      shouldPrint && console.log("esc3");

      this.props.hideGlobalKeyMap();
    } else if (!isEmptyObjectOrFalsey(this.props.popupEvent)) {
      shouldPrint && console.log("esc4");

      this.props.removePopupEvent();
    } else if (!isEmptyObjectOrFalsey(this.props.hoverPopupEvent)) {
      shouldPrint && console.log("esc14");

      this.props.removeHoverPopupEvent();
    } else if (this.props.shouldDisplayMenu) {
      shouldPrint && console.log("esc5");

      this.props.setShouldDisplayMenu(false);
    } else if (this.props.isCreateFocusModeBlocks) {
      shouldPrint && console.log("esc12");

      mainCalendarBroadcast.publish("CANCEL_CREATE_FOCUS_BLOCK");
    } else if (isActionModeUpsertEvent(this.props.actionMode)) {
      shouldPrint && console.log("esc13");
      if (isSchedulingAssistantEventPreviewShowing()) {
        // we need time out here otherwise EventModalPopup will close the entire preview since it picks up on the escape key
        // this is hacky and not great but I don't think there's another way to get this to work
        setTimeout(() => {
          if (!this._isMounted) {
            return;
          }
          schedulingAssistantBroadcast.publish(SCHEDULING_ASSISTANT_BROADCAST_VALUES.SET_PREVIEW_EVENT, null);
        }, 0.1 * SECOND_IN_MS);
        return;
      }
      Broadcast.publish("EVENT_FORM_ESCAPE");
    } else if (
      this._lastSetMouseTrap &&
      Math.abs(differenceInMilliseconds(this._lastSetMouseTrap, new Date())) < 100
    ) {
      shouldPrint && console.log("this.state.lastSet", this._lastSetMouseTrap);
      shouldPrint &&
        console.log(
          "esc6",
          Math.abs(differenceInMilliseconds(this._lastSetMouseTrap, new Date()))
        );
    } else if (!isEmptyObjectOrFalsey(this.props.currentPreviewedEvent)) {
      shouldPrint && console.log("esc7");

      batch(() => {
        if (window.location.pathname.includes("expanded")) {
          this.props.history.push("/home");
        }

        this.props.removeCurrentHoverEvent();

        this.props.removePreviewedEvent();
      });
    } else if (!isEmptyObjectOrFalsey(this.props.currentHoverEvent)) {
      shouldPrint && console.log("esc8");

      this.props.removeCurrentHoverEvent();
    } else if (
      this.props.eventFormEmails?.length > 0
    ) {
      shouldPrint && console.log("esc10");
      Broadcast.publish("REMOVE_ALL_SELECTED_TEMPORARY_CALENDARS");
    } else if (
      isActionModeCreateAvailability(this.props.actionMode) &&
      !this.isCommandCenterOn()
    ) {
      shouldPrint && console.log("isAvailabilityPanelModalOpen()_", isAvailabilityPanelModalOpen());
      if (isAvailabilityPanelModalOpen()) {
        AvailabilityBroadcast.publish("CLOSE_AVAILABILITY_PANEL_MODAL");
        return;
      }
      shouldPrint && console.log("esc9");
      if (document.getElementById(PERSONAL_LINK_DETAIL_CONTAINER)) {
        Broadcast.publish("ON_BACK_PERSONAL_LINK_DETAIL");
        shouldPrint && console.log("esc9_personal_link");
        return;
      }

      if (isGroupVoteDetailPageOpen()) {
        shouldPrint && console.log("esc9_group_vote");
        availabilityBroadcast.publish(AVAILABILITY_BROADCAST_VALUES.ESCAPE_GROUP_VOTE_DETAIL);
        return;
      }

      // if searched calendar is on -> remove those first
      shouldPrint && console.log("esc9_cancel_availability");
      Broadcast.publish("CANCEL_SELECT_AVAILABILITY");
    } else if (!window.location.href.toLowerCase().includes("home")) {
      shouldPrint && console.log("esc12");
    } else if (!isEmptyArray(this.props.temporaryEvents)) {
      shouldPrint && console.log("esc15");
      broadcast.publish("REMOVE_TEMPORARY_EVENTS");
    } else if (this.props.currentTimeZoneLabel) {
      shouldPrint && console.log("esc11");

      if (!doTemporaryTimeZonesExist(this.props.temporaryTimeZones)) {
        shouldPrint && console.log("esc11_1");
        return;
      }
      shouldPrint && console.log("esc11_2");
      Broadcast.publish("RETURN_TO_DEFAULT_TIME_ZONE");
    } else if (reverseSlotsText) {
      this.resetReverseSlotsData(true);
      Broadcast.publish("REMOVE_TEMPORARY_EVENTS");
    } else if (isTutorialWizardOpen) {
      shouldPrint && console.log("esc16");
      const {
        minimizeTutorialWizard
      } = this.props.tutorialWizard;
      minimizeTutorialWizard();
    } else {
      shouldPrint && console.log("reached final else");
    }
  }

  getTimesOverLastFewDays(e) {
    if (!isActionModeCreateAvailability(this.props.actionMode)) {
      return;
    }

    if (!isGetUpcomingWeekAvailabiityMessageShowing()) {
      return;
    }

    hasEventPreventDefault(e);

    AvailabilityBroadcast.publish("GET_UPCOMING_WEEK_AVAILABILITY");
  }

  useLastSelectedSlots(e) {
    if (!isActionModeCreateAvailability(this.props.actionMode)) {
      return;
    }

    if (!isUsePreviouslySelectedSlotsMessageShowing()) {
      return;
    }

    if (
      !getPreviouslySelectedSlots(
        this.props.currentTimeZone,
        this.props.currentUser
      )
    ) {
      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        "There are no existing previously selected Slots"
      );
      return;
    }

    hasEventPreventDefault(e);
    AvailabilityBroadcast.publish(AVAILABILITY_BROADCAST_VALUES.USE_PREVIOUSLY_SELECTED_SLOTS_AND_TIME_ZONE);
  }

  onChangeUpload(event) {
    let files = event.target.files;
    if (!files || !files[0]) {
      return;
    }

    this.parseICSFile(files[0]);
  }

  duplicatePreviewEvent(e) {
    if (this.isAppInTaskMode()) {
      return;
    }
    const previewEvent = this.getPreviewEvent();

    if (!previewEvent ||
      (this._lastPressedPTime &&
        Date.now() - this._lastPressedPTime < SECOND_IN_MS)
    ) {
      return;
    }

    hasStopEventPropagation(e);
    hasEventPreventDefault(e);
    expandedEventViewBroadcast.publish(EXPANDED_VIEW_BROADCAST_VALUES.DUPLICATE_FROM_EVENT);
  }

  yankLocation(e) {
    if (
      this._lastPressedLTime &&
      Date.now() - this._lastPressedLTime < SECOND_IN_MS
    ) {
      return;
    }
    hasStopEventPropagation(e);
    hasEventPreventDefault(e);

    const previewEvent = this.getPreviewEvent();
    if (previewEvent) {
      Broadcast.publish("COPY_EVENT_EXPANDED_LOCATION");
    } else {
      Broadcast.publish("COPY_NEXT_EVENT_LOCATION");
    }
  }

  yankVideo(e) {
    if (
      this._lastPressedVTime &&
      Date.now() - this._lastPressedVTime < SECOND_IN_MS
    ) {
      return;
    }
    hasStopEventPropagation(e);
    hasEventPreventDefault(e);

    const previewEvent = this.getPreviewEvent();

    if (previewEvent) {
      ConferencingBroadcasts.publish("COPY_EXPANDED_CONFERENCING_LINK");
    } else {
      ConferencingBroadcasts.publish("COPY_NEXT_EVENT_CONFERENCING");
    }
  }

  parseICSFile(file) {
    if (!file) {
      return;
    }
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const { masterAccount } = this.props.masterAccount;

    if (isActionModeUpsertEvent(this.props.actionMode)) {
      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        "Please finish editing this event before uploading a new .ics file"
      );
      return;
    } else if (isActionModeCreateAvailability(this.props.actionMode)) {
      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        "Please exit availability mode before uploading an .ics file"
      );
      return;
    }

    if (file.type !== "text/calendar") {
      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        "Please only upload .ics files"
      );
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      let result = parseICSString(reader.result);
      if (!result) {
        Broadcast.publish(
          SET_DISAPPEARING_NOTIFICATION_MESSAGE,
          "Please only upload single-event .ics files"
        );
        return;
      }

      batch(() => {
        // to copy event to a different calendar
        if (result.eventStart) {
          this.props.selectDay(result.eventStart);
        }

        const { allCalendars } = this.props.allCalendars;

        result = addEventUserCalendarID(result, getUserCalendarIDFromEmail({
          email: getUserEmail(this.props.currentUser),
          allCalendars,
          allLoggedInUsers,
          masterAccount,
        }));
        this.props.setPreviewedEvent(result);
        this.props.history.push("/home");
        this.props.setActionMode(ACTION_MODE.UPSERT_EVENT);
        this.props.setDuplicateEvent(true);
      });
    };
    reader.readAsText(file);
  }

  openAffiliateModal() {
    this.setState({
      shouldDisplayModal: true,
      modalWidth: 344,
      modalTitle: "Join our affiliate program 🤝",
      modalContent: AFFILIATE_MODAL,
    });
  }

  openMainCalendarToolbarSelectModal() {
    this.setState({
      shouldDisplayModal: true,
      modalWidth: 208,
      modalContent: MAIN_CALENDAR_SELECT_MODAL,
    });
  }

  openAccountsModal() {
    this.setState({
      shouldDisplayModal: true,
      modalWidth: 320,
      modalContent: VIEW_ACCOUNTS_MODAL,
    });
    Broadcast.publish(BROADCAST_VALUES.OPEN_ACCOUNTS_DROPDOWN);
  }

  openOnboardingModal() {
    this.setState({
      shouldDisplayModal: true,
      modalWidth: 900,
      modalContent: ONBOARDING_MODAL,
      modalTitle: "Book personal onboarding",
    });
  }

  displayPromotionModal(modals) {
    if (isEmptyArray(modals)) {
      return;
    }

    const determineTitle = () => {
      const promotionCode = getPromotionType(modals);
      switch (promotionCode) {
        case PRODUCT_HUNT_PROMOTION:
          return "Thank you for your support on Product Hunt";
        case STANDARD_YC_PROMO:
          return "Thank you for your support YC";
        case LEGACY_YC_PROMOTION:
          return "Thank you for your support YC";
        case HUSTLE_FUND_PROMOTION:
          return "Thank you for being a part of the Hustle Fund community";
        default:
          return "Thank you for your interest in Vimcal";
      }
    };

    this.setState({
      shouldDisplayModal: true,
      modalWidth: 500,
      modalTitle: determineTitle(),
      modalContent: CLAIM_PROMOTION,
      promotionModal: modals,
    });
  }

  displayJoinedTeamModal(teamPlan) {
    if (!teamPlan) {
      return;
    }

    this.setState({
      shouldDisplayModal: true,
      modalWidth: 500,
      modalTitle: "Joined Team Plan",
      modalContent: JOINED_TEAM_PLAN,
      teamPlanJoined: teamPlan,
    });
  }

  createGroupVoteEvent(groupVoteLink, slot) {
    if (isEmptyObjectOrFalsey(groupVoteLink)) {
      return;
    }
    const {
      masterAccount,
    } = this.props.masterAccount;
    const {
      allCalendars,
    } = this.props.allCalendars;
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    const matchingUser = getMatchingUserFromAllUsers({
      allUsers: allLoggedInUsers, 
      userEmail: groupVoteLink?.calendar_provider_id
    }) ?? this.props.currentUser;

    const event = createEventFromGroupVoteLink({
      groupVoteLink,
      slot,
      currentUser: matchingUser,
      allCalendars,
      masterAccount,
      allLoggedInUsers,
    });

    batch(() => {
      // to copy event to a different calendar
      this.props.setPreviewedEvent(event);

      this.props.history.push("/home");
      this.props.setActionMode(ACTION_MODE.UPSERT_EVENT);
      this.props.setDuplicateEvent(true);
      this.props.selectDay(event.eventStart);
    });
  }

  createSmartHoldsEvent({ holdDetails, slot, userCalendarID }) {
    if (isEmptyObjectOrFalsey(holdDetails)) {
      return;
    }
    const {
      masterAccount,
    } = this.props.masterAccount;
    const {
      allCalendars
    } = this.props.allCalendars;
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    const matchingUser = getMatchingUserFromAllUsers({
      allUsers: allLoggedInUsers,
      userEmail: holdDetails?.calendar_provider_id,
    }) ?? this.props.currentUser;

    const event = createEventFromSlotsHold({
      holdDetails,
      slot,
      currentUser: matchingUser,
      allCalendars,
      masterAccount,
      userCalendarID,
      allLoggedInUsers,
    });

    // this is so event form doesn't have to convert the time again using iso string which creates a lot of weird edge cases.
    event.setEventTimeDirectlyFromEventStartAndEventEnd = true;

    batch(() => {
      // to copy event to a different calendar
      this.props.setPreviewedEvent(event);

      this.props.history.push("/home");
      this.props.setActionMode(ACTION_MODE.UPSERT_EVENT);
      this.props.setDuplicateEvent(true);
      this.props.selectDay(event.eventStart);
    });
  }

  toggleOnSlotsAnimation() {
    this.disableHotKeys();
    this.setState({ displaySlotsAnimation: true });
  }

  toggleOffSlotsAnimation() {
    Broadcast.publish("MARK_TOOLTIP_COMPLETED", tooltipKeys.SLOTS_TUTORIAL);

    this.enableHotKeys();
    if (this.state.displaySlotsAnimation) {
      this.setState({ displaySlotsAnimation: false });
    }
  }

  openTrialIsOverModal() {
    this.setState({
      shouldDisplayModal: true,
      modalContent: TRIAL_IS_OVER_MODAL
    }, () => {
      this.disableHotKeys(); // so no other modal shows up
    });
  }

  toggleRightPanel() {
    if (this.isAppInTaskMode()) {
      return;
    }

    const updatedShouldHide = !shouldTruncateRightHandPanel(this.props.hideRightHandSidebar);
    const {
      setHideRightHandSide
    } = this.props.hideRightHandSidebar;

    setHideRightHandSide(updatedShouldHide);
  }

  toggleFocusMode(showFocus = false) {
    if (showFocus) {
      this.props.history.push("/focus");
      this.disableHotKeys();

      const {
        masterAccount
      } = this.props.masterAccount;
      if (!isTooltipCompleted(masterAccount, tooltipKeys.FOCUS_MODE)) {
        Broadcast.publish("MARK_TOOLTIP_COMPLETED", tooltipKeys.FOCUS_MODE);
      }

      // active hot keys for pomadora mode
      focusModeBroadcast.publish("BIND_ESCAPE_HOTKEY");
      focusModeBroadcast.publish("BIND_HOTKEY_TO_PLAY_SOUND");
      focusModeBroadcast.publish("BIND_SPACE_TO_START");
      focusModeBroadcast.publish("UPDATE_UPCOMING_EVENT");
    } else {
      this.props.history.push("/home");
      focusModeBroadcast.publish("PAUSE_FOCUS_MODE_MUSIC");

      this.unbindMouseTrap();
      this._hotKeysEnabled = false;

      this.enableHotKeys();
    }

    this.setState({
      shouldDisplayFocusMode: showFocus
    });
  }

  showEmailModal(event, isRunningLate = false) {
    if (isEmptyObjectOrFalsey(event)) {
      return;
    }

    if (this.isAppInTaskMode()) {
      return;
    }

    if (!isEmptyObjectOrFalsey(this.props.currentHoverEvent)
      && isEmptyObjectOrFalsey(this.props.currentPreviewedEvent)
      && !shouldTruncateRightHandPanel(this.props.hideRightHandSidebar)
    ) {
      this.props.setPreviewedEvent(event);
    }

    this.setState({
      emailAttendeesEvent: event,
      shouldDisplayModal: true,
      modalWidth: "728px",
      modalTitle: "Email guests",
      modalContent: isRunningLate ? EMAIL_ATTENDEES_RUNNING_LATE : EMAIL_ATTENDEES
    });
  }

  openEventFormFindTime() {
    if (isActionModeUpsertEvent(this.props.actionMode)) {
      if (isEventFormFindTimeButtonShowing()) {
        eventFormBroadcast.publish("FIND_TIME_INSIDE_EVENT_FORM");
      }
      return; 
    }
    
    if (!isCalendarListEventFormFindTimeButtonShowing() 
      || this.isAppInTaskMode()
    ) {
      return;
    }
    eventFormBroadcast.publish("FIND_TIME_CREATE_EVENT");
  }

  openForwardOutlookModal({event, originalRecurringEvent}) {
    if (isEmptyObjectOrFalsey(event)) {
      return;
    }

    if (this.isAppInTaskMode()) {
      return;
    }

    if (!isEmptyObjectOrFalsey(this.props.currentHoverEvent)
      && isEmptyObjectOrFalsey(this.props.currentPreviewedEvent)
      && !shouldTruncateRightHandPanel(this.props.hideRightHandSidebar)
    ) {
      this.props.setPreviewedEvent(event);
    }

    this.setState({
      emailAttendeesEvent: event,
      outlookForwardOriginalEvent: originalRecurringEvent,
      shouldDisplayModal: true,
      modalWidth: "728px",
      modalTitle: "Forward event",
      modalContent: FORWARD_OUTLOOK_EVENT
    });
  }

  getPreviewEvent() {
    return getPreviewEvent({
      popupEvent: this.props.popupEvent,
      hoverPopupEvent: this.props.hoverPopupEvent,
      currentPreviewedEvent: this.props.currentPreviewedEvent,
      currentHoverEvent: this.props.currentHoverEvent,
    });
  }

  getContainerClassName() {
    if (this.props.isMobileView) {
      return "app-wrapper-mobile";
    }

    const DESKTOP_CONTAINER = "app-wrapper-desktop";
    if (this.shouldHideRightHandSide()) {
      return classNames("min-app-container-width-without-panel", DESKTOP_CONTAINER);
    } else {
      return classNames("min-app-container-min-width-with-panel", DESKTOP_CONTAINER);
    }
  }

  shouldHideRightHandSide() {
    const {
      reverseSlotsText
    } = this.props.temporaryStateStore;
    const {
      actionMode
    } = this.props;
    return shouldHideRightHandSide({
      hideRightHandSideBar: this.props.hideRightHandSidebar,
      actionMode,
      reverseSlotsText
    });
  }

  getGroupVoteRerouteToken() {
    // can't use react-route because on route home, it causes the layout to change to different layout
    const url = window.location.href;
    const match = url.match(/\/g\/(.+)/);
    const token = match ? match[1] : null;
    return token;
  }

  // TODO: Add support for group spreadsheets.
  checkForRerouteToGroupVote() {
    if (!this.getGroupVoteRerouteToken()) {
      return;
    }

    // open availabiility panel and route to group vote
    this.props.setActionMode(ACTION_MODE.CREATE_AVAILABILITY);
    // fetch group vote links
    const url = constructRequestURL(LATEST_GROUP_VOTE_LINKS, isVersionV2());

    // get the latest version fo group vote
    Fetcher.get(url, {}, true, getUserEmail(this.props.currentUser))
      .then((response) => {
        if (!response?.group_vote_links) {
          return;
        }

        const {
          group_vote_links: groupVoteLinks
        } = response;

        const { setGroupVoteLinks } = this.props.groupVoteStore;
        setGroupVoteLinks(groupVoteLinks);
        Broadcast.publish("SET_AVAILABILITY_TYPE", GROUP_VOTE_SELECT_OPTION);
        const matchingGroupVote = groupVoteLinks.find(g => g.token === this.getGroupVoteRerouteToken());
        this.openGroupVoteEventPicker(matchingGroupVote);
        this.rerouteToHome();
      })
      .catch(handleError);
  }

  shouldHideModalOverflow() {
    return [GROUP_VOTE_PREVIEW, CREATE_EVENT_FROM_BOOKING_LINK].includes(this.state.modalContent);
  }

  openFeedbackModal(feedbackType) {
    this.setState({
      shouldDisplayModal: true,
      modalContent: FEEDBACK_MODAL,
      modalWidth: "476px",
      feedbackType,
    });
  }

  isAvailabilityPreviewModal() {
    return [
      CREATE_EVENT_FROM_BOOKING_LINK, 
      GROUP_VOTE_PREVIEW,
      AVAILABILITY_GROUP_VOTE_LINK_PREVIEW_MODAL,
      AVAILABILITY_PREVIEW_MODAL,
    ].includes(this.state.modalContent);
  }

  getModalClassName() {
    if (this.isAvailabilityPreviewModal() && this.props.isDarkMode) {
      return "light-mode-scroll-bar";
    }

    const isModalWithoutDarkenedBackground = () => {
      return this.isViewAccountsModal() || this.isMainCalendarDropdownModal();
    };

    if (isModalWithoutDarkenedBackground()) {
      return "default-bottom-left-modal-border large-blur";
    }
    return "";
  }

  // needs to be async incase someone internally changes the user's masterAccount state
  async checkforAccountStateUpdate() {
    try {
      const {
        currentUser,
      } = this.props;
      const response = await fetchLatestMasterAccount({user: currentUser});
      if (!this._isMounted) {
        return;
      }
      // If a user skips the onboarding flow, we need to start their subscription and update
      // their account status. Otherwise they will be able to use the app for free.
      const { masterAccount } = this.props.masterAccount;
      const accountState = getAccountState( response?.master_account ?? masterAccount);
      if (accountState === ACCOUNT_STATE_PREPAID) {
        appBroadcast.publish(APP_BROADCAST_VALUES.UPDATE_ACCOUNT_STATE, ACCOUNT_STATE_PAYING);
      } else if (accountState === ACCOUNT_STATE_NEW_USER) {
        // The backend does not create a new subscription if user already has one.
        appBroadcast.publish(APP_BROADCAST_VALUES.INIT_TRIALED_SUBSCRIPTION);
      }
    } catch (error) {
      handleError(error);
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    hideGlobalKeyMap: () => dispatch({ type: "HIDE_GLOBAL_KEY_MAP" }),
    setPreviewedEvent: (event) =>
      dispatch({ data: event, type: "SET_PREVIEWED_EVENT" }),
    toggleGlobalKeyMap: () => dispatch({ type: "TOGGLE_SHOW_GLOBAL_KEY_MAP" }),
    setShouldDisplayMenu: (data) =>
      dispatch({ data: data, type: "SET_SHOULD_DISPLAY_MENU" }),
    removePopupEvent: (event) =>
      dispatch({ data: event, type: "REMOVE_POPUP_EVENT" }),
    removeCurrentHoverEvent: (data) =>
      dispatch({ data: data, type: "REMOVE_CURRENT_HOVER_EVENT" }),
    removePreviewedEvent: (event) =>
      dispatch({ data: event, type: "REMOVE_CURRENT_PREVIEW_EVENT" }),
    setWeeklyCalendarToZoom: (day) =>
      dispatch({ data: day, type: "SET_WEEKLY_CALENDAR_TO_ZOOM" }),
    setTimeZone: (timeZone) =>
      dispatch({ data: timeZone, type: "SET_TIME_ZONE" }),
    setActionMode: (data) => dispatch({ data: data, type: "SET_ACTION_MODE" }),
    selectDay: (day) => dispatch({ data: day, type: "SELECT_DAY" }),
    setIsMac: (day) => dispatch({ data: day, type: "SET_IS_MAC" }),
    setAgendaDay: (event) => dispatch({ data: event, type: "SET_AGENDA_DAY" }),
    displayShortcutLegend: () => dispatch({ type: "DISPLAY_SHORTCUTS_LEGEND" }),
    hideShortcutsLegend: () => dispatch({ type: "HIDE_SHORTCUTS_LEGEND" }),
    setShouldShowTopBar: (data) =>
      dispatch({ data: data, type: "SET_SHOULD_SHOW_TOP_BAR" }),
    setPopupEvent: (event) => dispatch({ data: event, type: "SET_POPUP_EVENT" }),
    removeHoverPopupEvent: () =>
      dispatch({ type: "REMOVE_HOVER_POPUP_EVENT" }),
    setDuplicateEvent: (data) => dispatch({ data, type: "DUPLICATE_EVENT" }),
    setCurrentTimeZoneLabel: (day) =>
      dispatch({ data: day, type: "SET_TIME_ZONE_LABEL" }),
    setAnchorTimeZones: (data) =>
      dispatch({ data: data, type: "SET_ANCHOR_TIME_ZONES" }),
    setIsMobileView: (data) =>
      dispatch({ data: data, type: "SET_IS_MOBILE_VIEW" }),
    setPopupView: (event) => dispatch({ data: event, type: "SET_POPUP_EVENT" }),
    setDefaultBrowserTimeZone: (timeZone) =>
      dispatch({ data: timeZone, type: "STORE_DEFAULT_BROWSER_TIME_ZONE" }),
  };
}

function mapStateToProps(state) {
  let {
    currentHoverEvent,
    isMac,
    shouldShowShortcutsLegend,
    currentTimeZone,
    selectedDay,
    shouldShowTopBar,
    shouldDisplayMenu,
    popupEvent,
    currentUser,
    shouldShowGlobalKeyMap,
    currentPreviewedEvent,
    eventFormEmails,
    isDarkMode,
    currentTimeZoneLabel,
    hoverPopupEvent,
    activeCalendars,
    anchorTimeZones,
    defaultBrowserTimeZone,
    temporaryTimeZones,
    isMobileView,
    isCreateFocusModeBlocks,
    personalLinks,
    temporaryEvents,
    actionMode,
  } = state;

  return {
    currentHoverEvent,
    isMac,
    shouldShowShortcutsLegend,
    currentTimeZone,
    selectedDay,
    shouldShowTopBar,
    shouldDisplayMenu,
    popupEvent,
    currentUser,
    shouldShowGlobalKeyMap,
    currentPreviewedEvent,
    eventFormEmails,
    isDarkMode,
    currentTimeZoneLabel,
    hoverPopupEvent,
    activeCalendars,
    anchorTimeZones,
    defaultBrowserTimeZone,
    temporaryTimeZones,
    isMobileView,
    isCreateFocusModeBlocks,
    personalLinks,
    temporaryEvents,
    actionMode,
  };
}

const withStore = (BaseComponent) => (props) => {
  const store = useIsMouseMoving();
  const eventIndexStore = useEventHotKeysIndex();
  const allCalendars = useAllCalendars();
  const shouldStayOpen = useCustomEvent();
  const masterAccount = useMasterAccount();
  const hideRightHandSidebar = useHideRightHandSidebar()
  const groupVoteStore = useGroupVoteStore();
  const temporaryStateStore = useTemporaryStateStore();
  const userCodes = useUserCodes();
  const tutorialWizard = useTutorialWizard();
  const appTimeZone = useAppTimeZones();
  const allLoggedInUsers = useAllLoggedInUsers();

  return (
    <BaseComponent
      {...props}
      useIsMouseMoving={store}
      eventIndexStore={eventIndexStore}
      allCalendars={allCalendars}
      shouldStayOpen={shouldStayOpen}
      masterAccount={masterAccount}
      hideRightHandSidebar={hideRightHandSidebar}
      groupVoteStore={groupVoteStore}
      temporaryStateStore={temporaryStateStore}
      userCodes={userCodes}
      tutorialWizard={tutorialWizard}
      appTimeZone={appTimeZone}
      allLoggedInUsers={allLoggedInUsers}
    />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStore(Layout)));
