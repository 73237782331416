import classNames from "classnames";
import React from "react";
import { BarChart, Clock, Globe, Link2, MessageCircle } from "react-feather";
import layoutBroadcast from "../js/broadcasts/layoutBroadcast";
import { LAYOUT_BROADCAST_VALUES } from "../js/lib/broadcastValues";
import broadcast from "../js/broadcasts/broadcast";
import { useMasterAccount } from "../js/services/stores/SharedAccountData";
import { isUserMaestroUser } from "../js/services/maestroFunctions";
import { isInActivePilot } from "../js/lib/featureFlagFunctions";
import { useSelector } from "react-redux";
import { FEEDBACK_TYPE } from "../js/lib/vimcalVariables";
import { trackEvent } from "../js/components/tracking";
import { getUserToken } from "../js/lib/userFunctions";

const ICON_SIZE = 12;
const TEXT_LABEL_CLASSNAME =
  "ml-2 default-font-size cursor-pointer font-weight-300";
const ROW_CLASSNAME =
  "flex items-center hoverable-secondary-text-color";
export default function ActionListContainer({ containerClassName }) {
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const currentUser = useSelector((state) => state.currentUser);
  return (
    <div
      className={classNames("flex flex-col gap-3", containerClassName || "")}
    >
      <SelectAvailability />
      {isUserMaestroUser(masterAccount)
        ? <GroupPolls />
        : <PersonalLinks />}
      <TimeTravel />
      {isInActivePilot(currentUser) ? <AskAQuestion /> : null}
    </div>
  );
}

function SelectAvailability() {
  return (
    <div
      className={classNames(ROW_CLASSNAME)}
      onClick={() =>
        layoutBroadcast.publish(LAYOUT_BROADCAST_VALUES.TOGGLE_ON_SLOTS)
      }
    >
      <Clock size={ICON_SIZE} />
      <div className={classNames(TEXT_LABEL_CLASSNAME)}>Slots</div>
    </div>
  );
}

function PersonalLinks() {
  return (
    <div
      className={classNames(ROW_CLASSNAME)}
      onClick={() => broadcast.publish("OPEN_PERSONAL_LINKS")}
    >
      <Link2 size={ICON_SIZE} />
      <div className={classNames(TEXT_LABEL_CLASSNAME)}>Personal Links</div>
    </div>
  );
}

function GroupPolls() {
  return (
    <div
      className={classNames(ROW_CLASSNAME)}
      onClick={() =>
        layoutBroadcast.publish(LAYOUT_BROADCAST_VALUES.TOGGLE_ON_GROUP_POLLS)
      }
    >
      <BarChart size={ICON_SIZE} />
      <div className={classNames(TEXT_LABEL_CLASSNAME)}>Group Polls</div>
    </div>
  );
}

function TimeTravel() {
  return (
    <div
      className={classNames(ROW_CLASSNAME)}
      onClick={() => broadcast.publish("TOGGLE_SHOULD_SHOW_SET_TIME_ZONE")}
    >
      <Globe size={ICON_SIZE} />
      <div className={classNames(TEXT_LABEL_CLASSNAME)}>Time Travel</div>
    </div>
  );
}

function AskAQuestion() {
  const currentUser = useSelector((state) => state.currentUser);
  return (
    <div
      className={classNames(ROW_CLASSNAME)}
      onClick={() => {
        trackEvent({
          category: "sendFeedback",
          action: "click ask a question",
          label: "sidePanel",
          userToken: getUserToken(currentUser),
        });
        layoutBroadcast.publish(LAYOUT_BROADCAST_VALUES.TOGGLE_PROVIDE_FEEDBACK, FEEDBACK_TYPE.QUESTION);
      }}
    >
      <MessageCircle size={ICON_SIZE} />
      <div className={classNames(TEXT_LABEL_CLASSNAME)}>Ask a Question</div>
    </div>
  );
}
