import React, { Component } from "react";
import { connect, batch } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  TYPE_CONFERENCING,
  TYPE_LOCATION,
  TYPE_UP_NEXT,
  TYPE_EMAIL,
  AGENDA_FORMAT_JS_DATE,
  SET_DISAPPEARING_NOTIFICATION_MESSAGE,
  TYPE_EMAIL_RUNNING_LATE,
  HOVER_UPCOMING_EVENTS,
  SECOND_IN_MS,
} from "../services/globalVariables";
import { determineConferenceText } from "../lib/conferencing";
import Broadcast from "../broadcasts/broadcast";
import {
  formatTimeJSDate,
  filterForLocation,
  generateConferenceRooms,
  getFirstDayOfWeekJsDate,
  OpenLink,
  OpenGoogleMapsLocation,
  convertDateIntoEpochUnixWeek,
  hasStateOrPropsChanged,
  getFirstDayOfMonthlyCalendarJSDate,
  getLastDayOfMonthlyCalendarJSDate,
  determineMultipleConferenceWarning,
  handleError,
  determineFurthestMinute,
  FormatIntoJSDate,
  isElectron,
  openConferencingURL,
  determineTimeRemaining,
  convertToTimeZone,
  getLastDayOfWeekJsDate,
  isMac,
  getPhoneNumberFromText,
  formatEventForReactBigCalendar,
  isWindowsElectron,
  isTooltipCompleted,
  hasEventPreventDefault,
  hasStopEventPropagation,
  getCurrentTimeInCurrentTimeZone,
} from "../services/commonUsefulFunctions";
import _ from "underscore";
import { constructRequestURL } from "../services/api";
import db from "../services/db";
import EventConferencing from "./eventConferencing";
import EventLocation from "./eventLocation";
import Fetcher from "../services/fetcher";
import {
  ATTENDEE_EVENT_DECLINED,
} from "../services/googleCalendarService";
import GlobalKeyMapTile from "./globalKeyMapTile";
import Push from "push.js";
import {
  createWindow,
  isEventInWindow,
} from "../lib/stateManagementFunctions";
import {
  findUpcomingEvent,
  isNextEventNow,
  getEventButtonStyle,
  renderMergedEventIndicator,
} from "../services/sharedAgendaFunctions";
import {
  differenceInDays,
  parseISO,
  format,
  isSameYear,
  isSameDay,
  addDays,
  subDays,
  startOfDay,
  subMinutes,
  isSameMinute,
  addWeeks,
} from "date-fns";
import ClassNames from "classnames";
import { timeRemainingBreakdown } from "../lib/timeFunctions";
import {
  createStaticInformation,
  isMergedEvent,
  getEventReminderOverrides,
  isCancelledEvent,
  getEventStartAllDayDate,
  eventHasAttendees,
  isValidEvent,
  eventOnlyHasTime,
  isEventTooLong,
} from "../lib/eventFunctions";
import CalendarList from "../components/calendarList";
import { mergeSameEvents } from "../services/mergeEventFunctions";
import {
  createAgendaPanelIndex,
  flattenEventsToUserCalendar,
  formatEventsList,
  formatFlattendCalendarAndEvents,
} from "../lib/webWorkerFunctions";
import conferencingBroadcasts from "../broadcasts/conferencingBroadcasts";
import {
  renderConferencingActionText,
  isPhoneConferencingWhatsApp,
} from "../lib/conferencing";
import { useHideRightHandSidebar, useTabID } from "../services/stores/appFunctionality";
import {
  useAllCalendars,
  useAllLoggedInUsers,
  useMasterAccount,
} from "../services/stores/SharedAccountData";
import {
  getUserCalendarIDFromEmail,
  isValidCalendar,
  getEventAttendeeStatus,
  getAllUserCalendarIDWithDefaultReminders,
  isCalendarSelected,
  getMatchingCalendarsForUser,
  getCalendarUserEmail,
  getMatchingUserProviderIDsFromUserCalendarIDs,
  getActiveSelectedCalendars,
  doesAllCalendarsContainOutlookCalendars,
} from "../lib/calendarFunctions";
import TooltipBox from "./tooltips/tooltipBox";
import { tooltipKeys } from "../services/tooltipVariables";
import {
  getEventConferenceData,
  getEventLocation,
  getEventReminders,
  getEventUserCalendarID,
  getEventUserEventID,
} from "../services/eventResourceAccessors";
import {
  getCalendarDefaultReminders,
  getCalendarUserCalendarID,
} from "../services/calendarAccessors";
import Email from "./icons/email";
import { getPreviewEvent, isHoverUpcomingEventShowing, isInActionMode, isInEditTemplatesState, isInSearchState, shouldTruncateRightHandPanel } from "../services/appFunctions";
import mainCalendarBroadcast from "../broadcasts/mainCalendarBroadcast";
import agendaBroadcast from "../broadcasts/agendaBroadcast";
import focusModeBroadcast from "../broadcasts/focusModeBroadcast";
import classNames from "classnames";
import ReferralPopup from "./referralPopup";
import { getTimeZoneForMenuBar, getUpcomingCalendarUserCalendarIDs, getUserEmail } from "../lib/userFunctions";
import HoverableScrollContainer from "./hoverableScrollContainer";
import { DEXIE_EVENT_COLUMNS, addEventsIntoIndexDB, isDBEventItemWithinWindow } from "../lib/dbFunctions";
import { getTabIDFromLocalData } from "../lib/localData";
import { safeJSONParse } from "../lib/jsonFunctions";
import { getSelectedDayWithBackup, shouldSkipFetchEventsResponse } from "../lib/syncFunctions";
import { isEmptyArray } from "../lib/arrayFunctions";
import { FETCH_CALENDAR_EVENTS_ENDPOINT } from "../lib/endpoints";
import { useOutlookCategoriesStore } from "../services/stores/outlookCategoriesStore";
import { getDefaultHeaders } from "../lib/fetchFunctions";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey } from "../services/typeGuards";
import { useAppSettings } from "../services/stores/settings";
import { capitalizeFirstLetter, pluralize } from "../lib/stringFunctions";
import { createUUID } from "../services/randomFunctions";
import { getDateTimeFormat } from "../lib/dateFunctions";
import { shouldShowListOfActions } from "../lib/featureFlagFunctions";
import ActionListContainer from "../../components/actionListContainer";
import CommandCenterSearchInput from "./commandCenterSearchInput";
import { OUTLOOK_ACTIVE_CALENDARS_THROTTLE_AMOUNT } from "../lib/outlookFunctions";
import { isUserMaestroUser, shouldHideDelegatedUser } from "../services/maestroFunctions";
import { getRetryAfterMS, shouldRetryBasedOnResponse } from "../lib/backendFunctions";
import { delayByMs } from "../lib/asyncFunctions";
import { getMatchingUIUserForEvent } from "../lib/tagsFunctions";

class AgendaPanel extends Component {
  constructor(props) {
    super(props);

    let start = getFirstDayOfMonthlyCalendarJSDate(
      props.selectedDay,
      props.weekStart
    );
    let end = getLastDayOfMonthlyCalendarJSDate(
      props.selectedDay,
      props.weekStart
    );

    this._backendFetchTimeout = null;
    this._setDesktopBridgeTimeout = null;
    this._desktopBridgeAttempt = 0;

    this.state = {
      events: [],
      upcomingEvents: [],
      nextEvent: null,
      isElectron: isElectron(),
      indexByDay: {},
      monthStartDateJSDate: start,
      monthEndDateJSDate: end,
      unixMonthStartDate: convertDateIntoEpochUnixWeek(start),
      unixMonthEndDate: convertDateIntoEpochUnixWeek(end),
      isMac: isMac(),
      shouldHideJoinMeetingTooltip: true,
    };

    this.onClickAgendaEvent = this.onClickAgendaEvent.bind(this);
    this.reformatAgendaIndexWithNextEvent =
      this.reformatAgendaIndexWithNextEvent.bind(this);
    this.openNextEventConferencing = this.openNextEventConferencing.bind(this);
    this.openNextEventLocation = this.openNextEventLocation.bind(this);
    this.showNextEvent = this.showNextEvent.bind(this);
    this.initialFetchAndPullDataForAgenda =
      this.initialFetchAndPullDataForAgenda.bind(this);
    this.checkForNotification = this.checkForNotification.bind(this);
    this.getNewUpcomingEventsAndCheckNotification =
      this.getNewUpcomingEventsAndCheckNotification.bind(this);
    this.copyNextEventConferencing = this.copyNextEventConferencing.bind(this);
    this.copyNextEventLocation = this.copyNextEventLocation.bind(this);
    this.openConferencingFromElectron =
      this.openConferencingFromElectron.bind(this);
    this.onClickNotification = this.onClickNotification.bind(this);
    this.onClickNotificationElectron =
      this.onClickNotificationElectron.bind(this);
    this.pullDataFromDate = this.pullDataFromDate.bind(this);
    this.emailUpcomingEvent = this.emailUpcomingEvent.bind(this);
    this.sendUpcomingEvents = this.sendUpcomingEvents.bind(this);

    Broadcast.subscribe(
      "UPDATE_EVENT_IN_AGENDA_WITH_NEW_EVENTS_FROM_INDEXDB",
      this.pullDataFromDate
    );
    Broadcast.subscribe(
      "REFORMAT_AGENDA_INDEX_WITH_NEXT_EVENT",
      this.reformatAgendaIndexWithNextEvent
    );
    Broadcast.subscribe(
      "OPEN_NEXT_EVENT_CONFERENCING",
      this.openNextEventConferencing
    );
    Broadcast.subscribe("OPEN_NEXT_EVENT_LOCATION", this.openNextEventLocation);
    Broadcast.subscribe("SHOW_NEXT_EVENT", this.showNextEvent);
    Broadcast.subscribe("CHECK_FOR_NOTIFICATION", this.checkForNotification);
    Broadcast.subscribe("SET_AGENDA_EVENT_AS_PREVIEW", this.onClickAgendaEvent);
    Broadcast.subscribe(
      "GET_AGENDA_UPCOMING_EVENTS_AND_CHECK_FOR_NOTIFICATIONS",
      this.getNewUpcomingEventsAndCheckNotification
    );
    conferencingBroadcasts.subscribe(
      "COPY_NEXT_EVENT_CONFERENCING",
      this.copyNextEventConferencing
    );
    Broadcast.subscribe("COPY_NEXT_EVENT_LOCATION", this.copyNextEventLocation);
    Broadcast.subscribe("EMAIL_UPCOMING_EVENT", this.emailUpcomingEvent);
    agendaBroadcast.subscribe("GET_UPCOMING_EVENTS", this.sendUpcomingEvents);
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return hasStateOrPropsChanged(
      this.state,
      nextState,
      this.props,
      nextProps,
      ["match", "location"],
      false
    );
  }

  componentDidMount() {
    this._isMounted = true;

    // switch accounts
    this.reformatAgendaIndexWithNextEvent();
    this.loadDesktopBridge();
  }

  getNewUpcomingEventsAndCheckNotification() {
    const { nextEvent, events, upcomingEvents } = this.determineUpcomingEvents();

    const updatedState = {
      currentTime: convertToTimeZone(new Date(), {
        timeZone: this.getCurrentTimeZone(),
      }),
      upcomingEvents,
      events,
    };

    if (
      !this.state.nextEvent ||
      !nextEvent ||
      getEventUserEventID(nextEvent) !== getEventUserEventID(this.state.nextEvent)
    ) {
      updatedState["nextEvent"] = nextEvent;
    }

    this.setState(updatedState);
    this.checkForNotification();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.indexByDay &&
      this.props &&
      prevProps &&
      this.props.date &&
      prevProps.date &&
      prevProps.date !== this.props.date
    ) {
      let { nextEvent, events, upcomingEvents } =
        this.determineUpcomingEvents();

      this.setState({
        nextEvent,
        events,
        upcomingEvents,
      });
    }

    const { masterAccount } = this.props.masterAccount;
    if (
      this.state?.upcomingEvents?.length !== 0 &&
      prevState?.upcomingEvents?.length === 0 &&
      !isTooltipCompleted(masterAccount, tooltipKeys.JOIN_MEETING)
    ) {
      this.setState({
        shouldHideJoinMeetingTooltip: false,
      });
      Broadcast.publish("MARK_TOOLTIP_COMPLETED", tooltipKeys.JOIN_MEETING);
    }

    if (this.state.upcomingEvents !== prevState.upcomingEvents) {
      agendaBroadcast.publish("SET_HOVER_UPCOMING_EVENT", this.state.upcomingEvents);
      focusModeBroadcast.publish("UPDATE_UPCOMING_EVENT"); // if upcoming changes -> get new events
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this._backendFetchTimeout);

    clearTimeout(this._setDesktopBridgeTimeout);
    this._setDesktopBridgeTimeout = null;

    if (this.state.isElectron && window?.vimcal) {
      window.vimcal.removeOnOpenConferencing &&
        window.vimcal?.removeOnOpenConferencing(
          this.openConferencingFromElectron
        );
      window.vimcal.removeOnClickNotification &&
        window.vimcal.removeOnClickNotification(
          this.onClickNotificationElectron
        );

      if (isWindowsElectron()) {
        // to pick up next meeting for pcs
        window.vimcal.removeOnJoinNextMeetingMainApp &&
          window.vimcal.removeOnJoinNextMeetingMainApp(
            this.openNextEventConferencing
          );
      }
    }

    Broadcast.unsubscribe(
      "UPDATE_EVENT_IN_AGENDA_WITH_NEW_EVENTS_FROM_INDEXDB"
    );
    Broadcast.unsubscribe("REFORMAT_AGENDA_INDEX_WITH_NEXT_EVENT");
    Broadcast.unsubscribe("OPEN_NEXT_EVENT_CONFERENCING");
    Broadcast.unsubscribe("OPEN_NEXT_EVENT_LOCATION");
    Broadcast.unsubscribe("SHOW_NEXT_EVENT");
    Broadcast.unsubscribe("CHECK_FOR_NOTIFICATION");
    Broadcast.unsubscribe("SET_AGENDA_EVENT_AS_PREVIEW");
    Broadcast.unsubscribe(
      "GET_AGENDA_UPCOMING_EVENTS_AND_CHECK_FOR_NOTIFICATIONS"
    );
    conferencingBroadcasts.unsubscribe("COPY_NEXT_EVENT_CONFERENCING");
    Broadcast.unsubscribe("COPY_NEXT_EVENT_LOCATION");
    Broadcast.unsubscribe("EMAIL_UPCOMING_EVENT");
    agendaBroadcast.unsubscribe("GET_UPCOMING_EVENTS");
  }

  render() {
    return (
      <>
        {this.renderUpNextWithCalendarList()}
        {this.renderHoverDayAgendaList()}
      </>
    );
  }

  //================
  // RENDER METHODS
  //================

  getHiddenDisplayClassName() {
    return "height-0px-important invisible-important";
  }

  renderHoverDayAgendaList() {
    return (
      <div
        className={ClassNames(
          this.isDateToday() ? this.getHiddenDisplayClassName() : "flex-grow",
          "render-agenda-content w-full agenda-style",
          "overflow-y-hidden-important",
        )}
      >
        <div
          className={ClassNames("side-menu-calendar-calendar-text ml-6 mb-2")}
        >
          {this.renderTitle()}
        </div>

        {this.renderAgendaList()}
      </div>
    );
  }

  renderActionList() {
    const {
      masterAccount
    } = this.props.masterAccount;
    if (!shouldShowListOfActions({ masterAccount })) {
      return null;
    }
    return (
      <div className="ml-6 mb-6">
        <CommandCenterSearchInput inputClassname="mb-6 -ml-1.5"/>
        <div className="side-menu-calendar-calendar-text mb-2.5">
          Quick Actions
        </div>
        <ActionListContainer />
      </div>
    );
  }

  renderUpNextWithCalendarList() {
    return (
      <HoverableScrollContainer inputClassName={ClassNames(
        this.isDateToday() ? "flex-grow" : this.getHiddenDisplayClassName(),
        "render-agenda-content w-full agenda-style",
        "pt-2.5",
      )}>
        {this.renderActionList()}
        {this.renderUpNextSection()}
        {this.renderCalendarList()}

        <ReferralPopup />
      </HoverableScrollContainer>
    );
  }

  renderUpNextSection() {
    const {
      masterAccount
    } = this.props.masterAccount;
    if (shouldShowListOfActions({ masterAccount })) {
      return null;
    }

    if (this.shouldRenderAgenda()) {
      return this.renderUpcomingEvents();
    }
    return this.renderEmptyState();
  }

  renderCalendarList() {
    return (
      <CalendarList
        inputClassName={this.isDateToday() ? "" : "invisible"}
        upcomingEvents={this.state.upcomingEvents}
        customMarginTopClassName={this.shouldRenderAgenda() ? null : "mt-3"}
      />
    );
  }

  renderUpcomingEvents() {
    if (this.state.upcomingEvents.length === 0) {
      return null;
    }

    return (
      <>
        <div>
          {this.state.upcomingEvents.map((e, index) => {
            return this.renderNextEvent(e, index);
          })}
        </div>
      </>
    );
  }

  renderConferenceRooms(event) {
    const conferenceRooms = generateConferenceRooms(event);

    return conferenceRooms.map((room, index) => {
      return (
        <div
          key={`expanded_selected_room_list_${index}`}
          className="agenda-view-render-selected-conference-room agenda-up-next-content-width"
        >
          {room}
        </div>
      );
    });
  }

  emailUpcomingEvent(isRunningLate=false) {
    if (this.preventHotKeyOptions()) {
      return;
    }

    if (this.state.upcomingEvents.length > 1) {
      Broadcast.publish(
        "DISPLAY_MULTIPLE_UPCOMING_EVENTS_MODAL",
        this.state.upcomingEvents,
        `You have ${this.state.upcomingEvents.length} upcoming events. Select which event to email`,
        isRunningLate ? TYPE_EMAIL_RUNNING_LATE : TYPE_EMAIL
      );
    } else {
      const nextEvent = this.state.upcomingEvents[0];
      Broadcast.publish("SHOW_EMAIL_ATTENDEES_MODAL", nextEvent, isRunningLate);
    }
  }

  renderNextEvent(nextEvent, index) {
    if (isEmptyObjectOrFalsey(nextEvent)) {
      return null;
    }
    const {
      actionMode,
      popupEvent,
      hoverPopupEvent,
      currentPreviewedEvent,
      currentHoverEvent,
    } = this.props;

    const previewEvent = getPreviewEvent({
      popupEvent,
      hoverPopupEvent,
      currentPreviewedEvent,
      currentHoverEvent,
    });

    const hideShortCuts = previewEvent ||
      isInActionMode(actionMode);
    const { timeRemaining, timeRemainingText } =
      determineTimeRemaining(nextEvent);
    const furthestReminderMin = this.determineFurthestReminderMin(nextEvent);

    // const shouldDisplayBorderBottom =
    //   this.state.upcomingEvents &&
    //   index !== this.state.upcomingEvents.length - 1;
    const isWithinNotificationPeriod = timeRemaining < furthestReminderMin;

    return (
      <div
        key={`upcoming_next_event_${index}`}
        className={ClassNames(
          "cursor-pointer",
          "agenda-next-event-wrapper margin-left-18px relative",
          // shouldDisplayBorderBottom ? "agenda-bottom-border" : "",
          index === 0 ? "" : "mt-4",
          "agenda-upcoming-event-box-container",
          "duration-200",
          this.shouldRenderIndictor(nextEvent) ? "" : "padding-left-12px-override",
        )}
        onClick={(e) => {
          hasEventPreventDefault(e);
          hasStopEventPropagation(e);
          this.onClickAgendaEvent(nextEvent);
        }}
      >
        <div
          className={ClassNames(
            "up-next-section cursor-pointer",
          )}
        >
          {isWithinNotificationPeriod
            ? <div className="agenda-upnext-text mr-2.5">
                {isNextEventNow(nextEvent) ? "NOW" : "Up Next"}
              </div>
            : null
          }

          <div
            className={classNames(
              "time-remaining",
              isWithinNotificationPeriod
                ? "time-remaining-warning"
                : ""
            )}
          >
            {isWithinNotificationPeriod ? timeRemainingText : capitalizeFirstLetter(timeRemainingText)}
          </div>
        </div>

        <div
          className="agenda-next-event-summary display-flex-flex-direction-row align-items-center -ml-4"
        >
          <GlobalKeyMapTile
            style={{ top: "-12px", left: "-15px", fontWeight: 300 }}
            shortcut={"N"}
            shouldHide={hideShortCuts}
          />

          {this.renderAgendaCalendarIndicator(nextEvent)}

          {nextEvent.summaryUpdatedWithVisibility}
        </div>

        <div className={classNames("agenda-upnext-time-wrapper flex", eventOnlyHasTime(nextEvent) ? "margin-bottom-0px-override" : "")}>
          <div className="agenda-subsection-label">Time:</div>

          <div className="inline-block font-weight-300">
            {formatTimeJSDate(
              convertToTimeZone(nextEvent.defaultStartTime, {
                timeZone: this.getCurrentTimeZone(),
              }),
              this.props.format24HourTime
            ) +
              " - " +
              formatTimeJSDate(
                convertToTimeZone(nextEvent.defaultEndTime, {
                  timeZone: this.getCurrentTimeZone(),
                }),
                this.props.format24HourTime
              )}
          </div>

					{eventHasAttendees(nextEvent) 
            ? <Email
                event={nextEvent}
                onClickEmail={(e) => {
                  hasStopEventPropagation(e);
                  Broadcast.publish("SHOW_EMAIL_ATTENDEES_MODAL", nextEvent);
                }}
                containerStyle={{ marginLeft: "10px" }}
                hoverHintStyle={{
                  top: "-10px",
                  right: "24px",
                  width: "90px"
                }}
                iconClassName="clickable-icon"
                keyMapStyle={{ top: -30, left: -10, width: 35 }}
                hideShortCut={hideShortCuts}
            />
            : null
          }
        </div>

        {filterForLocation(nextEvent) && filterForLocation(nextEvent) !== nextEvent.conferenceUrl && (
          <div className="agenda-location-wrapper">
            <div className="agenda-subsection-label">Location:</div>

            <EventLocation
              hideShortCut={hideShortCuts}
              agendaPanelStyle={{ left: "-100px", top: "-4px", zIndex: 1 }}
              location={filterForLocation(nextEvent)}
              hideIcon={true}
              shouldIgnoreBroadcast={true}
              displayTitleWithCopy={true}
              textColorClassName={"hoverable-secondary-text-color"}
            />
          </div>
        )}

        {generateConferenceRooms(nextEvent) &&
          generateConferenceRooms(nextEvent).length > 0 && (
            <div className="agenda-location-wrapper">
              <div className="agenda-subsection-label">Room:</div>

              <div className="agenda-location-text">
                {this.renderConferenceRooms(nextEvent)}
              </div>
            </div>
          )}

        {nextEvent.conferenceUrl && (
          <div className="agenda-conference-wrapper flex items-center">
            <div className="agenda-subsection-label">Video:</div>

            <div className="inline-block font-weight-300">
              <EventConferencing
                hideShortCut={hideShortCuts}
                agendaPanelStyle={{ left: "-100px", top: "-3px" }}
                conferenceUrl={nextEvent.conferenceUrl}
                hideIcon={true}
                shouldIgnoreBroadcast={true}
                displayTitleWithCopy={true}
              />
            </div>
          </div>
        )}

        {!this.state.shouldHideJoinMeetingTooltip && nextEvent.conferenceUrl ? (
          <div className="mr-4 margin-top-15">
            <TooltipBox
              description="You can press the shortcut “V” anytime in Vimcal to automatically jump into your next or current video call!"
              hideLearnMore={true}
              onClick={(e) => {
                hasEventPreventDefault(e);
                hasStopEventPropagation(e);
                this.setState({
                  shouldHideJoinMeetingTooltip: true,
                });
              }}
              title="Did You Know?"
            />
          </div>
        ) : null}

        {this.renderMultipleConferenceWarning(nextEvent)}
      </div>
    );
  }

  renderMultipleConferenceWarning(event) {
    let warning = determineMultipleConferenceWarning(event);
    if (!warning) {
      return null;
    }

    return (
      <div
        className="conflicting-zoom-warning font-size-12 font-weight-300"
        style={{ width: 285, wordBreak: "break-word" }}
      >
        <div className="margin-top-five">*</div>

        <div className="margin-left-5 margin-top-five">{warning}</div>
      </div>
    );
  }

  renderEventStartText(e, displayAllDay, multidayEvent, hoverDate) {
    const {
      format24HourTime
    } = this.props;
    if (displayAllDay) {
      if (multidayEvent) {
        if (
          isSameDay(e.eventEnd, hoverDate) ||
          this.isEventStartAndEventEndNotOnTheSameDayAsHoverDay(e, hoverDate)
        ) {
          return "Multi-day";
        } else {
          return format(
            convertToTimeZone(e.defaultStartTime, {
              timeZone: this.getCurrentTimeZone(),
            }),
            getDateTimeFormat(format24HourTime)
          );
        }
      } else {
        return "All day";
      }
    } else {
      return format(
        convertToTimeZone(e.defaultStartTime, {
          timeZone: this.getCurrentTimeZone(),
        }),
        getDateTimeFormat(format24HourTime)
      );
    }
  }

  shouldHideEndTime(multidayEvent, e, hoverDate) {
    return (
      e.allDay ||
      (multidayEvent &&
        this.isEventStartAndEventEndNotOnTheSameDayAsHoverDay(e, hoverDate))
    );
  }

  isEventStartAndEventEndNotOnTheSameDayAsHoverDay(e, hoverDate) {
    return (
      !isSameDay(e.eventStart, hoverDate) && !isSameDay(e.eventEnd, hoverDate)
    );
  }

  renderAgendaList() {
    const currentDate = convertToTimeZone(new Date(), {
      timeZone: this.getCurrentTimeZone(),
    });
    const { nextEvent } = this.state;
    let events = this.state.events;
    const hoverDate = this.props.date || new Date();

    if (currentDate === this.props.date && !!nextEvent) {
      events = this.state.events.filter(
        (e) => getEventUserEventID(e) !== getEventUserEventID(this.state.nextEvent)
      );
    }

    if (events.length === 0 && !this.isDateToday()) {
      return this.renderEmptyState();
    }

    const determineSubtext = (event) => {
      const location = getEventLocation(event);

      const eventConferenceData = getEventConferenceData(event);
      if (location) {
        return determineConferenceText(location) || location;
      } else if (eventConferenceData?.conferenceSolution?.name) {
        return eventConferenceData.conferenceSolution.name;
      } else {
        return determineConferenceText(event.conferenceUrl);
      }
    };

    return events.map((e, index) => {
      let displayAllDay = e.displayAsAllDay;
      let multidayEvent = !e.allDay && e.displayAsAllDay;
      let subtext = determineSubtext(e);

      return (
        <div
          key={`agendaItem${index}`}
          id={nextEvent === e ? "highlightedItem" : ""}
          className="focusable-text mt-2.5 display-flex-flex-direction-row padding-left-15 p-2 align-items-center ml-2"
          onClick={() => this.onClickAgendaEvent(e)}
        >
          {this.renderAgendaCalendarIndicator(e, true)}

          <div
            className={ClassNames(
              !this.shouldHideEndTime(multidayEvent, e, hoverDate)
                ? "agenda-time-not-all-day"
                : "agenda-time-all-day"
            )}
          >
            <div className="agenda-duration">
              {this.renderEventStartText(
                e,
                displayAllDay,
                multidayEvent,
                hoverDate
              )}
            </div>

            {!this.shouldHideEndTime(multidayEvent, e, hoverDate) ? (
              <div className="agenda-start-end">
                {multidayEvent && isSameDay(e.eventStart, hoverDate)
                  ? "Multi-day"
                  : format(
                      convertToTimeZone(e.defaultEndTime, {
                        timeZone: this.getCurrentTimeZone(),
                      }),
                      "p"
                    )}
              </div>
            ) : null}
          </div>

          <div className={subtext ? "" : "agenda-information"}>
            <div className="agenda-summary">
              {e.summaryUpdatedWithVisibility || "No Title"}
            </div>

            {subtext && <div className="agenda-event-detail">{subtext}</div>}
          </div>
        </div>
      );
    });
  }

  renderEmptyState() {
    return (
      <div className="no-events-scheduled agenda-calendar-list relative h-7 secondary-text-color">
        Your schedule is clear!
      </div>
    );
  }

  shouldRenderIndictor(event) {
    if (isEmptyObjectOrFalsey(event)) {
      return false;
    }

    if (this.getUpcomingCalendarIDs().length <= 1) {
      return false;
    }

    return true;
  }

  renderAgendaCalendarIndicator(event, hideInvisibleIndicator = false) {
    const { allCalendars } = this.props.allCalendars;
    const {
      allLoggedInUsers
    } = this.props.allLoggedInUsers;
    const {
      currentUser
    } = this.props;
    const {
      masterAccount,
    } = this.props.masterAccount;
    const { outlookCategories } = this.props.outlookCategoriesStore;
    const matchingUser = getMatchingUIUserForEvent({
      event,
      allCalendars,
      allLoggedInUsers,
      masterAccount,
      currentUser,
    });

    if (
      isEmptyObjectOrFalsey(event) || this.getUpcomingCalendarIDs().length <= 1
    ) {
      if (hideInvisibleIndicator) {
        return null;
      }

      return (
        <div
          className="agenda-event-calendar-color mr-2 bg-transparent border-transparent border-color-transparent-important background-color-transparent-important"
        ></div>
      );
    } else if (isMergedEvent(event)) {
      return (
        <div className="mr-4 mb-5">
          {renderMergedEventIndicator({
            event, 
            allCalendars,
            user: matchingUser,
            currentUser,
            allLoggedInUsers,
            outlookCategories,
            masterAccount,
          })}
        </div>
      );
    } else {
      return (
        <div
          className="agenda-event-calendar-color mr-2"
          style={getEventButtonStyle({
            event,
            allCalendars,
            user: matchingUser,
            currentUser,
            allLoggedInUsers,
            outlookCategories,
            masterAccount,
          })}
        ></div>
      );
    }
  }

  renderTitle() {
    if (isSameYear(new Date(), this.props.date)) {
      // Sunday, Jan 31 schedule
      return format(this.props.date, "EEEE, MMM d") + " schedule";
    } else {
      // Sunday, Jan 31 2021 schedule
      return format(this.props.date, "EEEE, MMM d yyyy") + " schedule";
    }
  }

  //================
  // EVENT HANDLERS
  //================

  onClickAgendaEvent(e) {
    if (isEmptyObjectOrFalsey(e)) {
      return;
    }

    batch(() => {
      this.props.history.push("/home/expanded");

      this.setPreviewEvent(e);
    });

    if (this.shouldGoToEventStartDate(e)) {
      let date = convertToTimeZone(e.defaultStartTime, {
        timeZone: this.getCurrentTimeZone(),
      });

      Broadcast.publish("NAVIGATE_DATE", date);
    }
  }

  shouldGoToEventStartDate(event) {
    return differenceInDays(this.props.selectedDay, event.eventStart) !== 0;
  }

  copyNextEventLocation() {
    if (this.state.upcomingEvents.length === 0) {
      // Do nothing
    } else if (this.state.upcomingEvents.length > 1) {
      let eventsWithLocation = [];

      this.state.upcomingEvents.forEach((e) => {
        if (getEventLocation(e)) {
          eventsWithLocation = eventsWithLocation.concat(e);
        }
      });

      if (eventsWithLocation.length === 0) {
        // do nothing
      } else {
        const event = eventsWithLocation[0];
        const location = getEventLocation(event);

        this.copyText(location, "Copied event location");
      }
    } else {
      const event = this.state.upcomingEvents[0];
      const location = getEventLocation(event);

      this.copyText(location, "Copied event location");
    }
  }

  copyNextEventConferencing() {
    if (this.state.upcomingEvents.length === 0) {
      // Do nothing
    } else if (this.state.upcomingEvents.length > 1) {
      let eventsWithConferencing = [];

      this.state.upcomingEvents.forEach((e) => {
        if (e.conferenceUrl) {
          eventsWithConferencing = eventsWithConferencing.concat(e);
        }
      });

      if (eventsWithConferencing.length === 0) {
        // do nothing
      } else {
        let event = eventsWithConferencing[0];
        let conferencing = event.conferenceUrl;

        this.copyText(conferencing, "Copied conferencing link");
      }
    } else {
      // only one event
      let event = this.state.upcomingEvents[0];
      let conferencing = event.conferenceUrl;

      this.copyText(conferencing, "Copied conferencing link");
    }
  }

  copyText(text, message) {
    if (!text) {
      return;
    }

    navigator.clipboard.writeText(text).then(
      () => {
        if (!this._isMounted) {
          return;
        }
        Broadcast.publish(SET_DISAPPEARING_NOTIFICATION_MESSAGE, message);
        /* clipboard successfully set */
      },
      function (e) {
        handleError(e);
        /* clipboard write failed */
      }
    );
  }

  openNextEventConferencing() {
    if (this.preventHotKeyOptions()) {
      return;
    }

    if (isHoverUpcomingEventShowing()) {
      agendaBroadcast.publish("REMOVE_CURRENT_EVENT_FROM_UPCOMING_EVENT");
    }

    if (this.state.upcomingEvents.length > 1) {
      let eventsWithConferencing = [];

      this.state.upcomingEvents.forEach((e) => {
        if (e.conferenceUrl) {
          eventsWithConferencing = eventsWithConferencing.concat(e);
        }
      });

      if (eventsWithConferencing.length === 0) {
        // do nothing
      } else if (eventsWithConferencing.length === 1) {
        let event = eventsWithConferencing[0];
        let conferencing = event.conferenceUrl;

        openConferencingURL(conferencing, this.props.currentUser.email);
      } else {
        Broadcast.publish(
          "DISPLAY_MULTIPLE_UPCOMING_EVENTS_MODAL",
          eventsWithConferencing,
          `You have ${eventsWithConferencing.length} upcoming events. Select which event to join`,
          TYPE_CONFERENCING
        );
      }
    } else {
      // only one event
      let event = this.state.upcomingEvents[0];
      let conferencing = event.conferenceUrl;

      openConferencingURL(conferencing, this.props.currentUser.email);
    }
  }

  openNextEventLocation() {
    if (this.preventHotKeyOptions()) {
      return;
    }

    if (isHoverUpcomingEventShowing()) {
      agendaBroadcast.publish("REMOVE_CURRENT_EVENT_FROM_UPCOMING_EVENT");
    }

    if (this.state.upcomingEvents.length > 1) {
      let eventsWithLocation = [];

      this.state.upcomingEvents.forEach((e) => {
        if (getEventLocation(e)) {
          eventsWithLocation = eventsWithLocation.concat(e);
        }
      });

      if (eventsWithLocation.length === 0) {
        // do nothing
      } else if (eventsWithLocation.length === 1) {
        const event = eventsWithLocation[0];
        const location = getEventLocation(event);

        OpenGoogleMapsLocation(location, this.props.currentUser);
      } else {
        Broadcast.publish(
          "DISPLAY_MULTIPLE_UPCOMING_EVENTS_MODAL",
          eventsWithLocation,
          `You have ${eventsWithLocation.length} upcoming events. Select which location to open`,
          TYPE_LOCATION
        );
      }
    } else {
      const event = this.state.upcomingEvents[0];
      const location = getEventLocation(event);

      OpenGoogleMapsLocation(location, this.props.currentUser);
    }
  }

  // prevent N, V, L, GE
  preventHotKeyOptions() {
    return (
      isInActionMode(this.props.actionMode) ||
      isInSearchState() ||
      isInEditTemplatesState() ||
      !this.state.upcomingEvents ||
      this.state.upcomingEvents.length === 0
    );
  }

  showNextEvent() {
    if (this.preventHotKeyOptions()) {
      return;
    }

    if (isHoverUpcomingEventShowing()) {
      agendaBroadcast.publish("REMOVE_CURRENT_EVENT_FROM_UPCOMING_EVENT");
    }

    if (this.state.upcomingEvents.length > 1) {
      Broadcast.publish(
        "DISPLAY_MULTIPLE_UPCOMING_EVENTS_MODAL",
        this.state.upcomingEvents,
        `You have ${this.state.upcomingEvents.length} upcoming events. Select which event to open`,
        TYPE_UP_NEXT
      );
    } else {
      this.onClickAgendaEvent(this.state.upcomingEvents[0]);
    }
  }

  //=================
  // PRIVATE METHODS
  //=================

  initialFetchAndPullDataForAgenda() {
    this.pullDataFromDate({
      start: this.state.unixMonthStartDate,
      end: this.state.unixMonthEndDate,
      jsDateStart: this.state.monthStartDateJSDate,
      jsDateEnd: this.state.monthEndDateJSDate,
      fetchId: createUUID(),
    });
  }

  checkForNotification() {
    if (
      isEmptyObjectOrFalsey(this.props.currentUser) ||
      Push.Permission.get() === Push.Permission.DENIED
    ) {
      // no need to continue if we don't have permission
      return;
    }

    if (!Push.Permission.has()) {
      // if we haven't even asked for permission -> ask here
      Push.Permission.request();
    }

    const currentTabID = getTabIDFromLocalData();

    const { tabID } = this.props.tabIDStore;
    if (tabID !== currentTabID || !Push.Permission.has()) {
      // we only want one tab to send out notification at any given time
      return;
    }

    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const { allCalendars } = this.props.allCalendars;
    const { masterAccount } = this.props.masterAccount;
    const {
      showDeclinedEvents,
      currentUser,
    } = this.props;

    let dbPromises = [];
    let dbCalendarIdEventsObject = {};

    const upcomingCalendarIDs = this.getUpcomingCalendarIDs();

    let fetchedEvents = [];

    const pastJSDate = subDays(new Date(), 1);
    const futureJSDate = addWeeks(new Date(), 1); // need to look a week in the future becuase you could have events that send notification a few days in advance
    allLoggedInUsers.forEach((user) => {
      let dbEmailPromise = db
        .fetch(getUserEmail(user))
        .events.where(DEXIE_EVENT_COLUMNS.CALENDAR_ID)
        .startsWithAnyOfIgnoreCase(upcomingCalendarIDs)
        .and((item) => {
          return (
            isValidEvent(item?.event) &&
            !isCancelledEvent(item) &&
            item.event.eventStart &&
            isDBEventItemWithinWindow({
              item,
              windowStart: pastJSDate,
              windowEnd: futureJSDate,
            })
          );
        })
        .toArray()
        .then((response) => {
          if (!this._isMounted || !response) {
            return;
          }

          // filter out not attending events
          const filteredEvents = response.filter(
            (r) =>
              getEventAttendeeStatus(r.event, allCalendars) !==
              ATTENDEE_EVENT_DECLINED
          );

          // indexdb stores our event in another format and need to map the actual event out
          const eventsResult = filteredEvents.map((e) => {
            return e.event;
          });

          // add event into index with user calendar id as the key
          fetchedEvents = fetchedEvents.concat(eventsResult);
        })
        .catch((err) => {
          handleError(err);
        });

      dbPromises = dbPromises.concat(dbEmailPromise);
    });

    const calendarsWithDefaultNotifications =
      getAllUserCalendarIDWithDefaultReminders(allCalendars);

    Promise.all(dbPromises).then(() => {
      // merge events together so we don't send duplicate notifications if you have the same multiple calendars
      const currentUserUserCalendarID = this.getUserCalendarIDFromEmail();

      // always need to format events to the default OS time zone
      const { events } = formatEventsList({
        calendarEvents: fetchedEvents,
        currentTimeZone: this.getDefaultUserTimeZone(),
        currentUserEmail: getUserEmail(currentUser),
        showDeclinedEvents,
        allCalendars,
      });

      // filter out events that do not have default reminders or overrides
      const filteredEvents = events.filter(
        (e) =>
          calendarsWithDefaultNotifications.includes(getEventUserCalendarID(e)) ||
          getEventReminderOverrides(e)?.length > 0
      );

      // do not need to always pass in merged events
      const mergedEvents = mergeSameEvents({
        eventList: filteredEvents,
        currentUser: this.props.currentUser,
        currentUserUserCalendarID: currentUserUserCalendarID,
        allCalendars: allCalendars,
        masterAccount,
        allLoggedInUsers,
      });

      mergedEvents.forEach((e) => {
        if (dbCalendarIdEventsObject[getEventUserCalendarID(e)]) {
          dbCalendarIdEventsObject[getEventUserCalendarID(e)] =
            dbCalendarIdEventsObject[getEventUserCalendarID(e)].concat(e);
        } else {
          dbCalendarIdEventsObject[getEventUserCalendarID(e)] = [e];
        }
      });

      if (!this._isMounted) {
        return;
      }
      // Loop through event and find which event has reminder now
      // Skip if email, only show popup;
      let notificationCalendarIndex = {};

      const defaultUserTimeZone = this.getDefaultUserTimeZone();
      Object.keys(dbCalendarIdEventsObject).forEach((userCalendarID) => {
        // value for dbCalendarIdEventsObject is a list of events
        const formatEventsTimeZone = dbCalendarIdEventsObject[
          userCalendarID
        ].map((e) => {
          return FormatIntoJSDate(e, defaultUserTimeZone); // need to use guessTimeZone and not currentTimeZone since we send notifications based on the current time zone
        });

        // return list of upcoming events that need notification based on calendar preferences for notifications or the event's own override
        const notificationsForCalendar = this.createNotificationsList(
          formatEventsTimeZone,
          userCalendarID
        );

        if (notificationsForCalendar?.length > 0) {
          notificationCalendarIndex[userCalendarID] = notificationsForCalendar;
        }
      });

      // Used for testing below
      // TODO: Comment out
      // console.log('notificationCalendarIndex_', notificationCalendarIndex);
      // Used for testing above

      // No upcoming notifications
      if (Object.keys(notificationCalendarIndex).length === 0) {
        return;
      }

      // Call function to push notification
      Object.keys(notificationCalendarIndex).forEach(
        (userCalendarID, index) => {
          // If want to add sound -> have to add it separately (chrome does the same thing)
          // Library: https://www.npmjs.com/package/howler

          const notificationEventList =
            notificationCalendarIndex[userCalendarID];

          if (
            this.state.isElectron &&
            this.state.isMac &&
            window?.vimcal?.setNotification
          ) {
            // only use rich os notifier if it's mac and electron and has notification function call
            notificationEventList.forEach((e) => {
              let notificationBody = {
                title: e.summaryUpdatedWithVisibility,
                time: this.formatNotificationTime(e),
                calendarId: userCalendarID,
                event: JSON.stringify(e),
              };
              if (e.conferenceUrl) {
                notificationBody.conferencingType =
                  renderConferencingActionText(e.conferenceUrl, e)
                    ?.replace("join ", "")
                    .replace("Join ", "");
                notificationBody.conferencingLink = e.conferenceUrl;
              } else if (filterForLocation(e)) {
                notificationBody.location = filterForLocation(e);
              }

              window.vimcal.setNotification(notificationBody);
            });
          } else {
            notificationEventList.forEach((e) => {
              Push.create(e.summaryUpdatedWithVisibility, {
                body: this.formatNotificationTime(e),
                icon: process.env.PUBLIC_URL + `/logo192.png`,
                requireInteraction: true,
                onClick: () => this.onClickNotification(e, userCalendarID),
              });
            });
          }
        }
      );
    });
  }

  formatNotificationTime(event) {
    const defaultUserTimeZone = this.getDefaultUserTimeZone();
    const startTime = convertToTimeZone(parseISO(event.defaultStartTime), {timeZone: defaultUserTimeZone});
    const endTime = convertToTimeZone(parseISO(event.defaultEndTime), {timeZone: defaultUserTimeZone});

    // need different format for all day events
    if (event.allDay) {
      // E.g.: Monday 08/15/2020
      return `${format(startTime, "EEEE")} ${format(startTime, "P")}`;
    }

    // Not all day events
    let startString;
    let endString;

    // if minute is 0
    if (startTime.getMinutes() === 0) {
      startString = format(startTime, "haaa");
    } else {
      startString = format(startTime, "h:mmaaa");
    }

    if (endTime.getMinutes() === 0) {
      endString = format(endTime, "haaa");
    } else {
      endString = format(endTime, "h:mmaaa");
    }

    const { day, hour, minute } = timeRemainingBreakdown(event, this.getDefaultUserTimeZone());

    let remainingText = "";
    if (day) {
      remainingText = `${day} ${pluralize(day, "day")}`;
    }

    if (hour) {
      remainingText =
        remainingText + `${day ? " " : ""}${hour} ${pluralize(hour, "hour")}`;
    }

    if (minute) {
      remainingText =
        remainingText +
        `${day || hour ? " " : ""}${minute} ${pluralize(minute, "min")}`;
    }

    if (!day && !hour && !minute && minute === 0) {
      // event is now
      // early return so we don't have (in now)
      return `${startString} - ${endString} (now)`;
    }

    if (!remainingText) {
      // early return if there's no remainText so we don't show in () where it's an empty string inside the ()
      return `${startString} - ${endString}`; 
    }

    return `${startString} - ${endString} (in ${remainingText})`;
  }

  createNotificationsList(events, userCalendarID) {
    if (isEmptyArray(events)) {
      return [];
    }

    const { allCalendars } = this.props.allCalendars;
    const matchingCalendar = allCalendars[userCalendarID];
    if (!isValidCalendar(matchingCalendar)) {
      return [];
    }

    const defaultReminders = getCalendarDefaultReminders(matchingCalendar);

    // Get array of minutes for reminder (10 minutes before, 3 minutes before, etc
    let reminderMinutesArray = [];
    if (defaultReminders) {
      defaultReminders.forEach((r) => {
        if (r.method === "popup") {
          reminderMinutesArray = reminderMinutesArray.concat(r.minutes);
        }
      });
    }

    let eventsWithUpcomingNotifications = [];

    const currentTimeAtTheMoment = getCurrentTimeInCurrentTimeZone(this.getDefaultUserTimeZone());

    events.forEach((e) => {
      const eventAllDayStartDate = getEventStartAllDayDate(e);
      const eventStartJSDate = eventAllDayStartDate
        ? startOfDay(parseISO(eventAllDayStartDate)) // All day event -> need to set hour and minute to 0
        : e.eventStart;

      // Make sure we only add event once
      let addedEvent = false;
      const eventReminders = getEventReminders(e);

      if (eventReminders) {
        if (eventReminders.useDefault && reminderMinutesArray.length > 0) {
          // see what the default options are
          reminderMinutesArray.forEach((m) => {
            if (
              !addedEvent &&
              isSameMinute(
                subMinutes(eventStartJSDate, m),
                currentTimeAtTheMoment
              )
            ) {
              addedEvent = true;
              eventsWithUpcomingNotifications =
                eventsWithUpcomingNotifications.concat(e);
            }
          });
        } else if (getEventReminderOverrides(e)?.length > 0) {
          const overrideReminders = getEventReminderOverrides(e);

          overrideReminders.forEach((r) => {
            // Skip if email notification
            if (
              !addedEvent &&
              r.method === "popup" &&
              isSameMinute(
                subMinutes(eventStartJSDate, r.minutes),
                currentTimeAtTheMoment
              )
            ) {
              addedEvent = true;
              eventsWithUpcomingNotifications =
                eventsWithUpcomingNotifications.concat(e);
            }
          });
        }
      }
    });

    return eventsWithUpcomingNotifications;
  }

  onClickNotificationElectron(e, param) {
    if (isEmptyObjectOrFalsey(param)) {
      return;
    }

    const {
      event, // comes back as string -> need to JSON.parse
      calendarId,
    } = param;
    if (!event) {
      return;
    }
    const parsedEvent = safeJSONParse(event);
    if (!parsedEvent) {
      return;
    }
    this.onClickNotification(parsedEvent, calendarId);
  }

  onClickNotification(e, calendarID) {
    // Electron requires different focus technique
    if (this.state.isElectron && window?.vimcal?.focusWindow) {
      window.vimcal.focusWindow();
    } else if (window?.focus) {
      window.focus();
    }

    const { allCalendars } = this.props.allCalendars;

    const matchingCalendar = allCalendars[calendarID]; // calendar that the event belongs to
    if (
      isEmptyObjectOrFalsey(e) ||
      !calendarID ||
      !isValidCalendar(matchingCalendar) ||
      !this._isMounted ||
      !this.props.currentTimeZone
    ) {
      return;
    }

    const event = formatEventForReactBigCalendar({
      event: e,
      currentTimeZone: this.props.currentTimeZone,
      calendarId: calendarID,
    });

    if (!event?.eventStart) {
      return;
    }

    const date = convertToTimeZone(event.defaultStartTime, {
      timeZone: this.props.currentTimeZone,
    });
    Broadcast.publish("NAVIGATE_DATE", date);

    this.setPreviewEvent(event);

    if (!isCalendarSelected(matchingCalendar)) {
      // need to toggle on calendar for event
      Broadcast.publish(
        "TOGGLE_SELECT_CALENDAR_WITH_CALENDAR_ID",
        getCalendarUserCalendarID(matchingCalendar)
      );
    }
  }

  determineFurthestReminderMin(nextEvent) {
    const eventReminders = getEventReminders(nextEvent);
    if (isEmptyObjectOrFalsey(eventReminders)) {
      return 10;
    }

    const { allCalendars } = this.props.allCalendars;

    const matchingCalendar = allCalendars[getEventUserCalendarID(nextEvent)];
    if (!isValidCalendar(matchingCalendar)) {
      return 10;
    }

    if (
      eventReminders.useDefault &&
      getCalendarDefaultReminders(matchingCalendar)
    ) {
      const defaultReminders = getCalendarDefaultReminders(matchingCalendar);
      return determineFurthestMinute(defaultReminders);
    } else if (eventReminders.overrides) {
      const overrides = eventReminders.overrides;

      return determineFurthestMinute(overrides);
    }

    return 10;
  }

  reformatAgendaIndexWithNextEvent(newTimeZone = null) {
    const { masterAccount } = this.props.masterAccount;
    const { allLoggedInUsers } = this.props.allLoggedInUsers;

    const currentUserUserCalendarID = this.getUserCalendarIDFromEmail();

    if (!currentUserUserCalendarID || !this._isMounted) {
      return;
    }

    const {
      selectedDay,
      weekStart,
      selectedCalendarView
    } = this.props;
    const { outlookCategories } = this.props.outlookCategoriesStore;
    const eventDataWindow = createWindow({
      windowJSDate: getSelectedDayWithBackup(selectedDay),
      isMonth: true,
      weekStart,
      selectedCalendarView
    });

    const { windowMonthStart, windowMonthEnd } = eventDataWindow;

    const currentTimeZone = this.props.currentTimeZone;
    const currentDayPlusOne = convertDateIntoEpochUnixWeek(
      addDays(new Date(), 1)
    );
    const currentDayMinusOne = convertDateIntoEpochUnixWeek(
      subDays(new Date(), 1)
    );

    // Also pull events for the current month
    let currentMonthStart = this.state.unixMonthStartDate;
    let currentMonthEnd = this.state.unixMonthEndDate;

    let dbFetchPromises = [];
    let fetchedEvents = [];

    allLoggedInUsers.forEach((user) => {
      const dbFetch = db
        .fetch(getUserEmail(user))
        .events.where(DEXIE_EVENT_COLUMNS.CALENDAR_ID)
        .startsWithAnyOfIgnoreCase(this.getUpcomingCalendarIDs())
        .and(function (item) {
          return (
            isEventInWindow(item.event, windowMonthStart - 7, windowMonthEnd + 7) ||
            isEventInWindow(
              item.event,
              currentDayMinusOne,
              currentDayPlusOne
            ) ||
            isEventInWindow(item.event, currentMonthStart, currentMonthEnd)
          );
        })
        .toArray()
        .then((response) => {
          if (!this._isMounted) {
            return;
          }

          const calendarEvents = response.map((e) => {
            return e.event;
          });

          const filteredEvents = calendarEvents
            .filter((e) => !isCancelledEvent(e) && !isEventTooLong(e))

          fetchedEvents = fetchedEvents.concat(filteredEvents);
        })
        .catch(handleError);

      dbFetchPromises = dbFetchPromises.concat(dbFetch);
    });

    Promise.all(dbFetchPromises).then(() => {
      if (!this._isMounted) {
        return;
      }

      const eventsWithStaticInformation = fetchedEvents.map((e) => {
        const staticInformation = createStaticInformation({
          e,
          currentUser: this.props.currentUser,
          allCalendars: this.props.allCalendars.allCalendars,
          where: "agenda",
          outlookCategories,
          allLoggedInUsers,
          masterAccount,
        });
        const updatedEvent = { ...e, ...staticInformation };
        return updatedEvent;
      });

      const { allCalendars } = this.props.allCalendars;
      const mainCalendarEvents = mergeSameEvents({
        eventList: eventsWithStaticInformation,
        currentUser: this.props.currentUser,
        currentUserUserCalendarID: currentUserUserCalendarID,
        allCalendars: allCalendars,
        masterAccount,
        alwaysMerge: true,
        allLoggedInUsers,
      });

      const { indexByDate } = createAgendaPanelIndex({
        mainCalendarEvents,
        currentTimeZone,
      });

      if (newTimeZone) {
        this.setState(
          {
            indexByDay: indexByDate,
          },
          () => {
            this.props.setAgendaDay(
              convertToTimeZone(new Date(), { timeZone: newTimeZone })
            );
          }
        );
      } else {
        const { nextEvent, events, upcomingEvents } =
          this.determineUpcomingEvents(indexByDate);

        this.setState({
          indexByDay: indexByDate,
          events,
          nextEvent,
          upcomingEvents,
        });
      }
    });
  }

  todayScheduleStyle() {
    return {
      display: "flex",
      fontSize: 16,
      marginBottom: "10px",
      fontWeight: 400,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      marginLeft: 25,
    };
  }

  shouldRenderAgenda() {
    if (this.state.nextEvent) {
      return true;
    }
    if (
      this.state.upcomingEvents?.length > 0
    ) {
      return true;
    }
    if (this.state.events?.length > 0) {
      return true;
    }
    if (isEmptyArrayOrFalsey(this.state.events)) {
      return false;
    }
    return true;
  }

  pullDataFromDate(startAndEndDate) {
    const { start, end, jsDateStart, jsDateEnd, fetchId } = startAndEndDate;

    const upcomingCalendarUserCalendarIDs = this.getUpcomingCalendarIDs();
    if (isEmptyArray(upcomingCalendarUserCalendarIDs)) {
      // no point in searching if we don't have main calendars set up yet
      return;
    }

    this.fetchId = fetchId;

    // fetch latest events from backend
    clearTimeout(this._backendFetchTimeout);
    this._backendFetchTimeout = setTimeout(() => {
      if (!this._isMounted) {
        return;
      }
      this.fetchEventsForUserCalendarIDs({
        userCalendarIDs: upcomingCalendarUserCalendarIDs,
        dateFrom: jsDateStart,
        dateTo: jsDateEnd,
        fetchId
      });
    }, SECOND_IN_MS * 3);

    this.setState(
      {
        unixMonthStartDate: start,
        unixMonthEndDate: end,
        monthStartDateJSDate: jsDateStart,
        monthEndDateJSDate: jsDateEnd,
      },
      this.reformatAgendaIndexWithNextEvent
    );
  }

  fetchEventsForUserCalendarIDs({
    userCalendarIDs, 
    dateFrom, 
    dateTo, 
    fetchId
  }) {
    if (isEmptyArray(userCalendarIDs) || isEmptyObjectOrFalsey(this.props.currentUser)) {
      return;
    }

    const { allCalendars } = this.props.allCalendars;
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const { currentUser } = this.props;

    const formatFetchedEvents = (flattenedResponse) => {
      let timeZone = this.props.currentTimeZone;

      const { formattedEventsObject } = formatFlattendCalendarAndEvents({
        objectOfCalendarAndEvents: flattenedResponse,
        currentTimeZone: timeZone,
        shouldFilterForActive: true,
      });

      // formattedEventsObject is an object of formatted main calendar events with the user calendar id as the key
      // next -> need to store each usrCalendarID pair into appropriate indexDB based on user email
      if (isEmptyObjectOrFalsey(formattedEventsObject)) {
        return;
      }

      let allDbPromises = [];

      Object.keys(formattedEventsObject).forEach((k) => {
        if (!isValidCalendar(allCalendars[k])) {
          return;
        }

        const calendarUserEmail = getCalendarUserEmail(allCalendars[k]);
        const dbStoragePromise = storeFetchedEventsIntoDB(
          calendarUserEmail,
          formattedEventsObject[k]
        );
        allDbPromises = allDbPromises.concat(dbStoragePromise);
      });

      if (allDbPromises.length === 0) {
        return;
      }
      Promise.all(allDbPromises).then(() => {
        if (!this._isMounted) {
          return;
        }

        this.reformatAgendaIndexWithNextEvent();
      });
    };

    const storeFetchedEventsIntoDB = (userEmail, userEvents) => {
      const filteredEvents = userEvents.filter((e) => !isCancelledEvent(e));
      addEventsIntoIndexDB({
        userEmail,
        events: filteredEvents
      });
    };

    const sendRequest = (userEmail, isRetry) => {
      const {
        weekStart,
        currentTimeZone,
      } = this.props;
      const timeMin = subDays(
        getFirstDayOfWeekJsDate(dateFrom, weekStart),
        7
      ).toISOString();
      const timeMax = addDays(
        getLastDayOfWeekJsDate(dateTo, weekStart),
        7
      ).toISOString();
      const providerIDs = getMatchingUserProviderIDsFromUserCalendarIDs({
        userCalendarIDs,
        allCalendars,
        userEmail,
      });
      if (isEmptyArray(providerIDs)) {
        // nothing to send
        return;
      }

      const url = constructRequestURL(FETCH_CALENDAR_EVENTS_ENDPOINT, true);

      const body = {
        timeMin,
        timeMax,
        timeZone: currentTimeZone,
        calendarIds: providerIDs,
      };

      const payloadData = {
        headers: getDefaultHeaders(),
        body: JSON.stringify(body),
      };

      const handleResponse = async (response) => {
        if (!this._isMounted || this.fetchId !== fetchId) {
          return;
        }
        if (!isRetry && shouldRetryBasedOnResponse(response, currentUser)) {
          // checked for 429
          const retryAfterMS = getRetryAfterMS(response);
          await delayByMs(retryAfterMS);
          if (!this._isMounted) {
            return;
          }
          sendRequest(userEmail, true);
          return;
        }
        if (
          !this._isMounted ||
          isEmptyObjectOrFalsey(response) ||
          this.fetchId !== fetchId ||
          shouldSkipFetchEventsResponse(response)
        ) {
          return;
        }
        if (isEmptyArrayOrFalsey(response?.events)) {
          return;
        }
        formatFetchedEvents(flattenEventsToUserCalendar(response.events));
      };

      return Fetcher.post(url, payloadData, true, userEmail)
        .then(handleResponse)
        .catch((error) => {
          handleError(error);
        });
    };

    // call send request for each logged in user
    const {
      masterAccount,
    } = this.props.masterAccount;
    if (doesAllCalendarsContainOutlookCalendars(allCalendars) 
      && getActiveSelectedCalendars(allCalendars).length >= OUTLOOK_ACTIVE_CALENDARS_THROTTLE_AMOUNT
      && isUserMaestroUser(masterAccount)
    ) {
      // do not call this if there are too many calendars selected and on Vimcal EA account
      return;
    }

    allLoggedInUsers.forEach((user) => {
      if (shouldHideDelegatedUser({ user, allCalendars })) {
        return;
      }
      const matchingCalendarsForUser = getMatchingCalendarsForUser({allCalendars, user});
      const isActiveUser = Object.values(matchingCalendarsForUser).some(calendar => userCalendarIDs.includes(getCalendarUserCalendarID(calendar)));
      if (isActiveUser) {
        sendRequest(getUserEmail(user));
      }
    });
  }

  removeDateIfEmpty(index, date) {
    if (index[date]?.length === 0) {
      index = _.omit(index, date);
    }

    return index;
  }

  determineUpcomingEvents(indexByDay) {
    const index = isEmptyObjectOrFalsey(indexByDay) ? this.state.indexByDay : indexByDay;

    const {
      allCalendars,
    } = this.props.allCalendars;
    const {
      currentTimeZone,
      date,
      currentUser,
    } = this.props;
    const { nextEvent, events, upcomingEvents } = findUpcomingEvent({
      indexByDate: index,
      allCalendars,
      currentUserEmail: getUserEmail(currentUser),
      timeZone: currentTimeZone,
    });

    if (
      date &&
      !isSameDay(
        date,
        convertToTimeZone(new Date(), { timeZone: currentTimeZone })
      )
    ) {
      return {
        nextEvent,
        events: index[format(date, AGENDA_FORMAT_JS_DATE)] || [],
        upcomingEvents,
      };
    }

    return { nextEvent, events, upcomingEvents };
  }

  isDateToday() {
    return isSameDay(
      this.props.date,
      convertToTimeZone(new Date(), {
        timeZone: this.getCurrentTimeZone(),
      })
    );
  }

  openConferencingFromElectron(event, url) {
    const {
      currentUser,
    } = this.props;
    if (!url) {
      return;
    }

    if (
      isPhoneConferencingWhatsApp(currentUser) &&
      getPhoneNumberFromText(url, true)
    ) {
      setTimeout(() => {
        if (!this._isMounted) {
          return;
        }
        OpenLink(getPhoneNumberFromText(url, true));
      }, 1.5 * SECOND_IN_MS);
      return;
    }
    setTimeout(() => {
      if (!this._isMounted) {
        return;
      }
      openConferencingURL(url, getUserEmail(currentUser));
    }, 1.5 * SECOND_IN_MS);
  }

  setPreviewEvent(event) {
    const { allCalendars } = this.props.allCalendars;
    const matchingCalendar = allCalendars[getEventUserCalendarID(event)];

    if (!isCalendarSelected(matchingCalendar) && shouldTruncateRightHandPanel(this.props.hideRightHandSidebar)) {
      // need to toggle on calendar for event
      Broadcast.publish(
        "TOGGLE_SELECT_CALENDAR_WITH_CALENDAR_ID",
        getCalendarUserCalendarID(matchingCalendar)
      );
    }

    mainCalendarBroadcast.publish("SET_PREVIEW_EVENT", event);
  }

  sendUpcomingEvents(from) {
    if (from === HOVER_UPCOMING_EVENTS) {
      agendaBroadcast.publish("SET_HOVER_UPCOMING_EVENT", this.state.upcomingEvents);
    }
  }

  getUpcomingCalendarIDs() {
    const {
      masterAccount,
    } = this.props.masterAccount;
    const {
      allCalendars,
    } = this.props.allCalendars;
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    return getUpcomingCalendarUserCalendarIDs({
      masterAccount,
      allCalendars,
      allLoggedInUsers,
    });
  }

  getDefaultUserTimeZone() {
    const {
      masterAccount
    } = this.props.masterAccount;
    const {
      allLoggedInUsers
    } = this.props.allLoggedInUsers;
    return getTimeZoneForMenuBar({masterAccount, allLoggedInUsers});
  }

  getCurrentTimeZone() {
    const {
      currentTimeZone,
    } = this.props;
    return currentTimeZone || this.getDefaultUserTimeZone();
  }

  getUserCalendarIDFromEmail() {
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const { masterAccount } = this.props.masterAccount;
    const { allCalendars } = this.props.allCalendars;
    const {
      currentUser,
    } = this.props;
    return getUserCalendarIDFromEmail({
      email: getUserEmail(currentUser),
      allCalendars,
      allLoggedInUsers,
      masterAccount,
    });
  }

  loadDesktopBridge() {
    if (!isElectron()) {
      return;
    }
    if (!window?.vimcal) {
      if (this._desktopBridgeAttempt > 100) {
        return;
      }
      clearTimeout(this._setDesktopBridgeTimeout);
      this._desktopBridgeAttempt += 1;
      this._setDesktopBridgeTimeout = setTimeout(() => {
        if (!this._isMounted) {
          return;
        }
        this.loadDesktopBridge();
      }, SECOND_IN_MS * (0.1 * this._desktopBridgeAttempt));
      return;
    }
    this._desktopBridgeAttempt = 0;
    window.vimcal.onOpenConferencing &&
      window.vimcal?.onOpenConferencing(this.openConferencingFromElectron);
    window.vimcal.onClickEventNotification &&
      window.vimcal.onClickEventNotification(
        this.onClickNotificationElectron
      );

    if (isWindowsElectron()) {
      // to pick up next meeting for pcs
      window.vimcal.onJoinNextMeetingMainApp &&
        window.vimcal.onJoinNextMeetingMainApp(
          this.openNextEventConferencing
        );
    }
  }
}

function mapStateToProps(state) {
  let {
    selectedDay,
    currentPreviewedEvent,
    currentUser,
    currentTimeZone,
    format24HourTime,
    dateFieldOrder,
    weekStart,
    shouldShowTopBar,
    currentHoverEvent,
    selectedCalendarView,
    actionMode,
  } = state;

  return {
    selectedDay,
    currentPreviewedEvent,
    currentUser,
    currentTimeZone,
    format24HourTime,
    dateFieldOrder,
    weekStart,
    shouldShowTopBar,
    currentHoverEvent,
    selectedCalendarView,
    actionMode,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setAgendaDay: (event) => dispatch({ data: event, type: "SET_AGENDA_DAY" }),
  };
}

const withStore = (BaseComponent) => (props) => {
  // Fetch initial state
  const tabIDStore = useTabID();
  const allCalendars = useAllCalendars();
  const allLoggedInUsers = useAllLoggedInUsers();
  const masterAccount = useMasterAccount();
  const hideRightHandSidebar = useHideRightHandSidebar()
  const outlookCategoriesStore = useOutlookCategoriesStore();
  const appSettings = useAppSettings();

  return (
    <BaseComponent
      {...props}
      tabIDStore={tabIDStore}
      allCalendars={allCalendars}
      allLoggedInUsers={allLoggedInUsers}
      masterAccount={masterAccount}
      hideRightHandSidebar={hideRightHandSidebar}
      outlookCategoriesStore={outlookCategoriesStore}
      appSettings={appSettings}
    />
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStore(AgendaPanel))
);
