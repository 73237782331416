import React, { Component, createRef } from "react";
import { connect, batch } from "react-redux";
import { withRouter } from "react-router-dom";
import StyleConstants, {
  COMMAND_KEY,
  CONFERENCING_OPTIONS_OBJECT,
  MODAL_BLUR,
  PC_CONTROL_KEY,
  SECOND_IN_MS,
  ZOOM_CONFERENCING,
  getModalBackgroundColor,
} from "../../services/globalVariables";
import { EMOJI_SUMMARY_DICTIONARY } from "../../services/emoji";
import {
  EVENT_FORM_SUMMARY,
  EVENT_FORM_ALL_DAY,
  EVENT_FORM_START_DATE_INPUT,
  EVENT_FORM_START_TIME_INPUT,
  EVENT_FORM_END_DATE_INPUT,
  EVENT_FORM_END_TIME_INPUT,
  EVENT_FORM_REPEAT,
  EVENT_FORM_TIME_ZONE,
  EVENT_FORM_LOCATION,
  EVENT_FORM_CONFERENCE,
  EVENT_FORM_ROOM,
  EVENT_FORM_ATTENDEE,
  EVENT_FORM_GUESTS_CAN,
  EVENT_FORM_COLOR,
  EVENT_FORM_EMAIL,
  EVENT_FORM_NOTIFICATION,
  EVENT_FORM_BUSY_FREE,
  EVENT_FORM_DEFAULT_AVAILABILITY,
  EVENT_FORM_DESCRIPTION,
  eventFormSectionHotKeysIndex,
  HIGH_LIGHT_COLOR,
  WHITE_BUTTON,
  COMPONENT_NOTIFICATION,
  ISO_DATE_FORMAT,
  SET_DISAPPEARING_NOTIFICATION_MESSAGE,
} from "../../services/globalVariables";
import CustomSelect from "../select";
import GoogleCalendarService, {
  DEFAULT,
  PUBLIC,
  PRIVATE,
  DEFAULT_VISIBILITY,
  TEMPLATE,
  UPDATE_TEMPLATE,
  EDIT,
  CREATE_NEW,
  RECURRING_EVENT,
  VIMCAL_SIGNATURE,
  renderDefaultSignatureWithEmptyLinesAbove,
  createConferenceData,
  WEEKS_STRING,
  PHONE_NUMBER_CONFERENCE,
  WHATS_APP_STRING,
  createPhoneConferenceData,
  createWhatsAppConferenceData,
  createCustomConferencing,
  NO_CONFERENCE_STRING,
  DEFAULT_GOOGLE_DO_NOT_SEND_UPDATE,
  GOOGLE_UPDATES,
  OUT_OF_OFFICE_DURING_EVENT,
  OUT_OF_OFFICE_EVENT_TYPE,
  DEFAULT_EVENT_TYPE,
  BUSY_DURING_EVENT,
  OUT_OF_OFFICE_AUTO_DECLINE_NONE,
  OUT_OF_OFFICE_DEFAULT_DECLINE_MESSAGE,
  OUT_OF_OFFICE_AUTO_DECLINE_NEW,
  getVimcalPTagSignature,
  getVimcalRichTextSignature,
  DATE_TIME_12_HOUR_FORMAT,
  ZOOM_STRING,
} from "../../services/googleCalendarService";
import {
  backendConferenceValueFromHumanReadable,
  eventFormConferenceOptionFromBackend,
} from "../../services/googleCalendarHelpers";
import DottedLine from "../dottedLine";
import Classnames from "classnames";
import LightModeConferenceRoomDoorSVG from "../lightModeConferenceRoomDoorSVG";
import DarkModeConferenceRoomDoorSVG from "../darkModeConferenceRoomIcon";
import SelectConferenceRoom from "../selectConferenceRoom";
import {
  X,
  Plus,
  MapPin,
  Video,
  Briefcase,
  Smile,
  Users,
  Calendar,
  ChevronDown,
  Grid,
  Settings,
} from "react-feather";
import Fetcher from "../../services/fetcher";
import { constructRequestURL, constructRequestURLV2 } from "../../services/api";
import ApiClient from "../../services/apiClient";
import { rrulestr } from "rrule";
import { trackEvent, trackFeatureUsage } from "../tracking";
import ReactSelectAttendeeAutoComplete from "../reactSelectAttendeeAutoComplete";
import WarningUpdateRecurringEvent from "../warningUpdateRecurringEvent";
import EventFormDate from "../eventFormDate";
import {
  ValidateEventData,
  IsEventStartAfterEventEnd,
  HoursDifferenceBetweenTimeZones,
  CreateRecurrentObject,
  KEYCODE_ENTER,
  KEYCODE_K,
  KEYCODE_J,
  KEYCODE_SEMI_COLON,
  KEYCODE_ESCAPE,
  KEYCODE_F,
  KEYCODE_CONTROL_MAC,
  KEYCODE_CONTROL,
  isCommandKeyPressed,
  addAbbrevationToTimeZone,
  DaysDifferenceJSDate,
  removeDuplicatesFromArray,
  constructQueryParams,
  fetchEvent,
  getComponentLocation,
  convertDateIntoEpochUnixWeek,
  getFirstDayOfWeekJsDate,
  blurInputOnEscape,
  canDisplayAttendees,
  replaceVimcalSignature,
  isHangoutGSuiteIntegration,
  hasEventPreventDefault,
  calculateMarginTop,
  DoesAttendeesObjectHaveOtherAttendees,
  removeGoogleAutoGeneratedKeys,
  filterOutModalFromArray,
  determineScrollToHour,
  determineConferenceType,
  isEventConferencingZoom,
  handleError,
  determineRBCEventEndWithEventStart,
  hasStateOrPropsChanged,
  CombineDateAndTimeJSDate,
  guessTimeZone,
  getGoogleEventId,
  doesSentenceIncludeWord,
  getRRuleStringFromRecurrence,
  KEYCODE_BACKSPACE,
  KEYCODE_TAB,
  KEYCODE_UP_ARROW,
  KEYCODE_DOWN_ARROW,
  minDifferentBetweenTimeZones,
  isBeforeDay,
  isSameOrBeforeDay,
  getTimeInAnchorTimeZone,
  isBeforeMinute,
  RoundToClosestMinuteJSDate,
  isSameOrAfterDay,
  isSameOrAfterMinute,
  emojiAtBeginningOfString,
  isTemplateZoomConferencing,
  determineSyncWindow,
  formatTimeForBackendJsDate,
  isValidJSDate,
  constructEmailData,
  isTooltipCompleted,
  hasStopEventPropagation,
  omitNullOrUndefinedProps,
  GetConferenceURL,
  formatEventForReactBigCalendar,
  isAfterDay,
  stringIncludesZoomURLCombinations,
  getConferenceURLFromNative,
  localData,
} from "../../services/commonUsefulFunctions";
import parseHTML from "html-react-parser";
import {
  makeAllSuggestionsInactive,
  createTemplateSummaryIndex,
  getMatchingTemplates,
  pressUpSuggestion,
  getCurrentActiveIndex,
  pressDownSuggestions,
  getTransparencyOptions,
  EVENT_HAS_MODIFY_PERMISSION_AND_IS_NOT_AN_ORGANIZER,
  getLocalEventFormState,
  removeLocalEventFormState,
} from "../../services/sharedEventFormFunctions";
import CustomButton from "../customButton";
import DefaultRepeatOptions from "../defaultRepeatOptions";
import SelectGuestPermissions from "../selectGuestPermissions";
import LocationSearchInput from "../locationSearchInput";
import SelectEventTime from "../selectEventTime";
import SelectTimeZone from "../selectTimeZone";
import CloseButton from "../closeButton";
import EventModalPopup from "../eventModalPopup";
import SaveButton from "../saveButton";
import CustomRepeatOptions from "../customRepeatOptions";
import AddAdditionalReminders from "../addAdditionalReminders";
import db from "../../services/db";
import FullAttendeeList from "../fullAttendeeList";
import MonthlyCalendar from "../monthlyCalendar";
import EventReminder from "../eventReminder";
import _ from "underscore";
import Broadcast from "../../broadcasts/broadcast";
import SelectDuration from "../selectDuration";
import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import GoogleColors, {
  createColorValueAndLabel,
  createColorsListWithDefault,
  createColorsWithNickName,
  getBackgroundHexFromGoogleColorID,
  getMatchingColorFromID,
} from "../../services/googleColors";
import GlobalKeyMapTile from "../globalKeyMapTile";
import EventFormDescription from "../eventFormDescription";
import NlpInput from "../nlpInput";
import SendEmailUpdateModal from "../sendEmailUpdateModal";
import {
  addDays,
  addMinutes,
  isSameDay,
  startOfDay,
  set,
  startOfMinute,
  differenceInDays,
  format,
  differenceInMinutes,
  parseISO,
  isSameMinute,
  differenceInMilliseconds,
  setMinutes,
  add,
  subDays,
  subWeeks,
  parse,
  formatISO,
  getISODay,
  setHours,
  startOfHour,
  addHours,
} from "date-fns";
import {
  isDefaultZoomPersonalLink,
  getZoomPersonalLink,
  GENERIC_ZOOM_ERROR,
  REAUTH_ZOOM_ERROR,
  isValidConferencing,
  isTemplatePhoneConferencing,
  isTemplateWhatsAppConferencing,
  isTemplateCustomConferencing,
  isValidCustomConferencing,
  isValidPhoneConferencing,
  ZOOM,
  createOutlookUniqueZoomDescription,
  createOutlookPersonalLinkDescription,
  PHONE,
  isZoomFromGSuiteIntegration,
} from "../../lib/conferencing";
import ConferenceSelect from "./conferenceSelect";
import ConferenceStatus from "./conferenceStatus";
import EventFormConferenceAdditionalInfo from "./eventFormConferenceAdditionalInfo";
import MultipleConferenceWarning from "./multipleConferenceWarning";
import CircleWithColor from "../circleWithColor";
import { getConferenceRoomAvailability } from "../../lib/webWorkerFunctions";
import OnboardBroadcast from "../../broadcasts/onboardBroadcast";
import {
  useAllCalendars,
  useAllLoggedInUsers,
  useAllUserDomains,
  useMasterAccount,
  useZoomSchedulers,
} from "../../services/stores/SharedAccountData";
import {
  determineCalendarColor,
  getCurrentUserDefaultColor,
  getEmailFromUserCalendarID,
  getCalendarFromEmail,
  isValidCalendar,
  getCalendarName,
  isCalendarOutlookCalendar,
  getOutlookConferencingReactSelectOptions,
  getOutlookCalendarDefaultOnlineMeetingProvider,
  getCalendarUserEmail,
  isGoogle,
  isGoogleWorkspaceCalendar,
  doesCalendarHaveCategories,
} from "../../lib/calendarFunctions";
import {
  getMatchingExecOrNormalUserFromCalendar,
  getMatchingExecutiveUserFromCalendar,
  getZoomSchedulerID,
  isCalendarExecutiveCalendar,
  isMaestroUserOnDelegatedAccount,
  isUserBeingScheduledFor,
  isUserDelegatedUser,
  isUserMaestroUser,
} from "../../services/maestroFunctions";
import classNames from "classnames";
import DropdownIndicator from "../select/dropDownIndicator";
import TooltipBox from "../tooltips/tooltipBox";
import { NLP } from "../helpCenter/helpCenterVariables";
import { tooltipKeys } from "../../services/tooltipVariables";
import {
  createRecurrenceCutOffDate,
  isEventFirstRecurringInstance,
  resetOriginalRecurringEventIndex,
  updatedRecurrenceAfterEventStartChange,
} from "../../lib/recurringEventFunctions";
import { trimOriginalRecurrenceRule } from "../../lib/recurringEventFunctions";
import {
  getAttachmentId,
  getAttachmentName,
  getClientEventID,
  getEventAttendees,
  getEventConferenceData,
  getEventDescription,
  getEventExtendedProperties,
  getEventExtendedPropertiesPrivate,
  getEventICalUID,
  getEventMasterEventID,
  getEventOrganizer,
  getEventOriginalStartTime,
  getEventRawData,
  getEventStart,
  getEventTransparency,
  getEventUserCalendarID,
  getEventUserEventID,
  getGCalEventId,
  GUESTS_CAN_INVITE_OTHERS,
  GUESTS_CAN_MODIFY,
  GUESTS_CAN_SEE_OTHER_GUESTS,
  isAllDayOutlookEvent,
} from "../../services/eventResourceAccessors";
import {
  getCalendarVisibilityOptions,
  getEventEndTimeZone,
  getEventEndValue,
  getEventStartTimeZone,
  getEventStartValue,
  getResourceID,
  isConferenceDataZoom,
  isGoogleEvent,
  isOutlookEvent,
  isValidEvent,
} from "../../lib/eventFunctions";
import {
  getTagsFromTemplate,
  getTemplateConferenceData,
  getTemplateExtendedProperties,
  getTemplateGuestPermissions,
  getTemplateTitle,
  isEventTemplateAllDay,
  isZoomFromIntegrationTemplate,
} from "../../services/templateFunctions";
import {
  getCalendarDefaultReminders,
  getCalendarEmail,
  getCalendarProviderId,
  getCalendarUserCalendarID,
  getCalendarDefaultMeetingProvider,
  getCalendarIsPrimary,
  getCalendarColorID,
  getGoogleCalendarAllowedConferenceType,
} from "../../services/calendarAccessors";
import {
  EVENT_FORM_ATTENDEE_SCROLL_CONTAINER,
  EVENT_FORM_FIND_TIME_BUTTON_ID,
  EVENT_FORM_ID,
  EVENT_FORM_SAVE_BOTTON_STICKY_CONTAINER_ID,
  EVENT_FORM_SAVE_BUTTON_ID,
  EVENT_FORM_SCROLL_CONTAINER,
  EVENT_PANEL_WRAPPER_ID,
  NLP_INPUT_FIELD_NAME,
} from "../../services/elementIDVariables";
import { isVersionV2 } from "../../services/versionFunctions";
import {
  APP_SETTINGS,
  CALENDAR_PROVIDERS,
  CONFERENCING_SETTINGS_ID,
  REACT_ATTENDEE_SELECT_LOCATION,
} from "../../lib/vimcalVariables";
import {
  addDefaultOutlookDescriptionHTMLText,
  BACKEND_OUTLOOK_CONFERENCING,
  isOutlookConferencingOption,
  isOutlookUser,
  isTemplateOutlookConferencing,
  shouldGateExtendedProperties,
  stripeAllNewLines,
} from "../../lib/outlookFunctions";
import {
  getMasterAccountTemplates,
  getMatchingUserFromAllUsers,
  getUserEmail,
  getUserToken,
} from "../../lib/userFunctions";
import { customMenuStyle, getReactSelectBaseStyle } from "../select/styles";
import layoutBroadcast from "../../broadcasts/layoutBroadcast";
import {
  clearEventFormFocusSection,
  getEventFormFocusSection,
} from "../../lib/stateManagementFunctions";
import Tags from "../tags";
import { DISPLAY_LOCATION_EVENT_FORM } from "../tags/tagsVariables";
import eventFormBroadcast from "../../broadcasts/eventFormBroadcast";
import { isEventSlotAllDayEvent, isOnClickSlot } from "../../lib/rbcFunctions";
import { useTemporaryStateStore } from "../../services/stores/temporaryStateStores";
import {
  TAGS_LIMIT,
  generateMergedTagsAndSmartTags,
  getEventSmartTags,
  getMatchingTagUserForUserCalendarID,
  getMatchingUserAndTags,
  getTagColor,
  getTagColorId,
  getTagId,
  getTagsFromEvent,
  hasReachedTagsLimit,
  isPriorityTag,
  isTransparentTag,
} from "../../lib/tagsFunctions";
import TagsEmptyIcon from "../tags/icons/tagsEmptyIcon";
import {
  canEditZoomSettings,
  hasOutlookConferenceRoomFeatureFlag,
  hasPermissionToViewDistroList,
  isHubSpotUser,
  isWBUser,
  shouldDefaultFocusToTitleForEventForm,
  shouldDisplayColorLabel,
  shouldShowFindTimeEventForm,
} from "../../lib/featureFlagFunctions";
import TagsSuggestions from "./tagsSuggestions";
import {
  blurCalendar,
  isElementSticky,
  isGoogleUser,
  isModalOpen,
  isSchedulingAssistantEventPreviewShowing,
} from "../../services/appFunctions";
import { sanitizeStringAndLinkify } from "../../lib/jsVariables";
import { safeJSONStringify } from "../../lib/jsonFunctions";
import {
  getZoomSchedulers,
  getZoomSchedulersHostEmail,
} from "../../services/zoomFunctions";
import { addDefaultToArray, arrayContainSameItems, isEmptyArray } from "../../lib/arrayFunctions";
import FindTimeCapsule from "../availability/findTimeCapsule";
import {
  getZoomHostEmail,
  getZoomJoinURL,
  getZoomSchedulerEmails,
  isRecurringZoomMeeting,
  isZoomMeetingPersonalLink,
  stripOutZoomFromLocationAndDescription,
} from "../../lib/zoomFunctions";
import appBroadcast from "../../broadcasts/appBroadcast";
import MaestroZoomLoginInfo from "../modal/maestroZoomLoginInfo";
import { MAESTRO_ZOOM_MODAL_INFO_TYPE } from "../../lib/maestroFunctions";
import backendBroadcasts from "../../broadcasts/backendBroadcasts";
import DefaultSwitch from "../defaultSwitch";
import OutOfOfficeOptions from "./outOfOfficeOptions";
import { getBuildingIDFromRawObject, getConferenceRoomID, sortConferenceRooms } from "../../lib/conferenceRoomFunctions";
import {
  BROADCAST_VALUES,
  EVENT_FORM_BROADCAST_VALUES,
  MAIN_CALENDAR_BROADCAST_VALUES,
} from "../../lib/broadcastValues";
import mainCalendarBroadcast from "../../broadcasts/mainCalendarBroadcast";
import { parseOutlookAllDayEvent } from "../../lib/timeFunctions";
import { OUTLOOK_SHOW_AS } from "../../resources/outlookVariables";
import SelectCategories from "./selectCategories";
import {
  isEmptyArrayOrFalsey,
  isEmptyObjectOrFalsey,
  isNullOrUndefined,
  isTypeString,
} from "../../services/typeGuards";
import { OUTLOOK_COLORS } from "../../services/outlookColors";
import { useOutlookCategoriesStore } from "../../services/stores/outlookCategoriesStore";
import {
  capitalizeFirstLetter,
  equalAfterTrimAndLowerCased,
  getInputStringFromEvent,
  isValidEmail,
  isSameEmail,
  isUrl,
  lowerCaseAndTrimString,
  removePlusAndCapitalize,
  truncateString,
  formatEmail,
  lowerCaseAndTrimStringWithGuard,
  replaceEmptySpaceWithTextEmptyString,
  removeLeadingSpacesInTags,
  splitStringIntoEmails,
} from "../../lib/stringFunctions";
import { getDefaultHeaders } from "../../lib/fetchFunctions";
import { getObjectEmail } from "../../lib/objectFunctions";
import {
  determineDefaultGuestPermissions,
  getCustomConferencingName,
  getDefaultUserConferencing,
  getDefaultUserTimeZone,
  shouldAutoAddEmoji,
} from "../../lib/settingsFunctions";
import { fetchZoomMeetingByZoomId } from "../queries/zoomMeetings";
import ZoomSettings from "./zoomSettings/zoomSettings";
import {
  extractMeetingIdFromURL,
  getAuthedZoomEmail,
  isZoomMeetingPMI,
} from "./zoomSettings/sharedFunctions";
import { DiscardChangesContents } from "./discardChangesContents";
import { createUUID } from "../../services/randomFunctions";
import { GET_ROOM_AVAILABILITY, GOOGLE_DRIVE_ENDPOINTS } from "../../lib/endpoints";
import { useDistroListDictionary } from "../../services/stores/eventsData";
import { getDistroListGroupMembers, getMatchingDistroListFromEmails, getSubDistroLists, isDistroListEmail } from "../../lib/distroListFunctions";
import DidYouKnowAlert from "../availability/alerts/didYouKnowAlert";
import {
  createCalendarReactSelectOptions,
  filterForCalendarSelect,
} from "../select/helpFunctions";
import CustomSelectV2, { SELECT_VARIANTS } from "../select/selectV2";
import {
  determineDefaultModalStyle,
  getSchedulingAssistantModalStyles,
  MODAL_OVERLAY_Z_INDEXES,
} from "../../lib/modalFunctions";
import { fetcherPost } from "../../services/fetcherFunctions";
import { useConferenceRoomStore } from "../../services/stores/conferenceRoomStores";
import SchedulingAssistantContainer from "../scheduleAssistant/schedulingAssistantContainer";
import { WINDOW_SYNC_DAYS_OUT } from "../scheduleAssistant/helperFunctions";
import { getSidePoppedOverModalBorder, SCHEDULING_ASSISTANT_MODAL_WIDTH } from "../../lib/styleFunctions";
import { createEventFormTemporaryEvent } from "../../lib/temporaryEventFunctions";
import { getGuestAttendanceCounts, isAttendeeResource } from "../../services/attendeeFunctions";
import PillButton from "../buttons/pillButton";
import ExecutiveLabel from "../../../components/executiveLabel";
import GoogleDriveAttachments from "./googleDrive/attachmentsDropzone";
import GoogleDrivePermissionsModal, { GOOGLE_DRIVE_DONT_GIVE_ACCESS } from "./googleDrive/permissionsModal";
import { getAttendeeEmail } from "../../lib/groupSchedulingFunctions";
import { isLocal } from "../../services/devFunctions";

const IS_CREATE_NEW_COLOR_LABEL = "create-new-color-label";

// strings for modal in switch statement
const NLP_BAR = "NLP_bar";
const EMOJI = "emoji";
const EVENT_TIME_AND_DATE = "eventTimeAndDate";
const ADD_ADDTIONAL_REMINDERS = "AddAdditionalReminders";
const EVENT_REPEAT = "eventRepeat";
const START_DATE = "startDate";
const END_DATE = "endDate";
const START_TIME = "startTime";
const END_TIME = "endTime";
const TIME_ZONE = "timeZone";
const GUEST_PERMISSIONS = "GUEST_PERMISSIONS";
const ERROR = "eventContainsError";
const EMAIL_NOT_VALID_ERROR = "emailNotValid";
const DISCARD_CHANGES = "discard changes";
const EDIT_WITH_OTHER_GUEST_WARNING = "warning with other guests present";
const SUMMARY = "summary";
const LOCATION = "location";
const DESCRIPTION = "description";
const VIDEO_CONFERENCE = "videoConference";
const ATTENDEE = "newAttendee";
const CONFERENCE_ROOMS = "select conference rooms";
const UPDATE_RECURRING_EVENT_MODAL = "warning update recurring event";
const EVENT_FORM_START_DATE = "eventStartDate";
const EVENT_FORM_END_DATE = "eventEndDate";
const EMAIL_COLOR = "selectEmailColor";
const SELECT_EMAIL = "selectCalendar";
const SELECT_VISIBILITY = "selectVisibility";
const SELECT_BUSY_OR_FREE = "selectBusyOrFree";
const ICON_SIZE = "14";
const SELECT_END_DATE = "select-end-date";
const ZOOM_ERROR = "ZOOM_ERROR";
const CONFERENCE_SECTION = "conference-section";
const MAESTRO_ZOOM_LOGIN_INFO = "MAESTRO_ZOOM_LOGIN_INFO";
const ZOOM_STILL_LOADING_MODAL = "ZOOM_STILL_LOADING_MODAL";
const SCHEDULING_ASSISTANT_MODAL = "scheduling-assistant-modal";
const ATTACHMENTS_PERMISSIONS_MODAL = "ATTACHMENTS_PERMISSIONS_MODAL";

// Strings that are used in the UI
const FOCUS_GUARD = "focusguard";

const MARGIN_BETWEEN_BOXES = "mt-4";

const PROPERTY_FIELDS = {
  SUMMARY: "summary",
  ALL_DAY: "allDay",
  EVENT_START_TIME: "eventStartTime",
  EVENT_START_DATE: "eventStartDate",
  EVENT_START_TIME_ZONE: "startTimeZone",
  EVENT_END_TIME: "eventEndTime",
  EVENT_END_DATE: "eventEndDate",
  EVENT_END_TIME_ZONE: "endTimeZone",
  LOCATION: "location",
  CONFERENCE: "conference",
  ATTENDEES: "attendees",
  GUEST_PERMISSIONS: "guestPermissions",
  COLOR_ID: "eventColorId",
  CALENDAR: "calendar",
  DESCRIPTION: "description",
  REMINDERS: "reminders",
  ALL_DAY_REMINDERS: "allDayReminders",
  TRANSPARENCY: "transparency",
  VISIBILITY: "visibility",
};

// Strings from google
let {
  freeDuringEvent,
  busyDuringEvent,
  googleHangoutString,
  zoomString,
  noConferenceString,
  phoneNumberConference,
  modifyEventsString,
  inviteOthersString,
  seeGuestListString,
  selectTimeZone,
  temporary,
  attendee_event_declined,
  EDIT_RECURRING_INSTANCE_ONLY,
  EDIT_RECURRING_FOLLOWING_EVENTS,
  EDIT_RECURRING_ALL_INSTANCES,
} = GoogleCalendarService;

const defaultEventFormKeyMapTileStyle = {
  top: "-24px",
  left: "-20px",
  fontWeight: 300,
};
const defaultEventFormTimeAndDateKeyMapTileStyle = {
  top: "-20px",
  left: "0px",
  fontWeight: 300,
  zIndex: 1,
};

const TEMPLATE_SELECT_CALENDAR_LABEL = "Select Calendar";
const TEMPLATE_SELECT_CALENDAR_VALUE = "SELECT_CALENDAR";
const TEMPLATE_SELECT_CALENDAR_OPTION = {
  label: TEMPLATE_SELECT_CALENDAR_LABEL,
  value: TEMPLATE_SELECT_CALENDAR_VALUE,
};

class EventForm extends Component {
  constructor(props) {
    super(props);

    this.setUpInitialDataTimer = null;
    this._isCreatingUniqueZoom = false;

    this.addAttendeeRef = React.createRef();
    this._fetchRoomsFetchID = null;

    this.selectStartDateRef = React.createRef();
    this.selectStartTimeRef = React.createRef();

    this.selectEndTimeRef = React.createRef();
    this.selectEndDateRef = React.createRef();

    this._zoomTimer = null; // used to check for timeouts
    this._hasTriedCreatingUniqueZoomAlready = false;
    this._originalCalendarView = null;

    this.selectConferenceSection = React.createRef();
    this._initializedFocus = false;
    this._userExplicitlySetNoConferencing = false;
    this._paginationBuildingID = null;
    this._setTemporaryEventTimeout = null;

    let commonState = {
      shouldSetStartTime: false,
      shouldSetEndTime: false,
      shouldDisplayModal: false,
      modalWidth: 300,
      modalTitle: "Title",
      modalTop: "0px",
      modalContent: ADD_ADDTIONAL_REMINDERS,
      shouldDisplayEventRepeatCustomModal: false,
      errorMessage: "",
      lastTimeSet: new Date(),
      lastEscapeTimeStamp: new Date(),
      availableConferenceRooms: null,
      availableBuildings: null,
      customModalIsOn: false,
      warningEditWillOnlyReflectOnThisCalendarSection: null,
      originalDescription: null,
      hasChangedTransparency: false,
      shouldSendUpdate: true,
      modalArray: null,
      updateRepeatType: null,
      recurringHasChanged: false,
      hasTimeZoneAlreadyBeenSet:
        this.props.currentTimeZone !==
        (this.props.defaultBrowserTimeZone || guessTimeZone()),
      attendeeQueryText: "",
      templatesSummaryIndex: createTemplateSummaryIndex(getMasterAccountTemplates({ masterAccount: props.masterAccount.masterAccount })),
      knownTemplate: null,
      suggestions: [],
      refs: {},
      rerenderCount: 0,
      zoomMeetingError: null,
      shouldHideNLPTooltip: true,
      hasPressedAddDescription: true,
      conferencingSelectOptions: [],
      calendarSelectOptions: this.createCalendarReactSelectOptions(), // set it in state, otherwise using arrows to navigate becomes pain in the behind
      isAttendeesDropdownOpen: false,
      wasZoomUpdated: false,
      zoomMeeting: null,
      isNewZoomMeeting: false,
      errorFetchingZoomMeeting: false,
      isFetchingRoomsAvailability: false,
      warningToDeleteOutlookZoomDescription: false, // outlook zomo description can have weird formatting. If user tries to delete conferencing (set to no conferencing, we should tell them to also delete the string from outlook if it still exists)
      isSubmittingDrivePermissions: false,
    };

    let state = _.clone(this.props.initialState);

    // TODO: Should we update initial state at the source, or would that break other things?
    // This bug only seems to affect Outlook. Other conferencing options may also be affected.
    if (state.conference === CONFERENCING_OPTIONS_OBJECT[ZOOM_CONFERENCING]) {
      state.conference = ZOOM;
    }
    if (
      state.originalState?.conference ===
      CONFERENCING_OPTIONS_OBJECT[ZOOM_CONFERENCING]
    ) {
      state.originalState.conference = ZOOM;
    }

    const localEventState = getLocalEventFormState();
    /* Description is uncontrolled so we initialize state with the description */
    if (!isEmptyObjectOrFalsey(localEventState)) {
      /* Clear the stored state so event doesn't keep opening */
      removeLocalEventFormState();
      this.state = localEventState;
    } else {
      this.state = Object.assign(state, commonState);
    }

    this.setEventStartDate = this.setEventStartDate.bind(this);
    this.setEventStartTime = this.setEventStartTime.bind(this);
    this.setEventEndTime = this.setEventEndTime.bind(this);
    this.setGuestPermissions = this.setGuestPermissions.bind(this);
    this.setRepeat = this.setRepeat.bind(this);
    this.setAdditionalReminder = this.setAdditionalReminder.bind(this);
    this.removeReminder = this.removeReminder.bind(this);
    this.addAttendees = this.addAttendees.bind(this);
    this.removeAttendeeFromAttendeeList =
      this.removeAttendeeFromAttendeeList.bind(this);
    this.toggleDisplayModalState = this.toggleDisplayModalState.bind(this);
    this.setModalContent = this.setModalContent.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
    this.setEventTimeZone = this.setEventTimeZone.bind(this);
    this.exitEditView = this.exitEditView.bind(this);
    this.onPressExit = this.onPressExit.bind(this);
    this.updateKeyMap = this.updateKeyMap.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.setConference = this.setConference.bind(this);
    this.shouldDisplayDifferentTimeZoneWarning =
      this.shouldDisplayDifferentTimeZoneWarning.bind(this);
    this.goToStartTime = this.goToStartTime.bind(this);
    this.resetLatestFocus = this.resetLatestFocus.bind(this);
    this.goToSummary = this.goToSummary.bind(this);
    this.goToSaveButton = this.goToSaveButton.bind(this);
    this.goToDescription = this.goToDescription.bind(this);
    this.goToLocation = this.goToLocation.bind(this);
    this.goToAttendeeList = this.goToAttendeeList.bind(this);
    this.goToEndTime = this.goToEndTime.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.focusField = this.focusField.bind(this);
    this.onClickEventRepeat = this.onClickEventRepeat.bind(this);
    this.onClickStartDate = this.onClickStartDate.bind(this);
    this.onClickStartTime = this.onClickStartTime.bind(this);
    this.onClickEndDate = this.onClickEndDate.bind(this);
    this.shouldSetEndTime = this.shouldSetEndTime.bind(this);
    this.onClickTimeZoneIconText = this.onClickTimeZoneIconText.bind(this);
    this.onClickSelectCustomRepeat = this.onClickSelectCustomRepeat.bind(this);
    this.selectConferenceRoom = this.selectConferenceRoom.bind(this);
    this.setConferenceRoom = this.setConferenceRoom.bind(this);
    this.removeSelectedRoom = this.removeSelectedRoom.bind(this);
    this.removeNonEditableSelectedRoom =
      this.removeNonEditableSelectedRoom.bind(this);
    this.attendeeAlreadyContainOwnEmail =
      this.attendeeAlreadyContainOwnEmail.bind(this);
    this.updateRecurringEventResponse =
      this.updateRecurringEventResponse.bind(this);
    this.updateFollowingRecurringInstances =
      this.updateFollowingRecurringInstances.bind(this);
    this.createEvent = this.createEvent.bind(this);
    this.updateEvent = this.updateEvent.bind(this);
    this.isEqualToCurrentTimeZone = this.isEqualToCurrentTimeZone.bind(this);
    this.createTemplate = this.createTemplate.bind(this);
    this.setBusyOrFree = this.setBusyOrFree.bind(this);
    this.setVisibility = this.setVisibility.bind(this);
    this.openGuestPermissionsModal = this.openGuestPermissionsModal.bind(this);
    this.onClickAddReminder = this.onClickAddReminder.bind(this);
    this.onFocusElement = this.onFocusElement.bind(this);
    this.onClickEmojiPicker = this.onClickEmojiPicker.bind(this);
    this.onSelectEmojiInTitle = this.onSelectEmojiInTitle.bind(this);
    this.isEventTimeAndDateInvalid = this.isEventTimeAndDateInvalid.bind(this);
    this.setTemporaryEvents = this.setTemporaryEvents.bind(this);
    this.updateTimeAndDate = this.updateTimeAndDate.bind(this);
    this.fetchAvailableRooms = this.fetchAvailableRooms.bind(this);
    this.updatedAvailableRoomsAndUpdateTemporaryEvent =
      this.updatedAvailableRoomsAndUpdateTemporaryEvent.bind(this);
    this.scrollToTime = this.scrollToTime.bind(this);
    this.onTitleKeyDown = this.onTitleKeyDown.bind(this);
    this.onHitEscape = this.onHitEscape.bind(this);
    this.setPopUpTime = this.setPopUpTime.bind(this);
    this.createExistingAttendeeList =
      this.createExistingAttendeeList.bind(this);
    this.isTemplate = this.isTemplate.bind(this);
    this.loadInitialData = this.loadInitialData.bind(this);
    this.goToEventFormSection = this.goToEventFormSection.bind(this);
    this.setEventColor = this.setEventColor.bind(this);
    this.setCalendar = this.setCalendar.bind(this);
    this.setDuration = this.setDuration.bind(this);
    this.goToEndDate = this.goToEndDate.bind(this);
    this.setEventEndDate = this.setEventEndDate.bind(this);
    this.setLocation = this.setLocation.bind(this);
    this.onBlurDescription = this.onBlurDescription.bind(this);
    this.onFocusCaptureSummary = this.onFocusCaptureSummary.bind(this);
    this.onBlurCaptureSummary = this.onBlurCaptureSummary.bind(this);
    this.onFocusSection = this.onFocusSection.bind(this);
    this.removeWarningEditsWillOnlyReflectOnThisCalendar =
      this.removeWarningEditsWillOnlyReflectOnThisCalendar.bind(this);
    this.onBlurEventTimeOrDate = this.onBlurEventTimeOrDate.bind(this);
    this.updateAllRecurringInstances =
      this.updateAllRecurringInstances.bind(this);
    this.onFocusDescription = this.onFocusDescription.bind(this);
    this.onClickEscapeDescription = this.onClickEscapeDescription.bind(this);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.removeArrayOfAttendees = this.removeArrayOfAttendees.bind(this);
    this.removeEmoji = this.removeEmoji.bind(this);
    this.processDataOnClickSave = this.processDataOnClickSave.bind(this);
    this.removeEventFormOverLay = this.removeEventFormOverLay.bind(this);
    this.onBlurNlpBar = this.onBlurNlpBar.bind(this);
    this.onFocusNlpBar = this.onFocusNlpBar.bind(this);
    this.goToColor = this.goToColor.bind(this);
    this.setConferenceAndBlur = this.setConferenceAndBlur.bind(this);
    this.setValidAddressWarning = this.setValidAddressWarning.bind(this);
    this.updateAttendeeQuery = this.updateAttendeeQuery.bind(this);
    this.updateOriginalEvent = this.updateOriginalEvent.bind(this);
    this.onMouseLeaveSuggestion = this.onMouseLeaveSuggestion.bind(this);
    this.scrollToOption = this.scrollToOption.bind(this);
    this.setAllDay = this.setAllDay.bind(this);
    this.applyTemplateToEvent = this.applyTemplateToEvent.bind(this);
    this.openSelectCalendarModal = this.openSelectCalendarModal.bind(this);
    this.handleFocusOnTransition = this.handleFocusOnTransition.bind(this);
    this.onClickMainCalendarSlot = this.onClickMainCalendarSlot.bind(this);
    this.determineAttendeeToAddress =
      this.determineAttendeeToAddress.bind(this);
    this.onClickFindTime = this.onClickFindTime.bind(this);
    this.onClickZoomLogin = this.onClickZoomLogin.bind(this);
    this.onLoginZoom = this.onLoginZoom.bind(this);
    this.uptickRerenderCount = this.uptickRerenderCount.bind(this);
    this.onUserSetConferencing = this.onUserSetConferencing.bind(this);
    this.closeZoomSettingsForm = this.closeZoomSettingsForm.bind(this);
    this.pasteContentIntoField = this.pasteContentIntoField.bind(this);
    this.setEventDateAndTime = this.setEventDateAndTime.bind(this);
    this.isSelectCalendarOption = this.isSelectCalendarOption.bind(this);
    this.setAttachments = this.setAttachments.bind(this);

    Broadcast.subscribe("GO_TO_START_TIME", this.goToStartTime);
    Broadcast.subscribe("GO_TO_TITLE", this.goToSummary);
    Broadcast.subscribe("GO_TO_DESCRIPTION", this.goToDescription);
    Broadcast.subscribe("GO_TO_LOCATION", this.goToLocation);
    Broadcast.subscribe("GO_TO_ATTENDEE_LIST", this.goToAttendeeList);
    Broadcast.subscribe("GO_TO_COLOR", this.goToColor);
    Broadcast.subscribe("FOCUS_FIELD", this.focusField);
    Broadcast.subscribe("CHANGE_EDIT_FORM_TIME", this.updateTimeAndDate);
    Broadcast.subscribe("EVENT_FORM_ESCAPE", this.onHitEscape);
    Broadcast.subscribe("EVENT_FORM_APPLY_TEMPLATE", this.applyTemplateToEvent);
    Broadcast.subscribe(
      "EVENT_FORM_APPLY_TEXT_TEMPLATE",
      this.pasteContentIntoField
    );
    Broadcast.subscribe("GO_TO_EVENT_FORM_SECTION", this.goToEventFormSection);
    Broadcast.subscribe("SET_EVENT_CONFERENCE", this.setConferenceAndBlur);
    Broadcast.subscribe(
      "UPDATE_EVENT_FORM_ORIGINAL_EVENT",
      this.updateOriginalEvent
    );
    this.addTransitionedListener();
    eventFormBroadcast.subscribe("EXIT_EVENT_FORM", this.onPressExit);
    eventFormBroadcast.subscribe(
      EVENT_FORM_BROADCAST_VALUES.ON_CLICK_MAIN_CALENDAR_SLOT_WITH_OPEN_EVENT_FORM,
      this.onClickMainCalendarSlot
    );
    eventFormBroadcast.subscribe(
      "FIND_TIME_INSIDE_EVENT_FORM",
      this.onClickFindTime
    );
    eventFormBroadcast.subscribe(
      "UPTICK_RERENDER_COUNT",
      this.uptickRerenderCount
    );
    eventFormBroadcast.subscribe(
      EVENT_FORM_BROADCAST_VALUES.FETCH_AVAILABLE_ROOMS,
      this.fetchAvailableRooms
    );
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return hasStateOrPropsChanged(
      this.state,
      nextState,
      this.props,
      nextProps,
      ["match", "location", "currentPreviewedEvent"],
      false
    );
  }

  componentDidMount() {
    this._isMounted = true;
    this.props.onRef(this);

    const { zoomMeeting } = this.state;
    const { masterAccount } = this.props.masterAccount;

    const element = document.getElementById(EVENT_PANEL_WRAPPER_ID);
    // Get the computed style of the element
    if (getComputedStyle && element) {
      const computedStyle = getComputedStyle(element);
      // Get the visibility property value
      const visibility = computedStyle?.visibility;

      // Check if the element is visible
      if (visibility === "visible") {
        this.handleFocusOnTransition();
      }
      // else focus with addTransitionedListener()
    }

    // create unique is conferencing set to zoom (e.g.duplicate)
    if (
      this.state.conference === zoomString &&
      !this.isDefaultZoomPersonalLink() &&
      !zoomMeeting &&
      this.isDuplicateEvent() // we only want to create new zoom if it's duplicate event, not if it's a "copy to xCalendar"
    ) {
      this.createUniqueZoomLink();
    } else if (
      this.state.conference === zoomString &&
      !this.isDefaultZoomPersonalLink() &&
      !zoomMeeting &&
      this.state.attendees?.length > 0 &&
      this.isCreateNew()
    ) {
      // new event with attendees and conferencing is zoom
      this.createUniqueZoomLink();
    }

    this.setState({
      conferencingSelectOptions: this.getConferencingSelectOptions(),
    });

    if (this.props.lastEventFormState) {
      // restore previous state, do nothing here -> no need to load initial data
    } else if (this.isEditSingleEvent()) {
      this.loadInitialData();
    } else {
      this.setUpInitialDataTimer = setTimeout(() => {
        if (!this._isMounted) {
          return;
        }

        this.loadInitialData();
      }, 500);
    }

    /* TODO: Fix to fire only one request. */
    /* Component mounts before the broadcast is subscribed */
    if (!isTooltipCompleted(masterAccount, tooltipKeys.NLP)) {
      this.setState({
        shouldHideNLPTooltip: false,
      });
      Broadcast.publish("MARK_TOOLTIP_COMPLETED", tooltipKeys.NLP);
    }

    document.addEventListener("keydown", this.handleKeyDown, false);
    document.addEventListener("keyup", this.handleKeyUp, false);
    const { resetBookingLinkState, bookingLinkState } =
      this.props.temporaryStateStore;
    if (!isEmptyObjectOrFalsey(bookingLinkState)) {
      resetBookingLinkState();
    }

    this.fetchZoomMeeting();
  }

  componentDidUpdate(prevProps, prevState) {
    if (document?.activeElement?.id === "top-tab-rail-bar") {
      this.goToDescription();
    } else if (document?.activeElement?.id === FOCUS_GUARD) {
      // cycle back to beginning for tab
      if (this.shouldDisplayNlpInput()) {
        this.goToNLPInput();
      } else {
        this.goToSummary();
      }
    } else if (document?.activeElement?.className === "ql-container ql-snow") {
      if (prevState.latestFocus === DESCRIPTION) {
        this.goToSelectEmailSection();
      } else {
        this.goToDescription();
      }
    }

    this.scrollUpComponent();

    if (!_.isEqual(prevProps.popupEvent, this.props.popupEvent)) {
      this.setState({ lastEscapeTimeStamp: new Date() });
    }

    if (
      this.state.calendar !== prevState.calendar ||
      this.state.conference !== prevState.conference
    ) {
      this.setState({
        conferencingSelectOptions: this.getConferencingSelectOptions(),
      });
    }

    // For Google calendars, you cannot create an out of office event on your non-primary calendar or on
    // non-Workspace accounts.
    if (
      this.state.calendar !== prevState.calendar &&
      this.state.transparency === OUT_OF_OFFICE_DURING_EVENT &&
      this.isGoogleCalendar() &&
      (!this.isPrimaryCalendar() ||
        !isGoogleWorkspaceCalendar(this.state.calendar))
    ) {
      this.setState({ transparency: BUSY_DURING_EVENT });
    }

    if (
      this.state.calendar !== prevState.calendar &&
      this.state.transparency === OUTLOOK_SHOW_AS.TENTATIVE &&
      this.isGoogleCalendar()
    ) {
      // if outlook show as -> set to busy
      this.setState({ transparency: BUSY_DURING_EVENT });
    }

    // Reset these values to the initial state if the event can no longer auto-decline.
    if (
      (this.state.calendar !== prevState.calendar &&
        !this.isGoogleCalendar()) ||
      (this.state.transparency !== prevState.transparency &&
        prevState.transparency === OUT_OF_OFFICE_DURING_EVENT)
    ) {
      this.setState({
        outOfOfficeAutoDeclineMode: OUT_OF_OFFICE_AUTO_DECLINE_NEW,
        outOfOfficeDeclineMessage: OUT_OF_OFFICE_DEFAULT_DECLINE_MESSAGE,
      });
    }

    // Set the default decline meeting message if events are being declined and a message is not already set.
    if (
      this.state.outOfOfficeAutoDeclineMode !==
        OUT_OF_OFFICE_AUTO_DECLINE_NONE &&
      prevState.outOfOfficeAutoDeclineMode ===
        OUT_OF_OFFICE_AUTO_DECLINE_NONE &&
      !this.state.outOfOfficeDeclineMessage
    ) {
      this.setState({
        outOfOfficeDeclineMessage: OUT_OF_OFFICE_DEFAULT_DECLINE_MESSAGE,
      });
    }

    if (this.state.newlyAddedAttendees !== prevState.newlyAddedAttendees) {
      this.getDistroListForEvent();
    }
  }

  componentWillUnmount() {
    clearTimeout(this._setTemporaryEventTimeout);
    if (!this._hasCalledHandleExitView) {
      this.exitEditView({
        addEventBackIntoWeeklyCalendar: true,
        removeTemporaryEvent: true,
        skipSetHandleExitViewState: true,
      });
    }

    const { resetFindTimeData } = this.props.temporaryStateStore;
    resetFindTimeData();

    this.props.onRef(undefined);

    if (this.setUpInitialDataTimer) {
      clearTimeout(this.setUpInitialDataTimer);

      this.setUpInitialDataTimer = null;
    }

    if (this._zoomTimer) {
      clearTimeout(this._zoomTimer);
      this._zoomTimer = null;
    }

    this._isMounted = false;

    clearTimeout(this._saveTimeout);
    this._saveTimeout = null;

    document.removeEventListener("keydown", this.handleKeyDown, false);
    document.removeEventListener("keyup", this.handleKeyUp, false);

    this.clearComponentHasChangedNotification();
    this.removeListener();

    Broadcast.unsubscribe("GO_TO_START_TIME");
    Broadcast.unsubscribe("GO_TO_TITLE");
    Broadcast.unsubscribe("GO_TO_DESCRIPTION");
    Broadcast.unsubscribe("GO_TO_LOCATION");
    Broadcast.unsubscribe("GO_TO_ATTENDEE_LIST");
    Broadcast.unsubscribe("GO_TO_COLOR");
    Broadcast.unsubscribe("FOCUS_FIELD");
    Broadcast.unsubscribe("CHANGE_EDIT_FORM_TIME");
    Broadcast.unsubscribe("EVENT_FORM_ESCAPE");
    Broadcast.unsubscribe("EVENT_FORM_APPLY_TEMPLATE");
    Broadcast.unsubscribe("EVENT_FORM_APPLY_TEXT_TEMPLATE");
    Broadcast.unsubscribe("GO_TO_EVENT_FORM_SECTION");
    Broadcast.unsubscribe("SET_EVENT_CONFERENCE");
    Broadcast.unsubscribe("UPDATE_EVENT_FORM_ORIGINAL_EVENT");
    eventFormBroadcast.unsubscribe("EXIT_EVENT_FORM");
    eventFormBroadcast.unsubscribe(
      EVENT_FORM_BROADCAST_VALUES.ON_CLICK_MAIN_CALENDAR_SLOT_WITH_OPEN_EVENT_FORM
    );
    eventFormBroadcast.unsubscribe("FIND_TIME_INSIDE_EVENT_FORM");
    eventFormBroadcast.unsubscribe("UPTICK_RERENDER_COUNT");
    eventFormBroadcast.unsubscribe(
      EVENT_FORM_BROADCAST_VALUES.FETCH_AVAILABLE_ROOMS
    );
  }

  render() {
    const { isEditingZoomSettings, isOnboarding, zoomMeeting } = this.state;
    if (isOnboarding) {
      return this.renderOnboardingEventForm();
    }

    const { schedulers } = this.props.zoomSchedulers;

    if (isEditingZoomSettings && this.canEditZoomSettingsForHost()) {
      return (
        <ZoomSettings
          authedZoomEmail={getAuthedZoomEmail(
            schedulers,
            getZoomHostEmail(zoomMeeting),
          )}
          onClose={this.closeZoomSettingsForm}
          zoomMeeting={zoomMeeting}
        />
      );
    }

    return (
      <div id={EVENT_FORM_ID} className="event-form-wrapper">
        <div tabIndex={1} id="top-tab-rail-bar"></div>
        {this.renderPinnedHeader()}
        <div className="relative h-full">
          <div
            id={EVENT_FORM_SCROLL_CONTAINER}
            className="event-form-wrapper-underneath-title"
            style={{
              overflowY:
                !this.props.isDarkMode && this.state.shouldDisplayOverlay
                  ? "hidden"
                  : null,
              height: "auto",
              maxHeight: this.getContentContainerHeight(),
            }}
          >
            {this.shouldDisplayNlpInput() && this.renderTitle()}

            {this.shouldDisplayNlpInput() && (
              <div className="event-form-title-and-time-separator"></div>
            )}

            {this.renderEventTimeAndDate()}

            {this.renderLocationAndConference()}

            {this.renderAttendeesList()}

            {this.renderEmailAndColor()}

            {this.isOutlookCalendar() ? null : this.renderRemindersSection()}

            {this.shouldRenderFullDescription()
              ? null
              : this.renderAddDescriptionButton()}
            {this.renderGoogleAttachments()}
            {this.renderDescription(this.shouldRenderFullDescription())}
            <div
              className="w-full"
              id={FOCUS_GUARD}
              tabIndex={15}
              style={{ height: this.getBottomSafeGuardHeight() }}
            />
            {this.renderModal()}
          </div>
          {this.renderOpaqueOverlay()}
          {this.renderSaveButton()}
        </div>
      </div>
    );
  }

  //================
  // RENDER METHODS
  //================

  renderWarningWillOnlyBeReflectedOnCalendar(section, style = {}) {
    const getCopy = () => {
      if (this.canModifyButNotOrganizer() && this.isGoogleCalendar()) {
        return `Due to a Google API limitation, changes will be reflected only on this calendar: ${this.getCalendarName()}`;
      }
      return `Changes will be reflected only on this calendar: ${this.getCalendarName()}`;
    };

    return (
      <div
        className="warning-changes-only-reflected-on-this-calendar"
        style={Object.assign(
          {
            display:
              section ===
              this.state.warningEditWillOnlyReflectOnThisCalendarSection
                ? "block"
                : "none",
          },
          style
        )}
      >
        {getCopy()}
      </div>
    );
  }

  renderOnboardingEventForm() {
    return (
      <div id={EVENT_FORM_ID} className="event-form-wrapper">
        <div tabIndex={1} id="top-tab-rail-bar"></div>

        {this.renderPinnedHeader()}

        <div
          className="event-form-wrapper-underneath-title"
          style={{
            overflowY:
              !this.props.isDarkMode && this.state.shouldDisplayOverlay
                ? "hidden"
                : null,
            height: this.state.isOnboarding
              ? "500px"
              : `calc(100vh - ${
                  50 + calculateMarginTop(this.props.shouldShowTopBar)
                }px)`,
          }}
        >
          {this.renderOpaqueOverlay()}

          {this.shouldDisplayNlpInput() && this.renderTitle()}

          {this.shouldDisplayNlpInput() && (
            <div className="event-form-title-and-time-separator"></div>
          )}

          {this.renderEventTimeAndDate()}

          <DottedLine style={this.determineDateAndTimeSeparatorStyle()} />

          {this.renderLocationAndConference()}

          <DottedLine />

          {this.renderAttendeesList()}
        </div>
      </div>
    );
  }

  // otherwise the overlay gets cut off at the top
  renderOpaqueOverlay() {
    if (this.props.isDarkMode || !this.state.shouldDisplayOverlay) {
      return null;
    }
    return (
      <div
        className="event-form-over-lay"
        onClick={this.removeEventFormOverLay}
      ></div>
    );
  }

  summaryWithoutEmoji() {
    let emoji = emojiAtBeginningOfString(this.state.summary);

    return emoji ? this.state.summary.split(emoji)[1] : this.state.summary;
  }

  renderNLPBar() {
    return (
      <>
        <div
          className="position-relative"
          style={{ marginTop: calculateMarginTop(this.props.shouldShowTopBar) }}
        >
          <NlpInput
            onSelectEmoji={this.onSelectEmojiInTitle}
            focusRef={(input) => {
              this.nlpBar = input;
            }}
            id={NLP_BAR}
            autoFocus={true}
            tabIndex={2}
            value={""}
            removeEmoji={this.removeEmoji}
            setSummary={this.onChangeTitle}
            summary={this.state.summary}
            setLocation={this.setLocation}
            setConference={this.setConference}
            setAttendees={this.addAttendees}
            onPressEscape={this.onPressEscape}
            setStartDate={this.setEventStartDate}
            setStartTime={this.setEventStartTime}
            setEndDate={this.setEventEndDate}
            setEndTime={this.setEventEndTime}
            removeAttendee={this.removeAttendeeFromAttendeeList}
            removeListOfAttendees={this.removeArrayOfAttendees}
            createdFromDrag={!!this.state.selectedTimeSlot}
            initialStartTime={this.state.eventStartTime}
            initialStartDate={this.state.eventStartDate}
            initialEndTime={this.state.eventEndTime}
            initialEndDate={this.state.eventEndDate}
            initialStartAndEndTimeDiff={
              this.state.timeDifferenceBetweenEventStartEnd
            }
            initialTimeZone={this.state.startTimeZone}
            onBlur={this.onBlurNlpBar}
            onFocus={this.onFocusNlpBar}
            setEventTimeZone={this.setEventTimeZone}
            writableCalendars={this.state.writableCalendars || []}
            setCalendar={this.setCalendar}
            calendar={this.state.calendar}
            setAllDay={this.setAllDay}
            isAllDay={this.state.allDay}
            selectedUser={this.getUser()}
          />

          {this.renderCloseButton()}
        </div>
        {this.renderNLPTip()}
      </>
    );
  }

  renderCloseButton() {
    return (
      <div
        id="event-form-close-button"
        className="event-form-close-button"
        style={this.shouldDisplayNlpInput() ? {} : { right: 8 }}
      >
        <CloseButton size={18} onClick={this.onPressExit} />
      </div>
    );
  }

  renderSaveButton() {
    return (
      <div
        className={classNames(
          "sticky bottom-0",
          "event-form-save-button-container"
        )}
        id={EVENT_FORM_SAVE_BOTTON_STICKY_CONTAINER_ID}
      >
        <SaveButton
          width={330}
          className="event-form-save-button"
          style={this.saveButtonStyle()}
          onClick={this.onClickSave}
          buttonText={this.state.saveButtonText}
          buttonId={EVENT_FORM_SAVE_BUTTON_ID}
          shortcut={`${this.props.isMac ? COMMAND_KEY : PC_CONTROL_KEY} Enter`}
          tabIndex={14}
          focusRef={(input) => {
            this.saveButton = input;
          }}
        />
      </div>
    );
  }
  renderPinnedHeader() {
    return this.shouldDisplayNlpInput()
      ? this.renderNLPBar()
      : this.renderTitle();
  }

  renderTitle() {
    const emoji = emojiAtBeginningOfString(this.state.summary);

    const determineInputWidth = () => {
      if (this.props.isMobileView) {
        return "100%";
      }
      return this.shouldDisplayNlpInput() ? 300 : 275;
    };

    return (
      <div
        className="event-form-title"
        style={{
          marginTop: calculateMarginTop(
            this.props.shouldShowTopBar && !this.shouldDisplayNlpInput(),
            0,
            !this.shouldDisplayNlpInput()
          ),
          backgroundColor: this.shouldHighLightField()
            ? HIGH_LIGHT_COLOR
            : null,
          paddingLeft: this.shouldDisplayNlpInput() ? 0 : 12,
          position: "relative",
        }}
      >
        {!this.checkMode(TEMPLATE) && !this.checkMode(UPDATE_TEMPLATE) && (
          <div style={this.eventHeaderColor()}>|</div>
        )}

        <span
          id="emoji"
          style={this.constructEmojiIconStyle()}
          className="clickable-icon"
          onClick={this.onClickEmojiPicker}
        >
          <span className="font-size-18 ml-1.5">
            {emoji || <Smile size="18" />}
          </span>
        </span>

        <GlobalKeyMapTile
          style={defaultEventFormKeyMapTileStyle}
          shortcut={removePlusAndCapitalize(
            eventFormSectionHotKeysIndex[EVENT_FORM_SUMMARY]
          )}
        />

        <input
          ref={(input) => {
            this.summary = input;
          }}
          id={SUMMARY}
          className={classNames(
            "event-form-title-and-close-input",
            this.summaryNotification || ""
          )}
          tabIndex={3}
          value={this.summaryWithoutEmoji()}
          style={{
            backgroundColor: this.shouldHighLightField()
              ? HIGH_LIGHT_COLOR
              : null,
            width: determineInputWidth(),
            color: this.shouldHighLightField()
              ? StyleConstants.defaultFontColor
              : null,
          }}
          onChange={(e) => this.onChangeTitle(getInputStringFromEvent(e))}
          onKeyDown={this.onTitleKeyDown}
          onBlurCapture={this.onBlurCaptureSummary}
          onFocusCapture={this.onFocusCaptureSummary}
          autoComplete="off"
          autoFocus={false}
          autoCorrect="off"
          autoCapitalize="none"
          spellCheck="false"
          // placeholder="Title"
        />

        {this.renderSuggestions()}

        {!this.shouldDisplayNlpInput() && this.renderCloseButton()}

        {this.renderWarningWillOnlyBeReflectedOnCalendar(SUMMARY, {
          top: 50,
          left: 50,
        })}
      </div>
    );
  }

  renderSuggestions() {
    let suggestions = this.state.suggestions;

    return this.state.latestFocus === SUMMARY && suggestions.length > 0 ? (
      <div
        className="autocomplete-dropdown-container margin-top-ten"
        style={{
          marginLeft: 11,
          maxHeight: 180,
          overflowY: "auto",
          top: 40,
          left: -10,
        }}
      >
        <div
          className="location-suggestion-item"
          style={{ width: 330, fontWeight: 400 }}
        >
          Templates
        </div>

        {suggestions.map((suggestion) => {
          return (
            <div
              className="location-suggestion-item"
              style={
                suggestion.active
                  ? {
                      backgroundColor: this.props.isDarkMode
                        ? StyleConstants.darkModeHoverBackgroundColor
                        : "#F2F3F4",
                      width: 330,
                    }
                  : { width: 330 }
              }
              key={`attendee_suggestion_${suggestion.index}`}
              onMouseEnter={() => this.onMouseEnterSuggestion(suggestion)}
              onMouseLeave={this.onMouseLeaveSuggestion}
              onClick={() => this.handleSelectTemplate(suggestion)}
              ref={this.state.refs[suggestion.key]}
            >
              <span>{truncateString(suggestion.description, 50)}</span>
            </div>
          );
        })}
      </div>
    ) : null;
  }

  onTitleKeyDown(e) {
    if (!isEmptyArray(this.state.suggestions)) {
      this.handleKeyDownForSuggestions(e);
    } else if (
      e?.keyCode === KEYCODE_BACKSPACE && // this also implicitly checks for existence of e
      e.target.selectionStart === 0 &&
      (e.target.selectionEnd === 0 ||
        e.target.selectionEnd === getInputStringFromEvent(e).length) &&
      emojiAtBeginningOfString(this.state.summary)
    ) {
      hasEventPreventDefault(e);

      if (!this.state.summary) {
        // early exit. Setting it to empty string explicitly here incase summary somehow gets set to null
        this.setState({
          summary: "",
        });
        return;
      }
      if (e.target.selectionEnd === 0) {
        const stringWithoutEmoji = this.state.summary
          .substring(emojiAtBeginningOfString(this.state.summary).length)
          .trimStart();

        this.setState({
          summary: stringWithoutEmoji,
        });
      } else {
        this.setState({
          summary: "",
        });
      }
    }
  }

  handleKeyDownForSuggestions(e) {
    if (e.keyCode === KEYCODE_ESCAPE) {
      // Do nothing -> handleKeyDown will take care of it
    } else {
      let { suggestions } = this.state;
      let currentIndex = getCurrentActiveIndex(suggestions);

      if ([KEYCODE_ENTER, KEYCODE_TAB].includes(e.keyCode)) {
        if (e.keyCode === KEYCODE_TAB) {
          hasEventPreventDefault(e);
        }
        // selects suggestion
        if (isCommandKeyPressed(this.state.keyMap) && KEYCODE_ENTER) {
          // Do nothing here
        } else {
          this.handleSelectTemplate(suggestions[currentIndex]);
        }
      } else if (e.keyCode === KEYCODE_UP_ARROW) {
        let { suggestion } = pressUpSuggestion(
          currentIndex,
          suggestions,
          this.scrollToOption
        );

        this.setState({
          suggestions: suggestion,
          refs: this.createRefForSuggestion(suggestion),
        });
      } else if (e.keyCode === KEYCODE_DOWN_ARROW) {
        let { suggestion } = pressDownSuggestions(
          currentIndex,
          suggestions,
          this.scrollToOption
        );

        this.setState({
          suggestions: suggestion,
          refs: this.createRefForSuggestion(suggestion),
        });
      }
    }
  }

  scrollToOption(newIndex) {
    if (newIndex) {
      let ref = this.state.refs[newIndex.key];

      ref &&
        ref.current &&
        ref.current.scrollIntoView({
          behavior: "auto",
          block: "nearest",
        });
    }
  }

  removeEmoji() {
    this.setState({ summary: "" });
  }

  onChangeTitle(
    text,
    programmaticallyAdded = false,
    notifyChange = false,
    skipSettingTemplateSuggestion = false
  ) {
    let addedEmoji = false;

    if (notifyChange) {
      this.setComponentHasChangedNotification(SUMMARY);
    }

    let newState = {};
    // dropdown for templates suggestions
    if (
      !skipSettingTemplateSuggestion &&
      !isEmptyObjectOrFalsey(this.state.templatesSummaryIndex)
    ) {
      const { knownTemplate } = this.state;
      let suggestions = getMatchingTemplates({
        templatesSummaryIndex: this.state.templatesSummaryIndex,
        query: text,
        knownTemplate,
      });
      newState.suggestions = suggestions;
      newState.refs = this.createRefForSuggestion(suggestions);
    }

    const loweredText = text.toLowerCase();

    if (
      loweredText.includes("walk") &&
      loweredText.includes("through") &&
      emojiAtBeginningOfString(this.state.summary)
    ) {
      // Include emoji for 'walk' but not 'walk through/walk-through'
      const stringWithoutEmoji = text.substring(2).trimStart();
      newState.summary = stringWithoutEmoji;

      this.setState(newState, () => {
        if (programmaticallyAdded) {
          this.setTemporaryEvents();
        }
      });

      return;
    }

    if (!emojiAtBeginningOfString(this.state.summary)) {
      const { masterAccount } = this.props.masterAccount;
      const shouldAddEmoji = shouldAutoAddEmoji({
        masterAccount,
        user: this.getUser(),
      });
      Object.keys(EMOJI_SUMMARY_DICTIONARY).forEach((key) => {
        if (
          text &&
          text.length >= 3 &&
          shouldAddEmoji &&
          doesSentenceIncludeWord(loweredText, key) &&
          !this.isEmojiKeywordInDomain(key)
        ) {
          if (key === "walk" && loweredText.includes("through")) {
            return true;
          }

          addedEmoji = true;

          this.onSelectEmojiInTitle(
            EMOJI_SUMMARY_DICTIONARY[key]?.native,
            text
          );
        }
      });
    }

    if (!addedEmoji) {
      let emoji = emojiAtBeginningOfString(this.state.summary) || "";
      let updatedSummary = emoji + text;

      if (programmaticallyAdded) {
        updatedSummary = emoji + " " + text;
      }

      newState.summary = updatedSummary;
      this.setState(newState, () => {
        if (programmaticallyAdded) {
          this.setTemporaryEvents();
        } else {
          clearTimeout(this._setTemporaryEventTimeout);
          this._setTemporaryEventTimeout = setTimeout(() => {
            if (!this._isMounted) {
              return;
            }
            this.setTemporaryEvents();
          }, SECOND_IN_MS);
        }
      });
    }
  }

  getAllTags(inputNormalTags, inputSmartTags) {
    const { eventObject } = this.state;
    const { currentUser } = this.props;
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const {
      masterAccount,
    } = this.props.masterAccount;

    const matchingUser = this.getMatchingTagsUser();
    const userTagIDs = getMatchingUserAndTags({
      user: matchingUser,
      currentUser,
      allLoggedInUsers,
      masterAccount,
    }).map((tag) => getTagId(tag));

    const tags = this.checkMode(UPDATE_TEMPLATE)
      ? getTagsFromTemplate(eventObject)
      : getTagsFromEvent(eventObject);

    const smartTags = getEventSmartTags({ event: eventObject });
    const allTags = generateMergedTagsAndSmartTags({
      tags: inputNormalTags ?? tags,
      smartTags: inputSmartTags ?? smartTags,
    });
    return allTags.filter((tag) => {
      return userTagIDs.includes(getTagId(tag));
    });
  }

  renderEmailAndColor() {
    const { masterAccount } = this.props.masterAccount;

    const {
      calendar,
      categories,
      eventObject,
      originalEvent,
      outOfOfficeAutoDeclineMode,
      outOfOfficeDeclineMessage,
      transparency,
    } = this.state;
    const { outlookCategories } = this.props.outlookCategoriesStore;

    const isNewEvent = this.isCreateNew();
    const isGoogle =
      isNewEvent || this.isTemplate()
        ? this.isGoogleCalendar()
        : isGoogleEvent(originalEvent);

    const tags = this.checkMode(UPDATE_TEMPLATE)
      ? getTagsFromTemplate(eventObject)
      : getTagsFromEvent(eventObject);

    const allTags = this.getAllTags();

    const setTags = (updatedTags) => {
      if (
        updatedTags?.length > tags?.length &&
        hasReachedTagsLimit(updatedTags)
      ) {
        Broadcast.publish(
          SET_DISAPPEARING_NOTIFICATION_MESSAGE,
          `You can only add up to ${TAGS_LIMIT} tags.`
        );
        return;
      }
      let updatedState = {};
      if (this.checkMode(UPDATE_TEMPLATE)) {
        // updating template is still all stored in raw_json
        const { eventObject } = this.state;
        const rawJSON = eventObject?.raw_json ?? {};
        updatedState.eventObject = {
          raw_json: {
            ...rawJSON,
            extended_properties: {
              private: {
                tags: JSON.stringify(updatedTags),
              },
            },
          },
        };
      } else {
        // for all other modes (create event, update event, etc)
        updatedState = {
          eventObject: {
            ...eventObject,
            extended_properties: {
              private: {
                ...getEventExtendedPropertiesPrivate(eventObject),
                tags: JSON.stringify(updatedTags),
              },
            },
          },
        };
      }

      this.setState(updatedState, () => {
        this.setTemporaryEvents();
        const { allCalendars } = this.props.allCalendars;
        const userCalendarID = getCalendarUserCalendarID(this.state.calendar);
        const calendarColorID = getCalendarColorID(
          allCalendars[userCalendarID]
        );
        const eventColorID = this.state.eventColorId;

        /* Specifically check 0 since 0 is the same as default color */
        if (
          tags?.length === 1 &&
          updatedTags?.length === 0 &&
          tags[0].color ===
            getBackgroundHexFromGoogleColorID(eventColorID).color
        ) {
          // remove last tag and there's no color
          this.setEventColor({ value: "0" });
          return;
        }

        if (
          eventColorID &&
          eventColorID !== calendarColorID &&
          eventColorID !== "0"
        ) {
          /* Don't set label color if eventColor is already set */
          return;
        }

        const updatedAllTags = this.getAllTags(updatedTags);
        /* If event has default colors, overwrite with paint color */
        if (
          updatedAllTags?.length > 0 &&
          !isTransparentTag(updatedAllTags[0])
        ) {
          this.setEventColor({ value: getTagColorId(updatedTags[0]) });
        }
      });
    };

    const calendarEmailColor = this.determineEmailColor();
    const colorList = createColorsWithNickName({
      defaultColor: calendarEmailColor,
      user: this.getUser(),
      masterAccount,
    });

    const renderColorSelect = () => {
      const getOptions = () => {
        if (!shouldDisplayColorLabel({ user: this.getUser() })) {
          return createColorValueAndLabel(colorList);
        }
        return createColorValueAndLabel(colorList).concat({
          value: IS_CREATE_NEW_COLOR_LABEL,
          name: "Add color label",
          label: (
            <div className="flex items-center">
              <div className="w-4 h-4 flex items-center default-primary-border justify-center rounded-full ">
                <Plus size={10} strokeWidth={2} />
              </div>
              <div className="default-font-size ml-2">Add color label</div>
            </div>
          ),
        });
      };

      return (
        <>
          <GlobalKeyMapTile
            style={{ top: "-15px", left: "0px", fontWeight: 300, zIndex: 1 }}
            shortcut={removePlusAndCapitalize(
              eventFormSectionHotKeysIndex[EVENT_FORM_COLOR]
            )}
          />

          <CustomSelect
            components={{ DropdownIndicator }}
            ref={(input) => {
              this.selectEmailColorSection = input;
            }}
            openMenuOnFocus={true}
            overrideStyles={getReactSelectBaseStyle({
              isDarkMode: this.props.isDarkMode,
              showBorder: false,
              menuListStyle: customMenuStyle({ width: 180, zIndex: 5 }),
            })}
            tabSelectsValue={false}
            isSearchable={false}
            tabIndex={11}
            inputId={EMAIL_COLOR}
            className={"select-calendar-color-dropdown"}
            classNamePrefix="dark-mode"
            value={{
              label: (
                <CircleWithColor
                  color={
                    getMatchingColorFromID({
                      colorList,
                      colorID: this.state.eventColorId,
                    }) || calendarEmailColor
                  }
                />
              ),
              value: String(this.state.eventColorId || "0"),
            }}
            onChange={this.setEventColor}
            options={getOptions()}
            onKeyDown={(event) =>
              blurInputOnEscape(event, this.selectEmailColorSection)
            }
            onFocus={() => this.onFocusSection(EMAIL_COLOR)}
            filterOption={(candidate, input) => {
              return (
                candidate?.data?.name
                  ?.toLowerCase()
                  .includes(input?.toLowerCase()) ||
                candidate?.data?.nickName
                  ?.toLowerCase()
                  .includes(input?.toLowerCase())
              );
            }}
          />
        </>
      );
    };

    const renderColorAndCalendarSelect = () => {
      const { calendar } = this.state;
      if (this.isOutlookCalendar() && this.isUpdateEvent()) {
        return null;
      }

      const shouldHideColorSelect = () => {
        const isTemplateSelectCalendar = this.isTemplate() && isEmptyObjectOrFalsey(calendar);
        if (this.isOutlookCalendar() || isTemplateSelectCalendar) {
          return true;
        }

        return false;
      };

      return (
        <>
          <Calendar size={ICON_SIZE} className="secondary-text-color mr-4" />
          <div
            className={Classnames(
              "display-flex-flex-direction-row align-items-center",
              this.props.isMobileView ? "justify-around" : "",
              "event-form-email-color-section",
            )}
          >
            {shouldHideColorSelect() ? null : (
              <div className="mr-4">{renderColorSelect()}</div>
            )}

            <GlobalKeyMapTile
              style={{ top: "-15px", left: "0px", fontWeight: 300, zIndex: 1 }}
              shortcut={removePlusAndCapitalize(
                eventFormSectionHotKeysIndex[EVENT_FORM_EMAIL],
              )}
            />

            {this.renderCalendarSelectionDropdown()}
          </div>
        </>
      );
    };

    const matchingTagsUser = this.getMatchingTagsUser();
    return (
      <div
        id="email-and-color-section"
        className={classNames("event-form-box-container", MARGIN_BETWEEN_BOXES)}
      >
        <div
          className="event-form-section-header secondary-text-color"
          ref={(input) => {
            this.email = input;
          }}
          id="emailAndColor"
        >
          {"Calendar & availability"}
        </div>

        <div
          className={
            this.isTemplate()
              ? "event-form-icon-input-grid-without-tags"
              : "event-form-icon-input-grid"
          }
        >
          {renderColorAndCalendarSelect()}

          {doesCalendarHaveCategories({ calendar, outlookCategories }) ? (
            <>
              <Grid size={ICON_SIZE} className="secondary-text-color" />
              <SelectCategories
                initialCategories={categories}
                onChange={(newCategories) =>
                  this.setState(
                    { categories: newCategories },
                    this.setTemporaryEvents
                  )
                }
                selectedCalendar={calendar}
              />
            </>
          ) : null}

          {this.props.isMobileView ? null : (
            <Briefcase size={ICON_SIZE} className="secondary-text-color" />
          )}
          {this.renderAvailabilityAndVisibility()}

          {isGoogle && transparency === OUT_OF_OFFICE_DURING_EVENT ? (
            <OutOfOfficeOptions
              autoDeclineMode={outOfOfficeAutoDeclineMode}
              declineMessage={outOfOfficeDeclineMessage}
              setAutoDeclineMode={(newAutoDeclineMode) =>
                this.setState({
                  outOfOfficeAutoDeclineMode: newAutoDeclineMode,
                })
              }
              setDeclineMessage={(newDeclineMessage) =>
                this.setState({ outOfOfficeDeclineMessage: newDeclineMessage })
              }
            />
          ) : null}

          {
            (this.isSelectCalendarOption()) ||
            shouldGateExtendedProperties(calendar) ?
              null : (
                <>
                  <div className="self-start mt-2.5">
                    <TagsEmptyIcon />
                  </div>
                  <Tags
                    displayLocation={DISPLAY_LOCATION_EVENT_FORM}
                    tags={allTags}
                    setTags={setTags}
                    userEmail={getUserEmail(matchingTagsUser)}
                    matchingUser={matchingTagsUser}
                  />
                  <TagsSuggestions
                    existingTags={allTags}
                    setTags={setTags}
                    searchString={this.state.summary}
                    containerClassName={"mt-2"}
                    userEmail={getUserEmail(matchingTagsUser)}
                  />
                </>
              )
          }
        </div>
      </div>
    );
  }

  getMatchingTagsUser() {
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    const {
      masterAccount,
    } = this.props.masterAccount;
    const {
      calendar,
    } = this.state;
    const {
      allCalendars,
    } = this.props.allCalendars;
    const {
      currentUser,
    } = this.props;
    return getMatchingTagUserForUserCalendarID({
      userCalendarID: getCalendarUserCalendarID(calendar),
      allCalendars,
      allLoggedInUsers,
      masterAccount,
      currentUser,
    });
  }

  getCalendarUserEmail() {
    return getCalendarUserEmail(this.state.calendar) ?? this.getUserEmail();
  }

  canModifyButNotOrganizer() {
    return this.state?.[EVENT_HAS_MODIFY_PERMISSION_AND_IS_NOT_AN_ORGANIZER];
  }

  renderCalendarSelectionDropdown() {
    if (!this.state.writableCalendars) {
      return null;
    }

    if (this.state.writableCalendars.length <= 1) {
      return (
        <div className="default-font-size px-2.5 max-width-160px truncate-text">
          {truncateString(this.getCalendarName(), 26)}
        </div>
      );
    } else {
      const isDisabled =
        this.state.eventNotHavePermissionToEditActualEvent ||
        this.canModifyButNotOrganizer()

      if (isDisabled) {
        return (
          <div className="disabled-select-event-form-email">
            {this.getCalendarName()}
          </div>
        );
      }
      const calendarName = this.getCalendarName();
      const { isDarkMode } = this.props;
      const calendarSelectOptions = [
        ...(this.isTemplate() ? [TEMPLATE_SELECT_CALENDAR_OPTION] : []),
        ...this.state.calendarSelectOptions,
      ];
      const {
        allLoggedInUsers,
      } = this.props.allLoggedInUsers;
      const {
        masterAccount,
      } = this.props.masterAccount;
      const {
        calendar,
      } = this.state;

      const isExecutiveCalendar = isUserMaestroUser(masterAccount) && isCalendarExecutiveCalendar({ calendar, allLoggedInUsers });
      return (
        <CustomSelect
          components={{ DropdownIndicator }}
          ref={(input) => {
            this.selectEmailSection = input;
          }}
          tabIndex={12}
          openMenuOnFocus={true}
          overrideStyles={getReactSelectBaseStyle({
            isDarkMode,
            showBorder: false,
            menuListStyle: customMenuStyle({ width: 190, zIndex: 5 }),
            controlWidth: 190,
            menuListMaxHeight: 240,
          })}
          classNamePrefix="dark-mode"
          tabSelectsValue={false}
          isSearchable={true}
          value={{
            label: (
              <div className="flex items-center default-font-size">
                <div className={classNames("truncate", isExecutiveCalendar ? "max-width-98px" : "")}>{calendarName}</div>
                {isExecutiveCalendar ? <ExecutiveLabel className="ml-2" /> : null}
              </div>
            ),
            value: this.state.calendar,
          }}
          onKeyDown={(event) =>
            blurInputOnEscape(event, this.selectEmailSection)
          }
          inputId={SELECT_EMAIL}
          options={calendarSelectOptions}
          onFocus={() => this.onFocusSection(SELECT_EMAIL)}
          onChange={this.setCalendar}
          onMenuOpen={() => {
            this.setState({
              calendarSelectOptions: this.createCalendarReactSelectOptions(),
            });
          }}
          filterOption={(candidate, input) => {
            const { masterAccount } = this.props.masterAccount;
            const { emailToNameIndex } = this.props;
            return filterForCalendarSelect({
              candidate,
              input,
              emailToNameIndex,
              user: this.getUser(),
              masterAccount,
            });
          }}
        />
      );
    }
  }

  renderAllDayToggle() {
    return (
      <div className="flex items-center flex-row position-relative event-form-all-day-container">
        {this.renderWarningWillOnlyBeReflectedOnCalendar(EVENT_TIME_AND_DATE, {
          top: -8,
          left: -243,
        })}

        <GlobalKeyMapTile
          style={{ top: "-30px", left: "-5px", fontWeight: 300, zIndex: 1 }}
          shortcut={removePlusAndCapitalize(
            eventFormSectionHotKeysIndex[EVENT_FORM_ALL_DAY]
          )}
        />

        <div
          className="event-form-all-day-text select-none cursor-pointer duration-200"
          onClick={() => this.setAllDay(!this.state.allDay)}
        >
          All day?
        </div>

        <div className="margin-top-three">
          <DefaultSwitch
            tabIndex={-1}
            isChecked={!!this.state.allDay}
            onChange={(action) => this.setAllDay(action)}
          />
        </div>
      </div>
    );
  }

  renderEventTimeAndDate() {
    const isTemplate = this.isTemplate();
    const renderWhichEventTimeAndDate = () => {
      if (this.isTemplate()) {
        return this.renderTemplateEventTime();
      } else {
        return this.renderNormalEventTimeAndDate();
      }
    };

    return (
      <div className="event-form-box-container">
        <div
          className={classNames(
            "event-form-date-and-time event-form-section-header display-flex-flex-direction-row",
            "justify-between"
          )}
          ref={(input) => {
            this.dateTime = input;
          }}
          id="dateAndTime"
        >
          {isTemplate ? (
            <div className="secondary-text-color">{"Event Duration"}</div>
          ) : null}

          {isTemplate ? this.renderAllDayToggle() : null}
        </div>

        {renderWhichEventTimeAndDate()}
      </div>
    );
  }

  renderTemplateEventTime() {
    return (
      <div className="event-form-select-duration-container">
        <SelectDuration
          duration={this.state.duration}
          onChange={this.setDuration}
          onBlur={this.setPopUpTime}
          onFocus={() => this.onFocusElement(START_TIME)}
          allDay={this.state.allDay}
        />
      </div>
    );
  }

  renderNormalEventTimeAndDate() {
    return (
      <div>
        <div
          className=""
          style={this.isAllDayAndTemplate() ? { marginTop: 0, height: 0 } : {}}
        >
          <div className="flex items-center">
            <div className="text-align-left margin-right-on-event-form-time-and-date">
              {!this.isTemplate() && (
                <>
                  <GlobalKeyMapTile
                    style={defaultEventFormTimeAndDateKeyMapTileStyle}
                    shortcut={removePlusAndCapitalize(
                      eventFormSectionHotKeysIndex[EVENT_FORM_START_DATE_INPUT]
                    )}
                  />

                  <EventFormDate
                    additionalClassname={this.startDateNotification}
                    tabIndex={4}
                    autoFocus={false}
                    isStartDate={true}
                    date={this.state.eventStartDate}
                    id={EVENT_FORM_START_DATE}
                    placeholder={"Start date"}
                    sendData={this.setEventStartDate}
                    onComplete={this.goToStartTime}
                    modalTitle={"Select Start Date"}
                    onEscape={this.resetLatestFocus}
                    onBlur={
                      this.removeWarningEditsWillOnlyReflectOnThisCalendar
                    }
                    highlightField={this.shouldHighLightField()}
                    innerRef={this.selectStartDateRef}
                  />
                </>
              )}
            </div>

            <div>
              {!this.state.allDay && (
                <>
                  <GlobalKeyMapTile
                    style={{
                      top: "-20px",
                      left: "5px",
                      fontWeight: 300,
                      zIndex: 1,
                    }}
                    shortcut={removePlusAndCapitalize(
                      eventFormSectionHotKeysIndex[EVENT_FORM_START_TIME_INPUT]
                    )}
                  />

                  <SelectEventTime
                    additionalClassname={this.startTimeNotification}
                    inputTabIndex={5}
                    id={START_TIME}
                    eventTime={this.state.eventStartTime}
                    timeZone={this.state.startTimeZone}
                    setEventTime={this.setEventStartTime}
                    defaultDisplaySelect={this.determineShouldDisplaySelectInEventTimeBox()}
                    selectInOptions={this.isTemplate()}
                    shouldSetTime={this.onClickStartTime}
                    isStartTime={true}
                    onBlur={this.onBlurEventTimeOrDate}
                    onEnter={this.goToEndTime}
                    highlightField={this.shouldHighLightField()}
                    innerRef={this.selectStartTimeRef}
                  />
                </>
              )}
            </div>
            <div className="w-full"></div>
            {this.isTemplate() ? null : this.renderAllDayToggle()}
          </div>

          {/* TO Section  */}
          <div className="flex items-center mt-1">
            <div id={SELECT_END_DATE} className={Classnames("text-align-left")}>
              {!(
                this.checkMode(TEMPLATE) || this.checkMode(UPDATE_TEMPLATE)
              ) && (
                <>
                  <GlobalKeyMapTile
                    style={{
                      top: "3px",
                      left: "147px",
                      fontWeight: 300,
                      zIndex: 1,
                    }}
                    shortcut={removePlusAndCapitalize(
                      eventFormSectionHotKeysIndex[EVENT_FORM_END_TIME_INPUT]
                    )}
                  />

                  <EventFormDate
                    additionalClassname={this.endDateNotification}
                    tabIndex={6}
                    date={this.state.eventEndDate}
                    id={EVENT_FORM_END_DATE}
                    placeholder={"End date"}
                    sendData={this.setEventEndDate}
                    onComplete={this.goToEndTime}
                    isInvalid={this.isEventTimeAndDateInvalid()}
                    highlightField={this.shouldHighLightField()}
                    modalTitle={"Select End Date"}
                    onEscape={this.resetLatestFocus}
                    inputTabIndex={3}
                    innerRef={this.selectEndDateRef}
                  />
                </>
              )}
            </div>
            <div
              className={"position-relative"}
              ref={(input) => {
                this.endTimeZone = input;
              }}
            >
              {!this.state.allDay && (
                <>
                  <GlobalKeyMapTile
                    style={{
                      top: "3px",
                      left: "-118px",
                      fontWeight: 300,
                      zIndex: 1,
                    }}
                    shortcut={removePlusAndCapitalize(
                      eventFormSectionHotKeysIndex[EVENT_FORM_END_DATE_INPUT]
                    )}
                  />

                  <SelectEventTime
                    additionalClassname={this.endTimeNotification}
                    inputTabIndex={7}
                    id={END_TIME}
                    eventTime={this.state.eventEndTime}
                    timeZone={this.state.endTimeZone}
                    setEventTime={this.setEventEndTime}
                    defaultDisplaySelect={this.determineShouldDisplaySelectInEventTimeBox()}
                    selectInOptions={this.isTemplate()}
                    shouldSetTime={this.shouldSetEndTime}
                    hasIssue={this.isEventTimeAndDateInvalid()}
                    onBlur={this.onBlurEventTimeOrDate}
                    onEnter={this.goToEndDate}
                    highlightField={this.shouldHighLightField()}
                    innerRef={this.selectEndTimeRef}
                    isStartTime={false}
                    eventStartTime={this.state.eventStartTime}
                  />
                </>
              )}
            </div>
          </div>
        </div>

        <div
          className={classNames(
            "flex",
            "mt-2",
            "ml-1",
            this.shouldMakeRepeatAndTimeZoneColumn() ? "flex-col" : ""
          )}
        >
          {this.renderRepeatSection()}
          {this.renderTimeZoneSection()}
        </div>

        {!this.state.separateStartEndTimezone &&
        this.shouldDisplayDifferentTimeZoneWarning() ? (
          <DidYouKnowAlert
            subText={
              "Note: This event is in a different time zone than your calendar"
            }
            title={""}
            className={"margin-bottom-4px-override"}
          />
        ) : null}
      </div>
    );
  }

  shouldMakeRepeatAndTimeZoneColumn() {
    return (
      this.shouldDisplaySelectedTimeZone() ||
      this.shouldDisplaySelectedRecurring()
    );
  }

  renderTimeZoneSection() {
    if (this.state.allDay || this.state.isOnboarding) {
      return null;
    }

    const { separateStartEndTimezone, startTimeZone, endTimeZone } = this.state;
    const { currentTimeZone } = this.props;

    return (
      <div
        className={classNames(
          this.shouldMakeRepeatAndTimeZoneColumn() ? "" : "ml-4"
        )}
      >
        <div
          className={classNames(
            this.shouldMakeRepeatAndTimeZoneColumn() ? "mt-1.5" : ""
          )}
        >
          <div className="flex items-center w-full justify-between">
            {separateStartEndTimezone ? (
              <div className="secondary-text-color default-font-size">
                Start time zone
              </div>
            ) : null}

            <div
              id="select-time-zone"
              className="hoverable-text event-form-time-zone-icon-text max-width-200px truncate-text hoverable-secondary-text-color"
              onClick={this.onClickTimeZoneIconText}
            >
              {this.shouldDisplaySelectedTimeZone()
                ? addAbbrevationToTimeZone({ timeZone: startTimeZone }) ||
                  addAbbrevationToTimeZone({ timeZone: currentTimeZone })
                : "Time zone"}
            </div>
          </div>

          {this.shouldSeparateTimeZones() ? (
            <div className="flex items-center w-full justify-between mt-1">
              <div className="secondary-text-color default-font-size">
                End time zone
              </div>
              <div
                className="hoverable-text separate-time-zone-text separate-end-time-zone max-width-200px truncate-text"
                onClick={this.onClickTimeZoneIconText}
              >
                {addAbbrevationToTimeZone({ timeZone: endTimeZone }) ||
                  addAbbrevationToTimeZone({ timeZone: currentTimeZone })}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  renderRepeatSection() {
    return (
      <div>
        {!this.state.isOnboarding && !this.isTemplate() && (
          <div
            id="repeat-icon"
            className={Classnames(
              "event-form-repeat-wrapper",
              "secondary-text-color",
              this.state.eventNotHavePermissionToEditActualEvent
                ? ""
                : "hoverable-text",
              "event-form-repeat-text"
            )}
            onClick={this.onClickEventRepeat}
          >
            {this.state.repeatText}
          </div>
        )}
      </div>
    );
  }

  renderRepeatModalContent() {
    if (this.state.shouldDisplayEventRepeatCustomModal) {
      return (
        <CustomRepeatOptions
          isOutlookCalendar={this.isOutlookCalendar()}
          date={this.state.eventStartDate}
          setRecurring={(option) => this.setRecurring(option)}
          recurrence={CreateRecurrentObject(
            this.state.recurrenceRules,
            this.state.eventStartDate
          )}
        />
      );
    } else {
      return (
        <DefaultRepeatOptions
          date={this.state.eventStartDate}
          defaultOption={this.state.repeatText}
          sendInformationBackToParentComponent={this.setRepeat}
          selectCustomRepeat={this.onClickSelectCustomRepeat}
        />
      );
    }
  }

  renderLocationAndConference() {
    const {
      conference,
      errorFetchingZoomMeeting,
      wasZoomUpdated,
      zoomMeeting,
      eventNotHavePermissionToEditActualEvent,
      isNativeLink,
      originalEvent,
      conferencingSelectOptions,
      conferenceURL,
    } = this.state;
    return (
      <div
        className={classNames(
          "position-relative event-form-box-container",
          MARGIN_BETWEEN_BOXES
        )}
      >
        {this.renderWarningWillOnlyBeReflectedOnCalendar(LOCATION, {
          top: -40,
          left: 0,
        })}

        <div className="event-form-location-wrapper">
          <GlobalKeyMapTile
            style={{ top: "-20px", left: "30px", fontWeight: 300, zIndex: 1 }}
            shortcut={removePlusAndCapitalize(
              eventFormSectionHotKeysIndex[EVENT_FORM_LOCATION]
            )}
          />

          <div style={{ marginTop: 7 }}>
            <MapPin size={ICON_SIZE} className="secondary-text-color" />
          </div>

          <LocationSearchInput
            onPressEscape={this.onPressEscape}
            changeNotificationClassName={this.locationNotification}
            focusRef={(input) => {
              this.location = input;
            }}
            inputTabIndex={8}
            onChange={this.setLocation}
            defaultLocation={this.state.location}
            onFocus={() => this.onFocusSection(LOCATION)}
            onBlur={this.removeWarningEditsWillOnlyReflectOnThisCalendar}
            doesEventNotHavePermissionToEditActualEvent={this.shouldHighLightField()}
            onClickSave={this.onClickSave}
            suggestionMarginLeft={10}
          />
        </div>

        {!(
          eventNotHavePermissionToEditActualEvent &&
          conference === noConferenceString
        ) && (
          <div className="event-form-conference-wrapper">
            <div
              className={Classnames(
                "event-form-conference-icon",
                eventNotHavePermissionToEditActualEvent
                  ? "display-flex align-items-flex-start"
                  : ""
              )}
              style={{
                paddingBottom: 5,
                marginTop:
                  eventNotHavePermissionToEditActualEvent && isNativeLink
                    ? 5
                    : 0,
              }}
            >
              <Video
                size={ICON_SIZE}
                id={CONFERENCE_SECTION}
                className="secondary-text-color"
              />
            </div>

            <GlobalKeyMapTile
              style={{ top: "-15px", left: "10px", fontWeight: 300, zIndex: 1 }}
              shortcut={removePlusAndCapitalize(
                eventFormSectionHotKeysIndex[EVENT_FORM_CONFERENCE]
              )}
            />

            {eventNotHavePermissionToEditActualEvent ? (
              <ConferenceStatus
                conference={conference}
                originalEvent={originalEvent}
              />
            ) : (
              <ConferenceSelect
                id={VIDEO_CONFERENCE}
                conference={conference}
                options={conferencingSelectOptions}
                notification={this.conferenceNotification}
                onChange={this.onUserSetConferencing}
                inputRef={this.selectConferenceSection}
                value={this.state.conferencing}
                containerClassName="ml-2.5"
              />
            )}
          </div>
        )}

        <>
          {this.renderUniqueZoomSchedulerDropdown()}
          {this.isTemplate() ? null : (
            <EventFormConferenceAdditionalInfo
              conference={conference}
              conferenceURL={conferenceURL}
              eventNotHavePermissionToEditActualEvent={
                this.state.eventNotHavePermissionToEditActualEvent
              }
              user={this.getUser()}
              zoomURL={this.determineZoomURL()}
              hideConferencingInfo={
                this.state.updatedToPersonalZoomLink || wasZoomUpdated
              }
              conferenceChanged={this.hasConferencingChanged()}
              originalEvent={this.state.originalEvent}
              isNativeLink={this.state.isNativeLink}
              uniqueZoomMeeting={zoomMeeting}
              wasZoomUpdated={wasZoomUpdated}
              isTemplate={this.isTemplate()}
              defaultToZoomPersonalLink={this.isDefaultZoomPersonalLink()}
              zoomError={this.state.zoomMeetingError}
              isDuplicate={this.isDuplicateEvent()}
              onClickZoomLogin={this.onClickZoomLogin}
              shouldRenderZoomDetails={
                zoomMeeting ||
                this.state.zoomMeetingError ||
                this.state.isFetchingZoomMeeting ||
                this.state.hasAttemptedToCreateUniqueZoom
              }
              isCopyToCalendarEvent={this.isCopyToCalendarEvent()}
              originalEventConferenceData={
                this.state.originalEventConferenceData
              }
            />
          )}

          <MultipleConferenceWarning
            conference={this.state.conference}
            conferenceURL={
              this.state.conferenceURL ?? getZoomJoinURL(zoomMeeting)
            }
            location={this.state.location}
            description={this.description}
            isNativeLink={this.state.isNativeLink}
            currentUser={this.getUser()}
            conferenceChanged={this.hasConferencingChanged()}
            isCreateNew={this.isCreateNew()}
          />
          {this.state.warningToDeleteOutlookZoomDescription &&
          this.state.conference !== ZOOM ? (
            <div className="default-font-size warning-color mt-2">
              Warning: There is still Zoom info in the description.
            </div>
          ) : null}

          {/* TODO: Once we can edit the description when the join URL changes, re-enable this for PMI meetings. */}
          {!errorFetchingZoomMeeting &&
          this.canEditZoomSettingsForHost() &&
          !this.isTemplate() ? (
            <div className="ml-4 mt-5">
              <CustomButton
                buttonType={WHITE_BUTTON}
                className="margin-left-22px zoom-settings-button"
                onClick={() =>
                  this.setState({
                    description: this.getDescriptionContent(),
                    isEditingZoomSettings: true,
                  })
                }
                removeDefaultFontStyles
                labelClassNameOverride="font-light"
                label={
                  <>
                    <Settings className="mr-2" size={15} />
                    Zoom Settings
                  </>
                }
              />
            </div>
          ) : null}
        </>

        {this.shouldDisplayConferenceRoomSection() ? (
          <div className="event-form-conference-wrapper">
            <GlobalKeyMapTile
              style={{ top: "-20px", left: "30px", fontWeight: 300, zIndex: 1 }}
              shortcut={removePlusAndCapitalize(
                eventFormSectionHotKeysIndex[EVENT_FORM_ROOM]
              )}
            />

            <div
              className={classNames(
                this.state.selectedConferenceRooms &&
                  this.state.selectedConferenceRooms.length > 0
                  ? "event-for-door-icon mt-0.5"
                  : "event-form-conference-icon mb-1"
              )}
            >
              {this.props.isDarkMode ? (
                <DarkModeConferenceRoomDoorSVG isSecondary={true} />
              ) : (
                <LightModeConferenceRoomDoorSVG isSecondary={true} />
              )}
            </div>

            {this.renderConferenceRoomContent()}
          </div>
        ) : null}

        {this.state.selectedConferenceRooms &&
          this.state.selectedConferenceRooms.length > 0 && (
            <div
              id="select-conference-room"
              className="hoverable-text event-form-select-conference-room-text margin-left-40px-override secondary-text-color"
              onClick={this.selectConferenceRoom}
            >
              {"Change conference rooms"}
            </div>
          )}

        {this.state.nonEditConferenceRooms &&
          this.state.nonEditConferenceRooms.length > 0 && (
            <div>
              {this.renderNonEditableConferenceRoomsWithOrWithoutIcon()}

              <div className="calendar-can-not-be-shown">
                {"* Calendar cannot be shown"}
              </div>
            </div>
          )}
      </div>
    );
  }

  getCurrentCalendarDomainInfo() {
    const { allUserDomains } = this.props.allUserDomains;

    return allUserDomains[this.getCalendarUserEmail()];
  }

  determineZoomURL() {
    if (
      this.state.isNativeLink &&
      isEventConferencingZoom(this.state.originalEvent) &&
      this.state.conferenceURL
    ) {
      return this.state.conferenceURL;
    } else {
      return this.getUser().zoom_link;
    }
  }

  renderConferenceRoomContent() {
    if (this.state.selectedConferenceRooms?.length > 0) {
      return (
        <div className="event-form-selected-room">
          {this.renderConferenceRooms()}
        </div>
      );
    } else if (this.userHasConferenceRooms()) {
      return (
        <div
          id="select-conference-room"
          className="hoverable-text event-form-select-conference-room-text secondary-text-color"
          onClick={this.selectConferenceRoom}
        >
          Add conference room
        </div>
      );
    } else {
      return null;
    }
  }

  renderConferenceRooms() {
    return this.state.selectedConferenceRooms.map((room, index) => {
      return (
        <div
          key={`selected_room_list_${getConferenceRoomID(room.value)}`}
          className={
            this.state.roomAvailabilityIndex[room.label] ===
            attendee_event_declined
              ? "event-form-render-selected-conference-room cross-through"
              : "event-form-render-selected-conference-room"
          }
        >
          <div className="w-60">{room.label}</div>

          <X
            size={14}
            className="clickable-icon mt-0.5"
            onClick={() => this.removeSelectedRoom(room)}
          />
        </div>
      );
    });
  }

  renderNonEditableConferenceRoomsWithOrWithoutIcon() {
    if (
      (this.state.selectedConferenceRooms &&
        this.state.selectedConferenceRooms.length > 0) ||
      this.state.availableConferenceRooms
    ) {
      return this.renderNonEditableConferenceRooms();
    } else {
      return (
        <div className="event-form-conference-wrapper">
          <div className="event-for-door-icon">
            {this.props.isDarkMode ? (
              <DarkModeConferenceRoomDoorSVG />
            ) : (
              <LightModeConferenceRoomDoorSVG />
            )}
          </div>

          <div className="flex-direction-column">
            {this.renderNonEditableConferenceRooms(
              "event-form-with-icon-render-non-editable-selected-conference-room"
            )}
          </div>
        </div>
      );
    }
  }

  renderNonEditableConferenceRooms(
    passedDownClassName = "event-form-render-non-editable-selected-conference-room"
  ) {
    return this.state.nonEditConferenceRooms.map((room, index) => {
      return (
        <div
          key={`non_editable_selected_room_list_${room}`}
          className={
            this.state.roomAvailabilityIndex[room] === attendee_event_declined
              ? Classnames(passedDownClassName, "cross-through")
              : passedDownClassName
          }
        >
          {room} *
          {!this.state.eventNotHavePermissionToEditActualEvent && (
            <div
              style={{ marginRight: 20, display: "flex", alignItems: "center" }}
              onClick={() => this.removeNonEditableSelectedRoom(room)}
            >
              <X size={15} className="clickable-icon" />
            </div>
          )}
        </div>
      );
    });
  }

  renderSchedulingTools() {
    const allAttendees = this.getAllAttendees();

    const hideFindTimes = (
      isEmptyArrayOrFalsey(allAttendees) ||
      this.isTemplate() ||
      !shouldShowFindTimeEventForm(this.props.currentUser) ||
      this.state.allDay
    );

    const hideSchedulingAssistant = (
      this.shouldHighLightField() ||
      this.isTemplate() ||
      this.getDifferenceInDaysBetweenEventStartAndEnd() >= WINDOW_SYNC_DAYS_OUT
    );

    if (hideFindTimes && hideSchedulingAssistant) {
      return null;
    }

    return (
      <div className="flex gap-2 my-2">
        {hideSchedulingAssistant ? null : (
          <PillButton
            onClick={() => {
              this.setState({
                shouldDisplayModal: true,
                modalWidth: SCHEDULING_ASSISTANT_MODAL_WIDTH,
                modalTitle: "Scheduling assistant",
                modalContent: SCHEDULING_ASSISTANT_MODAL,
              });
            }}
          >
          Scheduling Assistant
          </PillButton>
        )}
        {hideFindTimes ? null : <FindTimeCapsule
          onClick={this.onClickFindTime}
          id={EVENT_FORM_FIND_TIME_BUTTON_ID}
        />}
      </div>
    );
  }

  onClickFindTime() {
    const { eventStart, eventEnd } = this.getEventStartAndEnd();
    const duration = differenceInMinutes(eventEnd, eventStart);
    eventFormBroadcast.publish("FIND_TIME_CREATE_EVENT", duration);
  }

  renderUniqueZoomSchedulerDropdown() {
    const {
      zoomSchedulerEmail,
      zoomMeeting,
      eventNotHavePermissionToEditActualEvent,
      location,
    } = this.state;
    if (this.isTemplate()) {
      // only show for new events/create event
      return null;
    }
    if (
      eventNotHavePermissionToEditActualEvent ||
      this.canModifyButNotOrganizer()
    ) {
      return null;
    }
    if (isEmptyObjectOrFalsey(zoomMeeting)) {
      return null;
    }
    if (this.state.conference !== zoomString) {
      return null;
    }
    if (!this.isUserMaestroUser()) {
      return null;
    }
    const schedulers = this.getZoomScheduler();
    const schedulerEmails = getZoomSchedulerEmails(schedulers);
    if (isEmptyArrayOrFalsey(schedulerEmails)) {
      return null;
    }
    const calendarUserEmail = this.getCalendarUserEmail();
    const zoomHostEmail =
      getZoomSchedulersHostEmail({
        schedulers,
        userEmail: calendarUserEmail,
      }) || calendarUserEmail;
    const getOptions = () => {
      const emails = removeDuplicatesFromArray(schedulerEmails);
      const emailOptions = emails.map((email) => ({
        label: email,
        value: email,
      }));
      if (emails.includes(zoomHostEmail)) {
        return emailOptions;
      }
      return [{ label: zoomHostEmail, value: null }].concat(emailOptions);
    };
    const options = getOptions();
    if (isEmptyArrayOrFalsey(options) || options.length === 1) {
      // no point in showing the dropdown if there's only one option
      return null;
    }

    const onChange = (option) => {
      const email = option.value;

      const deletePreviousMeeting = () => {
        if (isRecurringZoomMeeting(zoomMeeting)) {
          // do not delete recurring zoom meetings
          return;
        }
        this.deleteUniqueZoomLink({ zoomMeeting, zoomSchedulerEmail });
      };

      const onFailureFunction = () => {
        Broadcast.publish(
          SET_DISAPPEARING_NOTIFICATION_MESSAGE,
          "Failed to update Zoom meeting"
        );
      };

      const updatedState = {
        zoomSchedulerEmail: email,
        zoomMeeting: null, // reset
      };
      const { updatedLocation, updatedDescription, hasUpdatedDescription } =
        stripOutZoomFromLocationAndDescription({
          zoomMeeting,
          location,
          description: this.getDescriptionContent(),
        });
      if (hasUpdatedDescription) {
        updatedState.description = updatedDescription;
      }
      if (updatedLocation !== location) {
        updatedState.location = updatedLocation;
      }
      this.setState(updatedState, () => {
        if (hasUpdatedDescription) {
          this.description.setEditorContents(
            this.description.getEditor(),
            updatedState.description
          );
        }
        this.createUniqueZoomLink(
          email,
          deletePreviousMeeting,
          onFailureFunction
        );
      });
    };

    return (
      <div className="flex items-center gap-2 default-font-size ml-10">
        <div className="secondary-text-color">Schedule for: </div>
        <CustomSelectV2
          className="event-form-zoom-schedule-for-dropdown"
          value={{
            value: zoomSchedulerEmail ?? calendarUserEmail,
            label: zoomSchedulerEmail ?? calendarUserEmail,
          }}
          options={options}
          onChange={onChange}
          isSearchable={true}
          variant={SELECT_VARIANTS.OUTLINED}
        />
      </div>
    );
  }

  isUserMaestroUser() {
    const { masterAccount } = this.props.masterAccount;
    return isUserMaestroUser(masterAccount);
  }

  renderAttendeesList() {
    const displayAttendees = this.shouldDisplayAttendees();

    return (
      <div
        ref={(input) => {
          this.attendeeSection = input;
        }}
        className={classNames("event-form-box-container", MARGIN_BETWEEN_BOXES)}
      >
        {!this.state.eventDoesNotHavePermissionToInviteOthers && (
          <div className={"event-form-attendee-wrapper"}>
            <GlobalKeyMapTile
              style={{ top: "-30px", left: "0px", fontWeight: 300, zIndex: 1 }}
              shortcut={removePlusAndCapitalize(
                eventFormSectionHotKeysIndex[EVENT_FORM_ATTENDEE]
              )}
            />
            <Users size={ICON_SIZE} className="secondary-text-color mr-4" />

            <ReactSelectAttendeeAutoComplete
              inputTabIndex={10}
              innerRef={this.addAttendeeRef}
              onFocus={() => this.onFocusElement(ATTENDEE)}
              addAttendeeIntoEventForm={this.addAttendees}
              onEscape={this.resetLatestFocus}
              onCommandEnter={this.onClickSave}
              componentLocation={REACT_ATTENDEE_SELECT_LOCATION.EVENT_FORM}
              selectedGuests={this.createExistingAttendeeList()}
              onTab={this.goToDescription}
              className={classNames(
                "select-attendee-event-form",
                this.state.isInvalidAddress
                  ? "warning-background-color-important"
                  : "",
                "light-mode-event-form-background-color"
              )}
              id={ATTENDEE}
              updateAttendeeQuery={this.updateAttendeeQuery}
              setValidAddressWarning={this.setValidAddressWarning}
              skipSavingContact={true}
              calendarUserEmail={this.getCalendarUserEmail()}
              hideBorder={true}
              shouldFetchDistroLists={true}
            />
          </div>
        )}

        {this.state.isInvalidAddress ? (
          <div className="invalid-email-warning mt-2.5">
            Invalid email address
          </div>
        ) : null}

        {displayAttendees
          ? this.renderAttendeeInfo()
          : this.renderCanNotDisplayAttendees()}

        {this.renderSchedulingTools()}
        {this.shouldDisplayGuestPermissionsSection() && (
          <div className="event-form-guest-permission-wrapper">
            <div
              id="select-guest-permissions"
              className="hoverable-text event-form-guest-permission-text-wrapper secondary-text-color font-weight-200"
              onClick={this.openGuestPermissionsModal}
            >
              <GlobalKeyMapTile
                style={{
                  top: "-20px",
                  left: "0px",
                  fontWeight: 300,
                  zIndex: 1,
                }}
                shortcut={removePlusAndCapitalize(
                  eventFormSectionHotKeysIndex[EVENT_FORM_GUESTS_CAN]
                )}
              />

              <div className="event-form-guest-can-text">Guests can:</div>

              <div
                className={classNames(
                  "event-form-guest-permission-list",
                  this.acceptedGuestPermissions().length > 2 ? "flex-col" : ""
                )}
              >
                {this.renderGuestPermissions()}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  acceptedGuestPermissions() {
    return this.state.guestPermissions.filter((permission) => permission);
  }

  shouldDisplayGuestPermissionsSection() {
    return (
      !this.state.isOnboarding &&
      !this.state.eventNotHavePermissionToEditActualEvent &&
      !this.canModifyButNotOrganizer()
    );
  }

  renderCanNotDisplayAttendees() {
    return (
      <div
        className="default-font-size"
        style={{ marginTop: this.state.shouldHideGuestList ? 20 : 0 }}
      >
        The full list has been hidden at the organizer's request.
        <FullAttendeeList
          attendees={this.state.newlyAddedAttendees}
          editMode={true}
          removeAttendee={(attendee) =>
            this.removeAttendeeFromAttendeeList(attendee)
          }
          showColorBorder={true}
          makeAttendeeOptional={(attendee) =>
            this.makeAttendeeOptional(attendee)
          }
        />
      </div>
    );
  }

  getTotalAttendeeCount() {
    const { newlyAddedAttendees, attendees } = this.state;

    return (newlyAddedAttendees || []).length + (attendees || []).length;
  }

  getAllAttendees(checkForPermissionToEdit=true) {
    if (!this.shouldDisplayAttendees()) {
      return null;
    }

    const {
      newlyAddedAttendees,
      attendees,
      eventNotHavePermissionToEditActualEvent,
    } = this.state;

    if (checkForPermissionToEdit && eventNotHavePermissionToEditActualEvent) {
      return null;
    }
    return [...addDefaultToArray(newlyAddedAttendees), ...addDefaultToArray(attendees)];
  }

  shouldShowMinimizeAttendeesOption() {
    if (!this.isUpdateEvent()) {
      return false;
    }
    const { attendees } = this.state;
    return attendees?.length > 10;
  }

  renderExpandAttendeesChevron() {
    if (!this.shouldShowMinimizeAttendeesOption()) {
      return null;
    }
    const { isAttendeesDropdownOpen } = this.state;
    const onClick = (e) => {
      hasEventPreventDefault(e);
      hasStopEventPropagation(e);
      this.setState({
        isAttendeesDropdownOpen: !isAttendeesDropdownOpen,
      });
    };
    return (
      <ChevronDown
        className={classNames(
          "clickable-icon clickable-icon-with-rotate",
          "transition-transform-200ms",
          isAttendeesDropdownOpen ? "rotate-180-degrees" : ""
        )}
        size={16}
        onClick={onClick}
      />
    );
  }

  renderAttendeeInfo() {
    const {
      newlyAddedAttendees,
      attendees,
      eventNotHavePermissionToEditActualEvent,
    } = this.state;
    if (isEmptyArrayOrFalsey(newlyAddedAttendees) && isEmptyArrayOrFalsey(attendees)) {
      return null;
    }

    const renderList = () => {
      if (
        this.shouldShowMinimizeAttendeesOption() &&
        !this.state.isAttendeesDropdownOpen
      ) {
        return (
          <FullAttendeeList
            attendees={newlyAddedAttendees}
            editMode={true}
            removeAttendee={(attendee) =>
              this.removeAttendeeFromAttendeeList(attendee)
            }
            showColorBorder={true}
            makeAttendeeOptional={(attendee) =>
              this.makeAttendeeOptional(attendee)
            }
          />
        );
      }
      return (
        <>
          <FullAttendeeList
            attendees={newlyAddedAttendees}
            editMode={true}
            removeAttendee={(attendee) =>
              this.removeAttendeeFromAttendeeList(attendee)
            }
            showColorBorder={true}
            makeAttendeeOptional={(attendee) =>
              this.makeAttendeeOptional(attendee)
            }
          />

          <FullAttendeeList
            attendees={attendees}
            editMode={!eventNotHavePermissionToEditActualEvent}
            removeAttendee={(attendee) =>
              this.removeAttendeeFromAttendeeList(attendee)
            }
            showColorBorder={true}
            makeAttendeeOptional={(attendee) =>
              this.makeAttendeeOptional(attendee)
            }
            isInEventForm={true}
            organizer={getEventOrganizer(this.state.originalEvent)}
          />
        </>
      );
    };

    return (
      <div className="text-left">
        <div className={
          classNames(
            "flex items-center justify-between",
            !this.shouldShowMinimizeAttendeesOption() && !this.isUpdateEvent() ? "mb-1.5" : "" // checks if we render this.renderAttendeeCount() or notthis.renderExpandAttendeesChevron()
          )}
        >
          {this.isUpdateEvent() ? this.renderAttendeeCount() : null}
          {this.renderExpandAttendeesChevron()}
        </div>

        <div id={EVENT_FORM_ATTENDEE_SCROLL_CONTAINER}>{renderList()}</div>
      </div>
    );
  }

  renderAttendeeCount() {
    const allAttendees = []
      .concat(this.state.attendees)
      .concat(this.state.newlyAddedAttendees);
    const { distroListDictionary } = this.props.distroListDictionary;
    const { guestCount, acceptedCount } = getGuestAttendanceCounts({
      attendees: allAttendees,
      distroListDictionary,
    });

    return (
      <div className="ml-1.5 mb-1 secondary-text-color mt-1">
        {guestCount} ({acceptedCount} yes)
      </div>
    );
  }

  renderGuestPermissions() {
    return this.state.guestPermissions.map((permission, index) => {
      return (
        <div key={`attendee_permission${index}`} className="margin-left-five">
          {permission}
          {permission && this.state.guestPermissions[index + 1] ? ", " : ""}
        </div>
      );
    });
  }

  isGoogleCalendar() {
    return isGoogle(this.state.calendar);
  }

  isPrimaryCalendar() {
    return getCalendarIsPrimary(this.state.calendar);
  }

  renderAvailabilityAndVisibility() {
    const { calendar, originalEvent, transparency } = this.state;
    const isNewEvent = this.isCreateNew();
    const options = getTransparencyOptions({
      calendar,
      isTemplate: this.isTemplate(),
      originalTransparency: isNewEvent
        ? undefined
        : getEventTransparency(originalEvent) ?? BUSY_DURING_EVENT,
    });
    const { isDarkMode } = this.props;

    return (
      <div id="availability-and-visibility-section">
        <div className="event-form-location-wrapper event-form-availability-visibility items-center">
          <GlobalKeyMapTile
            style={{ top: "-15px", left: "-10px", fontWeight: 300, zIndex: 1 }}
            shortcut={removePlusAndCapitalize(
              eventFormSectionHotKeysIndex[EVENT_FORM_BUSY_FREE]
            )}
          />

          <CustomSelect
            inputId={SELECT_BUSY_OR_FREE}
            isDisabled={options.length <= 1}
            isSearchable={false}
            ref={(input) => {
              this.selectBusyOrFreeSection = input;
            }}
            openMenuOnFocus={true}
            tabSelectsValue={false}
            className={classNames("mr-4", isDarkMode ? "dark-mode-select" : "")}
            classNamePrefix="dark-mode"
            value={options.find((option) => option.value === transparency)}
            onChange={this.setBusyOrFree}
            options={options}
            onKeyDown={(event) =>
              blurInputOnEscape(event, this.selectBusyOrFreeSection)
            }
            overrideStyles={getReactSelectBaseStyle({
              isDarkMode,
              controlWidth: this.isOutlookCalendar() ? 100 : 75,
              showBorder: false,
              menuListStyle: customMenuStyle({ minWidth: "max-content" }),
            })}
          />

          <div>
            <GlobalKeyMapTile
              style={{
                top: "-15px",
                left: "-10px",
                fontWeight: 300,
                zIndex: 1,
              }}
              shortcut={removePlusAndCapitalize(
                eventFormSectionHotKeysIndex[EVENT_FORM_DEFAULT_AVAILABILITY]
              )}
            />

            <CustomSelect
              isSearchable={false}
              inputId={SELECT_VISIBILITY}
              ref={(input) => {
                this.selectVisibilitySection = input;
              }}
              openMenuOnFocus={true}
              tabSelectsValue={false}
              className={classNames(
                "select-visibility",
                this.props.isDarkMode ? "dark-mode-select" : ""
              )}
              classNamePrefix="dark-mode"
              value={{
                label: this.isVisibility(DEFAULT)
                  ? DEFAULT_VISIBILITY
                  : capitalizeFirstLetter(this.state.visibility),
                value: this.state.visibility,
              }}
              onChange={this.setVisibility}
              options={getCalendarVisibilityOptions(calendar)}
              onKeyDown={(event) =>
                blurInputOnEscape(event, this.selectVisibilitySection)
              }
              overrideStyles={getReactSelectBaseStyle({
                isDarkMode,
                showBorder: false,
                controlWidth: undefined,
              })}
            />
          </div>
        </div>

        {this.renderAvailabilityWarning()}
      </div>
    );
  }

  availabilityWarningString() {
    if (this.isVisibility(PUBLIC)) {
      return "* Making this event public will expose all event details to anyone who has access to this calendar, even if they can't see details of other events.";
    } else if (this.isVisibility(PRIVATE)) {
      return '* Making this event private will hide all event details from anyone who has access to this calendar, unless they have "Make changes to events" level of access or higher.';
    } else {
      return null;
    }
  }

  isVisibility(visibility) {
    return this.state.visibility === visibility;
  }

  renderAvailabilityWarning() {
    if (!this.isVisibility(PUBLIC) && !this.isVisibility(PRIVATE)) {
      return null;
    }

    return (
      <div
        style={{ marginTop: 10, fontWeight: 300, width: "90%" }}
        className="mb-2"
      >
        {this.availabilityWarningString()}
      </div>
    );
  }

  renderRemindersSection() {
    return (
      <div
        id="reminderSection"
        className={classNames("event-form-box-container", MARGIN_BETWEEN_BOXES)}
        ref={(input) => {
          this.reminderSection = input;
        }}
      >
        {this.doRemindersExists() && (
          <div className="event-form-reminder-wrapper secondary-text-color">
            <div className="event-form-event-reminder">
              <EventReminder
                userCalendarID={getCalendarUserCalendarID(this.state.calendar)}
                reminders={this.getReminders()}
                editMode={true}
                removeReminder={this.removeReminder}
                allDay={this.state.allDay}
                isAllDayEvent={this.state.allDay}
              />
            </div>
          </div>
        )}

        {this.shouldDisplayAddReminders() &&
          this.renderAddAdditionalReminders()}
      </div>
    );
  }

  getReminders() {
    return this.state.allDay
      ? this.state.allDayReminders
      : this.state.reminders;
  }

  renderAddAdditionalReminders() {
    return (
      <div
        id="select-reminder"
        tabIndex={-1}
        className="hoverable-text event-form-add-reminder-button secondary-text-color mt-1"
        onClick={this.onClickAddReminder}
      >
        <GlobalKeyMapTile
          style={{ top: "-25px", left: "25px", fontWeight: 300, zIndex: 1 }}
          shortcut={removePlusAndCapitalize(
            eventFormSectionHotKeysIndex[EVENT_FORM_NOTIFICATION]
          )}
        />

        <Plus size={12} className="mb-0.5 secondary-text-color" />

        <div className="ml-2.5">{"Add notification"}</div>
      </div>
    );
  }

  getCalendarFromEmail(email) {
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    const {
      allCalendars,
    } = this.props.allCalendars;
    const {
      masterAccount,
    } = this.props.masterAccount;
    return getCalendarFromEmail({
      email,
      allLoggedInUsers,
      allCalendars,
      masterAccount,
    });
  }
  
  shouldDisplayAddReminders() {
    const currentUserCalendar = this.getCalendarFromEmail(this.getUserEmail());

    if (
      getCalendarUserCalendarID(currentUserCalendar) !==
      getCalendarUserCalendarID(this.state.calendar)
    ) {
      return true;
    } else if (this.state.reminders.useDefault) {
      const currentUserDefaultReminders =
        getCalendarDefaultReminders(currentUserCalendar);
      const haveDefaultReminders = currentUserDefaultReminders?.length > 0;

      return (
        !haveDefaultReminders ||
        (haveDefaultReminders && currentUserDefaultReminders.length < 5)
      );
    } else if (
      this.state.allDay &&
      ((this.state.allDayReminders &&
        this.state.allDayReminders.overrides &&
        this.state.allDayReminders.overrides.length < 5) ||
        !this.state.allDayReminders ||
        !this.state.allDayReminders.overrides)
    ) {
      return true;
    } else if (
      !this.state.allDay &&
      ((this.state.reminders &&
        this.state.reminders.overrides &&
        this.state.reminders.overrides.length < 5) ||
        !this.state.reminders ||
        !this.state.reminders.overrides)
    ) {
      return true;
    } else {
      return false;
    }
  }

  fetchDescription() {
    // Need fetch description because we add paragraph tag <p> if it's a pure string
    // if description doesn't change, we don't want to add the paragraph tag to description
    const updatedDescription = this.getDescriptionContent();
    const { masterAccount } = this.props.masterAccount;

    if (
      updatedDescription === renderDefaultSignatureWithEmptyLinesAbove() ||
      updatedDescription === getVimcalPTagSignature()
    ) {
      // Nothing changed in description
      return VIMCAL_SIGNATURE;
    }

    if (
      updatedDescription ===
        renderDefaultSignatureWithEmptyLinesAbove(masterAccount) ||
      updatedDescription === getVimcalPTagSignature(masterAccount)
    ) {
      // remove empty lines above the signature
      return getVimcalRichTextSignature(masterAccount);
    }

    if (
      isNullOrUndefined(this.state.originalDescription) ||
      updatedDescription === this.state.originalDescription ||
      this.removeFirstAndClosingPTag(updatedDescription) ===
        this.state.originalDescription
    ) {
      if (this.state.fromEventToTemplate) {
        return getEventDescription(this.state.originalEvent) ?? "";
      } else if (this.state.isDuplicate) {
        return this.state.originalEventDescription;
      } else if (
        this.state.updateRepeatType === EDIT_RECURRING_FOLLOWING_EVENTS
      ) {
        // since need to create event form scratch
        return updatedDescription;
      } else if (updatedDescription !== this.state.originalEvent?.description) {
        return updatedDescription;
      } else {
        return null;
      }
    }
    return updatedDescription;
  }

  removeFirstAndClosingPTag(string) {
    if (!string || string.length === 0) {
      return "";
    } else {
      let updatedString = string;
      if (string.substr(0, 3) === "<p>") {
        updatedString = updatedString.substr(3);
      }

      if (string.substr(string.length - 4) === "</p>") {
        updatedString = updatedString.substr(0, updatedString.length - 4);
      }

      return updatedString;
    }
  }

  onBlurDescription() {
    let newState = { focusOnDescription: false };

    if (this.state.eventNotHavePermissionToEditActualEvent === DESCRIPTION) {
      newState.warningEditWillOnlyReflectOnThisCalendarSection = null;
    }

    this.setState(newState);
  }

  renderAddDescriptionButton() {
    return (
      <div
        className={classNames(
          "flex items-center event-form-box-container",
          MARGIN_BETWEEN_BOXES
        )}
        onClick={() => this.setState({ hasPressedAddDescription: true })}
      >
        <GlobalKeyMapTile
          style={{ top: "-30px", left: "0px", fontWeight: 300, zIndex: 1 }}
          shortcut={removePlusAndCapitalize(
            eventFormSectionHotKeysIndex[EVENT_FORM_DESCRIPTION]
          )}
        />

        <Plus size={12} className="secondary-text-color mb-0.5" />
        <div className="ml-2.5 hoverable-secondary-text-color">
          Add description
        </div>
      </div>
    );
  }

  shouldRenderFullDescription() {
    return (
      this.state.hasPressedAddDescription ||
      this.state.originalEvent?.description ||
      this.state.originalDescription
    );
  }

  renderDescription(shouldRenderFullDescription = false) {
    // can't just conditional render this since we need this for getting the description (.getEditorContents())
    return (
      <div
        className={classNames(
          "position-relative",
          MARGIN_BETWEEN_BOXES,
          shouldRenderFullDescription ? "" : "hidden",
          "quill-max-content-height"
        )}
      >
        <div
          className="event-form-section-header ml-2.5 secondary-text-color"
          id={"event-form-section-header"}
        >
          Description
        </div>

        {this.renderWarningWillOnlyBeReflectedOnCalendar(DESCRIPTION, {
          top: -20,
          left: 0,
        })}

        <GlobalKeyMapTile
          style={{ top: "0px", left: "0px", fontWeight: 300, zIndex: 1 }}
          shortcut={removePlusAndCapitalize(
            eventFormSectionHotKeysIndex[EVENT_FORM_DESCRIPTION]
          )}
        />

        <div
          id="event-form-description-wrapper"
          className={Classnames(
            "event-form-description-wrapper",
            this.shouldHighLightField()
              ? "highlight-field-color"
              : classNames("event-form-background-container-color", "soft-container-border"),
          )}
        >
          {this.renderDescriptionEditor()}
        </div>
      </div>
    );
  }

  onFocusSection(section, latestFocus) {
    let newState = { latestFocus: latestFocus || section };

    if (this.state.eventNotHavePermissionToEditActualEvent) {
      newState.warningEditWillOnlyReflectOnThisCalendarSection = section;
    }

    this.setState(newState);
  }

  onFocusDescription() {
    let newState = { latestFocus: DESCRIPTION, focusOnDescription: true };

    if (this.state.eventNotHavePermissionToEditActualEvent) {
      newState.warningEditWillOnlyReflectOnThisCalendarSection = DESCRIPTION;
    }

    if (
      !this.state.hasAlreadyCheckedDescription &&
      !this.state.originalDescription &&
      this.description
    ) {
      newState.originalDescription = this.getDescriptionContent();
      newState.hasAlreadyCheckedDescription = true;
    }

    this.setState(newState);
  }

  renderDescriptionEditor() {
    return (
      <EventFormDescription
        description={this.state.description}
        onBlurDescription={this.onBlurDescription}
        id={DESCRIPTION}
        className="event-form-description-input display-linebreak"
        onFocus={this.onFocusDescription}
        elementRef={(input) => {
          this.description = input;
        }}
        focusRef={(input) => {
          this.description = input;
        }}
        tabIndex={13}
        onClickTab={this.goToSaveButton}
        shouldHighLightField={this.shouldHighLightField()}
        onClickEscape={this.onClickEscapeDescription}
        onChange={(description) => {
          // we update description at the end on save -> this is just to handle other state changes
          // we don't need to update state on every letter change, otherwise this could slow things down
          const { warningToDeleteOutlookZoomDescription, conference } =
            this.state;
          if (!warningToDeleteOutlookZoomDescription) {
            return;
          }
          if (conference === ZOOM) {
            this.setState({ warningToDeleteOutlookZoomDescription: false });
            return;
          }
          if (!description.includes(ZOOM_CONFERENCING)) {
            this.setState({ warningToDeleteOutlookZoomDescription: false });
            return;
          }
        }}
      />
    );
  }

  isAttachmentsPermissionsModal() {
    return this.state.modalContent === ATTACHMENTS_PERMISSIONS_MODAL;
  }

  getModalStyle() {
    if (
      window.innerHeight < 750 ||
      this.state.modalContent === CONFERENCE_ROOMS
    ) {
      // if window height is too small -> show default modal
      // we always show the conference room modal as a proper modal
      if (
        [EVENT_REPEAT, CONFERENCE_ROOMS, SELECT_EMAIL].includes(
          this.state.modalContent
        )
      ) {
        const modalStyle = determineDefaultModalStyle(this.props.isDarkMode);
        modalStyle.content.maxHeight = "92vh";
        return modalStyle;
      }
    }

    if (this.state.modalContent === SCHEDULING_ASSISTANT_MODAL) {
      return getSchedulingAssistantModalStyles(this.props.isDarkMode);
    }

    if (this.isAttachmentsPermissionsModal()) {
      return determineDefaultModalStyle(
        this.props.isDarkMode,
        false,
        { content: { overflow: "visible" }},
      );
    }

    return ![
      UPDATE_RECURRING_EVENT_MODAL,
      DISCARD_CHANGES,
      EDIT_WITH_OTHER_GUEST_WARNING,
    ].includes(this.state.modalContent)
      ? {
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "transparent",
            zIndex: MODAL_OVERLAY_Z_INDEXES.LOW_PRIORITY,
          },
          content: {...({
            padding:
              this.state.modalContent === EMOJI ? "10px 3px 5px" : "20px",
            top: this.state.modalTop,
            position: "absolute",
            right: "362px",
            left: "auto",
            bottom: "auto",
            zIndex: 5,
            backgroundColor: getModalBackgroundColor(this.props.isDarkMode),
            color: this.props.isDarkMode
              ? StyleConstants.darkModeModalTextColor
              : "",
            maxHeight: "90vh",
            backdropFilter: MODAL_BLUR,
            WebkitBackdropFilter: MODAL_BLUR,
          }),
          ...getSidePoppedOverModalBorder(this.props.isDarkMode)
        },
        }
      : determineDefaultModalStyle(this.props.isDarkMode);
  }

  renderModal() {
    return (
      <EventModalPopup
        isOpen={this.state.shouldDisplayModal}
        onRequestClose={() => {
          if (isSchedulingAssistantEventPreviewShowing()) {
            return;
          }
          this.closeModal();
        }}
        width={this.state.modalWidth}
        title={this.state.modalTitle}
        style={this.getModalStyle()}
        headerClassName={
          this.isAttachmentsPermissionsModal() ?
            "two-line-header-limit end-quote" :
            ""
        }
      >
        {this.renderModalContent()}
      </EventModalPopup>
    );
  }

  onSelectEmojiInTitle(emoji, text = null, skipGoingToTitle = false) {
    let { summary } = this.state;

    if (text) {
      summary = text;
    }

    let updatedSummary = "";
    let emojiAtBeginningOfSummary = emojiAtBeginningOfString(
      this.state.summary
    );

    if (text && text[0] === " ") {
      updatedSummary = emoji + summary;
    } else if (text) {
      updatedSummary = emoji + " " + summary;
    } else if (emojiAtBeginningOfSummary) {
      updatedSummary = emoji + summary.split(emojiAtBeginningOfSummary)[1];
    } else {
      updatedSummary = emoji + " " + summary;
    }

    this.closeModal();

    this.setState(
      {
        summary: updatedSummary,
        emojiAtBeginningOfSummary: true,
      },
      () => {
        if (text) {
          this.setTemporaryEvents();
        } else if (skipGoingToTitle) {
          // nothing
        } else {
          Broadcast.publish("GO_TO_TITLE");
        }
      }
    );
  }

  renderModalContent() {
    switch (this.state.modalContent) {
      case SCHEDULING_ASSISTANT_MODAL:
        return (
          <SchedulingAssistantContainer
            attendees={this.getAllAttendees()}
            selectedUser={this.getUser()}
            eventStart={this.getCombinedEventStartDateAndTime()}
            eventEnd={this.getCombinedEventEndDateAndTime()}
            calendar={this.state.calendar}
            addAttendees={this.addAttendees}
            setEventStartDate={this.setEventStartDate}
            setEventStartTime={this.setEventStartTime}
            setEventEndTime={this.setEventEndTime}
            setEventDateAndTime={this.setEventDateAndTime}
            startTimeZone={this.state.startTimeZone}
            endTimeZone={this.state.endTimeZone}
            onClose={this.closeModal}
          />
        );
      case EMOJI:
        return (
          <Picker
            onSelect={(emojiObject) => {
              this.onSelectEmojiInTitle(emojiObject.native);
            }}
            darkMode={this.props.isDarkMode}
            autoFocus
            title="Pick your emoji…"
            emoji="point_up"
          />
        );
      case ZOOM_STILL_LOADING_MODAL:
        return (
          <div className="weekly-calendar-modal-content">
            <div className="default-font-size">
              We're setting up your unique Zoom meeting. This usually takes a
              moment.
            </div>
            <div className="flex items-center justify-end mt-5">
              <CustomButton
                buttonType={WHITE_BUTTON}
                onClick={this.closeModal}
                label="OK"
                shouldFocus={true}
              />
            </div>
          </div>
        );
      case ZOOM_ERROR:
        return (
          <div className="weekly-calendar-modal-content">
            <div className="default-font-size">
              There was an error creating a unique Zoom link.
            </div>
            <div className="flex items-center justify-end mt-5">
              <CustomButton
                buttonType={WHITE_BUTTON}
                onClick={this.closeModal}
                label="OK"
                shouldFocus={true}
              />
            </div>
          </div>
        );
      case ADD_ADDTIONAL_REMINDERS:
        return (
          <AddAdditionalReminders
            setAdditionalReminder={this.setAdditionalReminder}
            allDay={this.state.allDay}
            timeList={this.createTimeIntervals(30)}
          />
        );
      case UPDATE_RECURRING_EVENT_MODAL:
        return (
          <WarningUpdateRecurringEvent
            cancel={this.closeModal}
            onClick={this.updateRecurringEventResponse}
            withoutThisEventChoice={this.state.recurringHasChanged}
            withoutAllInstances={this.hasChangedOriginalEventStartDate()}
          />
        );
      case CONFERENCE_ROOMS:
        return (
          <SelectConferenceRoom
            errorFetchingRooms={this.state.errorFetchingRooms}
            isFetchingPaginatedRooms={this.state.isFetchingPaginatedRooms}
            rooms={this.state.availableConferenceRooms}
            unavailableRooms={this.state.unavailableConferenceRooms}
            buildings={this.state.availableBuildings}
            onClick={this.setConferenceRoom}
            selectedRooms={this.state.selectedConferenceRooms}
            userEmail={getUserEmail(this.getUser())}
            isLoading={this.state.isFetchingRoomsAvailability}
            attendees={this.getAllAttendees(false)}
            user={this.getUser()}
            calendar={this.state.calendar}
          />
        );
      case TIME_ZONE:
        return (
          <SelectTimeZone
            startTimeZone={
              this.state.startTimeZone || this.props.currentTimeZone
            }
            endTimeZone={this.state.endTimeZone || this.props.currentTimeZone}
            setEventTimeZone={this.setEventTimeZone}
            separateStartEndTimezone={this.state.separateStartEndTimezone}
            hasTimeZoneBeenSet={this.state.hasTimeZoneBeenSet}
          />
        );
      case EVENT_REPEAT:
        return this.renderRepeatModalContent();
      case START_DATE:
        return (
          <div className="display-flex-center">
            <MonthlyCalendar
              modalCalendarSelectedDay={this.state.eventStartDate}
              onNavigateToDate={this.setEventStartDate}
              noSaveButton={true}
              hideHighlightToday={true}
            />
          </div>
        );
      case END_DATE:
        return (
          <div className="display-flex-center">
            <MonthlyCalendar
              modalCalendarSelectedDay={this.state.eventEndDate}
              onNavigateToDate={(event) => this.setEventEndDate(event)}
              noSaveButton={true}
              hideHighlightToday={true}
            />
          </div>
        );
      case GUEST_PERMISSIONS:
        return (
          <SelectGuestPermissions
            defaultOption={this.state.guestPermissions}
            sendInformationBackToParentComponent={this.setGuestPermissions}
          />
        );
      case ERROR:
        return <div>{this.state.errorMessage}</div>;
      case DISCARD_CHANGES:
        return (
          <DiscardChangesContents
            onCancel={() => this.toggleDisplayModalState("shouldDisplayModal")}
            onDiscard={() =>
              this.exitEditView({
                addEventBackIntoWeeklyCalendar: true,
                removeTemporaryEvent: true,
              })
            }
          />
        );
      case EMAIL_NOT_VALID_ERROR:
        return <div>This email does not have the proper format</div>;
      case EDIT_WITH_OTHER_GUEST_WARNING:
        return (
          <SendEmailUpdateModal
            onClickDismiss={() =>
              this.toggleDisplayModalState("shouldDisplayModal")
            }
            onClickDoNotSend={() => this.setShouldSendUpdate(false)}
            sendUpdate={(message) => this.setShouldSendUpdate(true, message)}
            shouldHideTextArea={this.isCreateNew()}
          />
        );
      case MAESTRO_ZOOM_LOGIN_INFO:
        return (
          <MaestroZoomLoginInfo
            onClose={this.closeModal}
            onClickZoomLogin={this.onLoginZoom}
            type={MAESTRO_ZOOM_MODAL_INFO_TYPE.WITH_ONLY_CHECK_BOX}
          />
        );
      case ATTACHMENTS_PERMISSIONS_MODAL:
        return (
          <GoogleDrivePermissionsModal
            attachments={this.determineAttachmentsForPermissionsModal()}
            attendees={this.determineAttendeesForPermissionsModal()}
            isSubmittingDrivePermissions={this.state.isSubmittingDrivePermissions}
            onClickDismiss={this.closeModal}
            onClickSave={(permissions) => this.createGoogleDrivePermissions(permissions)}
          />
        );
      default:
        return <div></div>;
    }
  }

  isHideAttendees() {
    return !this.state.guestPermissions?.includes(seeGuestListString);
  }

  renderNLPTip() {
    const { masterAccount } = this.props.masterAccount;

    if (
      isTooltipCompleted(masterAccount, tooltipKeys.NLP) ||
      this.state.shouldHideNLPTooltip
    ) {
      return null;
    }

    return (
      <div className="m-4">
        <TooltipBox
          description="You can use natural language to create entire events! The only rule is to start with the title. Try “Coffee 3pm friday at starbucks for 45 min”."
          helpCenterSection={NLP}
          onClick={(e) => {
            hasEventPreventDefault(e);
            hasStopEventPropagation(e);
            this.setState({
              shouldHideNLPTooltip: true,
            });
          }}
          title="Did You Know?"
        />
      </div>
    );
  }

  setAttachments = (attachments) => {
    this.setState({ attachments });
  };

  renderGoogleAttachments() {
    if (this.isTemplate() || this.isOutlookCalendar()) {
      return null;
    }

    return (
      <>
        <div
          className={
            classNames(
              MARGIN_BETWEEN_BOXES,
              "event-form-section-header ml-2.5 secondary-text-color",
            )
          }
          id={"event-form-section-header"}
        >
          Attachments
        </div>
        <GoogleDriveAttachments
          description={this.getDescriptionContent()}
          eventFormState={this.state}
          parentAttachments={this.state.attachments}
          setParentAttachments={this.setAttachments}
          user={this.getUser()}
        />
      </>
    );
  }

  //=================
  // PRIVATE METHODS
  //=================

  onFocusCaptureSummary() {
    this.onFocusSection(SUMMARY);
  }

  setValidAddressWarning(isInvalid) {
    if (isInvalid !== this.state.isInvalidAddress) {
      this.setState({ isInvalidAddress: isInvalid });
    }
  }

  hasEventChangedCalendars() {
    return (
      this.state.originalEvent &&
      this.state.calendar &&
      getCalendarUserCalendarID(this.state.calendar) !==
        getEventUserCalendarID(this.state.originalEvent)
    );
  }

  onBlurNlpBar() {
    this.setState({ shouldDisplayOverlay: false });
  }

  onFocusNlpBar() {
    this.onFocusSection(NLP_BAR);
  }

  removeEventFormOverLay() {
    this.setState({ shouldDisplayOverlay: false });
  }

  setShouldSendUpdate(sendUpdate, message) {
    const updatedModal = filterOutModalFromArray(
      this.state.modalArray,
      EDIT_WITH_OTHER_GUEST_WARNING,
      "content",
    );

    if (message?.length > 0) {
      trackEvent({
        category: "email",
        action: "email_attendees_event_form",
        label: `attendees_count_${this.state.attendees?.length}`,
        userToken: getUserToken(this.getUser()),
      });
    }

    this.setState(
      {
        modalArray: updatedModal,
        shouldSendUpdate: sendUpdate,
        message: message,
      },
      this.processDataOnClickSave,
    );
  }

  async createGoogleDrivePermissions({ role, type }) {
    const updatedModal = filterOutModalFromArray(
      this.state.modalArray,
      ATTACHMENTS_PERMISSIONS_MODAL,
      "content",
    );

    /* Send request to create permissions */
    if (type !== GOOGLE_DRIVE_DONT_GIVE_ACCESS) {
      this.setState({ isSubmittingDrivePermissions: true });
      const path = GOOGLE_DRIVE_ENDPOINTS.CREATE_PERMISSIONS;
      const payloadData = {
        body: JSON.stringify({
          email_addresses: this.determineAttendeesForPermissionsModal()?.map(
            attendee => getAttendeeEmail(attendee),
          ),
          file_ids: this.determineAttachmentsForPermissionsModal()?.map(
            attachment => getAttachmentId(attachment),
          ),
          role,
          type,
        }),
      };

      const response = await fetcherPost({
        email: this.getUserEmail(),
        payloadData,
        url: constructRequestURLV2(path),
      });

      /* TODO: Determine how to handle error */
      if (!response || response?.error) {
        this.setState({ isSubmittingDrivePermissions: false });
        return;
      }
    }

    this.setState(
      {
        modalArray: updatedModal,
      },
      this.processDataOnClickSave,
    );
  }

  isEditSingleEvent() {
    return this.checkMode(EDIT);
  }

  isCreateNew() {
    return this.checkMode(CREATE_NEW);
  }

  shouldDisplayNlpInput() {
    return this.isCreateNew();
  }

  doesLocationOrDescriptionContainConferencing() {
    let locationConference = determineConferenceType(this.state.location);
    let descriptionConference = determineConferenceType(
      this.getDescriptionContent()
    );

    return locationConference || descriptionConference;
  }

  onBlurCaptureSummary() {
    this.setTemporaryEvents();

    this.removeWarningEditsWillOnlyReflectOnThisCalendar();

    let { suggestions, hoverOverSuggestion } = this.state;

    if (!hoverOverSuggestion && suggestions && suggestions.length > 0) {
      this.setState({
        suggestions: [],
        refs: {},
        attendeesQuery: "",
        shouldDisplayPlaceholderText: false,
      });
    }
  }

  setWarningEditsWillOnlyReflectOnThisCalendar(section) {
    if (this.state.eventNotHavePermissionToEditActualEvent) {
      this.setState({
        warningEditWillOnlyReflectOnThisCalendarSection: section,
      });
    }
  }

  onClickEscapeDescription(text) {
    this.setState({ description: text }, () => {
      Broadcast.publish("GLOBAL_ESCAPE");
    });
  }

  onPressEscape() {
    Broadcast.publish("GLOBAL_ESCAPE");
  }

  removeWarningEditsWillOnlyReflectOnThisCalendar() {
    if (this.state.eventNotHavePermissionToEditActualEvent) {
      this.setState({ warningEditWillOnlyReflectOnThisCalendarSection: null });
    }
  }

  updateStringToRichText(description) {
    let withUpdatedNextLine = description.replace(/(?:\r\n|\r|\n)/g, "<br/>");
    let withUpdatedUrl = this.updateTextWithUrl(withUpdatedNextLine);

    return withUpdatedUrl;
  }

  updateTextWithUrl(string) {
    let updatedReturnString = string;

    let parsed = parseHTML(string);

    if (!parsed || !_.isArray(parsed)) {
      return string;
    }

    parsed.forEach((a) => {
      if (_.isString(a)) {
        let substringArray = a.split(" ");
        let updatedSubString;

        substringArray.forEach((s) => {
          if (isUrl(s)) {
            updatedSubString = `<a href="${s}" rel="noopener noreferrer" target="_blank">${s}</a>`;

            updatedReturnString = updatedReturnString.replace(
              s,
              updatedSubString
            );
          }
        });
      }
    });

    return updatedReturnString;
  }

  shouldDisplayAttendees() {
    if (this.state.eventNotHavePermissionToEditActualEvent) {
      return (
        canDisplayAttendees(this.state.originalEvent) && !!this.state.attendees
      );
    } else {
      return !!this.state.attendees;
    }
  }

  isAllDayAndTemplate() {
    return this.state.allDay && this.isTemplate();
  }

  clearComponentHasChangedNotification() {
    this.summaryNotification = null;
    this.startDateNotification = null;
    this.startTimeNotification = null;
    this.endTimeNotification = null;
    this.endDateNotification = null;
    this.locationNotification = null;
    this.attendeeNotification = null;
    this.conferenceNotification = null;
    this.notificationTimer = null;
  }

  setComponentHasChangedNotification(componentName) {
    this.clearComponentHasChangedNotification();

    switch (componentName) {
      case LOCATION:
        this.locationNotification = COMPONENT_NOTIFICATION;
        break;
      case ATTENDEE:
        this.attendeeNotification = COMPONENT_NOTIFICATION;
        break;
      case SUMMARY:
        this.summaryNotification = COMPONENT_NOTIFICATION;
        break;
      case VIDEO_CONFERENCE:
        this.conferenceNotification = COMPONENT_NOTIFICATION;
        break;
      case START_DATE:
        this.startDateNotification = COMPONENT_NOTIFICATION;
        break;
      case END_DATE:
        this.endDateNotification = COMPONENT_NOTIFICATION;
        break;
      case START_TIME:
        this.startTimeNotification = COMPONENT_NOTIFICATION;
        break;
      case END_TIME:
        this.endTimeNotification = COMPONENT_NOTIFICATION;
        break;
      default:
        break;
    }

    this.startComponentHasChangedNotificationTimer();
  }

  startComponentHasChangedNotificationTimer() {
    this.notificationTimer = setTimeout(() => {
      if (!this._isMounted) {
        return;
      }

      this.clearComponentHasChangedNotification();
    }, 500);
  }

  setLocation(location, notifyChange, focusLocationInput = false) {
    this.setState({ location: location });

    if (notifyChange) {
      this.setComponentHasChangedNotification(LOCATION);
    }

    if (focusLocationInput) {
      this.location.focus();
    }
  }

  isTemplate() {
    return this.checkMode(TEMPLATE) || this.checkMode(UPDATE_TEMPLATE);
  }

  isSelectCalendarOption() {
    const { calendar } = this.state;
    return this.isTemplate() && isEmptyObjectOrFalsey(calendar);
  }

  goToEventFormSection(section) {
    switch (section) {
      case EVENT_FORM_SUMMARY:
        this.goToSummary();

        break;
      case EVENT_FORM_ALL_DAY:
        this.setAllDay(!this.state.allDay);

        break;
      case EVENT_FORM_START_DATE_INPUT:
        this.goToStartDate();

        break;
      case EVENT_FORM_START_TIME_INPUT:
        this.goToStartTime();

        break;
      case EVENT_FORM_END_DATE_INPUT:
        this.goToEndDate();

        break;
      case EVENT_FORM_END_TIME_INPUT:
        this.goToEndTime();

        break;
      case EVENT_FORM_REPEAT:
        this.onClickEventRepeat(true);

        break;
      case EVENT_FORM_TIME_ZONE:
        this.onClickTimeZoneIconText();

        break;
      case EVENT_FORM_LOCATION:
        this.goToLocation();

        break;
      case EVENT_FORM_CONFERENCE:
        this.selectConferenceSection?.current?.focus();
        break;
      case EVENT_FORM_ROOM:
        this.selectConferenceRoom();
        break;
      case EVENT_FORM_ATTENDEE:
        this.goToAttendeeList();

        break;
      case EVENT_FORM_GUESTS_CAN:
        this.openGuestPermissionsModal();

        break;
      case EVENT_FORM_COLOR:
        this.goToColor();

        break;
      case EVENT_FORM_EMAIL:
        this.goToSelectEmailSection();

        break;
      case EVENT_FORM_NOTIFICATION:
        this.onClickAddReminder();

        break;
      case EVENT_FORM_BUSY_FREE:
        this.selectBusyOrFreeSection && this.selectBusyOrFreeSection.focus();

        break;
      case EVENT_FORM_DEFAULT_AVAILABILITY:
        this.selectVisibilitySection && this.selectVisibilitySection.focus();

        break;
      case EVENT_FORM_DESCRIPTION:
        if (!this.shouldRenderFullDescription()) {
          this.setState({ hasPressedAddDescription: true }, () => {
            this.goToDescription();
          });
          return;
        }
        this.goToDescription();

        break;
      default:
        this.goToSummary();
    }
  }

  userHasConferenceRooms() {
    if (hasOutlookConferenceRoomFeatureFlag(this.getUser())) {
      // otherwise we can't display the "add conference room" button to open modal
      return true;
    }
    if (this.state.allRooms?.length > 0) {
      return true;
    }
    if (this.state.availableConferenceRooms?.length > 0) {
      return true;
    } else if (this.state.unavailableConferenceRooms?.length > 0) {
      return true;
    }

    return false;
  }

  shouldDisplayConferenceRoomSection() {
    if (this.state.isOnboarding) {
      return false;
    }

    return (
      this.state.selectedConferenceRooms?.length > 0 ||
      this.userHasConferenceRooms()
    );
  }

  loadInitialData() {
    this.loadRooms();

    this.loadInitialEventIntoWeeklyCalendar();

    if (this.setUpInitialDataTimer) {
      clearTimeout(this.setUpInitialDataTimer);

      this.setUpInitialDataTimer = null;
    }

    batch(() => {
      if (this.state.isDuplicate) {
        this.props.setDuplicateEvent(false);
      }

      const { attendees } = this.state;
      if (attendees?.length > 0) {
        const { distroListDictionary } = this.props.distroListDictionary;
        const eventAttendeeEmails = attendees
          .map((c) => getObjectEmail(c))
          .filter((e) => e !== this.getUserEmail());

        // do not add emails that are distro list emails (e.g. teams@team.com should not be searched)
        let emails = eventAttendeeEmails.filter(
          (email) => !isDistroListEmail({ email, distroListDictionary })
        );

        if (this.props.eventFormEmails) {
          emails = emails.concat(this.props.eventFormEmails);
        }

        if (
          this.hasPermissionToViewDistroList() &&
          !isEmptyArray(eventAttendeeEmails)
        ) {
          eventAttendeeEmails.forEach((email) => {
            const groupMembers = getDistroListGroupMembers({
              email,
              distroListDictionary,
            });
            if (!isEmptyArrayOrFalsey(groupMembers)) {
              emails = emails.concat(
                groupMembers.map((m) => getObjectEmail(m))
              );
            }
          });
        }

        // remove any duplicates before we make backend call
        emails = removeDuplicatesFromArray(
          emails.map((email) => formatEmail(email))
        );

        if (attendees.length <= 5) {
          this.onUpdateEventFormEmails(emails);
        } else {
          // go to start of event and turn to day view
          this._originalCalendarView = this.props.selectedCalendarView; // revert back to this view after save event
          const { eventStartDate } = this.state;
          this.props.selectDay(eventStartDate);
          mainCalendarBroadcast.publish(
            MAIN_CALENDAR_BROADCAST_VALUES.DETERMINE_CALENDAR_VIEW_CHANGE,
            1,
            eventStartDate
          );

          this.onUpdateEventFormEmails(emails.slice(0, 10));
        }
      }

      // To remove the current previewed event underneath
      if (!isEmptyObjectOrFalsey(this.props.currentPreviewedEvent)) {
        this.props.removePreviewEvent();
      }
    });
  }

  shouldHighLightField() {
    return this.state.shouldHighlight;
  }

  loadInitialEventIntoWeeklyCalendar() {
    if (this.isCreateNew()) {
      this.setTemporaryEvents();

      if (this.state.isDuplicate && this.state.recurringEventId) {
        this.fetchForRecurringInfo();
      }
    } else if (this.isEditSingleEvent() && this.state.originalEvent) {
      this.setTemporaryEvents(getEventUserEventID(this.state.originalEvent));
    } else if (this.checkMode(RECURRING_EVENT) && this.state.recurringEventId) {
      this.state.originalEvent &&
        this.setTemporaryEvents(getEventUserEventID(this.state.originalEvent));
      this.fetchForRecurringInfo();
    }

    if (
      !this.isEditSingleEvent() &&
      !this.checkMode(TEMPLATE) &&
      !this.checkMode(UPDATE_TEMPLATE) &&
      !this.state.selectedTimeSlot
    ) {
      this.scrollToTime(this.state.eventStartTime);
    }
  }

  fetchForRecurringInfo() {
    if (this.state.originalRecurringEventInstance) {
      return;
    }

    fetchEvent(
      getCalendarUserCalendarID(this.state.calendar),
      this.state.recurringEventId,
      this.getUserEmail()
    ).then((response) => {
      if (!this._isMounted || isEmptyObjectOrFalsey(response)) {
        return;
      }

      const { event } = response;

      const rRuleString = getRRuleStringFromRecurrence(event);

      if (isEmptyObjectOrFalsey(event) || !rRuleString) {
        return;
      }

      const rrule = rrulestr(rRuleString);

      const originalUnchangedState = _.clone(this.props.originalUnchangedState);

      originalUnchangedState.repeatText = capitalizeFirstLetter(rrule.toText());
      originalUnchangedState.recurrenceRules = rrule;

      this.setState({
        originalRecurringEventInstance: event,
        repeatText: capitalizeFirstLetter(rrule.toText()),
        recurrenceRules: rrule,
        originalState: originalUnchangedState,
      });
    });
  }

  createExistingAttendeeList() {
    const combinedAttendees = this.state.attendees.concat(
      this.state.newlyAddedAttendees
    );

    const combinedAttendeeEmailsList = combinedAttendees.map((a) =>
      getObjectEmail(a)
    );

    return combinedAttendeeEmailsList;
  }

  scrollUpComponent() {
    if (document.activeElement && document.activeElement.id === DESCRIPTION) {
      let elementDom = document.getElementById(DESCRIPTION);
      let elementTop = 0;
      let saveButton = document.getElementById(EVENT_FORM_SAVE_BUTTON_ID);
      let saveButtonTop = 0;

      if (saveButton && saveButton.getBoundingClientRect()) {
        saveButtonTop = saveButton.getBoundingClientRect().top;
      }

      if (elementDom && elementDom.getBoundingClientRect()) {
        elementTop = elementDom.getBoundingClientRect().top;
      }

      if (elementTop >= saveButtonTop - 30) {
        this.attendeeSection.scrollIntoView();
      }

      elementDom = null;
      saveButton = null;
    }
  }

  updatedAvailableRoomsAndUpdateTemporaryEvent() {
    if (this.shouldPrefetchRooms()) {
      this.fetchAvailableRooms();
    }

    this.setTemporaryEvents();
  }

  setEventColor(option) {
    if (option.value === IS_CREATE_NEW_COLOR_LABEL) {
      mainCalendarBroadcast.publish("REMOVE_POPUP_EVENT");
      layoutBroadcast.publish(APP_SETTINGS.OPEN_SETTINGS_MODAL, {
        initialContent: APP_SETTINGS.PREFERENCES,
        scrollToSettingContent: APP_SETTINGS.COLOR_LABEL,
        initialSettingsUser: this.getUser(),
      });
      return;
    }

    this.setState(
      {
        eventColorId: option.value,
      },
      this.setTemporaryEvents
    );
  }

  updateTimeAndDate(event) {
    const eventStartDate = startOfDay(event.start);
    const eventEndDate = startOfDay(event.end);

    const startMinsDiff = minDifferentBetweenTimeZones(
      this.state.startTimeZone,
      this.props.currentTimeZone
    );

    const endMinsDiff = minDifferentBetweenTimeZones(
      this.state.endTimeZone,
      this.props.currentTimeZone
    );

    const eventStartTime = addMinutes(event.start, startMinsDiff);
    const eventEndTime = addMinutes(event.end, endMinsDiff);

    this.setState(
      {
        eventStartDate,
        eventEndDate,
        eventStartTime,
        eventEndTime,
      },
      this.updatedAvailableRoomsAndUpdateTemporaryEvent
    );
  }

  determineShouldDisplaySelectInEventTimeBox() {
    if (this.state.fromEventToTemplate) {
      return !this.state.showSelectInitiallyInSelectEventTime;
    } else if (this.checkMode(TEMPLATE)) {
      return true;
    } else {
      return getEventStart(this.state.inputEvent);
    }
  }

  loadRooms() {
    db.fetch(this.getCalendarUserEmail())
      .buildings.toArray()
      .then((response) => {
        if (!this._isMounted) {
          return;
        }

        this.setState({ availableBuildings: response });
      })
      .catch((err) => {
        handleError(err);
      });

    const { inputConferenceRooms } = this.state;
    if (inputConferenceRooms?.length > 0 && this.getCalendarUserEmail()) {
      db.fetch(this.getCalendarUserEmail())
        .conferenceRooms.where("fullName")
        .startsWithAnyOfIgnoreCase(inputConferenceRooms)
        .toArray()
        .then((response) => {
          // To prevent memory leak if user creates event and exits right away before this is done
          if (!this._isMounted) {
            return;
          }

          const conferenceRoomArray = response.map((r) => {
            return r.fullName;
          });

          const selectedConferenceRooms = response.map((r) => {
            return {
              label: r.fullName,
              value: r,
            };
          });

          const nonEditConferenceRooms = inputConferenceRooms.filter(
            (r) => !conferenceRoomArray.includes(r)
          );

          this.setState({
            selectedConferenceRooms,
            nonEditConferenceRooms,
          });
        })
        .catch((err) => {
          handleError(err);
        });
    }

    db.fetch(this.getCalendarUserEmail())
      .conferenceRooms.toArray()
      .then((response) => {
        // To prevent memory leak if user creates event and exits right away before this is done
        if (!this._isMounted) {
          return;
        }

        this.setState(
          {
            allRooms: response,
            availableConferenceRooms: response,
            unavailableConferenceRooms: [],
          },
          () => {
            if (this.shouldPrefetchRooms()) {
              this.fetchAvailableRooms({
                selectedRooms: this.state.inputConferenceRooms,
              });
            }
          }
        );
      })
      .catch((err) => {
        handleError(err);
      });
  }

  getDescriptionContent() {
    return this.description?.getEditorContents() || "";
  }

  // roomListId is optional, but if provided, it will be used to fetch rooms for that building - only for outlook calendar.
  // isPagination is optional, but if provided, it will be used to fetch rooms for that building - only for outlook calendar.
  // if isPagination is true -> we should not clear the state, but instead stack the results
  // const optional param can be: {selectedRooms = null, roomListId = null, isPagination = false}
  fetchAvailableRooms(param) {
    if (this.isTemplate()) {
      return;
    }
    if (param?.isFromSelectBuilding && this._paginationBuildingID === param?.roomListId) {
      // we're already fetching rooms for this building, so we don't need to fetch them again
      return;
    }

    if (
      !isValidJSDate(this.state.eventStartDate) ||
      !isValidJSDate(this.state.eventEndDate) ||
      isAfterDay(this.state.eventStartDate, this.state.eventEndDate)
    ) {
      return;
    }

    const params = {
      timeMin: this.getCombinedEventStartDateAndTime().toISOString(),
      timeMax: this.getCombinedEventEndDateAndTime().toISOString(),
      timeZone: this.props.currentTimeZone,
    };

    /* Require room list id for Outlook availability */
    if (isOutlookUser(this.getUser())) {
      const roomListId = param?.roomListId;
      const page = param?.page;

      if (page) {
        params.page = page;
      }

      /* Can't fetch without roomListId */
      if (!roomListId) {
        const {
          lastSelectedBuildingID,
        } = this.props.conferenceRoomStore;
        const hasMatchingBuilding = this.state.availableBuildings?.some(b => getBuildingIDFromRawObject(b?.buildingObject) === lastSelectedBuildingID);
        if (lastSelectedBuildingID && hasMatchingBuilding) {
          params.room_list_id = lastSelectedBuildingID;
        } else {
          return;
        }
      } else {
        params.room_list_id = roomListId;
      }
      if (params.room_list_id) {
        this._paginationBuildingID = params.room_list_id;
      }
    }

    const payloadData = {
      headers: getDefaultHeaders(),
    };

    const queryParams = constructQueryParams(params);
    const url =
      constructRequestURL(GET_ROOM_AVAILABILITY, true) + `?${queryParams}`;

    if (isEmptyObjectOrFalsey(this.getUser())) {
      return;
    }
    const fetchRoomsFetchID = param?.paginationFetchID || createUUID();
    this._fetchRoomsFetchID = fetchRoomsFetchID;

    const onEarlyFinishFetchingRooms = () => {
      this.setState({ 
        isFetchingRoomsAvailability: false,
        isFetchingPaginatedRooms: false,
      });
    };

    if (!this.state.isFetchingRoomsAvailability || this.state.errorFetchingRooms) {
      this.setState({ isFetchingRoomsAvailability: true, errorFetchingRooms: false });
    }
    return Fetcher.get(
      url,
      payloadData,
      true,
      this.getCalendarUserEmail() || this.getUserEmail()
    )
      .then((response) => {
        // will get back empty object {} if domain doesn't exist (should be taken care of on the front end)
        if (!this._isMounted) {
          return;
        }
        if (this._fetchRoomsFetchID !== fetchRoomsFetchID) {
          // user has requested a new fetch
          return;
        }
        if (isEmptyObjectOrFalsey(response) || response.error) {
          this.setState({ 
            isFetchingRoomsAvailability: false,
            isFetchingPaginatedRooms: false,
            errorFetchingRooms: true,
          });
          return;
        }
        const hasNextPage = !isNullOrUndefined(response?.next_page);
        if (hasNextPage) {
          const { next_page: page } = response;
          this.fetchAvailableRooms({
            ...param,
            isFromSelectBuilding: false,
            isPagination: true,
            paginationFetchID: fetchRoomsFetchID,
            page,
          });
        }
        if (!response?.available_rooms?.calendars) {
          onEarlyFinishFetchingRooms();
          return;
        }
        const { available_rooms } = response;

        const {
          allRooms,
          eventStartTime,
          eventEndTime,
          eventStartDate,
          eventEndDate,
          allDay,
          selectedConferenceRooms,
          inputConferenceRooms,
        } = this.state;

        const getSelectedRooms = () => {
          if (param?.selectedRooms) {
            return param.selectedRooms;
          } else {
            return selectedConferenceRooms
              ? selectedConferenceRooms.map((r) => {
                  return r.label;
                })
              : [];
          }
        };

        const selectedRoomsArray = getSelectedRooms();

        const { availableConferenceRooms, unavailableConferenceRooms } =
          getConferenceRoomAvailability({
            allRooms,
            roomsAvailbiityObject: available_rooms.calendars,
            eventStartTime,
            eventEndTime,
            eventStartDate,
            eventEndDate,
            allDay,
            selectedRoomsArray,
            inputRooms: inputConferenceRooms || [],
            isOutlook: this.isOutlookCalendar(),
          });
        
        const isPagination = param?.isPagination;
        if (isPagination) {
          const updatedAvailableConferenceRooms = (
            this.state.availableConferenceRooms || []
          ).concat(availableConferenceRooms || []);
          /* Make a unique unavailable rooms */
          const existingUnavailableRoomsWithGuard = this.state.unavailableConferenceRooms || [];
          
          // remove rooms that are also returned from the paginated response
          const uniqueUnavailableRooms = unavailableConferenceRooms.concat(
            existingUnavailableRoomsWithGuard.filter(
              (existingUnavailableRooms) =>
                !unavailableConferenceRooms.some(
                  (newUnavailableRooms) =>
                    isSameEmail(getConferenceRoomID(existingUnavailableRooms), getConferenceRoomID(newUnavailableRooms))
                )
            )
          );

          const updatedUnavailableConferenceRooms =
            uniqueUnavailableRooms.filter((room) => !updatedAvailableConferenceRooms.some(
              (availableRoom) =>
                isSameEmail(getConferenceRoomID(availableRoom), getConferenceRoomID(room))
            ));
          this.setState({
            availableConferenceRooms: sortConferenceRooms(updatedAvailableConferenceRooms),
            unavailableConferenceRooms: sortConferenceRooms(updatedUnavailableConferenceRooms),
            isFetchingRoomsAvailability: hasNextPage ? true : false,
            isFetchingPaginatedRooms: hasNextPage, // so we don't show unavailable rooms before getting all results back
            errorFetchingRooms: false,
          });
        } else {
          this.setState({
            availableConferenceRooms: sortConferenceRooms(availableConferenceRooms),
            unavailableConferenceRooms: sortConferenceRooms(unavailableConferenceRooms),
            isFetchingRoomsAvailability: hasNextPage ? true : false,
            isFetchingPaginatedRooms: hasNextPage, // so we don't show unavailable rooms before getting all results back
            errorFetchingRooms: false,
          });
        }
      })
      .catch((err) => {
        handleError(err);
      });
  }

  setCalendar(option) {
    if (this.isTemplate() && option.value === TEMPLATE_SELECT_CALENDAR_VALUE) {
      const updatedState = {
        calendar: null,
        categories: [],
        eventColorId: null,
        eventObject: {
          ...this.state.eventObject,
          extended_properties: {
            ...this.state.eventObject?.extended_properties,
            private: {
              ...this.state.eventObject?.extended_properties?.private,
              tags: "[]",
            },
          },
        },
      };

      this.setState(updatedState, () => {
        this.setTemporaryEvents();
        this.loadRooms();
        if (this.shouldPrefetchRooms()) {
          this.fetchAvailableRooms();
        }
        this.checkUpdateZoomOnChangeUser({ previouslySelectedUser: this.getUser() });
      });
      return;
    }

    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const email = isCalendarOutlookCalendar(option.value)
      ? getCalendarUserEmail(option.value)
      : getCalendarProviderId(option.value);

    const updatedState = { calendar: option.value };

    if (!isSameEmail(option.value?.userEmail, this.getCalendarUserEmail())) {
      updatedState.selectedConferenceRooms = [];
    }

    let updatedAttendees = [];
    let updatedNewlyAttendees = [];

    this.state.attendees.forEach((a) => {
      if (
        isSameEmail(
          getCalendarProviderId(this.state.calendar),
          getObjectEmail(a)
        )
      ) {
        let updatedAttendeeInfo = _.clone(a);
        updatedAttendeeInfo.email = email;

        updatedAttendees = updatedAttendees.concat(updatedAttendeeInfo);
      } else {
        updatedAttendees = updatedAttendees.concat(a);
      }
    });

    this.state.newlyAddedAttendees.forEach((a) => {
      if (
        isSameEmail(
          getCalendarProviderId(this.state.calendar),
          getObjectEmail(a)
        )
      ) {
        const updatedAttendeeInfo = _.clone(a);
        updatedAttendeeInfo.email = email;
        updatedNewlyAttendees =
          updatedNewlyAttendees.concat(updatedAttendeeInfo);
      } else {
        updatedNewlyAttendees = updatedNewlyAttendees.concat(a);
      }
    });

    // Check if selected calendar
    if (isCalendarOutlookCalendar(option.value)) {
      // Check if current conferencing is provided by Outlook
      if (isOutlookConferencingOption(this.state.conference)) {
        const updatedConferencingOption =
        getOutlookCalendarDefaultOnlineMeetingProvider(option.value);

        // Maybe add a popup or notification that we're swapping off conferencing if it's not a conferencing option?
        // Or maybe change to a different conferencing option?
        isOutlookConferencingOption(updatedConferencingOption)
          ? (updatedState.conference = updatedConferencingOption)
          : (updatedState.conference = NO_CONFERENCE_STRING);
      }

      updatedState.colorId = null;
    }

    /* If switching to delegated user calendar we unset tags */
    if (
      isUserDelegatedUser(
        allLoggedInUsers.find((user) =>
          isSameEmail(getUserEmail(user), option.value?.userEmail)
        )
      )
    ) {
      updatedState.eventObject = {
        ...this.state.eventObject,
        extended_properties: {
          private: {
            ...getEventExtendedPropertiesPrivate(this.state.eventObject),
            tags: undefined,
          },
        },
      };

      /* Set to original color */
      updatedState.eventColorId = this.state.originalState.eventColorId;
    }

    updatedState.attendees = updatedAttendees;
    updatedState.newlyAddedAttendees = updatedNewlyAttendees;

    const previouslySelectedUser = this.getUser();
    this.setState(updatedState, () => {
      this.setTemporaryEvents();
      this.loadRooms();
      if (this.shouldPrefetchRooms()) {
        this.fetchAvailableRooms();
      }
      this.checkUpdateZoomOnChangeUser({ previouslySelectedUser });
    });
  }

  getUserEmail() {
    return getUserEmail(this.getUser());
  }

  checkUpdateZoomOnChangeUser({ previouslySelectedUser }) {
    return; // comment out for now.
    // if there's a unique zoom that was created within this session (not on update)
    // need to recreate it with the new user's calendar
    // && this.state.zoomMeeting
    const { masterAccount } = this.props.masterAccount;
    if (
      !this.state.zoomMeeting ||
      !isUserMaestroUser(masterAccount) ||
      previouslySelectedUser.email === this.getUserEmail()
    ) {
      return;
    }

    this.clearCreateZoomStates();
    this.createUniqueZoomLink();
  }

  /// clear zoom states so we can cleanly recreate zoom
  clearCreateZoomStates() {
    this._isCreatingUniqueZoom = false;
    this.setState({
      isNewZoomMeeting: false,
      wasZoomUpdated: false,
      zoomMeeting: null,
    });
    this._hasTriedCreatingUniqueZoomAlready = false;
    clearTimeout(this._zoomTimer);
  }

  isEventTimeAndDateInvalid() {
    if (this.checkMode(TEMPLATE) || this.checkMode(UPDATE_TEMPLATE)) {
      return false;
    } else if (this.state.allDay) {
      return isBeforeDay(this.state.eventEndDate, this.state.eventStartDate);
    } else {
      return IsEventStartAfterEventEnd({
        startDate: this.state.eventStartDate,
        endDate: this.state.eventEndDate,
        startTime: this.state.eventStartTime,
        endTime: this.state.eventEndTime,
        startTimeZone: this.state.startTimeZone,
        endTimeZone: this.state.endTimeZone,
        currentTimeZone: this.props.currentTimeZone,
      });
    }
  }

  onClickEmojiPicker() {
    this.setModalContent("", 350, EMOJI, "emoji");
  }

  onFocusElement(element) {
    this.setState({
      latestFocus: element,
    });
  }

  setAllDay(isAllDay) {
    const { allDay: prevAllDay, hasChangedTransparency } = this.state;

    const updatedState = {
      allDay: isAllDay,
      lastTimeSet: new Date(),
    };

    if (this.isTemplate()) {
      // duration is kept as object of {days: 1, hours: 0, minutes: 30}
      if (isAllDay && !prevAllDay) {
        // going from not all day to all day
        updatedState["duration"] = { days: 1 };
      } else if (!isAllDay && prevAllDay) {
        // going from all day to not all day
        updatedState["duration"] = { hours: 0, minutes: 30 };
      }
    }

    if (
      (this.isCreateNew() || this.checkMode(TEMPLATE)) &&
      !hasChangedTransparency
    ) {
      updatedState["transparency"] = isAllDay
        ? freeDuringEvent
        : busyDuringEvent;
    }

    if (!this.isTemplate() && prevAllDay && !isAllDay) {
      // no need to manipulate time if template
      const currentTime = RoundToClosestMinuteJSDate(new Date(), 30);
      updatedState["eventStartTime"] = startOfHour(setHours(currentTime, 9));
      updatedState["eventEndTime"] = startOfHour(setHours(currentTime, 10));
    }

    this.setState(
      updatedState,
      this.updatedAvailableRoomsAndUpdateTemporaryEvent
    );
  }

  onClickAddReminder() {
    this.setModalContent(
      "Add notifications",
      this.state.allDay ? 460 : 335,
      ADD_ADDTIONAL_REMINDERS,
      "select-reminder",
      500
    );
  }

  openGuestPermissionsModal() {
    this.setModalContent(
      "Select guest permissions",
      300,
      GUEST_PERMISSIONS,
      "select-guest-permissions",
      250
    );
  }

  checkMode(mode) {
    return this.props.mode === mode;
  }

  updateRecurringEventResponse(response) {
    let updateRepeatType = response;

    let updatedModal = filterOutModalFromArray(
      this.state.modalArray,
      UPDATE_RECURRING_EVENT_MODAL,
      "content"
    );

    this.setState(
      {
        updateRepeatType: updateRepeatType,
        modalArray: updatedModal,
      },
      () => {
        this.processDataOnClickSave();
      }
    );
  }

  attendeeAlreadyContainOwnEmail() {
    let response = false;

    const currentCalendarEmail = this.getCalendarEmail();

    if (!currentCalendarEmail) {
      response = true;
    }

    this.state.attendees.forEach((e) => {
      if (isSameEmail(getObjectEmail(e), currentCalendarEmail)) {
        response = true;
      }
    });

    return response;
  }

  getCalendarEmail(inputCalendar) {
    const { calendar } = this.state;
    if (isEmptyObjectOrFalsey(inputCalendar) && isEmptyObjectOrFalsey(calendar)) {
      return "";
    }

    return getCalendarEmail(inputCalendar || calendar);
  }

  createAttendeeAndRoomData() {
    let attendeesList = [];
    let currentAttendees = this.state.attendees || [];
    let newlyAddedAttendees = this.state.newlyAddedAttendees || [];
    let emailToNameIndex = this.props.emailToNameIndex;

    if (newlyAddedAttendees.length > 0 && emailToNameIndex) {
      newlyAddedAttendees.forEach((a) => {
        if (emailToNameIndex[getObjectEmail(a)]) {
          a.displayName = emailToNameIndex[getObjectEmail(a)];
        }
      });
    }

    attendeesList = attendeesList.concat(currentAttendees);
    attendeesList = attendeesList.concat(newlyAddedAttendees);

    this.state.selectedConferenceRooms &&
      this.state.selectedConferenceRooms.forEach((r) => {
        attendeesList = attendeesList.concat({
          email: r.value.resourceEmail,
          displayName: r.label,
          responseStatus: "needsAction",
          resource: true,
        });
      });

    const filteredList = attendeesList
      .filter((a) => !!getObjectEmail(a))
      .map((a) => {
        if (!a.email) {
          return null;
        }

        a.email = getObjectEmail(a); // if not trimeed, error occurs
        return a;
      });

    return filteredList;
  }

  determineAttendee(google_event) {
    // Need to check for this.state.attendees, this.state.newlyAddedAttendees and conference rooms
    // If all empty -> omit
    let updatedAttendees = [];

    if (this.state.attendees && this.state.attendees.length > 0) {
      updatedAttendees = updatedAttendees.concat(this.state.attendees);
    }

    if (
      this.state.newlyAddedAttendees &&
      this.state.newlyAddedAttendees.length > 0
    ) {
      updatedAttendees = updatedAttendees.concat(
        this.state.newlyAddedAttendees
      );
    }

    if (
      this.state.selectedConferenceRooms &&
      this.state.selectedConferenceRooms.length > 0
    ) {
      this.state.selectedConferenceRooms &&
        this.state.selectedConferenceRooms.forEach((r) => {
          updatedAttendees = updatedAttendees.concat({
            email: r.value.resourceEmail,
            displayName: r.label,
            responseStatus: "needsAction",
            resource: true,
          });
        });
    }

    let returnEvent = _.clone(google_event);
    returnEvent = _.omit(returnEvent, "attendees");

    if (updatedAttendees.length > 0) {
      returnEvent.attendees = updatedAttendees;
    }

    return returnEvent;
  }

  removeSelectedRoom(room) {
    let updatedList = _.clone(this.state.selectedConferenceRooms).filter(
      (r) => r.label !== room.label
    );

    if (updatedList.length === 0) {
      updatedList = null;
    }

    this.setState({
      selectedConferenceRooms: updatedList,
    });
  }

  removeNonEditableSelectedRoom(room) {
    let updatedList = this.state.nonEditConferenceRooms?.filter(
      (r) => r !== room
    );

    if (isEmptyArrayOrFalsey(updatedList)) {
      updatedList = null;
    }

    this.setState({
      nonEditConferenceRooms: updatedList,
    });
  }

  setConferenceRoom(rooms) {
    this.setState({
      selectedConferenceRooms: rooms?.length > 0 ? rooms : null,
      shouldDisplayModal: false,
    });
  }

  selectConferenceRoom() {
    if (!this.shouldPrefetchRooms()) {
      // only check here if we're not pre-fetching
      this.fetchAvailableRooms();
    }
    this.setModalContent(
      "Select Conference Rooms",
      550,
      CONFERENCE_ROOMS,
      "select-conference-room",
      660
    );
  }

  onClickSelectCustomRepeat() {
    this.setState({ shouldDisplayEventRepeatCustomModal: true });
  }

  onClickTimeZoneIconText() {
    this.setModalContent("Select Timezone", 400, TIME_ZONE, "select-time-zone");
  }

  shouldSetEndTime() {
    this.setState({ shouldSetEndTime: false, clickedSelect: true });
  }

  onClickEndDate() {
    this.setModalContent("Select End Date", 320, END_DATE, EVENT_FORM_END_DATE);
  }

  onUpdateEventFormEmails(newEmails, userEmail) {
    const prevEmails = _.clone(this.props.eventFormEmails);
    this.props.setEventFormEmails(newEmails);
    const currentUserEmail = userEmail ?? this.getCalendarUserEmail();
    const filteredNewEmails = (newEmails ?? []).filter(
      (email) => email !== currentUserEmail
    );
    Broadcast.publish("ON_UPDATE_EVENT_FORM_EMAIL", {
      prevEmails,
      newEmails: filteredNewEmails,
      userEmail: currentUserEmail,
    });
  }

  saveButtonStyle() {
    return {
      width: this.props.isMobileView ? "calc(100% - 20px)" : 332,
      height: StyleConstants.saveButtonHeight,
      zIndex: 1,
      marginTop: "0px",
      marginLeft: "12px",
    };
  }

  eventHeaderColor() {
    const color = this.getBackgroundColor();
    return {
      position: "absolute",
      left: this.shouldDisplayNlpInput() ? -12 : 0,
      width: 5,
      height: 35,
      backgroundColor: color,
      fontSize: 1,
      zIndex: 5,
    };
  }

  applyTemplateToEvent(templateToBeApplied) {
    let { json, shouldApplyTitle, fromNLP } = templateToBeApplied;

    trackEvent({
      category: "home",
      action: "applied_event_template",
      label: "event_template",
      userToken: getUserToken(this.getUser()),
    });

    let template = json.raw_json;
    let keys = Object.keys(template);

    let {
      summary,
      description,
      location,
      reminders,
      attendees,
      conference,
      guestPermissions,
      eventStartTime,
      eventEndTime,
      startTimeZone,
      endTimeZone,
      allDay,
      selectedConferenceRooms,
      eventEndDate,
      timeDifferenceBetweenEventStartEnd,
      dateDifferenceBetweenEventStartEnd,
      eventColorId,
      transparency,
      outOfOfficeAutoDeclineMode,
      outOfOfficeDeclineMessage,
      visibility,
      calendar,
      zoomMeetingError,
      eventObject,
    } = this.state;

    attendees = attendees.filter((a) => !isAttendeeResource(a));

    if (
      keys.includes("summary") &&
      (this.state.summary === "" || shouldApplyTitle)
    ) {
      summary = template["summary"];
    }

    const currentUserCalendar = this.getCalendarFromEmail(this.getUserEmail());

    if (
      template.google_id &&
      this.state.calendar &&
      isValidCalendar(currentUserCalendar) &&
      this.getUserEmail() === getCalendarProviderId(this.state.calendar) &&
      template.google_id !== this.getUserEmail() &&
      this.state.writableCalendars
    ) {
      // only set calendar id if calendar id has not been changed
      this.state.writableCalendars.forEach((c) => {
        if (getCalendarProviderId(c.value) === template.google_id) {
          calendar = c.value;
        }
      });
    }
    const { masterAccount } = this.props.masterAccount;

    if (keys.includes("description")) {
      if (
        template["description"].trim() === this.state.description.trim() ||
        this.state.description
          .trim()
          .substring(0, template["description"].trim().length) ===
          template["description"]
      ) {
        description = this.state.description;
      } else if (template["description"].toLowerCase().indexOf("vimcal") >= 0) {
        description =
          template["description"] +
          "\n\n" +
          replaceVimcalSignature(this.state.description, masterAccount);
      } else {
        description = template["description"] + "\n\n" + this.state.description;
      }

      description = this.updateStringToRichText(description);
    }

    if (keys.includes("location") && this.state.location === "") {
      location = template["location"];
    }

    if (
      keys.includes("reminders") &&
      _.isEqual(this.state.reminders, { useDefault: true }) &&
      !isEmptyObjectOrFalsey(template["reminders"]) &&
      !template["reminders"].useDefault
    ) {
      reminders = template["reminders"];
      reminders.useDefault = false;
    }

    if (keys.includes("attendees")) {
      const emailsInAttendeesList = this.state.attendees.map((attendee) => {
        return attendee.email;
      });

      template["attendees"].forEach((attendee) => {
        if (
          !emailsInAttendeesList.includes(getObjectEmail(attendee)) &&
          !isAttendeeResource(attendee)
        ) {
          attendees = attendees.concat(attendee);
        }
      });
    }

    // Conference rooms
    if (template["attendees"] && template["attendees"].length > 0) {
      let rooms =
        template["attendees"]
          .filter((r) => isAttendeeResource(r))
          .map((r) => {
            return r.displayName;
          }) || [];

      if (rooms.length > 0) {
        db.fetch(this.getCalendarUserEmail())
          .conferenceRooms.where("fullName")
          .startsWithAnyOfIgnoreCase(rooms)
          .toArray()
          .then((response) => {
            if (!this._isMounted) {
              return;
            }

            let updatedConferenceRoom = response.map((r) => {
              return {
                label: r.fullName,
                value: r,
              };
            });

            if (!selectedConferenceRooms) {
              // currently does not have conference rooms
              selectedConferenceRooms = updatedConferenceRoom;
            } else {
              // add more conference rooms
              let roomNamesInConferenceRoom = selectedConferenceRooms.map(
                (c) => {
                  return c.label;
                }
              );

              updatedConferenceRoom.forEach((r) => {
                if (!roomNamesInConferenceRoom.includes(r.label)) {
                  selectedConferenceRooms = selectedConferenceRooms.concat(r);
                }
              });
            }

            this.setState({ selectedConferenceRooms });
          })
          .catch((err) => {
            handleError(err);
          });
      }
    }

    if (
      keys.includes("conferenceData") &&
      this.state.conference === noConferenceString
    ) {
      if (keys.includes("conferenceData")) {
        const templateConferenceData = getTemplateConferenceData(json);

        if (
          this.isOutlookCalendar() &&
          isTemplateOutlookConferencing(templateConferenceData)
        ) {
          const outlookConferenceType = templateConferenceData.conferenceType;
          conference = outlookConferenceType;
        } else if (isZoomFromIntegrationTemplate(templateConferenceData)) {
          conference = zoomString;
          !this.isDefaultZoomPersonalLink() &&
            this.createUniqueZoomLink();
        } else if (
          !this.isOutlookCalendar() &&
          isHangoutGSuiteIntegration(templateConferenceData)
        ) {
          conference = googleHangoutString;
        } else if (isTemplateZoomConferencing(templateConferenceData)) {
          if (this.isDefaultZoomPersonalLinkAndMissingLink()) {
            layoutBroadcast.publish(APP_SETTINGS.OPEN_SETTINGS_MODAL, {
              initialContent: APP_SETTINGS.CONFERENCING,
              conferencingSetting: CONFERENCING_SETTINGS_ID.ZOOM,
            });
          } else if (this.isDefaultZoomUniqueAndMissingLink()) {
            // if not logged into zoom -> show modal to login
            zoomMeetingError = REAUTH_ZOOM_ERROR;
          } else {
            conference = zoomString;
            !this.isDefaultZoomPersonalLink() &&
              this.createUniqueZoomLink();
          }
        } else if (isTemplatePhoneConferencing(templateConferenceData)) {
          conference = PHONE_NUMBER_CONFERENCE;
        } else if (isTemplateWhatsAppConferencing(templateConferenceData)) {
          conference = WHATS_APP_STRING;
        } else if (
          isTemplateCustomConferencing(
            templateConferenceData,
            this.getUser()
          ) &&
          isValidCustomConferencing(this.getUser())
        ) {
          conference = getCustomConferencingName({ user: this.getUser() });
        } else {
          conference = noConferenceString;
        }
      }
    }

    const defaultPermission = determineDefaultGuestPermissions({
      user: this.getUser(),
    });
    if (arrayContainSameItems(this.state.guestPermissions, defaultPermission)) {
      // only update if user hasn't already updated the permissions
      if (getTemplateGuestPermissions(template, GUESTS_CAN_MODIFY)) {
        guestPermissions = [
          modifyEventsString,
          inviteOthersString,
          seeGuestListString,
        ];
      } else {
        const inviteOthers = getTemplateGuestPermissions(
          template,
          GUESTS_CAN_INVITE_OTHERS
        )
          ? inviteOthersString
          : null;
        const seeGuestList = getTemplateGuestPermissions(
          template,
          GUESTS_CAN_SEE_OTHER_GUESTS
        )
          ? seeGuestListString
          : null;

        guestPermissions = [null, inviteOthers, seeGuestList];
      }
    }

    if (keys.includes("colorId") && this.isGoogleCalendar()) {
      eventColorId = template["colorId"];
    }

    if (keys.includes("transparency")) {
      transparency = template["transparency"];
    } else if (
      transparency !== BUSY_DURING_EVENT &&
      !template["transparency"]
    ) {
      // by default, we set the transparency to BUSY_DURING_EVENT
      transparency = BUSY_DURING_EVENT;
    }

    if (transparency === OUT_OF_OFFICE_DURING_EVENT) {
      if (keys.includes("outOfOfficeAutoDeclineMode")) {
        outOfOfficeAutoDeclineMode = template["outOfOfficeAutoDeclineMode"];
      }

      if (keys.includes("outOfOfficeDeclineMessage")) {
        outOfOfficeDeclineMessage = template["outOfOfficeDeclineMessage"];
      }
    }

    if (keys.includes("visibility")) {
      visibility = template["visibility"];
    }

    if (keys.includes("extended_properties")) {
      if (eventObject) {
        const updatedTagsString = safeJSONStringify(getTagsFromTemplate(json));
        if (updatedTagsString) {
          eventObject = {
            ...eventObject,
            extended_properties: {
              private: {
                ...getEventExtendedPropertiesPrivate(eventObject),
                tags: updatedTagsString,
              },
            },
          };
        }
      } else {
        eventObject = {
          extended_properties: template["extended_properties"],
        };
      }
    }

    // apply time below
    let temporaryStart = CombineDateAndTimeJSDate(
      this.state.eventStartDate,
      eventStartTime
    );

    if (keys.includes("allDay")) {
      allDay = true;
    }

    if (keys.includes("duration")) {
      let updatedEndTime;

      if (keys.includes("allDay")) {
        const dayDuration =
          template.duration.days === 0
            ? template.duration.days
            : this.isOutlookCalendar()
            ? template.duration.days
            : template.duration.days - 1;

        updatedEndTime = addDays(temporaryStart, dayDuration);
      } else {
        updatedEndTime = add(temporaryStart, {
          hours: template.duration.hours,
          minutes: template.duration.minutes,
        });
      }

      eventEndTime = startOfMinute(
        set(new Date(), {
          hours: updatedEndTime.getHours(),
          minutes: updatedEndTime.getMinutes(),
        })
      );

      timeDifferenceBetweenEventStartEnd = differenceInMinutes(
        updatedEndTime,
        temporaryStart
      );
      dateDifferenceBetweenEventStartEnd = differenceInDays(
        updatedEndTime,
        temporaryStart
      );

      eventEndDate = startOfDay(
        set(new Date(), {
          year: updatedEndTime.getFullYear(),
          month: updatedEndTime.getMonth(),
          date: updatedEndTime.getDate(),
        })
      );
    }
    // apply time above

    this.description?.setEditorContents(
      this.description.getEditor(),
      description
    );

    // need to set before setState, otherwise event will have changed during set state
    const shouldFocusNLPSection =
      this.shouldDisplayNlpInput() && !this.hasStateChanged();

    const updatedState = {
      summary,
      description,
      originalDescription: description,
      location,
      reminders,
      attendees,
      conference,
      guestPermissions,
      eventStartTime,
      eventEndDate,
      eventEndTime,
      allDay: allDay,
      startTimeZone,
      endTimeZone,
      timeDifferenceBetweenEventStartEnd,
      dateDifferenceBetweenEventStartEnd,
      eventColorId,
      transparency,
      outOfOfficeAutoDeclineMode,
      outOfOfficeDeclineMessage,
      visibility,
      calendar,
      zoomMeetingError,
    };
    if (eventObject) {
      updatedState.eventObject = eventObject;
    }

    this.setState(updatedState, () => {
      if (fromNLP) {
        // apply from nlp section -> do nothing
      } else if (shouldFocusNLPSection) {
        this.addDefaultTextInsideNLP();
      } else {
        this.goToSummary();
      }

      this.setTemporaryEvents();
    });
  }

  addDefaultTextInsideNLP() {
    const hasEmojiAtBeginning = emojiAtBeginningOfString(this.state.summary);
    const nlpText = hasEmojiAtBeginning
      ? this.state.summary.split(hasEmojiAtBeginning)[1].trim()
      : this.state.summary;

    Broadcast.publish("SET_NLP_QUERY_TEXT", nlpText + "&nbsp;");
  }

  pasteContentIntoField(text) {
    if (
      [SUMMARY, LOCATION, ATTENDEE, DESCRIPTION].includes(
        this.state.latestFocus
      )
    ) {
      this.setState((prevState, props) => {
        return {
          [this.state.latestFocus]: prevState[this.state.latestFocus]
            ? prevState[this.state.latestFocus] + text
            : text,
        };
      });

      if (
        [SUMMARY, LOCATION].includes(this.state.latestFocus) &&
        this[this.state.latestFocus]
      ) {
        this[this.state.latestFocus].focus();
      } else if (this.state.latestFocus === DESCRIPTION) {
        if (!this.description) {
          return;
        }

        this.description.focus();

        const editorSelection = this.description.getEditorSelection();

        this.description
          .getEditor()
          .insertText(editorSelection.index, text, true);
      }
    }
  }

  focusField() {
    if (
      [SUMMARY, LOCATION, DESCRIPTION].includes(this.state.latestFocus) &&
      this[this.state.latestFocus]
    ) {
      this[this.state.latestFocus].focus();
    } else if (this.state.latestFocus === NLP_BAR) {
      this.nlpBar && this.nlpBar.focus();
    } else if (this.state.latestFocus === EMAIL_COLOR) {
      this.selectEmailColorSection && this.selectEmailColorSection.focus();
    } else if (this.state.latestFocus === SELECT_EMAIL) {
      this.selectEmailSection && this.selectEmailSection.focus();
    } else if (this.state.latestFocus === ATTENDEE && this._isMounted) {
      this.goToAttendeeList();
    } else if (this.state.latestFocus === START_TIME) {
      this.selectStartTimeRef &&
        this.selectStartTimeRef.current &&
        this.selectStartTimeRef.current.setFocus();
    } else if (this.state.latestFocus === END_TIME) {
      this.selectEndTimeRef &&
        this.selectEndTimeRef.current &&
        this.selectEndTimeRef.current.setFocus();
    }
  }

  isDefaultZoomPersonalLink() {
    return isDefaultZoomPersonalLink(this.getUser());
  }

  determineEmailColor() {
    const userCalendarID = getCalendarUserCalendarID(this.state.calendar);
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    const {
      masterAccount,
    } = this.props.masterAccount;

    if (this.state.fromEventToTemplate) {
      if (
        this.state.eventColorId &&
        GoogleColors.primaryEventsColors[this.state.eventColorId]
      ) {
        return GoogleColors.primaryEventsColors[this.state.eventColorId].color;
      } else {
        const { allCalendars } = this.props.allCalendars;
        return getCurrentUserDefaultColor({
          currentUserEmail: this.getUserEmail(),
          allCalendars,
          masterAccount,
          allLoggedInUsers,
        });
      }
    }

    const { allCalendars } = this.props.allCalendars;
    return determineCalendarColor(allCalendars[userCalendarID]);
  }

  shouldDisplaySelectedRecurring() {
    // should display the actual recurring vs just "Time zone";
    return this.state.repeatText !== "Does not repeat";
  }

  shouldDisplaySelectedTimeZone() {
    // should display the actual time zone vs just "Time zone";
    const { hasTimeZoneBeenSet, startTimeZone } = this.state;

    return (
      hasTimeZoneBeenSet ||
      startTimeZone !== guessTimeZone() ||
      this.shouldSeparateTimeZones()
    );
  }

  shouldSeparateTimeZones() {
    const { separateStartEndTimezone, startTimeZone, endTimeZone } = this.state;

    return (
      separateStartEndTimezone || (endTimeZone && startTimeZone !== endTimeZone)
    );
  }

  shouldDisplayDifferentTimeZoneWarning() {
    return (
      this.state.startTimeZone &&
      (HoursDifferenceBetweenTimeZones(
        this.state.startTimeZone,
        "Universal"
      ) !==
        HoursDifferenceBetweenTimeZones(
          this.props.currentTimeZone,
          "Universal"
        ) ||
        HoursDifferenceBetweenTimeZones(this.state.endTimeZone, "Universal") !==
          HoursDifferenceBetweenTimeZones(
            this.props.currentTimeZone,
            "Universal"
          ))
    );
  }

  onClickMainCalendarSlot(slot) {
    if (!slot || !slot.start || !slot.end) {
      this.onPressExit();
      return;
    }

    const { start, end, isMove } = slot;
    const shouldUpdateEventDateTime = () => {
      return (
        isMove ||
        this.hasStateChanged() || // this also checks creating new events after adding attendees
        this.isUpdateEvent() ||
        this.props.eventFormEmails?.length > 0
      );
    };

    if (shouldUpdateEventDateTime()) {
      const isSlotAllDay = isEventSlotAllDayEvent({
        eventStart: start,
        eventEnd: end, // do not use getEventEnd() below because we're using end to check if it's all day here
      });

      if (isSlotAllDay !== this.state.allDay) {
        this.setAllDay(!this.state.allDay);
      }

      const getEventStart = () => {
        const { startTimeZone } = this.state;
        const { currentTimeZone } = this.props;
        if (startTimeZone && startTimeZone !== currentTimeZone) {
          return getTimeInAnchorTimeZone(start, currentTimeZone, startTimeZone);
        }
        return start;
      };
      const eventStart = getEventStart();
      const getEventEnd = () => {
        if (isOnClickSlot(slot)) {
          return addMinutes(eventStart, this.getEventDuration());
        }
        const { endTimeZone } = this.state;
        const { currentTimeZone } = this.props;
        if (endTimeZone && endTimeZone !== currentTimeZone) {
          // if different time zone on drag
          return getTimeInAnchorTimeZone(end, currentTimeZone, endTimeZone);
        }
        return end;
      };
      const eventEnd = getEventEnd();

      this.setEventStartTime(eventStart);
      this.setEventEndTime(eventEnd);
      this.setEventStartDate(eventStart);

      if (isSlotAllDay) {
        this.setEventEndDate(subDays(end, 1)); // if all day, use end
      } else {
        this.setEventEndDate(eventEnd);
      }
      return;
    }

    this.onPressExit();
  }

  onPressExit() {
    if (this.hasStateChanged()) {
      this.setModalContent("Discard unsaved changes?", 420, DISCARD_CHANGES);
    } else {
      this.exitEditView({
        addEventBackIntoWeeklyCalendar: true,
        removeTemporaryEvent: true,
      });
    }
  }

  hasStateChanged(omitList = null) {
    let initialState = this.state.originalState;

    let state = this.state;

    let keys = [
      PROPERTY_FIELDS.SUMMARY,
      PROPERTY_FIELDS.ALL_DAY,
      PROPERTY_FIELDS.EVENT_START_DATE,
      PROPERTY_FIELDS.EVENT_START_TIME,
      PROPERTY_FIELDS.START_TIME_ZONE,
      PROPERTY_FIELDS.EVENT_END_DATE,
      PROPERTY_FIELDS.EVENT_END_TIME,
      PROPERTY_FIELDS.END_TIME_ZONE,
      "recurringHasChanged",
      PROPERTY_FIELDS.LOCATION,
      PROPERTY_FIELDS.CONFERENCE,
      PROPERTY_FIELDS.ATTENDEES,
      "newlyAddedAttendees",
      PROPERTY_FIELDS.GUEST_PERMISSIONS,
      PROPERTY_FIELDS.COLOR_ID,
      PROPERTY_FIELDS.CALENDAR,
      PROPERTY_FIELDS.REMINDERS,
      PROPERTY_FIELDS.ALL_DAY_REMINDERS,
      PROPERTY_FIELDS.TRANSPARENCY,
      PROPERTY_FIELDS.VISIBILITY,
      PROPERTY_FIELDS.DESCRIPTION,
    ];

    if (omitList?.length > 0) {
      keys = keys.filter((k) => !omitList.includes(k));
    }

    if (!initialState) {
      return false;
    } else {
      let hasChanged = false;

      Object.keys(initialState).forEach((k) => {
        if (!hasChanged && keys.includes(k) && initialState[k] !== state[k]) {
          hasChanged = true;
        }
      });

      return hasChanged;
    }
  }

  doRemindersExists() {
    if (this.state.allDay) {
      if (this.state.allDayReminders.overrides) {
        return this.state.allDayReminders.overrides.length > 0;
      }
    } else if (this.state.reminders.overrides) {
      return this.state.reminders.overrides.length > 0;
    } else if (this.state.reminders.useDefault) {
      return true;
    } else {
      return false;
    }
  }

  makeAttendeeOptional(attendee) {
    const {
      newlyAddedAttendees,
      attendees,
    } = this.state;
    const updatedNewlyAddedAttendees = [];
    const updatedAttendees = [];

    if (!isEmptyArrayOrFalsey(newlyAddedAttendees)) {
      newlyAddedAttendees.forEach((a) => {
        if (isSameEmail(getObjectEmail(a), getObjectEmail(attendee))) {
          const updatedAttendee = {...a};
          updatedAttendee.optional = !a.optional;
          updatedNewlyAddedAttendees.push(updatedAttendee);
        } else {
          updatedNewlyAddedAttendees.push(a);
        }
      });
    }
    if (!isEmptyArrayOrFalsey(attendees)) {
      attendees.forEach((a) => {
        if (isSameEmail(getObjectEmail(a), getObjectEmail(attendee))) {
          const updatedAttendee = {...a};
          updatedAttendee.optional = !a.optional;
          updatedAttendees.push(updatedAttendee);
        } else {
          updatedAttendees.push(a);
        }
      });
    }

    this.setState({
      newlyAddedAttendees: updatedNewlyAddedAttendees,
      attendees: updatedAttendees,
    });
  }

  removeArrayOfAttendees(removedList) {
    let newAttendeesList = _.clone(this.state.newlyAddedAttendees);

    newAttendeesList = newAttendeesList.filter(
      (a) => !removedList.includes(a.email)
    );

    let updatedEmails = _.clone(this.props.eventFormEmails);

    if (updatedEmails) {
      updatedEmails = updatedEmails.filter((e) => !removedList.includes(e));

      let updatedColorIndex = _.clone(
        this.props.temporarySecondaryCalendarColorsIndex
      );

      removedList.forEach((e) => {
        updatedColorIndex = _.omit(updatedColorIndex, e);
      });

      batch(() => {
        this.props.setTemporarySecondaryCalendarColorsIndex(updatedColorIndex);
        this.onUpdateEventFormEmails(updatedEmails);
      });
    }

    let newState = { newlyAddedAttendees: newAttendeesList };

    // remove self as an attendee if everyone else has been removed
    if (
      newAttendeesList &&
      newAttendeesList.length === 1 &&
      newAttendeesList[0].self
    ) {
      newState.newlyAddedAttendees = [];
      newState.conference = noConferenceString;
    }

    this.setState(newState);
  }

  removeAttendeeFromAttendeeList(element) {
    const {
      newlyAddedAttendees,
      attendees,
    } = this.state;
    const updatedNewlyAddedAttendees = (newlyAddedAttendees || []).filter(
      (attendee) => !isSameEmail(getObjectEmail(attendee), getObjectEmail(element)),
    );
    const updatedAttendees = (attendees || []).filter(
      (attendee) => !isSameEmail(getObjectEmail(attendee), getObjectEmail(element))
    );
    this.setState({
      newlyAddedAttendees: updatedNewlyAddedAttendees,
      attendees: updatedAttendees,
    });

    if (isEmptyArrayOrFalsey(this.props.eventFormEmails)) {
      return;
    }
    const updatedEmails = this.props.eventFormEmails.filter(e => !isSameEmail(e, getObjectEmail(element)));
    batch(() => {
      this.props.setTemporarySecondaryCalendarColorsIndex(
        _.omit(
          this.props.temporarySecondaryCalendarColorsIndex,
          getObjectEmail(element)
        )
      );
      this.onUpdateEventFormEmails(updatedEmails);
    });
  }

  removeReminder(reminder) {
    // Have to deal with both all day and not all day

    let reminderState = this.state.allDay ? "allDayReminders" : "reminders";
    const currentUserCalendar = this.getCalendarFromEmail(this.getUserEmail());

    if (this.state[reminderState] && this.state[reminderState].useDefault) {
      let updatedReminders = [];

      if (getCalendarDefaultReminders(currentUserCalendar)?.length > 0) {
        updatedReminders = getCalendarDefaultReminders(currentUserCalendar);
      }

      updatedReminders = updatedReminders.filter(
        (r) => r.method !== reminder.method || r.minutes !== reminder.minutes
      );

      this.setState({
        reminders: { useDefault: false, overrides: updatedReminders },
      });
    } else {
      let newReminderState = this.state[reminderState].overrides.filter(
        (item) =>
          item.method !== reminder.method || item.minutes !== reminder.minutes
      );

      this.setState({
        [reminderState]: { useDefault: false, overrides: newReminderState },
      });
    }
  }

  constructEmojiIconStyle() {
    let style = {
      display: "flex",
      marginRight: "10px",
      marginTop: "5px",
      marginLeft: -5,
    };

    if (emojiAtBeginningOfString(this.state.summary)) {
      style["opacity"] = 0.9;
      style["marginRight"] = "5px";
      style["fontSize"] = "20px";
    } else if (this.state.modalContent === EMOJI) {
      style["opacity"] = 0.9;
    }

    return style;
  }

  //================
  // EVENT HANDLERS
  //================

  onClickStartTime() {
    this.setState({ shouldSetStartTime: false, clickedSelect: true });
  }

  setDuration(duration) {
    this.setState({ duration });
  }

  setPopUpTime() {
    this.setState({ lastEscapeTimeStamp: new Date() });
  }

  onBlurEventTimeOrDate() {
    let newState = { lastEscapeTimeStamp: new Date() };

    if (this.state.eventNotHavePermissionToEditActualEvent) {
      newState.warningEditWillOnlyReflectOnThisCalendarSection = null;
    }

    this.setState(newState);
  }

  onClickStartDate() {
    this.setModalContent(
      "Select Start Date",
      320,
      START_DATE,
      "select-start-date"
    );
  }

  onClickEventRepeat(autoOpen = false) {
    if (this.state.eventNotHavePermissionToEditActualEvent) {
      return;
    } else if (
      differenceInMilliseconds(new Date(), this.state.lastTimeSet) > 100 ||
      autoOpen
    ) {
      this.setModalContent(
        "Select Event Repeat",
        300,
        EVENT_REPEAT,
        "repeat-icon"
      );
    }
  }

  closeModal() {
    this.setState({
      shouldDisplayModal: false,
      shouldDisplayEventRepeatCustomModal: false,
      lastEscapeTimeStamp: new Date(),
      modalContent: null,
    });
  }

  goToSummary() {
    this.summary?.focus && this.summary?.focus();
  }

  goToSaveButton() {
    this.setState({ focusOnDescription: false });

    this.saveButton.focus();
  }

  resetLatestFocus() {
    blurCalendar();
    this.setState({ lastEscapeTimeStamp: new Date() });
  }

  goToEndTime(message = null) {
    let updatedState = {};
    this.selectEndTimeRef &&
      this.selectEndTimeRef.current &&
      this.selectEndTimeRef.current.setFocus();

    if (message) {
      updatedState.errorMessage = message;

      this.setModalContent("Event contains error", 300, ERROR, SELECT_END_DATE);
    }

    this.setState(updatedState);
  }

  goToStartTime() {
    this.selectStartTimeRef &&
      this.selectStartTimeRef.current &&
      this.selectStartTimeRef.current.setFocus();
  }

  goToColor() {
    this.selectEmailColorSection && this.selectEmailColorSection.focus();
  }

  goToStartDate() {
    this.selectStartDateRef &&
      this.selectStartDateRef.current &&
      this.selectStartDateRef.current.focus();
  }

  goToEndDate() {
    this.selectEndDateRef &&
      this.selectEndDateRef.current &&
      this.selectEndDateRef.current.focus();
  }

  goToLocation() {
    this.setState({ latestFocus: LOCATION });

    this.location && this.location.focus();
  }

  goToAttendeeList() {
    this.addAttendeeRef &&
      this.addAttendeeRef.current &&
      this.addAttendeeRef.current.focus();

    this.setState({ latestFocus: ATTENDEE });
  }

  goToNLPInput() {
    this.shouldDisplayNlpInput() && this.nlpBar && this.nlpBar.focus();
    this.setState({ latestFocus: NLP_BAR });
  }

  goToSelectEmailSection() {
    this.selectEmailSection && this.selectEmailSection.focus();
  }

  goToDescription() {
    this.setState({ latestFocus: DESCRIPTION });

    this.description && this.description.focus();
  }

  handleKeyUp(e) {
    if (!isEmptyObjectOrFalsey(this.state.keyMap)) {
      this.setState({ keyMap: {} });
    }
  }

  updateKeyMap(e) {
    let keyMap = _.clone(this.state.keyMap);
    keyMap[e.keyCode] = e.type === "keydown";

    this.setState({ keyMap });
  }

  handleKeyDown(e) {
    const { isEditingZoomSettings } = this.state;

    this.updateKeyMap(e);

    const isAnyModalOpen = isModalOpen();

    if (isAnyModalOpen) {
      return;
    }

    if (isEditingZoomSettings) {
      return;
    }

    if (
      e.keyCode === KEYCODE_ESCAPE &&
      this.state.suggestions &&
      this.state.suggestions.length > 0
    ) {
      hasEventPreventDefault(e);

      this.setState({ suggestions: [], refs: {} });
      return;
    }

    if (
      this.state.keyMap[KEYCODE_ENTER] &&
      isCommandKeyPressed(this.state.keyMap) &&
      !this.state.shouldDisplayModal
    ) {
      this.onClickSave();
    }

    if (
      this.state.keyMap[KEYCODE_F] &&
      (this.props.isMac
        ? this.state.keyMap[KEYCODE_CONTROL_MAC]
        : this.state.keyMap[KEYCODE_CONTROL])
    ) {
      this.props.toggleGlobalKeyMap();
    }

    if (
      isCommandKeyPressed(this.state.keyMap) &&
      this.state.keyMap[KEYCODE_K] &&
      !this.state.focusOnDescription
    ) {
      this.closeOpenDateTimeModals();

      Broadcast.publish("TURN_ON_COMMAND_CENTER", e);
    }

    if (
      isCommandKeyPressed(this.state.keyMap) &&
      this.state.keyMap[KEYCODE_J]
    ) {
      hasEventPreventDefault(e);
    }

    if (
      isCommandKeyPressed(this.state.keyMap) &&
      this.state.keyMap[KEYCODE_SEMI_COLON]
    ) {
      this.closeOpenDateTimeModals();
      Broadcast.publish("TURN_ON_TEMPLATE_COMMAND_CENTER", e);
    }

    switch (e.keyCode) {
      case KEYCODE_ESCAPE:
        if (
          document.activeElement &&
          [SUMMARY, DESCRIPTION].includes(document.activeElement.id)
        ) {
          Broadcast.publish("GLOBAL_ESCAPE");

          return;
        }

        return;
      default:
        return;
    }
  }

  onHitEscape() {
    // FOR DEV
    // console.log('got to esc in eventform')
    // console.log('!this.props.isCommandCenterOn', !this.props.isCommandCenterOn);
    // console.log('!this.state.shouldDisplayModal', !this.state.shouldDisplayModal);
    // console.log('isEmptyObjectOrFalsey(this.props.popupEvent)', isEmptyObjectOrFalsey(this.props.popupEvent));
    // console.log('!this.state.lastEscapeTimeStamp ', !this.state.lastEscapeTimeStamp);
    // console.log('moment().diff(this.state.lastEscapeTimeStamp)', moment().diff(this.state.lastEscapeTimeStamp));
    // console.log('last', (!this.state.lastEscapeTimeStamp || moment().diff(this.state.lastEscapeTimeStamp) > 300));

    // console.log('c', !this.state.shouldDisplayModal)
    // console.log('d', isEmptyObjectOrFalsey(this.props.popupEvent))
    // console.log('render this.state.latestFocus', this.state.latestFocus);
    // console.log('e', (!this.state.popup || moment().diff(this.state.popup) > 200));
    // console.log('this.state.popup', this.state.popup, moment().diff(this.state.popup));

    const { isEditingZoomSettings } = this.state;

    if (isEditingZoomSettings) {
      return;
    }
    const shouldPrint = false && isLocal();

    if (!isEmptyObjectOrFalsey(this.props.popupEvent)) {
      shouldPrint && console.log("eventFormEscape0");

      this.props.removePopupEvent();
    } else if (
      this.state.shouldDisplayModal &&
      this.state.modalContent !== DISCARD_CHANGES
    ) {
      shouldPrint && console.log("eventFormEscape1");

      this.setState({ shouldDisplayModal: false });
    } else if (
      [
        EVENT_FORM_START_DATE,
        EVENT_FORM_END_DATE,
        START_TIME,
        END_TIME,
        ATTENDEE,
      ].includes(this.state.latestFocus) &&
      (!this.state.lastEscapeTimeStamp ||
        differenceInMilliseconds(new Date(), this.state.lastEscapeTimeStamp) <=
          500)
    ) {
      shouldPrint &&
        console.log(
          "eventFormEscape2",
          this.state.lastEscapeTimeStamp,
          differenceInMilliseconds(new Date(), this.state.lastEscapeTimeStamp)
        );

      return;
    } else if (
      !this.props.isCommandCenterOn &&
      !this.state.shouldDisplayModal &&
      isEmptyObjectOrFalsey(this.props.popupEvent) &&
      (!this.state.lastEscapeTimeStamp ||
        differenceInMilliseconds(new Date(), this.state.lastEscapeTimeStamp) >
          300)
    ) {
      shouldPrint && console.log("eventFormEscape3");

      this.onPressExit();
    }
  }

  closeOpenDateTimeModals() {
    Broadcast.publish("CLOSE_EVENT_FORM_MONTH_MODAL_START");
    Broadcast.publish("CLOSE_EVENT_FORM_MONTH_MODAL_END");

    this.selectStartTimeRef &&
      this.selectStartTimeRef.current &&
      this.selectStartTimeRef.current.setOpen &&
      this.selectStartTimeRef.current.setOpen(false);
    this.selectEndTimeRef &&
      this.selectEndTimeRef.current &&
      this.selectEndTimeRef.current.setOpen &&
      this.selectEndTimeRef.current.setOpen(false);
  }

  constructEventData(updatingRecurringEvent = false) {
    const { start, end } = this.calculateStartAndEndDate();
    let google_calendar_event;

    if (this.checkMode(UPDATE_TEMPLATE)) {
      google_calendar_event = _.clone(getEventRawData(this.state.inputEvent));
    } else if (updatingRecurringEvent) {
      const eventRawData = getEventRawData(this.state.eventObject);
      google_calendar_event = eventRawData ? _.clone(eventRawData) : {};
      google_calendar_event.recurrence = updatedRecurrenceAfterEventStartChange(
        this.state.originalRecurringEventInstance,
        this.state.eventStartDate
      );

      google_calendar_event = removeGoogleAutoGeneratedKeys(
        google_calendar_event
      );
    } else {
      google_calendar_event = {};
    }

    // Check if user switched off all Day
    if (
      this.checkMode(UPDATE_TEMPLATE) &&
      (google_calendar_event.allDay ||
        isEventTemplateAllDay(google_calendar_event)) &&
      !this.state.allDay
    ) {
      google_calendar_event = _.omit(google_calendar_event, "allDay");
    }
    if (this.isTemplate()) {
      if (this.state.allDay) {
        const startDate = startOfDay(new Date());
        google_calendar_event.start = {
          date: formatISO(startDate, ISO_DATE_FORMAT),
        };
        google_calendar_event.end = {
          date: formatISO(
            addDays(startDate, this.state.duration?.days || 0),
            ISO_DATE_FORMAT
          ),
        };
      } else {
        const startDateTime = startOfHour(new Date());
        google_calendar_event.start = {
          dateTime: startDateTime,
        };
        google_calendar_event.end = {
          dateTime: addMinutes(
            addHours(startDateTime, this.state.duration?.hours || 0),
            this.state.duration?.minutes || 0
          ),
        };
      }
    }

    let originalState = this.state.originalState;
    let currentState = this.state;

    if (
      !_.isEqual(currentState.startTimeZone, originalState.startTimeZone) ||
      !_.isEqual(currentState.endTimeZone, originalState.endTimeZone)
    ) {
      start.timeZone = this.state.startTimeZone;
      end.timeZone = this.state.endTimeZone;

      if (
        (this.checkMode(TEMPLATE) || this.checkMode(UPDATE_TEMPLATE)) &&
        !this.state.shouldSetStartTime
      ) {
      } else {
        google_calendar_event.start = start;
      }

      if (
        (this.checkMode(TEMPLATE) || this.checkMode(UPDATE_TEMPLATE)) &&
        !this.state.shouldSetEndTime
      ) {
      } else {
        google_calendar_event.end = end;
      }
    }

    if (
      this.state.hasDefaultTitle ||
      this.props.shouldSaveEverything ||
      !_.isEqual(currentState.summary, originalState.summary)
    ) {
      google_calendar_event.summary = this.state.summary.trim();
    }

    if (this.isCreateNew()) {
      google_calendar_event.start = start;
      google_calendar_event.end = end;

      if (
        !isSameMinute(
          currentState.eventStartTime,
          originalState.eventStartTime
        ) ||
        !isSameDay(currentState.eventStartDate, originalState.eventStartDate)
      ) {
        google_calendar_event.start = start;
        start.timeZone = this.state.startTimeZone;
        end.timeZone = this.state.endTimeZone;
      }

      if (
        !isSameMinute(currentState.eventEndTime, originalState.eventEndTime) ||
        !isSameDay(currentState.eventEndDate, originalState.eventEndDate)
      ) {
        google_calendar_event.end = end;
        start.timeZone = this.state.startTimeZone;
        end.timeZone = this.state.endTimeZone;
      }
    }

    if (this.checkMode(TEMPLATE) || this.checkMode(UPDATE_TEMPLATE)) {
      if (
        !isSameMinute(
          currentState.eventStartTime,
          originalState.eventStartTime
        ) ||
        !isSameDay(currentState.eventStartDate, originalState.eventStartDate)
      ) {
        google_calendar_event.start = start;
        start.timeZone = this.state.startTimeZone;
        end.timeZone = this.state.endTimeZone;
      }

      if (
        !isSameMinute(currentState.eventEndTime, originalState.eventEndTime) ||
        !isSameDay(currentState.eventEndDate, originalState.eventEndDate)
      ) {
        google_calendar_event.end = end;
        start.timeZone = this.state.startTimeZone;
        end.timeZone = this.state.endTimeZone;
      }
    }

    if (
      currentState.startTimeZone !== originalState.startTimeZone ||
      currentState.endTimeZone !== originalState.endTimeZone
    ) {
      google_calendar_event.start = start;
      google_calendar_event.end = end;

      if (
        (this.checkMode(TEMPLATE) || this.checkMode(UPDATE_TEMPLATE)) &&
        isSameMinute(currentState.eventStartTime, originalState.eventStartTime)
      ) {
        google_calendar_event.start.dateTime = null;
      }

      if (
        (this.checkMode(TEMPLATE) || this.checkMode(UPDATE_TEMPLATE)) &&
        isSameMinute(currentState.eventEndTime, originalState.eventEndTime)
      ) {
        google_calendar_event.end.dateTime = null;
      }
    }

    if (
      (this.props.shouldSaveEverything ||
        !_.isEqual(currentState.allDay, originalState.allDay)) &&
      currentState.allDay
    ) {
      if (this.checkMode(TEMPLATE) || this.checkMode(UPDATE_TEMPLATE)) {
        google_calendar_event.allDay = currentState.allDay;
      }
      google_calendar_event.start = start;
      google_calendar_event.end = end;
    }

    if (
      !isSameMinute(
        currentState.eventStartTime,
        originalState.eventStartTime
      ) ||
      !isSameDay(currentState.eventStartDate, originalState.eventStartDate)
    ) {
      google_calendar_event.start = start;
    }

    if (
      !isSameMinute(currentState.eventEndTime, originalState.eventEndTime) ||
      !isSameDay(currentState.eventEndDate, originalState.eventEndDate)
    ) {
      // Calculate duration and add start here as well. If changing end date/time for all instances of a recurring event,
      //
      // google_calendar_event.start = start;
      google_calendar_event.end = end;
    }

    if (
      (this.checkMode(UPDATE_TEMPLATE) || this.checkMode(TEMPLATE)) &&
      this.state.clickedSelect
    ) {
      // if move time to SELECT
      google_calendar_event = _.omit(google_calendar_event, "start");
      google_calendar_event = _.omit(google_calendar_event, "end");
    }

    if (this.checkMode(UPDATE_TEMPLATE)) {
      google_calendar_event = this.determineAttendee(google_calendar_event);
    } else if (
      ((this.props.shouldSaveEverything ||
        !_.isEqual(currentState.attendees, originalState.attendees)) &&
        currentState.attendees) ||
      this.props.shouldSaveEverything ||
      !_.isEqual(
        currentState.selectedConferenceRooms,
        originalState.selectedConferenceRooms
      ) ||
      this.props.shouldSaveEverything ||
      !_.isEqual(
        currentState.newlyAddedAttendees,
        originalState.newlyAddedAttendees
      ) ||
      this.state.createdEventWithExistingAttendees
    ) {
      // Add rooms
      google_calendar_event.attendees = this.createAttendeeAndRoomData();
    }

    if (
      this.props.shouldSaveEverything ||
      !_.isEqual(currentState.location, originalState.location)
    ) {
      google_calendar_event.location = this.state.location;
    }

    const { masterAccount } = this.props.masterAccount;
    if (this.state.fromEventToTemplate && this.state.description) {
      let templateDescription = this.state.description
        .replace(renderDefaultSignatureWithEmptyLinesAbove(masterAccount), "")
        .replace(renderDefaultSignatureWithEmptyLinesAbove(), "")
        .replace(VIMCAL_SIGNATURE, "");

      google_calendar_event.description =
        sanitizeStringAndLinkify(templateDescription);
    } else if (!this.state.description) {
      // Do nothing since description has not changed
    } else {
      google_calendar_event.description = this.state.description;

      google_calendar_event.description = sanitizeStringAndLinkify(
        this.state.description
      );
    }

    if (
      (this.props.shouldSaveEverything ||
        !_.isEqual(currentState.conference, originalState.conference)) &&
      (currentState.conference === noConferenceString ||
        this.state.createdEventWithExistingAttendees)
    ) {
      google_calendar_event = _.omit(
        google_calendar_event,
        "conference",
        "conferenceData"
      );
    }

    if (
      this.isCreateNew() ||
      !_.isEqual(
        currentState.guestPermissions,
        originalState.guestPermissions
      ) ||
      this.checkMode(TEMPLATE) ||
      this.checkMode(UPDATE_TEMPLATE)
    ) {
      google_calendar_event.guestsCanModify =
        this.state.guestPermissions.includes(modifyEventsString);
      google_calendar_event.guestsCanInviteOthers =
        this.state.guestPermissions.includes(inviteOthersString);
      google_calendar_event.guestsCanSeeOtherGuests =
        this.state.guestPermissions.includes(seeGuestListString);
    }

    if (
      this.props.shouldSaveEverything ||
      !_.isEqual(currentState.transparency, originalState.transparency) ||
      (this.isCreateNew() &&
        this.state.allDay &&
        currentState.transparency === freeDuringEvent)
    ) {
      google_calendar_event.transparency = this.state.transparency;
    }

    if (
      this.props.shouldSaveEverything ||
      currentState.outOfOfficeAutoDeclineMode !==
        originalState.outOfOfficeAutoDeclineMode
    ) {
      google_calendar_event.outOfOfficeAutoDeclineMode =
        this.state.outOfOfficeAutoDeclineMode;
    }

    if (
      this.props.shouldSaveEverything ||
      currentState.outOfOfficeDeclineMessage !==
        originalState.outOfOfficeDeclineMessage
    ) {
      google_calendar_event.outOfOfficeDeclineMessage =
        this.state.outOfOfficeDeclineMessage;
    }

    if (
      this.props.shouldSaveEverything ||
      !_.isEqual(currentState.reminders, originalState.reminders) ||
      !_.isEqual(currentState.allDayReminders, originalState.reminders)
    ) {
      google_calendar_event.reminders = this.state.allDay
        ? this.state.allDayReminders
        : this.state.reminders;
    }

    if (
      this.props.shouldSaveEverything ||
      !_.isEqual(currentState.visibility, originalState.visibility)
    ) {
      google_calendar_event.visibility = this.state.visibility;
    }

    if (
      currentState.eventColorId !== originalState.eventColorId ||
      this.props.shouldSaveEverything
    ) {
      google_calendar_event.colorId = this.state.eventColorId;
    }

    if (
      !_.isEqual(currentState.recurrenceRules, originalState.recurrenceRules) ||
      this.state.isDuplicate
    ) {
      if (!isEmptyObjectOrFalsey(this.state.recurrenceRules)) {
        google_calendar_event.recurrence = [
          this.state.recurrenceRules.toString(),
        ];
      } else {
        google_calendar_event.recurrence = [];
      }
    }

    if (
      this.checkMode(TEMPLATE) ||
      (this.checkMode(UPDATE_TEMPLATE) &&
        (!_.isEqual(this.state.duration, originalState.duration) ||
          originalState.allDay !== this.state.allDay))
    ) {
      if (this.state.allDay) {
        google_calendar_event.duration = {
          days: this.state.duration.days,
          hours: 0,
          minutes: 0,
        };
      } else {
        google_calendar_event.duration = {
          days: 1,
          hours: this.state.duration.hours,
          minutes: this.state.duration.minutes,
        };
      }
    }

    // Apparently recurring need timeZone for it to work
    if (!isEmptyObjectOrFalsey(this.state.recurrenceRules)) {
      google_calendar_event.start = google_calendar_event.start
        ? _.clone(google_calendar_event.start)
        : {};
      google_calendar_event.end = google_calendar_event.end
        ? _.clone(google_calendar_event.end)
        : {};

      google_calendar_event.start.timeZone = this.state.startTimeZone;
      google_calendar_event.end.timeZone = this.state.endTimeZone;
    }

    if (getCalendarProviderId(this.state.calendar)) {
      google_calendar_event.google_id = getCalendarProviderId(
        this.state.calendar
      );
    }

    const tags = this.checkMode(UPDATE_TEMPLATE)
      ? getTagsFromTemplate(this.state.eventObject)
      : getEventExtendedProperties(this.state.eventObject)?.private?.tags;
    if (tags) {
      google_calendar_event.extended_properties = this.checkMode(
        UPDATE_TEMPLATE
      )
        ? getTemplateExtendedProperties(this.state.eventObject)
        : getEventExtendedProperties(this.state.eventObject);
    }

    // Set conference data
    // Note, this must happen when the event is otherwise constructed so that
    // we can create the potential conference meeting with the appropriate values.
    if (this.shouldUpdateConferencing()) {
      const { masterAccount } = this.props.masterAccount;
      const { zoomMeeting } = this.state;
      google_calendar_event.conferenceData = createConferenceData(
        {
          conference: this.state.conference,
          currentUser: this.getUser(),
          zoomMeeting: zoomMeeting,
          isTemplate: this.isTemplate(),
          location: "eventForm",
          masterAccount,
        },
        this.getZoomScheduler()
      );
    }

    if (
      !isEmptyArrayOrFalsey(currentState.categories) &&
      !_.isEqual(currentState.categories, originalState.categories)
    ) {
      google_calendar_event.categories = currentState.categories;
    }

    let event = {
      google_calendar_event: google_calendar_event,
    };

    if (this.checkMode(TEMPLATE) || this.checkMode(UPDATE_TEMPLATE)) {
      event.description = this.state.descriptionName;
      event.text = null;

      return { event };
    } else {
      let eventData = {
        user_calendar_id: getCalendarUserCalendarID(this.state.calendar),
        event,
      };

      const originalEvent = this.state.originalEvent;
      eventData.organizer = getEventOrganizer(originalEvent)?.self;

      if (this.hasEventChangedCalendars()) {
        eventData["moved"] = true;
        eventData["destination_user_calendar_id"] = getCalendarUserCalendarID(
          this.state.calendar
        );
      }

      return eventData;
    }
  }

  getOulookAllDayEventEnd() {
    const { eventEndDate } = this.state;

    return {
      date: formatISO(addDays(startOfDay(eventEndDate), 1), ISO_DATE_FORMAT),
      dateTime: null,
    };
  }

  calculateStartAndEndDate(returnTimeZone = false) {
    const { allDay, eventStartDate, eventEndDate, endTimeZone, startTimeZone } =
      this.state;

    const isCreateNew = this.isCreateNew();
    const isEdit = this.isEditSingleEvent();
    const isOutlook = this.isOutlookCalendar();

    const getAllDayEndDate = () => {
      if (isOutlook) {
        return this.getOulookAllDayEventEnd();
      }

      const endDate =
        isCreateNew || isEdit ? addDays(eventEndDate, 1) : eventEndDate;
      return { date: formatISO(endDate, ISO_DATE_FORMAT), dateTime: null };
    };

    if (this.isGoogleCalendar() && allDay && this.isOutOfOfficeEvent()) {
      // google does not accept all day out of office events
      const { masterAccount } = this.props.masterAccount;
      const { currentUser } = this.props;
      const userDefaultTimeZone = getDefaultUserTimeZone({
        masterAccount,
        user: currentUser,
      });
      // let's say the default browser time zone is in PST
      // the userDefaultTimeZone is in EST
      // we want to set it to midnight EST
      const guessedTimeZone = guessTimeZone();
      return {
        start: {
          dateTime: getTimeInAnchorTimeZone(
            startOfDay(eventStartDate),
            userDefaultTimeZone,
            guessedTimeZone
          ).toISOString(), // want to do start of day in the current time zone
          timeZone: userDefaultTimeZone,
        },
        end: {
          dateTime: getTimeInAnchorTimeZone(
            startOfDay(addDays(eventEndDate, 1)),
            userDefaultTimeZone,
            guessedTimeZone
          ).toISOString(),
          timeZone: userDefaultTimeZone,
        },
      };
    }

    if (allDay) {
      return {
        start: {
          date: formatISO(eventStartDate, ISO_DATE_FORMAT),
          dateTime: null,
        },
        end: getAllDayEndDate(),
      };
    }

    const startDateTime = getTimeInAnchorTimeZone(
      this.getCombinedEventStartDateAndTime(),
      startTimeZone
    );

    const endDateTime = getTimeInAnchorTimeZone(
      this.getCombinedEventEndDateAndTime(),
      endTimeZone
    );

    const getTimeZone = () => {
      if (allDay) {
        return { inputStartTimeZone: undefined, inputEndTimeZone: undefined };
      }
      if (returnTimeZone) {
        return {
          inputStartTimeZone: startTimeZone,
          inputEndTimeZone: endTimeZone,
        };
      }
      if (isOutlook) {
        return {
          inputStartTimeZone: startTimeZone,
          inputEndTimeZone: endTimeZone,
        };
      }
      return { inputStartTimeZone: undefined, inputEndTimeZone: undefined };
    };

    const { inputStartTimeZone, inputEndTimeZone } = getTimeZone();

    return {
      start: {
        dateTime: startDateTime.toISOString(),
        date: null,
        timeZone: inputStartTimeZone,
      },
      end: {
        dateTime: endDateTime.toISOString(),
        date: null,
        timeZone: inputEndTimeZone,
      },
    };
  }

  isUpdateEvent() {
    return this.isEditSingleEvent() || this.checkMode(RECURRING_EVENT);
  }

  constructEventDataV2(isRecurringEvent = false, isFollowing = false) {
    const {
      currentTimeZone,
      shouldSaveEverything: inputShouldSaveEverything,
      weekStart,
    } = this.props;
    const isCreateNew = this.isCreateNew();
    const isUpdateTemplate = this.checkMode(UPDATE_TEMPLATE);

    if (this.isTemplate()) {
      return {};
    }
    const shouldSaveEverything = isFollowing || inputShouldSaveEverything; // need to create on following

    const {
      allDay,
      attendees,
      calendar,
      conference,
      createdEventWithExistingAttendees,
      description,
      duration,
      endTimeZone,
      eventColorId,
      eventEndDate,
      eventObject,
      eventStartDate,
      fromEventToTemplate,
      guestPermissions,
      hasDefaultTitle,
      inputEvent,
      isDuplicate,
      location,
      newlyAddedAttendees,
      originalEvent,
      originalState: prevState,
      recurrenceRules,
      selectedConferenceRooms,
      shouldSetEndTime,
      shouldSetStartTime,
      startTimeZone,
      transparency,
      summary,
      visibility,
      outOfOfficeAutoDeclineMode,
      outOfOfficeDeclineMessage,
      categories,
      vholds_id,
    } = this.state;

    const hasNewRecurrence =
      !_.isEqual(recurrenceRules, prevState.recurrenceRules) || isDuplicate;

    let baseEvent = {};
    if (isUpdateTemplate || isRecurringEvent || hasNewRecurrence) {
      const newEvent = _.clone(
        getEventRawData(isRecurringEvent ? eventObject : inputEvent) || {}
      );
      baseEvent = isRecurringEvent
        ? removeGoogleAutoGeneratedKeys(newEvent)
        : newEvent;
    }

    const isOutlook = this.isOutlookCalendar();
    const hasUpdatedAllDay = !_.isEqual(allDay, prevState.allDay);
    const hasNewTimeZone =
      !_.isEqual(startTimeZone, prevState.startTimeZone) ||
      !_.isEqual(endTimeZone, prevState.endTimeZone);
    const returnTimeZone =
      !isOutlook && (!isEmptyObjectOrFalsey(recurrenceRules) || hasNewTimeZone);
    const { start, end } = this.calculateStartAndEndDate(returnTimeZone);
    const hasDifferentStart = this.hasDifferentTime(
      PROPERTY_FIELDS.EVENT_START_TIME,
      PROPERTY_FIELDS.EVENT_START_DATE
    );
    const hasDifferentEnd = this.hasDifferentTime(
      PROPERTY_FIELDS.EVENT_END_TIME,
      PROPERTY_FIELDS.EVENT_END_DATE
    );
    const hasDateOrTimeChanged = hasDifferentStart || hasDifferentEnd; // if either start or end time has changed
    const timeMin = formatTimeForBackendJsDate(eventStartDate, weekStart, true);
    const timeMax = formatTimeForBackendJsDate(eventEndDate, weekStart, false);
    const timeZone = currentTimeZone;

    const hasTimeZoneChanged = () => {
      if (isOutlook) {
        // outlook event always returns UTC
        return (
          prevState.startTimeZone !== startTimeZone ||
          prevState.endTimeZone !== endTimeZone
        );
      }
      return (
        getEventStartTimeZone(originalEvent) !== startTimeZone ||
        getEventEndTimeZone(originalEvent) !== endTimeZone
      );
    };

    const shouldSetEventStartAndEnd =
      isCreateNew ||
      hasDateOrTimeChanged ||
      shouldSaveEverything ||
      shouldSetStartTime ||
      shouldSetEndTime ||
      isRecurringEvent ||
      hasUpdatedAllDay ||
      hasNewRecurrence ||
      hasTimeZoneChanged();

    const hasDifferentSummary = !_.isEqual(summary, prevState.summary);
    const hasDifferentAttendees =
      !_.isEqual(attendees, prevState.attendees) && attendees;
    const hasNewConferenceRooms = !_.isEqual(
      selectedConferenceRooms,
      prevState.selectedConferenceRooms
    );
    const hasNewAttendees = !_.isEqual(
      newlyAddedAttendees,
      prevState.newlyAddedAttendees
    );
    const hasNewAttendeesRoom =
      hasDifferentAttendees ||
      hasNewConferenceRooms ||
      hasNewAttendees ||
      createdEventWithExistingAttendees;
    const hasNewLocation =
      !_.isEqual(location, prevState.location) ||
      (location && this.isCreateNew());
    const hasNewGuestPermissions =
      isCreateNew || !_.isEqual(guestPermissions, prevState.guestPermissions);
    const hasNewTransparency =
      !_.isEqual(transparency, prevState.transparency) ||
      (allDay && isCreateNew && transparency === freeDuringEvent);
    const hasNewVisibility = !_.isEqual(visibility, prevState.visibility);
    const hasNewColor = eventColorId !== prevState.eventColorId;
    const hasNewDuration = !_.isEqual(duration, prevState.duration);
    const userCalendarId = getCalendarUserCalendarID(calendar);

    const conferenceData = this.getConferencingGoogleV2({
      isFollowing,
      existingConferencing: baseEvent.conferenceData,
    }); // only for google
    const shouldDeleteConferencing =
      conference === "No conferencing" && prevState.conference !== conference;

    // VIM-700 for google events with all day changes we want to pass null inside the event_start/event_end
    // objects. By passing false to omitNullOrUndefinedProps we make sure the objects above will have
    // their null values preserved
    const omitPropsRecursively = !(!isOutlook && hasUpdatedAllDay);
    const isOutOfOfficeEvent = transparency === OUT_OF_OFFICE_DURING_EVENT;

    const getTransparency = () => {
      // Out of office events are always busy for both Google and Outlook.
      if (isOutOfOfficeEvent) {
        return;
      }

      if (hasNewTransparency || shouldSaveEverything) {
        return transparency;
      }
    };

    let holdAttendees;
    if (this.isEditingHoldEvent()) {
      const existingAttendees = new Set();
      const uniqueAttendees = [
        ...this.state.attendees,
        ...this.state.newlyAddedAttendees,
      ]
        .filter((attendee) => {
          if (existingAttendees.has(getObjectEmail(attendee))) {
            return false;
          }

          existingAttendees.add(getObjectEmail(attendee));
          return true;
        })
        .map((attendee) => ({
          email: getObjectEmail(attendee),
          isOptional: attendee.optional,
          name: this.props.emailToNameIndex[getObjectEmail(attendee)],
        }));
      holdAttendees = JSON.stringify(uniqueAttendees);
    }
    const updatedDescription = this.buildEventDescriptionData(
      description,
      fromEventToTemplate
    );
    const hasDescriptionChanged = () => {
      if (shouldSaveEverything || isCreateNew) {
        return true;
      }
      if (updatedDescription === getEventDescription(originalEvent)) {
        return false;
      }
      const formatDescription = (description) => {
        try {
          return stripeAllNewLines(
            replaceEmptySpaceWithTextEmptyString(
              removeLeadingSpacesInTags(description)
            )
          ).replaceAll('rel="noopener noreferrer" ', "");
        } catch (error) {
          handleError(error);
          return description;
        }
      };
      // outlook wraps the description in html tags
      const formattedUpdated = formatDescription(
        addDefaultOutlookDescriptionHTMLText(updatedDescription)
      );
      const formattedOriginal = formatDescription(
        getEventDescription(originalEvent)
      );
      if (formattedUpdated === formattedOriginal) {
        return false;
      }
      return updatedDescription !== getEventDescription(originalEvent);
    };

    const getCategories = () => {
      if (!isOutlook) {
        return null;
      }
      if (isCreateNew) {
        return categories;
      }
      if (_.isEqual(categories, prevState.categories)) {
        return null;
      }
      return categories;
    };

    const shouldUpdateColorID =
      !isOutlook && (isRecurringEvent || hasNewColor || shouldSaveEverything);
    return omitNullOrUndefinedProps(
      {
        user_calendar_id: userCalendarId,
        calendar_event: {
          ...baseEvent,
          provider_id: getCalendarProviderId(calendar) || undefined,
          title:
            hasDefaultTitle || shouldSaveEverything || hasDifferentSummary
              ? summary.trim()
              : undefined,
          event_start: shouldSetEventStartAndEnd ? start : undefined,
          event_end: shouldSetEventStartAndEnd ? end : undefined,
          ooo_decline_mode: isOutOfOfficeEvent
            ? outOfOfficeAutoDeclineMode
            : undefined,
          ooo_decline_message: isOutOfOfficeEvent
            ? outOfOfficeDeclineMessage
            : undefined,
          attendees:
            hasNewAttendeesRoom || shouldSaveEverything
              ? this.createAttendeeAndRoomData()
              : undefined,
          location:
            hasNewLocation || shouldSaveEverything ? location : undefined,
          description: hasDescriptionChanged() ? updatedDescription : undefined,
          guest_permissions:
            !hasNewGuestPermissions && !isCreateNew
              ? undefined
              : {
                  guests_can_modify:
                    guestPermissions.includes(modifyEventsString),
                  guests_can_invite_others:
                    guestPermissions.includes(inviteOthersString),
                  guests_can_see_other_guests:
                    guestPermissions.includes(seeGuestListString),
                },
          show_as: isOutOfOfficeEvent
            ? OUT_OF_OFFICE_EVENT_TYPE
            : DEFAULT_EVENT_TYPE,
          transparency: getTransparency(),
          reminders: this.buildEventReminders(),
          visibility:
            hasNewVisibility || shouldSaveEverything ? visibility : undefined,
          extended_properties: getEventExtendedProperties(eventObject),
          colorId: shouldUpdateColorID ? eventColorId : undefined,
          recurrence: this.buildRecurrenceEventDataV2(isRecurringEvent),
          duration:
            !hasNewDuration || (!!allDay && hasUpdatedAllDay)
              ? undefined
              : {
                  days: allDay ? duration.days : 1,
                  hours: allDay ? 0 : duration.hours,
                  minutes: allDay ? 0 : duration.minutes,
                },
          online_meeting_provider:
            this.shouldUpdateConferencing() &&
            this.isOutlookCalendar() &&
            !this.isEditingHoldEvent()
              ? conference
              : undefined, // for outlook
          conference_data: this.isEditingHoldEvent() ? null : conferenceData, // only for google
          categories: getCategories(),
          attachments: this.hasAttachmentsChanged() ? this.state.attachments : undefined,
        },
        delete_conferencing: shouldDeleteConferencing,
        delete_conferencing_url:
          originalEvent && shouldDeleteConferencing
            ? GetConferenceURL(originalEvent)
            : undefined,
        organizer: getEventOrganizer(originalEvent)?.self,
        moved: this.hasEventChangedCalendars() ? true : undefined,
        destination_user_calendar_id: this.hasEventChangedCalendars()
          ? userCalendarId
          : undefined,
        timeMin: hasNewRecurrence ? timeMin : undefined,
        timeMax: hasNewRecurrence ? timeMax : undefined,
        timeZone: hasNewRecurrence ? timeZone : undefined,
        hold_details: this.isEditingHoldEvent()
          ? {
              attendees: holdAttendees,
              conferencing: backendConferenceValueFromHumanReadable(
                this.state.conference
              ),
              description: this.buildEventDescriptionData(
                description,
                fromEventToTemplate
              ),
              location,
            }
          : {},
        vholds_id,
      },
      omitPropsRecursively
    );
  }

  getZoomScheduler() {
    return getZoomSchedulers(this.props.zoomSchedulers);
  }

  getConferencingGoogleV2({ isFollowing, existingConferencing }) {
    if (this.isOutlookCalendar()) {
      return null;
    }
    const {
      conference,
      originalState,
      zoomMeeting,
      originalEventConferenceData,
    } = this.state;

    const { masterAccount } = this.props.masterAccount;

    if (!_.isEqual(conference, originalState?.conference)) {
      // has conferencing changed
      return createConferenceData(
        {
          conference,
          currentUser: this.getUser(),
          zoomMeeting,
          isTemplate: this.isTemplate(),
          location: "eventForm",
          masterAccount,
        },
        this.getZoomScheduler()
      );
    } else if (isFollowing && !isEmptyObjectOrFalsey(existingConferencing)) {
      // use default
      return existingConferencing;
    } else if (this.isCopyToCalendarEvent() && originalEventConferenceData) {
      // conferencing has not changed
      return originalEventConferenceData;
    } else {
      return !(this.shouldUpdateConferencing() || isFollowing)
        ? undefined
        : createConferenceData(
            {
              conference,
              currentUser: this.getUser(),
              zoomMeeting,
              isTemplate: this.isTemplate(),
              location: "eventForm",
              masterAccount,
            },
            this.getZoomScheduler()
          );
    }
  }

  hasDifferentTime(timeFieldName, dayFieldName) {
    if (this.state.isUpdateProposedTime) {
      return true;
    }
    const { originalState: prevState } = this.state;

    return (
      !isSameMinute(this.state[timeFieldName], prevState[timeFieldName]) ||
      !isSameDay(this.state[dayFieldName], prevState[dayFieldName])
    );
  }

  buildEventDescriptionData(description, fromEventToTemplate) {
    if (!description) {
      return undefined;
    }
    const { masterAccount } = this.props.masterAccount;

    const newDescription = !fromEventToTemplate
      ? description
      : description
          .replace(renderDefaultSignatureWithEmptyLinesAbove(masterAccount), "")
          .replace(renderDefaultSignatureWithEmptyLinesAbove(), "")
          .replace(VIMCAL_SIGNATURE, "");

    return sanitizeStringAndLinkify(newDescription);
  }

  buildEventReminders() {
    const {
      allDay,
      allDayReminders,
      reminders,
      originalState: prevState,
    } = this.state;

    if (allDay && allDayReminders?.overrides?.length === 0) {
      // if allday reminder is empty -> send in empty array
      return { useDefault: false, overrides: [] };
    }

    // TODO: originally this compared allDayReminders with prevState.Reminders
    if (
      _.isEqual(reminders, prevState.reminders) &&
      _.isEqual(allDayReminders, prevState.allDayReminders)
    ) {
      return undefined;
    }

    return allDay ? allDayReminders : reminders;
  }

  buildRecurrenceEventDataV2(isRecurrent) {
    const {
      isDuplicate,
      originalRecurringEventInstance,
      originalState: prevState,
      recurrenceRules,
    } = this.state;
    const combinedEventTime = this.getCombinedEventStartDateAndTime();

    const hasNewRecurrence =
      !_.isEqual(recurrenceRules, prevState.recurrenceRules) || isDuplicate;
    let newRecurrence = updatedRecurrenceAfterEventStartChange(
      originalRecurringEventInstance,
      combinedEventTime
    );
    if (Array.isArray(newRecurrence)) {
      newRecurrence = newRecurrence[0];
    }

    // recurrenceRules is rrule function
    if (hasNewRecurrence) {
      return isEmptyObjectOrFalsey(recurrenceRules)
        ? null
        : recurrenceRules.toString();
    }

    if (!isRecurrent) {
      return undefined;
    }

    return newRecurrence?.length > 0 ? newRecurrence : null;
  }

  exitEditView({
    addEventBackIntoWeeklyCalendar = false,
    removeTemporaryEvent = false,
    skipSetHandleExitViewState = false,
    changesSaved = false,
  }) {
    const { isNewZoomMeeting, zoomMeeting, zoomSchedulerEmail } = this.state;
    const data = {
      type: this.props.mode,
      addEventBackIntoWeeklyCalendar:
        addEventBackIntoWeeklyCalendar && !this.isTemplate(),
      originalEvent: this.state.isUpdateProposedTime
        ? this.state.originalEvent?.originalProposedEvent ??
          this.state.originalEvent
        : this.state.originalEvent,
      removeTemporaryEvent,
      addedAttendees:
        this.state.attendees?.length > 0 ||
        this.state.newlyAddedAttendees?.length > 0,
    };
    if (
      this._originalCalendarView &&
      this._originalCalendarView !== this.props.selectedCalendarView
    ) {
      mainCalendarBroadcast.publish(
        MAIN_CALENDAR_BROADCAST_VALUES.DETERMINE_CALENDAR_VIEW_CHANGE,
        this._originalCalendarView
      );
    }

    // Delete the Zoom meeting if it was created for this event but changes were not saved.
    if (zoomMeeting && isNewZoomMeeting && !changesSaved) {
      this.deleteUniqueZoomLink({ zoomMeeting, zoomSchedulerEmail });
    }

    if (!skipSetHandleExitViewState) {
      this._hasCalledHandleExitView = true;
    }

    Broadcast.publish("CALENDAR_HOME_VIEW_ON_EXIT_EVENT_FORM", data);
  }

  shouldAddEmailAttendeesModal() {
    if (
      this.state.eventNotHavePermissionToEditActualEvent ||
      this.checkMode(TEMPLATE) ||
      this.checkMode(UPDATE_TEMPLATE) ||
      this.isEditingHoldEvent() ||
      this.isOutlookCalendar()
    ) {
      return false;
    }

    if (
      this.isCreateNew() &&
      !DoesAttendeesObjectHaveOtherAttendees(
        this.state.newlyAddedAttendees,
        getCalendarProviderId(this.state.calendar)
      ) &&
      !DoesAttendeesObjectHaveOtherAttendees(
        this.state.attendees,
        getCalendarProviderId(this.state.calendar)
      )
    ) {
      return false;
    }

    return (
      DoesAttendeesObjectHaveOtherAttendees(
        this.state.originalState.attendees,
        getCalendarProviderId(this.state.calendar)
      ) ||
      DoesAttendeesObjectHaveOtherAttendees(
        this.state.attendees,
        getCalendarProviderId(this.state.calendar)
      ) ||
      DoesAttendeesObjectHaveOtherAttendees(
        this.state.newlyAddedAttendees,
        getCalendarProviderId(this.state.calendar)
      )
    );
  }

  getNewAttachments() {
    const { attachments: originalAttachments } = this.state.originalState;
    const { attachments } = this.state;
    if (isEmptyArrayOrFalsey(attachments)) {
      // prevent type error on attachments. below
      return [];
    }

    const newAttachments = attachments.filter(attachment =>
      !originalAttachments.find(existingAttachment =>
        getAttachmentId(attachment) === getAttachmentId(existingAttachment),
      ),
    );

    return newAttachments;
  }

  hasAttachmentsChanged() {
    const { attachments: originalAttachments } = this.state.originalState;
    const { attachments } = this.state;

    if (_.isEqual(_.sortBy(attachments), _.sortBy(originalAttachments))) {
      return false;
    }

    return true;
  }

  shouldAddAttachmentsPermissionsModal() {
    const attachments = this.determineAttachmentsForPermissionsModal();
    const attendees = this.determineAttendeesForPermissionsModal();

    /* Need attachments and attendees for permissions modal */
    if (
      !isEmptyArrayOrFalsey(attachments) &&
      !isEmptyArrayOrFalsey(attendees)
    ) {
      return true;
    }

    return false;
  }

  onClickSave() {
    if (this.isZoomConferencingAndDoesNotHaveLink() && !this.isTemplate()) {
      if (this._isCreatingUniqueZoom) {
        this.showUniqueZoomIsStillLoadingError();
        return;
      }

      this.closeModalAndShowZoomFailedMessage();
      return;
    }

    if (this.state.attendeeQueryText?.length > 0) {
      this.addAttendees({
        email: this.state.attendeeQueryText,
        shouldRefocusAttendeeInput: false,
      });
    }

    this._saveTimeout = setTimeout(
      () => {
        if (!this._isMounted || this.state.isInvalidAddress) {
          return;
        }

        const validation = ValidateEventData(
          this.state,
          this.props.currentTimeZone
        );

        if (!validation.passValidation) {
          this.setModalContent(
            "Event contains error",
            300,
            ERROR,
            SELECT_END_DATE
          );
          this.setState({ errorMessage: validation.errorMessage });

          return;
        }

        let modals = [];

        if (
          !this.state.eventNotHavePermissionToEditActualEvent &&
          this.state.recurringEventId &&
          this.checkMode(RECURRING_EVENT) &&
          !this.shouldDefaultToThisAndFollowing()
        ) {
          modals = modals.concat({
            title: "Edit recurring event",
            width: 350,
            content: UPDATE_RECURRING_EVENT_MODAL,
          });
        }

        if (this.shouldAddEmailAttendeesModal()) {
          modals = modals.concat({
            title: this.determineSendUpdatesModalTitle(),
            width: 350,
            content: EDIT_WITH_OTHER_GUEST_WARNING,
          });
        }

        if (this.shouldAddAttachmentsPermissionsModal()) {
          modals = modals.concat({
            content: ATTACHMENTS_PERMISSIONS_MODAL,
            title: this.determineAttachmentsPermissionsModalTitle(),
            width: 350,
          });
        }

        this.setState(
          {
            modalArray: modals,
          },
          this.processDataOnClickSave
        );
      },
      this.state.attendeeQueryText && this.state.attendeeQueryText.length > 0
        ? 500
        : 0
    );
  }

  shouldDefaultToThisAndFollowing() {
    return (
      this.state.recurringHasChanged && // if both are true -> default to this and following
      this.hasChangedOriginalEventStartDate() &&
      this.checkMode(RECURRING_EVENT) &&
      !this.state.isDuplicate
    );
  }

  processDataOnClickSave() {
    if (this.state.isOnboarding) {
      OnboardBroadcast.publish("NLP_NEXT_PAGE");
      return;
    }

    if (this.state.modalArray?.length > 0) {
      const currentModal = this.state.modalArray[0];
      this.setModalContent(
        currentModal.title,
        currentModal.width,
        currentModal.content,
      );
      return;
    }

    const description = this.fetchDescription();

    const updatedRecentContacts = this.state.newlyAddedAttendees || [];
    const filterEmail = !isEmptyObjectOrFalsey(this.state.calendar)
      ? getCalendarProviderId(this.state.calendar)
      : getUserEmail(this.getUser());
    const updatedRecentContactEmails = updatedRecentContacts
      .map((c) => getObjectEmail(c))
      .filter((e) => !equalAfterTrimAndLowerCased(e, filterEmail)); // filter out self attending email

    backendBroadcasts.publish(
      "UPDATE_RECENT_CONTACTS",
      updatedRecentContactEmails,
      getUserEmail(this.getUser())
    );
    this.setState({ description: description }, () => {
      if (this.checkMode(TEMPLATE)) {
        this.createTemplate();
      } else if (this.state.eventNotHavePermissionToEditActualEvent) {
        if (this.isZoomConferencingAndDoesNotHaveLink()) {
          this.closeModalAndShowZoomFailedMessage();
          return;
        }

        this.updateEvent();
      } else if (
        this.state.updateRepeatType === EDIT_RECURRING_FOLLOWING_EVENTS ||
        this.shouldDefaultToThisAndFollowing()
      ) {
        if (!this.hasOriginalRecurrenceEvent()) {
          this.closeModalAndShowMessage();
          return;
        } else if (this.isZoomConferencingAndDoesNotHaveLink()) {
          this.closeModalAndShowZoomFailedMessage();
          return;
        }

        this.updateFollowingRecurringInstances();
      } else if (this.state.updateRepeatType === EDIT_RECURRING_ALL_INSTANCES) {
        if (!this.hasOriginalRecurrenceEvent()) {
          this.closeModalAndShowMessage();
          return;
        } else if (this.isZoomConferencingAndDoesNotHaveLink()) {
          this.closeModalAndShowZoomFailedMessage();
          return;
        }

        this.updateAllRecurringInstances();
      } else if (this.checkMode(UPDATE_TEMPLATE)) {
        this.updateTemplate();
      } else if (
        this.isEditSingleEvent() ||
        this.state.updateRepeatType === EDIT_RECURRING_INSTANCE_ONLY
      ) {
        if (this.isZoomConferencingAndDoesNotHaveLink()) {
          this.closeModalAndShowZoomFailedMessage();
          return;
        }

        this.updateEvent();
      } else {
        if (this.isZoomConferencingAndDoesNotHaveLink()) {
          this.closeModalAndShowZoomFailedMessage();
          return;
        }

        this.createEvent();
      }

      this.exitEditView({
        addEventBackIntoWeeklyCalendar: false,
        removeTemporaryEvent: false,
        skipSetHandleExitViewState: false,
        changesSaved: true,
      });
    });

    const eventFormType = this.isCreateNew()
      ? "newEvent"
      : "updateEvent";
    trackFeatureUsage({
      action: "eventForm_" + eventFormType,
      userToken: getUserToken(this.getUser()),
    });
  }

  updateTemplate() {
    const eventData = this.constructEventData();
    const path = `templates/${getClientEventID(this.state.inputEvent)}`;
    const url = constructRequestURLV2(path);
    const payloadData = {
      body: JSON.stringify(eventData),
    };

    Broadcast.publish("EVENT_FORM_CREATE_OR_EDIT_TEMPLATE", {
      url,
      payloadData,
      originalEvent: this.state.originalEvent,
      isUpdate: true,
      lastState: this.state,
    });
  }

  createTemplate() {
    const eventData = this.constructEventData();
    const path = "templates";
    const url = constructRequestURLV2(path);
    const payloadData = {
      body: JSON.stringify(eventData),
    };

    Broadcast.publish("EVENT_FORM_CREATE_OR_EDIT_TEMPLATE", {
      url,
      payloadData,
      isUpdate: false,
      lastState: this.state,
    });
  }

  getGoogleIdOnUpdate() {
    if (this.hasEventChangedCalendars()) {
      return getCalendarProviderId(this.state.originalState.calendar);
    }

    return getEmailFromUserCalendarID(
      getCalendarUserCalendarID(this.state.calendar),
      this.props.allCalendars.allCalendars
    );
  }

  updateAllRecurringInstances() {
    const { calendar, message, originalEvent, originalRecurringEventInstance } =
      this.state;

    const { selectedCalendarView, selectedDay, shouldSendUpdate, weekStart } =
      this.props;

    const { allCalendars } = this.props.allCalendars;

    const isV2 = isVersionV2();

    const { user_calendar_id } = originalRecurringEventInstance;
    const gcal_event_id = getGCalEventId(originalRecurringEventInstance);
    const path = isV2
      ? "recurring_events/all"
      : `calendars/${user_calendar_id}/recurring_events/${gcal_event_id}/all`;

    // TODO: ASK USER IF WANT TO SEND UPDATE AND INCLUDE NOTE
    const timeWindows = determineSyncWindow({
      selectedDay,
      selectedCalendarView,
      weekStart,
    });
    // Given a message we set sendUpdates to 'none' to send a vimcal mailer instead of google's mailer
    let params = {
      sendUpdates:
        shouldSendUpdate && !message
          ? GOOGLE_UPDATES.ALL
          : DEFAULT_GOOGLE_DO_NOT_SEND_UPDATE,
      timeMin: formatTimeForBackendJsDate(timeWindows.minDate, weekStart, true),
      timeMax: formatTimeForBackendJsDate(
        timeWindows.maxDate,
        weekStart,
        false
      ),
      ical_uid: getEventICalUID(originalRecurringEventInstance),
      // V1 specific fields
      ...(!isV2 && {
        google_calendar_id: getEmailFromUserCalendarID(
          getCalendarUserCalendarID(calendar),
          allCalendars
        ),
        google_event_id: gcal_event_id,
        conferenceDataVersion: 1,
      }),
      // V2 specific fields
      ...(isV2 && {
        calendar_provider_id: getEmailFromUserCalendarID(
          getEventUserCalendarID(originalRecurringEventInstance),
          allCalendars
        ),
        event_provider_id: getGoogleEventId(originalEvent),
        master_event_id: getGoogleEventId(originalRecurringEventInstance),
        master_event_start_date: getEventStartValue(
          originalRecurringEventInstance
        ),
        master_event_end_date: getEventEndValue(originalRecurringEventInstance),
      }),
    };

    // Need to comment out since on creating event, google needs conferenceDataVersion1 to keep event conferencing
    // createParams['conferenceDataVersion'] = 1;
    // if (this.conferenceChanged()
    // || (this.state.conference === zoomString && this.state.updatedToPersonalZoomLink)
    // ) {
    //   params['conferenceDataVersion'] = 1;
    // }

    const url = `${constructRequestURL(path, isV2)}?${constructQueryParams(
      params
    )}`;
    const eventData = isV2
      ? this.constructEventDataV2(true)
      : this.constructEventData(true);

    // event_start and end will always exist here because isRecurringEvent triggers putting event start and end in
    const { start, end } = this.updateStartAndEndDateForRecurringAll({
      eventStart: eventData.calendar_event.event_start,
      eventEnd: eventData.calendar_event.event_end,
      originalRecurringEventInstance,
    });
    if (isV2) {
      // Use the original start time for Google events.
      //
      // Otherwise, the new series of recurring events will start
      // at the selected event's start date, rather than the first
      // event in the series. In other words, it will delete all
      // past instances.
      //
      eventData.calendar_event.event_start = start;
      eventData.calendar_event.event_end = end;
    } else {
      eventData.event.google_calendar_event.start = start;
      eventData.event.google_calendar_event.end = end;
    }

    const emailData = this.constructEmailData();

    const payloadData = {
      body: JSON.stringify({
        ...eventData,
        ...emailData,
        message,
      }),
      headers: getDefaultHeaders(),
    };

    Broadcast.publish("UPDATE_RECURRING_ALL_INSTANCES", {
      url,
      payloadData,
      originalEvent,
      lastState: this.state,
      userEmail: getCalendarUserEmail(calendar),
    });
  }

  // TODO: PASS IN EVENT (TO GET THE RECURRING EVENT ID) AS WELL AS THE EVENT DATA TO BE UPDATED
  updateFollowingRecurringInstances() {
    const {
      currentTimeZone,
      originalRecurrenceEventIndex,
      selectedCalendarView,
      selectedDay,
      setOriginalRecurrenceEventIndex,
      weekStart,
    } = this.props;
    const { allCalendars } = this.props.allCalendars;

    const {
      allDay,
      calendar,
      originalEvent,
      originalState,
      recurringEventId,
      shouldSendUpdate,
      originalRecurringEventInstance,
    } = this.state;

    if (!originalEvent || !originalRecurringEventInstance) {
      this.closeModalAndShowMessage();
      return;
    }

    const isV2 = isVersionV2();
    const eventData = isV2
      ? this.constructEventDataV2(true, true)
      : this.constructEventData(true);

    const isFirstRecurring = isEventFirstRecurringInstance(
      getEventOriginalStartTime(originalState.originalEvent),
      getEventStart(originalRecurringEventInstance)
    );

    if (isFirstRecurring) {
      this.updateAllRecurringInstances();
      return;
    }

    const updatePath = isV2
      ? "recurring_events/following"
      : `calendars/${getEventUserCalendarID(
          eventData
        )}/recurring_events/${recurringEventId}`;
    const deletePath = isV2
      ? isFirstRecurring
        ? "recurring_events/all"
        : updatePath
      : isFirstRecurring
      ? updatePath + "/all"
      : updatePath;

    const timeWindows = determineSyncWindow({
      selectedDay,
      selectedCalendarView,
      weekStart,
    });
    const { provider_id: masterEventId } = originalRecurringEventInstance;
    // Given a message we set sendUpdates to 'none' to send a vimcal mailer instead of google's mailer
    // TODO: a similar pattern is found in deleteEventButton.js. We should abstract and keep it DRY
    const params = {
      sendUpdates: shouldSendUpdate
        ? GOOGLE_UPDATES.ALL
        : DEFAULT_GOOGLE_DO_NOT_SEND_UPDATE,
      timeMin: formatTimeForBackendJsDate(timeWindows.minDate, weekStart, true),
      timeMax: formatTimeForBackendJsDate(
        timeWindows.maxDate,
        weekStart,
        false
      ),
      // V1 specific fields
      ...(!isV2 && {
        google_calendar_id: getEmailFromUserCalendarID(
          getCalendarUserCalendarID(calendar),
          allCalendars
        ),
        google_event_id: getGoogleEventId(originalRecurringEventInstance),
        conferenceDataVersion: 1,
      }),
      // V2 specific fields
      ...(isV2 && {
        calendar_provider_id: getEmailFromUserCalendarID(
          getEventUserCalendarID(originalRecurringEventInstance),
          allCalendars
        ),
        event_provider_id: getGoogleEventId(originalRecurringEventInstance),
        ical_uid: getEventICalUID(originalRecurringEventInstance),
        master_event_id: masterEventId,
        master_event_start_date: getEventStartValue(
          originalRecurringEventInstance
        ),
      }),
    };

    // Comment out below because google requires conferenceDataVersion on create event to properly show events
    // if (this.conferenceChanged()
    //   || this.state.conference !== noConferenceString
    //   || (this.state.conference === zoomString && this.state.updatedToPersonalZoomLink)
    // ) {
    //   params['conferenceDataVersion'] = 1;
    // }

    const ruleString = getRRuleStringFromRecurrence(
      originalRecurringEventInstance
    );

    const deleteParams = {
      ...params,
      sendUpdates: GOOGLE_UPDATES.NONE,
      conferenceDataVersion: undefined,
    };

    const deleteQueryParams = constructQueryParams(deleteParams);
    const deleteUrl = `${constructRequestURL(
      deletePath,
      isV2
    )}?${deleteQueryParams}`;

    const deleteCutOff = createRecurrenceCutOffDate(originalEvent);

    const updated_original_recurrence = trimOriginalRecurrenceRule(
      ruleString,
      deleteCutOff
    );

    const deleteRequestBody = {
      organizer: eventData.organizer,
      // Only to be used when updating recurring as non-organizer with edit rights
      // E.g. see constructEventData's use of eventHasModifyPermissionAndIsNotAnOrganizer (this.canModifyButNotOrganizer())
      // Risk of deleting organizer's events in other cases
      overrideCalendarId: eventData.overrideCalendarId,
      ical_uid: getEventICalUID(originalEvent),
      updated_original_recurrence,
      master_event_id: getEventMasterEventID(originalEvent),
      time_zone: currentTimeZone,
    }; // updated_original_recurrence is used to delete the rest of the events in the original recurrence

    const deletePayloadData = {
      body: JSON.stringify(deleteRequestBody),
      headers: getDefaultHeaders(),
    };

    const queryParams = constructQueryParams(params);
    const updateUrl = `${constructRequestURL(updatePath, isV2)}?${queryParams}`;

    const payloadData = {
      body: JSON.stringify(eventData),
      headers: getDefaultHeaders(),
    };

    Broadcast.publish("UPDATE_FOLLOWING_INSTANCES_AS_ORGANIZER", {
      updateUrl,
      deleteUrl,
      deletePayloadData,
      payloadData,
      eventData,
      originalEvent: originalEvent,
      lastState: this.state,
      userEmail: calendar?.userEmail,
    });

    if (isOutlookEvent(originalEvent)) {
      const updatedIndex = resetOriginalRecurringEventIndex({
        currentEvent: originalEvent,
        originalRecurrenceEventIndex,
      });

      setOriginalRecurrenceEventIndex(updatedIndex);
    }
  }

  constructEmailData() {
    const { originalEvent } = this.state;
    const { currentTimeZone } = this.props;
    const { start, end } = this.calculateStartAndEndDate(true);
    const event = {
      raw_json: getEventRawData(originalEvent),
      description: this.state.description,
      location: this.state.location,
      event_start: start,
      event_end: end,
    };
    const { eventStart, eventEnd } = formatEventForReactBigCalendar({
      event,
      currentTimeZone,
    });
    event.eventStart = eventStart;
    event.eventEnd = eventEnd;
    const { masterAccount } = this.props.masterAccount;

    event.attendees = this.determineAttendeeToAddress().attendees;

    return constructEmailData({
      event,
      currentUser: this.getUser(),
      subject: this.state.summary,
      currentTimeZone,
      masterAccount,
      hideAttendeesEmail: this.isHideAttendees(),
    });
  }

  updateEvent() {
    const {
      calendar,
      conference,
      eventID,
      message,
      originalEvent,
      shouldSendUpdate,
      updatedToPersonalZoomLink,
    } = this.state;

    const isAppVersion2 = isVersionV2();
    const eventData = isAppVersion2
      ? this.constructEventDataV2()
      : this.constructEventData();

    const emailData = this.constructEmailData();

    // When the description is updated, the emailData.description unfortunately contains the old unupdated description
    // eventData.event.google_calendar_event.description contains the updated description, hence the explicit object
    // destructuring that takes place at the end of the payload object
    const updatedDescription =
      eventData?.event?.google_calendar_event?.description;

    const hasConferenceVersion =
      this.hasConferencingChanged() ||
      (conference === zoomString && updatedToPersonalZoomLink);
    const queryParams = constructQueryParams({
      conferenceDataVersion: hasConferenceVersion ? 1 : undefined,
      // If there is an optional message provided then do not send update via google mailer.
      // sendUpdates set to 'none' with a message field in payload will trigger Vimcal initiated mailer
      sendUpdates:
        shouldSendUpdate && !message
          ? GOOGLE_UPDATES.ALL
          : DEFAULT_GOOGLE_DO_NOT_SEND_UPDATE,
      // API Version 1 properties
      google_calendar_id: !isAppVersion2
        ? this.getGoogleIdOnUpdate()
        : undefined,
      google_event_id: !isAppVersion2
        ? getGoogleEventId(originalEvent)
        : undefined,
      // API Version 2 properties
      calendar_provider_id: isAppVersion2
        ? this.getGoogleIdOnUpdate()
        : undefined,
      event_provider_id: isAppVersion2
        ? getGoogleEventId(originalEvent)
        : undefined,
    });

    const url = `${constructRequestURL(
      "events",
      isAppVersion2
    )}?${queryParams}`;
    const payloadData = {
      body: JSON.stringify({
        ...eventData,
        ...emailData,
        ...(message && { message }),
        ...(updatedDescription && { description: updatedDescription }),
        user_event_id: eventID,
        user_calendar_id: getCalendarUserCalendarID(calendar),
      }),
    };

    Broadcast.publish(BROADCAST_VALUES.UPDATE_EVENT, {
      url,
      payloadData,
      originalEvent,
      hasCalendarChanged: this.hasEventChangedCalendars(),
      userEmail: calendar?.userEmail,
      lastState: this.state,
      eventData,
    });
  }

  createEvent() {
    const isAppVersion2 = isVersionV2();
    const eventData = isAppVersion2
      ? this.constructEventDataV2()
      : this.constructEventData();
    // note: for outlook, we create conferencing through online_meeting_provider

    // Given a message we set sendUpdates to 'none' to send a vimcal mailer instead of google's mailer
    const { allCalendars } = this.props.allCalendars;
    const params = {
      sendUpdates: this.state.shouldSendUpdate
        ? GOOGLE_UPDATES.ALL
        : DEFAULT_GOOGLE_DO_NOT_SEND_UPDATE,
      conferenceDataVersion: 1,
      google_calendar_id: getEmailFromUserCalendarID(
        getCalendarUserCalendarID(this.state.calendar),
        allCalendars
      ),
    };

    if (
      !!eventData?.["event"]?.["google_calendar_event"]?.["recurrence"] &&
      !isAppVersion2
    ) {
      let timeWindows = determineSyncWindow({
        selectedDay: this.props.selectedDay,
        selectedCalendarView: this.props.selectedCalendarView,
        weekStart: this.props.weekStart,
      });
      params["timeMin"] = formatTimeForBackendJsDate(
        timeWindows.minDate,
        this.props.weekStart,
        true
      );
      params["timeMax"] = formatTimeForBackendJsDate(
        timeWindows.maxDate,
        this.props.weekStart,
        false
      );
    } else if (
      !!eventData?.["calendar_event"]?.["recurrence"] &&
      isAppVersion2
    ) {
      const timeWindows = determineSyncWindow({
        selectedDay: this.props.selectedDay,
        selectedCalendarView: this.props.selectedCalendarView,
        weekStart: this.props.weekStart,
      });
      params["timeMin"] = formatTimeForBackendJsDate(
        timeWindows.minDate,
        this.props.weekStart,
        true
      );
      params["timeMax"] = formatTimeForBackendJsDate(
        timeWindows.maxDate,
        this.props.weekStart,
        false
      );
    }

    const queryParams = constructQueryParams(params);
    const path = "events";
    const url = constructRequestURL(path, isAppVersion2) + `?${queryParams}`;

    const payloadData = {
      body: JSON.stringify(eventData),
    };

    Broadcast.publish("CREATE_EVENT", {
      url,
      payloadData,
      userEmail: this.getCalendarUserEmail(),
      lastState: this.state,
      eventData,
    });
  }

  setEventTimeZone(timeZones) {
    if (
      timeZones.start === selectTimeZone ||
      timeZones.end === selectTimeZone
    ) {
      this.setState({
        hasTimeZoneBeenSet: false,
        shouldDisplayModal: false,
      });
    } else {
      let newTimeZone = timeZones.start;

      this.setState(
        {
          startTimeZone: timeZones.start,
          endTimeZone: timeZones.end,
          separateStartEndTimezone: !(timeZones.start === timeZones.end),
          hasTimeZoneBeenSet: true,
          shouldDisplayModal: false,
        },
        () => {
          if (this.state.hasTimeZoneAlreadyBeenSet) {
            // Timezone has already been set prior to opening event form.
            // Do not set it again on changing time zone
          } else {
            Broadcast.publish("SELECT_TIME_ZONE", {
              timeZone: newTimeZone,
              // Prevent bug where changing time zones refresh causes old event to show
              editingEventProviderId: getGCalEventId(this.state.originalEvent),
            });
          }

          this.setTemporaryEvents();
        }
      );
    }
  }

  setModalContent(title, width, modalContent, id, modalHeight = null) {
    const getTop = () => {
      if (title === CONFERENCE_ROOMS) {
        // place modal as center modal
        return 0;
      }
      return getComponentLocation(
        id,
        modalHeight,
        calculateMarginTop(this.props.shouldShowTopBar)
      ).top;
    };
    const top = getTop();

    this.setState({
      modalTitle: title,
      modalWidth: width,
      modalContent,
      shouldDisplayModal: true,
      modalTop: top,
    });
  }

  setRecurring(input) {
    const repeatText = capitalizeFirstLetter(input.rRule.toText());

    this.setState({
      recurrenceRules: input.rRule,
      repeatText: repeatText,
      shouldDisplayEventRepeatCustomModal: false,
      lastTimeSet: new Date(),
      recurringHasChanged: repeatText !== this.state.repeatText,
    });

    this.closeModal();
  }

  setConferenceAndBlur(option) {
    if (this.state.eventNotHavePermissionToEditActualEvent) {
      return;
    }

    this.setConference({
      option,
      ignoreModal: true,
    });
  }

  openZoomSettingsModal() {
    layoutBroadcast.publish(APP_SETTINGS.OPEN_SETTINGS_MODAL, {
      initialContent: APP_SETTINGS.CONFERENCING,
      conferencingSetting: CONFERENCING_SETTINGS_ID.ZOOM,
      initialSettingsUser: this.getUser(),
    });

    this.selectConferenceSection.current &&
      this.selectConferenceSection.current.blur();
  }

  setConference({ option, notifyChange = false, ignoreModal = false }) {
    if (!option?.value) {
      return;
    }
    const { value: updatedConferencing } = option;

    const defaultToZoomPersonalLink = this.isDefaultZoomPersonalLink();

    if (!ignoreModal && !this.isTemplate()) {
      if (
        updatedConferencing === zoomString &&
        this.isDefaultZoomPersonalLinkAndMissingLink()
      ) {
        // default to personal linka nd no personal link
        this.openZoomSettingsModal();
        return;
      } else if (
        updatedConferencing === zoomString &&
        this.isDefaultZoomUniqueAndMissingLink()
      ) {
        // default to unique but not logged in
        this.setState({
          zoomMeetingError: REAUTH_ZOOM_ERROR,
          conference: updatedConferencing,
        });
        return;
      }

      if (
        (updatedConferencing === phoneNumberConference ||
          updatedConferencing === WHATS_APP_STRING) &&
        !isValidPhoneConferencing(this.getUser())
      ) {
        // open modal to show option to add phone number
        layoutBroadcast.publish(APP_SETTINGS.OPEN_SETTINGS_MODAL, {
          initialContent: APP_SETTINGS.CONFERENCING,
          conferencingSetting: CONFERENCING_SETTINGS_ID.PHONE,
          initialSettingsUser: this.getUser(),
        });

        this.selectConferenceSection.current?.blur();
        return;
      }
    }

    const lastUsedConferencing = this.state.conference;

    const updatedState = { conference: updatedConferencing };
    const { zoomMeeting, location } = this.state;
    let hasRemovedUniqueZoomDescription = false; // false by default
    if (
      lastUsedConferencing === ZOOM_STRING &&
      updatedConferencing !== ZOOM_STRING &&
      zoomMeeting
    ) {
      if (!isRecurringZoomMeeting(zoomMeeting)) {
        this.deleteUniqueZoomLink({ zoomMeeting }); // remove unique zoom
      }
      const { updatedLocation, updatedDescription, hasUpdatedDescription } =
        stripOutZoomFromLocationAndDescription({
          zoomMeeting,
          location,
          description: this.getDescriptionContent(),
        });
      updatedState.conferenceURL = null;
      updatedState.zoomMeetingError = null;
      updatedState.zoomMeeting = null;
      if (hasUpdatedDescription) {
        hasRemovedUniqueZoomDescription = true;
        updatedState.description = updatedDescription;
        this.description.setEditorContents(
          this.description.getEditor(),
          updatedDescription
        );
      }
      if (updatedLocation !== location) {
        updatedState.location = updatedLocation;
      }
    }
    this.setState(updatedState, () => {
      if (
        !defaultToZoomPersonalLink &&
        updatedConferencing === zoomString &&
        !this.isTemplate() &&
        !this.isEditingHoldEvent() // Don't actually make link for holds
      ) {
        // do not create unique for template, otherwise it won't be unique
        this.createUniqueZoomLink();
      }

      if (notifyChange) {
        this.setComponentHasChangedNotification(VIDEO_CONFERENCE);
      }

      if (this.isOutlookCalendar()) {
        this.setOutlookConferencing({
          newConferencing: updatedConferencing,
          lastUsedConferencing,
          hasRemovedUniqueZoomDescription,
        });
      }
    });
  }

  getUser() {
    // can not use getCalendarUserEmail, otherwise circular
    const { masterAccount } = this.props.masterAccount;
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    if (isUserMaestroUser(masterAccount)
      && isCalendarExecutiveCalendar({ calendar: this.state?.calendar, allLoggedInUsers })
    ) {
      const matchingExecUser = this.getMatchingExecOrNormalUserFromCalendar();
      if (matchingExecUser) {
        return matchingExecUser;
      }
    }
    const calendarUserEmail = getCalendarUserEmail(this.state?.calendar);
    const { currentUser } = this.props;
    if (!calendarUserEmail) {
      return currentUser; // have to use currentUser here otherwise circular loop
    }
    return (
      getMatchingUserFromAllUsers({
        allUsers: allLoggedInUsers,
        userEmail: calendarUserEmail,
      }) ?? currentUser
    );
  }

  isUserBeingScheduledFor() {
    const { masterAccount } = this.props.masterAccount;

    return isUserBeingScheduledFor({
      user: this.getUser(),
      schedulers: this.getZoomScheduler(),
      masterAccount,
    });
  }

  async createUniqueZoomLink(
    zoomSchedulerEmail,
    onSuccessFunction = null,
    onFailureFunction = null
  ) {
    const { zoomMeeting } = this.state;
    if (this._isCreatingUniqueZoom || zoomMeeting) {
      // currently creating unique zoom
      return;
    }

    if (this.state.zoomMeetingError) {
      this.setState({ zoomMeetingError: null });
    }

    if (this._zoomTimer) {
      clearTimeout(this._zoomTimer);
      this._zoomTimer = null;
    }

    this._isCreatingUniqueZoom = true;
    this._zoomTimer = setTimeout(() => {
      if (!this._isMounted || !this._hasTriedCreatingUniqueZoomAlready) {
        return;
      }
      this._isCreatingUniqueZoom = false;
      this._hasTriedCreatingUniqueZoomAlready = true;
      this.createUniqueZoomLink(
        zoomSchedulerEmail,
        onSuccessFunction,
        onFailureFunction
      );
    }, 3000);

    const isSchedulingForExec = this.isSchedulingForExec();
    const schedulers = this.getZoomScheduler();

    const getZoomParam = () => {
      if (zoomSchedulerEmail) {
        return {
          ...getZoomSchedulerID({
            user: { email: zoomSchedulerEmail },
            schedulers,
          }),
          schedule_for: zoomSchedulerEmail,
        };
      }

      if (isSchedulingForExec) {
        return {
          ...getZoomSchedulerID({
            user: this.getZoomUser(),
            schedulers,
          }),
          schedule_for: getObjectEmail(this.getZoomUser()),
        };
      }

      // if there's matching zoom scheduler email, use that one
      const schedulerEmails = getZoomSchedulerEmails(schedulers);
      if (schedulerEmails.includes(this.getCalendarEmail())) {
        return {
          ...getZoomSchedulerID({
            user: { email: this.getCalendarEmail() },
            schedulers,
          }),
          schedule_for: this.getCalendarEmail(),
        };
      }

      return {};
    };

    const zoomParam = getZoomParam();

    zoomParam.title = this.state.summary;
    const { start, end } = this.calculateStartAndEndDate();
    zoomParam.start_time = start.date ?? start.dateTime;
    zoomParam.end_time = start.date ?? end.dateTime;

    this.setState({ hasAttemptedToCreateUniqueZoom: true });

    try {
      const payloadData = {
        body: JSON.stringify(zoomParam),
      };
      const zoomAuthEmail = this.determineZoomAuthEmail(zoomSchedulerEmail);
      const path = "zoom_meetings";
      const url = constructRequestURLV2(path);
      const newZoomMeeting = await fetcherPost({
        url,
        payloadData,
        email: zoomAuthEmail,
      });

      if (!this._isMounted) {
        return;
      }

      if (this.isOutlookCalendar() || this.isUserMaestroUser()) {
        this.addUniqueZoomLinkToDescriptionAndLocation(newZoomMeeting);
      }

      if (!zoomMeeting) {
        // no existing zoom meeting
        this._isCreatingUniqueZoom = false;
        if (this._zoomTimer) {
          clearTimeout(this._zoomTimer);
          this._zoomTimer = null;
        }

        if (isEmptyObjectOrFalsey(newZoomMeeting)) {
          // error occured
          this.setState({
            zoomMeetingError: GENERIC_ZOOM_ERROR,
            rerenderCount: this.state.rerenderCount + 1,
          });
          if (onFailureFunction) {
            onFailureFunction();
          }
          return;
        }

        if (newZoomMeeting.code === 403) {
          this.setState({
            zoomMeetingError: REAUTH_ZOOM_ERROR,
            rerenderCount: this.state.rerenderCount + 1,
          });
          if (onFailureFunction) {
            onFailureFunction();
          }
          return;
        }

        const updatedState = {
          rerenderCount: this.state.rerenderCount + 1,
          zoomMeetingError: null,
        };

        if (newZoomMeeting && !newZoomMeeting.error) {
          updatedState.isNewZoomMeeting = true;
          updatedState.zoomMeeting = newZoomMeeting;
          if (getZoomJoinURL(newZoomMeeting)) {
            updatedState.conferenceURL = getZoomJoinURL(newZoomMeeting);
          }
        }

        if (onSuccessFunction) {
          onSuccessFunction();
        }
        const schedulers = this.getZoomScheduler();

        // conditions below are similar to that of getZoomParam() above
        if (this.state.zoomSchedulerEmail) {
          // ignore
        } else if (zoomSchedulerEmail) {
          updatedState.zoomSchedulerEmail = zoomSchedulerEmail;
        } else if (isSchedulingForExec) {
          updatedState.zoomSchedulerEmail = getObjectEmail(this.getZoomUser());
        } else if (
          getZoomSchedulerEmails(schedulers).includes(this.getCalendarEmail())
        ) {
          // we have zoom scheduling access to the email
          updatedState.zoomSchedulerEmail = this.getCalendarEmail();
        }

        if (zoomSchedulerEmail) {
          // changed zoom from one user to another
          updatedState.wasZoomUpdated = true;
        }
        // rerender state
        this.setState(updatedState);
      } else {
        if (this._zoomTimer) {
          clearTimeout(this._zoomTimer);
          this._zoomTimer = null;
        }

        this.deleteUniqueZoomLink({
          zoomMeeting: newZoomMeeting,
          zoomSchedulerEmail,
        });
      }
    } catch (err) {
      if (!this._isMounted) {
        return;
      }
      if (this._zoomTimer) {
        clearTimeout(this._zoomTimer);
        this._zoomTimer = null;
      }

      this._isCreatingUniqueZoom = false;
      handleError(err);
    }
  }

  deleteUniqueZoomLink({ zoomMeeting, zoomSchedulerEmail }) {
    if (!zoomMeeting) {
      return;
    }
    if (isZoomMeetingPersonalLink(zoomMeeting)) {
      return;
    }
    ApiClient.deleteZoomMeeting(
      zoomMeeting,
      this.determineZoomAuthEmail(zoomSchedulerEmail)
    );
  }

  determineZoomAuthEmail(zoomSchedulerEmail) {
    const userEmail = getUserEmail(this.getUser());

    /* Get user for selected email in dropdown */
    if (zoomSchedulerEmail) {
      return (
        getAuthedZoomEmail(this.getZoomScheduler(), zoomSchedulerEmail) ||
        userEmail
      );
    }

    /* Get email for delegated user */
    const isSchedulingForDelegatedUser = this.isSchedulingForExec();
    if (isSchedulingForDelegatedUser) {
      return (
        getAuthedZoomEmail(
          this.getZoomScheduler(),
          getObjectEmail(this.getZoomUser()),
        ) || userEmail
      );
    }

    /* Default to the current user's email */
    return userEmail;
  }

  // this also checks if user selected on secondary calendar that has a matching exec account
  isSchedulingForExec() {
    if (this.isUserBeingScheduledFor()) {
      return true;
    }
    if (this.isPrimaryCalendar()) {
      // only do this check for secondary calendars
      return false;
    }
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    if (!isCalendarExecutiveCalendar({ calendar: this.state?.calendar, allLoggedInUsers })) {
      return false;
    }
    const matchingExecUser = getMatchingExecutiveUserFromCalendar({
      calendar: this.state?.calendar,
      allLoggedInUsers,
    });
    if (matchingExecUser) {
      return true;
    }
    return false;
  }

  getMatchingExecOrNormalUserFromCalendar() {
    const { masterAccount } = this.props.masterAccount;
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    return getMatchingExecOrNormalUserFromCalendar({
      allLoggedInUsers,
      calendar: this.state?.calendar,
      masterAccount,
    });
  }

  getZoomUser() {
    // if select secondary calendar that has a matching exec account -> use that one
    const defaultUser = this.getUser();
    if (this.isUserBeingScheduledFor()) {
      return defaultUser;
    }
    if (!isGoogleUser(this.state?.calendar)) {
      return defaultUser;
    }
    if (this.isPrimaryCalendar()) {
      return defaultUser;
    }
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    if (!isCalendarExecutiveCalendar({ calendar: this.state?.calendar, allLoggedInUsers })) {
      return defaultUser;
    }
    const matchingExecUser = getMatchingExecutiveUserFromCalendar({
      calendar: this.state?.calendar,
      allLoggedInUsers,
    });
    if (matchingExecUser) {
      return matchingExecUser;
    }
    return defaultUser;
  }

  setBusyOrFree(option) {
    this.setState({ transparency: option.value, hasChangedTransparency: true });
  }

  determineSendUpdatesModalTitle() {
    if (this.isCreateNew()) {
      return "Would you like to send invitation emails to guests?";
    }

    return this.determineAttendeeToAddress().message;
  }

  determineAttachmentsForPermissionsModal() {
    const {
      attachments,
      newlyAddedAttendees,
    } = this.state;
    const newlyAddedAttachments = this.getNewAttachments();

    // New attachment(s), no new attendee(s) - Old attendee(s) need(s) access to new file(s)
    if (
      !isEmptyArrayOrFalsey(newlyAddedAttachments) &&
      isEmptyArrayOrFalsey(newlyAddedAttendees)
    ) {
      return newlyAddedAttachments;
    }
    // New attachment(s), new attendee(s) - All attendee(s) need(s) access to all file(s)
    if (
      !isEmptyArrayOrFalsey(newlyAddedAttachments) &&
      !isEmptyArrayOrFalsey(newlyAddedAttendees)
    ) {
      return newlyAddedAttachments;
    }

    // No new attachment(s), new attendee(s) - New attendee(s) need(s) access to old file(s)
    if (
      isEmptyArrayOrFalsey(newlyAddedAttachments) &&
      !isEmptyArrayOrFalsey(newlyAddedAttendees)
    ) {
      return attachments;
    }

    return [];
  }

  determineAttendeesForPermissionsModal() {
    const {
      attendees,
      newlyAddedAttendees,
    } = this.state;
    const newlyAddedAttachments = this.getNewAttachments();

    // New attachment(s), no new attendee(s) - Old attendee(s) need(s) access to new file(s)
    if (
      !isEmptyArrayOrFalsey(newlyAddedAttachments) &&
      isEmptyArrayOrFalsey(newlyAddedAttendees)
    ) {
      return attendees;
    }
    // New attachment(s), new attendee(s) - All attendee(s) need(s) access to all file(s)
    if (
      !isEmptyArrayOrFalsey(newlyAddedAttachments) &&
      !isEmptyArrayOrFalsey(newlyAddedAttendees)
    ) {
      return [...attendees, ...newlyAddedAttendees];
    }

    // No new attachment(s), new attendee(s) - New attendee(s) need(s) access to old file(s)
    if (
      isEmptyArrayOrFalsey(newlyAddedAttachments) &&
      !isEmptyArrayOrFalsey(newlyAddedAttendees)
    ) {
      return newlyAddedAttendees;
    }

    return [];
  }

  determineAttachmentsPermissionsModalTitle() {
    const attachments = this.determineAttachmentsForPermissionsModal();
    const attachmentText = attachments?.length > 1 ? "these files" : `"${getAttachmentName(attachments[0])}`;
    const attendees = this.determineAttendeesForPermissionsModal();
    const attendeeText = attendees?.length > 1 ? "People need" : "Someone needs";

    return `${attendeeText} access to ${attachmentText}`;
  }

  setVisibility(option) {
    this.setState({
      visibility: option.value,
    });
  }

  isProxyInPersonEvent() {
    // checks if based on title, the event is in person
    const { summary } = this.state;
    const loweredCase = lowerCaseAndTrimStringWithGuard(summary);
    const inPersonTitles = [
      "in person",
      "lunch",
      "coffee",
      "dinner",
      "breakfast",
    ];
    return inPersonTitles.some((title) => loweredCase.includes(title));
  }

  addAttendees({
    email,
    shouldRefocusAttendeeInput = true,
    notifyChange = false,
    userEmail,
    contact,
  }) {
    let newAttendee = email;

    if (notifyChange) {
      this.setComponentHasChangedNotification(ATTENDEE);
    }

    // Refocus attendee
    shouldRefocusAttendeeInput && this.goToAttendeeList();
    const attendeeEmailWithoutWhiteSpaces = lowerCaseAndTrimStringWithGuard(newAttendee);

    if (!attendeeEmailWithoutWhiteSpaces) {
      return;
    }

    // Split out so we know if something is pasted in or manually entered
    // make sure Test email <testtest@gmail.com> works
    const attendeesArray = splitStringIntoEmails(attendeeEmailWithoutWhiteSpaces);

    if (attendeesArray.length > 1) {
      // Enter in string separated by white space
      const newState = this.processListOfEmailsForAttendees(attendeesArray);

      if (!newState) {
        return;
      }

      this.setState(newState);
      return;
    }

    const getEmail = () => {
      if (attendeesArray.length === 1) {
        return attendeesArray[0];
      }
      return attendeeEmailWithoutWhiteSpaces;
    };

    const attendeeEmail = getEmail();

    if (!isValidEmail(attendeeEmail)) {
      this.setValidAddressWarning(true);
      return;
    }

    let currentAttendees = this.state.attendees
      ? this.state.attendees.map((a) => getObjectEmail(a))
      : [];
    let recentlyAddedAttendees = this.state.newlyAddedAttendees
      ? this.state.newlyAddedAttendees.map((a) => getObjectEmail(a))
      : [];

    let existingAttendees = []
      .concat(currentAttendees)
      .concat(recentlyAddedAttendees);

    if (existingAttendees.includes(attendeeEmail)) {
      return;
    }

    // Add emails to temporary secondary calendar
    let updatedEventFormEmails = this.props.eventFormEmails || [];
    updatedEventFormEmails = removeDuplicatesFromArray(
      updatedEventFormEmails.concat(attendeeEmail)
    );

    this.onUpdateEventFormEmails(updatedEventFormEmails, userEmail);

    let newState = {};
    this.addDefaultConferencing();

    if (
      isEmptyArrayOrFalsey(this.state.newlyAddedAttendees) &&
      !this.attendeeAlreadyContainOwnEmail()
    ) {
      const selfResponse = this.getAutoGeneratedSelfResponse();

      const newAttendeeResponse = {
        email: attendeeEmail,
        responseStatus: "needsAction",
      };

      if (
        isSameEmail(
          this.getCalendarEmail(),
          attendeeEmail
        )
      ) {
        // if you add yourself
        newState["newlyAddedAttendees"] = [selfResponse];
      } else {
        const {
          emailToNameIndex,
        } = this.props;
        const calendarEmail = this.getCalendarEmail();
        const getAttendeeName = () => {
          try {
            if (isTypeString(contact?.name)) {
              return contact.name;
            }
            if (isTypeString(contact?.fullName)) {
              return contact.fullName;
            }
            return emailToNameIndex[attendeeEmail];
          } catch (err) {
            return "";
          }
        };

        const attendeeName = getAttendeeName();
        const calendarName = emailToNameIndex[calendarEmail];
        newState["newlyAddedAttendees"] = [selfResponse, newAttendeeResponse];
        if (!this.state.summary
          && attendeeEmail
          && calendarName
          && attendeeName
          && isTypeString(attendeeName)
        ) {
          // we only want to add the summary if the attendee name exists and is a string
          // no title -> add default calendar_name // attendee name
          newState["summary"] = `${calendarName} <> ${attendeeName}`;
        }
      }
    } else {
      const newAttendeeObject = {
        email: attendeeEmail,
        responseStatus: "needsAction",
      };

      newState["newlyAddedAttendees"] =
        this.state.newlyAddedAttendees.concat(newAttendeeObject);
    }

    this.setState(newState);
  }

  isLocationEmpty() {
    return !this.state.location;
  }

  toggleDisplayModalState(keyName) {
    if (this.state.modalContent === EVENT_REPEAT) {
      this.setState({
        shouldDisplayEventRepeatCustomModal: false,
      });
    } else if (keyName === "displayEventRepeatOptionsModal") {
      this.setState({ shouldDisplayEventRepeatCustomModal: false });
    } else {
      this.setState((prevState, props) => {
        return { [keyName]: !prevState[keyName] };
      });
    }
  }

  processListOfEmailsForAttendees(attendeesArray) {
    let currentAttendees = this.state.attendees
      ? this.state.attendees.map((a) => getObjectEmail(a))
      : [];
    let recentlyAddedAttendees = this.state.newlyAddedAttendees
      ? this.state.newlyAddedAttendees.map((a) => getObjectEmail(a))
      : [];

    let existingAttendees = []
      .concat(currentAttendees)
      .concat(recentlyAddedAttendees);

    let filterForEmail = attendeesArray.filter(
      (e) =>
        isValidEmail(e) &&
        e !== getCalendarProviderId(this.state.calendar) &&
        !existingAttendees.includes(e.toLowerCase())
    );

    if (filterForEmail.length === 0) {
      return null;
    }

    filterForEmail = removeDuplicatesFromArray(filterForEmail);

    // Update event form emails below
    let updatedEventFormEmails = _.clone(this.props.eventFormEmails) || [];
    updatedEventFormEmails = removeDuplicatesFromArray(
      updatedEventFormEmails.concat(filterForEmail)
    );

    this.onUpdateEventFormEmails(updatedEventFormEmails);

    let newState = {};
    this.addDefaultConferencing();

    let newlyAddedAttendees = this.state.newlyAddedAttendees || [];
    filterForEmail.forEach((e) => {
      newlyAddedAttendees = newlyAddedAttendees.concat({
        email: e,
        responseStatus: "needsAction",
      });
    });

    if (
      this.state.newlyAddedAttendees.length === 0 &&
      !this.attendeeAlreadyContainOwnEmail()
    ) {
      newlyAddedAttendees = newlyAddedAttendees.concat(this.getAutoGeneratedSelfResponse());
    }

    newState.newlyAddedAttendees = newlyAddedAttendees;

    return newState;
  }

  getAutoGeneratedSelfResponse() {
    return {
      email: this.getCalendarEmail(),
      responseStatus: "accepted",
    };
  }

  addDefaultConferencing() {
    // Add default conferencing if no conferencing is set when adding attendees
    if (this._userExplicitlySetNoConferencing) {
      return;
    }
    if (this.isProxyInPersonEvent()) {
      return;
    }
    if (
      !(
        this.state.conference === noConferenceString &&
        !this.doesLocationOrDescriptionContainConferencing() &&
        this.isLocationEmpty()
      )
    ) {
      return;
    }
    if (
      this.state.conference === noConferenceString &&
      !this.doesLocationOrDescriptionContainConferencing() &&
      this.isLocationEmpty()
    ) {
      const defaultConferencingOption = getDefaultUserConferencing({
        user: this.getUser(),
      });
      let updatedConferencing;

      if (defaultConferencingOption === BACKEND_OUTLOOK_CONFERENCING) {
        updatedConferencing = eventFormConferenceOptionFromBackend(
          getCalendarDefaultMeetingProvider(this.state.calendar),
          this.getUser()
        );
      } else {
        updatedConferencing = eventFormConferenceOptionFromBackend(
          defaultConferencingOption,
          this.getUser()
        );
      }

      if (isValidConferencing(this.getUser())) {
        this.setConference({
          option: updatedConferencing,
        });
      }
    }
  }

  setEventStartDate(newStartDate, notifyChange = false) {
    // newStartDate comes in as jsDate
    if (notifyChange) {
      this.setComponentHasChangedNotification(START_DATE);
    }

    let {
      dateDifferenceBetweenEventStartEnd,
      eventStartTime,
      eventEndTime,
      startTimeZone,
      endTimeZone,
    } = this.state;

    let temporaryEndDate = addDays(
      newStartDate,
      this.state.dateDifferenceBetweenEventStartEnd
    );
    let addOnDays = IsEventStartAfterEventEnd({
      startDate: newStartDate,
      endDate: temporaryEndDate,
      startTime: eventStartTime,
      endTime: eventEndTime,
      startTimeZone,
      endTimeZone,
      currentTimeZone: this.props.currentTimeZone,
    })
      ? 1
      : 0;

    let newEndDate = addDays(
      newStartDate,
      dateDifferenceBetweenEventStartEnd + addOnDays
    );

    this.setState(
      {
        eventStartDate: newStartDate,
        eventEndDate: newEndDate,
      },
      () => {
        if (
          convertDateIntoEpochUnixWeek(
            getFirstDayOfWeekJsDate(
              this.props.selectedDay,
              this.props.weekStart
            )
          ) !==
            convertDateIntoEpochUnixWeek(
              getFirstDayOfWeekJsDate(
                this.state.eventStartDate,
                this.props.weekStart
              )
            ) ||
          this.isInDayView()
        ) {
          // Only change selectedDay if it's a different move
          this.props.selectDay(this.state.eventStartDate);
          Broadcast.publish(
            "UPDATE_MONTHLY_CALENDAR_START_OF_MONTH",
            this.state.eventStartDate
          );
        }

        this.updatedAvailableRoomsAndUpdateTemporaryEvent();
      }
    );
  }

  setEventEndDate(newEndDate, notifyChange = false) {
    // newEndDate comes in as JsDate
    if (notifyChange) {
      this.setComponentHasChangedNotification(END_DATE);
    }

    let { eventStartDate } = this.state;

    let updatedState = { eventEndDate: newEndDate };

    if (isSameOrBeforeDay(eventStartDate, newEndDate)) {
      updatedState["dateDifferenceBetweenEventStartEnd"] = differenceInDays(
        startOfDay(newEndDate),
        startOfDay(eventStartDate)
      );
    }

    this.setState(
      updatedState,
      this.updatedAvailableRoomsAndUpdateTemporaryEvent
    );
  }

  /**
   * @param {Object} options
   * @param {Date=} options.startTime
   * @param {Date=} options.endTime
   */
  setEventDateAndTime({ startTime, endTime }) {
    const updatedState = {};
    if (startTime) {
      updatedState.eventStartDate = startOfDay(startTime);
      updatedState.eventStartTime = startTime;
    }
    if (endTime) {
      updatedState.eventEndDate = startOfDay(endTime);
      updatedState.eventEndTime = endTime;
    }
    if (startTime && endTime) {
      updatedState.timeDifferenceBetweenEventStartEnd = differenceInMinutes(endTime, startTime);
    }
    if (!isEmptyObjectOrFalsey(updatedState)) {
      this.setState(updatedState, this.updatedAvailableRoomsAndUpdateTemporaryEvent);
    }
  }

  getDifferenceInDaysBetweenEventStartAndEnd() {
    return differenceInDays(this.getCombinedEventEndDateAndTime(), this.getCombinedEventStartDateAndTime());
  }

  getCombinedEventStartDateAndTime() {
    const { eventStartDate, eventStartTime } = this.state;
    return CombineDateAndTimeJSDate(eventStartDate, eventStartTime);
  }

  getCombinedEventEndDateAndTime() {
    const { eventEndDate, eventEndTime } = this.state;
    return CombineDateAndTimeJSDate(eventEndDate, eventEndTime);
  }

  setEventStartTime(time, notifyChange = false, endTime = null) {
    if (notifyChange) {
      this.setComponentHasChangedNotification(START_TIME);
    }

    let {
      timeDifferenceBetweenEventStartEnd,
      endTimeZone,
      eventStartDate,
      startTimeZone,
    } = this.state;

    let startDateTime = this.getCombinedEventStartDateAndTime();

    let updatedStartTime = startOfMinute(
      set(startDateTime, { hours: time.getHours(), minutes: time.getMinutes() })
    );

    let updatedEndTime = addMinutes(
      getTimeInAnchorTimeZone(
        updatedStartTime,
        startTimeZone,
        this.state.endTimeZone || this.props.currentTimeZone
      ),
      timeDifferenceBetweenEventStartEnd
    );

    let updatedEndDate = startOfDay(updatedEndTime);

    let updatedState = { eventStartTime: updatedStartTime };

    if (isValidJSDate(endTime)) {
      updatedState.eventEndTime = endTime;
      const updatedEndDateFromDuration = eventStartDate;
      if (
        IsEventStartAfterEventEnd({
          startDate: eventStartDate,
          endDate: updatedEndDateFromDuration,
          startTime: updatedStartTime,
          endTime: endTime,
          startTimeZone,
          endTimeZone,
          currentTimeZone: this.props.currentTimeZone,
        })
      ) {
        updatedState.eventEndDate = addDays(eventStartDate, 1);
      } else {
        updatedState.eventEndDate = updatedEndDateFromDuration;
      }
    } else if (
      IsEventStartAfterEventEnd({
        startDate: eventStartDate,
        endDate: updatedEndDate,
        startTime: updatedStartTime,
        endTime: updatedEndTime,
        startTimeZone,
        endTimeZone,
        currentTimeZone: this.props.currentTimeZone,
      })
    ) {
      updatedState.eventEndTime = updatedEndTime;
      updatedState.eventEndDate = addDays(updatedEndDate, 1);
    } else {
      updatedState.eventEndTime = updatedEndTime;
      updatedState.eventEndDate = updatedEndDate;
    }

    this.setState(updatedState, () => {
      this.updatedAvailableRoomsAndUpdateTemporaryEvent();

      if (!this.checkMode(TEMPLATE) && !this.checkMode(UPDATE_TEMPLATE)) {
        this.scrollToTime(this.state.eventStartTime);
      }
    });
  }

  scrollToTime(time) {
    let scrollToHour = determineScrollToHour(time);

    Broadcast.publish(`SCROLL_TO_HOUR_${scrollToHour}`, false);
  }

  getEventDuration() {
    const eventStart = this.getCombinedEventStartDateAndTime();
    const getEventEnd = this.getCombinedEventEndDateAndTime();
    return differenceInMinutes(getEventEnd, eventStart);
  }

  getEventStartAndEnd() {
    const { allDay, eventEndDate, eventStartDate, endTimeZone, startTimeZone } =
      this.state;
    const { currentTimeZone } = this.props;

    const temporaryStart = this.getCombinedEventStartDateAndTime();
    const temporaryEnd = this.getCombinedEventEndDateAndTime();

    const startDiffMinutes = minDifferentBetweenTimeZones(
      currentTimeZone,
      startTimeZone,
      temporaryStart
    );

    const endDiffMinutes = minDifferentBetweenTimeZones(
      currentTimeZone,
      endTimeZone,
      temporaryEnd
    );

    const eventStart = !this.isEqualToCurrentTimeZone(startTimeZone)
      ? addMinutes(temporaryStart, startDiffMinutes)
      : temporaryStart;

    const eventEnd = this.createTimeForTemporaryEvent({
      allDay: allDay,
      startDate: eventStartDate,
      endDate: eventEndDate,
      timeZone: endTimeZone,
      time: temporaryEnd,
      minsDiff: endDiffMinutes,
    });

    return { eventStart, eventEnd };
  }

  setTemporaryEvents(removeOriginalEventId) {
    if (!this._isMounted) {
      return;
    }
    if (this.isTemplate()) {
      // no need to set if template
      return;
    }

    const { allDay, calendar, isDuplicate, originalEvent, summary } =
      this.state;

    const { temporaryEvents } = this.props;

    const shouldSetEvent =
      temporaryEvents ||
      this.isCreateNew() ||
      this.isUpdateEvent();

    if (!shouldSetEvent) {
      return;
    }

    const userEventId =
      originalEvent && !isDuplicate ? getEventUserEventID(originalEvent) : null;
    const { eventStart, eventEnd } = this.getEventStartAndEnd();

    const endsAfterStart = allDay
      ? isSameOrAfterDay(eventEnd, eventStart)
      : isSameOrAfterMinute(eventEnd, eventStart);

    if (!endsAfterStart) {
      return;
    }

    const calendarId = getCalendarUserCalendarID(calendar);
    const rbcEventEnd = determineRBCEventEndWithEventStart(
      eventStart,
      eventEnd
    );

    const updatedEvent = createEventFormTemporaryEvent({
      displayAsAllDay: !!allDay || DaysDifferenceJSDate(eventStart, eventEnd),
      isTemporary: true,
      backgroundColor: this.getBackgroundColor(),
      defaultStartTime: formatISO(eventStart),
      defaultEndTime: formatISO(eventEnd),
      eventStart,
      eventEnd,
      rbcEventEnd,
      status: temporary,
      raw_json: { status: temporary },
      summaryUpdatedWithVisibility: summary === "" ? "(No title)" : summary,
      calendarId,
      user_event_id: userEventId,
      resourceId:
        getResourceID({
          currentUserEmail: getUserEmail(this.getUser()),
          eventCalendarEmail: this.getCalendarEmail(),
        }) ?? getCalendarProviderId(calendar),
      provider: this.isOutlookCalendar()
        ? CALENDAR_PROVIDERS.OUTLOOK
        : CALENDAR_PROVIDERS.GOOGLE,
      id: temporaryEvents?.[0]?.id || createUUID(),
    });

    const isSameAsOriginalEvent =
      userEventId && removeOriginalEventId === userEventId;
    const shouldRemoveEvent = removeOriginalEventId && isSameAsOriginalEvent;
    if (shouldRemoveEvent) {
      mainCalendarBroadcast.publish(
        MAIN_CALENDAR_BROADCAST_VALUES.REMOVE_EVENT_FROM_WEEKLY_CALENDAR,
        {
          deleteEvent: originalEvent,
          addTemporaryEvent: updatedEvent,
        }
      );
      return;
    }
    this.props.setTemporaryEvent([updatedEvent]);
  }

  getBackgroundColor() {
    const allTags = this.getAllTags();
    const firstTag = allTags[0];
    const firstTagColor = getTagColor(firstTag);
    const isFirstTagClearTag = isTransparentTag(firstTag);
    if (isPriorityTag(firstTag) && !isFirstTagClearTag) {
      // if priority tag is clear -> skip it.
      return firstTagColor;
    }
    const { categories, eventColorId } = this.state;

    if (this.isOutlookCalendar() && !isEmptyArrayOrFalsey(categories)) {
      const { outlookCategories } = this.props.outlookCategoriesStore;
      const color = outlookCategories[this.getCalendarEmail()]?.find(
        (c) => c.displayName === categories[0]
      )?.color;
      if (color in OUTLOOK_COLORS) {
        return OUTLOOK_COLORS[color].hex;
      }
    }

    const calendarEmailColor = this.determineEmailColor();
    const colorList = createColorsListWithDefault(calendarEmailColor);
    if (
      (!eventColorId || parseInt(eventColorId) === 0) &&
      firstTagColor &&
      !isFirstTagClearTag
    ) {
      // if there's no event color id and there's valid tag color -> use tag color
      return firstTagColor;
    }

    // if the eventColorID is vaid and not the default color
    const backgroundColor =
      eventColorId && colorList[eventColorId] && parseInt(eventColorId) !== 0
        ? colorList[eventColorId].color
        : calendarEmailColor;
    return backgroundColor;
  }

  createTimeForTemporaryEvent(param) {
    const { allDay, startDate, endDate, timeZone, time, minsDiff } = param;

    if (this.isOutlookCalendar() && allDay) {
      if (!isSameDay(startDate, endDate)) {
        return startOfDay(addDays(endDate, 1));
      }
      return startOfDay(endDate);
    } else if (allDay && !isSameDay(startDate, endDate)) {
      return startOfDay(addDays(endDate, 1));
    } else {
      return !this.isEqualToCurrentTimeZone(timeZone)
        ? addMinutes(time, minsDiff)
        : time;
    }
  }

  changedFromAllDayToNonAllDay() {
    // all day changed from all day to non-all day
    return this.state.originalState?.allDay && !this.state.allDay;
  }

  updateStartAndEndDateForRecurringAll({
    eventStart,
    eventEnd,
    originalRecurringEventInstance,
  }) {
    // event start here should look:
    // {
    //   "dateTime": "2024-04-02T14:00:00.000Z",
    //   "timeZone": "America/New_York"
    // }
    const { currentTimeZone } = this.props;

    const originalStartString = getEventStartValue(
      originalRecurringEventInstance
    );
    const originalEndString = getEventEndValue(originalRecurringEventInstance);

    const isAllDayEvent = this.state.allDay;

    // if it changed from non all day to all day
    if (this.changedFromAllDayToNonAllDay() && originalRecurringEventInstance) {
      const getEventStart = () => {
        if (isAllDayOutlookEvent(originalRecurringEventInstance)) {
          const { eventStart: originalStart } = parseOutlookAllDayEvent(
            originalRecurringEventInstance
          );
          return originalStart;
        }
        return parseISO(originalStartString);
      };

      const newStart = parseISO(eventStart.dateTime);
      const updatedStart = set(getEventStart(), {
        hours: newStart.getHours(),
        minutes: newStart.getMinutes(),
      });
      const startTimeZone = eventStart.timeZone || currentTimeZone;
      const minuteDiffrence = differenceInMinutes(
        this.getCombinedEventEndDateAndTime(),
        this.getCombinedEventStartDateAndTime()
      );
      const start = {
        dateTime: updatedStart.toISOString(),
        date: null, // need to pass date null if changing all day to non-all day
        timeZone: startTimeZone,
      };
      const end = {
        dateTime: addMinutes(updatedStart, minuteDiffrence).toISOString(),
        date: null, // need to pass date null if changing all day to non-all day
        timeZone: startTimeZone,
      };
      return {
        start,
        end,
      };
    }

    // All day
    if (isAllDayEvent) {
      const start = {
        dateTime: null,
        date: formatISO(parseISO(originalStartString), ISO_DATE_FORMAT),
      };

      const end = {
        dateTime: null,
        date: formatISO(parseISO(originalEndString), ISO_DATE_FORMAT),
      };
      return { start, end };
    }

    if (eventStart.timeZone || eventEnd.timeZone) {
      let updatedStart = parseISO(originalStartString);
      let updatedEnd = parseISO(originalEndString);

      const startTimeZone = eventStart.timeZone || currentTimeZone;
      const endTimeZone = eventEnd.timeZone || currentTimeZone;

      const newStart = parseISO(eventStart.dateTime);
      const newEnd = parseISO(eventEnd.dateTime);

      updatedStart = set(updatedStart, {
        hours: newStart.getHours(),
        minutes: newStart.getMinutes(),
      });
      updatedEnd = set(updatedEnd, {
        hours: newEnd.getHours(),
        minutes: newEnd.getMinutes(),
      });

      const start = {
        dateTime: updatedStart.toISOString(),
        date: null,
        timeZone: startTimeZone,
      };

      const end = {
        dateTime: updatedEnd.toISOString(),
        date: null,
        timeZone: endTimeZone,
      };
      return { start, end };
    }

    // no time zone set
    let updatedStart = parseISO(originalStartString);
    let updatedEnd = parseISO(originalEndString);

    let newStart = parseISO(eventStart.dateTime);

    let newEnd = parseISO(eventEnd.dateTime);

    updatedStart = set(updatedStart, {
      hours: newStart.getHours(),
      minutes: newStart.getMinutes(),
    });
    updatedEnd = set(updatedEnd, {
      hours: newEnd.getHours(),
      minutes: newEnd.getMinutes(),
    });

    const start = {
      dateTime: updatedStart.toISOString(),
      date: null,
    };

    const end = {
      dateTime: updatedEnd.toISOString(),
      date: null,
    };
    return { start, end };
  }

  isEqualToCurrentTimeZone(timeZone) {
    return !timeZone || this.props.currentTimeZone === timeZone;
  }

  setGuestPermissions(option) {
    if (option.includes(modifyEventsString)) {
      this.setState({
        guestPermissions: [
          modifyEventsString,
          inviteOthersString,
          seeGuestListString,
        ],
      });
    } else {
      this.setState({
        guestPermissions: option,
      });
    }

    this.toggleDisplayModalState("shouldDisplayModal");
  }

  setRepeat(repeat) {
    this.setState({
      repeatText: repeat.ruleText,
      recurrenceRules: repeat.rRule,
      lastTimeSet: new Date(),
      recurringHasChanged: this.state.repeatText !== repeat.ruleText,
    });

    this.closeModal();
  }

  setEventEndTime(time, notifyChange = false) {
    let {
      eventStartTime,
      eventStartDate,
      eventEndDate,
      startTimeZone,
      endTimeZone,
    } = this.state;
    const { currentTimeZone } = this.props;

    if (notifyChange) {
      this.setComponentHasChangedNotification(END_TIME);
    }

    let updatedEndTime = startOfMinute(
      set(new Date(), { hours: time.getHours(), minutes: time.getMinutes() })
    );

    let updatedState = { eventEndTime: updatedEndTime };
    let shouldUpdateRoomAndTemporaryEvent = false;

    if (
      !IsEventStartAfterEventEnd({
        startDate: eventStartDate,
        endDate: eventEndDate,
        startTime: eventStartTime,
        endTime: updatedEndTime,
        startTimeZone: startTimeZone,
        endTimeZone: endTimeZone,
        currentTimeZone,
      })
    ) {
      updatedState.timeDifferenceBetweenEventStartEnd = differenceInMinutes(
        getTimeInAnchorTimeZone(
          CombineDateAndTimeJSDate(eventEndDate, updatedEndTime),
          this.state.endTimeZone
        ),
        getTimeInAnchorTimeZone(
          CombineDateAndTimeJSDate(eventStartDate, eventStartTime),
          this.state.startTimeZone
        )
      );
      shouldUpdateRoomAndTemporaryEvent = true;
    }

    this.setState(updatedState, () => {
      if (shouldUpdateRoomAndTemporaryEvent) {
        this.updatedAvailableRoomsAndUpdateTemporaryEvent();
      }
    });
  }

  updateOriginalEvent(updatedEvent) {
    if (
      updatedEvent &&
      this.state.originalEvent &&
      getEventUserEventID(updatedEvent) ===
        getEventUserEventID(this.state.originalEvent)
    ) {
      this.setState({ originalEvent: updatedEvent });
    }
  }

  updateAttendeeQuery(text) {
    this.setState({ attendeeQueryText: text });
  }

  setAdditionalReminder(reminders) {
    if (this.state.allDay) {
      //set to 00:00
      let startTime = startOfDay(new Date(this.state.eventStartTime.getTime()));

      let notificationTime = new Date(startTime.getTime());
      if (reminders[2] === WEEKS_STRING) {
        notificationTime = subWeeks(notificationTime, reminders[1]);
      } else {
        notificationTime = subDays(notificationTime, reminders[1]);
      }

      let parsedDate = parse(
        reminders[3],
        DATE_TIME_12_HOUR_FORMAT,
        this.state.eventStartTime
      );
      let hours = parsedDate.getHours();
      let minutes = parsedDate.getMinutes();

      notificationTime = set(notificationTime, {
        hours,
        minutes,
      });

      let differenceBetweenInMinutes = differenceInMinutes(
        startTime,
        notificationTime
      );

      let newReminder = {
        method: reminders[0],
        minutes: differenceBetweenInMinutes,
      };

      this.setState({
        allDayReminders: {
          useDefault: false,
          overrides:
            this.state.allDayReminders &&
            this.state.allDayReminders.overrides &&
            this.state.allDayReminders.overrides.length >= 0
              ? this.state.allDayReminders.overrides.concat(newReminder)
              : [newReminder],
        },
      });
    } else {
      // Not all day reminders
      if (this.state.reminders.useDefault) {
        let defaultReminder = [];

        const currentUserCalendar = this.getCalendarFromEmail(this.getUserEmail());
        const currentUserDefaultReminders =
          getCalendarDefaultReminders(currentUserCalendar);

        if (
          currentUserDefaultReminders?.length > 0 &&
          getCalendarUserCalendarID(currentUserCalendar) ===
            getCalendarUserCalendarID(this.state.calendar)
        ) {
          defaultReminder = currentUserDefaultReminders;
        }

        let updatedReminders = defaultReminder.concat(reminders);

        this.setState({
          reminders: { useDefault: false, overrides: updatedReminders },
        });
      } else {
        let updatedRemindersOverrides =
          this.state.reminders &&
          this.state.reminders.overrides &&
          this.state.reminders.overrides.length >= 0
            ? this.state.reminders.overrides
            : [];

        updatedRemindersOverrides = updatedRemindersOverrides.concat(reminders);

        this.setState({
          reminders: {
            useDefault: false,
            overrides: updatedRemindersOverrides,
          },
        });
      }
    }

    this.toggleDisplayModalState("shouldDisplayModal");
  }

  shouldUpdateConferencing() {
    // shouldSaveEverything is for when you need to save every field into the backend for patch
    return (
      this.props.shouldSaveEverything ||
      this.state.updatedToPersonalZoomLink ||
      this.hasConferencingChanged() ||
      this.state.createdEventWithExistingAttendees ||
      this.state.wasZoomUpdated
    );
  }

  hasConferencingChanged() {
    const { originalState, conference, zoomMeeting } = this.state;
    if (
      originalState?.originalEvent &&
      getEventConferenceData(originalState?.originalEvent) &&
      isConferenceDataZoom(
        getEventConferenceData(originalState?.originalEvent)
      ) &&
      zoomMeeting
    ) {
      const existingEventConferenceURL = getConferenceURLFromNative(
        getEventConferenceData(originalState?.originalEvent)
      );
      if (getZoomJoinURL(zoomMeeting) !== existingEventConferenceURL) {
        // e.g. if we update the zoom link
        return true;
      }
    }
    return originalState && conference !== originalState.conference;
  }

  isZoomConferencingAndDoesNotHaveLink() {
    const { zoomMeeting } = this.state;
    const defaultToZoomPersonalLink = this.isDefaultZoomPersonalLink();

    if (
      this.state.conference === zoomString &&
      defaultToZoomPersonalLink &&
      !this.getZoomPersonalLink()
    ) {
      // no personal link
      return;
    }

    if (
      !(
        this.state.conference === zoomString &&
        !defaultToZoomPersonalLink &&
        this.shouldUpdateConferencing()
      )
    ) {
      return;
    }
    if (zoomMeeting) {
      // if true => early return.
      return;
    }

    // if it's copy to different calendar and there's arl
    if (
      this.isCopyToCalendarEvent() &&
      this.state.originalEventConferenceData
    ) {
      // we're simply copying the zoom over
      return !isZoomFromGSuiteIntegration({
        conference_data: this.state.originalEventConferenceData,
      });
    }
    return !zoomMeeting;
  }

  isEmojiKeywordInDomain(key) {
    // this.propsdomain -> {id: 1, name: "vimcal.com"}
    // tests if emoji keyword is in the user domain
    if (!this.getCurrentCalendarDomainInfo()?.name || !key) {
      return false;
    }

    let domainName = this.getCurrentCalendarDomainInfo().name.toLowerCase();

    return domainName.includes(key.toLowerCase());
  }

  hasOriginalRecurrenceEvent() {
    return isValidEvent(this.state.originalRecurringEventInstance);
  }

  showUniqueZoomIsStillLoadingError() {
    const conferenceElement = document.getElementById(CONFERENCE_SECTION);
    if (conferenceElement?.getBoundingClientRect().y < 20) {
      conferenceElement.scrollIntoView();
    }

    this.setModalContent(
      "Please wait",
      340,
      ZOOM_STILL_LOADING_MODAL,
      CONFERENCE_SECTION
    );
  }

  closeModalAndShowZoomFailedMessage() {
    this.closeModal();

    const conferenceElement = document.getElementById(CONFERENCE_SECTION);
    if (conferenceElement?.getBoundingClientRect().y < 20) {
      conferenceElement.scrollIntoView();
    }

    this.setModalContent("Oops!", 400, ZOOM_ERROR, CONFERENCE_SECTION);
  }

  closeModalAndShowMessage() {
    this.closeModal();

    Broadcast.publish("SHOW_ERROR_MESSAGE");
  }

  createRefForSuggestion(suggestions) {
    if (!suggestions || suggestions.length === 0) {
      return {};
    }

    let refs = suggestions.reduce((result, option) => {
      result[option.key] = createRef();

      return result;
    }, {});

    return refs;
  }

  onMouseEnterSuggestion(suggestion) {
    let resetSuggestions = makeAllSuggestionsInactive(this.state.suggestions);

    let updatedSuggestions = [];

    resetSuggestions.forEach((s) => {
      if (s.index === suggestion.index) {
        let updatedElement = s;
        updatedElement.active = true;

        updatedSuggestions = updatedSuggestions.concat(updatedElement);
      } else {
        updatedSuggestions = updatedSuggestions.concat(s);
      }
    });

    this.setState({
      hoverOverSuggestion: true,
      suggestions: updatedSuggestions,
      refs: this.createRefForSuggestion(updatedSuggestions),
    });
  }

  onMouseLeaveSuggestion() {
    this.setState({ hoverOverSuggestion: false });
  }

  handleSelectTemplate(suggestion) {
    if (isEmptyObjectOrFalsey(suggestion)) {
      // reset if suggestion is empty object
      this.setState({
        suggestions: [],
        refs: {},
        knownTemplate: null,
      });
      return;
    }
    const { template } = suggestion;

    Broadcast.publish(
      SET_DISAPPEARING_NOTIFICATION_MESSAGE,
      "Event template applied"
    );
    Broadcast.publish("EVENT_FORM_APPLY_TEMPLATE", {
      json: template,
      shouldApplyTitle: true,
    });

    // Update last used template
    const path = `templates/${template.id}/used`;
    const url = constructRequestURLV2(path);

    Broadcast.publish("UPDATE_LAST_USED_TEMPLATE", { url });

    const templateTitle = getTemplateTitle(template);
    this.setState({
      suggestions: [],
      refs: {},
      knownTemplate: templateTitle ? lowerCaseAndTrimString(templateTitle) : "",
    });
  }

  createTimeIntervals(interval) {
    let { eventStartTime } = this.state;

    let dateTime = format(eventStartTime, "Pp");

    if (this._staticTimeList && this._staticTimeList[dateTime]) {
      return this._staticTimeList[dateTime];
    }

    let end = add(eventStartTime, { hours: 23, minutes: 30 });
    // round starting minutes up to nearest 15 (12 --> 15, 17 --> 30)
    // note that 59 will round up to 60
    let start = setMinutes(
      eventStartTime,
      Math.ceil(eventStartTime.getMinutes() / interval) * interval
    );

    let result = [];

    // https://stackoverflow.com/questions/1090815/how-to-clone-a-date-object
    let current = new Date(start.getTime()); // clone

    while (isBeforeMinute(current, end) || isSameMinute(current, end)) {
      result = result.concat(format(current, DATE_TIME_12_HOUR_FORMAT));
      current = addMinutes(current, interval);
    }

    if (!this._staticTimeList) {
      this._staticTimeList = { [dateTime]: result };
    } else {
      this._staticTimeList[dateTime] = result;
    }

    return result;
  }

  isDefaultZoomPersonalLinkAndMissingLink() {
    return (
      this.isDefaultZoomPersonalLink() && !this.getZoomPersonalLink()
    );
  }

  isDefaultZoomUniqueAndMissingLink() {
    return (
      !this.isDefaultZoomPersonalLink() &&
      !this.getUser().has_zoom_access &&
      !this.isUserBeingScheduledFor()
    );
  }

  determineDateAndTimeSeparatorStyle() {
    if (this.shouldDisplayDifferentTimeZoneWarning()) {
      return { marginTop: "15px" };
    } else if (this.state.isOnboarding) {
      return { marginTop: 0 };
    } else {
      return {};
    }
  }

  hasChangedOriginalEventStartDate() {
    // on edit event and change start date -> do not show all option
    if (!this.state.originalState?.eventStartDate) {
      return false;
    }

    return (
      getISODay(this.state.originalState.eventStartDate) !==
      getISODay(this.state.eventStartDate)
    );
  }

  getZoomPersonalLink() {
    const { masterAccount } = this.props.masterAccount;
    return getZoomPersonalLink({
      user: this.getUser(),
      schedulers: this.getZoomScheduler(),
      masterAccount,
    });
  }

  isOutlookCalendar() {
    return isCalendarOutlookCalendar(this.state.calendar);
  }

  setOutlookConferencing({
    newConferencing,
    lastUsedConferencing,
    hasRemovedUniqueZoomDescription = false, // gets deleted in setConference::stripOutZoomFromLocationAndDescription
  }) {
    const getConferencingURL = (conferenceData) => {
      return conferenceData?.entryPoints[0]?.uri;
    };
    const { masterAccount } = this.props.masterAccount;
    const { zoomMeeting } = this.state;

    if (newConferencing === ZOOM && lastUsedConferencing === ZOOM) {
      // Zoom meeting changes, the URL may change as well and the description needs to
      // be updated.
    } else if (
      !hasRemovedUniqueZoomDescription &&
      (newConferencing === ZOOM || lastUsedConferencing === ZOOM)
    ) {
      // ignore since we need
      const isZoomPersonalLink = this.isDefaultZoomPersonalLink();
      if (isZoomPersonalLink) {
        const zoomLink = this.getZoomPersonalLink();
        if (!zoomLink) {
          return;
        }
        const personalLinkText = createOutlookPersonalLinkDescription(zoomLink);

        lastUsedConferencing === ZOOM
          ? this.removeConferencingFromLocationAndDescription({
              conferenceURL: zoomLink,
              descriptionText: personalLinkText,
              lastUsedConferencing: ZOOM,
            })
          : this.addConferencingURLToLocationAddDescription({
              conferenceURL: zoomLink,
              descriptionText: personalLinkText,
            });
      } else if (zoomMeeting) {
        const { join_url } = zoomMeeting;

        if (lastUsedConferencing === ZOOM) {
          this.removeConferencingFromLocationAndDescription({
            conferenceURL: join_url,
            descriptionText: createOutlookUniqueZoomDescription(zoomMeeting),
            lastUsedConferencing: ZOOM,
          });
        }

        // If a Zoom meeting doesn't exist yet, the conference info will not be set here.
        // addUniqueZoomLinkToDescriptionAndLocation will be called once the meeting is created.
        if (newConferencing === ZOOM) {
          this.addConferencingURLToLocationAddDescription({
            conferenceURL: join_url,
            descriptionText: createOutlookUniqueZoomDescription(zoomMeeting),
          });
        }
      }
    } else if (newConferencing === PHONE || lastUsedConferencing === PHONE) {
      const user = this.getUser();
      const phoneData = createPhoneConferenceData({
        currentUser: user,
        masterAccount,
      });

      const descriptionText = phoneData.notes ?? "";
      lastUsedConferencing === PHONE
        ? this.removeConferencingFromLocationAndDescription({
            conferenceURL: getConferencingURL(phoneData),
            descriptionText,
            isPhone: true,
            lastUsedConferencing: PHONE,
          })
        : this.addConferencingURLToLocationAddDescription({
            conferenceURL: getConferencingURL(phoneData),
            descriptionText,
          });
    } else if (
      newConferencing === WHATS_APP_STRING ||
      lastUsedConferencing === WHATS_APP_STRING
    ) {
      const whatsAppData = createWhatsAppConferenceData({
        currentUser: this.getUser(),
        masterAccount,
      });
      const descriptionText = whatsAppData.notes ?? "";

      lastUsedConferencing === WHATS_APP_STRING
        ? this.removeConferencingFromLocationAndDescription({
            conferenceURL: getConferencingURL(whatsAppData),
            descriptionText,
            isPhone: true,
            lastUsedConferencing: WHATS_APP_STRING,
          })
        : this.addConferencingURLToLocationAddDescription({
            conferenceURL: getConferencingURL(whatsAppData),
            descriptionText,
          });
    } else if (
      newConferencing === getCustomConferencingName({ user: this.getUser() }) ||
      lastUsedConferencing ===
        getCustomConferencingName({ user: this.getUser() })
    ) {
      const customConferencing = createCustomConferencing(this.getUser());

      lastUsedConferencing ===
      getCustomConferencingName({ user: this.getUser() })
        ? this.removeConferencingFromLocationAndDescription({
            conferenceURL: getConferencingURL(customConferencing),
            descriptionText: `Join ${getCustomConferencingName({
              user: this.getUser(),
            })}\n\n${getConferencingURL(customConferencing)}`,
            lastUsedConferencing: getCustomConferencingName({
              user: this.getUser(),
            }),
          })
        : this.addConferencingURLToLocationAddDescription({
            conferenceURL: getConferencingURL(customConferencing),
            descriptionText: `Join ${getCustomConferencingName({
              user: this.getUser(),
            })}\n\n${getConferencingURL(customConferencing)}`,
          });
    }
  }

  removeConferencingFromLocationAndDescription({
    conferenceURL,
    lastUsedConferencing,
  }) {
    try {
      if (
        this.state.location === conferenceURL ||
        (lastUsedConferencing === ZOOM &&
          stringIncludesZoomURLCombinations(this.state.location))
      ) {
        // need to rerender for changes to get picked up in location
        this.setState({ location: "" }, () => {
          this.setState({ rerenderCount: this.state.rerenderCount + 1 });
        });
      }

      let currentDescription = this.getDescriptionContent();
      if (lastUsedConferencing === ZOOM) {
        const zoomPatterns = [
          /<div>Join Zoom Meeting<\/div>(<div><br><\/div>)*<div><a href="https:\/\/[^"]+\.zoom\.us\/[^"]+"[^>]*>[^<]+<\/a><\/div>(<div><br><\/div>)*<div>Meeting ID: [\d\s]+<\/div>(<div><br><\/div>)*<div>Password: [^\s]+<\/div>(<div><br><\/div>)*(<div>One tap mobile<\/div>(<div>[^<]+<\/div>)+)?(<div><br><\/div>)*(<div>Dial by location<\/div>(<div>[^<]+<\/div>)+)?(<div><br><\/div>)*(<div>Meeting ID: [\d\s]+<\/div>(<div><br><\/div>)*<div>Find your local number: <a href="https:\/\/[^"]+\.zoom\.us\/[^"]+"[^>]*>[^<]+<\/a><\/div>(<div><br><\/div>)*)?/gi, // picks up company.zoom.us
          /<div>Meeting ID: [\d\s]+<\/div>(<div><br><\/div>)*<div>Password: [^\s]+<\/div>(<div><br><\/div>)*/gi, // meeting id pickups digit sand spaces, password picks up anything not white space
          /<div>One tap mobile<\/div>(<div>[^<]+<\/div>)+/gi, // This pattern captures the "One tap mobile" section and its subsequent lines.
          /<div>Dial by location<\/div>(<div>[^<]+<\/div>)+/gi, // This pattern captures the "Dial by location" section and its subsequent lines.
          /<div>Find your local number: <a href="https:\/\/[^"]+\.zoom\.us\/[^"]+"[^>]*>[^<]+<\/a><\/div>(<div><br><\/div>)*/gi, // This pattern captures the "Find your local number" line with a Zoom subdomain link.
        ];

        // Remove each pattern found in the description
        zoomPatterns.forEach((pattern) => {
          currentDescription = currentDescription.replace(pattern, "");
        });

        // Remove any extra <div><br></div> pairs that might be left behind
        currentDescription = currentDescription.replace(
          /(<div><br><\/div>)+/gi,
          "<div><br></div>"
        );

        // Update the editor contents with the cleaned description
        this.description.setEditorContents(
          this.description.getEditor(),
          currentDescription
        );
        if (
          currentDescription.includes(ZOOM_CONFERENCING) &&
          this.state.conference !== ZOOM
        ) {
          this.setState({ warningToDeleteOutlookZoomDescription: true });
        }
      } else if (lastUsedConferencing === WHATS_APP_STRING) {
        // Define regex pattern to match WhatsApp call information
        const whatsappPattern =
          /<div>Call .*? on WhatsApp \(tel:[^\)]+\)<\/div>(<div><br><\/div>)*/gi;

        // Remove the WhatsApp call information
        currentDescription = currentDescription.replace(whatsappPattern, "");

        // Remove any extra <div><br></div> pairs that might be left behind
        currentDescription = currentDescription.replace(
          /(<div><br><\/div>)+/gi,
          "<div><br></div>"
        );

        // Update the editor contents with the cleaned description
        this.description.setEditorContents(
          this.description.getEditor(),
          currentDescription
        );
      }
    } catch (error) {
      handleError(error);
    }
  }

  addConferencingURLToLocationAddDescription({
    conferenceURL,
    descriptionText,
  }) {
    if (!this.description) {
      return;
    }

    this.setState({ hasPressedAddDescription: true }, () => {
      this.description
        .getEditor()
        .insertText(0, descriptionText + "\n\n\n", true);
    });

    if (!this.state.location && conferenceURL) {
      // need to rerender for changes to get picked up in location
      this.setState({ location: conferenceURL }, () => {
        this.setState({ rerenderCount: this.state.rerenderCount + 1 });
      });
    }
  }

  addPersonalZoomLinkToDescriptionAndLocation() {
    const zoomLink = this.getZoomPersonalLink();
    if (!zoomLink) {
      return;
    }
    const personalLinkText = createOutlookPersonalLinkDescription(zoomLink);

    this.addConferencingURLToLocationAddDescription({
      conferenceURL: zoomLink,
      descriptionText: personalLinkText,
    });
  }

  addUniqueZoomLinkToDescriptionAndLocation(zoomData) {
    if (isEmptyObjectOrFalsey(zoomData) || zoomData.error) {
      return;
    }

    const { join_url } = zoomData;
    // below is logci to spell out zoom into a long string and add it into description
    const zoomInDescription = createOutlookUniqueZoomDescription(zoomData);
    this.addConferencingURLToLocationAddDescription({
      conferenceURL: join_url,
      descriptionText: zoomInDescription,
    });
  }

  getConferencingSelectOptions() {
    // need to save to state so there's reference otherwise up/down keys will not work
    const { conferencingOptions, calendar, originalState, conference } =
      this.state;

    let optionsList;
    if (!this.isOutlookCalendar()) {
      if (getGoogleCalendarAllowedConferenceType(calendar)) {
        // hide google hangout if there's no allowed conferencing type
        optionsList = conferencingOptions;
      } else {
        optionsList = conferencingOptions.filter(
          (c) => c.value !== googleHangoutString
        );
      }
    } else {
      const optionsWithoutGoogleMeet = conferencingOptions.filter(
        (c) => c.value !== googleHangoutString
      );
      optionsList = optionsWithoutGoogleMeet.concat(
        getOutlookConferencingReactSelectOptions(calendar)
      );
    }

    const originalEventConference = originalState?.conference;
    return optionsList.map((option) =>
      option.value === originalEventConference &&
      conference !== originalEventConference
        ? { value: option.value, label: option.label + " (restore)" }
        : option
    );
  }

  isAllDayOutlook() {
    return this.state.allDay && this.isOutlookCalendar();
  }

  openSelectCalendarModal() {
    this.setModalContent(
      "Select calendar",
      300,
      SELECT_EMAIL,
      SELECT_EMAIL,
      420
    );
  }

  createCalendarReactSelectOptions() {
    const { writableCalendars } = this.props.initialState;
    const { currentUser, emailToNameIndex } = this.props;
    const { masterAccount } = this.props.masterAccount;
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    return createCalendarReactSelectOptions({
      currentCalendar: this.state?.calendar,
      writableCalendars,
      masterAccount,
      emailToNameIndex,
      user: this.getUser(),
      currentUser,
      allLoggedInUsers,
    });
  }

  handleFocusOnTransition() {
    if (this._initializedFocus) {
      return;
    }
    const {
      masterAccount,
    } = this.props.masterAccount;
    if (this.props.lastEventFormState) {
      // restore previous state
      let updatedState = _.clone(this.props.lastEventFormState);

      updatedState.shouldDisplayModal = false;
      updatedState.updateRepeatType = null;
      updatedState.shouldSendUpdate = true;
      updatedState.keyMap = {};
      updatedState.shouldDisplayOverlay = false;

      this.setState(updatedState, () => {
        this.goToSummary();

        if (this.isCreateNew()) {
          this.setTemporaryEvents();
        } else if (
          (this.isUpdateEvent()) &&
          this.state.originalEvent
        ) {
          this.setTemporaryEvents(
            getEventUserEventID(this.state.originalEvent)
          );
        }

        this.props.setLastEventFormState(null);
      });
    } else if (this.props.isDuplicate) {
      this.goToSummary();
    } else if (getEventFormFocusSection()) {
      this.goToEventFormSection(getEventFormFocusSection());
      clearEventFormFocusSection();
    } else if (shouldDefaultFocusToTitleForEventForm({user: this.getUser(), masterAccount})) {
      // for VimcalEA users -> go to summary
      this.goToSummary();
    } else if (this.shouldDisplayNlpInput()) {
      this.goToNLPInput();
    } else {
      this.goToSummary();
    }
    this._initializedFocus = true;
  }

  addTransitionedListener() {
    // with transition, the focus doesn't actually focus until transition is done
    const panelElement = document.getElementById(EVENT_PANEL_WRAPPER_ID);
    panelElement?.addEventListener(
      "transitionend",
      this.handleFocusOnTransition
    );
  }

  removeListener() {
    const panelElement = document.getElementById(EVENT_PANEL_WRAPPER_ID);
    panelElement?.removeEventListener(
      "transitionend",
      this.handleFocusOnTransition
    );
  }

  getCalendarName(calendar) {
    const calendarToUse = calendar ?? this.state.calendar;
    if (this.isTemplate() && isEmptyObjectOrFalsey(calendarToUse)) {
      return TEMPLATE_SELECT_CALENDAR_LABEL;
    }

    const { masterAccount } = this.props.masterAccount;
    const { emailToNameIndex } = this.props;
    return getCalendarName({
      calendar: calendarToUse,
      emailToNameIndex,
      currentUser: this.getUser(),
      masterAccount,
    });
  }

  getCalendarUserCalendarID(calendar) {
    return getCalendarUserCalendarID(calendar ?? this.state.calendar);
  }

  determineAttendeeToAddress() {
    const { attendees, newlyAddedAttendees, originalEvent, originalState } =
      this.state;

    const hasEventChanged = this.hasStateChanged([
      "attendees",
      "newlyAddedAttendees",
    ]);

    const currentAttendees = attendees; // Current attendees in the new event, excluding new and removed
    const originalAttendees = getEventAttendees(originalEvent); // Original attendees in the old event

    const newAttendees = newlyAddedAttendees; // New attendees in the new event
    const removedAttendees =
      originalAttendees?.filter(
        (originalAttendee) =>
          !currentAttendees.find((currentAttendee) =>
            isSameEmail(
              getObjectEmail(currentAttendee),
              getObjectEmail(originalAttendee)
            )
          )
      ) ?? []; // This works since we don't track new attendees in current attendees

    const allAttendees = [
      ...currentAttendees,
      ...newAttendees,
      ...removedAttendees,
    ]; // All attendees including removed
    const newAndRemovedAttendees = [...newAttendees, ...removedAttendees]; // Only new and removed attendees

    const ATTENDEE_TO_ADDRESS_TYPE = {
      EVENT_CHANGED: {
        ADD_ATTENDEES_NO_ORIGINAL_OR_REMOVED_ATTENDEES: {
          attendees: allAttendees,
          message: "Would you like to send invitation emails to guests?",
        },
        ADD_TO_EXISTING_ATTENDEES_WITH_NONE_REMOVED: {
          attendees: allAttendees,
          message:
            "Would you like to send update emails to new and existing guests?",
        },
        REMOVED_AND_DID_NOT_ADD_ATTENDEES: {
          attendees: allAttendees,
          message:
            "Would you like to send emails to existing and removed guests?",
        },
        REMOVED_AND_ADDED_ATTENDEES: {
          attendees: allAttendees,
          message:
            "Would you like to send emails to new, existing, and removed guests?",
        },
        SAME_ATTENDEES: {
          attendees: allAttendees,
          message: "Would you like to send update emails to existing guests?",
        },
      },
      EVENT_NOT_CHANGED: {
        ADD_TO_EXISTING_ATTENDEES_WITH_NONE_REMOVED: {
          attendees: newAttendees,
          message: "Would you like to send invitation emails to new guests?",
        },
        REMOVED_AND_DID_NOT_ADD_ATTENDEES: {
          attendees: removedAttendees,
          message:
            "Would you like to send cancellation emails to removed guests?",
        },
        REMOVED_AND_ADDED_ATTENDEES: {
          attendees: newAndRemovedAttendees,
          message: "Would you like to send emails to new and removed guests?",
        },
      },
    };

    if (
      hasEventChanged &&
      newlyAddedAttendees &&
      newlyAddedAttendees.length > 0 &&
      originalState.attendees === attendees
    ) {
      // event changed and has added new people
      if (!attendees || attendees.length === 0) {
        // no original attendees and did not remove
        return ATTENDEE_TO_ADDRESS_TYPE.EVENT_CHANGED
          .ADD_ATTENDEES_NO_ORIGINAL_OR_REMOVED_ATTENDEES;
      }

      return ATTENDEE_TO_ADDRESS_TYPE.EVENT_CHANGED
        .ADD_TO_EXISTING_ATTENDEES_WITH_NONE_REMOVED;
    } else if (
      hasEventChanged &&
      originalState.attendees !== attendees &&
      (!newlyAddedAttendees || newlyAddedAttendees.length === 0)
    ) {
      // removed a user and changed event
      return ATTENDEE_TO_ADDRESS_TYPE.EVENT_CHANGED
        .REMOVED_AND_DID_NOT_ADD_ATTENDEES;
    } else if (
      hasEventChanged &&
      originalState.attendees !== attendees &&
      newlyAddedAttendees &&
      newlyAddedAttendees.length > 0
    ) {
      // removed a user and added user and changed event
      return ATTENDEE_TO_ADDRESS_TYPE.EVENT_CHANGED.REMOVED_AND_ADDED_ATTENDEES;
    } else if (
      !hasEventChanged &&
      originalState.attendees !== attendees &&
      newlyAddedAttendees &&
      newlyAddedAttendees.length > 0
    ) {
      // removed a user and added user but did not change event
      return ATTENDEE_TO_ADDRESS_TYPE.EVENT_NOT_CHANGED
        .REMOVED_AND_ADDED_ATTENDEES;
    } else if (
      !hasEventChanged &&
      originalState.attendees !== attendees &&
      (!newlyAddedAttendees || newlyAddedAttendees.length === 0)
    ) {
      // only removed user
      return ATTENDEE_TO_ADDRESS_TYPE.EVENT_NOT_CHANGED
        .REMOVED_AND_DID_NOT_ADD_ATTENDEES;
    } else if (
      !hasEventChanged &&
      newlyAddedAttendees &&
      newlyAddedAttendees.length > 0
    ) {
      // only added new attendee
      return ATTENDEE_TO_ADDRESS_TYPE.EVENT_NOT_CHANGED
        .ADD_TO_EXISTING_ATTENDEES_WITH_NONE_REMOVED;
    } else {
      // only changed event
      return ATTENDEE_TO_ADDRESS_TYPE.EVENT_CHANGED.SAME_ATTENDEES;
    }
  }

  getContentContainerHeight() {
    return `calc(100vh - ${
      50 + calculateMarginTop(this.props.shouldShowTopBar)
    }px)`;
  }

  getBottomSafeGuardHeight() {
    const nlpElement = document.getElementById(NLP_INPUT_FIELD_NAME);
    const saveButton = document.getElementById(
      EVENT_FORM_SAVE_BOTTON_STICKY_CONTAINER_ID
    );
    const isStuck = isElementSticky(saveButton);
    if (!isStuck) {
      return "16px";
    }

    if (!nlpElement) {
      return "90px";
    }

    return `calc(40px + ${nlpElement.offsetHeight ?? 0}px)`;
  }

  isInDayView() {
    return this.props.selectedCalendarView === 1;
  }

  onLoginZoom() {
    appBroadcast.publish("AUTHENTICATE_ZOOM", this.getUser());
  }

  onClickZoomLogin() {
    const { masterAccount } = this.props.masterAccount;
    const user = this.getUser();
    if (isMaestroUserOnDelegatedAccount({ masterAccount, user })) {
      // show modal with extra instructions
      this.setState({
        shouldDisplayModal: true,
        modalWidth: "700px",
        modalTitle: "Zoom access instructions",
        modalContent: MAESTRO_ZOOM_LOGIN_INFO,
      });
      return;
    }
    this.onLoginZoom();
  }

  uptickRerenderCount() {
    this.setState({ rerenderCount: this.state.rerenderCount + 1 });
  }

  onUserSetConferencing(selection) {
    this._userExplicitlySetNoConferencing = true;
    this.setConference(selection);
  }

  isOutOfOfficeEvent() {
    return this.state.transparency === OUT_OF_OFFICE_DURING_EVENT;
  }

  isEditingHoldEvent() {
    return !!this.state.vholds_id && !this.state.isCreatingNonHoldEvent;
  }

  /**
   * Pre-fetch the zoom meeting in case the user opens the settings menu. We may have the
   * conferencing info from the event itself, however it could be outdated if a user has
   * updated the Zoom settings without updating the calendar event. The safest way to check
   * if the event is a personal meeting link is to have the full Zoom event.
   */
  async fetchZoomMeeting() {
    const { conference } = this.state;

    if (conference !== ZOOM) {
      return;
    }

    const { schedulers } = this.props.zoomSchedulers;
    const authedZoomEmail =
      getAuthedZoomEmail(schedulers, getUserEmail(this.getUser())) ||
      getUserEmail(this.getUser());

    if (!authedZoomEmail) {
      return;
    }

    const entrypoint = getEventConferenceData(
      this.state.originalEvent
    )?.entryPoints?.find(
      (ep) => ep.entryPointType === "video" && "meetingCode" in ep
    );

    let zoomId;
    if (entrypoint) {
      zoomId = parseInt(entrypoint.meetingCode);
    }

    if (!zoomId || isNaN(zoomId)) {
      // for outlook, we have to check the original event's description to get the meeting ID since Outlook does not have event conferencing data
      zoomId = extractMeetingIdFromURL(this.state.originalEvent?.conferenceUrl);
    }

    if (!zoomId) {
      return;
    }

    this.setState({ isFetchingZoomMeeting: true });

    const zoomResponse = await fetchZoomMeetingByZoomId(
      authedZoomEmail,
      zoomId
    );
    if (!this._isMounted) {
      return;
    }
    if (isZoomMeetingPersonalLink(zoomResponse)) {
      this.setState({
        errorFetchingZoomMeeting: true,
        isEditingZoomSettings: false,
        isFetchingZoomMeeting: false,
      });
      return;
    }

    if (!zoomResponse) {
      this.setState({
        errorFetchingZoomMeeting: true,
        isEditingZoomSettings: false,
        isFetchingZoomMeeting: false,
      });
      return;
    }
    const { host_email } = zoomResponse;

    this.setState({
      isFetchingZoomMeeting: false,
      zoomMeeting: zoomResponse,
      zoomSchedulerEmail: host_email,
    });
  }

  /**
   * The user can only edit Zoom settings for the meeting host if the following
   * are true:
   * - The conferencing is set to Zoom
   * - There is a Zoom meeting in state
   * - The Zoom meeting is not a PMI (personal meeting link)
   * - The user has permission to schedule for the Zoom meeting host
   * - The user is not blocked from editing settings by the feature gate
   */
  canEditZoomSettingsForHost() {
    const { schedulers } = this.props.zoomSchedulers;
    const { conference, zoomMeeting } = this.state;

    if (conference !== ZOOM || !zoomMeeting) {
      return false;
    }

    // Feature gate.
    if (!canEditZoomSettings(this.getUser())) {
      return false;
    }

    if (isZoomMeetingPMI(zoomMeeting)) {
      return false;
    }

    const zoomMeetingHost = getZoomHostEmail(zoomMeeting);

    if (!zoomMeetingHost) {
      return false;
    }

    const matchingAuthZoomEmailFromScheduler = getAuthedZoomEmail(schedulers, zoomMeetingHost);
    if (matchingAuthZoomEmailFromScheduler) {
      return !!matchingAuthZoomEmailFromScheduler;
    }
    if (zoomMeetingHost && this.isCreateNew()) {
      // if creating event with newly created zoom -> you should always be able to edit
      return true;
    }
    if (this.isUpdateEvent()
      && zoomMeetingHost
    ) {
      // if you can read the event, you should be able to edit
      return true;
    }

    return false;
  }

  /**
   * @param {ZoomMeeting | null} updatedZoomMeeting
   */
  closeZoomSettingsForm(updatedZoomMeeting) {
    const updatedState = { isEditingZoomSettings: false };
    // An updated meeting is only returned if changes were made and saved.
    if (updatedZoomMeeting) {
      updatedState.conferenceURL = updatedZoomMeeting.join_url;
      updatedState.location = updatedZoomMeeting.join_url;
      updatedState.wasZoomUpdated = true;
      updatedState.zoomMeeting = updatedZoomMeeting;
    }
    this.setState(updatedState);
  }

  isCopyToCalendarEvent() {
    return this.state.isDuplicate && this.state.isCopiedFromAnotherCalendar;
  }

  isDuplicateEvent() {
    return this.state.isDuplicate && !this.state.isCopiedFromAnotherCalendar;
  }

  // if we always prefetch, this is goign to get really expensive for our backend
  shouldPrefetchRooms() {
    if (this.isTemplate()) {
      // no reason to fetch for template
      return;
    }
    const { allRooms } = this.state;
    if (allRooms?.length <= 200) {
      return true;
    }
    const user = this.getUser();
    return isWBUser(user) || isHubSpotUser(user);
  }

  hasPermissionToViewDistroList() {
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const matchingUser = getMatchingUserFromAllUsers({
      allUsers: allLoggedInUsers,
      userEmail: this.getCalendarEmail(),
    });
    return hasPermissionToViewDistroList(matchingUser);
  }

  async getDistroListForEvent() {
    const { newlyAddedAttendees } = this.state;
    if (isEmptyArrayOrFalsey(newlyAddedAttendees)) {
      return;
    }

    if (!this.hasPermissionToViewDistroList()) {
      return;
    }

    try {
      const emails = newlyAddedAttendees
        .map((attendee) => getObjectEmail(attendee))
        .filter((email) => email);

      let updatedDictionary = await getMatchingDistroListFromEmails({
        emails,
        userEmail: this.getCalendarEmail(),
      });
      const subgroupDistroLists = await getSubDistroLists({ distroLists: updatedDictionary });
      updatedDictionary = { ...updatedDictionary, ...subgroupDistroLists };
      if (!this._isMounted) {
        return;
      }
      if (isEmptyObjectOrFalsey(updatedDictionary)) {
        return;
      }
      const { setDistroListDictionary, distroListDictionary } =
        this.props.distroListDictionary;
      setDistroListDictionary({
        ...distroListDictionary,
        ...updatedDictionary,
      });
    } catch (error) {
      handleError(error);
    }
  }
}

function mapStateToProps(state) {
  let {
    popupEvent,
    isMac,
    selectedDay,
    shouldShowTopBar,
    activeCalendars,
    temporarySecondaryCalendarColorsIndex,
    eventFormEmails,
    isCommandCenterOn,
    temporaryEvents,
    currentPreviewedEvent,
    currentUser,
    currentTimeZone,
    currentTemplate,
    contacts,
    isDarkMode,
    currentTimeZoneLabel,
    lastEventFormState,
    defaultBrowserTimeZone,
    dateFieldOrder,
    emailToNameIndex,
    selectedCalendarView,
    weekStart,
    isMobileView,
    originalRecurrenceEventIndex,
  } = state;

  return {
    popupEvent,
    isMac,
    selectedDay,
    shouldShowTopBar,
    activeCalendars,
    temporarySecondaryCalendarColorsIndex,
    eventFormEmails,
    isCommandCenterOn,
    temporaryEvents,
    currentPreviewedEvent,
    currentUser,
    currentTimeZone,
    currentTemplate,
    contacts,
    isDarkMode,
    currentTimeZoneLabel,
    lastEventFormState,
    defaultBrowserTimeZone,
    dateFieldOrder,
    emailToNameIndex,
    selectedCalendarView,
    weekStart,
    isMobileView,
    originalRecurrenceEventIndex,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectDay: (day) => dispatch({ data: day, type: "SELECT_DAY" }),
    removePreviewEvent: () =>
      dispatch({ type: "REMOVE_CURRENT_PREVIEW_EVENT" }),
    setTemporaryEvent: (event) =>
      dispatch({ data: event, type: "SET_TEMPORARY_EVENTS" }),
    removePopupEvent: (event) =>
      dispatch({ data: event, type: "REMOVE_POPUP_EVENT" }),
    setEventFormEmails: (email) =>
      dispatch({ data: email, type: "SET_EVENT_FORM_EMAILS" }),
    createTemplate: (event) =>
      dispatch({ data: event, type: "CREATE_TEMPLATE" }),
    setTemporarySecondaryCalendarColorsIndex: (data) =>
      dispatch({
        data: data,
        type: "SET_TEMPORARY_SEARCHED_CALENDAR_COLORS_INDEX",
      }),
    toggleGlobalKeyMap: () => dispatch({ type: "TOGGLE_SHOW_GLOBAL_KEY_MAP" }),
    setLastEventFormState: (data) =>
      dispatch({ data: data, type: "SET_LAST_EVENT_FORM_STATE" }),
    setDuplicateEvent: (data) => dispatch({ data, type: "DUPLICATE_EVENT" }),
    setOriginalRecurrenceEventIndex: (data) =>
      dispatch({ data, type: "SET_ORIGINAL_RECURRENCE_EVENT_INDEX" }),
  };
}

const withStore = (BaseComponent) => (props) => {
  // Fetch initial state
  const allCalendars = useAllCalendars();
  const allUserDomains = useAllUserDomains();
  const allLoggedInUsers = useAllLoggedInUsers();
  const masterAccount = useMasterAccount();
  const zoomSchedulers = useZoomSchedulers();
  const temporaryStateStore = useTemporaryStateStore();
  const outlookCategoriesStore = useOutlookCategoriesStore();
  const distroListDictionary = useDistroListDictionary();
  const conferenceRoomStore = useConferenceRoomStore();

  return (
    <BaseComponent
      {...props}
      allCalendars={allCalendars}
      allUserDomains={allUserDomains}
      allLoggedInUsers={allLoggedInUsers}
      masterAccount={masterAccount}
      zoomSchedulers={zoomSchedulers}
      temporaryStateStore={temporaryStateStore}
      outlookCategoriesStore={outlookCategoriesStore}
      distroListDictionary={distroListDictionary}
      conferenceRoomStore={conferenceRoomStore}
    />
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStore(EventForm))
);
