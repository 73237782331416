import React, { Component, lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import {
  constructQueryParams,
  localData,
  isElectron,
  loadTheme,
  handleError,
  setDebounceBackendTimer,
  convertTrueFalseStringIntoValue,
  isValidJSDate,
  isOSSchemeDarkMode,
  isWindows,
  isOnboardingMode,
  isSafari,
  omitNullOrUndefinedProps,
  isMobile,
  OpenLink,
  isMac,
} from "./js/services/commonUsefulFunctions";
import {
  getReferralCode,
  isInWaitingForSignupPage,
  updateAllLoggedInUsers,
} from "./js/lib/stateManagementFunctions";
import { trackEvent } from "./js/components/tracking";
import { constructRequestURL, isErrorResponse } from "./js/services/api";
import Fetcher from "./js/services/fetcher";
import Broadcast from "./js/broadcasts/broadcast";
import db from "./js/services/db";
import * as Sentry from "@sentry/browser";
import {
  DARK_MODE_THEME,
  LIGHT_MODE_THEME,
  ELECTRON_APP_VERSION_NUMBER,
  MINUTE_IN_MS,
  DARK_MODE_BACKGROUND_COLOR,
  VIMCAL_LOGO_WITH_CIRCLE_BACKGROUND,
  VIMCAL_LOGO_SVG,
  PHOTOS,
  SECOND_IN_MS,
  SET_DISAPPEARING_NOTIFICATION_MESSAGE,
  GENERIC_ERROR_MESSAGE,
  PERMISSIONS_SCREENSHOT_WHITE,
} from "./js/services/globalVariables";
import { MAESTRO_ACCOUNT_TYPES } from "./js/services/globalMaestroVariables";
import { isValid, differenceInHours } from "date-fns";
import DefaultLoadingScreen from "./js/components/defaultLoadingScreen";
import componentLoader from "./js/lib/componentLoader";
import { getSavedSSOLoginToken, LOCAL_DATA_ACTION, removeSavedSSOLoginToken, saveTabIDIntoLocalData, setCurrentUserEmail } from "./js/lib/localData";
import Onboarding from "./js/components/onboarding/onboardingContainer";
import AppBroadcast from "./js/broadcasts/appBroadcast";
import BackendContainer from "./js/components/backendContainer";
import TrialFinished from "./js/components/onboarding/trialExpired/trialFinished";
import AuthorizedRoute from "./js/services/authorizedRoute";
import PreloadResources from "./js/components/preloadResources";
import { useAccountActivity, useTabID } from "./js/services/stores/appFunctionality";
import UnsubscribeFromEmail from "./js/components/unsubscribeFromEmail";
import BillingContainer from "./js/components/billing/billingContainer";
import {
  useAllLoggedInUsers,
  useAllUserDomains,
  useMasterAccount,
} from "./js/services/stores/SharedAccountData";
import produce from "immer";
import {
  MOBILE_DOWNLOAD_PATH,
  ONBOARDING_CONFIRMATION,
  SSO_DESKTOP_LOGIN,
  SSO_LOGIN,
  SSO_MAGIC_LINK_LOGIN,
  SSO_NEW_USER_DESKTOP_LOGIN,
  SSO_NEW_USER_LOGIN,
  TYPEFORM_ROUTE,
  UNAUTHORIZED_PAYMENT_ROUTE,
  VIMCAL_EA_LOGIN_PATH,
  VIMCALEA_DEMO_PATH,
  VIMCALEA_FORM_PATH,
} from "./js/services/routingFunctions";
import classNames from "classnames";
import RecurrenceContainer from "./js/components/recurrenceContainer";
import TooltipsWrapper from "./js/components/tooltips/tooltipsWrapper";
import { isVersionV2 } from "./js/services/versionFunctions";
import {
  getAccountHasSelfServeAccess,
  getUserEmail,
  getUserToken,
  isValidUser,
} from "./js/lib/userFunctions";
import {
  isBeyondMinimumWidth,
  isElectronGlassyBackground,
} from "./js/lib/clientFunctions";
import { getGoogleRedirectURL } from "./js/lib/googleFunctions";
import TypeformThankYouPage from "./js/components/onboarding/typeFormThankYouPage";
import backendBroadcasts from "./js/broadcasts/backendBroadcasts";
import {
  getUserCodes,
  isOpenSelfServeLink,
} from "./js/services/queryParamFunctions";
import { usePermissionsStore } from "./js/services/stores/permissionsStore";
import { useUserCodes } from "./js/services/stores/userData";
import { isInVimcalEAFormView, isMacMSeries, isVimcalEAAddNewAccountPage, isVimcalEALoginPage, shouldShowFullLoadingScreenPendingBackendResponse, triggerRefreshWithOnlineCheck } from "./js/services/appFunctions";
import { identifyMixPanel } from "./js/lib/mixpanelTracking";
import LoadingFullScreen from "./js/components/onboarding/loadingFullScreen";
import routeBroadcast from "./js/broadcasts/routeBroadcast";
import { OUTLOOK_ADDITIONAL_SCOPES } from "./js/resources/outlookVariables";
import { LATEST_SLOTS_VERSION, useAppSettings } from "./js/services/stores/settings";
import { isShortWindowFetchUser } from "./js/lib/featureFlagFunctions";
import MobilePostSignUpPage from "./js/components/mobileSignUp/mobilePostSignUpPage";
import { sendMobileSignUpEmail } from "./js/components/mobileSignUp/sharedFunctions";
import { APP_BROADCAST_VALUES, BROADCAST_VALUES, CONTACT_BROADCAST_VALUES, LAYOUT_BROADCAST_VALUES, LOGIN_BROADCAST_VALUES, ROUTE_BROADCAST_VALUES } from "./js/lib/broadcastValues";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey } from "./js/services/typeGuards";
import UpdateSettingsContainer from "./js/components/settings/updateSettingsContainer";
import { getDefaultUserTimeZone, isUserInDarkMode, shouldMatchOSSetting } from "./js/lib/settingsFunctions";
import { createUUID } from "./js/services/randomFunctions";
import MagicLinksContainer from "./js/components/magicLink/magicLinksContainer";
import { MAGIC_LINK_PATH } from "./js/services/maestro/maestroRouting";
import MagicLink from "./js/components/magicLink";
import { useMagicLink } from "./js/services/stores/magicLinkStore";
import { getUserConnectedAccountToken, isUserFromMagicLink } from "./js/services/maestro/maestroAccessors";
import { fetcherPost } from "./js/services/fetcherFunctions";
import { isEmptyArray } from "./js/lib/arrayFunctions";
import loginBroadcast from "./js/broadcasts/loginBroadcast";
import { authenticateViaSSOLoginToken, createDesktopLoginLinkFromSSOToken, SSO_TOKEN_LOGIN_ERROR } from "./js/lib/ssoFunctions";
import { getHomeLink } from "./js/lib/envFunctions";
import { WEB_STYLES_ID } from "./js/services/elementIDVariables";
import layoutBroadcast from "./js/broadcasts/layoutBroadcast";
import { isUserMaestroUser } from "./js/services/maestroFunctions";
import VimcalEALogin from "./components/vimcalEALogin";
import contactBroadcast from "./js/broadcasts/contactBroadcast";
import VimcalEAForm from "./components/vimcalEAForm/vimcalEAForm";
import Layout from "./js/views/layout";
import Login from "./js/views/login/index";
import DemoLogin from "./js/views/demoLogin";

const LIGHT_SCHEME = "light";
const DARK_SCHEME = "dark";


class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isElectron: isElectron(),
      shouldRefresh: false,
      isBeyondMinimumWidth: isBeyondMinimumWidth(), // detects if the window is beyond the minimum width for calendar and right panel to be displayed properly
    };
    this.sanityCheckDataIntegrity();

    this.isConstructed = true;

    this._lastRefreshedTime = new Date();
    this._reloadComponentTimer = null;
    this._forceTimeoutTimer = null;

    this._desktopBridgeAttempt = 0;
    this._setDesktopBridgeTimeout = null;

    this.onLoginSuccess = this.onLoginSuccess.bind(this);
    this.onLoginSuccessOutlook = this.onLoginSuccessOutlook.bind(this);
    this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
    this.checkForElectronVisibility =
      this.checkForElectronVisibility.bind(this);
    this.onElectronLogin = this.onElectronLogin.bind(this);
    this.onReceiveAppVersionNumber = this.onReceiveAppVersionNumber.bind(this);
    this.zoomLogin = this.zoomLogin.bind(this);
    this.onElectronZoomLogin = this.onElectronZoomLogin.bind(this);
    this.onElectronOutlookLogin = this.onElectronOutlookLogin.bind(this);
    this.setShouldRefreshApp = this.setShouldRefreshApp.bind(this);
    this.OSColorSchemeListener = this.OSColorSchemeListener.bind(this);
    this.determineOSSchemeSetting = this.determineOSSchemeSetting.bind(this);
    this.setDarkMode = this.setDarkMode.bind(this);
    this.setLightMode = this.setLightMode.bind(this);
    this.checkForceAppRefresh = this.checkForceAppRefresh.bind(this);
    this.changeTabLabel = this.changeTabLabel.bind(this);
    this.authenticateProxyUser = this.authenticateProxyUser.bind(this);
    this.loginFromLoggedOutStateOutlook =
      this.loginFromLoggedOutStateOutlook.bind(this);
    this.handleLoginResponse = this.handleLoginResponse.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.showFullScreenLoadingScreen = this.showFullScreenLoadingScreen.bind(this);
    this.authenticateByOutlookEmail = this.authenticateByOutlookEmail.bind(this);
    this.handleProxyUserLogin = this.handleProxyUserLogin.bind(this);
    this.authenticateBySSO = this.authenticateBySSO.bind(this);
    this.onElectronSSOTokenLogin = this.onElectronSSOTokenLogin.bind(this);

    Broadcast.subscribe("ON_LOGIN_SUCCESS", this.onLoginSuccess);
    Broadcast.subscribe("CHANGE_TAB_LABEL", this.changeTabLabel);
    Broadcast.subscribe("SET_SHOULD_REFRESH_APP", this.setShouldRefreshApp);
    Broadcast.subscribe(
      "SET_COLOR_SCHEME_BASED_ON_OS",
      this.determineOSSchemeSetting,
    );
    AppBroadcast.subscribe("SET_APP_DARK_MODE", this.setDarkMode);
    AppBroadcast.subscribe("SET_APP_LIGHT_MODE", this.setLightMode);
    Broadcast.subscribe("CHECK_FORCE_APP_REFRESH", this.checkForceAppRefresh);
    Broadcast.subscribe("CONVERT_CALENDAR_TO_USER", this.authenticateProxyUser);
    AppBroadcast.subscribe(
      "OUTLOOK_LOGIN_WITH_AUTH_CODE",
      this.onLoginSuccessOutlook,
    );
    AppBroadcast.subscribe("SHOW_FULL_SCREEN_LOADING_SCREEN", this.showFullScreenLoadingScreen);
    AppBroadcast.subscribe("AUTHENTICATE_BY_OUTLOOK_EMAIL", this.authenticateByOutlookEmail);
    AppBroadcast.subscribe(APP_BROADCAST_VALUES.HANDLE_LOGIN_RESPONSE, this.handleLoginResponse);
    AppBroadcast.subscribe(APP_BROADCAST_VALUES.AUTHENTICATE_BY_SSO, this.authenticateBySSO);

    const mql = window.matchMedia("(prefers-color-scheme: dark)");
    if (mql?.addEventListener) {
      mql.addEventListener("change", this.OSColorSchemeListener);
    }

    !isElectron() &&
      document.addEventListener(
        "visibilitychange",
        this.handleVisibilityChange,
      );
  }

  componentDidMount() {
    this._isMounted = true;
    document.documentElement.setAttribute("client-type", isElectron() ? "electron" : "web");
    if (isElectron()) {
      // remove is web so desktop can have transparent background
      const isWebElem = document.getElementById(WEB_STYLES_ID);
      if (isWebElem) {
        isWebElem.remove();
      }
    }

    window.addEventListener("resize", this.handleResize);
    this.setUserCodes();

    // Tab number to track which tab is most recently active. Tab number only matches to one tab
    this.setTabID();
    this.checkForMacArch();

    this.loadDesktopBridge();

    this._forceTimeoutTimer = setTimeout(() => {
      if (!this._isMounted) {
        return;
      }
      this.refreshApp();
      // refresh after 60 hours
    }, 60 * (60 * MINUTE_IN_MS));
  }

  componentWillUnmount() {
    this._isMounted = false;

    clearTimeout(this._setDesktopBridgeTimeout);
    this._setDesktopBridgeTimeout = null;

    this.isConstructed = false;
    window.removeEventListener("resize", this.handleResize);

    clearTimeout(this._lastRefreshedTime);
    this._lastRefreshedTime = null;

    clearTimeout(this._reloadComponentTimer);
    this._reloadComponentTimer = null;

    clearTimeout(this._forceTimeoutTimer);
    this._forceTimeoutTimer = null;

    Broadcast.unsubscribe("ON_LOGIN_SUCCESS");
    Broadcast.unsubscribe("CHANGE_TAB_LABEL");
    Broadcast.unsubscribe("SET_SHOULD_REFRESH_APP");
    Broadcast.unsubscribe("SET_COLOR_SCHEME_BASED_ON_OS");
    Broadcast.unsubscribe("CHECK_FORCE_APP_REFRESH");
    AppBroadcast.unsubscribe("SET_APP_DARK_MODE");
    AppBroadcast.unsubscribe("SET_APP_LIGHT_MODE");
    Broadcast.unsubscribe("CONVERT_CALENDAR_TO_USER");
    AppBroadcast.unsubscribe("OUTLOOK_LOGIN_WITH_AUTH_CODE");
    AppBroadcast.unsubscribe("SHOW_FULL_SCREEN_LOADING_SCREEN");
    AppBroadcast.unsubscribe("AUTHENTICATE_BY_OUTLOOK_EMAIL");
    AppBroadcast.unsubscribe(APP_BROADCAST_VALUES.HANDLE_LOGIN_RESPONSE);
    AppBroadcast.unsubscribe(APP_BROADCAST_VALUES.AUTHENTICATE_BY_SSO);

    const mql = window.matchMedia("(prefers-color-scheme: dark)");
    if (mql?.removeEventListener) {
      mql.removeEventListener("change", this.OSColorSchemeListener);
    }

    !this.state.isElectron &&
      document.removeEventListener(
        "visibilitychange",
        this.handleVisibilityChange,
      );

    if (this.state.isElectron && window && window.vimcal) {
      window.vimcal.removeOnReceiveElectronAppNumber(
        this.onReceiveAppVersionNumber,
      );
      window.vimcal.removeOnLogin(this.onElectronLogin);
      window.vimcal.removeOnChangeVisibility(this.checkForElectronVisibility);
      window.vimcal.removeZoomLogin(this.onElectronZoomLogin);
      if (window.vimcal.removeOnSSOTokenLogin) {
        window.vimcal.removeOnSSOTokenLogin(this.onElectronSSOTokenLogin);
      }
      if (window.vimcal.removeOutlookLogin) {
        window.vimcal.removeOutlookLogin(this.onElectronOutlookLogin);
      }
    }
  }

  render() {
    return (
      <Suspense fallback={<DefaultLoadingScreen />}>
        <Router>
          <div
            className={classNames(
              "App",
              isWindows() ? "client-windows" : "",
              isElectron() ? "electron-client" : "",
              isElectronGlassyBackground() && this.state.isBeyondMinimumWidth
                ? "electron-glassy-background"
                : "", // only show this past version 1.0.22
              isOnboardingMode() ? "default-background-color" : "",
              isSafari() ? "" : "home-page-hide-scroll-bar-unless-hover",
              isInVimcalEAFormView() || isVimcalEALoginPage() ? "h-screen" : "",
            )}
          >
            <PreloadResources
              resources={[VIMCAL_LOGO_WITH_CIRCLE_BACKGROUND, VIMCAL_LOGO_SVG, PHOTOS.LOADING_SCREEN_PHOTO, PERMISSIONS_SCREENSHOT_WHITE]}
            />
            {this.shouldShowFullLoadingScreenPendingBackendResponse()
              ? <LoadingFullScreen />
              : null
            }
            <BackendContainer />
            <MagicLinksContainer />
            <UpdateSettingsContainer />
            <RecurrenceContainer />
            <TooltipsWrapper />
            <Suspense fallback={<DefaultLoadingScreen/>}>
              <Switch>
                <Route
                  path={`/${MAGIC_LINK_PATH}`}
                  component={MagicLink}
                />
                <Route
                  path="/ea-login"
                  render={(props) => <Login {...props} isMaestro={true} />}
                />
                <Route path="/login" component={Login} />
                <Route path={`/${VIMCAL_EA_LOGIN_PATH}`} component={VimcalEALogin} />
                <Route path="/signup" render={() => <Login isSignup={true} />} />
                <Route path="/aloha" render={() => <Login isSignup={true} />} />
                <Route
                  path="/desktop-login"
                  render={(props) => <Login {...props} isDesktopLogin={true} />}
                />
                <Route path={`/${VIMCALEA_DEMO_PATH}`} component={DemoLogin} />
                <Route path="/zoom-login" component={this.zoomLogin} />
                <Route
                  path={`/${UNAUTHORIZED_PAYMENT_ROUTE}`}
                  component={TrialFinished}
                />
                <Route path="/unsubscribe" component={UnsubscribeFromEmail} />
                {/* While not referenced in the app directly, the Stripe emails include links to this page. */}
                <Route path="/billing" component={BillingContainer} />
                <Route path={`/${VIMCALEA_FORM_PATH}`} component={VimcalEAForm} />

                <Route path="/welcome" component={Onboarding} />
                <Route path={`/${MOBILE_DOWNLOAD_PATH}`} component={MobilePostSignUpPage} />
                <Route path={`/${SSO_MAGIC_LINK_LOGIN}`} render={(props) => <Login {...props} isMagicLink={true} />} />
                <Route path={`/${SSO_LOGIN}`} component={Login} />
                <Route path={`/${SSO_DESKTOP_LOGIN}`} render={(props) => <Login {...props} isDesktopLogin={true} />} />
                <Route path={`/${SSO_NEW_USER_LOGIN}`} render={(props) => <Login {...props} isNewUserSSOLogin={true} />} />
                <Route path={`/${SSO_NEW_USER_DESKTOP_LOGIN}`} render={(props) => <Login {...props} isDesktopLogin={true} isNewUserSSOLogin={true} />} />
                <Route
                  path={`/${TYPEFORM_ROUTE}`}
                  component={TypeformThankYouPage}
                />
                <Route
                  path={`/${ONBOARDING_CONFIRMATION}`}
                  isConfirmedBooking={true}
                  component={TypeformThankYouPage}
                />
                <Route path="/join" component={TrialFinished} />
                <AuthorizedRoute path="*" component={Layout} />
              </Switch>
            </Suspense>
          </div>
        </Router>
      </Suspense>
    );
  }

  checkForElectronVisibility(event, data) {
    if (!data || !data.result) {
      return;
    }

    let action = data.result;

    if (["onShow"].includes(action)) {
      this.checkForRefresh();
      contactBroadcast.publish(CONTACT_BROADCAST_VALUES.FETCH_CONTACTS);
    }
  }

  onElectronOutlookLogin(event, parameterString) {
    // codeString = vimcal-dev://outlook=aslkdfjalskdf
    const separatedParameters = parameterString.split("&");
    /* Response code from outlook log in */
    const codeString = separatedParameters.find((parameterString) =>
      parameterString.includes("outlook="),
    );
    const codeIndex = codeString?.indexOf("outlook=");
    const authCode = codeString?.substring(codeIndex + 8);

    /* Account type to pass */
    const accountTypeString = separatedParameters.find((parameterString) =>
      parameterString.includes("accountType="),
    );
    const accountTypeIndex = accountTypeString?.indexOf("accountType=");

    /* For debugging */
    const accountTypeOriginalString = accountTypeString?.substring(accountTypeIndex + 12);
    if (!MAESTRO_ACCOUNT_TYPES[accountTypeOriginalString]) {
      console.log(accountTypeOriginalString);
    }

    /* Enforce only letters */
    const accountType = accountTypeString?.substring(accountTypeIndex + 12).replace(/[^A-Za-z]/gi, "");

    if (!authCode) {
      // no auth code
      return;
    }

    this.onLoginSuccessOutlook({
      response: authCode,
      accountType,
      isDesktopLogin: true,
    });
  }

  onElectronZoomLogin(event, codeString) {
    // codeString = vimcal-dev://zoom=?zoom_logged_in=true
    const searchParams = new URL(codeString).searchParams;
    const isZoomLoggedIn = convertTrueFalseStringIntoValue(
      searchParams.get("zoom_logged_in"),
    );
    const personalLink = searchParams.get("zoom_link");
    const defaultZoomMode = searchParams.get("default_zoom_mode");

    if (!isZoomLoggedIn) {
      Broadcast.publish("ERROR_ON_ZOOM_LOGIN");
      return;
    }

    AppBroadcast.publish(
      APP_BROADCAST_VALUES.UPDATE_CURRENT_USER_ACCESS_TOKEN, {
        personalLink,
        defaultZoomMode,
      },
    );
  }

  setTabID() {
    // Setting in localStorage also works across browser window as well as every modern browser
    const newTabID = createUUID();

    saveTabIDIntoLocalData(newTabID);

    const { setTabID } = this.props.useTabID;
    setTabID(newTabID);
  }

  onElectronSSOTokenLogin(event, parameterString) {
    if (!parameterString) {
      return;
    }
    const tokenRegex = /sso_login_token=([^&]+)/;
    const match = parameterString.match(tokenRegex);

    if (match?.[1]) {
      const ssoLoginToken = match[1];
      this.authenticateBySSO({ ssoLoginToken, isFromElectronLogin: true});
    }
  }

  onElectronLogin(event, parameterString) {
    const separatedParameters = parameterString.split("&");
    /* Response code from google log in */
    const codeString = separatedParameters.find((parameterString) =>
      parameterString.includes("code="),
    );
    const codeIndex = codeString?.indexOf("code=");
    const code = codeString?.substring(codeIndex + 5);

    /* Account type to pass */
    const accountTypeString = separatedParameters.find((parameterString) =>
      parameterString.includes("accountType="),
    );
    const accountTypeIndex = accountTypeString?.indexOf("accountType=");
    const accountType = accountTypeString?.substring(accountTypeIndex + 12);

    this.onLoginSuccess({ response: { code }, accountType, isDesktopLogin: true });
  }

  showFullScreenLoadingScreen() {
    const {
      currentUser,
    } = this.props;
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    if (isEmptyObjectOrFalsey(currentUser) && isEmptyArray(allLoggedInUsers)) {
      const {
        setHasReceivedLoginCode,
      } = this.props.accountActivity;
      setHasReceivedLoginCode();
      setTimeout(() => {
        this.resetFullScreenLoadingScreen();
      }, 8 * SECOND_IN_MS);
    }
  }

  onLoginSuccess({
    response,
    accountType = null,
    isDesktopLogin,
    isMagicLink = false,
    shouldSkipMagicLinkUserCheck = false,
  }) {
    const {
      currentUser,
    } = this.props;
    const {
      setSlotsVersion,
    } = this.props.appSettingsStore;
    setSlotsVersion(LATEST_SLOTS_VERSION);
    this.responseHasUserEmail(response) &&
      this.changeTabLabel(response.user.email);

    !isEmptyObjectOrFalsey(currentUser) ?
      this.addAdditionalAccount({
        response,
        accountType,
        isDesktopLogin,
        isMagicLink,
        shouldSkipMagicLinkUserCheck, // Only allowed to skip with correct user signed in
      }) :
      this.loginFromLoggedOutState({
        response,
        accountType,
        isDesktopLogin,
        isMagicLink,
      });
  }

  responseHasUserEmail(response) {
    return response && response.user && response.user.email;
  }

  addAdditionalAccount({
    response,
    accountType,
    isDesktopLogin,
    isMagicLink,
    shouldSkipMagicLinkUserCheck,
  }) {
    const { currentUser, magicLinkToken } = this.props;
    trackEvent({
      category: "login",
      action: "add_additional_account",
      label: "login_page",
      userToken: getUserToken(currentUser),
    });

    const path =
      accountType === MAESTRO_ACCOUNT_TYPES.SCHEDULER_FOR_OTHERS
        ? "delegated_users/authenticate"
        : "google/authenticate_additional_user";

    const isUserLoggingInFromOnboardingFlow = isOnboardingMode();
    response["redirect_uri"] = getGoogleRedirectURL({
      isDesktopLogin,
      isInOnboarding: isUserLoggingInFromOnboardingFlow,
      isMagicLink, // Assume user is in magic link flow if token is stored
    });

    /* Only add the token if we don't want to skip the user check */
    /* For Google, we pass in the redirect_uri directly */
    if (isMagicLink && !shouldSkipMagicLinkUserCheck) {
      response["magic_link_token"] = magicLinkToken;
    }

    const payloadData = {
      body: JSON.stringify({...response, sso_login_token: getSavedSSOLoginToken()}),
      credentials: "include",
      headers: {},
    };

    const params = {
      master_account_id: currentUser.master_account_id,
    };

    const queryParams = constructQueryParams(params);
    const url = constructRequestURL(path) + `?${queryParams}`;

    return fetcherPost({
      url,
      payloadData,
      email: getUserEmail(currentUser),
      onError: this.handleAuthenticationError,
    })
      .then((response) => {
        if (!this._isMounted || isEmptyObjectOrFalsey(response) || response.error) {
          return;
        }

        const { user, master_account } = response;
        const { email } = user;

        this.determineDarkModeSetting({ masterAccount: master_account });
        removeSavedSSOLoginToken();

        Sentry.configureScope(function (scope) {
          scope.setUser({ email });
          scope.setTag("email", email);
        });

        if (!isUserLoggingInFromOnboardingFlow) {
          this.updateAppTimeZones({
            inputMasterAccount: master_account,
            inputUser: user,
          });
        }

        // only show first login modal if it's an addtional account
        this.storeLoginResponse({
          response,
          skipUpdatingCurrentUser: isUserLoggingInFromOnboardingFlow,
        });
        if (!isUserLoggingInFromOnboardingFlow) {
          Broadcast.publish("SWITCH_ACCOUNTS", {
            account: user,
            checkForFirstLogin: true,
          });
          routeBroadcast.publish(ROUTE_BROADCAST_VALUES.ROUTE_ON_AUTH);
        }

        Promise.resolve(db.fetch(email))
          .then(() => {
            if (!this._isMounted) {
              return;
            }

            if (response) {
              db.fetch(email)
                .open()
                .then(() => {})
                .catch((err) => {
                  handleError(err);
                });
            }
          })
          .catch((err) => {
            handleError(err);
          });
      })
      .catch((err) => {
        handleError(err);
      });
  }

  authenticateByOutlookEmail({
    delegateEmail,
    email,
    firstName,
    lastName,
  }) {
    const {
      currentUser,
    } = this.props;

    const path = "proxy_users/authenticate_by_outlook_email";
    const url = constructRequestURL(path, true);

    const payloadData = {
      body: JSON.stringify({
        email,
        delegate_email: delegateEmail,
        first_name: firstName,
        last_name: lastName,
      }),
    };

    return fetcherPost({
      url,
      payloadData,
      email: delegateEmail || getUserEmail(currentUser),
      onError: this.handleAuthenticationError,
    })
      .then((response) => {
        if (!this._isMounted) {
          return;
        }
        if (isEmptyObjectOrFalsey(response)) {
          Broadcast.publish(
            SET_DISAPPEARING_NOTIFICATION_MESSAGE,
            GENERIC_ERROR_MESSAGE,
          );
          return;
        }
        if (response.error) {
          const {
            error,
          } = response;
          Broadcast.publish(
            SET_DISAPPEARING_NOTIFICATION_MESSAGE,
            error,
          );
          return;
        }
        this.handleProxyUserLogin(response);
      })
      .catch((err) => {
        handleError(err);
      });
  }

  async authenticateBySSO({
    ssoLoginToken,
    isDesktopLogin,
    isFromElectronLogin
  }) {
    if (!ssoLoginToken) {
      return;
    }

    if (isDesktopLogin) {
      // send to desktop app to authorize.
      const appLink = createDesktopLoginLinkFromSSOToken({ ssoLoginToken });
      window.open(appLink, "_self");
      routeBroadcast.publish(ROUTE_BROADCAST_VALUES.GO_TO_DESKTOP_LOGIN);
      return;
    }

    this.showFullScreenLoadingScreen();
    try {
      const response = await authenticateViaSSOLoginToken({ssoLoginToken});
      // response always comes back as 200 with {error: message} if it errors out
      if (!this._isMounted || isEmptyObjectOrFalsey(response)) {
        return;
      }
      if (response?.error) {
        this.resetFullScreenLoadingScreen();
        const {
          error,
        } = response;
        if (isDesktopLogin) {
          routeBroadcast.publish(ROUTE_BROADCAST_VALUES.GO_TO_DESKTOP_LOGIN);
        } else {
          routeBroadcast.publish("GO_TO_LOGIN_PAGE");
        }
        switch (error) {
          case SSO_TOKEN_LOGIN_ERROR.INVALID_TOKEN:
            // 401 unauthorized
            // Description:  This error is returned if the token is expired or doesn’t exist in the backend. 
            // The frontend should show the sso_login button again so the user can initiate the sso login
            removeSavedSSOLoginToken();
            loginBroadcast.publish(LOGIN_BROADCAST_VALUES.UPDATE_SHOW_SSO_BUTTON, { shouldHideSSOLoginButton: false });
            return;
          case SSO_TOKEN_LOGIN_ERROR.NOT_FOUND:
            // keep token
            // Status: 404 not found
            // Description:  This error is returned if the token is valid but we couldn’t find a master account associated with it.
            // The frontend should NOT show the sso_login button, 
            // so the user can click on either google or outlook and create the master account associated with that saml_id. 
            // IMPORTANT: the backend will still expect the sso_login_token on the v1/google/authenticate endpoint 
            // or the v2 matching endpoint for outlook so we can associate the new master account with the saml account in the backend
            if (isFromElectronLogin) {
              OpenLink(`${getHomeLink()}/${SSO_NEW_USER_DESKTOP_LOGIN}}`);
            } else {
              routeBroadcast.publish(ROUTE_BROADCAST_VALUES.GO_TO_NEW_USER_SSO_LOGIN);
            }
            return;
          default:
            return;
        }
      }

      this.handleLoginResponse(response);
      removeSavedSSOLoginToken();
    } catch (error) {
      handleError(error);
    }
  }

  authenticateProxyUser({email, firstName, lastName, userCalendarId}) {
    const path = "proxy_users/authenticate";
    const url = constructRequestURL(path, true);

    const payloadData = {
      body: JSON.stringify({
        // Use a secondary calendar your work account has write-access to.
        // First and last name are required for creating a new account,
        // but not for subsequent authentication
        email,
        first_name: firstName,
        last_name: lastName,
        user_calendar_id: userCalendarId,
      }),
    };

    return fetcherPost({
      url,
      payloadData,
      email: getUserEmail(this.props.currentUser),
      onError: this.handleAuthenticationError,
    })
      .then((response) => {
        this.handleProxyUserLogin(response, false);
        if (response?.user) {
          Broadcast.publish(
            SET_DISAPPEARING_NOTIFICATION_MESSAGE,
            `${firstName || getUserEmail(response.user)} has been added as your executive`,
          );
          if (isVimcalEAAddNewAccountPage()) {
            routeBroadcast.publish("GO_TO_HOME");
          }
        }
      })
      .catch((err) => {
        handleError(err);
      });
  }

  handleAuthenticationError(error) {
    if (error?.json?.error === "Personal account already exists") {
      Broadcast.publish(BROADCAST_VALUES.OPEN_EXECUTIVE_AUTH_ERROR_MODAL);
      layoutBroadcast.publish(LAYOUT_BROADCAST_VALUES.REROUTE_TO_HOME);
    } else if (error?.json?.error === "Executive account already exists") {
      Broadcast.publish(BROADCAST_VALUES.OPEN_PERSONAL_AUTH_ERROR_MODAL);
      layoutBroadcast.publish(LAYOUT_BROADCAST_VALUES.REROUTE_TO_HOME);
    }
  }

  handleProxyUserLogin(response, shouldSwitchToUser = true) {
    if (!this._isMounted || isEmptyObjectOrFalsey(response) || isErrorResponse(response)) {
      Broadcast.publish(SET_DISAPPEARING_NOTIFICATION_MESSAGE, "Error occurred while authenticating user.");
      return;
    }

    const { user, master_account: masterAccount } = response;
    const email = getUserEmail(user);

    this.determineDarkModeSetting({ masterAccount });

    Sentry.configureScope(function (scope) {
      scope.setUser({ email });
      scope.setTag("email", email);
    });

    if (shouldSwitchToUser) {
      Broadcast.publish("SWITCH_ACCOUNTS", { account: user });
      this.storeLoginResponse({response});
    } else {
      this.storeLoginResponse({
        response,
        skipUpdatingCurrentUser: false,
        shouldSwitchToUser: false,
      });
      Broadcast.publish("SYNC_ALL_LOGGED_IN_CALENDARS_AND_EVENTS", true);
    }

    Promise.resolve(db.fetch(email))
      .then(() => {
        if (!this._isMounted) {
          return;
        }

        if (response) {
          db.fetch(email)
            .open()
            .then(() => {})
            .catch((err) => {
              handleError(err);
            });
        }
      })
      .catch((err) => {
        handleError(err);
      });
  }

  determineDarkModeSetting({ masterAccount }) {
    if (isEmptyObjectOrFalsey(masterAccount)) {
      return;
    }

    if (shouldMatchOSSetting({ masterAccount })) {
      this.determineOSSchemeSetting();
      return;
    }

    const isDarkMode = isUserInDarkMode({ masterAccount });

    if (isDarkMode) {
      this.setDarkMode();
    } else {
      this.setLightMode();
    }
  }

  determineOSSchemeSetting() {
    if (!window.matchMedia) {
      this.setLightMode();
      return;
    }

    const isOSDarkMode = isOSSchemeDarkMode();

    if (isOSDarkMode) {
      this.setDarkMode();
    } else {
      this.setLightMode();
    }
  }

  setDarkMode() {
    if (!this.props.isDarkMode) {
      this.props.turnOnDarkMode();
    }

    loadTheme(DARK_MODE_THEME);

    const domElement = document.getElementById(WEB_STYLES_ID);
    if (domElement) {
      domElement.innerHTML = `body {
        background-color: ${DARK_MODE_BACKGROUND_COLOR};
      }`;
    }
  }

  setLightMode() {
    if (this.props.isDarkMode) {
      this.props.turnOffDarkMode();
    }

    loadTheme(LIGHT_MODE_THEME);

    const domElement = document.getElementById(WEB_STYLES_ID);
    if (domElement) {
      domElement.innerHTML = `body {
        background-color: #FFFFFF;
      }`;
    }
  }

  handleLoginResponse(response) {
    if (!this._isMounted) {
      return;
    }

    if (isEmptyObjectOrFalsey(response) || response.error || !response.user) {
      // handle error
      if (this.shouldShowFullLoadingScreenPendingBackendResponse()) {
        // remove loading page
        this.resetFullScreenLoadingScreen();
      }
      return;
    }
    removeSavedSSOLoginToken();

    const { user, master_account } = response;

    if (isUserMaestroUser(master_account)) {
      // only hide monthly calendar if user is maestro user and is coming from logged out state
      this.hideMonthlyCalendar();

      if (!isEmptyObjectOrFalsey(response?.connected_account_tokens)) {
        this.props.setConnectedAccountTokens(response?.connected_account_tokens);
      }
    }

    const { email } = user;
    if (isShortWindowFetchUser(user)) {
      const {
        setIsShortendWindow,
      } = this.props.appSettingsStore;
      setIsShortendWindow(true);
    }

    identifyMixPanel({masterAccount: master_account});

    if (getAccountHasSelfServeAccess(master_account)) {
      this.setLocalAccessForSelfServePermission();
    }

    Promise.resolve(db.fetch(email))
      .then(() => {
        if (!this._isMounted) {
          return;
        }

        AppBroadcast.publish("UPDATE_MASTER_ACCOUNT", master_account);
        this.determineDarkModeSetting({ masterAccount: master_account });

        this.updateAppTimeZones({
          inputMasterAccount: master_account,
          inputUser: user,
        });

        Sentry.configureScope(function (scope) {
          scope.setUser({ email: email });
          scope.setTag("email", email);
        });

        db.fetch(email)
          .open()
          .then(() => {
            if (!this._isMounted) {
              return;
            }

            response.client_version &&
              localData(LOCAL_DATA_ACTION.SET, "backendAppVersion", response.client_version);
            this.storeLoginResponse({response});
            routeBroadcast.publish(ROUTE_BROADCAST_VALUES.ROUTE_ON_AUTH, master_account);
            if (isMobile()) {
              sendMobileSignUpEmail({user});
            }
          })
          .catch((err) => {
            handleError(err);
          });
      })
      .catch((err) => {
        handleError(err);
      });
  }

  updateAppTimeZones({ inputMasterAccount, inputUser }) {
    const { masterAccount } = this.props.masterAccount;
    const defaultUserTimeZone = getDefaultUserTimeZone({
      masterAccount: inputMasterAccount ?? masterAccount,
      user: inputUser ?? this.props.currentUser,
    });

    this.props.setTimeZone(defaultUserTimeZone);
    this.props.setDefaultBrowserTimeZone(defaultUserTimeZone);
  }

  onLoginSuccessOutlook({
    response,
    accountType = null,
    isDesktopLogin = false,
    isFromOnboarding = false,
    isMagicLink = false,
    shouldSkipMagicLinkUserCheck = false,
  }) {
    const {
      currentUser,
    } = this.props;
    const {
      setSlotsVersion,
    } = this.props.appSettingsStore;
    setSlotsVersion(LATEST_SLOTS_VERSION);
    this.responseHasUserEmail(response) &&
      this.changeTabLabel(response.user.email);

    !isEmptyObjectOrFalsey(currentUser)
      ? this.addAdditionalAccountOutlook({
        authCode: response,
        accountType,
        isDesktopLogin,
        isFromOnboarding,
        isMagicLink,
        shouldSkipMagicLinkUserCheck, // Only allowed to skip with correct user signed in
      })
      : this.loginFromLoggedOutStateOutlook({
        authCode: response,
        accountType,
        isDesktopLogin,
        isMagicLink,
      });
  }

  shouldShowFullLoadingScreenPendingBackendResponse() {
    const {
      hasReceivedLoginCode,
      isStillPendingInitialCalendarSync,
    } = this.props.accountActivity;
    return shouldShowFullLoadingScreenPendingBackendResponse({
      hasReceivedLoginCode,
      isStillPendingInitialCalendarSync,
    }) && !isMobile();
  }

  addAdditionalAccountOutlook({
    authCode,
    accountType,
    isDesktopLogin,
    isFromOnboarding,
    isMagicLink,
    shouldSkipMagicLinkUserCheck,
  }) {
    trackEvent({
      category: "login",
      action: "add_additional_account",
      label: "login_page",
      userToken: getUserToken(this.props.currentUser),
    });
    const { masterAccount } = this.props.masterAccount;
    const { currentUser, magicLinkToken } = this.props;
    const isUserLoggingInFromOnboardingFlow = isOnboardingMode();

    const payloadData = {
      body: JSON.stringify({
        code: authCode,
        desktop: isDesktopLogin,
        additional_scopes: OUTLOOK_ADDITIONAL_SCOPES,
        /* Backend checks onboarding first so we need to make sure we're not in magicLink */
        is_from_onboarding: isFromOnboarding && !isMagicLink,
        /* Only add the token if we don't want to skip the user check */
        magic_link_token: isMagicLink && !shouldSkipMagicLinkUserCheck ? magicLinkToken : undefined,
        /* Need this since: */
        /* 1) We handle Outlook redirect_uri differently */
        /* 2) We don't pass in token to differentiate magic_link */
        is_from_magic_link: isMagicLink,
        sso_login_token: getSavedSSOLoginToken(),
      }),
      credentials: "include",
      headers: {},
    };

    const params = {
      master_account_id: isUserFromMagicLink({ user: currentUser }) ?
        masterAccount.id :
        currentUser.master_account_id,
    };

    const queryParams = constructQueryParams(params);
    const path =
      accountType === MAESTRO_ACCOUNT_TYPES.SCHEDULER_FOR_OTHERS
        ? "delegated_users/outlook_authenticate"
        : "outlook/authenticate_additional_user";
    const url = `${constructRequestURL(path, isVersionV2())}?${queryParams}`;

    return fetcherPost({
      authorizationRequired: true,
      connectedAccountToken: getUserConnectedAccountToken({ user: currentUser }),
      email: getUserEmail(currentUser),
      payloadData,
      url,
      onError: this.handleAuthenticationError,
    })
      .then((response) => {
        if (!this._isMounted || isEmptyObjectOrFalsey(response) || response.error) {
          return;
        }

        const { user, master_account } = response;
        const { email } = user;

        if (!isUserLoggingInFromOnboardingFlow) {
          this.determineDarkModeSetting({ masterAccount: master_account });
          Sentry.configureScope(function (scope) {
            scope.setUser({ email });
            scope.setTag("email", email);
          });
          this.updateAppTimeZones({
            inputMasterAccount: master_account,
            inputUser: user,
          });
        }

        this.storeLoginResponse({
          response,
          skipUpdatingCurrentUser: isUserLoggingInFromOnboardingFlow
        });
        if (!isUserLoggingInFromOnboardingFlow) {
          Broadcast.publish("SWITCH_ACCOUNTS", { account: user });
          routeBroadcast.publish(ROUTE_BROADCAST_VALUES.ROUTE_ON_AUTH);
        }

        Promise.resolve(db.fetch(email))
          .then(() => {
            if (!this._isMounted) {
              return;
            }

            if (response) {
              db.fetch(email)
                .open()
                .then(() => {})
                .catch((err) => {
                  handleError(err);
                });
            }
          })
          .catch((err) => {
            handleError(err);
          });
      })
      .catch((err) => {
        handleError(err);
      });
  }

  hideMonthlyCalendar() {
    const { setIsHideMonthlyCalendar } = this.props.appSettingsStore;
    setIsHideMonthlyCalendar(true);
  }

  loginFromLoggedOutStateOutlook({
    authCode,
    accountType,
    isDesktopLogin = false,
    isMagicLink = false,
  }) {
    this.showFullScreenLoadingScreen();

    const path = accountType ? "authenticate_outlook_scheduler_for_others" : "outlook/authenticate";
    const url = constructRequestURL(path, true);
    const referral_code = getReferralCode();
    const { magicLinkToken } = this.props;
    const { hasSelfServeAccess } = this.props.permissionsStore;
    const {
      referral,
      utmSource,
      promo,
      affiliate,
      attribution,
      utmMedium,
      utmCampaign,
    } = this.props.userCodes;
    const body = omitNullOrUndefinedProps({
      code: authCode,
      referral_code,
      desktop: isDesktopLogin,
      has_access_to_self_serve: hasSelfServeAccess,
      referral,
      promo,
      affiliate,
      attribution,
      additional_scopes: OUTLOOK_ADDITIONAL_SCOPES,
      magic_link_token: isMagicLink ? magicLinkToken : null,
      sso_login_token: getSavedSSOLoginToken(),
    });

    const payloadData = {
      body: JSON.stringify(body),
      credentials: "include",
    };

    Fetcher.post(url, payloadData, false)
      .then((response) => {
        this.handleLoginResponse(response);
        this.sendUTMParamsToTracking({
          utmMedium,
          utmCampaign,
          utmSource,
          referral,
          affiliate,
          promo,
          attribution,
          additional_scopes: OUTLOOK_ADDITIONAL_SCOPES,
          inputUser: response?.user,
        });
      })
      .catch((error) => {
        handleError(error);
      });
  }

  setLocalAccessForSelfServePermission() {
    const {
      setHasSelfServeAccess,
      setHideFirstPageOfOnboarding,
      hasSelfServeAccess,
      hideFirstPageOfOnboarding,
    } = this.props.permissionsStore;
    if (hasSelfServeAccess && hideFirstPageOfOnboarding) {
      // no point in updating again
      return;
    }

    setHasSelfServeAccess(true);
    setHideFirstPageOfOnboarding(true);
  }

  loginFromLoggedOutState({ response, accountType, isDesktopLogin, isMagicLink }) {
    this.showFullScreenLoadingScreen();
    const { magicLinkToken } = this.props;
    const { hasSelfServeAccess } = this.props.permissionsStore;

    const path =
      accountType && accountType === MAESTRO_ACCOUNT_TYPES.MYSELF
        ? "users/authenticate_scheduler_for_others"
        : "google/authenticate";
    const url = constructRequestURL(path);

    const referral_code = getReferralCode();
    const {
      referral,
      utmSource,
      promo,
      affiliate,
      attribution,
      utmMedium,
      utmCampaign,
    } = this.props.userCodes;

    const body = omitNullOrUndefinedProps({
      ...response,
      referral_code,
      redirect_uri: getGoogleRedirectURL({ isDesktopLogin, isMagicLink }), // can not login in for first time in onboarding mode
      ...{
        has_access_to_self_serve: hasSelfServeAccess,
        referral,
        promo,
        affiliate,
        attribution,
      },
      magic_link_token: isMagicLink ? magicLinkToken : null,
      sso_login_token: getSavedSSOLoginToken(),
    });

    const payloadData = {
      body: JSON.stringify(body),
      credentials: "include",
    };

    Fetcher.post(url, payloadData, false)
      .then((response) => {
        this.handleLoginResponse(response);
        this.sendUTMParamsToTracking({
          utmMedium,
          utmCampaign,
          utmSource,
          referral,
          affiliate,
          promo,
          attribution,
          inputUser: response?.user,
        });
      })
      .catch((err) => {
        handleError(err);
      });
  }

  resetFullScreenLoadingScreen() {
    if (!this._isMounted) {
      return;
    }
    const {
      resetAccountActivity,
    } = this.props.accountActivity;
    resetAccountActivity();
  }

  storeLoginResponse({
    response,
    skipUpdatingCurrentUser=false,
    shouldSwitchToUser=true,
  }) {
    const { user, domain, connected_users, connected_user_domains } = response;
    const { allLoggedInUsers, setAllLoggedInUsers } =
      this.props.allLoggedInUsers;
    const { allUserDomains, setAllUserDomains } = this.props.allUserDomains;

    if (!skipUpdatingCurrentUser) {
      setCurrentUserEmail(getUserEmail(user));
    }
    setDebounceBackendTimer();

    if (shouldSwitchToUser) {
      this.props.storeUserData(user);
    }
    
    // set up all logged in calendars

    let updatedUsers;
    if (skipUpdatingCurrentUser) {
      updatedUsers = [
        ...(connected_users?.length > 0 ? connected_users : []),
        user,
      ];
    } else {
      updatedUsers = [
        user,
        ...(connected_users?.length > 0 ? connected_users : []),
      ];
    }
    const updatedAllLoggedInUsers = updateAllLoggedInUsers(allLoggedInUsers, updatedUsers);
    setAllLoggedInUsers(updatedAllLoggedInUsers);

    let shouldUpdateDomains = false;
    const updatedAllUserDomains = produce(allUserDomains, (draftState) => {
      if (domain) {
        shouldUpdateDomains = true;
        draftState[user.email] = domain;
      }

      if (!isEmptyObjectOrFalsey(connected_user_domains)) {
        shouldUpdateDomains = true;
        // Backend returns an object of email: domain where email is the key and domain is the value
        Object.keys(connected_user_domains).forEach((emailKey) => {
          draftState[emailKey] = connected_user_domains[emailKey];
        });
      }
    });

    if (shouldUpdateDomains) {
      setAllUserDomains(updatedAllUserDomains);
    }

    if (skipUpdatingCurrentUser) {
      // do nothing
    } else if (this.state.isElectron && window && window.vimcal) {
      window.vimcal.onLoginSuccess();
    }

    return user;
  }

  changeTabLabel(email) {
    let tabLabel = document.getElementById("tab-label");

    if (!email) {
      tabLabel.innerHTML = "Vimcal";
      tabLabel = null;
      return;
    }

    tabLabel.innerHTML = `${email} | Vimcal`;
    tabLabel = null;
  }

  setShouldRefreshApp() {
    this.setState({ shouldRefresh: true });
  }

  checkForceAppRefresh() {
    if (
      isValidJSDate(this._lastRefreshedTime) &&
      differenceInHours(new Date(), this._lastRefreshedTime) >= 60
    ) {
      this.refreshApp();
    }
  }

  onReceiveAppVersionNumber(event, data) {
    // To make sure app has latest web version
    if (!data || !this.state.isElectron) {
      return;
    }

    const currentVersion = localData(LOCAL_DATA_ACTION.GET, ELECTRON_APP_VERSION_NUMBER);
    if (!currentVersion) {
      localData(LOCAL_DATA_ACTION.SET, ELECTRON_APP_VERSION_NUMBER, data);
    } else if (data !== currentVersion) {
      localData(LOCAL_DATA_ACTION.SET, ELECTRON_APP_VERSION_NUMBER, data);
      triggerRefreshWithOnlineCheck();
    }
  }

  handleVisibilityChange() {
    if (isInWaitingForSignupPage() && getUserEmail(this.props.currentUser)) {
      // only check if logged in
      backendBroadcasts.publish("GET_LATEST_MASTER_ACCOUNT", true);
      return;
    }

    if (!document.hidden) {
      // only check refresh on show
      this.setTabID();
    } else {
      // only refresh after it's hidden
      this.checkForRefresh();
      contactBroadcast.publish(CONTACT_BROADCAST_VALUES.FETCH_CONTACTS);
    }
  }

  checkForDesktopUpdates() {
    if (isElectron() && window?.vimcal) {
      window.vimcal.checkForUpdates();
    }
  }

  checkForRefresh() {
    if (!this._isMounted) {
      return;
    }

    if (this.state.shouldRefresh) {
      this.refreshApp();
    } else if (!this._lastRefreshedTime || !isValid(this._lastRefreshedTime)) {
      this._lastRefreshedTime = new Date();
    } else if (!this.state.isElectron) {
      // refresh more often for browser since it doesn't come to the forefront
      if (differenceInHours(new Date(), this._lastRefreshedTime) >= 24) {
        this.refreshApp();
      }
    } else if (differenceInHours(new Date(), this._lastRefreshedTime) >= 50) {
      this.refreshApp();
    }
  }

  refreshApp() {
    triggerRefreshWithOnlineCheck();
  }

  OSColorSchemeListener(e) {
    const { masterAccount } = this.props.masterAccount;
    if (!shouldMatchOSSetting({ masterAccount })) {
      return;
    }

    const newColorScheme = e?.matches ? DARK_SCHEME : LIGHT_SCHEME;

    if (newColorScheme === DARK_SCHEME) {
      this.setDarkMode();
    } else {
      this.setLightMode();
    }
  }

  zoomLogin() {
    if (window.opener) {
      let searchParams = new URL(window.location).searchParams;
      window.opener.postMessage({
        zoom_logged_in: searchParams.get("zoom_logged_in"),
        zoom_link: searchParams.get("zoom_link"),
        default_zoom_mode: searchParams.get("default_zoom_mode"),
      });
    }

    window.close();
    return null;
  }

  handleResize() {
    const updatedIsBeyondMinimumWidth = isBeyondMinimumWidth();
    if (updatedIsBeyondMinimumWidth !== this.state.isBeyondMinimumWidth) {
      this.setState({ isBeyondMinimumWidth: updatedIsBeyondMinimumWidth });
    }
  }

  setUserCodes() {
    if (isOpenSelfServeLink()) {
      this.setLocalAccessForSelfServePermission();
    }

    const {
      referral,
      utmSource,
      promo,
      affiliate,
      attribution,
      utmMedium,
      utmCampaign,
      from,
    } = getUserCodes();

    const { setUserCodes } = this.props.userCodes;

    setUserCodes({
      referral,
      promo,
      affiliate: window.Rewardful?.referral ?? affiliate,
      attribution,
      utmMedium,
      utmCampaign,
      utmSource,
      from,
    });
  }

  sendUTMParamsToTracking({
    utmMedium,
    utmCampaign,
    utmSource,
    referral,
    affiliate,
    promo,
    attribution,
    inputUser // passing in current user so not dependent on current user getting set
  }) {
    const userToken = getUserToken(inputUser || this.props.currentUser);

    if (utmMedium) {
      trackEvent({
        category: "utmMedium", // formerly just "utm"
        action: "login",
        label: utmMedium,
        userToken,
      });
    }

    if (utmCampaign) {
      trackEvent({
        category: "utmCampaign", // formerly just "utm"
        action: "login",
        label: utmCampaign,
        userToken,
      });
    }

    if (utmSource) {
      trackEvent({
        category: "utmSource", // formerly just "utm"
        action: "login",
        label: utmSource,
        userToken,
      });
    }

    if (referral) {
      trackEvent({
        category: "referral",
        action: "login",
        label: referral,
        userToken,
      });
    }

    if (affiliate) {
      trackEvent({
        category: "affiliate",
        action: "login",
        label: affiliate,
        userToken,
      });
    }

    if (promo) {
      trackEvent({
        category: "promo",
        action: "login",
        label: promo,
        userToken,
      });
    }

    if (attribution) {
      trackEvent({
        category: "attribution",
        action: "login",
        label: attribution,
        userToken,
      });
    }
  }

  sanityCheckDataIntegrity() {
    try {
      const {
        allLoggedInUsers,
        setAllLoggedInUsers,
      } = this.props.allLoggedInUsers;
      if (isEmptyArrayOrFalsey(allLoggedInUsers)) {
        return;
      }
      const hasError = allLoggedInUsers.some(user => !isValidUser(user));
      if (!hasError) {
        return;
      }
      setAllLoggedInUsers(allLoggedInUsers.filter(isValidUser));
    } catch (error) {
      handleError(error);
    }
  }

  async checkForMacArch() {
    try {
      if (!isMac()) {
        return;
      }
      // set the cache for isMacMSeries
      await isMacMSeries();
    } catch (error) {
      handleError(error);
    }
  }

  loadDesktopBridge() {
    if (!isElectron()) {
      return;
    }
    if (!window?.vimcal) {
      if (this._desktopBridgeAttempt > 100) {
        return;
      }
      clearTimeout(this._setDesktopBridgeTimeout);
      this._desktopBridgeAttempt += 1;
      this._setDesktopBridgeTimeout = setTimeout(() => {
        if (!this._isMounted) {
          return;
        }
        this.loadDesktopBridge();
      }, SECOND_IN_MS * (0.1 * this._desktopBridgeAttempt));
      return;
    }
    this._desktopBridgeAttempt = 0;
    window.vimcal.getElectronAppNumber();
    window.vimcal.onReceiveElectronAppNumber(this.onReceiveAppVersionNumber);
    window.vimcal.onLogin(this.onElectronLogin);
    if (window.vimcal.onSSOTokenLogin) {
      window.vimcal.onSSOTokenLogin(this.onElectronSSOTokenLogin);
    }
    if (window.vimcal.onOutlookLogin) {
      window.vimcal.onOutlookLogin(this.onElectronOutlookLogin);
    }
    window.vimcal.onChangeVisibility(this.checkForElectronVisibility);
    window.vimcal.onZoomLogin(this.onElectronZoomLogin);

    if (!isMac()) {
      // do nothing here
    } else if (window?.vimcal?.setAppIconForDayOfMonth) {
      const dayOfMonth = new Date().getDate();
      window.vimcal.setAppIconForDayOfMonth(dayOfMonth);
    } else if (window?.vimcal?.updateAppIconForDayOfMonth) {
      window.vimcal.updateAppIconForDayOfMonth();
    }

    this.checkForDesktopUpdates();
  }
}

function mapStateToProps(state) {
  const { currentUser, isDarkMode } =
    state;

  return {
    currentUser,
    isDarkMode,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    storeUserData: (user) => dispatch({ data: user, type: "STORE_USER_DATA" }),
    setDefaultBrowserTimeZone: (timeZone) =>
      dispatch({ data: timeZone, type: "STORE_DEFAULT_BROWSER_TIME_ZONE" }),
    turnOnDarkMode: () => dispatch({ type: "TURN_ON_DARK_MODE" }),
    turnOffDarkMode: () => dispatch({ type: "TURN_OFF_DARK_MODE" }),
    setTimeZone: (timeZone) =>
      dispatch({ data: timeZone, type: "SET_TIME_ZONE" }),
  };
}

const withStore = (BaseComponent) => (props) => {
  const store = useTabID();
  const allLoggedInUsers = useAllLoggedInUsers();
  const allUserDomains = useAllUserDomains();
  const masterAccount = useMasterAccount();
  const permissionsStore = usePermissionsStore();
  const userCodes = useUserCodes();
  const accountActivity = useAccountActivity();
  const appSettingsStore = useAppSettings();
  const { magicLinkToken, setConnectedAccountTokens } = useMagicLink();

  return (
    <BaseComponent
      {...props}
      useTabID={store}
      allLoggedInUsers={allLoggedInUsers}
      allUserDomains={allUserDomains}
      masterAccount={masterAccount}
      permissionsStore={permissionsStore}
      userCodes={userCodes}
      accountActivity={accountActivity}
      appSettingsStore={appSettingsStore}
      magicLinkToken={magicLinkToken}
      setConnectedAccountTokens={setConnectedAccountTokens}
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withStore(App));
