export const SUPPORTED_IMAGE_FILE_TYPES = ["image/jpeg", "image/jpg", "image/png"];
export const SUPPORTED_VIDEO_FILE_TYPES = ["video/mpeg", "video/mp4", "video/quicktime"];
export const SUPPORTED_COMPRESSED_FILE_TYPES = ["application/zip", "application/x-zip-compressed"];

/**
 * Note: this does not account for complex extensions like .tar.gz.
 */
export function getFileExtension(file: File) {
  const fileNameParts = file.name.split(".");
  return fileNameParts.length > 1 ? fileNameParts[fileNameParts.length - 1] : "";
}

export function megabytesToBytes(megabytes: number) {
  return megabytes * 2 ** 20;
}

export function isFileSizeUnderMBLimit(file: File, limit: number) {
  return file.size <= megabytesToBytes(limit);
}

export function downloadInMemoryFile(file: File) {
  const downloadElement = document.createElement("a");
  downloadElement.style.display = "none";

  downloadElement.download = file.name;
  downloadElement.href = URL.createObjectURL(file);
  downloadElement.click();
}

interface CreateFileNameWithPrefixAndSuffixOptions {
  fileName: string
  prefix?: string
  suffix?: string
}

/**
 * Adds the prefix to the start of the file name, and adds the suffix right before the file extension.
 */
export function createFileNameWithPrefixAndSuffix({ fileName, prefix, suffix }: CreateFileNameWithPrefixAndSuffixOptions) {
  const fileNameParts = fileName.split(".");

  if (prefix) {
    fileNameParts[0] = prefix + fileNameParts[0];
  }

  if (suffix) {
    const indexToUpdate = Math.max(fileNameParts.length - 2, 0);
    fileNameParts[indexToUpdate] = fileNameParts[indexToUpdate] + suffix;
  }

  return fileNameParts.join(".");
}
