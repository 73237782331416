import {
  getEmailBasedOnCalendarId,
  getTimeInAnchorTimeZone,
  isAfterMinute,
  isBeforeMinute,
  guessTimeZone,
  isSameOrBeforeMinute,
  isSameOrAfterMinute,
  isEditable,
  dimDarkModeColor,
  shouldReduceTextSize,
  isUserOnlyPersonWhoAcceptedAndRestDeclined,
  isOrganizer,
  createFadedColorIfIndexDoesNotExist,
  getFadedColor,
  GetLetterAndNumberHotKeyCombinations,
  isSameOrAfterDay,
  removeDuplicatesFromArray,
  NO_RESPONSE_NEEDED,
  formatEventForReactBigCalendar,
  generateConferenceRooms,
  hasPermissionToModify,
  sendMessageToSentry,
  replaceStringInLinkTag,
} from "../services/commonUsefulFunctions";
import GoogleCalendarService, {
  ATTENDEE_EVENT_NEEDS_ACTION,
  TEMPORARY,
  AVAILABILITY,
  OUT_OF_OFFICE_EVENT_TYPE,
  PRIVATE,
  SELF_RESPONSE_CANCELLED,
  FREE_DURING_EVENT,
  ATTENDEE_EVENT_ATTENDING,
  ATTENDEE_EVENT_TENTATIVE,
  ATTENDEE_EVENT_DECLINED,
  GOOGLE_EVENT_VISIBILILITY_OPTIONS,
  OUTLOOK_EVENT_VISIBILILITY_OPTIONS,
} from "../services/googleCalendarService";
import {
  parseISO,
  subMinutes,
  addMinutes,
  differenceInMinutes,
  parseJSON,
  differenceInMonths,
  startOfDay,
  endOfDay,
  isSameDay,
} from "date-fns";
import {
  DARK_MODE_BACKGROUND_COLOR,
  MERGED_EVENTS,
  SELECT_AVAILABILITY_COLOR,
  FADED_GREY_TEXT_COLOR_DARK_MODE,
  DEFAULT_PRIMARY_CALENDAR_COLOR,
  EXCLUDED_DOMAINS,
  RSVP_WITHOUT_SHOW_AS,
  FADED_WHITE_MODE_COLOR_FOR_DIMMED_PAST_EVENTS,
  DEFAULT_FONT_COLOR,
} from "../services/globalVariables";
import GoogleColors from "../services/googleColors";
import { readability, TinyColor } from "@ctrl/tinycolor";
import { isEventSlotAllDayEvent } from "./rbcFunctions";
import {
  determineCalendarColor,
  doesCalendarHaveEditableRole,
  getAllEditableCalendars,
  getCalendarFromEmail,
  getCalendarFromUserCalendarID,
  getCalendarUserEmail,
  isCalendarOutlookCalendar,
  isResourceCalendarEmail,
  isValidCalendar,
} from "./calendarFunctions";
import {
  getEventAttendeeComment,
  getEventAttendees,
  getEventCategories,
  getEventColorHex,
  getEventColorID,
  getEventDescription,
  getEventEnd,
  getEventExtendedProperties,
  getEventGuestPermissions,
  getEventID,
  getEventLocation,
  getEventMasterEventID,
  getEventOrganizer,
  getEventProvider,
  getEventReminders,
  getEventStart,
  getEventStatus,
  getEventTitle,
  getEventTransparency,
  getEventType,
  getEventUpdatedAt,
  getEventUserCalendarID,
  getEventUserEmail,
  getEventUserEventID,
  getEventVisibility,
  getOutlookEventShowAs,
  getOutlookResponseRequested,
  GUESTS_CAN_MODIFY,
  GUESTS_CAN_SEE_OTHER_GUESTS,
  isAllDayOutlookEvent,
} from "../services/eventResourceAccessors";
import {
  getCalendarOwnerEmail,
} from "../services/calendarAccessors";
import { CALENDAR_PROVIDERS } from "./vimcalVariables";
import { isVersionV2 } from "../services/versionFunctions";
import { useAvailabilityStore } from "../services/stores/availabilityStores";
import {
  getEventTagColor,
  getMatchingUIUserForEvent,
  shouldUseTagColorForGoogleEvent,
} from "./tagsFunctions";
import { parseOutlookAllDayEvent } from "./timeFunctions";
import { getMatchingUserFromAllUsers, getSelectedUserName, getUserEmail } from "./userFunctions";
import { shouldUseTagColorAsEventColorForOutlookEvent } from "./outlookFunctions";
import { isMeetWithEvent } from "./meetWithFunctions";
import { isEmptyArray } from "./arrayFunctions";
import { isOutlookShowAsTentativeEvent } from "../resources/outlookVariables";
import { getObjectEmail } from "./objectFunctions";
import { getAllCalendarsFromStore } from "./zustandFunctions";
import { OUTLOOK_COLORS, OUTLOOK_OOO_COLOR } from "../services/outlookColors";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey } from "../services/typeGuards";
import {
  getRecentContacts,
  getRecentlySearchedContacts,
} from "./stateManagementFunctions";
import { getEmailDomain, isSameEmail, isValidEmail, lowerCaseAndTrimString, lowerCaseAndTrimStringWithGuard } from "./stringFunctions";
import { createUUID } from "../services/randomFunctions";
import classNames from "classnames";
import { sanitizeStringAndLinkify } from "./jsVariables";
import { isWBEmail } from "./featureFlagFunctions";
import { isFakeMimicEvent } from "./mimicEventUpdate";
import { getEventHoldDetails } from "../services/holdFunctions";
import { filterOutResourceAttendees } from "../services/attendeeFunctions";
import mainCalendarBroadcast from "../broadcasts/mainCalendarBroadcast";
import { BACKEND_BROADCAST_VALUES, MAIN_CALENDAR_BROADCAST_VALUES } from "./broadcastValues";
import backendBroadcasts from "../broadcasts/backendBroadcasts";
import { addEventsIntoIndexDB } from "./dbFunctions";
import { getMatchingExecOrNormalUserFromCalendar } from "../services/maestroFunctions";

let _staticEventIndex = {};
let _defaultFontColorOnBackgroundColorCache = {};
let _color_contrast_against_white_cache = {};
let _color_contrast_against_black_cache = {};

// WCAG 2.0 level AA requires contrast ratio of at least:
// 4.5:1 for normal text
// 3:1 for large text
// AAA requires 7
// AA requires 4.5
export const READABILITY_CONTRAST_MINIMUM = 4.5; // this is the minimum contrast ratio for readability

const EVENT_FONT_COLOR = {
  WHITE: "white",
  DARK: DEFAULT_FONT_COLOR, // if this is not enough contrast
  DARKER: DARK_MODE_BACKGROUND_COLOR,
};

export const TYPE_FOCUS_BLOCK = "focus-block";

/**
 * Take a grouped attendee from the select attendee auto-complete dropdown and
 * split into individual attendees, matching names and emails.
 *
 * @param {{emailArray?: string[], label: string, name: string, value: string}} groupedAttendees
 * @param {User} currentUser
 * @param {Record<string, string>} emailToNameIndex
 */
export function splitGroupedAttendees(
  groupedAttendees,
  currentUser,
  emailToNameIndex = {}
) {
  if (
    isEmptyArrayOrFalsey(groupedAttendees.emailArray) ||
    groupedAttendees.emailArray.length === 1
  ) {
    return [groupedAttendees];
  }

  const splitPattern = /(?:,\s+)|(?:,?\s+&\s+)/;

  const attendeeCount = groupedAttendees.emailArray.length;
  let splitNames =
    attendeeCount === 2
      ? groupedAttendees.name.trim().split(splitPattern)
      : groupedAttendees.name
        .trim()
        .split(splitPattern)
        .map((n) => ((n ?? "").startsWith("& ") ? n.substring(2) : n));

  if (splitNames.length !== attendeeCount) {
    sendMessageToSentry(
      "Error splitting attendees",
      groupedAttendees.emailArray.join(", ")
    );
    splitNames = [];
  }

  const recentContacts = [
    ...getRecentContacts(currentUser),
    ...getRecentlySearchedContacts(currentUser),
  ];

  return groupedAttendees.emailArray.map((email, index) => {
    const name =
      splitNames[index] ??
      emailToNameIndex[email] ??
      recentContacts.find((c) => c.email === email)?.name ??
      "";
    return {
      label: name || email,
      name,
      value: email,
    };
  });
}

export function getListOfEmailsFromString(string, filterEmail = []) {
  if (!string || string.length === 0) {
    return;
  }

  let attendeesStringFiltered = string
    .trim()
    .replace(/,/g, " ")
    .replace(/\t/g, " ")
    .replace(/</g, " ")
    .replace(/>/g, " ")
    .toLowerCase();

  let attendeesArray = attendeesStringFiltered.split(" ");

  if (attendeesArray.length > 1) {
    return attendeesArray.filter(
      (e) => isValidEmail(e) && !filterEmail.includes(e)
    );
  }

  return [attendeesStringFiltered];
}

export function doesEventAskForResponse(param) {
  if (isEmptyObjectOrFalsey(param)) {
    // safety check
    return false;
  }

  const { event, allCalendars } = param;

  if (isEmptyObjectOrFalsey(event)) {
    return false;
  }

  const email = getEmailBasedOnCalendarId(event, allCalendars);
  const attendees = getEventAttendees(event);
  if (attendees && email) {
    const attendeeEmails = attendees
      .filter((a) => a?.email)
      .map((a) => a.email.toLowerCase().trim());
    const formattedEmail = email.toLowerCase().trim();

    return attendeeEmails.includes(formattedEmail);
  }

  return false;
}

export function determineAdditionalBroadcastMessaging(hideRender) {
  return hideRender ? "_popup" : "";
}

function createAvailabilitySlot({
  slot,
  timeZone,
  currentTimeZone,
}) {
  const result = {
    isTemporary: true,
    isAvailability: true,
    eventStart:
      timeZone !== currentTimeZone
        ? getTimeInAnchorTimeZone(
            parseISO(slot.eventStart),
            timeZone,
            currentTimeZone
          )
        : parseISO(slot.eventStart),
    index: slot.index,
    eventEnd:
      timeZone !== currentTimeZone
        ? getTimeInAnchorTimeZone(
            parseISO(slot.eventEnd),
            timeZone,
            currentTimeZone
          )
        : parseISO(slot.eventEnd),
    rbcEventEnd:
      timeZone !== currentTimeZone
        ? getTimeInAnchorTimeZone(
            parseISO(slot.rbcEventEnd),
            timeZone,
            currentTimeZone
          )
        : parseISO(slot.rbcEventEnd),
    status: GoogleCalendarService.availability,
    raw_json: {
      ...slot.raw_json,
      ...{ status: GoogleCalendarService.availability },
    },
    id: `last_slot_${createUUID()}`,
  };

  return result;
}

export function getPreviouslySelectedSlots(currentTimeZone, currentUser) {
  if (isEmptyObjectOrFalsey(currentUser)) {
    return;
  }

  const state = useAvailabilityStore.getState();
  const { lastSelectedTimeZone, lastSelectedSlots } = state;

  if (
    isEmptyArrayOrFalsey(lastSelectedSlots) ||
    !lastSelectedTimeZone
  ) {
    return null;
  }
  const guessedTimeZone = guessTimeZone();

  const isTimeAfterNow = (time) => {
    const now = new Date();
    if (currentTimeZone === guessedTimeZone) {
      return isAfterMinute(time, now);
    }
    return isAfterMinute(
      getTimeInAnchorTimeZone(
        time,
        currentTimeZone,
        guessedTimeZone,
      ),
      new Date()
    );
  };

  const slots = [];

  lastSelectedSlots.forEach((s, index) => {
    const slot = createAvailabilitySlot({
      slot: s,
      timeZone: lastSelectedTimeZone,
      currentTimeZone,
      index,
    });

    if (isTimeAfterNow(slot.eventEnd)) {
      slots.push(slot);
    }
  });

  if (isEmptyArrayOrFalsey(slots)) {
    return null;
  }

  return slots;
}

export function getNowOrUpcomingEvent(listOfEvents, mainCalendars = []) {
  if (isEmptyArrayOrFalsey(listOfEvents)) {
    return null;
  }

  let upcomingEvent;
  let nowPreviousBar = subMinutes(new Date(), 10);
  let nowLaterBar = addMinutes(new Date(), 10);
  listOfEvents.forEach((e) => {
    if (e.displayAsAllDay) {
      return;
    }

    if (!mainCalendars.includes(getEventUserCalendarID(e))) {
      return;
    }

    if (
      isSameOrBeforeMinute(parseISO(e.defaultStartTime), nowLaterBar) &&
      isSameOrAfterMinute(parseISO(e.defaultEndTime), nowPreviousBar)
    ) {
      upcomingEvent = e;
    } else if (isSameOrAfterMinute(e.eventStart, new Date())) {
      if (!upcomingEvent) {
        upcomingEvent = e;
      } else if (isBeforeMinute(e.eventStart, upcomingEvent.eventStart)) {
        upcomingEvent = e;
      }
    }
  });

  return upcomingEvent;
}

export function determinedMergedEventBackgroundColor(
  event,
  addPadding = false
) {
  return shouldShowTransparentMergedBackground(event)
    ? `border-radius-2px default-background-color ${
        addPadding ? "margin-left-negative-4 pl-1 margin-right-negative-4" : ""
      }`
    : "";
}

export function shouldShowTransparentMergedBackground(event) {
  return (
    isMergedEvent(event) &&
    event?.selfAttendingStatus === ATTENDEE_EVENT_NEEDS_ACTION
  );
}

export function isMergedEvent(event) {
  return event && event[MERGED_EVENTS] && event[MERGED_EVENTS]?.length > 0;
}

export function shouldShowReducedHeightTransparentMergedEvent(event) {
  return (
    shouldShowTransparentMergedBackground(event) &&
    differenceInMinutes(event.eventEnd, event.eventStart) <= 30
  );
}

export function isEventBetween15And30Minutes(event) {
  let minDiff = differenceInMinutes(event.eventEnd, event.eventStart);
  return minDiff <= 30 && minDiff > 15;
}

export function createStaticInformation({
  e,
  currentUser,
  allCalendars,
  where = "",
  outlookCategories,
  allLoggedInUsers,
  masterAccount,
  checkTagColor = false, // if true, we also check tag color and return the tag color in normalColor
  isSchedulingAssistant = false,
}) {
  const protectedAllCalendars = allCalendars ?? getAllCalendarsFromStore();
  const isDisplayEventEditable = (eventCalendarEmail) => {
    const hasPermissionToEditEventOwner = () => {
      return (
        isValidCalendar(protectedAllCalendars[getEventUserCalendarID(e)]) &&
        doesCalendarHaveEditableRole(protectedAllCalendars[getEventUserCalendarID(e)])
      );
    };

    if (isPreviewOutlookEvent(e)) {
      // no reason to return true or false since in reschedule, we explicitly check if it's equal to false
      return;
    }

    // let canPrint = event && event.summaryUpdatedWithVisibility === 'from personal -> invite others and see guest list';
    if (isTemporaryEvent(e)) {
      return true;
    } else if (isOrganizerSelf(e) && hasPermissionToEditEventOwner(e)) {
      return true;
    } else if (
      getEventMasterEventID(e) &&
      getEventAttendees(e)?.length > 0 &&
      !isSameEmail(getObjectEmail(getEventOrganizer(e)), getUserEmail(currentUser))
    ) {
      // if recurring event and you're not organizer -> can not edit (Google bug)
      // https://stackoverflow.com/questions/63259190/an-event-that-is-guests-can-modify-is-not-update-via-google-calendar-api
      // https://issuetracker.google.com/issues/36757203
      return false;
    } else if (
      isEditable({
        event: e,
        allCalendars: protectedAllCalendars,
      })
    ) {
      const eventAttendees = getEventAttendees(e);
      if (!eventAttendees) {
        return true;
      } else if (
        eventAttendees &&
        isOrganizer(eventAttendees, eventCalendarEmail)
      ) {
        return true;
      } else if (getEventGuestPermissions(e, GUESTS_CAN_MODIFY)) {
        return false; // Bug with Google where you can't actually edit events with modify permission
      } else {
        return false;
      }
    } else if (isAvailabilityEvent(e)) {
      return true;
    } else {
      return false;
    }
  };

  const determineOutOfOfficeColor = (color, isDarkMode = false) => {
    if (isDarkMode) {
      return new TinyColor(color).tint(20).shade(40).toHexString();
    }
    return new TinyColor(color).tint(88).toHexString();
  };

  const getCacheToken = (event) => {
    if (isEmptyObjectOrFalsey(event) || !e.uniqueEtag) {
      return "";
    }
    // cache for certain locations
    const { uniqueEtag } = event;

    return where ? `${where}_${uniqueEtag}` : uniqueEtag;
  };

  const determineColor = (event) => {
    const calendarId = getEventUserCalendarID(event) || event.calendarId;
    if (isSchedulingAssistant && isOutlookEvent(event) && isOutOfOfficeEvent(event)) {
      return {
        fadedColor: OUTLOOK_OOO_COLOR,
        normalColor: OUTLOOK_OOO_COLOR,
      };
    }

    if (getEventColorID(event) === "0") {
      // get default calendar color
      const calendarColor = determineCalendarColor(
        protectedAllCalendars[calendarId]
      );

      return {
        fadedColor: isOutOfOfficeEvent(event)
          ? determineOutOfOfficeColor(calendarColor)
          : createFadedColorIfIndexDoesNotExist(calendarColor, true),
        normalColor: createFadedColorIfIndexDoesNotExist(calendarColor, false),
      };
    }

    const outlookCategoryColor = determineOutlookCategoryColor({
      allCalendars,
      event,
      outlookCategories,
    });
    if (outlookCategoryColor) {
      return {
        fadedColor: createFadedColorIfIndexDoesNotExist(
          outlookCategoryColor,
          true
        ),
        normalColor: outlookCategoryColor,
      };
    }
    if (getEventColorHex(event)) {
      const normalColor = getEventColorHex(event);
      return {
        fadedColor: createFadedColorIfIndexDoesNotExist(normalColor, true),
        normalColor,
      };
    }
    if (event.color) {
      return {
        fadedColor: createFadedColorIfIndexDoesNotExist(event.color, true),
        normalColor: event.color,
      };
    }
    if (event.backgroundColor) {
      return {
        fadedColor: createFadedColorIfIndexDoesNotExist(
          event.backgroundColor,
          true
        ),
        normalColor: createFadedColorIfIndexDoesNotExist(
          event.backgroundColor,
          false
        ),
      };
    }
    if (protectedAllCalendars[calendarId]) {
      // calendarID is part of all the primary and secondary calendars that are logged in
      const eventColorID = getEventColorID(event);
      if (eventColorID) {
        return {
          fadedColor: isOutOfOfficeEvent(event)
            ? determineOutOfOfficeColor(
                GoogleColors.primaryEventsColors[eventColorID].color
              )
            : GoogleColors.primaryEventsColors[eventColorID].fadedColor,
          normalColor: GoogleColors.primaryEventsColors[eventColorID].color,
        };
      }
      if (checkTagColor) {
        const calendarColorWithTagCheck = determineEventColor({
          event,
          allCalendars,
          user: getMatchingUIUserForEvent({
            event,
            allCalendars,
            allLoggedInUsers,
            masterAccount,
            currentUser,
          }),
          currentUser,
          allLoggedInUsers,
          outlookCategories,
          masterAccount,
          where: "eventFunctions::createStaticInformation",
        });
        return {
          fadedColor: isOutOfOfficeEvent(event)
            ? determineOutOfOfficeColor(calendarColorWithTagCheck)
            : createFadedColorIfIndexDoesNotExist(calendarColorWithTagCheck, true),
          normalColor: createFadedColorIfIndexDoesNotExist(
            calendarColorWithTagCheck,
            false
          ),
        };
      }

      const calendarColor = determineCalendarColor(
        protectedAllCalendars[calendarId]
      );
      return {
        fadedColor: isOutOfOfficeEvent(event)
          ? determineOutOfOfficeColor(calendarColor)
          : createFadedColorIfIndexDoesNotExist(calendarColor, true),
        normalColor: createFadedColorIfIndexDoesNotExist(
          calendarColor,
          false
        ),
      };
    }

    if (isTemporaryEvent(event)) {
      const eventColor =
        event.backgroundColor || DEFAULT_PRIMARY_CALENDAR_COLOR;

      return {
        fadedColor: createFadedColorIfIndexDoesNotExist(eventColor, true),
        normalColor: createFadedColorIfIndexDoesNotExist(eventColor, false),
      };
    }
    if (isAvailabilityEvent(event)) {
      return {
        fadedColor: SELECT_AVAILABILITY_COLOR,
        normalColor: SELECT_AVAILABILITY_COLOR,
      };
    }
    if (isEventTypeFocusBlock(event)) {
      // return primary calendar user colors
      const matchingCalendar = getCalendarFromEmail({
        email: getUserEmail(currentUser),
        allCalendars: protectedAllCalendars,
        masterAccount,
        allLoggedInUsers,
      });
      const calendarColor = determineCalendarColor(matchingCalendar);
      return {
        fadedColor: getFadedColor(calendarColor),
        normalColor: calendarColor,
      };
    } 
    return {
      fadedColor: getFadedColor(DEFAULT_PRIMARY_CALENDAR_COLOR),
      normalColor: DEFAULT_PRIMARY_CALENDAR_COLOR,
    };
  };

  const cacheToken = getCacheToken(e);
  if (
    !e.temporaryCalendarId &&
    !!cacheToken &&
    !!_staticEventIndex[cacheToken]
  ) {
    // do not store static information for temporary events
    return _staticEventIndex[cacheToken];
  }

  // normalColor, fadedColor, onlyPersonAttending, backgroundColor, bordercolor, selfAttendingStatus

  const eventCalendarEmail =
    e.isTemporary || isMeetWithEvent(e)
      ? e.resourceId
      : getEmailBasedOnCalendarId(e, protectedAllCalendars);

  const selfAttendingStatus = getSelfAttendingStatus(e, eventCalendarEmail);
  const { fadedColor, normalColor } = determineColor(e);

  const darkModeFadedColor = isOutOfOfficeEvent(e)
    ? determineOutOfOfficeColor(normalColor, true)
    : dimDarkModeColor(normalColor);
  const shouldTruncateEvent = shouldReduceTextSize(e, 20);

  const result = {
    isEditable: isDisplayEventEditable(eventCalendarEmail),
    eventCalendarEmail,
    resourceId: getResourceID({
      event: e,
      currentUserEmail: getUserEmail(currentUser),
      eventCalendarEmail,
    }),
    selfAttendingStatus,
    isUserOnlyPersonWhoAcceptedAndRestDeclined:
      isUserOnlyPersonWhoAcceptedAndRestDeclined(e, selfAttendingStatus),
    fadedColor,
    normalColor,
    darkModeFadedColor,
    shouldTruncateEvent,
    userEmail: protectedAllCalendars[getEventUserCalendarID(e)]?.userEmail,
    [RSVP_WITHOUT_SHOW_AS]: getAttendeeStatus(e, eventCalendarEmail),
  };

  if (!e.temporaryCalendarId && !!cacheToken && !isPreviewOutlookEvent(e)) {
    _staticEventIndex[cacheToken] = result;
  }

  return result;
}

export function getResourceID({ event, currentUserEmail, eventCalendarEmail }) {
  if (isMeetWithEvent(event)) {
    return event.resourceId;
  }

  // to see if it's a birthday or holiday calendar
  const isResourceCalendar = isResourceCalendarEmail(eventCalendarEmail);
  if (isResourceCalendar) {
    return getEventUserEmail(event) ?? currentUserEmail;
  }
  return eventCalendarEmail;
}

export function getEventStaticInfo({
  event,
  key,
  currentUser,
  currentUserDefaultColor,
  allCalendars,
  masterAccount,
  allLoggedInUsers,
}) {
  if (event && key in event) {
    return event[key];
  } else {
    /*
      We should never hit this condition since that means we don't have the static information set in the event
     */

    if (!event.start) {
      // if (isTestEnvironment() || isInternalTeamUser(currentUser)) {
      //   console.log("bug_event", event);
      //   console.log("bug_key", key);
      // }
    }
    const staticInfo = createStaticInformation({
      e: event,
      currentUser,
      allCalendars,
      masterAccount,
      allLoggedInUsers,
      where: "eventFunctions",
    });

    const defaultValues = {
      isEditable: false,
      eventCalendarEmail: getUserEmail(currentUser),
      selfAttendingStatus: true,
      isUserOnlyPersonWhoAcceptedAndRestDeclined: false,
      fadedColor: getFadedColor(currentUserDefaultColor),
      normalColor: currentUserDefaultColor,
      [RSVP_WITHOUT_SHOW_AS]: ATTENDEE_EVENT_ATTENDING,
    };

    return staticInfo[key] ?? defaultValues[key];
  }
}

export function determineMergedEventBackground(colorArray) {
  const filteredArray = colorArray?.filter((c) => c) || [];
  if (filteredArray.length < 2) {
    return "";
  }
  let background = "linear-gradient(90deg, ";
  filteredArray.forEach((c, index) => {
    if (index === filteredArray.length - 1) {
      // last element
      background = background + `${c})`;
    } else {
      background = background + `${c}, `;
    }
  });

  return background;
}

export function determineEventColor({
  event,
  allCalendars,
  user,
  currentUser,
  allLoggedInUsers,
  outlookCategories,
  masterAccount,
  where, // for debugging
  shouldPrint, // for debugging
}) {
  const calendarColor = determineCalendarColor(
    allCalendars[getEventUserCalendarID(event)]
  );
  const tagColor = getEventTagColor({
    event,
    user,
    currentUser,
    allLoggedInUsers,
    masterAccount,
    allCalendars,
    shouldPrint,
  });
  if (
    isOutlookEvent(event) &&
    shouldUseTagColorAsEventColorForOutlookEvent({
      event,
      user,
      currentUser,
      allLoggedInUsers,
      masterAccount,
      allCalendars,
    })
  ) {
    return tagColor; // if there's no tags -> use default color
  }

  if (
    shouldUseTagColorForGoogleEvent({
      event,
      allCalendars,
      user,
      currentUser,
      allLoggedInUsers,
      masterAccount,
    })
  ) {
    return tagColor;
  }

  const outlookCategoryColor = determineOutlookCategoryColor({
    allCalendars,
    event,
    outlookCategories,
  });
  const eventColorHex = getEventColorHex(event);
  if (outlookCategoryColor) {
    return outlookCategoryColor;
  } else if (eventColorHex) {
    return eventColorHex;
  } else if (event.color) {
    return event.color;
  } else if (getEventColorID(event)) {
    return GoogleColors.primaryEventsColors[getEventColorID(event)].color;
  } else {
    // base event color on calendar
    return calendarColor;
  }
}

export function determineOutlookCategoryColor({
  allCalendars,
  event,
  outlookCategories,
}) {
  if (!allCalendars || !event || !outlookCategories || !isOutlookEvent(event)) {
    return;
  }

  const calendar = getCalendarFromUserCalendarID({
    allCalendars,
    userCalendarID: getEventUserCalendarID(event),
  });
  if (!calendar) {
    return;
  }

  const ownerEmail = getCalendarOwnerEmail(calendar);
  const userCategories = outlookCategories[ownerEmail] ?? [];
  const colorPresetName = userCategories.find(
    (category) => category.displayName === getEventCategories(event)?.[0]
  )?.color;
  if (colorPresetName in OUTLOOK_COLORS) {
    return OUTLOOK_COLORS[colorPresetName].hex;
  }
}

export function clearEventStaticCache() {
  _staticEventIndex = {};
}

export function isOutOfOfficeEvent(e) {
  return getEventType(e) === OUT_OF_OFFICE_EVENT_TYPE;
}

export function createEventHotKeyLabel(events) {
  if (!events || events.length === 0) {
    return {};
  }

  let keyCombinations = GetLetterAndNumberHotKeyCombinations();
  let combinationMapping = {};

  keyCombinations.forEach((k, index) => {
    if (index < events.length) {
      let handler = k[0] + "+" + k[1];
      let sequentialHandler = k[0] + " " + k[1];
      // let combinationHandler = k[0] + '+' + k[1];
      let sequentialHandlerCap = k[0].toUpperCase() + " " + k[1].toUpperCase();
      // let combinationHandlerCap = k[0].toUpperCase() + '+' + k[1].toUpperCase();

      combinationMapping[getEventUserEventID(events[index])] = {
        handler: handler,
        label: k.toUpperCase(),
        event: events[index],
        sequentialHandler,
        sequentialHandlerCap,
      };
    }
  });

  return combinationMapping;
}

const RECLAIM_EVENT_ID = "reclaim.personalSync.eventId";
export function isReclaimEvent(event) {
  return !!getReclaimEventExtendedProperties(event);
}

export function isReclaimPrivateEventTitle(event) {
  if (!isReclaimEvent(event)) {
    return false;
  }
  return ["personal commitment", "busy"].includes(
    lowerCaseAndTrimString(getEventTitle(event))
  );
}

export function getReclaimEventExtendedProperties(event) {
  const eventExtendedProperties = getEventExtendedProperties(event);
  return eventExtendedProperties?.private?.[RECLAIM_EVENT_ID]; // this maps to getGCalEventId(event) or provider_id
}

export function isTextColorTooBrightAgainstWhiteBackground(color) {
  return getWhiteColorContrast(color) < READABILITY_CONTRAST_MINIMUM;
}

export function isColorTooDarkAgainstDarkBackground(color) {
  return getBlackColorContrast(color) < READABILITY_CONTRAST_MINIMUM;
}

function getWhiteColorContrast(backgroundColor) {
  if (_color_contrast_against_white_cache[backgroundColor]) {
    return _color_contrast_against_white_cache[backgroundColor];
  }
  const result = readability(backgroundColor, "#FFFFFF");
  _color_contrast_against_white_cache[backgroundColor] = result;
  return result;
}

function getBlackColorContrast(backgroundColor, againstColor = "#000000") {
  const key = `${backgroundColor}_${againstColor}`;

  if (_color_contrast_against_black_cache[key]) {
    return _color_contrast_against_black_cache[key];
  }
  const result = readability(backgroundColor, againstColor);
  _color_contrast_against_black_cache[key] = result;
  return result;
}

function getDefaultFontColorOnBackgroundColor(backgroundColor) {
  // Calculate contrast ratio between background and white/black text
  const whiteContrast = getWhiteColorContrast(backgroundColor); // white
  const blackContrast = getBlackColorContrast(backgroundColor, EVENT_FONT_COLOR.DARK);

  // If neither color provides sufficient contrast, choose the better one
  if (whiteContrast < READABILITY_CONTRAST_MINIMUM && blackContrast < READABILITY_CONTRAST_MINIMUM) {
    if (whiteContrast >= blackContrast) {
      return EVENT_FONT_COLOR.WHITE;
    }

    // need a darker color that our default text color
    return EVENT_FONT_COLOR.DARKER;
  }

  // Otherwise, choose the color that meets the contrast requirements
  if (whiteContrast >= READABILITY_CONTRAST_MINIMUM) {
    return EVENT_FONT_COLOR.WHITE;
  }
  return EVENT_FONT_COLOR.DARK;
}

export function getFontColorForBackgroundColor({
  backgroundColor,
  hasEventPassed,
  isCurrentPreviewEvent,
}) {
  let textColorForBackground;
  if (_defaultFontColorOnBackgroundColorCache[backgroundColor]) {
    textColorForBackground = _defaultFontColorOnBackgroundColorCache[backgroundColor];
  } else {
    textColorForBackground = getDefaultFontColorOnBackgroundColor(backgroundColor);
    _defaultFontColorOnBackgroundColorCache[backgroundColor] = textColorForBackground;
  }

  if (isCurrentPreviewEvent) {
    // if current event, we always want default
    return textColorForBackground;
  }

  if (hasEventPassed) {
    if (textColorForBackground === EVENT_FONT_COLOR.WHITE) {
      return FADED_GREY_TEXT_COLOR_DARK_MODE;
    }
    return FADED_WHITE_MODE_COLOR_FOR_DIMMED_PAST_EVENTS;
  }

  return textColorForBackground;
}

export function filterEventsInThePast(eventList) {
  if (isEmptyArrayOrFalsey(eventList)) {
    return [];
  }

  const NOW = new Date();
  return eventList.filter(
    (e) =>
      (isEventSlotAllDayEvent(e) && isSameOrAfterDay(e.eventEnd, NOW)) ||
      isAfterMinute(e.eventEnd, NOW)
  );
}

export function isEventPrivate(event) {
  return getEventVisibility(event) === PRIVATE;
}

export function getEventReminderOverrides(event) {
  return getEventReminders(event)?.overrides;
}

export function splitEventsByUserCalendarID(eventsList) {
  if (!eventsList || eventsList.length === 0) {
    return {};
  }

  let indexByUserCalendarID = {};
  eventsList.forEach((e) => {
    const eventUserCalendarID = getEventUserCalendarID(e);

    if (indexByUserCalendarID[eventUserCalendarID]) {
      indexByUserCalendarID[eventUserCalendarID] =
        indexByUserCalendarID[eventUserCalendarID].concat(e);
    } else {
      indexByUserCalendarID[eventUserCalendarID] = [e];
    }
  });

  return indexByUserCalendarID;
}

export function getAllUserCalendarIDsFromEventsList(eventsList) {
  if (isEmptyArray(eventsList)) {
    return [];
  }

  return removeDuplicatesFromArray(
    eventsList.map((e) => getEventUserCalendarID(e))
  );
}

export function getDefaultStartDateTime(event) {
  const eventStartDate = getEventStartAllDayDate(event);
  if (eventStartDate) {
    return eventStartDate;
  }

  return getEventStartDateTime(event);
}

export function getDefaultEndDateTime(event) {
  const eventEndDate = getEventEndAllDayDate(event);
  if (eventEndDate) {
    return eventEndDate;
  }

  return getEventEndDateTime(event);
}

export function isCancelledEvent(event) {
  const isCanceled = getEventStatus(event) === SELF_RESPONSE_CANCELLED;
  if (isOutlookEvent(event)) {
    return event?.raw_json?.isCancelled || isCanceled || event?.is_cancelled;
  }
  return isCanceled;
}

export function isAvailabilityEvent(event) {
  return getEventStatus(event) === AVAILABILITY;
}

export function isTemporaryEvent(event) {
  return getEventStatus(event) === TEMPORARY;
}

export function isOrganizerSelf(event) {
  return getEventOrganizer(event)?.self;
}

export function isFreeEvent(event) {
  return getEventTransparency(event) === FREE_DURING_EVENT;
}

export function isBusyEvent(event) {
  return !isFreeEvent(event);
}

export function getEventStartAllDayDate(event) {
  return getEventStart(event)?.date;
}

export function getEventStartDateTime(event) {
  return getEventStart(event)?.dateTime;
}

export function getEventEndAllDayDate(event) {
  return getEventEnd(event)?.date;
}

export function getEventEndDateTime(event) {
  return getEventEnd(event)?.dateTime;
}

// first check for date then check for dateTime
export function getEventStartValue(event) {
  return getEventStartAllDayDate(event) ?? getEventStartDateTime(event);
}

export function getEventEndValue(event) {
  return getEventEndAllDayDate(event) ?? getEventEndDateTime(event);
}

export function getEventStartTimeZone(event) {
  return getEventStart(event)?.timeZone;
}

export function getEventEndTimeZone(event) {
  return getEventEnd(event)?.timeZone;
}

export function getStartTimeUTC(event) {
  return event?.start_time_utc;
}

export function getEndTimeUTC(event) {
  return event?.end_time_utc;
}

export function isValidEvent(event) {
  return !!getEventStart(event);
}

export function hasHumanAttendees(event) {
  return getHumanAttendees(event)?.length > 0;
}

/**
 * @param {VimcalEvent} event
 * @returns {VimcalAttendee[]}
 */
export function getHumanAttendees(event) {
  if (isEmptyObjectOrFalsey(event)) {
    return [];
  }

  const attendees = getEventAttendees(event);
  return filterOutResourceAttendees(attendees);
}

export function isEventAttendeeOrganizer(attendee) {
  return !!attendee?.organizer;
}

export function isEventAttendeeOptional(attendee) {
  return attendee?.optional;
}

function getSelfRSVP(event, email) {
  const attendees = getHumanAttendees(event);
  const selfResponse = attendees.filter((attendee) =>
    isSameEmail(getObjectEmail(attendee), email)
  );
  return selfResponse;
}

function getAttendeeStatus(event, email) {
  const selfResponse = getSelfRSVP(event, email);

  if (isEmptyArray(selfResponse)) {
    return ATTENDEE_EVENT_ATTENDING;
  }

  return selfResponse.length >= 1
    ? selfResponse[0].responseStatus
    : NO_RESPONSE_NEEDED;
}

export function getSelfRSVPComment(event, email) {
  const selfResponse = getSelfRSVP(event, email);
  return getEventAttendeeComment(selfResponse?.[0]);
}

// Note: this is different than getSelfAttendingStatus because this only checks for RSVP and not outlook's show_as property
export function getSelfRSVPStatus(event, email) {
  return getAttendeeStatus(event, email);
}

export function getSelfAttendingStatus(event, email) {
  if (isOutlookShowAsTentativeEvent(event)) {
    // this is when outlook overrides
    return ATTENDEE_EVENT_TENTATIVE;
  }
  return getAttendeeStatus(event, email);
}

export function eventHasAttendees(event) {
  return getEventAttendees(event)?.length > 0;
}

export function isFocusModeEvent(event) {
  if (isEmptyObjectOrFalsey(event)) {
    return false;
  }

  const { summaryUpdatedWithVisibility } = event;

  if (!summaryUpdatedWithVisibility) {
    return false;
  }

  const loweredCase = summaryUpdatedWithVisibility.toLowerCase();

  if (
    loweredCase.includes("focus") ||
    loweredCase.includes("deep") ||
    loweredCase.includes("work") ||
    loweredCase.includes("block")
  ) {
    return true;
  }
}

export function isEventTypeFocusBlock(event) {
  return getEventStatus(event) === TYPE_FOCUS_BLOCK;
}

export function isOutlookEvent(event) {
  return getEventProvider(event) === CALENDAR_PROVIDERS.OUTLOOK;
}

export function isGoogleEvent(event) {
  return (
    getEventProvider(event) === CALENDAR_PROVIDERS.GOOGLE ||
    getEventProvider(event) !== CALENDAR_PROVIDERS.OUTLOOK
  );
}

export function isAllDayEvent(event) {
  if (isOutlookEvent(event)) {
    return isAllDayOutlookEvent(event);
  }

  return !!getEventEndAllDayDate(event);
}

export function getEventStartAndEnd(event) {
  const isAllDay = isAllDayEvent(event);

  if (isOutlookEvent(event) && !isAllDay) {
    const jsStart = parseJSON(getEventStartValue(event));
    const jsEnd = parseJSON(getEventEndValue(event));

    return { eventStart: jsStart, eventEnd: jsEnd };
  }
  if (isOutlookEvent(event) && isAllDay) {
    // outlook all day events as passed back as "2023-07-18T00:00:00.0000000"
    // so for certain times of the day, the event will be off by a day
    return parseOutlookAllDayEvent(event);
  } else {
    const jsStart = parseISO(getEventStartValue(event));
    const jsEnd = parseISO(getEventEndValue(event));
    return { eventStart: jsStart, eventEnd: jsEnd };
  }
}

export function formatEventsArrayForReactBigCalendar({
  events,
  currentTimeZone,
  calendarId,
  isPreviewOutlookEvent,
}) {
  if (isEmptyArray(events)) {
    // used to prevent this error: https://vimcal.sentry.io/issues/4317942840/?project=2190664&query=typeerror&referrer=issue-stream&statsPeriod=14d&stream_index=5
    return [];
  }

  return events.map((e) =>
    formatEventForReactBigCalendar({
      event: e,
      currentTimeZone,
      calendarId,
      isPreviewOutlookEvent,
    })
  );
}

export function eventOnlyHasTime(event) {
  if (
    !getEventLocation(event) &&
    !event?.conferenceUrl &&
    generateConferenceRooms(event).length === 0
  ) {
    return true;
  }

  return false;
}

export function getEventDuration(event) {
  const { eventStart, eventEnd } = event;
  return differenceInMinutes(eventEnd, eventStart);
}

export function isOutlookNonOrganizer(event) {
  if (!event || !isOutlookEvent(event)) {
    return false;
  }

  const isOutlook = isOutlookEvent(event);
  const attendees = getEventAttendees(event);
  const hasAttendees = Array.isArray(attendees) && attendees.length > 0;
  const isOrganizer = isOrganizerSelf(event);

  return isOutlook && hasAttendees && !isOrganizer;
}

export function isLocationString(event) {
  const location = getEventLocation(event);
  return typeof location === "string";
}

export function showChangesWillOnlyShowOnThisCalendar({
  event,
  allCalendars,
  masterAccount,
  allLoggedInUsers,
  currentUser,
}) {
  const determineCalendarFromCalendarId = (calendarId) => {
    const allWritableCalendars = getAllEditableCalendars(allCalendars);

    if (allWritableCalendars[calendarId]) {
      return allWritableCalendars[calendarId];
    } else {
      return getCalendarFromEmail({
        email: getUserEmail(currentUser),
        allCalendars,
        masterAccount,
        allLoggedInUsers,
      });
    }
  };

  const calendar = determineCalendarFromCalendarId(
    getEventUserCalendarID(event)
  );

  const eventOrganizerEmail = getEventOrganizer(event)?.email;
  const calendarEmail = isVersionV2()
    ? calendar?.provider_id
    : calendar?.google_id;

  if (
    getEventMasterEventID(event) &&
    getEventAttendees(event)?.length > 0 &&
    calendarEmail &&
    eventOrganizerEmail &&
    eventOrganizerEmail !== calendarEmail
  ) {
    return true;
  }

  return !hasPermissionToModify(event, allCalendars);
}

export function shouldDisplayEvent({ showDeclinedEvents, event, email }) {
  if (!isValidEvent(event) || isCancelledEvent(event)) {
    return false;
  }

  const attendingStatus = getSelfAttendingStatus(event, email);

  if (attendingStatus !== GoogleCalendarService.attendee_event_declined) {
    return true;
  }
  // below is logic for when we want to show declined events

  // Outlook events are always shown
  if (isOutlookEvent(event)) {
    return false;
  }

  // for google events
  return showDeclinedEvents;
}

export function shouldShowProposeTime({ event, allCalendars }) {
  if (isEmptyObjectOrFalsey(event)) {
    return false;
  }

  if (isOutlookEvent(event)) {
    return false;
  }
  const attendees = getEventAttendees(event);
  if (isEmptyArrayOrFalsey(attendees)) {
    return false;
  }

  // only show if it's an organizer
  const eventCalendarEmail = getEmailBasedOnCalendarId(event, allCalendars);
  return !isOrganizer(attendees, eventCalendarEmail);
}

export const formatContactsToAttendees = ({
  allLoggedInUsers = [],
  calendar = null,
  contacts = [],
  masterAccount,
}) => {
  let contactsList = contacts.map((contact) => {
    const email = contact.email || contact.contact?.email;
    const name = contact.name || contact.contact?.name;

    return {
      displayName: name,
      email,
      responseStatus: "needsAction",
    };
  });

  if (
    !isEmptyObjectOrFalsey(calendar) &&
    getCalendarUserEmail(calendar) &&
    allLoggedInUsers?.length > 0
  ) {
    const organizerUser = getMatchingExecOrNormalUserFromCalendar({
      calendar,
      allLoggedInUsers,
      masterAccount,
    });

    const organizerAttendee = {
      displayName: getSelectedUserName({ user: organizerUser }).fullName,
      email: getUserEmail(organizerUser),
      responseStatus: "accepted",
      isOrganizer: true,
    };

    contactsList.unshift(organizerAttendee);
  }

  return contactsList;
};

export function removeDuplicateEventsBasedOnTime(events) {
  const uniqueEvents = events.filter((event, index) => {
    const firstIndex = events.findIndex((otherEvent) => {
      return (
        event.eventStart.getTime() === otherEvent.eventStart.getTime() &&
        event.rbcEventEnd.getTime() === otherEvent.rbcEventEnd.getTime()
      );
    });
    return firstIndex === index;
  });
  return uniqueEvents;
}

export function getEventAttendeeDomains({ event, allLoggedInUsers }) {
  const attendees = getEventAttendees(event);
  if (isEmptyArrayOrFalsey(attendees)) {
    return [];
  }
  const allValidEmails = attendees.map((attendee) => getObjectEmail(attendee)).filter(e => isValidEmail(e));
  const uniqueDomains = removeDuplicatesFromArray(allValidEmails.map(getEmailDomain));

  // filter out domains that are the same as the current user and also excluded domains (google, gmail, etc)
  return filterOutCompanyDomains({ domains: uniqueDomains, allLoggedInUsers });
}

// filter out domains that are logged in and gmail, yahoo, etc
function filterOutCompanyDomains({ domains, allLoggedInUsers }) {
  const allUserEmails = allLoggedInUsers.map(user => getUserEmail(user)).filter(e => isValidEmail(e));
  const allExcludedDomains = removeDuplicatesFromArray(allUserEmails.map(getEmailDomain).concat(EXCLUDED_DOMAINS));

  return domains.filter((url) => {
    // Check if the current URL includes any of the excluded URLs
    return !allExcludedDomains.some((excludedUrl) => url.includes(excludedUrl));
  });
}

// events that are too long (e.g. 50000 years) will break app (infinite loop and take too much compute)
// since we iterate over each day when putting the event into the index
export function isEventTooLong(e) {
  if (!e?.eventStart || !e?.eventEnd) {
    return true;
  }

  const { eventStart, eventEnd } = e;
  return differenceInMonths(eventEnd, eventStart) > 1;
}

export function isEventWithinTimeRange({
  event,
  timeRangeStart,
  timeRangeEnd,
}) {
  const { eventStart, eventEnd } = event;

  return (
    isSameOrBeforeMinute(eventStart, timeRangeEnd) &&
    isSameOrAfterMinute(eventEnd, timeRangeStart)
  );
}

export function isEventWithinDateRange({
  event,
  timeRangeStart,
  timeRangeEnd,
}) {
  const { eventStart, eventEnd } = event;

  return (
    isSameOrBeforeMinute(eventStart, endOfDay(timeRangeEnd)) &&
    isSameOrAfterMinute(eventEnd, startOfDay(timeRangeStart))
  );
}

export function isEventWithinExactTimeRange({
  event,
  timeRangeStart,
  timeRangeEnd,
}) {
  const { eventStart, eventEnd } = event;

  return (
    isSameOrBeforeMinute(eventStart, timeRangeEnd) &&
    isSameOrAfterMinute(eventEnd, timeRangeStart)
  );
}

// get the searched email of the meet with event
export function getMeetWithEventCalendarEmail(event) {
  return event?.temporaryCalendarId;
}

export function getEventCalendarProviderID(event) {
  return event?.calendar_provider_id;
}

export function isEventHiddenAttendees(event) {
  if (getEventGuestPermissions(event, GUESTS_CAN_MODIFY)) {
    return false;
  }

  return getEventGuestPermissions(event, GUESTS_CAN_SEE_OTHER_GUESTS) === false;
}

export function doesEventSpanMultipleDays(event) {
  if (isEmptyObjectOrFalsey(event)) {
    return false;
  }
  const { eventStart, eventEnd } = event;

  if (!eventStart || !eventEnd) {
    return false;
  }

  return !isSameDay(eventStart, eventEnd);
}

export function getEventShowAs(event) {
  return getOutlookEventShowAs(event) || event?.show_as;
}

export const EVENT_SHOW_AS_TYPES = {
  DEFAULT: "default",
  OUT_OF_OFFICE: "outOfOffice",
  FOCUS_TIME: "focusTime",
  WORKING_LOCATION: "workingLocation",
  BIRTHDAY: "birthday",
  FROM_GMAIL: "fromGmail",
};

export function isWorkplaceEvent(event) {
  return getEventShowAs(event) === EVENT_SHOW_AS_TYPES.WORKING_LOCATION;
}

export function isShowAsBirthdayEvent(event) {
  return getEventShowAs(event) === EVENT_SHOW_AS_TYPES.BIRTHDAY;
}

// {
//   "type": "officeLocation",
//   "officeLocation": {}
// }
export function getEventWorkLocationData(event) {
  return (
    event.working_location_properties ??
    event?.raw_json?.workingLocationProperties
  );
}

const WORK_LOCATION_TYPE = {
  HOME_OFFICE: "homeOffice", // The user is working at home.
  OFFICE_LOCATION: "officeLocation", // The user is working from an office.
  CUSTOM_LOCATION: "customLocation", // The user is working from a custom location.
};

export function getEventWorkLocationType(event) {
  return getEventWorkLocationData(event)?.type;
}

export function isEventWorkLocationHome(event) {
  return getEventWorkLocationType(event) === WORK_LOCATION_TYPE.HOME_OFFICE;
}

export function isEventWorkLocationOffice(event) {
  return getEventWorkLocationType(event) === WORK_LOCATION_TYPE.OFFICE_LOCATION;
}

export function isEventWorkLocationCustom(event) {
  return getEventWorkLocationType(event) === WORK_LOCATION_TYPE.CUSTOM_LOCATION;
}

export function isSpecialEvent(event) {
  return isWorkplaceEvent(event) || isOutOfOfficeEvent(event);
}

export function isAllDayWorkLocationEvent(event) {
  return isAllDayEvent(event) && isWorkplaceEvent(event);
}

/**
 * Return some unique identifier for the given event to be used as a key.
 * Do not create a UUID as a fallback, as the return value of this function
 * needs to be deterministic.
 */
export function getEventUniqueKey(event) {
  // When editing an event, there could be a brief moment where both the
  // event and the temporary event are rendered simultaneously, so we
  // need to differentiate the keys.
  const getSuffix = () => {
    if (isFakeMimicEvent(event)) {
      return "-mimic";
    }
    if (isTemporaryEvent(event)) {
      return "-temp";
    }
    return "";
  };
  return (event?.user_event_id ?? event?.id) + getSuffix();
}

export function isDeclinedEvent(selfAttendingStatus) {
  return selfAttendingStatus === ATTENDEE_EVENT_DECLINED;
}

/* Should only be used on Outlook events */
/* Will need to update for Google if same functionality is allowed */
export function appendInlineAttachments({
  attachments,
  description,
  isDescriptionBlank,
}) {
  /* Description just filled with empty HTML -> return empty string */
  if (isEmptyArray(attachments) && isDescriptionBlank) {
    return "";
  }

  let updatedDescription = description;
  attachments
    .filter((attachment) => attachment.isInline)
    .forEach((attachment) => {
      const { contentBytes, contentId, contentType } = attachment;

      /* Replace the source of the inline attachments with content */
      updatedDescription = updatedDescription.replace(
        `cid:${contentId}`,
        `data:${contentType};base64,${contentBytes}`
      );
    });

  return updatedDescription;
}

export function isPreviewOutlookEvent(event) {
  return event?.isPreviewOutlookEvent;
}

export function getUniquePreviewOutlookEvents(events) {
  if (isEmptyArray(events)) {
    return [];
  }
  const eventIDSet = new Set();
  return events.filter((event) => {
    const eventID = getEventID(event);
    if (eventIDSet.has(eventID)) {
      return false;
    } else {
      eventIDSet.add(eventID);
      return true;
    }
  });
}

export function filterOutInternalEvents({events, internalDomains}) {
  if (isEmptyArray(events)) {
    return [];
  }

  if (isEmptyArray(internalDomains)) {
    return events;
  }

  return events.filter((event) => {
    const attendees = getHumanAttendees(event);
    if (isEmptyArray(attendees)) {
      return false; // if there's no attendees, filter out
    }

    return attendees.some((attendee) => {
      const email = getObjectEmail(attendee);
      const domain = getEmailDomain(email);
      // Check if either the entire email or the domain is in internalDomains
      return !internalDomains.includes(email) && !internalDomains.includes(domain);
    });
  });
}

export function getConferenceDataConferenceName(conferenceData) {
  return conferenceData?.conferenceSolution?.name;
}

export function isConferenceDataZoom(conferenceData) {
  return getConferenceDataConferenceName(conferenceData)?.toLowerCase().includes("zoom");
}

export function isConferenceDataPhone(conferenceData) {
  return getConferenceDataConferenceName(conferenceData)?.toLowerCase().includes("phone call");
}

export function isConferenceDataGoogleHangoutMeet(conferenceData) {
  return getConferenceDataConferenceName(conferenceData)?.toLowerCase().includes("google");
}

export function isConferenceDataWhatsApp(conferenceData) {
  return getConferenceDataConferenceName(conferenceData)?.toLowerCase().includes("whatsapp");
}

export function getEventDescriptionUIClassnames({sanitizedDescription, event, userEmail}) {
  const getWrapperClassName = () => {
    if (sanitizedDescription?.includes("Google Calendar")) {
      return "google-invite";
    }
    if (sanitizedDescription?.includes("BCG")) {
      return "bcg-container";
    }
    return "word-break-break-word";
  };

  const skipFormattingDescriptionForOutlookDarkMode = () => {
    const eventDescription = getEventDescription(event) ?? "";
    if (!eventDescription) {
      return false;
    }
    if (isWBEmail(userEmail)) {
      return true;
    }
    const loweredCaseEventDescription = lowerCaseAndTrimStringWithGuard(eventDescription);
    return (
      loweredCaseEventDescription.includes("bcg.com") ||
      loweredCaseEventDescription.includes("wb.com") ||
      loweredCaseEventDescription.includes("warner") ||
      loweredCaseEventDescription.includes("google calendar")
    );
  };

  return classNames(
    "expand-event-summary",
    getWrapperClassName(),
    isOutlookEvent(event) &&
      !skipFormattingDescriptionForOutlookDarkMode()
      ? "outlook-expanded-event-description"
      : "",
  );
}

export function getSanitizedEventDescription(event) {
  const eventDescription = getEventDescription(event);
  if (!eventDescription) {
    return "";
  }
  const text = replaceStringInLinkTag(eventDescription);
  return sanitizeStringAndLinkify(text);
}

export function getEventVisibilityOptions(event) {
  if (isOutlookEvent(event)) {
    return OUTLOOK_EVENT_VISIBILILITY_OPTIONS;
  }
  return GOOGLE_EVENT_VISIBILILITY_OPTIONS;
}

export function getCalendarVisibilityOptions(calendar) {
  if (isCalendarOutlookCalendar(calendar)) {
    return OUTLOOK_EVENT_VISIBILILITY_OPTIONS;
  }
  return GOOGLE_EVENT_VISIBILILITY_OPTIONS;
}

export function allowOutlookEventProposal(event) {
  // need to check response_requested, otherwise outlook will return error below:
  // error: "Your request can't be completed. The meeting organizer hasn't requested a response."
  return event?.allow_new_time_proposals && getOutlookResponseRequested(event);
}

// standardize this across both outlook and google
export function createCurrentEventUpdatedAtTime() {
  // 2024-11-08T17:46:51.578Z
  return new Date().toISOString();
}

export function hasEventBeenUpdated(oldEvent, newEvent) {
  return isEventNewlyUpdatedBasedOnEventUpdatedAtTime(oldEvent, newEvent) ||
    isEventHoldDetailsUpdated(oldEvent, newEvent);
}

function isEventHoldDetailsUpdated(oldEvent, newEvent) {
  try {
    const oldHoldDetails = getEventHoldDetails(oldEvent);
    const newHoldDetails = getEventHoldDetails(newEvent);

    /* There are no hold details on the old event */
    /* There are hold details on the new event */
    /* Technically not possible with our current implementation of holds */
    if (!oldHoldDetails && newHoldDetails) {
      return true;
    }

    /* There are hold details on the old event */
    /* There are no hold details on the new event */
    /* User converted a smart hold into an event -> SEATODO: Double check this */
    if (oldHoldDetails && !newHoldDetails) {
      return true;
    }

    /* No hold details in either events -> Nothing changed */
    if (!oldHoldDetails && !newHoldDetails) {
      return false;
    }

    if (isEmptyObjectOrFalsey(oldHoldDetails)) {
      return false;
    }

    /* Hold details in both events -> Check keys for difference */
    return Object.keys(oldHoldDetails).some(key => (
      oldHoldDetails[key] !== newHoldDetails?.[key]
    ));
  } catch (e) {
    return false;
  }
}

function isEventNewlyUpdatedBasedOnEventUpdatedAtTime(oldEvent, newEvent) {
  // See if new event was updated after old event;
  // since events are from the same provider -> we can just use updated_at instead of isGreatThanUpdatedAt
  const oldEventTimestamp = getEventUpdatedAt(oldEvent);
  const newEventTimestamp = getEventUpdatedAt(newEvent);

  if (oldEventTimestamp && newEventTimestamp) {
    if (oldEventTimestamp === newEventTimestamp) {
      // if strings match, by pass moment which is more expensive
      return false;
    }
    return newEventTimestamp > oldEventTimestamp;
  }

  // default true -> always update with new event
  return true;
}

export function isRecurringEvent(event) {
  return !!getEventMasterEventID(event);
}


export function shouldShowHiddenEventsOption(event) {
  return !isMeetWithEvent(event) && !isTemporaryEvent(event);
}

export const HIDDEN_EVENT_TOGGLE = {
  ON: "ON",
  OFF: "OFF",
};

const HIDDEN_EVENT_KEY = "is_hidden";
export function toggleEventHiddenEventPropertyOnBackendFailed(event) {
  // toggle back
  const updatedEvent = isEventHiddenEvent(event) ? removeEventHiddenEventProperty(event) : addEventHiddenEventProperty(event);
  mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.ADD_EVENT_INTO_WEEKLY_CALENDAR, {
    event: updatedEvent,
  });
  addEventsIntoIndexDB({ userEmail: getEventUserEmail(updatedEvent), events: [updatedEvent] });
}

export function toggleEventHiddenEventProperty(event, toggle) {
  const updatedEvent = toggle === HIDDEN_EVENT_TOGGLE.ON ? addEventHiddenEventProperty(event) : removeEventHiddenEventProperty(event);
  backendBroadcasts.publish(BACKEND_BROADCAST_VALUES.SET_HIDDEN_EVENTS, {
    event: updatedEvent,
    toggle,
  });
  mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.ADD_EVENT_INTO_WEEKLY_CALENDAR, {
    event: updatedEvent,
  });
  mainCalendarBroadcast.publish("REMOVE_PREVIEW_EVENT");

  // add into indexDB
  addEventsIntoIndexDB({ userEmail: getEventUserEmail(updatedEvent), events: [updatedEvent] });

  return updatedEvent;
}

function addEventHiddenEventProperty(event) {
  return  {
    ...event,
    [HIDDEN_EVENT_KEY]: true,
  };
}

function removeEventHiddenEventProperty(event) {
  return {
    ...event,
    [HIDDEN_EVENT_KEY]: false,
  };
}

export function isEventHiddenEvent(event) {
  try {
    return event?.[HIDDEN_EVENT_KEY];
  } catch (e) {
    return false;
  }
}
