import React, { useState } from "react";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  useSensor,
  useSensors,
  MouseSensor,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
} from "@dnd-kit/sortable";
import { arrayMove } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import classNames from "classnames";
import {
  getCalendarEmail,
  getCalendarUserCalendarID,
} from "../services/calendarAccessors";
import { useSelector } from "react-redux";
import {
  useAllCalendars,
  useAllLoggedInUsers,
  useMasterAccount,
} from "../services/stores/SharedAccountData";
import {
  createUpdatedAllCalendars,
  determineCalendarColor,
  getCalendarName,
  getOrderedAllCalendars,
  getUserCalendar,
  isCalendarSelected,
  parseSyncResponseCalendars,
  updateCalendarOrder,
} from "../lib/calendarFunctions";
import { isEmailGroupEmail } from "../lib/stringFunctions";
import CheckBoxSpinner from "./spinners/checkboxSpinner";
import CheckBox from "./checkbox";
import { MoreVertical } from "react-feather";
import broadcast from "../broadcasts/broadcast";
import { BROADCAST_VALUES } from "../lib/broadcastValues";
import { handleError, hasEventPreventDefault, hasStopEventPropagation } from "../services/commonUsefulFunctions";
import { formatISO } from "date-fns";
import { getUserEmail } from "../lib/userFunctions";
import { useIsMounted } from "../services/customHooks/useIsMounted";
import produce from "immer";
import { isCalendarExecutiveCalendar, isUserMaestroUser } from "../services/maestroFunctions";
import ExecutiveLabel from "../../components/executiveLabel";

const CLICKED_THRESHOLD = 10; // Pixels
export default function DraggableCalendarSection({
  user,
  toggleSelectCalendar,
  handleOnClickMoreOptions,
}) {
  const [draggedCalendarId, setDraggedCalendarId] = useState(null);
  const allCalendars = useAllCalendars((state) => state.allCalendars);
  const currentUser = useSelector((state) => state.currentUser);
  const setAllCalendars = useAllCalendars((state) => state.setAllCalendars);
  const allLoggedInUsers = useAllLoggedInUsers((state) => state.allLoggedInUsers);
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const componentIsMounted = useIsMounted();
  const userEmail = getUserEmail(user);
  const calendars = getOrderedAllCalendars({
    allCalendars: getUserCalendar(allCalendars, getUserEmail(user)), 
    currentUserEmail: userEmail,
    allLoggedInUsers,
    masterAccount,
  });

  // Mouse sensor with activation constraint
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: CLICKED_THRESHOLD, // Drag starts after moving 10px
    },
  });
  const sensors = useSensors(
    mouseSensor,
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  const handleDragEnd = (event) => {
    hasEventPreventDefault(event);
    hasStopEventPropagation(event);
    setDraggedCalendarId(null);
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = calendars.findIndex(
        (calendar) => getCalendarUserCalendarID(calendar) === active.id
      );
      const newIndex = calendars.findIndex(
        (calendar) => getCalendarUserCalendarID(calendar) === over.id
      );
      const updatedOrderedCalendars = arrayMove(
        calendars,
        oldIndex,
        newIndex
      );
      updateCalendarsOnBackend(updatedOrderedCalendars);

      // update locally
      const getIndex = (userCalendarID) => {
        const index = updatedOrderedCalendars.findIndex(
          (calendar) => getCalendarUserCalendarID(calendar) === userCalendarID
        );
        if (index === -1) {
          return null;
        }
        return index;
      };

      const updatedAllCalendars = produce(allCalendars, (draftState) => {
        Object.keys(draftState).forEach((userCalendarID) => {
          const calendar = draftState[userCalendarID];
          if (calendar?.calendar) {
            calendar["calendar"]["user_order"] = getIndex(userCalendarID);
          }
        });
      });
      setAllCalendars(updatedAllCalendars, currentUser, "setAllCalendars_10");
    }
  };

  const updateCalendarsOnBackend = async (updatedOrderedCalendars) => {
    try {
      const response = await updateCalendarOrder({
        orderedCalendars: updatedOrderedCalendars,
        user,
      });
      if (!componentIsMounted.current) {
        return;
      }
      if (!response || response.error || !response.calendars) {
        return;
      }
      const { calendars } = response;
      const lastSyncUTC = formatISO(new Date()); // format: 2024-05-13T16:00:01Z
      const { responseAllCalendars } = parseSyncResponseCalendars(
        calendars,
        lastSyncUTC,
        allCalendars,
      );
      const updatedAllCalendars = createUpdatedAllCalendars({
        responseAllCalendars,
        allCalendars,
        userEmail: getUserEmail(user),
        currentUser,
        allLoggedInUsers,
        masterAccount,
        fromWhere: "updateCalendarOrder",
      });
      setAllCalendars(updatedAllCalendars, currentUser, "setAllCalendars_9");
    } catch (error) {
      handleError(error);
    }
  };

  const handleDragStart = (event) => {
    hasEventPreventDefault(event);
    hasStopEventPropagation(event);
    const { active } = event;
    setDraggedCalendarId(active.id);
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
    >
      <SortableContext
        items={calendars.map((calendar) => getCalendarUserCalendarID(calendar))}
      >
        <div className="flex flex-col">
          {calendars.map((calendar) => (
            <SortableItem
              key={getCalendarUserCalendarID(calendar)}
              calendar={calendar}
              toggleSelectCalendar={toggleSelectCalendar}
              handleOnClickMoreOptions={handleOnClickMoreOptions}
              draggedCalendarId={draggedCalendarId}
            />
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
}

function SortableItem({
  calendar,
  handleOnClickMoreOptions,
  draggedCalendarId = null,
}) {
  const emailToNameIndex = useSelector((state) => state.emailToNameIndex);
  const currentUser = useSelector((state) => state.currentUser);
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const allLoggedInUsers = useAllLoggedInUsers((state) => state.allLoggedInUsers);

  const id = getCalendarUserCalendarID(calendar);
  const calendarColor = determineCalendarColor(calendar);
  const calendarDisplayName = getCalendarName({
    calendar,
    emailToNameIndex,
    currentUser,
    masterAccount,
  });
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleMoreOptions = (e) => {
    handleOnClickMoreOptions({
      e,
      id,
      userCalendarId: getCalendarUserCalendarID(calendar),
    });
  };

  const getHoverClassName = () => {
    const defaultClassName = "calendar-hover-agenda-panel";
    if (!draggedCalendarId) {
      return defaultClassName;
    }
    if (draggedCalendarId !== getCalendarUserCalendarID(calendar)) {
      return "";
    }
    return classNames(defaultClassName, "z-10");
  };

  const getCursorClassName = () => {
    if (draggedCalendarId === getCalendarUserCalendarID(calendar)) {
      return "cursor-grab-grabbing";
    }
    return "cursor-pointer";
  };

  const isSelected = isCalendarSelected(calendar);
  const isExecutiveCalendar = isUserMaestroUser(masterAccount) && isCalendarExecutiveCalendar({ calendar, allLoggedInUsers });
  return (
    <div
      key={id}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      id={id}
      className={classNames(
        "default-font-size",
        "font-weight-300 flex items-center",
        "mr-1 py-0.5 pr-3 pl-4",
        "container-hover-icon-visibility",
        getHoverClassName(),
        getCursorClassName(),
        "relative",
        "hover-visibility-hint-container",
      )}
      onClick={(e) => {
        if (calendar.isFetching) {
          return;
        }
        broadcast.publish(
          BROADCAST_VALUES.TOGGLE_CALENDAR_FROM_CALENDAR_LIST,
          calendar
        );
      }}
    >
      {!draggedCalendarId &&
      calendarDisplayName !== getCalendarEmail(calendar) &&
      !isEmailGroupEmail(getCalendarEmail(calendar)) ? (
        <div
          className={classNames(
            "absolute",
            "top-8 left-8 truncate",
            "z-index-1 max-width-280px",
            "hover-visibility-hint"
          )}
        >
          {getCalendarEmail(calendar)}
        </div>
      ) : null}

      {calendar.isFetching ? (
        <CheckBoxSpinner
          className={"checkbox-spinner"}
          color={calendarColor}
          size={16}
        />
      ) : (
        <CheckBox
          backgroundColor={
            isSelected ? calendarColor : "transparent"
          }
          borderColor={calendarColor}
          borderWidth={1}
          isChecked={isSelected}
        />
      )}
      <div className={classNames("ml-2 w-full default-font-size", isExecutiveCalendar ? "flex items-center gap-2" : "")}>
        <div className={classNames("truncate", isExecutiveCalendar ? "max-width-224px" : "max-width-270px")}>{calendarDisplayName}</div>
        {isExecutiveCalendar ? <ExecutiveLabel /> : null}
      </div>

      <div
        className="w-4 h-6 flex items-center justify-center"
        onClick={handleMoreOptions}
      >
        <MoreVertical size={14} className="clickable-icon mb-1 mt-0.5" />
      </div>
    </div>
  );
}
