import React, { Component } from "react";
import { connect, batch } from "react-redux";
import _ from "underscore";
import { withRouter } from "react-router-dom";
import StyleConstants, {
  BACKEND_MONTH,
  DARK_MODE_SECONDARY_TEXT_COLOR,
  LIGHT_MODE_SECONDARY_TEXT_COLOR,
  MEDIUM_GRAY,
  SECOND_IN_MS,
  SET_DISAPPEARING_NOTIFICATION_MESSAGE,
} from "../services/globalVariables";
import {
  constructQueryParams,
  updateEventsTime,
  hasStateOrPropsChanged,
  handleError,
  updateRecentlySearchedContactsArray,
  splitUpStringIntoArray,
  determineSyncWindow,
  filterOtherPeoplesCalendarsAndReport,
  hasEventPreventDefault,
  isTooltipCompleted,
  hasStopEventPropagation,
  getActiveCommandCentersKey,
  convertEmailToName,
  isInt,
  isValidTimeZone,
} from "../services/commonUsefulFunctions";
import {
  createWindow,
  getRecentlySearchedContacts,
  getCombinedEmailContact,
  getCurrentViewFetchDates,
  orderLoggedInUsers,
  getMostLeftHandTimeZone,
} from "../lib/stateManagementFunctions";
import { constructRequestURL } from "../services/api";
import Broadcast from "../broadcasts/broadcast";
import Fetcher from "../services/fetcher";
import { trackEvent } from "./tracking";
import ReactSelectAttendeeAutoComplete from "../components/reactSelectAttendeeAutoComplete";
import GoogleColors from "../services/googleColors";
import ShortcutTile from "./shortcutTiles/shortcutTile";
import AvailabilityBroadcast from "../broadcasts/availabilityBroadcast";
import BackendBroadcasts from "../broadcasts/backendBroadcasts";
import classNames from "classnames";
import { X, Check } from "react-feather";
import {
  getDomainAndContacts,
  getMatchingContactGroup,
} from "../lib/contactFunctions";
import EventModalPopup from "../components/eventModalPopup";
import {
  formatFlattendCalendarAndEvents,
  formatGCalEventsList,
} from "../lib/webWorkerFunctions";
import {
  useAllCalendars,
  useAllLoggedInUsers,
  useMasterAccount,
} from "../services/stores/SharedAccountData";
import {
  determineCalendarColor,
  isCalendarSelected,
  isValidCalendar,
  getActiveCalendarsFromAllCalendars,
  getCalendarFromEmail,
  isOwnerOfCalendar,
  getAllCalendarUserCalendarIDs,
  getUserEmailFromUserCalendarID,
  isCalendarSecondaryAccount,
  isCalendarConvertableToSecondary,
  getActiveCalendarEmailsFromAllCalendars,
  isCalendarOutlookCalendar,
  getCalendarUserEmail,
  getUpdatedUserTimeZonesIndexAndLastSet,
} from "../lib/calendarFunctions";
import produce from "immer";
import { tooltipKeys } from "../services/tooltipVariables";
import TooltipBox from "./tooltips/tooltipBox";
import {
  getCalendarProviderId,
  getCalendarIsPrimary,
  getCalendarUserCalendarID,
  getCalendarOwnerEmail,
  getCalendarEmail,
} from "../services/calendarAccessors";
import { isVersionV2 } from "../services/versionFunctions";
import { OUTLOOK_CALENDAR_COLORS } from "../resources/outlookVariables";
import { APP_SETTINGS, REACT_ATTENDEE_SELECT_LOCATION } from "../lib/vimcalVariables";
import layoutBroadcast from "../broadcasts/layoutBroadcast";
import {
  getMatchingUserWithDomain,
  getMeetWithUserEmail,
} from "../services/meetWithFunctions";
import {
  addEventsIntoIndexDB,
  isEventWithinJSWindow,
} from "../lib/dbFunctions";
import { isOutlookCalendarDeletable } from "../lib/outlookFunctions";
import backendBroadcasts from "../broadcasts/backendBroadcasts";
import {
  addBufferToWindow,
  getSelectedDayWithBackup,
} from "../lib/syncFunctions";
import { addDefaultToArray, immutablySortArray, isEmptyArray, removeDuplicatesFromArray, stringArrayContainsIgnoringCase } from "../lib/arrayFunctions";
import mainCalendarBroadcast from "../broadcasts/mainCalendarBroadcast";
import broadcast from "../broadcasts/broadcast";
import eventFormBroadcast from "../broadcasts/eventFormBroadcast";
import { shouldGetOutlookPreviewEvents, shouldShowFindTimeEventForm } from "../lib/featureFlagFunctions";
import { CALENDAR_LIST_FIND_TIME_BOTTON_ID } from "../services/elementIDVariables";
import AILogo from "./icons/aiLogo";
import { useTemporaryStateStore } from "../services/stores/temporaryStateStores";
import { getDefaultUser, getMasterAccountEmail, getMatchingUserFromAllUsers, getUserEmail, getUserToken, isUserEmailPartOfAllLoggedInUsers } from "../lib/userFunctions";
import { BACKEND_BROADCAST_VALUES, BROADCAST_VALUES, FETCH_BROADCAST_VALUES, MAIN_CALENDAR_BROADCAST_VALUES } from "../lib/broadcastValues";
import {
  capitalizeFirstLetter,
  formatEmail,
  getEmailDomain,
  isEmailGroupEmail,
  isSameEmail,
  isValidEmail,
  lowerCaseAndTrimString,
} from "../lib/stringFunctions";
import { isCalendarExecutiveCalendar, isMaestroUserOnDelegatedAccount, isUserDelegatedUser, shouldHideDelegatedUser } from "../services/maestroFunctions";
import { fetchOutlookPreviewMeetWithEvents, getDefaultHeaders } from "../lib/fetchFunctions";
import { GET_MEET_WITH_EVENTS_END_POINT, GET_TEAM_TEMPORARY_EVENTS } from "../lib/endpoints";
import fetchBroadcast from "../broadcasts/fetchBroadcast";
import { TEST_PREVIEW_OUTLOOK_LOCATION, isTestingPreviewOutlook } from "../services/testFunctions";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey, isTypeString } from "../services/typeGuards";
import { createUUID } from "../services/randomFunctions";
import { useDistroListDictionary } from "../services/stores/eventsData";
import { getTimeZoneFromMeetWithItem } from "../lib/meetWithFunctions";
import { useUserTimeZoneIndexStore } from "../services/stores/userData";
import { useAppTimeZones } from "../services/stores/appFunctionality";
import UserLocalTimeZone from "./userLocalTimeZone";
import ExpandIconWithAnimation from "./icons/expandIconWithAnimation";
import { GUESSED_TIME_ZONES_ON_APPLY_ALL_COPY } from "../lib/copy";
import DraggableCalendarSection from "./draggableCalendarSection";
import { MODAL_OVERLAY_Z_INDEXES } from "../lib/modalFunctions";
import { isActionModeCreateAvailability, isMonthlyView } from "../services/appFunctions";
import { addDays, endOfDay, startOfDay, subDays } from "date-fns";
import { WINDOW_SYNC_DAYS_OUT } from "./scheduleAssistant/helperFunctions";
import { useAppSettings } from "../services/stores/settings";
import { useMetricsStore } from "../services/stores/metricsStore";

// For DEV to create array if color changes
// const topColorsIndex = ['24', '4', '7', '16', '5', '17', '22'];
// const remainingColorsIndex = Object.keys(colorObject).filter( n => topColorsIndex.indexOf(n) === -1);
// const colorPriorityIndex = topColorsIndex.concat(remainingColorsIndex);
//
// let temporarySecondaryCalendarColors = [];
//
// colorPriorityIndex.forEach(n => {
//   temporarySecondaryCalendarColors = temporarySecondaryCalendarColors.concat(colorObject[n].background);
// });
//
class CalendarList extends Component {
  constructor(props) {
    super(props);

    this.selectRef = React.createRef();

  // used to keep track of cmd j events so if you press esc, async call doesn't still put events in main claendar
    this.fetchEventsNumber = createUUID();
    this._fetchOutlookPreviewEventsID = null;

    this.state = {
      emailInSearch: "",
      searchedEmails: [],
      calendarsWithNoAccess: [],
      emailsFromEventForm: [],
      calendarEmailEventsIndex: {},
      shouldDisplayModal: false,
      modalTop: 0,
      moreInformationCalendarId: null,
      shouldHideContactGroupsTooltip: true,
      meetWithHoverIndex: null,
    };

    this.toggleSelectCalendar = this.toggleSelectCalendar.bind(this);
    this.searchEmail = this.searchEmail.bind(this);
    this.fetchEventsForSearchedContacts =
      this.fetchEventsForSearchedContacts.bind(this);
    this.removeAllSelectedTemporarySecondaryCalendars =
      this.removeAllSelectedTemporarySecondaryCalendars.bind(this);
    this.updateSearchCalendarsWindow =
      this.updateSearchCalendarsWindow.bind(this);
    this.addEmailToSearchedEmails = this.addEmailToSearchedEmails.bind(this);
    this.createExistingEmailList = this.createExistingEmailList.bind(this);
    this.updateEventsFromEventForm = this.updateEventsFromEventForm.bind(this);
    this.matchContactNameWithEmail = this.matchContactNameWithEmail.bind(this);
    this.searchContactsDomainDB = this.searchContactsDomainDB.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.toggleOnOnlySelectCalendars =
      this.toggleOnOnlySelectCalendars.bind(this);
    this.toggleCalendarWithUserCalendarID =
      this.toggleCalendarWithUserCalendarID.bind(this);
    this.getSearchedEmailList = this.getSearchedEmailList.bind(this);
    this.findTimeCreateEvent = this.findTimeCreateEvent.bind(this);
    this.handleOnClickMoreOptions = this.handleOnClickMoreOptions.bind(this);
    this.refetchMeeWithEvents = this.refetchMeeWithEvents.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return hasStateOrPropsChanged(
      this.state,
      nextState,
      this.props,
      nextProps,
      ["match", "location"],
      null
    );
  }

  componentDidMount() {
    this._isMounted = true;
    this.addSubscriptions();
    this.matchContactNameWithEmail();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.eventFormEmails !== this.props.eventFormEmails) {
      this.searchContactsDomainDB(this.props.eventFormEmails);
    } else if (prevState.searchedEmails !== this.state.searchedEmails) {
      this.searchContactsDomainDB(this.state.searchedEmails);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;

    Broadcast.unsubscribe("REMOVE_ALL_SELECTED_TEMPORARY_CALENDARS");
    Broadcast.unsubscribe("UPDATE_SECONDARY_CALENDAR_WINDOW");
    Broadcast.unsubscribe("REMOVE_EMAIL");
    Broadcast.unsubscribe("ADD_EMAIL_TO_SEARCHED_EMAILS");
    Broadcast.unsubscribe("TOGGLE_SELECT_CALENDAR_WITH_CALENDAR_ID");
    Broadcast.unsubscribe(
      "UPDATE_SECONDARY_CALENDAR_EVENTS_ON_TIME_ZONE_CHANGE"
    );
    Broadcast.unsubscribe("ON_UPDATE_EVENT_FORM_EMAIL");
    Broadcast.unsubscribe("MATCH_CONTACTS_NAME_EMAIL_SIDE_MENU_BAR");
    Broadcast.unsubscribe("SEARCH_CONTACTS_DOMAIN_DB");
    Broadcast.unsubscribe("ONLY_TOGGLE_ON_SELECT_CALENDARS");
    eventFormBroadcast.unsubscribe("FIND_TIME_CREATE_EVENT");
    broadcast.unsubscribe(BROADCAST_VALUES.TOGGLE_CALENDAR_FROM_CALENDAR_LIST);
    broadcast.unsubscribe(BROADCAST_VALUES.REFETCH_MEET_WITH_EVENTS);
  }

  fetchEventsForSearchedContacts({
    fetchEventsNumber,
    emails,
    existingEmails = [],
    fromSideMenuBar = true,
    date = this.props.selectedDay,
    addToNoAccess = true,
    userEmail,
    isTeamSlots = false,
    where,
  }) {
    const formattedEmails = (emails || []).map(email => formatEmail(email));
    const emailsToFetch = formattedEmails.filter(
      (e) => !stringArrayContainsIgnoringCase(existingEmails, e)
    );
    const { selectedCalendarView } = this.props;
    const { fetchStart, fetchEnd } = getCurrentViewFetchDates({
      selectedCalendarView,
      selectedDate: getSelectedDayWithBackup(date),
      weekStart: this.props.weekStart,
    });
    const addBufferForFetch = selectedCalendarView !== BACKEND_MONTH;
    const getTimeMinAndMax = () => {
      if (isMonthlyView(selectedCalendarView)) {
        return addBufferToWindow({
          timeMin: fetchStart,
          timeMax: fetchEnd,
        });
      }
      return {
        timeMin: startOfDay(subDays(fetchStart, 1)),
        timeMax: endOfDay(addDays(getSelectedDayWithBackup(date), WINDOW_SYNC_DAYS_OUT)),
      };
    };

    const { timeMin, timeMax } = getTimeMinAndMax();

    if (formattedEmails.length > 0) {
      const path = isTeamSlots
        ? GET_TEAM_TEMPORARY_EVENTS
        : GET_MEET_WITH_EVENTS_END_POINT;
      const url = constructRequestURL(path, isVersionV2());
      const body = {
        timeMin: addBufferForFetch
          ? timeMin.toISOString()
          : fetchStart.toISOString(),
        timeMax: addBufferForFetch
          ? timeMax.toISOString()
          : fetchEnd.toISOString(),
        timeZone: this.props.currentTimeZone,
        calendarIds: emailsToFetch,
      };

      const payloadData = {
        headers: getDefaultHeaders(),
        body: JSON.stringify(body),
      };

      // pre determine calendar colors
      this.presetFetchCalendarColors(emailsToFetch);

      if (emailsToFetch.length > 0) {
        this._fetchOutlookPreviewEventsID = createUUID();
        this.fetchOutlookPreviewMeetWithEvents({ // check for outlook is in the function
          fetchEventsNumber,
          userEmail,
          startDate: timeMin,
          endDate: timeMax,
          emails: emailsToFetch,
          addToNoAccess,
          existingEmails,
          fromSideMenuBar,
          fetchOutlookPreviewEventsID: this._fetchOutlookPreviewEventsID,
          isTeamSlots,
        });

        return Fetcher.post(
          url,
          payloadData,
          true,
          userEmail || getUserEmail(this.props.currentUser)
        )
          .then((response) => {
            if (isTestingPreviewOutlook(TEST_PREVIEW_OUTLOOK_LOCATION.CALENDAR_LIST)) {
              return;
            }
            if (
              !this._isMounted ||
              isEmptyObjectOrFalsey(response) ||
              fetchEventsNumber !== this.fetchEventsNumber
            ) {
              return;
            }

            this._fetchOutlookPreviewEventsID = null;

            this.addEventsToWeeklyCalendar({
              response,
              addToNoAccess,
              emails,
              existingEmails,
              fromSideMenuBar,
            });
          })
          .catch((error) => {
            handleError(error);
          });
      }
    } else if (existingEmails.length > 0) {
      if (fromSideMenuBar) {
        mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.ADD_TEMPORARY_SEARCHED_EVENTS, {
          filterMeetWithExistingCalendarIds: existingEmails,
        });
      } else {
        mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.ADD_TEMPORARY_SEARCHED_EVENTS, {
          filterEventFormExistingCalendarIds: existingEmails,
        });
      }
    } else {
      // If removed all calendars
      if (fromSideMenuBar) {
        mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.ADD_TEMPORARY_SEARCHED_EVENTS, {
          meetWithEvents: [],
        });
      } else {
        mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.ADD_TEMPORARY_SEARCHED_EVENTS, {
          eventFormEvents: [],
        });
      }
    }
  }

  render() {
    if (this.props.renderOnlyContainer) {
      // e.g. when we preload events for onboarding
      return null;
    }

    return (
      <div
        className={classNames(
          "calendar-list-container ml-2.5",
          "relative",
          this.props.inputClassName || "",
        )}
      >
        <div
          className={classNames(
            "side-menu-calendar-calendar-text search-calendars-wrapper",
            this.props.customMarginTopClassName ?? "mt-5"
          )}
        >
          {"Meet With"}

          {this.props.isMobileView ? null : (
            <div className="flex flex-row items-center">
              <div className="display-flex-center margin-right-5 font-size-300">
                <ShortcutTile shortcut={getActiveCommandCentersKey()} />
                <ShortcutTile shortcut={"J"} />
              </div>
            </div>
          )}
        </div>

        <div className="side-menu-search-wrapper">
          <ReactSelectAttendeeAutoComplete
            createLabel="Search"
            innerRef={this.selectRef}
            addAttendees={this.searchEmail}
            addAttendeeList={this.addEmailToSearchedEmails}
            defaultText="Search for people"
            onEscape={() => this.props.setShouldDisplayMenu(false)}
            componentLocation={REACT_ATTENDEE_SELECT_LOCATION.SIDE_MENU_BAR}
            selectedGuests={this.createExistingEmailList()}
            useRecentlySearchedContacts={true}
            includeEmailAndName={true}
            useRecentlySearched={true}
            className="availability-search-attendees search-temporary-calendars light-right-panel-background-color calendarList-meet-with"
            controllerWidth={322}
            controllerMarginLeft={-7}
          />
        </div>
        <div
          className={classNames(
            this.getSearchedEmailList()?.length > 0
              ? "calendar-list-meet-with-container"
              : "",
            "mt-2.5 ml-2.5 rounded-lg width-320px"
          )}
        >
          {this.renderListOfSearchedEmails()}

          {this.state.calendarsWithNoAccess.length > 0 && (
            <div className="font-size-12 font-weight-200 mt-1">
              * Calendar can not be shown
            </div>
          )}
          {this.renderClearAndApplyTimeZone()}
        </div>
        {this.renderFindTimeEvents()}
        {this.renderContactGroupsTip()}

        <div
          className={classNames(
            "contact-list-wrapper",
            this.hasSearchedCalendars() ? "mt-4" : "mt-6",
            "flex justify-between items-center margin-right-5",
            "mb-2.5"
          )}
        >
          <div className="side-menu-calendar-calendar-text ml-1">Calendars</div>
        </div>
        {this.renderAllPermanentUser()}
        {this.renderCalendarModal()}
      </div>
    );
  }

  //================
  // RENDER METHODS
  //================

  renderCalendarModal() {
    const MODAL_STYLE = {
      overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "transparent",
        zIndex: MODAL_OVERLAY_Z_INDEXES.LOW_PRIORITY,
      },
      content: {
        padding: "0px",
        top: this.state.modalTop,
        position: "absolute",
        right: "25px",
        left: "auto",
        bottom: "auto",
        borderColor: "transparent",
        borderRadius: 6,
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        zIndex: 5,
        backgroundColor: this.props.isDarkMode
          ? StyleConstants.darkModeModalBackgroundColor
          : "white",
        color: this.props.isDarkMode
          ? StyleConstants.darkModeModalTextColor
          : "",
        maxHeight: "90vh",
        width: 185,
        overflowX: "hidden",
      },
    };

    const onClickDisplayOnlyThisCalendar = () => {
      this.toggleOnOnlySelectCalendars([this.state.moreInformationCalendarId]);
      this.closeModal();
      return;
    };

    /// stashing for later
    const hideCalendarFromList = (e) => {
      hasStopEventPropagation(e);
      BackendBroadcasts.publish("UPDATE_CALENDAR_PROPERTIES", {
        calendarID: this.state.moreInformationCalendarId,
        updatedProperties: { hidden: true },
        userEmail: getUserEmailFromUserCalendarID(
          this.state.moreInformationCalendarId,
          this.props.allCalendars.allCalendars
        ),
      });
      this.closeModal();
    };

    const onClickConvertToUser = () => {
      this.closeModal();
      Broadcast.publish(
        "OPEN_CONVERT_CALENDAR_MODAL",
        this.state.moreInformationCalendarId
      );

      return;
    };

    const onClickSwitchToUser = (allLoggedInUsers, calendar) => {
      this.closeModal();
      const calendarOwnerEmail = getCalendarOwnerEmail(calendar);
      const userToSwitchTo = allLoggedInUsers.find(
        (user) => user.email === calendarOwnerEmail
      );
      /* Check if user exists and swap if we aren't already on that user */
      if (
        userToSwitchTo &&
        this.props.currentUser.email !== userToSwitchTo.email
      ) {
        Broadcast.publish("SWITCH_ACCOUNTS", { account: userToSwitchTo });
      }

      return;
    };

    const onClickConvertOrSwitch = (
      allLoggedInUsers,
      calendar,
      isCalendarSecondaryAccountVar
    ) => {
      if (isCalendarSecondaryAccountVar) {
        onClickSwitchToUser(allLoggedInUsers, calendar);
      } else {
        onClickConvertToUser();
      }
      return;
    };

    const renderModalContent = () => {
      if (!this.state.shouldDisplayModal) {
        return null;
      }

      const isOwner = isOwnerOfCalendar(
        this.props.allCalendars.allCalendars,
        this.state.moreInformationCalendarId
      );
      const calendar =
        this.props.allCalendars.allCalendars[
          this.state.moreInformationCalendarId
        ];
      const { allLoggedInUsers } = this.props.allLoggedInUsers;
      const { masterAccount } = this.props.masterAccount;
      const {
        currentUser
      } = this.props;
      const isCalendarSecondaryAccountVar = isCalendarSecondaryAccount(
        calendar,
        allLoggedInUsers,
        currentUser
      );
      const { allCalendars } = this.props.allCalendars;

      const renderHideDeleteOption = () => {
        if (getCalendarIsPrimary(calendar)) {
          return null;
        }
        if (isCalendarExecutiveCalendar({ calendar, allLoggedInUsers })) {
          return null;
        }

        if (isCalendarOutlookCalendar(calendar)) {
          if (!isOutlookCalendarDeletable(calendar)) {
            return null;
          }

          return (
            <div
              className="calendar-list-options"
              onClick={() => {
                backendBroadcasts.publish("DELETE_OUTLOOK_CALENDAR", calendar);
                this.closeModal();
              }}
            >
              Remove calendar
            </div>
          );
        }

        return (
          <div className="calendar-list-options" onClick={hideCalendarFromList}>
            Hide from list
          </div>
        );
      };

      const getCopyForSwitchToExecutive = () => {
        if (isCalendarSecondaryAccountVar) {
          const calendarEmail = getCalendarEmail(calendar);
          const matchingUser = allLoggedInUsers.find(user => isSameEmail(getUserEmail(user), calendarEmail));
          if (matchingUser && !isUserDelegatedUser(matchingUser)) {
            return "Switch to my account";
          }
          return "Switch to executive";
        }
        return "Create an Executive Profile";
      };

      return (
        <div>
          <div
            className="calendar-list-options"
            onClick={onClickDisplayOnlyThisCalendar}
          >
            Display this only
          </div>

          {renderHideDeleteOption()}

          {isCalendarConvertableToSecondary({
            allCalendars,
            allLoggedInUsers,
            calendar,
            currentUser,
            masterAccount,
          }) ? (
            <div
              className="calendar-list-options"
              onClick={() =>
                onClickConvertOrSwitch(
                  allLoggedInUsers,
                  calendar,
                  isCalendarSecondaryAccountVar
                )
              }
            >
              {getCopyForSwitchToExecutive()}
            </div>
          ) : null}
          <div
            className="calendar-list-options border-bottom-line"
            onClick={() => {
              layoutBroadcast.publish(APP_SETTINGS.OPEN_SETTINGS_MODAL, {
                initialContent: APP_SETTINGS.CALENDAR_SETTINGS,
              });
              this.closeModal();
            }}
          >
            {isOwner && !isCalendarOutlookCalendar(calendar)
              ? "Settings and sharing"
              : "Settings"}
          </div>
          <div className="p-4 flex flex-row">
            {isCalendarOutlookCalendar(calendar)
              ? this.renderOutlookCalendarColor()
              : this.renderGoogleCalendarColors()}
          </div>
        </div>
      );
    };

    return (
      <EventModalPopup
        isOpen={this.state.shouldDisplayModal}
        onRequestClose={this.closeModal}
        width={"100%"}
        style={MODAL_STYLE}
        hideCloseButton={true}
        hideTitle={true}
      >
        {renderModalContent()}
      </EventModalPopup>
    );
  }

  renderClearAndApplyTimeZone() {
    if (!this.hasSearchedCalendars()) {
      return null;
    }
    const {
      searchedEmails,
    } = this.state;
    const {
      currentTimeZone,
    } = this.props;
    const {
      userTimeZoneIndex,
    } = this.props.userTimeZoneIndexStore;

    const onClickApplyTimeZones = () => {
      const groupTimeZones = addDefaultToArray(searchedEmails)
        .map(email => userTimeZoneIndex[email])
        .filter(tz => isValidTimeZone(tz));

      if (isEmptyArrayOrFalsey(groupTimeZones)) {
        // sanity check
        return;
      }
      const isOnlyCurrentTimeZone = groupTimeZones.length === 1 && groupTimeZones[0] === currentTimeZone;
      if (isOnlyCurrentTimeZone) {
        Broadcast.publish(
          SET_DISAPPEARING_NOTIFICATION_MESSAGE,
          GUESSED_TIME_ZONES_ON_APPLY_ALL_COPY,
        );
        return;
      }
      Broadcast.publish(
        "SELECT_TIME_ZONE",
        {
          timeZone: currentTimeZone, // always keep time zone
          groupTimeZone: groupTimeZones,
          keepCurrentTimeZone: true,
          customMessage: GUESSED_TIME_ZONES_ON_APPLY_ALL_COPY,
        }
      );
    };

    const shouldShowApplyTimeZones = searchedEmails.some(email => userTimeZoneIndex[email]);
    return (
      <div className="flex justify-between items-center mt-4">
        {shouldShowApplyTimeZones ?
          <div
            className="hoverable-secondary-text-color select-none"
            onClick={onClickApplyTimeZones}
          >
            Apply all time zones
          </div>
          : <div></div>}
        <div
          className="hoverable-secondary-text-color"
          onClick={this.removeAllSelectedTemporarySecondaryCalendars}
        >
          Clear
        </div>
      </div>
    );
  }

  renderOutlookCalendarColor() {
    const shouldRenderCheck = (color) => {
      const { allCalendars } = this.props.allCalendars;
      return (
        determineCalendarColor(
          allCalendars[this.state.moreInformationCalendarId]
        )?.toLowerCase() === color
      );
    };

    const renderColumn = ({ start, end, addMarginRight }) => {
      const colors = OUTLOOK_CALENDAR_COLORS.slice(start, end);
      return (
        <div
          className={classNames("flex flex-col", addMarginRight ? "mr-5" : "")}
        >
          {colors.map((c, index) => {
            return (
              <div
                className={classNames(
                  "select-color-container relative",
                  "mt-1"
                )}
                key={`select-color-container-${index}`}
                onClick={() => {
                  BackendBroadcasts.publish("UPDATE_CALENDAR_PROPERTIES", {
                    calendarID: this.state.moreInformationCalendarId,
                    updatedProperties: { colorId: c.colorId },
                    userEmail: getUserEmailFromUserCalendarID(
                      this.state.moreInformationCalendarId,
                      this.props.allCalendars.allCalendars
                    ),
                  });

                  this.closeModal();
                }}
              >
                <div className="color-name-hint -top-12">
                  {capitalizeFirstLetter(c.label)}
                </div>

                <div
                  key={`select-color-${index}`}
                  className="color-circle"
                  style={{
                    backgroundColor: c.color,
                    borderRadius: "50%",
                  }}
                >
                  {shouldRenderCheck(c.color) ? (
                    <Check size={12} color={"white"} />
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
      );
    };

    return (
      <>
        {renderColumn({ start: 0, end: 3, addMarginRight: true })}
        {renderColumn({ start: 3, end: 6, addMarginRight: true })}
        {renderColumn({ start: 6, end: 9, addMarginRight: false })}
      </>
    );
  }

  renderGoogleCalendarColors() {
    const calendarColors = GoogleColors.calendarColors;

    const shouldRenderCheck = (color) => {
      const { allCalendars } = this.props.allCalendars;
      return (
        determineCalendarColor(
          allCalendars[this.state.moreInformationCalendarId]
        )?.toLowerCase() === color
      );
    };

    const renderColumn = (start, end) => {
      const colors = calendarColors.slice(start, end);
      return (
        <div className="flex flex-col">
          {colors.map((c, index) => {
            return (
              <div
                className="select-color-container relative"
                key={`select-color-container-${index}`}
                onClick={() => {
                  BackendBroadcasts.publish("UPDATE_CALENDAR_PROPERTIES", {
                    calendarID: this.state.moreInformationCalendarId,
                    updatedProperties: { colorId: c.colorId },
                    userEmail: getUserEmailFromUserCalendarID(
                      this.state.moreInformationCalendarId,
                      this.props.allCalendars.allCalendars
                    ),
                  });

                  this.closeModal();
                }}
              >
                <div className="color-name-hint -top-10">
                  {capitalizeFirstLetter(c.label)}
                </div>

                <div
                  key={`select-color-${index}`}
                  className="color-circle"
                  style={{
                    backgroundColor: c.color,
                    borderRadius: "50%",
                  }}
                >
                  {shouldRenderCheck(c.color) ? (
                    <Check size={12} color={"white"} />
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
      );
    };

    return (
      <>
        {renderColumn(0, 4)}
        {renderColumn(4, 8)}
        {renderColumn(8, 12)}
        {renderColumn(12, 16)}
        {renderColumn(16, 20)}
        {renderColumn(20, 24)}
      </>
    );
  }

  renderFindTimeEvents() {
    if (!shouldShowFindTimeEventForm(this.props.currentUser)) {
      return;
    }
    const emailList = this.getSearchedEmailList();
    if (isEmptyArray(emailList)) {
      return null;
    }
    const sharedBorderClassName =
      "hoverable-border h-5 w-24 h-8 rounded-full flex items-center justify-center cursor-pointer";
    const onClickFindTimeSlots = () => {
      AvailabilityBroadcast.publish("GET_AVAILABLE_SLOTS_FIND_TIME");
    };
    return (
      <div
        className="mb-2 ml-2.5 mt-2.5 flex items-center justify-between width-320px"
        id={CALENDAR_LIST_FIND_TIME_BOTTON_ID}
      >
        <div className="flex items-center default-font-size secondary-text-color">
          <AILogo
            fillColor={
              this.props.isDarkMode
                ? DARK_MODE_SECONDARY_TEXT_COLOR
                : LIGHT_MODE_SECONDARY_TEXT_COLOR
            }
          />
          <div className="ml-2">Find times for</div>
        </div>

        <div className="flex">
          <div
            className={classNames(sharedBorderClassName, "mr-2.5")}
            onClick={this.findTimeCreateEvent}
          >
            Meeting
          </div>
          <div
            className={classNames(sharedBorderClassName)}
            onClick={onClickFindTimeSlots}
          >
            Slots
          </div>
        </div>
      </div>
    );
  }

  findTimeCreateEvent(inputDuration) {
    const emailList = this.getSearchedEmailList();
    if (isEmptyArrayOrFalsey(emailList)) {
      return;
    }
    const matchingUser = this.getSearchedEmailUserDomin(emailList);
    const { setFindTimeData } = this.props.temporaryStateStore;
    const duration = isInt(inputDuration) ? inputDuration : null;
    mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.SHOW_FREE_TIMES_FOR_USER, {
      inputUser: matchingUser,
      inputDuration: duration, // so we don't pass in an onClick event or something
      shouldJumpToFirstDate: true,
    });
    setFindTimeData({
      user: matchingUser,
      duration: duration,
    });
  }

  renderListOfSearchedEmails() {
    const list = this.getSearchedEmailList();
    const determineSecondaryCalendarButtonColor = (email) => {
      const matchingCalendar = this.getCalendarFromEmail(email);
      if (matchingCalendar) {
        return determineCalendarColor(matchingCalendar);
      } else {
        const determineDefaultColor = () => {
          return this.props.isDarkMode ? MEDIUM_GRAY : "rgb(224, 224, 224)";
        };

        return (
          this.props.temporarySecondaryCalendarColorsIndex[email] ||
          determineDefaultColor()
        );
      }
    };

    if (isEmptyArray(list)) {
      return null;
    }

    const onSetHoverIndex = (meetWithHoverIndex) => {
      this.setState({ meetWithHoverIndex });
    };
    const renderHint = ({index, email, displayName}) => {
      const {
        meetWithHoverIndex,
      } = this.state;
      if (index !== meetWithHoverIndex || displayName === email) {
        return null;
      }
      return (
        <div
          className={classNames("expanded-event-email-suggestion left-0px-override", "absolute", "z-50", "top-8")}
        >
          <div>{email}</div>
        </div>
      );
    };

    const { masterAccount } = this.props.masterAccount;
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const { allCalendars } = this.props.allCalendars;
    const {
      currentUser,
      emailToNameIndex,
    } = this.props;
    const {
      distroListDictionary
    } = this.props.distroListDictionary;

    return list.map((email, index) => {
      const backgroundColor = determineSecondaryCalendarButtonColor(email);
      const displayName = convertEmailToName({
        email,
        currentUser,
        emailToNameIndex,
        masterAccount,
        allLoggedInUsers,
        allCalendars,
        distroListDictionary,
      });

      return (
        <div
          className={classNames(
            "temporary-searched-calendars",
            index === list.length - 1 ? "" : "mb-2",
            "relative",
          )}
          key={`calendar_search_${index}`}
          style={{ border: `2px solid ${backgroundColor}` }}
        >
          <div className="flex flex-row items-center">
            <div
              className={classNames("searched-calendar-email")}
            >
              {displayName}{" "}
              {stringArrayContainsIgnoringCase(this.state.calendarsWithNoAccess, email) && "*"}
            </div>
            <UserLocalTimeZone email={email} className={"ml-2"}/>
            <X
              size={14}
              className="clickable-icon ml-1.5"
              onClick={() =>
                this.determineRemoveSelectTemporarySecondaryCalendar(email)
              }
            />
          </div>
          {renderHint({index, email, displayName})}
        </div>
      );
    });
  }

  renderAllPermanentUser() {
    const {
      allLoggedInUsers,
      collapsedUserCalendarList,
      setCollapsedUserCalendarList,
    } = this.props.allLoggedInUsers;
    const {
      allCalendars,
    } = this.props.allCalendars;
    const { masterAccount } = this.props.masterAccount;

    const renderCalendarPerUser = (user) => {
      return (
        <DraggableCalendarSection
          user={user}
          toggleSelectCalendar={this.toggleSelectCalendar}
          handleOnClickMoreOptions={this.handleOnClickMoreOptions}
        />
      );
    };

    const onToggleCollapseCalendarList = (email) => {
      if (!stringArrayContainsIgnoringCase(collapsedUserCalendarList, email)) {
        const updatedList = removeDuplicatesFromArray(
          collapsedUserCalendarList.concat(email)
        );
        setCollapsedUserCalendarList(updatedList);
      } else {
        const updatedList = collapsedUserCalendarList.filter(
          (c) => c !== email
        );
        setCollapsedUserCalendarList(updatedList);
      }
    };
    const getUserDisplayName = (user) => {
      if (user?.full_name && isTypeString(user.full_name)) {
        return user.full_name;
      }
      return getUserEmail(user);
    };

    return orderLoggedInUsers(
      allLoggedInUsers,
      getUserEmail(this.props.currentUser),
    ).map((user, index) => {
      if (shouldHideDelegatedUser({ user, allCalendars })) {
        return null;
      }
      const email = getUserEmail(user);
      return (
        <div
          key={`permanent_calendar_user_${email}`}
          className={index !== allLoggedInUsers.length - 1 ? "mb-4" : ""}
        >
          <div
            className={classNames(
              "flex items-center ml-4",
              "justify-between",
              "cursor-pointer",
              "text-color-on-hover-user-email-section",
              "duration-200",
              "mb-1",
              "hoverable-secondary-text-color"
            )}
            onClick={() => onToggleCollapseCalendarList(email)}
          >
            <div className="default-font-size font-weight-400 duration-200">
              {isMaestroUserOnDelegatedAccount({masterAccount, user}) || isEmailGroupEmail(email) ? getUserDisplayName(user) : email}
            </div>

            <ExpandIconWithAnimation
              isOpen={!stringArrayContainsIgnoringCase(collapsedUserCalendarList, email)} 
              className={"mr-2"}
            />
          </div>

          <div
            className={classNames(
              "duration-200",
              stringArrayContainsIgnoringCase(collapsedUserCalendarList, email) ? "hidden" : ""
            )}
          >
            {renderCalendarPerUser(user)}
          </div>
        </div>
      );
    });
  }

  renderContactGroupsTip() {
    const { masterAccount } = this.props.masterAccount;
    const list = this.getSearchedEmailList();

    if (
      list.length > 1 &&
      ((!isTooltipCompleted(masterAccount, tooltipKeys.CONTACT_GROUPS) &&
        !this.state.shouldHideContactGroupsTooltip) ||
        !this.state.shouldHideContactGroupsTooltip)
    ) {
      return (
        <div className="mt-2.5 ml-4 mr-4">
          <TooltipBox
            description="Contact Groups lets you nickname a group of teammates (e.g. “Sales”) and use that to include everyone instead of searching one by one."
            hideLearnMore={true}
            onClick={(e) => {
              hasEventPreventDefault(e);
              hasStopEventPropagation(e);
              this.setState({
                shouldHideContactGroupsTooltip: true,
              });
            }}
            title="Did You Know?"
          />
        </div>
      );
    }

    return null;
  }

  //================
  // EVENT HANDLERS
  //================

  matchContactNameWithEmail() {
    const { allCalendars } = this.props.allCalendars;

    let searchArray = [];

    Object.keys(allCalendars).forEach((k) => {
      const email = getCalendarProviderId(allCalendars[k]);
      searchArray = searchArray.concat(email.toLowerCase());
    });

    this.searchContactsDomainDB(searchArray);
  }

  async searchContactsDomainDB(searchArray) {
    const { emailToNameIndex, currentUser } = this.props;

    const { email } = currentUser;

    if (isEmptyArrayOrFalsey(searchArray) || !email) {
      return;
    }

    const filteredArray = searchArray.filter(
      (email) => !emailToNameIndex[email]
    ); // filter for existing values
    if (isEmptyArray(filteredArray)) {
      return;
    }

    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const { masterAccount } = this.props.masterAccount;

    const { contactResponse, domainResponse } = await getDomainAndContacts({
      allLoggedInUsers,
      masterAccount,
      currentUser,
      userEmail: getUserEmail(currentUser),
      searchArray: filteredArray,
    });

    if (!this._isMounted) {
      return;
    }

    const dbResponse = domainResponse.concat(contactResponse);
    const updatedIndexPortion = {};
    dbResponse.forEach((r) => {
      const e = lowerCaseAndTrimString(r.email);
      if (r?.name?.length > 0 && !emailToNameIndex[e] && !isValidEmail(r.name)) {
        // has name and name does not exist in index
        updatedIndexPortion[e] = r.name;
      }
    });

    if (isEmptyArray(Object.keys(updatedIndexPortion))) {
      // no new keys
      return;
    }

    this.props.addEmailToEmailNameIndex({
      ...emailToNameIndex,
      ...updatedIndexPortion,
    });
  }

  createExistingEmailList() {
    const combinedEmailList = this.state.searchedEmails.concat(
      this.state.calendarsWithNoAccess
    );

    return combinedEmailList;
  }

  removeSelectTemporarySecondaryCalendar(email) {
    let updatedSearchedEmails = this.state.searchedEmails.filter(
      (e) => e !== email
    );
    let updatedNonAccessEmails = this.state.calendarsWithNoAccess.filter(
      (e) => e !== email
    );

    let updatedTemporarySecondaryCalendarColors =
      this.props.temporarySecondaryCalendarColorsIndex;

    updatedTemporarySecondaryCalendarColors = _.omit(
      updatedTemporarySecondaryCalendarColors,
      email
    );

    this.props.setTemporarySecondaryCalendarColorsIndex(
      updatedTemporarySecondaryCalendarColors
    );

    this.setState({
      searchedEmails: updatedSearchedEmails,
      calendarsWithNoAccess: updatedNonAccessEmails,
    });

    mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.ADD_TEMPORARY_SEARCHED_EVENTS, {
      filterOutMeetWithEventsWithCalendarId: email,
    });
  }

  removeAllSelectedTemporarySecondaryCalendars() {
    const {
      currentUser,
    } = this.props;
    const saveGroupedEmails = () => {
      //example of contact: {email: "sophie@vimcal.com", updated: null, name: "Sophie Uran", fullName: Array(3)}
      let emails = this.state.searchedEmails;
      if (isEmptyArrayOrFalsey(emails) || emails.length <= 1) {
        // only do it if there's more than 1
        return { name: null, email: null, emailArray: null };
      }

      return getCombinedEmailContact(emails, this.props.emailToNameIndex);
    };

    const { email, name, emailArray } = saveGroupedEmails();

    // if has matching nick name -> update nick name and append that in
    let matchingNickNameContactGroup = getMatchingContactGroup(
      emailArray,
      currentUser
    );
    if (emailArray?.length > 1) {
      let updatedRecentlySearchedContacts = updateRecentlySearchedContactsArray(
        getRecentlySearchedContacts(currentUser),
        matchingNickNameContactGroup
          ? Object.assign(matchingNickNameContactGroup, {
              updated: new Date().toISOString(),
            })
          : {
              email,
              name,
              emailArray,
              hasMultiple: true,
              updated: new Date().toISOString(),
            }
      );
      backendBroadcasts.publish(
        "UPDATE_RECENTLY_SEARCHED_CONTACTS",
        updatedRecentlySearchedContacts
      );
    }

    mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.REMOVE_FIND_TIME_EVENTS);

    batch(() => {
      this.props.removeEventFormEmails();
      this.props.removeTemporarySecondaryCalendarColorsIndex();

      if (!this.props.currentTimeZoneLabel) {
        this.props.setShouldShowTopBar(false);
      }
    });

    this.fetchEventsNumber = createUUID();
    mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.ADD_TEMPORARY_SEARCHED_EVENTS, {
      eventFormEvents: [],
      meetWithEvents: [],
    });

    this.setState({
      searchedEmails: [],
      calendarsWithNoAccess: [],
      calendarEmailEventsIndex: {},
    });
  }

  searchEmail(attendee) {
    let newAttendee = attendee.value;
    let emailWithoutWhiteSpaces = newAttendee ? newAttendee.trim() : "";

    if (!isValidEmail(emailWithoutWhiteSpaces)) {
      return;
    } else {
      const { contact } = attendee;

      let inputContact = {
        email: newAttendee,
        fullName: splitUpStringIntoArray(attendee.name),
        name: attendee.name || "",
        updated: "",
      };

      this.addEmailToSearchedEmails(
        emailWithoutWhiteSpaces,
        contact ?? inputContact
      );
    }
  }

  addEmailToSearchedEmails(
    newAttendee,
    contact = null,
    userEmail,
    isTeamSlots = false
  ) {
    if (isTypeString(newAttendee) && !isValidEmail(newAttendee)) {
      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        "Please enter a valid email"
      );
      return;
    }
    let updatedSearchedEmails = this.state.searchedEmails;
    const eventFormEmails = this.props.eventFormEmails || [];
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const { currentUser } = this.props;
    const {
      allCalendars,
    } = this.props.allCalendars;
    const {
      masterAccount,
    } = this.props.masterAccount;

    updatedSearchedEmails =
      stringArrayContainsIgnoringCase(updatedSearchedEmails, newAttendee) ||
      stringArrayContainsIgnoringCase(eventFormEmails, newAttendee)
        ? updatedSearchedEmails
        : updatedSearchedEmails.concat(newAttendee);
    const filteredUpdatedSearchedEmails = updatedSearchedEmails.filter(email => isValidEmail(email));

    this.fetchEventsForSearchedContacts({
      fetchEventsNumber: this.fetchEventsNumber,
      emails: filteredUpdatedSearchedEmails,
      existingEmails: this.state.searchedEmails,
      where: "addEmailToSearchedEmails",
      userEmail:
        userEmail && isTypeString(userEmail) && isValidEmail(userEmail) && isUserEmailPartOfAllLoggedInUsers({ allLoggedInUsers, userEmail })
          ? userEmail
          : getMeetWithUserEmail({
            allLoggedInUsers,
            contact,
            currentUser,
            allCalendars,
            masterAccount,
          }),
      isTeamSlots,
    });

    // Set emails
    let updatedEventFormEmails = this.props.eventFormEmails || [];
    updatedEventFormEmails = newAttendee
      ? removeDuplicatesFromArray(updatedEventFormEmails.concat(newAttendee))
      : updatedEventFormEmails;

    // Update recently searched contacts
    if (contact) {
      const updatedRecentlySearchedContacts =
        updateRecentlySearchedContactsArray(
          getRecentlySearchedContacts(this.props.currentUser),
          contact
        );
      backendBroadcasts.publish(
        "UPDATE_RECENTLY_SEARCHED_CONTACTS",
        updatedRecentlySearchedContacts
      );
    }

    this.setState(
      {
        searchedEmails: filteredUpdatedSearchedEmails,
        emailInSearch: "",
      },
      () => {
        batch(() => {
          this.updateEventFormEmails(updatedEventFormEmails); // update event form emails reducer here

          if (isActionModeCreateAvailability(this.props.actionMode)) {
            const newEmails = [].concat(newAttendee); // could be single instance or array
            AvailabilityBroadcast.publish("ADD_ATTENDEE", newEmails);
          }

          if (!this.props.shouldShowTopBar) {
            this.props.setShouldShowTopBar(true);
          }
        });

        /* Check if we should complete contact groups tooltip */
        const { masterAccount } = this.props.masterAccount;
        const currentList = this.getSearchedEmailList();

        /* Compare lists to check if previous had one or less and current has more than 1 */
        /* Also check that tooltip has not been completed */
        if (
          currentList.length >= 2 &&
          !isTooltipCompleted(masterAccount, tooltipKeys.CONTACT_GROUPS)
        ) {
          this.setState({
            shouldHideContactGroupsTooltip: false,
          });
          Broadcast.publish(
            "MARK_TOOLTIP_COMPLETED",
            tooltipKeys.CONTACT_GROUPS
          );
        }
      }
    );
  }

  // remove calendars from cmd j
  removeCalendarsFromTemporarySearchedCalendars(previousList, newEmailList) {
    // Need to update: searchedEmails: [],
    // calendarsWithNoAccess: [],
    // calendarEmailEventsIndex: {}

    let previousEmailList = previousList || [];

    let removedEmails = previousEmailList.filter(
      (e) => !stringArrayContainsIgnoringCase(newEmailList, e)
    );

    let updatedSearchedCalendars = this.state.searchedEmails || [];
    let updatedCalendarEmailEventsIndex =
      this.state.calendarEmailEventsIndex || {};
    let updatedCalendarsWithNoAccess = this.state.calendarsWithNoAccess || [];

    if (removedEmails.length > 0) {
      updatedSearchedCalendars = updatedSearchedCalendars.filter(
        (e) => !stringArrayContainsIgnoringCase(removedEmails, e)
      );

      removedEmails.forEach((e) => {
        updatedCalendarEmailEventsIndex = _.omit(
          updatedCalendarEmailEventsIndex,
          e
        );
      });

      updatedCalendarsWithNoAccess = updatedCalendarsWithNoAccess.filter(
        (e) => !stringArrayContainsIgnoringCase(removedEmails, e)
      );

      mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.ADD_TEMPORARY_SEARCHED_EVENTS, {
        filterEventFormExistingCalendarIds: newEmailList,
        filterMeetWithExistingCalendarIds: updatedSearchedCalendars,
      });
    }

    this.setState({
      searchedEmails: updatedSearchedCalendars,
      calendarsWithNoAccess: updatedCalendarsWithNoAccess,
      calendarEmailEventsIndex: updatedCalendarEmailEventsIndex,
    });
  }

  changeCalendarsFromEventForm({ previousList, newEmails, userEmail }) {
    const emailList = newEmails || [];

    const existingList = previousList || [];

    const filteredExistingEmails = existingList.filter(
      (e) => !stringArrayContainsIgnoringCase(this.state.searchedEmails, e)
    );

    let temporarySearchedEmails = this.state.searchedEmails || [];
    if (!isEmptyObjectOrFalsey(this.state.calendarEmailEventsIndex)) {
      // also check for event form search emails
      temporarySearchedEmails = temporarySearchedEmails.concat(
        Object.keys(this.state.calendarEmailEventsIndex)
      );
    }
    temporarySearchedEmails = removeDuplicatesFromArray(
      temporarySearchedEmails
    );

    if (emailList.length <= temporarySearchedEmails.length) {
      this.removeCalendarsFromTemporarySearchedCalendars(
        previousList,
        emailList
      );

      if (
        this.props.shouldShowTopBar &&
        !this.props.currentTimeZoneLabel &&
        (isEmptyArrayOrFalsey(emailList))
      ) {
        this.props.setShouldShowTopBar(false);
      }
    } else {
      // no reason to search for emails that are already active
      const activeCalendarEmails = this.getActiveCalendarEmails();
      const emailsNotInSecondaryCalendarAlready = emailList.filter(
        (e) =>
          !stringArrayContainsIgnoringCase(this.state.searchedEmails, e) &&
          !stringArrayContainsIgnoringCase(activeCalendarEmails, e)
      );

      this.fetchEventsForSearchedContacts({
        fetchEventsNumber: this.fetchEventsNumber,
        emails: emailsNotInSecondaryCalendarAlready,
        existingEmails: filteredExistingEmails,
        fromSideMenuBar: false,
        addToNoAccess: false,
        userEmail,
        where: "changeCalendarsFromEventForm",
      });
    }
  }

  getActiveCalendarEmails() {
    const { allCalendars } = this.props.allCalendars;

    // no reason to search for emails that are already active
    return getActiveCalendarEmailsFromAllCalendars({ allCalendars });
  }

  toggleOnOnlySelectCalendars(exceptionList) {
    // exception list is a list of user_calendar_ids
    // toggle on calendars that are currently turned off
    if (isEmptyArray(exceptionList)) {
      return;
    }

    const { allCalendars } = this.props.allCalendars;

    const updateWebHooks = () => {
      Object.values(allCalendars).forEach((calendar) => {
        const userCalendarID = getCalendarUserCalendarID(calendar);
        if (
          !isCalendarSelected(calendar) &&
          exceptionList.includes(userCalendarID)
        ) {
          // is currently not selected but in exception list -> turn on
          Broadcast.publish("TOGGLE_SELECT_CALENDAR_CABLE", calendar, true);
        } else if (
          isCalendarSelected(calendar) &&
          !exceptionList.includes(userCalendarID)
        ) {
          // is currently selected and not in exception list -> turn off
          Broadcast.publish("TOGGLE_SELECT_CALENDAR_CABLE", calendar, false);
        }
      });
    };

    const preUnselectAllCalendars = () => {
      // unselect everything at first
      const updatedAllCalendars = produce(allCalendars, (draftState) => {
        Object.keys(draftState).forEach((k) => {
          if (exceptionList.includes(k)) {
            draftState[k].selected = true;
          } else {
            draftState[k].selected = false;
          }
        });
      });
      this.updateAllCalendars(updatedAllCalendars, "setAllCalendars_2");

      mainCalendarBroadcast.publish(
        MAIN_CALENDAR_BROADCAST_VALUES.INITIALIZE_EVENTS_IN_WEEKLY_CALENDAR,
        updatedAllCalendars
      );
    };

    updateWebHooks();

    const calendarsThatNeedToToggleOn = Object.values(allCalendars).filter((calendar) => {
      const userCalendarID = getCalendarUserCalendarID(calendar);
      if (exceptionList.includes(userCalendarID) && !isCalendarSelected(calendar)) {
        return true;
      }
    });

    const timeWindows = determineSyncWindow({
      selectedDay: this.props.selectedDay,
      selectedCalendarView: this.props.selectedCalendarView,
      weekStart: this.props.weekStart,
    });

    preUnselectAllCalendars(); // unselect calendars on the front end so there's no delay

    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    this.getPreviewOutlookEventOnToggle({
      users: allLoggedInUsers,
      calendars: calendarsThatNeedToToggleOn,
      timeMin: timeWindows.minDate,
      timeMax: timeWindows.maxDate,
    });

    let promiseArray = [];
    calendarsThatNeedToToggleOn.forEach((calendar) => {
      const path = `calendars/${getCalendarUserCalendarID(calendar)}/toggle_on`;
      const url = constructRequestURL(path, isVersionV2());
      const body = {
        timeMin: timeWindows.minDate.toISOString(),
        timeMax: timeWindows.maxDate.toISOString(),
        timeZone: this.props.currentTimeZone,
        user_calendar_id: getCalendarUserCalendarID(calendar),
      };
      const payloadData = {
        headers: getDefaultHeaders(),
        body: JSON.stringify(body),
      };
      promiseArray = promiseArray.concat(
        Fetcher.patch(url, payloadData, true, getCalendarUserEmail(calendar))
      );
    });

    Promise.all(promiseArray).then((allResponses) => {
      if (!allResponses || !this._isMounted) {
        return;
      }
      // fetch original recurring events on toggle calendar
      let allFormattedEvents = [];
      allResponses.forEach((r) => {
        if (r?.events?.length > 0) {
          const { formatEventsList } = formatGCalEventsList({
            currentTimeZone: this.props.currentTimeZone,
            eventList: r?.events,
            shouldFilterForActive: true,
          });
          allFormattedEvents = allFormattedEvents.concat(formatEventsList);
          this.storeEventsIntoDBAndToggleCalendar({
            events: formatEventsList,
            userEmail: r?.calendar?.user_email, // does not come in as calendar.calendar objecte
          }); // store into indexdb
        }
      });

      let eventsWithinWindow = [];

      const {
        dbWindowStartJSDate,
        dbWindowEndJSDate,
        todayLeftJSDate,
        todayRightJSDate,
      } = this.getWindow();

      if (!isEmptyArrayOrFalsey(allFormattedEvents)) {
        eventsWithinWindow = allFormattedEvents.filter(
          (e) =>
            isEventWithinJSWindow({
              event: e,
              windowStart: dbWindowStartJSDate,
              windowEnd: dbWindowEndJSDate,
            }) ||
            isEventWithinJSWindow({
              event: e,
              windowStart: todayLeftJSDate,
              windowEnd: todayRightJSDate,
            })
        );
      }

      if (eventsWithinWindow.length > 0) {
        mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.UPDATE_LIST_OF_EVENTS_INTO_WEEKLY_CALENDAR, {
          listOfEvents: eventsWithinWindow,
        });
      }
    });
  }

  toggleCalendarWithUserCalendarID(userCalendarID) {
    const { allCalendars } = this.props.allCalendars;
    this.toggleSelectCalendar(allCalendars[userCalendarID]);
  }

  toggleSelectCalendar(calendar) {
    if (!isValidCalendar(calendar)) {
      return;
    }

    const isToggleOn = isCalendarSelected(calendar) ? false : true;
    const userCalendarId = getCalendarUserCalendarID(calendar);
    const path = `calendars/${userCalendarId}/toggle_on`;
    const url = constructRequestURL(path, isVersionV2());

    Broadcast.publish(
      "TOGGLE_SELECT_CALENDAR_CABLE",
      calendar,
      isToggleOn
    );

    if (isToggleOn) {
      trackEvent({
        category: "home",
        action: "selected_secondary_calendar",
        label: "side_menu_bar",
        userToken: getUserToken(this.props.currentUser),
      });
    }

    this.handleToggleSelectCalendar({calendar, url, isToggleOn});
  }

  updateAllCalendars(updatedAllCalendars, where) {
    const {
      currentUser
    } = this.props;
    const { setAllCalendars } =
      this.props.allCalendars;
    const {
      userTimeZoneIndex,
      setUserTimeZoneIndex,
      userTimeZoneLastSetIndex,
      setUserTimeZoneLastSetIndex,
    } = this.props.userTimeZoneIndexStore;
    const {
      newUserTimeZonesIndex,
      newUserTimeZoneLastSetIndex
    } = getUpdatedUserTimeZonesIndexAndLastSet({allCalendars: updatedAllCalendars});
    setUserTimeZoneIndex({
      ...userTimeZoneIndex,
      ...newUserTimeZonesIndex,
    });
    setUserTimeZoneLastSetIndex({
      ...userTimeZoneLastSetIndex,
      ...newUserTimeZoneLastSetIndex,
    });
    setAllCalendars(
      updatedAllCalendars,
      currentUser,
      where || "calendarList::updateAllCalendars",
    );
  }

  handleToggleSelectCalendar({
    calendar,
    url,
    isToggleOn,
  }) {
    const { allCalendars, setCalendar } =
      this.props.allCalendars;
    const {
      currentUser,
    } = this.props;

    if (getCalendarIsPrimary(calendar)) {
      // Do not toggle primary calendars or main calendars
      const updatedAllCalendars = produce(allCalendars, (draftState) => {
        if (draftState[getCalendarUserCalendarID(calendar)]) {
          draftState[getCalendarUserCalendarID(calendar)].selected = isToggleOn;
        }
      });
      this.updateAllCalendars(updatedAllCalendars, "setAllCalendars_3");

      mainCalendarBroadcast.publish(
        MAIN_CALENDAR_BROADCAST_VALUES.INITIALIZE_EVENTS_IN_WEEKLY_CALENDAR,
        updatedAllCalendars
      );

      // still need to fetch these events
      if (isToggleOn) {
        // no reason to fetch if not toggled on
        BackendBroadcasts.publish(BACKEND_BROADCAST_VALUES.FETCH_EVENTS_FOR_CALENDAR, {
          calendar,
          inputAllCalendars: updatedAllCalendars,
        });
      }
      return;
    }

    if (isCalendarSelected(calendar)) {
      const updatedAllCalendars = produce(allCalendars, (draftState) => {
        if (draftState[getCalendarUserCalendarID(calendar)]) {
          draftState[getCalendarUserCalendarID(calendar)].selected = false;
        }
      });
      this.updateAllCalendars(updatedAllCalendars, "setAllCalendars_4");
      mainCalendarBroadcast.publish(
        MAIN_CALENDAR_BROADCAST_VALUES.INITIALIZE_EVENTS_IN_WEEKLY_CALENDAR,
        updatedAllCalendars
      );

      /* Return as we do not need to send request to backend. */
      return;
    } else {
      setCalendar({
        userCalendarID: getCalendarUserCalendarID(calendar),
        updatedCalendar: { isFetching: true },
        currentUser,
      });
    }

    const calendarEmail = getCalendarProviderId(calendar);
    let params;
    let queryParams;

    if (calendar.lastSyncedAt) {
      params = { last_synced_at: calendar.lastSyncedAt };
      queryParams = constructQueryParams(params);
      url += `?${queryParams}`;
    }

    const timeWindows = determineSyncWindow({
      selectedDay: this.props.selectedDay,
      selectedCalendarView: this.props.selectedCalendarView,
      weekStart: this.props.weekStart,
    });

    const body = {
      timeMin: timeWindows.minDate.toISOString(),
      timeMax: timeWindows.maxDate.toISOString(),
      timeZone: this.props.currentTimeZone,
      calendarIds: [calendarEmail],
    };

    const payloadData = {
      headers: getDefaultHeaders(),
      body: JSON.stringify(body),
    };
    let requestTimeOut = setTimeout(() => {
      if (!this._isMounted) {
        return;
      }

      handleFetchError();
    }, 12 * SECOND_IN_MS);
    const handleFetchError = () => {
      if (requestTimeOut) {
        clearTimeout(requestTimeOut);
        requestTimeOut = null;
      }
      setCalendar({
        userCalendarID: getCalendarUserCalendarID(calendar),
        updatedCalendar: { isFetching: false },
        currentUser,
      });
    };

    const userEmail = getCalendarUserEmail(calendar);
    if (!userEmail) {
      return;
    }

    const {
      allLoggedInUsers
    } = this.props.allLoggedInUsers;
    const matchingUser = getMatchingUserFromAllUsers({ 
      allUsers: allLoggedInUsers,
      userEmail: getCalendarUserEmail(calendar),
    });

    this.getPreviewOutlookEventOnToggle({
      users: [matchingUser],
      calendars: [calendar],
      timeMin: timeWindows.minDate,
      timeMax: timeWindows.maxDate,
    });

    return Fetcher.patch(url, payloadData, true, userEmail, handleFetchError)
      .then((response) => {
        if (!this._isMounted) {
          return;
        }
        clearTimeout(requestTimeOut);
        requestTimeOut = null;
        if (isEmptyObjectOrFalsey(response) || !response?.calendar) {
          handleFetchError();
          return;
        }

        const responseCalendar = response?.calendar;
        const responseUserCalendarID = getCalendarUserCalendarID(response);

        if (allCalendars[responseUserCalendarID]) {
          const updatedCalendar = {
            isFetching: false,
            selected: isToggleOn,
            calendar: responseCalendar,
            lastSyncedAt: response.last_synced_at
              ? response.last_synced_at
              : undefined,
          };
          /* Fix for checkbox temporarily being unchecked when user uncheck then check faster than fetch */
          if (!isToggleOn) {
            delete updatedCalendar.isFetching;
          }
          setCalendar({
            userCalendarID: responseUserCalendarID,
            updatedCalendar: updatedCalendar,
            currentUser,
          });
        }

        this.formatFetchedToggleEvents({ response, userEmail });
      })
      .catch((err) => {
        /* Could not get the handleFetchError to proc in here. */
        /* Is error handling working or am I doing it wrong? */
        handleError(err);
      });
  }

  formatFetchedToggleEvents({ response, userEmail }) {
    if (isEmptyArrayOrFalsey(response.events)) {
      return;
    }

    const { allCalendars } = this.props.allCalendars;
    const {
      currentUser
    } = this.props;

    const filteredList = filterOtherPeoplesCalendarsAndReport({
      events: response.events,
      allCalendarIds: getAllCalendarUserCalendarIDs(allCalendars),
      location: "formatFetchedToggleEvents-sideMenuBar",
      currentUserEmail: getUserEmail(currentUser),
    });

    const timeZone = this.props.currentTimeZone;
    const calendarId = getCalendarUserCalendarID(response);

    const { formatEventsList } = formatGCalEventsList({
      currentTimeZone: timeZone,
      eventList: filteredList,
      shouldFilterForActive: true,
      calendarId,
    });

    this.handleFormattedFetchedEvents({
      formattedEvents: formatEventsList,
      userEmail,
    });
  }

  handleFormattedFetchedEvents({ formattedEvents, userEmail }) {
    if (isEmptyArray(formattedEvents)) {
      // no need to run the filter below
      return;
    }

    let eventsWithinWindow = [];

    const {
      dbWindowStartJSDate,
      dbWindowEndJSDate,
      todayLeftJSDate,
      todayRightJSDate,
    } = this.getWindow();

    // get events that within the window according to selected day and the view as well as week start
    if (formattedEvents && formattedEvents.length > 0) {
      eventsWithinWindow = formattedEvents.filter(
        (e) =>
          isEventWithinJSWindow({
            event: e,
            windowStart: dbWindowStartJSDate,
            windowEnd: dbWindowEndJSDate,
          }) ||
          isEventWithinJSWindow({
            event: e,
            windowStart: todayLeftJSDate,
            windowEnd: todayRightJSDate,
          })
      );
    }

    if (eventsWithinWindow.length > 0) {
      mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.UPDATE_LIST_OF_EVENTS_INTO_WEEKLY_CALENDAR, {
        listOfEvents: eventsWithinWindow,
      });

      this.storeEventsIntoDBAndToggleCalendar({
        events: eventsWithinWindow,
        userEmail,
      });
    }
  }

  refetchMeeWithEvents({userEmail, isTeamSlots}) {
    if (!userEmail) {
      return;
    }
    if (isEmptyArrayOrFalsey(this.state.searchedEmails)) {
      return;
    }
    this.fetchEventsNumber = createUUID();
    this.fetchEventsForSearchedContacts({
      fetchEventsNumber: this.fetchEventsNumber,
      emails: addDefaultToArray(this.state.searchedEmails),
      existingEmails: [],
      where: "refetchMeeWithEvents",
      userEmail,
      isTeamSlots,
    });
  }

  handleOnClickMoreOptions({ e, id, userCalendarId }) {
    hasStopEventPropagation(e);
    hasEventPreventDefault(e);

    let calendarDom = document.getElementById(id);

    const MODAL_HEIGHT = 270;
    if (calendarDom) {
      let calendarCoordinates = calendarDom.getBoundingClientRect();
      const modalTop =
        calendarCoordinates.y + MODAL_HEIGHT >= window.innerHeight
          ? calendarCoordinates.y -
            (calendarCoordinates.y + MODAL_HEIGHT - window.innerHeight)
          : calendarCoordinates.y;
      this.setState({
        shouldDisplayModal: true,
        modalTop,
        moreInformationCalendarId: userCalendarId,
      });
    }

    calendarDom = null;
  }

  //=================
  // PRIVATE METHODS
  //=================

  updateEventFormEmails(newEmails) {
    let prevEmails = this.props.eventFormEmails;
    this.props.setEventFormEmails(newEmails);
    this.updateEventsFromEventForm({ prevEmails, newEmails });
  }

  updateEventsFromEventForm({ prevEmails, newEmails, userEmail }) {
    if (!_.isEqual(prevEmails, newEmails)) {
      this.changeCalendarsFromEventForm({
        previousList: prevEmails,
        newEmails,
        userEmail,
      });
    }
  }

  storeEventsIntoDBAndToggleCalendar({ events, userEmail }) {
    addEventsIntoIndexDB({
      userEmail,
      events,
    });
  }

  updateEventsOnTimeZoneChange(prevTimeZone, newTimeZone) {
    if (
      isEmptyObjectOrFalsey(this.state.calendarEmailEventsIndex) ||
      !prevTimeZone ||
      !newTimeZone
    ) {
      return;
    }

    let updatedIndex = _.clone(this.state.calendarEmailEventsIndex);

    Object.keys(updatedIndex).forEach((e) => {
      updatedIndex[e] = updateEventsTime({
        events: updatedIndex[e],
        prevTimeZone,
        newTimeZone,
      });
    });

    this.setState({ calendarEmailEventsIndex: updatedIndex });
  }

  determineRemoveSelectTemporarySecondaryCalendar(email) {
    let sideMenuEmails = this.state.searchedEmails || [];
    let eventFormEmails = this.props.eventFormEmails || [];
    let updatedCalendarEmailEventsIndex =
      _.clone(this.state.calendarEmailEventsIndex) || {};

    updatedCalendarEmailEventsIndex = _.omit(
      updatedCalendarEmailEventsIndex,
      email
    );

    this.setState({
      calendarEmailEventsIndex: updatedCalendarEmailEventsIndex,
    });

    if (stringArrayContainsIgnoringCase(sideMenuEmails, email)) {
      this.removeSelectTemporarySecondaryCalendar(email);
    }

    if (stringArrayContainsIgnoringCase(eventFormEmails, email)) {
      eventFormEmails = eventFormEmails.filter((e) => !isSameEmail(e, email));
      eventFormEmails = eventFormEmails.length > 0 ? eventFormEmails : null;

      this.updateEventFormEmails(eventFormEmails);
    }

    if (
      (isEmptyArrayOrFalsey(eventFormEmails)) &&
      !this.props.currentTimeZoneLabel
    ) {
      this.props.setShouldShowTopBar(false);
    }
  }

  hasSearchedCalendars() {
    return this.state.searchedEmails?.length > 0;
  }

  getSearchedEmailUserDomin(emailList) {
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const { currentUser } = this.props;
    if (isEmptyArrayOrFalsey(emailList)) {
      return null;
    }
    const allEmailDomains = removeDuplicatesFromArray(
      emailList.map((email) => getEmailDomain(email))
    );
    const matchingUser = getMatchingUserWithDomain({
      allLoggedInUsers,
      domain: allEmailDomains[0],
      currentUser,
    });
    return matchingUser;
  }

  updateSearchCalendarsWindow(date) {
    if (this.state.searchedEmails?.length > 0) {
      this.fetchEventsForSearchedContacts({
        fetchEventsNumber: this.fetchEventsNumber,
        emails: this.state.searchedEmails,
        where: "updateSearchCalendarsWindow",
        date,
        userEmail: this.getSearchedEmailUserDomin(this.state.searchedEmails)
          ?.email,
      });
    }

    if (this.props.eventFormEmails?.length > 0) {
      const searchEmails = this.state.searchedEmails || [];
      const activeCalendarEmails = this.getActiveCalendarEmails();
      const filteredEventFormEmails = this.props.eventFormEmails.filter(
        (e) => !stringArrayContainsIgnoringCase(searchEmails, e) && !stringArrayContainsIgnoringCase(activeCalendarEmails, e)
      );

      this.fetchEventsForSearchedContacts({
        where: "updateSearchCalendarsWindow",
        fetchEventsNumber: this.fetchEventsNumber,
        emails: filteredEventFormEmails,
        fromSideMenuBar: false,
        date,
        userEmail: this.getSearchedEmailUserDomin(this.props.eventFormEmails)
          ?.email,
      });
    }
  }

  parseFetchCalendarEvents({
    updatedCalendarEmailEventsIndex,
    emails,
    existingEmails,
    fromSideMenuBar,
  }) {
    let newEvents = [];
    const emailList = removeDuplicatesFromArray(emails.concat(existingEmails).map(email => formatEmail(email)));

    emailList.forEach((e) => {
      if (
        e in updatedCalendarEmailEventsIndex &&
        updatedCalendarEmailEventsIndex[e].length > 0
      ) {
        newEvents = newEvents.concat(updatedCalendarEmailEventsIndex[e]);
      }
    });

    if (fromSideMenuBar && newEvents.length > 0) {
      mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.ADD_TEMPORARY_SEARCHED_EVENTS, {
        meetWithEvents: newEvents,
      });
    } else if (newEvents.length > 0) {
      mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.ADD_TEMPORARY_SEARCHED_EVENTS, {
        eventFormEvents: newEvents,
      });
    }
  }

  getCalendarFromEmail(email) {
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    const {
      allCalendars,
    } = this.props.allCalendars;
    const {
      masterAccount,
    } = this.props.masterAccount;
    return getCalendarFromEmail({
      email,
      allLoggedInUsers,
      allCalendars,
      masterAccount,
    });
  }

  filterOutColorsAlreadyTaken() {
    let eventFormEmailColors = [];

    if (this.props.eventFormEmails) {
      this.props.eventFormEmails.forEach((e) => {
        const matchingCalendar = this.getCalendarFromEmail(e);
        if (matchingCalendar) {
          eventFormEmailColors = eventFormEmailColors.concat(
            determineCalendarColor(matchingCalendar)
          );
        }
      });
    }

    const { allCalendars } = this.props.allCalendars;
    const activeCalendarsArray = getActiveCalendarsFromAllCalendars({
      allCalendars,
      currentUserEmail: getUserEmail(this.props.currentUser),
    });
    let activeCalendarColors = activeCalendarsArray.map((c) =>
      determineCalendarColor(c)
    );

    const TEMPORARY_SECONDARY_CALENDAR_COLORS =
      GoogleColors.calendarColorsArray;

    return TEMPORARY_SECONDARY_CALENDAR_COLORS.filter(
      (c) =>
        !stringArrayContainsIgnoringCase(activeCalendarColors, c) && !stringArrayContainsIgnoringCase(eventFormEmailColors, c)
    );
  }

  presetFetchCalendarColors(fetchCalendars) {
    const updatedTemporarySecondaryCalendarColors = _.clone(
      this.props.temporarySecondaryCalendarColorsIndex
    );

    const filteredColors = this.filterOutColorsAlreadyTaken();

    const sorted = immutablySortArray(fetchCalendars);
    sorted.forEach((email) => {
      const formattedEmail = email?.trim()?.toLowerCase();
      let color;
      const matchingCalendar = this.getCalendarFromEmail(formattedEmail);
      if (matchingCalendar) {
        // nothing -> calendar color already exists
      } else if (
        Object.keys(updatedTemporarySecondaryCalendarColors).length <
        filteredColors.length
      ) {
        if (email in updatedTemporarySecondaryCalendarColors) {
          // nothing -> color already exist
        } else {
          // add new color to key
          const selectedElement = Object.keys(
            updatedTemporarySecondaryCalendarColors
          ).length;
          color = filteredColors[selectedElement];
          updatedTemporarySecondaryCalendarColors[email] = color;
        }
      }
    });

    this.props.setTemporarySecondaryCalendarColorsIndex(
      updatedTemporarySecondaryCalendarColors
    );
  }

  addEventsToWeeklyCalendar({
    response,
    addToNoAccess,
    emails,
    existingEmails,
    fromSideMenuBar,
    isPreviewOutlookEvent,
  }) {
    let nonAccessCalendars = this.state.calendarsWithNoAccess || [];
    let updatedCalendarEmailEventsIndex = _.clone(
      this.state.calendarEmailEventsIndex
    );

    // updatedTemporarySecondaryCalendarColors = object -> key: email from existing event form email. value: color
    let updatedTemporarySecondaryCalendarColors = _.clone(
      this.props.temporarySecondaryCalendarColorsIndex
    );

    // filteredColors: array of colors from google calendar colors minus colors of active calendars and existing email for email colors
    let filteredColors = this.filterOutColorsAlreadyTaken();

    let formatCalendarIdEventsQuery = {};
    if (!response?.calendars) {
      return;
    }
    const {
      userTimeZoneIndex,
      setUserTimeZoneIndex,
      userTimeZoneLastSetIndex,
      setUserTimeZoneLastSetIndex,
    } = this.props.userTimeZoneIndexStore;
    const newUserTimeZoneLastSetIndex = {};
    const updatedUserTimeZoneIndex = produce(userTimeZoneIndex, (draftState) => {
      response.calendars.forEach((item) => {
        Object.keys(item).forEach((key) => {
          const userTimeZone = getTimeZoneFromMeetWithItem({ key, item });
          if (userTimeZone) {
            draftState[key] = userTimeZone;
            newUserTimeZoneLastSetIndex[key] = (new Date()).toISOString();
          }
        });
      });
      return draftState;
    });
    setUserTimeZoneIndex(updatedUserTimeZoneIndex);
    setUserTimeZoneLastSetIndex({
      ...userTimeZoneLastSetIndex,
      ...newUserTimeZoneLastSetIndex,
    });
    response.calendars.forEach((item) => {
      Object.keys(item).forEach((key) => {
        let color;
        const calendar = this.getCalendarFromEmail(key);
        if (item[key].error) {
          // We don't have access to this calendar;
          updatedTemporarySecondaryCalendarColors = _.omit(
            updatedTemporarySecondaryCalendarColors,
            key
          ); // removed color of key since we don't have permission for it
          nonAccessCalendars = nonAccessCalendars.concat(key);
        } else if (
          "items" in item[key] &&
          calendar
        ) {
          color = determineCalendarColor(
            calendar,
          );

          formatCalendarIdEventsQuery[key] = {
            events: item[key].items,
            color,
          };
        } else if (
          "items" in item[key] &&
          Object.keys(updatedTemporarySecondaryCalendarColors).length <
            filteredColors.length
        ) {
          if (key in updatedTemporarySecondaryCalendarColors) {
            color = updatedTemporarySecondaryCalendarColors[key];
          } else {
            // add new color to key
            let selectedElement = Object.keys(
              updatedTemporarySecondaryCalendarColors
            ).length;

            color = filteredColors[selectedElement];
            updatedTemporarySecondaryCalendarColors[key] = color;
          }

          formatCalendarIdEventsQuery[key] = {
            events: item[key].items,
            color,
          };
        } else {
          // error in fetching calendar
          updatedTemporarySecondaryCalendarColors = _.omit(
            updatedTemporarySecondaryCalendarColors,
            key
          ); // removed color of key since we don't have permission for it
          nonAccessCalendars = nonAccessCalendars.concat(key);
        }
      });
    });

    this.props.setTemporarySecondaryCalendarColorsIndex(
      updatedTemporarySecondaryCalendarColors
    );

    const { formattedEventsObject } = formatFlattendCalendarAndEvents({
      objectOfCalendarAndEvents: formatCalendarIdEventsQuery,
      currentTimeZone: this.props.currentTimeZone,
      shouldFilterForActive: true,
      isMeetWithEvents: true,
      isPreviewOutlookEvent,
    });

    Object.keys(formattedEventsObject).forEach((k) => {
      let items = formattedEventsObject[k];
      updatedCalendarEmailEventsIndex[k] = items;
    });

    if (addToNoAccess) {
      this.setState({
        calendarsWithNoAccess: nonAccessCalendars,
        calendarEmailEventsIndex: updatedCalendarEmailEventsIndex,
      });
    } else {
      this.setState({
        calendarEmailEventsIndex: updatedCalendarEmailEventsIndex,
      });
    }

    this.parseFetchCalendarEvents({
      updatedCalendarEmailEventsIndex,
      emails,
      existingEmails,
      fromSideMenuBar,
    });
  }

  getWindow() {
    const { selectedDay, weekStart, selectedCalendarView } = this.props;
    return createWindow({
      windowJSDate: getSelectedDayWithBackup(selectedDay),
      isMonth: selectedCalendarView === BACKEND_MONTH,
      weekStart,
      selectedCalendarView,
    });
  }

  closeModal() {
    this.setState({
      shouldDisplayModal: false,
      modalTop: 0,
      moreInformationCalendarId: null,
    });
  }

  getSearchedEmailList() {
    let list = [];

    if (this.state.searchedEmails?.length > 0) {
      list = list.concat(this.state.searchedEmails);
    } else if (this.props.eventFormEmails?.length > 0) {
      list = list.concat(this.props.eventFormEmails);
    }

    list = removeDuplicatesFromArray(list.map(email => formatEmail(email)));
    return list;
  }

  async fetchOutlookPreviewMeetWithEvents({
    fetchEventsNumber,
    userEmail,
    startDate,
    endDate,
    emails,
    addToNoAccess,
    existingEmails,
    fromSideMenuBar,
    fetchOutlookPreviewEventsID,
    isTeamSlots,
  }) {
    if (fetchEventsNumber !== this.fetchEventsNumber) {
      return;
    }
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    const matchingUser = getMatchingUserFromAllUsers({
      allUsers: allLoggedInUsers,
      userEmail: userEmail || getUserEmail(this.props.currentUser),
    });

    if (!shouldGetOutlookPreviewEvents(matchingUser)) {
      return;
    }

    const {
      currentUser,
      currentTimeZone,
    } = this.props;
    try {
      const response = await fetchOutlookPreviewMeetWithEvents({
        userEmail,
        currentUser,
        startDate,
        endDate,
        emails,
        currentTimeZone,
        isTeamSlots,
      });
      if (!this._isMounted
          || isEmptyObjectOrFalsey(response)
          || fetchEventsNumber !== this.fetchEventsNumber
          || fetchOutlookPreviewEventsID !== this._fetchOutlookPreviewEventsID
      ) {
        return;
      }
      this.addEventsToWeeklyCalendar({
        response,
        addToNoAccess,
        emails,
        existingEmails,
        fromSideMenuBar,
        isPreviewOutlookEvent: true,
      });
    } catch (error) {
      handleError(error);
    }
  }

  getLeftHandTimeZone() {
    const {
      defaultBrowserTimeZone
    } = this.props;
    const {
      lastSelectedTimeZone
    } = this.props.appTimeZone;
    return getMostLeftHandTimeZone({
      lastSelectedTimeZone,
      defaultBrowserTimeZone,
    });
  }

  async getPreviewOutlookEventOnToggle({
    users,
    calendars,
    timeMin,
    timeMax,
  }) {
    if (isEmptyArray(users) || isEmptyArray(calendars)) {
      return;
    }
    const filteredUsers = users.filter((user) => shouldGetOutlookPreviewEvents(user));
    if (isEmptyArray(filteredUsers)) {
      return;
    }

    fetchBroadcast.publish(FETCH_BROADCAST_VALUES.FETCH_PREVIEW_OUTLOOK_EVENTS, {
      users: filteredUsers,
      userCalendarIDs: calendars.map((c) => getCalendarUserCalendarID(c)),
      inputTimeMin: timeMin,
      inputTimeMax: timeMax,
    });
  }

  addSubscriptions() {
    Broadcast.subscribe(
      "REMOVE_ALL_SELECTED_TEMPORARY_CALENDARS",
      this.removeAllSelectedTemporarySecondaryCalendars
    );
    Broadcast.subscribe(
      "UPDATE_SECONDARY_CALENDAR_WINDOW",
      this.updateSearchCalendarsWindow
    );
    Broadcast.subscribe("REMOVE_EMAIL", (email) =>
      this.determineRemoveSelectTemporarySecondaryCalendar(email)
    );
    Broadcast.subscribe(
      "ADD_EMAIL_TO_SEARCHED_EMAILS",
      (contactInfo, isTeamSlots = false) =>
        this.addEmailToSearchedEmails(
          contactInfo.email,
          contactInfo.contact,
          contactInfo.userEmail,
          isTeamSlots
        )
    );
    Broadcast.subscribe(
      "TOGGLE_SELECT_CALENDAR_WITH_CALENDAR_ID",
      this.toggleCalendarWithUserCalendarID
    );
    Broadcast.subscribe(
      "UPDATE_SECONDARY_CALENDAR_EVENTS_ON_TIME_ZONE_CHANGE",
      (prevTimeZone, newTimeZone) =>
        this.updateEventsOnTimeZoneChange(prevTimeZone, newTimeZone)
    );
    Broadcast.subscribe(
      "ON_UPDATE_EVENT_FORM_EMAIL",
      this.updateEventsFromEventForm
    );
    Broadcast.subscribe(
      "MATCH_CONTACTS_NAME_EMAIL_SIDE_MENU_BAR",
      this.matchContactNameWithEmail
    );
    Broadcast.subscribe(
      "SEARCH_CONTACTS_DOMAIN_DB",
      this.searchContactsDomainDB
    );
    Broadcast.subscribe(
      "ONLY_TOGGLE_ON_SELECT_CALENDARS",
      this.toggleOnOnlySelectCalendars
    );
    eventFormBroadcast.subscribe(
      "FIND_TIME_CREATE_EVENT",
      this.findTimeCreateEvent
    );
    broadcast.subscribe(BROADCAST_VALUES.TOGGLE_CALENDAR_FROM_CALENDAR_LIST, this.toggleSelectCalendar);
    broadcast.subscribe(BROADCAST_VALUES.REFETCH_MEET_WITH_EVENTS, this.refetchMeeWithEvents);
  }
}

function mapStateToProps(state) {
  let {
    shouldShowTopBar,
    defaultBrowserTimeZone,
    temporarySecondaryCalendarColorsIndex,
    emailToNameIndex,
    eventFormEmails,
    selectedDay,
    currentUser,
    currentTimeZone,
    currentTimeZoneLabel,
    selectedCalendarView,
    weekStart,
    isDarkMode,
    isMobileView,
    actionMode,
  } = state;

  return {
    shouldShowTopBar,
    defaultBrowserTimeZone,
    temporarySecondaryCalendarColorsIndex,
    emailToNameIndex,
    eventFormEmails,
    selectedDay,
    currentUser,
    currentTimeZone,
    currentTimeZoneLabel,
    selectedCalendarView,
    weekStart,
    isDarkMode,
    isMobileView,
    actionMode,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setShouldDisplayMenu: (data) =>
      dispatch({ data: data, type: "SET_SHOULD_DISPLAY_MENU" }),
    removeEventFormEmails: (event) =>
      dispatch({ data: event, type: "REMOVE_EVENT_FORM_EMAILS" }),
    setEventFormEmails: (email) =>
      dispatch({ data: email, type: "SET_EVENT_FORM_EMAILS" }),
    setTemporarySecondaryCalendarColorsIndex: (data) =>
      dispatch({
        data: data,
        type: "SET_TEMPORARY_SEARCHED_CALENDAR_COLORS_INDEX",
      }),
    removeTemporarySecondaryCalendarColorsIndex: () =>
      dispatch({ type: "REMOVE_SECONDARY_CALENDAR_COLORS_INDEX" }),
    addEmailToEmailNameIndex: (data) =>
      dispatch({ data: data, type: "ADD_EMAIL_TO_EMAIL_NAME_INDEX" }),
    setShouldShowTopBar: (data) =>
      dispatch({ data: data, type: "SET_SHOULD_SHOW_TOP_BAR" }),
  };
}

const withStore = (BaseComponent) => (props) => {
  const allCalendars = useAllCalendars();
  const allLoggedInUsers = useAllLoggedInUsers();
  const masterAccount = useMasterAccount();
  const temporaryStateStore = useTemporaryStateStore();
  const distroListDictionary = useDistroListDictionary();
  const userTimeZoneIndexStore = useUserTimeZoneIndexStore();
  const appTimeZone = useAppTimeZones();
  const appSettings = useAppSettings();
  const metricsStore = useMetricsStore();

  return (
    <BaseComponent
      {...props}
      allCalendars={allCalendars}
      allLoggedInUsers={allLoggedInUsers}
      masterAccount={masterAccount}
      temporaryStateStore={temporaryStateStore}
      distroListDictionary={distroListDictionary}
      userTimeZoneIndexStore={userTimeZoneIndexStore}
      appTimeZone={appTimeZone}
      appSettings={appSettings}
      metricsStore={metricsStore}
    />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStore(CalendarList)));
