import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { X } from "react-feather";
import ShortcutHoverHint from "./shortcutHoverHint";
import GlobalKeyMapTile from "./globalKeyMapTile";
import { hasStopEventPropagation } from "../services/commonUsefulFunctions";
import _ from "underscore";

let closeShortCutHint = { marginLeft: "-104px", marginTop: "-6px" };

let closeButtonTile = {
  left: "-25px",
  top: "-14px",
};

class CloseButton extends Component {
  render() {
    return (
      <div style={{ cursor: "pointer" }}>
        {this.props.disableHoverHint
          ? this.renderXWithoutHoverHint()
          : this.renderXWithHoverHint()}
      </div>
    );
  }

  renderXWithHoverHint() {
    return (
      <span>
        <ShortcutHoverHint
          left
          style={closeShortCutHint}
          title={"Close"}
          shortcut={"Esc"}
        >
          <GlobalKeyMapTile
            style={this.props.shortCutStyle || closeButtonTile}
            shortcut={"Esc"}
          />

          <div
            className="close-button-wrapper"
            onClick={this.onClickCancel.bind(this)}
          >
            <X size={this.props.size || 20} className="clickable-icon" />
          </div>
        </ShortcutHoverHint>
      </span>
    );
  }

  onClickCancel(e) {
    hasStopEventPropagation(e);
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  }

  renderXWithoutHoverHint() {
    return (
      <div
        className="close-button-wrapper"
        onClick={this.props.onClick ? this.props.onClick : _.noop}
      >
        <X size={20} className="clickable-icon" />
      </div>
    );
  }
}

export default withRouter(CloseButton);
