import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  useAllLoggedInUsers,
  useMasterAccount,
} from "../../services/stores/SharedAccountData";
import { getAccountShowMagicLinkOnboarding, getMagicLinkMasterAccount, getMagicLinkToken } from "../../services/maestro/maestroAccessors";
import { getAccountName, getAllNonDelegateUsers, getUserEmail, getUserToken } from "../../lib/userFunctions";
import { isEmptyObjectOrFalsey } from "../../services/typeGuards";
import CustomButton from "../customButton";
import { BLUE_BUTTON } from "../../services/globalVariables";
import { constructRequestURLV2 } from "../../services/api";
import { Check } from "react-feather";
import { LIGHT_MODE_AVAILABILITY } from "../onboarding/sharedVariables";
import Fetcher from "../../services/fetcher";
import { MAGIC_LINK_ENDPOINTS } from "../../lib/endpoints";
import { isCalendarSelected } from "../../lib/calendarFunctions";
import { getCalendarUserCalendarID } from "../../services/calendarAccessors";
import { trackOnboarding } from "../../lib/tracking/onboardingTracking";
import { HOME_PATH } from "../../services/routingFunctions";
import { useIsMounted } from "../../services/customHooks/useIsMounted";
import VimcalLogoWhite from "../logo/vimcalLogoWhite";
import GradientButton from "../buttons/gradientButton";
import { useMagicLink } from "../../services/stores/magicLinkStore";
import AnimatedGreenCheckMark from "../icons/animatedGreenCheckMark";
import VimcalErrorLogo from "../logo/vimcalErrorLogo";

function MagicLinkConnectAccount(props) {
  const { magicLink, magicLinkAllCalendars, step } = props;
  const allLoggedInUsers = useAllLoggedInUsers(
    (state) => state.allLoggedInUsers,
  );
  const componentIsMounted = useIsMounted();
  const currentUser = useSelector((state) => state.currentUser);
  const history = useHistory();
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const { resetMagicLinkToken } = useMagicLink();
  const [shouldDisplayError, setShouldDisplayError] = useState(isEmptyObjectOrFalsey(magicLink));
  const [isFetching, setIsFetching] = useState(false);
  /* Person who sent the invite is the inviter */
  const inviter = getMagicLinkMasterAccount({ magicLink });
  const { firstName } = getAccountName({ masterAccount: inviter });

  /* Send the request to connect all the accounts / calendars */
  async function connectAccountRequest() {
    if (isFetching) {
      return;
    } else {
      setIsFetching(true);
    }

    const path = MAGIC_LINK_ENDPOINTS.CONNECT_MAGIC_LINK_ACCOUNT;
    const url = constructRequestURLV2(path);
    const selectedCalendars = Object.values(magicLinkAllCalendars)
      ?.flat()
      ?.filter((calendar) => isCalendarSelected(calendar))
      ?.map((calendar) => getCalendarUserCalendarID(calendar));
    const payload = {
      body: JSON.stringify({
        magic_link_token: getMagicLinkToken({ magicLink }),
        shared_user_calendar_ids: selectedCalendars,
        user_tokens: getAllNonDelegateUsers(allLoggedInUsers)?.map(user => getUserToken(user)),
      }),
    };

    const response: any = await Fetcher.post(url, payload, true, getUserEmail(currentUser));

    if (
      !componentIsMounted?.current ||
      response?.error ||
      isEmptyObjectOrFalsey(response) ||
      isEmptyObjectOrFalsey(response?.success)
    ) {
      setShouldDisplayError(true);
      setIsFetching(false);
      return;
    }

    // Remove stored token if request succeeds
    resetMagicLinkToken();
  }

  useEffect(() => {
    trackOnboarding({
      user: currentUser,
      masterAccount,
      step,
    });

    connectAccountRequest();
  }, []);

  if (shouldDisplayError) {
    /* SEA/TODO: Ask Dillon for design */
    return (
      <div className="overflow-y-auto flex flex-col">
        <div className="onboarding-container">
          <VimcalErrorLogo />
          <div className="secondary-text-color my-4">Something went wrong...</div>
          <div
            className="magic-link-retry-button"
            onClick={() => {
              setShouldDisplayError(false);
              connectAccountRequest();
            }}
          >
            Retry
          </div>
        </div>
      </div>
    );
  }

  const renderHeader = () => {
    return (
      <>
        <AnimatedGreenCheckMark style={{ margin: 0, marginBottom: "1.5rem" }} />
        <div className="section-title">Thank you!</div>
        <div className="welcome-subtitle mt-3 mb-4">
          {firstName} is all set up now to schedule on your behalf.
        </div>
      </>
    );
  };

  const renderExistingUser = () => {
    return (
      <>
        {renderHeader()}
        <div className="flex justify-center">
          <CustomButton
            buttonType={BLUE_BUTTON}
            className="maestro-magic-link-home-button"
            onClick={() => history.push(`/${HOME_PATH}`)}
            label="Take me to Vimcal"
          />
        </div>
      </>
    );
  };

  const renderNewUser = () => {
    return (
      <>
        {renderHeader()}
        <div className="magic-link-extend-invite">
          <div className="magic-link-extend-invite-left">
            <div className="mb-12">
              <div>
                <VimcalLogoWhite className="" />
                {/* <VimcalText /> */}
              </div>
              <div className="magic-link-extend-invite-sub-text">
                {"The world's fastest calendar"}
              </div>
            </div>
            <GradientButton
              gradient="90deg, #B906B9 0%, #E9107A 46%, #F84E63 100%"
              label="Start trial"
              onClick={() => history.push("/welcome")}
            />
          </div>
          <div className="magic-link-extend-invite-right">
            <img
              alt=""
              className="magic-link-extend-invite-gif"
              src={LIGHT_MODE_AVAILABILITY}
            />
            <div className="magic-link-extend-invite-check-text">
              <span className="magic-link-extend-invite-check">
                <Check size={18} />
              </span>
              Weekly calendar metrics
            </div>
            <div className="magic-link-extend-invite-check-text">
              <span className="magic-link-extend-invite-check">
                <Check size={18} />
              </span>
              Fastest way to share availability
            </div>
            <div className="magic-link-extend-invite-check-text">
              <span className="magic-link-extend-invite-check">
                <Check size={18} />
              </span>
              Color coded events
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="overflow-y-auto flex flex-col">
      <div className="onboarding-container">
        {
          getAccountShowMagicLinkOnboarding({masterAccount})
            ? renderNewUser()
            : renderExistingUser()
        }
      </div>
    </div>
  );
}

export default MagicLinkConnectAccount;
