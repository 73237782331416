import React, { useState, useEffect } from "react";
import MinifiedCalendarHomeView from "./minifiedCalendarHomeView";
import {
  AVAILABILITY,
  PANEL_CONTENT_AVAILABILITY,
  TOAST_DURATION,
} from "./sharedVariables";
import Mousetrap from "mousetrap";
import { getActiveCommandCentersKey } from "../../services/commonUsefulFunctions";
import ToastPage from "./toastPage";
import { useSelector } from "react-redux";
import SlotsAnimation from "../availability/resources/slotsAnimation";
import { tooltipKeys } from "../../services/tooltipVariables";
import broadcast from "../../broadcasts/broadcast";
import { trackOnboarding } from "../../lib/tracking/onboardingTracking";
import { useMasterAccount } from "../../services/stores/SharedAccountData";
import SmallShortcutTile from "./smallShortCutTile";
import MediumShortcutTile from "../shortcutTiles/mediumShortcutTile";
import { pluralize } from "../../lib/stringFunctions";

export default function OnboardingAvailability(props) {
  const [shouldAnimateAvailability, setAvailabilityAnimate] = useState(true);
  const [isAvailabilityMode, setAvailabilityMode] = useState(false);
  const [slots, setSlots] = useState([]);
  const [shouldShowToast, setDisplayToast] = useState(false);
  const currentUser = useSelector((state) => state.currentUser);
  const masterAccount = useMasterAccount((state) => state.masterAccount);

  const [displaySlotsTutorial, setDisplaySlotsTutorial] = useState(false);

  useEffect(() => {
    trackOnboarding({
      user: currentUser,
      masterAccount,
      step: props.step
    });
    addMouseTrap();

    return () => {
      Mousetrap.reset();
    };
  }, []);

  const saveSlots = (slots) => {
    setSlots(slots);
  };

  const onClickAvailability = () => {
    setAvailabilityAnimate(false);
    setAvailabilityMode(true);
  };

  const showSlotsAnimation = () => {
    setDisplaySlotsTutorial(true);
    Mousetrap.reset();
  }

  const onToggleAvailability = () => {
    setAvailabilityMode(false);

    setDisplayToast(true);
    setTimeout(() => {
      props.onClickNext();
    }, TOAST_DURATION);
  };

  if (shouldShowToast) {
    return <ToastPage message="Availability has been copied" />;
  }

  const addMouseTrap = () => {
    Mousetrap.bind(["a", "A"], () => {
      onActiveAvailability()
    });
  }

  const onActiveAvailability = () => {
    onClickAvailability();
  }

  const onCloseSlotsAnimation = () => {
    broadcast.publish("MARK_TOOLTIP_COMPLETED", tooltipKeys.SLOTS_TUTORIAL);
    addMouseTrap();

    setDisplaySlotsTutorial(false);
  }

  const subTextWithButton = () => {
    if (slots?.length >= 3) {
      return (
        <div className="font-size-16 flex justify-center items-center cursor-pointer">
          Copy slots with:
          <div className="ml-4">
            <SmallShortcutTile text={getActiveCommandCentersKey()} isLeftHandSide={true} />
          </div>
          
          <SmallShortcutTile text={"C"}  />
        </div>
      );
    }

    if (isAvailabilityMode) {
      // show how many more slots they should drag
      return (
        <div className="font-size-16 mt-2">
          {`Drag ${3 - slots.length} more ${pluralize(
            3 - slots.length,
            "slot"
          )}`}
        </div>
      );
    }

    return (
      <div className="font-size-16 display-flex-flex-direction-row align-items-center">
        Try sharing your availabilities by pressing the green button
      </div>
    );
  }
  const determineSubText = () => {
    if (slots?.length >= 3) {
      return (
        <div className="font-size-16 flex justify-center items-center cursor-pointer">
          Copy slots with:
          <div className="ml-4">
            <MediumShortcutTile text={getActiveCommandCentersKey()} isLeftHandSide={true} />
          </div>
          
          <MediumShortcutTile text={"C"}  />
        </div>
      );
    }

    if (isAvailabilityMode) {
      // show how many more slots they should drag
      return (
        <div className="font-size-16 mt-2">
          {`Drag ${3 - slots.length} more ${pluralize(
            3 - slots.length,
            "slot"
          )}`}
        </div>
      );
    }

    return (
      <div className="font-size-16 display-flex-flex-direction-row align-items-center">
        Press the green button
      </div>
    );
  };

  return (
    <div className="onboarding-container onboarding-container-large">
      <div className="section-title">Select your availability</div>
      {/* <div className="welcome-subtitle mt-3">
        Select Slots when you’re free by dragging those times on the calendar.
      </div> */}
      {displaySlotsTutorial ? <SlotsAnimation onClose={onCloseSlotsAnimation} /> : null}

      <MinifiedCalendarHomeView
        className="mt-5"
        shouldAnimateAvailability={shouldAnimateAvailability}
        onClickAvailabilty={onClickAvailability}
        hideCreate={true}
        isAvailabilityMode={isAvailabilityMode}
        panelContent={isAvailabilityMode ? PANEL_CONTENT_AVAILABILITY : null}
        onToggleAvailability={onToggleAvailability}
        hideGutter={true}
        screen={AVAILABILITY}
        setSlots={saveSlots}
        showToolBar={null}
        hideAvailabilityButton={false}
        onClickShowMeHowSlots={showSlotsAnimation}
      />
      <div className="mt-5 h-12">{determineSubText()}</div>
    </div>
  );
}
