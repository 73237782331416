import React, { Component } from "react";
import {
  isElectron,
  filterForLocation,
  determineFurthestMinute,
  generateConferenceRooms,
  determineMultipleConferenceWarning,
  handleError,
  localData,
  OpenGoogleMapsLocation,
  openConferencingURL,
  determineTimeRemaining,
  formatTimeJSDate,
  convertToTimeZone,
  isOSSchemeDarkMode,
} from "../services/commonUsefulFunctions";
import StyleConstants, {
  MENU_BAR_TITLE_COUNTDOWN,
  MENU_BAR_LOGO,
  MENU_BAR_TITLE_POLICY,
  MINUTE_IN_MS,
  COMMAND_KEY,
  DEFAULT_FONT_COLOR,
  SECOND_IN_MS,
} from "../services/globalVariables";
import Broadcast from "../broadcasts/broadcast";
import {
  ATTENDEE_EVENT_DECLINED,
} from "../services/googleCalendarService";
import db from "../services/db";
import LoadingSkeleton from "./loadingSkeleton";
import { Check } from "react-feather";
import {
  findUpcomingEvent,
  isNextEventNow,
  getEventButtonStyle,
  renderMergedEventIndicator,
} from "../services/sharedAgendaFunctions";
import {
  format,
  parseISO,
  isSameDay,
  subDays,
  addDays,
  endOfDay,
  startOfDay,
} from "date-fns";
import { isCancelledEvent, isMergedEvent } from "../lib/eventFunctions";
import { mergeSameEvents } from "../services/mergeEventFunctions";
import { shouldHideMenuBarInterval } from "../lib/featureFlagFunctions";
import {
  getEventAttendeeStatus,
  getEmailFromUserCalendarID,
} from "../lib/calendarFunctions";
import {
  useAllCalendars,
  useAllLoggedInUsers,
  useMasterAccount,
} from "../services/stores/SharedAccountData";
import { createAgendaPanelIndex } from "../lib/webWorkerFunctions";
import {
  useMenuBarDisplaySections,
  useIsMenuBarDarkMode,
} from "../services/stores/menuBarStores";
import {
  getEventReminders,
  getEventUserCalendarID,
} from "../services/eventResourceAccessors";
import classNames from "classnames";
import {
  getTimeZoneForMenuBar,
  getUpcomingCalendarUserCalendarIDs,
  getUserEmail,
} from "../lib/userFunctions";
import { DEXIE_EVENT_COLUMNS, isDBEventItemWithinWindow } from "../lib/dbFunctions";
import { LOCAL_DATA_ACTION, getIsGlobalJoinEnabled } from "../lib/localData";
import { useOutlookCategoriesStore } from "../services/stores/outlookCategoriesStore";
import { isEmptyObjectOrFalsey } from "../services/typeGuards";
import { getIsAccountIn24HourFormat } from "../lib/settingsFunctions";
import { capitalizeFirstLetter, truncateString } from "../lib/stringFunctions";
import { createUUID } from "../services/randomFunctions";
import { getDateTimeFormat } from "../lib/dateFunctions";
import ShortcutTiles from "./shortcutTiles/shortcutTiles";
import menubarBroadcast from "../broadcasts/menubarBroadcast";
import { MENU_BAR_BROADCAST_VALUES } from "../lib/broadcastValues";
import { shouldHideDelegatedUser } from "../services/maestroFunctions";
import { getMatchingUIUserForEvent } from "../lib/tagsFunctions";

const AGENDA_CONTAINER = "agenda-container";
const AGENDA_NEXT_EVENT = "agenda_next_event";
const AGENDA_EMPTY_STATE = "agenda_empty_state";
const AGENDA_REMAINING_EVENT_LIST = "agenda_remaining_event_list";
const AGENDA_TITLE_SETTING = "agenda_title_setting";
const AGENDA_ACCOUNT = "agenda_account";
const MULTIPLE_UPCOMING_EVENTS_WARNING = "multiple_upcoming_events_warning";

const MAX_CONTAINER_HEIGHT = 700;
const UP_NEXT_EVENT_DETAIL_WIDTH = { width: 220 };

class MenuBarAgendaPanel extends Component {
  constructor(props) {
    super(props);
    this._currentTitle = "";
    this._currentTrayText = "";
    this._trayHeight = 0;
    this._focusModeCountdownTimer = "";
    this._hasJoinNextMeeting = undefined;

    this._setDesktopBridgeTimeout = null;
    this._desktopBridgeAttempt = 0;

    this._fetchId = null;

    this.state = {
      events: [],
      nextEvent: null,
      isElectron: isElectron(),
      indexByDay: {},
      showFullAgenda: true,
      upcomingEvents: [],
      format24HourTime: this.determine24HourFormat(),
      isLoading: true,
      isOSThemeDark: isOSSchemeDarkMode(), // only used for refreshing app if OS theme changes
    };

    this.updateTrayTitle = this.updateTrayTitle.bind(this);
    this.reformatAgendaIndexWithNextEvent =
      this.reformatAgendaIndexWithNextEvent.bind(this);
    this.joinNextMeeting = this.joinNextMeeting.bind(this);
    this.onClickOpenMainCalendar = this.onClickOpenMainCalendar.bind(this);
    this.runIntervalContent = this.runIntervalContent.bind(this);
    this.onReceiveFocusModeCountdown =
      this.onReceiveFocusModeCountdown.bind(this);
    this.handleOSThemeChangeChange = this.handleOSThemeChangeChange.bind(this);

    Broadcast.subscribe(
      MENU_BAR_BROADCAST_VALUES.MENU_BAR_REFORMAT_AGENDA_INDEX_WITH_NEXT_EVENT,
      this.reformatAgendaIndexWithNextEvent
    );
    Broadcast.subscribe(
      "RUN_AGENDA_INTERVAL",
      this.reformatAgendaIndexWithNextEvent
    );
  }

  componentDidMount() {
    this._isMounted = true;
    this.setContainerHeight();

    // Make sure we start interval at the start of every minute
    this._initialIntervalTimeOutTimer = clearTimeout(
      this._initialIntervalTimeOutTimer
    );
    this._initialIntervalTimeOutTimer = null;
    this.loadDesktopBridge();

    this.debounceMinuteIntervalUntilMinuteStart();
    this.reformatAgendaIndexWithNextEvent();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.isLoading && !this.state.isLoading) {
      this.setContainerHeight();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;

    clearTimeout(this._setDesktopBridgeTimeout);
    this._setDesktopBridgeTimeout = null;

    clearInterval(this.updateAgendaTimer);
    this.updateAgendaTimer = null;

    clearTimeout(this._initialIntervalTimeOutTimer);
    this._initialIntervalTimeOutTimer = null;

    this.removeOSThemeListener();

    Broadcast.unsubscribe(MENU_BAR_BROADCAST_VALUES.MENU_BAR_REFORMAT_AGENDA_INDEX_WITH_NEXT_EVENT);
    Broadcast.unsubscribe("RUN_AGENDA_INTERVAL");

    if (window?.vimcal) {
      window.vimcal.removeOnJoinNextMeeting(this.joinNextMeeting);
      window.vimcal.removeReformatMenuBarAgenda(
        this.reformatAgendaIndexWithNextEvent
      );

      if (window.vimcal.removeOnReceiveFocusModeCountdown) {
        window.vimcal.removeOnReceiveFocusModeCountdown(
          this.onReceiveFocusModeCountdown
        );
      }
    }
  }

  debounceMinuteIntervalUntilMinuteStart() {
    // this is legacy code to make sure legacy vimcal electron builds still work
    if (!this.shouldRunIntervalOutsideWebWorker()) {
      return;
    }
    const currentTime = new Date();

    this.updateAgendaTimer && clearInterval(this.updateAgendaTimer);
    this.updateAgendaTimer = null;

    clearTimeout(this._initialIntervalTimeOutTimer);
    this._initialIntervalTimeOutTimer = null;

    this._initialIntervalTimeOutTimer = setTimeout(() => {
      if (!this._isMounted) {
        return;
      }

      this.runMinuteInterval();
    }, (60 - currentTime.getSeconds()) * SECOND_IN_MS);
  }

  runMinuteInterval() {
    if (!this.shouldRunIntervalOutsideWebWorker()) {
      return;
    }

    this.runIntervalContent();

    this.updateAgendaTimer && clearInterval(this.updateAgendaTimer);
    this.updateAgendaTimer = null;

    this.updateAgendaTimer = setInterval(() => {
      this.runIntervalContent();
    }, MINUTE_IN_MS);
  }

  getTimeZone() {
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const { masterAccount } = this.props.masterAccount;

    return getTimeZoneForMenuBar({ masterAccount, allLoggedInUsers });
  }

  runIntervalContent() {
    // legacy code to make sure legacy electron builds still sync properly
    if (!this._isMounted) {
      return;
    }

    const { nextEvent, events, upcomingEvents } = findUpcomingEvent({
      indexByDate: this.state.indexByDay,
      allCalendars: this.props.allCalendars.allCalendars,
      timeZone: this.getTimeZone(),
    });

    const updatedState = {};

    updatedState["nextEvent"] = nextEvent;
    updatedState["events"] = events;
    updatedState["upcomingEvents"] = upcomingEvents;

    this.setState(updatedState, this.updateTrayTitle);

    // Check if start of minute is off and reset
    const currentTime = new Date();
    if (currentTime.getSeconds() > 3) {
      this.debounceMinuteIntervalUntilMinuteStart();
    }
  }

  render() {
    return this.determineRender();
  }

  determineRender() {
    if (this.state.isLoading) {
      return this.renderLoadingState();
    } else {
      return this.renderAgenda();
    }
  }

  renderLoadingState() {
    return (
      <div className="loading-screen-container">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: 20,
            marginLeft: 35,
          }}
        >
          <LoadingSkeleton
            style={{ width: 57, height: 20, marginRight: 20 }}
          ></LoadingSkeleton>

          <LoadingSkeleton style={{ width: 72, height: 10 }}></LoadingSkeleton>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: 20,
          }}
        >
          <LoadingSkeleton
            style={{
              borderRadius: "50%",
              width: 12,
              height: 12,
              marginLeft: 13,
              marginRight: 10,
            }}
          ></LoadingSkeleton>

          <LoadingSkeleton style={{ width: 150, height: 20 }}></LoadingSkeleton>
        </div>

        <LoadingSkeleton
          style={{ marginLeft: 35, marginTop: 20, width: 300, height: 20 }}
        ></LoadingSkeleton>

        <LoadingSkeleton
          style={{ marginLeft: 35, marginTop: 20, width: 300, height: 20 }}
        ></LoadingSkeleton>

        <div
          style={{
            marginTop: 20,
            marginLeft: 35,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <LoadingSkeleton
            style={{ width: 57, height: 20, marginRight: 20 }}
          ></LoadingSkeleton>

          <LoadingSkeleton style={{ width: 120, height: 40 }}></LoadingSkeleton>
        </div>
      </div>
    );
  }

  renderAgenda() {
    let shouldRenderAgenda = this.shouldRenderAgenda();

    return (
      <div className="menu-bar-agenda-style" id={AGENDA_CONTAINER}>
        <div
          className={
            shouldRenderAgenda
              ? "menu-bar-render-agenda-content"
              : "menu-bar-no-render-agenda-content"
          }
          id={shouldRenderAgenda ? "" : AGENDA_EMPTY_STATE}
        >
          {shouldRenderAgenda
            ? this.determineRenderAgenda()
            : this.renderEmptyState()}
        </div>

        {!shouldRenderAgenda && this.renderSettingSection()}
      </div>
    );
  }

  renderSettingSection() {
    const { menuBarDisplaySections } = this.props.menuBarDisplaySections;
    return (
      <div>
        <div
          className="agenda-setting-container"
          style={
            !this.state.events || this.state.events.length === 0
              ? { borderTop: "initial" }
              : {}
          }
          id={AGENDA_TITLE_SETTING}
        >
          <div
            className="agenda-reduced-events-list"
            onClick={() =>
              this.setMenuBarTitlePolicy(
                menuBarDisplaySections === MENU_BAR_TITLE_COUNTDOWN
                  ? MENU_BAR_LOGO
                  : MENU_BAR_TITLE_COUNTDOWN
              )
            }
            style={{ paddingLeft: 11, width: "100%" }}
          >
            <div className="flex items-center menu-bar-hover-container default-font-size w-full">
              {this.renderCheckMark(
                this.isMenuBarDisplaySection(MENU_BAR_TITLE_COUNTDOWN)
              )}
              {"Show countdown in title"}
            </div>
          </div>
        </div>

        <div className="default-font-size font-weight-300" id={AGENDA_ACCOUNT}>
          <div
            className="agenda-reduced-events-list agenda-setting-section-container"
            onClick={this.onClickOpenMainCalendar}
            style={{ paddingLeft: 11 }}
          >
            <div
              className="flex items-center menu-bar-hover-container hover-padding-left-open-calendar w-full"
              style={{ paddingLeft: 31 }}
            >
              Open calendar
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderCheckMark(shouldRender = false) {
    return (
      <div className="ml-2" style={{ paddingTop: 3, marginRight: 11 }}>
        <Check
          className={classNames(
            "non-clickable-icon hover-icon-menu-bar",
            shouldRender ? "" : "invisible"
          )}
          size={12}
        />
      </div>
    );
  }

  determineRenderAgenda() {
    let upcomingEventsHeight = this.getUpcomingEventsHeight();

    let scrollWrapper = {};
    if (upcomingEventsHeight !== 0 && this.state.containerHeight) {
      scrollWrapper = {
        maxHeight: `calc(100vh - ${upcomingEventsHeight}px)`,
      };
    }

    if (this.state.events.length === 0) {
      scrollWrapper.paddingTop = 0;
      scrollWrapper.paddingBottom = 0;
    }

    return (
      <div>
        {this.doesUpcomingEventsHaveMultipleConferencing() && (
          <div
            className="layout-top-flag-bar"
            style={{ position: "initial" }}
            id={MULTIPLE_UPCOMING_EVENTS_WARNING}
          >
            You have multiple upcoming events
          </div>
        )}

        <div id={AGENDA_NEXT_EVENT}>{this.renderUpcomingEvents()}</div>

        <div
          id={AGENDA_REMAINING_EVENT_LIST}
          className="agenda_remaining_event_list"
          style={scrollWrapper}
        >
          {this.renderAgendaReducedList()}

          {this.renderSettingSection()}
        </div>
      </div>
    );
  }

  renderAgendaReducedList() {
    let { events } = this.state;
    const { allCalendars } = this.props.allCalendars;

    return events.map((e, index) => {
      let displayAllDay = e.displayAsAllDay;
      let multidayEvent = !e.allDay && e.displayAsAllDay;

      return (
        <div
          key={`agenda-reduced-list-${index}`}
          className="agenda-reduced-events-list"
          style={{ justifyContent: "space-between", paddingRight: 10 }}
        >
          <div className="display-flex-flex-direction-row align-items-center">
            {this.renderAgendaCalendarIndicator(e, { marginBottom: 2 })}

            <div
              className={classNames(
                "default-font-size font-weight-300",
                getEventAttendeeStatus(e, allCalendars) ===
                  ATTENDEE_EVENT_DECLINED
                  ? "line-through"
                  : ""
              )}
            >
              {truncateString(
                `${this.renderEventStartText(
                  e,
                  displayAllDay,
                  multidayEvent
                )} - ${e.summaryUpdatedWithVisibility || "No Title"}`,
                40
              )}
            </div>
          </div>

          {/*{e.conferenceUrl &&*/}
          {/*  <div*/}
          {/*    className="join-conferencing-button small-join-button"*/}
          {/*    onClick={() => OpenLink(e.conferenceUrl)}*/}
          {/*  >*/}
          {/*    Join*/}
          {/*  </div>*/}
          {/*}*/}
        </div>
      );
    });
  }

  renderUpcomingEvents() {
    if (this.state.upcomingEvents.length === 0) {
      return null;
    }

    return this.state.upcomingEvents.map((e, index) => {
      return (
        <div
          className={classNames(
            "menu-bar-upcoming-event-container",
            "rounded-lg",
            "p-0.5",
            "mx-3",
            index === 0 ? "mt-3" : "",
            index !== this.state.upcomingEvents.length - 1 ? "mb-3" : ""
          )}
          key={`menu-bar-upcoming-event-${index}`}
        >
          {this.renderNextEvent(e, index)}
        </div>
      );
    });
  }

  renderUpNextAndTime(nextEvent) {
    if (isEmptyObjectOrFalsey(nextEvent)) {
      return null;
    }

    let { timeRemaining, timeRemainingText } =
      determineTimeRemaining(nextEvent);
    let furthestReminderMin = this.determineFurthestReminderMin(nextEvent);

    const isWithinNotificationPeriod = timeRemaining < furthestReminderMin;

    return (
      <div className="up-next-section mt-3">
        {isWithinNotificationPeriod ? (
          <div className="agenda-upnext-text mr-2.5">
            {isNextEventNow(nextEvent) ? "NOW" : "Up Next"}
          </div>
        ) : null}

        <div
          className={classNames(
            "time-remaining",
            isWithinNotificationPeriod ? "time-remaining-warning" : ""
          )}
        >
          {isWithinNotificationPeriod
            ? timeRemainingText
            : capitalizeFirstLetter(timeRemainingText)}
        </div>
      </div>
    );
  }

  renderNextEvent(inputEvent, index) {
    const nextEvent = inputEvent || this.state.nextEvent;
    const location = filterForLocation(nextEvent);
    const conferenceRoom = generateConferenceRooms(nextEvent);
    const { allCalendars } = this.props.allCalendars;
    const { isMenuBarDarkMode } = this.props.isMenuBarDarkMode;

    if (!nextEvent) {
      return null;
    }

    return (
      <div
        className={classNames(
          "agenda-next-event-wrapper relative",
          "padding-bottom-0px-important"
        )}
        style={{ marginLeft: 0, paddingLeft: 28, cursor: "initial" }}
        key={`agenda_next_event_${index || 0}`}
        id="next-event-container"
      >
        {(!index || index >= 0) && this.renderUpNextAndTime(nextEvent)}

        <div
          className="agenda-next-event-summary display-flex-flex-direction-row align-items-center"
          style={{ marginLeft: -21, alignItems: "flex-start" }}
        >
          {this.renderAgendaCalendarIndicator(nextEvent, {
            marginRight: 12,
            marginTop: 6,
          })}
          <div
            style={{
              textDecoration:
                getEventAttendeeStatus(nextEvent, allCalendars) ===
                ATTENDEE_EVENT_DECLINED
                  ? "line-through"
                  : null,
              width: 330,
            }}
          >
            {nextEvent.summaryUpdatedWithVisibility}
          </div>
        </div>

        <div className="font-size-12 mt-3.5 mb-3.5">
          <div className="inline-block font-weight-300">
            {formatTimeJSDate(
              convertToTimeZone(nextEvent.defaultStartTime, {
                timeZone: this.getTimeZone(),
              }),
              this.state.format24HourTime
            ) +
              " - " +
              formatTimeJSDate(
                convertToTimeZone(nextEvent.defaultEndTime, {
                  timeZone: this.getTimeZone(),
                }),
                this.state.format24HourTime
              )}
          </div>
        </div>

        {location && location !== nextEvent.conferenceUrl && (
          <div className="agenda-location-wrapper">
            <div className="agenda-subsection-label">Location:</div>

            <div
              className="hoverable-text agenda-location-text font-weight-300"
              style={UP_NEXT_EVENT_DETAIL_WIDTH}
              onClick={() => OpenGoogleMapsLocation(location)}
            >
              {location}
            </div>
          </div>
        )}

        {conferenceRoom?.length > 0 && (
          <div className="agenda-location-wrapper">
            <div className="agenda-subsection-label">Room:</div>

            <div
              className="agenda-location-text"
              style={UP_NEXT_EVENT_DETAIL_WIDTH}
            >
              {this.renderConferenceRooms(nextEvent)}
            </div>
          </div>
        )}

        {nextEvent.conferenceUrl ? (
          <div
            className="agenda-conference-wrapper"
            style={{ display: "flex" }}
          >
            <div className="agenda-subsection-label" style={{ paddingTop: 8 }}>
              Video:
            </div>

            <div className="launch-meeting-wrapper">
              <div
                className={classNames(
                  "join-conferencing-button duration-200 cursor-pointer",
                  isMenuBarDarkMode ? "hover:bg-blue-500" : "hover:bg-blue-700"
                )}
                onClick={() =>
                  openConferencingURL(
                    nextEvent.conferenceUrl,
                    this.getEventEmail(getEventUserCalendarID(nextEvent))
                  )
                }
              >
                Launch Meeting
              </div>

              {this.renderHotKeyHint()}
            </div>
          </div>
        ) : null}

        {!!nextEvent.conferenceUrl ||
        conferenceRoom?.length > 0 ||
        !!location ? (
          <div className="h-3 w-0"></div>
        ) : null}

        {this.renderMultipleConferenceWarning(nextEvent)}
      </div>
    );
  }

  renderHotKeyHint() {
    const { isMenuBarDarkMode } = this.props.isMenuBarDarkMode;
    if (!getIsGlobalJoinEnabled()) {
      return null;
    }
    return (
      <div className="launch-meeting-hints">
        {
          <div className="flex">
            <ShortcutTiles
              shortcut={`${COMMAND_KEY} Shift J`}
              backgroundColor={isMenuBarDarkMode ? DEFAULT_FONT_COLOR : StyleConstants.defaultFontColor}
              border={isMenuBarDarkMode ? "1px solid transparent" : null}
              color="white"
            />
          </div>
        }
      </div>
    );
  }

  renderConferenceRooms(event) {
    let conferenceRooms = generateConferenceRooms(event);

    return conferenceRooms.map((room, index) => {
      return (
        <div
          key={`expanded_selected_room_list_${index}`}
          className="agenda-view-render-selected-conference-room"
        >
          {room}
        </div>
      );
    });
  }

  renderAgendaCalendarIndicator(event, additionalStyles = {}) {
    const { allCalendars } = this.props.allCalendars;
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const { outlookCategories } = this.props.outlookCategoriesStore;
    const {
      masterAccount,
    } = this.props.masterAccount;
    const matchingUser = getMatchingUIUserForEvent({
      event,
      allCalendars,
      allLoggedInUsers,
      masterAccount,
    });
    if (isEmptyObjectOrFalsey(event) || isEmptyObjectOrFalsey(allCalendars)) {
      return (
        <div
          className="agenda-event-calendar-color mr-1"
          style={Object.assign(
            { backgroundColor: "transparent", borderColor: "transparent" },
            additionalStyles
          )}
        ></div>
      );
    } else if (isMergedEvent(event)) {
      return (
        <div className="mb-5 margin-right-22">
          {renderMergedEventIndicator({
            event,
            allCalendars,
            user: matchingUser,
            currentUser: matchingUser,
            allLoggedInUsers,
            outlookCategories,
            masterAccount,
          })}
        </div>
      );
    } else {
      return (
        <div
          className="agenda-event-calendar-color mr-3.5"
          style={Object.assign(
            getEventButtonStyle({
              event,
              allCalendars,
              user: matchingUser,
              currentUser: matchingUser,
              allLoggedInUsers,
              outlookCategories,
            }),
            additionalStyles,
            masterAccount,
          )}
        ></div>
      );
    }
  }

  renderEmptyState() {
    return (
      <div className="no-events-scheduled ml-8">Your schedule is clear!</div>
    );
  }

  renderMultipleConferenceWarning(event) {
    let warning = determineMultipleConferenceWarning(event);

    if (!warning) {
      return null;
    }

    return (
      <div
        className="conflicting-zoom-warning font-size-12 font-weight-300 mb-3"
        style={{ width: 285, wordBreak: "break-word" }}
      >
        <div className="margin-top-five">*</div>

        <div className="margin-left-5 margin-top-five">{warning}</div>
      </div>
    );
  }

  renderEventStartText(e, displayAllDay, multidayEvent) {
    const {
      format24HourTime
    } = this.state;
    if (displayAllDay) {
      if (multidayEvent) {
        if (
          isSameDay(parseISO(e.defaultEndTime), new Date()) ||
          this.isEventStartAndEventEndNotOnTheSameDayAsToday(e)
        ) {
          return "Multi-day";
        } else {
          return format(
            convertToTimeZone(e.defaultStartTime, {
              timeZone: this.getTimeZone(),
            }),
            getDateTimeFormat(format24HourTime)
          );
        }
      } else {
        return "All day";
      }
    } else {
      return format(
        convertToTimeZone(e.defaultStartTime, { timeZone: this.getTimeZone() }),
        getDateTimeFormat(format24HourTime)
      );
    }
  }

  setMenuBarTitlePolicy(policy) {
    const { setMenuBarDisplaySections } = this.props.menuBarDisplaySections;

    localData(LOCAL_DATA_ACTION.SET, MENU_BAR_TITLE_POLICY, policy);

    if (this.state.isElectron && window?.vimcal) {
      window.vimcal.updateMenuBarSetting(policy);
    }

    setMenuBarDisplaySections(policy);
  }

  shouldRenderAgenda() {
    if (this.state.nextEvent) {
      return true;
    } else if (this.state.upcomingEvents.length > 0) {
      return true;
    } else if (!this.state.events) {
      return false;
    } else if (this.state.events.length === 0) {
      return false;
    } else {
      return true;
    }
  }

  determineFurthestReminderMin(nextEvent) {
    const eventReminders = getEventReminders(nextEvent);
    if (!eventReminders) {
      return 10;
    }

    if (eventReminders.overrides) {
      const overrides = eventReminders.overrides;
      return determineFurthestMinute(overrides);
    }

    return 10;
  }

  isEventStartAndEventEndNotOnTheSameDayAsToday(e) {
    return (
      !isSameDay(new Date(), parseISO(e.defaultStartTime)) &&
      !isSameDay(new Date(), parseISO(e.defaultEndTime))
    );
  }

  getUpcomingEventsHeight() {
    let agendaNextEvent = document.getElementById(AGENDA_NEXT_EVENT);
    let multipleEventsWarning = document.getElementById(
      MULTIPLE_UPCOMING_EVENTS_WARNING
    );

    let height = 0;

    if (multipleEventsWarning) {
      height = height + multipleEventsWarning.getBoundingClientRect().height;
    }

    if (agendaNextEvent) {
      height = height + agendaNextEvent.getBoundingClientRect().height + 10;
    }
    agendaNextEvent = null;
    multipleEventsWarning = null;

    return height;
  }

  getContainerHeight() {
    let agendaContainer = document.getElementById(AGENDA_CONTAINER);
    let agendaEmpty = document.getElementById(AGENDA_EMPTY_STATE);
    let agendaNextEvent = document.getElementById(AGENDA_NEXT_EVENT);
    let multipleEventsWarning = document.getElementById(
      MULTIPLE_UPCOMING_EVENTS_WARNING
    );
    let agendaTitleSetting = document.getElementById(AGENDA_TITLE_SETTING);
    let agendaAccountSetting = document.getElementById(AGENDA_ACCOUNT);

    let shouldRenderAgenda = this.shouldRenderAgenda();

    let height = 0;

    if (!shouldRenderAgenda && agendaEmpty) {
      height = height + agendaEmpty.getBoundingClientRect().height;
    } else if (shouldRenderAgenda && agendaNextEvent) {
      height = height + agendaNextEvent.getBoundingClientRect().height;
    } else if (agendaNextEvent) {
      // default case
      height = height + agendaNextEvent.getBoundingClientRect().height;
    }

    if (multipleEventsWarning) {
      height = height + multipleEventsWarning.getBoundingClientRect().height;
    }

    if (this.state.events?.length > 0) {
      // height = height + agendaRemainingEvents.getBoundingClientRect().height;
      // 33 is height of each sub event
      // 10 is margin top and bottom (5 + 5)
      // 35 is height of settings section (2 * 35)
      height = height + this.state.events.length * 33 + 10 + 2 * 35;
    } else if (
      this.state.events &&
      this.state.events.length === 0 &&
      shouldRenderAgenda
    ) {
      height = height + 2 * 35;
    }

    if (!shouldRenderAgenda && agendaTitleSetting) {
      height = height + agendaTitleSetting.getBoundingClientRect().height;
    }

    if (!shouldRenderAgenda && agendaAccountSetting) {
      height = height + agendaAccountSetting.getBoundingClientRect().height;
    }

    if (height === 0) {
      if (agendaContainer) {
        height = agendaContainer.getBoundingClientRect().height + 5;
      } else {
        height = 250;
      }
    } else {
      let additionalHeight = shouldRenderAgenda ? 10 : 5;

      height = height + additionalHeight;
    }

    height = height > MAX_CONTAINER_HEIGHT ? MAX_CONTAINER_HEIGHT : height;

    agendaContainer = null;
    agendaEmpty = null;
    agendaNextEvent = null;
    multipleEventsWarning = null;
    agendaTitleSetting = null;
    agendaAccountSetting = null;

    return height;
  }

  shouldUpdateElectronMenuContent({ eventTitle, trayText, height }) {
    return (
      this._currentTitle !== eventTitle ||
      this._currentTrayText !== trayText ||
      this._trayHeight !== height
    );
  }

  updateElectronMenuContent({ eventTitle, trayText, height }) {
    this._currentTitle = eventTitle;
    this._currentTrayText = trayText;
    this._trayHeight = height;
  }

  updateTrayTitle(inputNextEvent) {
    if (this.state.isLoading) {
      return true;
    }

    const determineEventTitle = () => {
      if (this.state.upcomingEvents && this.state.upcomingEvents.length > 1) {
        return `${this.state.upcomingEvents.length} events`;
      } else {
        return nextEvent
          ? nextEvent.summaryUpdatedWithVisibility
          : "Schedule clear";
      }
    };

    const nextEvent = inputNextEvent || this.state.nextEvent;
    const eventTitle = determineEventTitle();

    const timeRemainingText = nextEvent
      ? determineTimeRemaining(nextEvent)
      : { trayText: null };

    const height = this.getContainerHeight();
    const conferencing = this.getNextMeetingConferencing();

    const hasNextMeeting = conferencing ? true : false;

    if (this.state.isElectron && window?.vimcal) {
      if (this._hasJoinNextMeeting !== hasNextMeeting) {
        // so we don't overcall it
        window.vimcal.updateTrayHasNextMeeting(hasNextMeeting);
        this._hasJoinNextMeeting = hasNextMeeting;
      }

      if (
        this.shouldUpdateElectronMenuContent({
          eventTitle,
          trayText: timeRemainingText.trayText,
          height,
        })
      ) {
        window.vimcal.updateTrayTitle(
          eventTitle,
          timeRemainingText.trayText,
          height,
          this._focusModeCountdownTimer, // focus mode countdown timer is checked via this.onReceiveFocusModeCountdown
        );

        this.updateElectronMenuContent({
          eventTitle,
          trayText: timeRemainingText.trayText,
          height,
        });
      }
    }

    this.setStateContainerHeight(height);
  }

  setStateContainerHeight(height) {
    this.setState({
      renderNumber: this.state.renderNumber + 1,
      containerHeight: height,
    });
  }

  getConferenceLinksForUpcomingEvents() {
    let conferenceLinksArray = [];

    if (this.state.upcomingEvents && this.state.upcomingEvents.length > 0) {
      this.state.upcomingEvents.forEach((e) => {
        if (e.conferenceUrl) {
          conferenceLinksArray = conferenceLinksArray.concat({
            url: e.conferenceUrl,
            calendarId: getEventUserCalendarID(e),
          });
        }
      });
    }

    return conferenceLinksArray;
  }

  getNextMeetingConferencing() {
    let conferenceLinks = this.getConferenceLinksForUpcomingEvents();

    return conferenceLinks && conferenceLinks.length > 0 && conferenceLinks[0];
  }

  onReceiveFocusModeCountdown(data, countdown) {
    if (countdown === this._focusModeCountdownTimer) {
      return;
    }
    this._focusModeCountdownTimer = countdown;
    window.vimcal.updateTrayTitle(
      this._currentTitle,
      this._currentTrayText,
      null, // so we don't need to change height
      this._focusModeCountdownTimer,
    );
  }

  reformatAgendaIndexWithNextEvent() {
    let dbFetchPromiseArrary = [];
    let allEvents = [];

    const currentFetchId = createUUID();
    this._fetchId = currentFetchId;

    const twoDaysBefore = startOfDay(subDays(new Date(), 2));
    const oneDayAfter = endOfDay(addDays(new Date(), 1));

    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const { allCalendars } = this.props.allCalendars;
    const { masterAccount } = this.props.masterAccount;

    const upcomingCalendarIDs = getUpcomingCalendarUserCalendarIDs({
      masterAccount,
      allCalendars,
      allLoggedInUsers,
    });
    menubarBroadcast.publish(
      MENU_BAR_BROADCAST_VALUES.CHECK_MENU_BAR_COLOR,
    );

    const filteredUsers = allLoggedInUsers.filter(user => !shouldHideDelegatedUser({user, allCalendars}));
    filteredUsers.forEach((user) => {
      const email = getUserEmail(user);

      let calendarDBFetchPromise = db
        .fetch(email)
        .events.where(DEXIE_EVENT_COLUMNS.CALENDAR_ID)
        .startsWithAnyOfIgnoreCase(upcomingCalendarIDs)
        .and(function (item) {
          return isDBEventItemWithinWindow({
            item,
            windowStart: twoDaysBefore,
            windowEnd: oneDayAfter,
          });
        })
        .toArray()
        .then((response) => {
          if (!this._isMounted) {
            return;
          }

          const calendarEvents = response.map((e) => {
            return e.event;
          });

          const formattedEvents = calendarEvents.filter(
            (e) => !isCancelledEvent(e)
          );

          allEvents = allEvents.concat(formattedEvents);
        })
        .catch((err) => {
          handleError(err);

          if (!this._isMounted) {
            return;
          }

          this.setState({ isLoading: false });
        });

      dbFetchPromiseArrary = dbFetchPromiseArrary.concat(
        calendarDBFetchPromise
      );
    });

    Promise.all(dbFetchPromiseArrary)
      .then(() => {
        if (!this._isMounted) {
          return;
        }

        if (currentFetchId !== this._fetchId) {
          // a new process happened
          return;
        }

        const mergedEvents = mergeSameEvents({
          eventList: allEvents,
          alwaysMerge: true,
          allCalendars,
          masterAccount,
          allLoggedInUsers,
        });

        const { indexByDate } = createAgendaPanelIndex({
          mainCalendarEvents: mergedEvents,
          currentTimeZone: this.getTimeZone(),
        });

        let { nextEvent, events, upcomingEvents } = findUpcomingEvent({
          indexByDate: indexByDate,
          allCalendars: allCalendars,
          timeZone: this.getTimeZone(),
        });

        this.setState(
          {
            indexByDay: indexByDate,
            events,
            nextEvent,
            upcomingEvents,
            isLoading: false,
          },
          () => {
            this.updateTrayTitle();
            this.debounceMinuteIntervalUntilMinuteStart();
          }
        );
      })
      .catch((err) => {
        handleError(err);

        if (!this._isMounted) {
          return;
        }

        this.setState({ isLoading: false });
      });
  }

  /* Don't need to pass user here for Maestro */
  /* We just always use master account here */
  determine24HourFormat() {
    const { masterAccount } = this.props.masterAccount;
    return getIsAccountIn24HourFormat({ masterAccount });
  }

  onClickOpenMainCalendar() {
    if (this.state.isElectron && window && window.vimcal) {
      window.vimcal.openMainCalendar();
    }
  }

  joinNextMeeting() {
    if (
      this.doesUpcomingEventsHaveMultipleConferencing() &&
      this.state.isElectron &&
      window &&
      window.vimcal
    ) {
      window.vimcal.toggleTrayWindow();
      return;
    }

    const conferencing = this.getNextMeetingConferencing();

    if (conferencing) {
      openConferencingURL(
        conferencing.url,
        this.getEventEmail(conferencing.calendarId)
      );
    }
  }

  doesUpcomingEventsHaveMultipleConferencing() {
    return this.getConferenceLinksForUpcomingEvents().length > 1;
  }

  setContainerHeight() {
    let height = this.getContainerHeight();

    if (this.state.isElectron && window && window.vimcal) {
      window.vimcal.updateTrayWindowHeight(height);
    }

    this.setStateContainerHeight(height);
  }

  isMenuBarDisplaySection(sectionType) {
    const { menuBarDisplaySections } = this.props.menuBarDisplaySections;
    return menuBarDisplaySections === sectionType;
  }

  getEventEmail(calendarId) {
    const { allCalendars } = this.props.allCalendars;
    if (!calendarId || isEmptyObjectOrFalsey(allCalendars)) {
      return null;
    }

    return getEmailFromUserCalendarID(calendarId, allCalendars);
  }

  shouldRunIntervalOutsideWebWorker() {
    // interval is kept through the webworker in calendarhomeview
    return (
      !window?.vimcal?.onMenuBarMinuteInterval || !shouldHideMenuBarInterval()
    );
  }

  handleOSThemeChangeChange(e) {
    if (isEmptyObjectOrFalsey(e)) {
      return;
    }

    const {
      isOSThemeDark,
    } = this.state;

    const updatedIsOSThemeDark = e.matches ? true : false;
    if (isOSThemeDark !== updatedIsOSThemeDark) {
      // simply updating state does not work
      this.refresh();
    }
  }

  refresh() {
    if (!window?.vimcal?.onLoginSuccess) {
      return;
    }
    window.vimcal.onLoginSuccess();
  }

  addOSThemeListener() {
    if (!window?.matchMedia) {
      return;
    }
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    if (mediaQuery?.addListener) {
      mediaQuery?.addListener(this.handleOSThemeChangeChange);
    }
  }

  removeOSThemeListener() {
    if (!window?.matchMedia) {
      return;
    }
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    if (mediaQuery?.removeListener) {
      mediaQuery?.removeListener(this.handleOSThemeChangeChange);
    }
  }

  loadDesktopBridge() {
    if (!isElectron()) {
      return;
    }
    if (!window?.vimcal) {
      if (this._desktopBridgeAttempt > 100) {
        return;
      }
      clearTimeout(this._setDesktopBridgeTimeout);
      this._desktopBridgeAttempt += 1;
      this._setDesktopBridgeTimeout = setTimeout(() => {
        if (!this._isMounted) {
          return;
        }
        this.loadDesktopBridge();
      }, SECOND_IN_MS * (0.1 * this._desktopBridgeAttempt));
      return;
    }
    this._desktopBridgeAttempt = 0;
    const { menuBarDisplaySections } = this.props.menuBarDisplaySections;
    window.vimcal.updateMenuBarSetting(menuBarDisplaySections);
    window.vimcal.onJoinNextMeeting(this.joinNextMeeting);
    window.vimcal.reformatMenuBarAgenda(
      this.reformatAgendaIndexWithNextEvent
    );

    if (window.vimcal.onReceiveFocusModeCountdown) {
      window.vimcal.onReceiveFocusModeCountdown(
        this.onReceiveFocusModeCountdown
      );
    }
  }
}

const withStore = (BaseComponent) => (props) => {
  // Fetch initial state
  const allCalendars = useAllCalendars();
  const allLoggedInUsers = useAllLoggedInUsers();
  const masterAccount = useMasterAccount();
  const menuBarDisplaySections = useMenuBarDisplaySections();
  const isMenuBarDarkMode = useIsMenuBarDarkMode();
  const outlookCategoriesStore = useOutlookCategoriesStore();

  return (
    <BaseComponent
      {...props}
      allCalendars={allCalendars}
      masterAccount={masterAccount}
      allLoggedInUsers={allLoggedInUsers}
      menuBarDisplaySections={menuBarDisplaySections}
      isMenuBarDarkMode={isMenuBarDarkMode}
      outlookCategoriesStore={outlookCategoriesStore}
    />
  );
};

export default withStore(MenuBarAgendaPanel);
