import RRule from "rrule";

export function getWeekdayConstant(date) {
  const days = [
    RRule.SU,
    RRule.MO,
    RRule.TU,
    RRule.WE,
    RRule.TH,
    RRule.FR,
    RRule.SA,
  ];
  return days[date.getUTCDay()];
}
