/* eslint-disable react/display-name */
import React, { PureComponent } from "react";
import AppBroadcast from "../broadcasts/appBroadcast";
import {
  ZOOM_PERSONAL_LINK,
  GENERIC_ERROR_MESSAGE,
  LIGHT_MODE_THEME,
  SET_DISAPPEARING_NOTIFICATION_MESSAGE,
  SECOND_IN_MS,
  ISO_DATE_FORMAT,
} from "../services/globalVariables";
import {
  sendMessageToSentry,
  handleError,
  isOnboardingMode,
  isElectron,
  determineElectronLoginEnv,
  localData,
  loadTheme,
  constructQueryParams,
  convertTrueFalseStringIntoValue,
  openLinkOnSamePage,
  isSafari,
  OpenLink,
  formatEventForReactBigCalendar,
  hasStopEventPropagation,
  hasEventPreventDefault,
  isOnHomePage,
  isOnAddNewEventPage,
  isMobile,
} from "../services/commonUsefulFunctions";
import Broadcast from "../broadcasts/broadcast";
import { batch, connect } from "react-redux";
import { determineZoomAuthInfo, isDefaultZoomPersonalLink } from "../lib/conferencing";
import { openSignInWindow } from "./popup/open-popup";
import ApiClient from "../services/apiClient";
import _ from "underscore";
import {
  getRecentlySearchedTimeZones,
  storeDefaultPaymentMethod,
  getUnseenPromotions,
  getLastSetPromotionTime,
  updateAllLoggedInUsers,
  getAllLoggedInAccountDetails,
  getShouldSkipDomainResourcesSync,
  shouldFetchOutlookContact,
  saveLastFetchOutlookContactTime,
  isInOnboarding,
  isWaitingToBeOnboarded,
  userNeedsTypeForm,
  isAccountReferred,
  getRecentContacts,
  shouldUpdateOrderedTimeZonesFromBackend,
  getUnappliedPromotions,
  deleteCalendarSelectedOverridePerUser,
} from "../lib/stateManagementFunctions";
import {
  CONFERENCING_TYPE,
} from "../services/googleCalendarService";
import { constructRequestURL, constructRequestURLV2, isErrorResponse } from "../services/api";
import Fetcher from "../services/fetcher";
import db from "../services/db";
import { withRouter } from "react-router-dom";
import backendBroadcasts from "../broadcasts/backendBroadcasts";
import {
  addMonths,
  differenceInMinutes,
  endOfMonth,
  formatISO,
  isMonday,
  startOfMonth,
} from "date-fns";
import {
  ALL_CANCELLED_STATE,
  ACCOUNT_STATE_PAYING,
  BACKEND_SETTINGS_NAMES,
  TYPEFORM_LINKS,
  FEEDBACK_TYPE,
} from "../lib/vimcalVariables";
import {
  usePromotionsStore,
  useSubscriptionStore,
  useDefaultPaymentMethod,
  useCharges,
  useStripePaymentMethods,
  useStripeSubscriptions,
  useStripeUpcomingInvoices,
  useHasBillingBeenFetched,
  useStripeCustomerEmail,
  useAnnualUpgradeInvoicesStore,
  useBackendSubscriptionStore,
} from "../services/stores/finance";
import availabilityBroadcast from "../broadcasts/availabilityBroadcast";
import { useAppSettings, useGroupVoteStore } from "../services/stores/settings";
import { useTeamPlan, useUserCodes, useUserTimeZoneIndexStore } from "../services/stores/userData";
import {
  useAllCalendars,
  useAllLoggedInUsers,
  useAllUserDomains,
  useMasterAccount,
  useZoomSchedulers,
} from "../services/stores/SharedAccountData";
import {
  useIsMenuBarDarkMode,
  useMenuBarDisplaySections,
} from "../services/stores/menuBarStores";
import {
  useEnrichedContactsStore,
  useStoredContactsStore,
} from "../services/stores/enrichedContacts";
import {
  getActiveCalendarsIDsFromAllCalendars,
  getCalendarFromUserCalendarID,
  getCalendarUserEmail,
  isValidCalendar,
} from "../lib/calendarFunctions";
import produce from "immer";
import { getCalendarObject, getCalendarUserCalendarID } from "../services/calendarAccessors";
import {
  useAccountActivity,
  useAppTimeZones,
  useFeatureFlags,
  useHideRightHandSidebar,
  useReferralStore,
  useTutorialWizard,
} from "../services/stores/appFunctionality";
import {
  useDistroListDictionary,
  useHiddenEventsIDs,
  useOOOBusyEventsDictionaryStore,
} from "../services/stores/eventsData";
import { useEnrichedCompanyStore } from "../services/stores/enrichedCompanies";
import ContactBroadcast from "../broadcasts/contactBroadcast";
import { isVersionV2 } from "../services/versionFunctions";
import { trackError, trackEvent } from "./tracking";
import mainCalendarBroadcast from "../broadcasts/mainCalendarBroadcast";
import { clearEventStaticCache, HIDDEN_EVENT_TOGGLE, toggleEventHiddenEventPropertyOnBackendFailed } from "../lib/eventFunctions";
import {
  isOutlookConferencingOption,
  isOutlookUser,
} from "../lib/outlookFunctions";
import {
  getAccountCalendarView,
  getMatchingUserFromAllUsers,
  getOutlookAndGoogleUsers,
  getSelectedUserName,
  getUserAccountUpdatedAtTime,
  getUserEmail,
  getUserSmartTags,
  getUserToken,
  getZoomLoginUserName,
  isUserHideMetricsNotifications,
  isValidUser,
  setZoomLoginUserName,
  shouldUpdateLocalMasterAccountSettings,
  shouldUpdateMasterAccount,
} from "../lib/userFunctions";
import settingsBroadcast from "../broadcasts/settingsBroadcast";
import {
  doesGooglePlacesScriptExist,
  isActionModeCreateAvailability,
  isActionModeUpsertEvent,
  isColorTagSettingsShowing,
  isModalOpen,
  isValidCalendarView,
  updateMasterAccountSettingsForFrontendAndBackend,
} from "../services/appFunctions";
import { usePermissionsStore } from "../services/stores/permissionsStore";
import {
  hasPermissionToViewDistroList,
  isEmailAVimcalDomain,
  isSelfServeOpen,
  isSyncingCategories,
} from "../lib/featureFlagFunctions";
import {
  isUserDelegatedUser,
  isUserLimitedAccess,
  isUserMaestroUser,
} from "../services/maestroFunctions";
import {
  MOBILE_DOWNLOAD_PATH,
  SSO_NEW_USER_LOGIN,
  TYPEFORM_ROUTE,
} from "../services/routingFunctions";
import { isAISchedulingModalOpen } from "../services/appFunctions";
import pasteBroadcast from "../broadcasts/pasteBroadcast";
import { useTemporaryStateStore } from "../services/stores/temporaryStateStores";
import { getParsedEventsAndMetaDataFromCalendlyLink } from "../lib/calendlyFunctions";
// TODO: This is also imported as Broadcast above.
import broadcast from "../broadcasts/broadcast";
import {
  fetchBookingProjectPersonalLinksData,
  fetchBookingProjectRescheduleSlots,
  fetchBookingProjectSlotsData,
  getVimcalAvailabilityTypeAndToken,
} from "../lib/vimcalFunctions";
import { useAvailabilityStore } from "../services/stores/availabilityStores";
import { UPDATE_PAINT_SETTINGS } from "./settings/features/tags/colorSetting";
import tagsBroadcast from "../broadcasts/tagsBroadcast";
import { UPDATE_EVENT_TAGS } from "./tags/tagsSelector";
import { addEventsIntoIndexDB } from "../lib/dbFunctions";
import appBroadcast from "../broadcasts/appBroadcast";
import {
  getEventID,
  getEventMasterEventID,
  getEventUserCalendarID,
  getEventUserEmail,
  getEventUserEventID,
} from "../services/eventResourceAccessors";
import { resetMixPanel } from "../lib/mixpanelTracking";
import routeBroadcast from "../broadcasts/routeBroadcast";
import { broadcastOpenPermissionModal } from "../lib/authFunctions";
import { useMetricsStore } from "../services/stores/metricsStore";
import {
  getMetricsDataID,
  getMetricsResponse,
  getMetricsWeekNumber,
  bulkFetchMetricsReports,
  isMetricsReady,
  METRICS_PERIOD,
} from "./metrics/metricsAccessorFunctions";
import modalBroadcast from "../broadcasts/modalBroadcast";
import { MODAL_TYPES } from "../lib/modalFunctions";
import conferencingBroadcasts from "../broadcasts/conferencingBroadcasts";
import {
  getZoomSchedulerUser,
  getZoomSchedulers,
} from "../services/zoomFunctions";
import { addDefaultToArray, isEmptyArray, isStringArraysEqual, removeDuplicatesFromArray } from "../lib/arrayFunctions";
import {
  APP_BROADCAST_VALUES,
  BACKEND_BROADCAST_VALUES,
  BROADCAST_VALUES,
  CONTACT_BROADCAST_VALUES,
  FETCH_BROADCAST_VALUES,
  HOLDS_BROADCAST_VALUES,
  MAIN_CALENDAR_BROADCAST_VALUES,
  ROUTE_BROADCAST_VALUES,
  SETTINGS_BROADCAST_VALUES,
  UPDATE_SETTINGS_BROADCAST_VALUES,
} from "../lib/broadcastValues";
import {
  LOCAL_DATA_ACTION,
} from "../lib/localData";
import {
  filterOutInvalidContacts,
  getDomainAndContacts,
} from "../lib/contactFunctions";
import { useOutstandingSlotsStore } from "../services/stores/outstandingSlots";
import { fetchOutstandingSlots, patchOutstandingSlot } from "./queries/slots";
import { useConferenceRoomStore } from "../services/stores/conferenceRoomStores";
import {
  GOOGLE_GEOCODING_RESPONSE_STATUS,
  GOOGLE_PLACES_ID,
  GOOGLE_PLACES_SCRIPT_SRC,
  GOOGLE_TIME_ZONE_RESPONSE_STATUS,
  getGeoCodeFromAddress,
  getTimeZoneFromLocation,
} from "../lib/googleFunctions";
import {
  GET_LATEST_MASTER_ACCOUNT_ENDPOINT,
  getEnrichedCompaniesURL,
  GROUP_SPREADSHEET_LINKS_ENDPOINT,
  HOLDS_ENDPOINT,
  LATEST_GROUP_VOTE_LINKS,
  STRIPE_ENDPOINTS,
  UPDATE_SLOTS_SETTINGS_ENDPOINT,
  ZOOM_LINK_ENDPOINT,
} from "../lib/endpoints";
import { fetchOutlookCategories } from "./queries/categories";
import { useOutlookCategoriesStore } from "../services/stores/outlookCategoriesStore";
import { getDefaultHeaders } from "../lib/fetchFunctions";
import {
  isEmptyArrayOrFalsey,
  isEmptyObjectOrFalsey,
  isNullOrUndefined,
} from "../services/typeGuards";
import {
  getAccountDateFormat,
  getAccountHideWeekend,
  getAccountStartOfWeek,
  getAnchorTimeZonesInSettings,
  getDefaultUserTimeZone,
  getDefaultZoomMode,
  getIsAccountIn24HourFormat,
  getUserAccountOrderedTimeZone,
  isUserInDarkMode,
  shouldMatchOSSetting,
  showAccountDeclinedEvents,
} from "../lib/settingsFunctions";
import {
  equalAfterTrimAndLowerCased,
  isSameEmail,
  pluralize,
  safeCheckStringIncludes,
} from "../lib/stringFunctions";
import { MAGIC_LINK_PATH } from "../services/maestro/maestroRouting";
import { useMagicLink } from "../services/stores/magicLinkStore";
import { getUserConnectedAccountToken, isUserFromMagicLink } from "../services/maestro/maestroAccessors";
import updateSettingsBroadcast from "../broadcasts/updateSettingsBroadcast";
import { fetcherGet, fetcherPatch, fetcherPost } from "../services/fetcherFunctions";
import { isSameWeekMonday } from "../lib/dateFunctions";
import { getObjectEmail } from "../lib/objectFunctions";
import { WEB_STYLES_ID } from "../services/elementIDVariables";
import { guardedParseISO } from "../lib/timeFunctions";
import { hasInternetConnection } from "../lib/onlineCheckFunctions";
import { validateZoomUrl } from "../lib/urlFunctions";
import { isLocal } from "../services/devFunctions";
import { clearEventStyleCache } from "../lib/stylesCache";
import holdsBroadcast from "../broadcasts/holdsBroadcast";
import { createMimicEventFromEvent } from "../lib/mimicEventUpdate";
import { getEventHoldID, getFrontendHoldEventsHoldsID } from "../services/holdFunctions";
import fetchBroadcast from "../broadcasts/fetchBroadcast";
import { fetchGoogleDistributionLists, fetchOutlookDistributionLists } from "./queries/distroLists";
import { updateDistroLists } from "../lib/distroListFunctions";
import { delayByMs } from "../lib/asyncFunctions";

class BackendContainer extends PureComponent {
  constructor(props) {
    super(props);
    this._googleScriptLoadedFailed = false;
    this._hasStartedLogout = false;
    this._isFetchingBilling = false;
    this._isFetchingLast12WeeksMetrics = false;

    this.fetchGeoCode = this.fetchGeoCode.bind(this);
    this.onClickZoomLogin = this.onClickZoomLogin.bind(this);
    this.addZoomAccessToken = this.addZoomAccessToken.bind(this);
    this.updateZoomLink = this.updateZoomLink.bind(this);
    this.updateCustomConferencingLink =
      this.updateCustomConferencingLink.bind(this);
    this.updateRecentTimeZones = this.updateRecentTimeZones.bind(this);
    this.loadGooglePlacesScript = this.loadGooglePlacesScript.bind(this);
    this.zoomLogout = this.zoomLogout.bind(this);
    this.onClickLogout = this.onClickLogout.bind(this);
    this.enableDarkMode = this.enableDarkMode.bind(this);
    this.disableDarkMode = this.disableDarkMode.bind(this);
    this.setColorSchemeBasedOnOS = this.setColorSchemeBasedOnOS.bind(this);
    this.updateAccountState = this.updateAccountState.bind(this);
    this.initTrialedSubscription = this.initTrialedSubscription.bind(this);
    this.updateMasterAccount = this.updateMasterAccount.bind(this);
    this.updateDefaultPaymentMethod =
      this.updateDefaultPaymentMethod.bind(this);
    this.updateSubscription = this.updateSubscription.bind(this);
    this.addPromotionCode = this.addPromotionCode.bind(this);
    this.checkForPromotionsNotSeen = this.checkForPromotionsNotSeen.bind(this);
    this.checkForPromotionsThatHaveNotBeenApplied =
      this.checkForPromotionsThatHaveNotBeenApplied.bind(this);
    this.updateSeenPromotion = this.updateSeenPromotion.bind(this);
    this.getSubscriptionDetails = this.getSubscriptionDetails.bind(this);
    this.saveSlotsSettings = this.saveSlotsSettings.bind(this);
    this.sendGroupVoteInvites = this.sendGroupVoteInvites.bind(this);
    this.fetchBillingInfo = this.fetchBillingInfo.bind(this);
    this.updateCalendarProperties = this.updateCalendarProperties.bind(this);
    this.fetchOutlookContacts = this.fetchOutlookContacts.bind(this);
    this.fetchOutlookDomainUsers = this.fetchOutlookDomainUsers.bind(this);
    this.trackWindowSize = this.trackWindowSize.bind(this);
    this.fetchDefaultOutlookConferencing =
      this.fetchDefaultOutlookConferencing.bind(this);
    this.cancelSubscription = this.cancelSubscription.bind(this);
    this.routeOnAuth = this.routeOnAuth.bind(this);
    this.setMasterAccountSettings = this.setMasterAccountSettings.bind(this);
    this.updatePhoneNumber = this.updatePhoneNumber.bind(this);
    this.storeUserInformation = this.storeUserInformation.bind(this);
    this.getLatestMasterAccount = this.getLatestMasterAccount.bind(this);
    this.upgradeFromMonthlyToAnnual =
      this.upgradeFromMonthlyToAnnual.bind(this);
    this.getUserNeedCard = this.getUserNeedCard.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
    this.onClickLLMFeedback = this.onClickLLMFeedback.bind(this);
    this.fetchEnrichDomains = this.fetchEnrichDomains.bind(this);
    this.fetchAIPermission = this.fetchAIPermission.bind(this);
    this.openTypeFormLinkAndReroute =
      this.openTypeFormLinkAndReroute.bind(this);
    this.getCalendlyLinkData = this.getCalendlyLinkData.bind(this);
    this.getVimcalLinkData = this.getVimcalLinkData.bind(this);
    this.updateUserSmartTags = this.updateUserSmartTags.bind(this);
    this.deleteUserSmartTag = this.deleteUserSmartTag.bind(this);
    this.forwardOutlookEvent = this.forwardOutlookEvent.bind(this);
    this.logOutOfSingleAccount = this.logOutOfSingleAccount.bind(this);
    this.sendFeedback = this.sendFeedback.bind(this);
    this.deleteOutlookCalendar = this.deleteOutlookCalendar.bind(this);
    this.goToLoginPage = this.goToLoginPage.bind(this);
    this.goToHome = this.goToHome.bind(this);
    this.goToNewUserSSOLogin = this.goToNewUserSSOLogin.bind(this);
    this.upsertSpecialTags = this.upsertSpecialTags.bind(this);
    this.checkForMetrics = this.checkForMetrics.bind(this);
    this.connectedUserSync = this.connectedUserSync.bind(this);
    this.updatePersonalLinkTrips = this.updatePersonalLinkTrips.bind(this);
    this.updateRecentlySearchedContacts =
      this.updateRecentlySearchedContacts.bind(this);
    this.updateRecentContacts = this.updateRecentContacts.bind(this);
    this.updateSocialLinks = this.updateSocialLinks.bind(this);
    this.fetchOutstandingSlots = fetchOutstandingSlots.bind(this);
    this.patchOutstandingSlot = patchOutstandingSlot.bind(this);
    this.resetAndReloadGooglePlacesScript =
      this.resetAndReloadGooglePlacesScript.bind(this);
    this.syncOutlookCategories = this.syncOutlookCategories.bind(this);
    this.fetchEventAttachments = this.fetchEventAttachments.bind(this);
    this.updateCalendarView = this.updateCalendarView.bind(this);
    this.getDistroList = this.getDistroList.bind(this);
    this.goToDesktopLoginPage = this.goToDesktopLoginPage.bind(this);
    this.createHoldsFromLinkable = this.createHoldsFromLinkable.bind(this);
    this.createMimicHoldEvents = this.createMimicHoldEvents.bind(this);
    this.removeMimicHoldEvents = this.removeMimicHoldEvents.bind(this);
    this.clearCompanyDomainsPendingBackend = this.clearCompanyDomainsPendingBackend.bind(this);
    this.hideEvent = this.hideEvent.bind(this);

    AppBroadcast.subscribe("FETCH_TIMEZONE_BY_GEOCODE", this.fetchGeoCode);
    AppBroadcast.subscribe("AUTHENTICATE_ZOOM", this.onClickZoomLogin);
    AppBroadcast.subscribe(APP_BROADCAST_VALUES.LOGOUT_ZOOM, this.zoomLogout);
    AppBroadcast.subscribe(
      APP_BROADCAST_VALUES.UPDATE_CURRENT_USER_ACCESS_TOKEN,
      this.addZoomAccessToken,
    );
    AppBroadcast.subscribe("UPDATE_ZOOM_LINK", this.updateZoomLink);
    AppBroadcast.subscribe(
      "SET_CUSTOM_CONFERENCING_LINK",
      this.updateCustomConferencingLink,
    );
    Broadcast.subscribe(
      "UPDATE_RECENTLY_SEARCHED_TIME_ZONES",
      this.updateRecentTimeZones,
    );
    AppBroadcast.subscribe("CLICK_LOG_OUT", this.onClickLogout);
    Broadcast.subscribe("ENABLE_DARK_MODE", this.enableDarkMode);
    Broadcast.subscribe("DISABLE_DARK_MODE", this.disableDarkMode);
    Broadcast.subscribe(
      "SET_COLOR_SCHEME_ON_OS_BACKEND",
      this.setColorSchemeBasedOnOS,
    );
    AppBroadcast.subscribe(
      APP_BROADCAST_VALUES.UPDATE_ACCOUNT_STATE,
      this.updateAccountState,
    );
    AppBroadcast.subscribe(
      APP_BROADCAST_VALUES.INIT_TRIALED_SUBSCRIPTION,
      this.initTrialedSubscription,
    );
    AppBroadcast.subscribe(
      APP_BROADCAST_VALUES.UPDATE_MASTER_ACCOUNT,
      this.updateMasterAccount,
    );
    AppBroadcast.subscribe("UPDATE_SUBSCRIPTION", this.updateSubscription);
    AppBroadcast.subscribe("ADD_PROMOTION_CODE", this.addPromotionCode);
    backendBroadcasts.subscribe(
      "CHECK_FOR_PROMOTIONS_NOT_SEEN",
      this.checkForPromotionsNotSeen,
    );
    backendBroadcasts.subscribe(
      "CHECK_FOR_PROMOTIONS_UNAPPLIED",
      this.checkForPromotionsThatHaveNotBeenApplied,
    );
    backendBroadcasts.subscribe(
      "UPDATE_SEEN_PROMOTION",
      this.updateSeenPromotion,
    );
    backendBroadcasts.subscribe(
      "GET_SUBSCRIPTION_DETAILS",
      this.getSubscriptionDetails,
    );
    availabilityBroadcast.subscribe(
      "SAVE_SLOTS_SETTINGS",
      this.saveSlotsSettings,
    );
    availabilityBroadcast.subscribe(
      "SEND_GROUP_VOTE_INVITES",
      this.sendGroupVoteInvites,
    );
    backendBroadcasts.subscribe(BACKEND_BROADCAST_VALUES.GET_BILLING_INFO, this.fetchBillingInfo);
    backendBroadcasts.subscribe(
      "UPDATE_CALENDAR_PROPERTIES",
      this.updateCalendarProperties,
    );
    ContactBroadcast.subscribe(
      CONTACT_BROADCAST_VALUES.FETCH_OUTLOOK_CONTACTS,
      this.fetchOutlookContacts,
    );
    ContactBroadcast.subscribe(
      CONTACT_BROADCAST_VALUES.FETCH_OUTLOOK_DOMAIN_USERS,
      this.fetchOutlookDomainUsers,
    );
    backendBroadcasts.subscribe("TRACK_WINDOW_SIZE", this.trackWindowSize);
    backendBroadcasts.subscribe(
      "GET_DEFAULT_OUTLOOK_CONFERENCING",
      this.fetchDefaultOutlookConferencing,
    );
    backendBroadcasts.subscribe("CANCEL_SUBSCRIPTION", this.cancelSubscription);
    routeBroadcast.subscribe(
      ROUTE_BROADCAST_VALUES.ROUTE_ON_AUTH,
      this.routeOnAuth,
    );
    backendBroadcasts.subscribe(
      "APPLY_MASTER_ACCOUNT_SETTINGS",
      this.setMasterAccountSettings,
    );
    backendBroadcasts.subscribe("UPDATE_PHONE_NUMBER", this.updatePhoneNumber);
    backendBroadcasts.subscribe(
      BACKEND_BROADCAST_VALUES.STORE_USER_INFORMATION,
      this.storeUserInformation,
    );
    backendBroadcasts.subscribe(
      "GET_LATEST_MASTER_ACCOUNT",
      this.getLatestMasterAccount,
    );
    backendBroadcasts.subscribe(
      "UPGRADE_FROM_MONTHLY_TO_ANNUAL",
      this.upgradeFromMonthlyToAnnual,
    );
    backendBroadcasts.subscribe("GET_NEED_CARD", this.getUserNeedCard);
    backendBroadcasts.subscribe("SEND_EMAIL", this.sendEmail);
    backendBroadcasts.subscribe("SEND_LLM_FEEDBACK", this.onClickLLMFeedback);
    backendBroadcasts.subscribe(
      BACKEND_BROADCAST_VALUES.FETCH_DOMAIN_SUMMARIES,
      this.fetchEnrichDomains,
    );
    backendBroadcasts.subscribe("FETCH_AI_PERMISSION", this.fetchAIPermission);
    backendBroadcasts.subscribe(
      "OPEN_TYPE_FORM_AND_REROUTE",
      this.openTypeFormLinkAndReroute,
    );
    backendBroadcasts.subscribe(
      "GET_CALENDLY_LINK_DATA",
      this.getCalendlyLinkData,
    );
    backendBroadcasts.subscribe("GET_VIMCAL_LINK_DATA", this.getVimcalLinkData);
    backendBroadcasts.subscribe(
      BACKEND_BROADCAST_VALUES.UPDATE_USER_SMART_TAGS,
      this.updateUserSmartTags,
    );
    backendBroadcasts.subscribe(
      "DELETE_USER_SMART_TAG",
      this.deleteUserSmartTag,
    );
    backendBroadcasts.subscribe(
      "FORWARD_OUTLOOK_EVENT",
      this.forwardOutlookEvent,
    );
    backendBroadcasts.subscribe(
      "LOGOUT_OF_SINGLE_ACCOUNT",
      this.logOutOfSingleAccount,
    );
    backendBroadcasts.subscribe("SEND_FEEDBACK", this.sendFeedback);
    backendBroadcasts.subscribe(
      "DELETE_OUTLOOK_CALENDAR",
      this.deleteOutlookCalendar,
    );
    routeBroadcast.subscribe("GO_TO_LOGIN_PAGE", this.goToLoginPage);
    routeBroadcast.subscribe(ROUTE_BROADCAST_VALUES.GO_TO_DESKTOP_LOGIN, this.goToDesktopLoginPage);
    routeBroadcast.subscribe("GO_TO_HOME", this.goToHome);
    backendBroadcasts.subscribe("UPSERT_SPECIAL_TAGS", this.upsertSpecialTags);
    backendBroadcasts.subscribe("CHECK_FOR_METRICS", this.checkForMetrics);
    backendBroadcasts.subscribe(BACKEND_BROADCAST_VALUES.INITIAL_CONNECTED_USERS_SYNC, this.connectedUserSync);
    backendBroadcasts.subscribe(
      BACKEND_BROADCAST_VALUES.UPDATE_PERSONAL_LINK_TRIPS,
      this.updatePersonalLinkTrips,
    );
    backendBroadcasts.subscribe(
      "UPDATE_RECENTLY_SEARCHED_CONTACTS",
      this.updateRecentlySearchedContacts,
    );
    backendBroadcasts.subscribe(
      "UPDATE_RECENT_CONTACTS",
      this.updateRecentContacts,
    );
    backendBroadcasts.subscribe(
      BACKEND_BROADCAST_VALUES.UPDATE_SOCIAL_LINKS,
      this.updateSocialLinks,
    );
    backendBroadcasts.subscribe(
      BACKEND_BROADCAST_VALUES.FETCH_OUTSTANDING_SLOTS,
      this.fetchOutstandingSlots,
    );
    backendBroadcasts.subscribe(
      BACKEND_BROADCAST_VALUES.PATCH_OUTSTANDING_SLOT,
      this.patchOutstandingSlot,
    );
    backendBroadcasts.subscribe(
      BACKEND_BROADCAST_VALUES.RELOAD_GOOGLE_PLACES_SCRIPT,
      this.resetAndReloadGooglePlacesScript,
    );
    backendBroadcasts.subscribe(
      BACKEND_BROADCAST_VALUES.FETCH_OUTLOOK_CATEGORIES,
      this.syncOutlookCategories,
    );
    backendBroadcasts.subscribe(
      BACKEND_BROADCAST_VALUES.FETCH_EVENT_ATTACHMENTS,
      this.fetchEventAttachments,
    );
    mainCalendarBroadcast.subscribe(
      MAIN_CALENDAR_BROADCAST_VALUES.UPDATE_CALENDAR_VIEW,
      this.updateCalendarView,
    );
    backendBroadcasts.subscribe(
      BACKEND_BROADCAST_VALUES.GET_DISTRO_LISTS,
      this.getDistroList,
    );
    routeBroadcast.subscribe(ROUTE_BROADCAST_VALUES.GO_TO_NEW_USER_SSO_LOGIN, this.goToNewUserSSOLogin);
    holdsBroadcast.subscribe(HOLDS_BROADCAST_VALUES.CREATE_LINKABLE_HOLDS, this.createHoldsFromLinkable);
    holdsBroadcast.subscribe(HOLDS_BROADCAST_VALUES.REMOVE_MIMIC_EVENTS, this.removeMimicHoldEvents);
    backendBroadcasts.subscribe(BACKEND_BROADCAST_VALUES.CLEAR_COMPANY_DOMAINS_PENDING_BACKEND, this.clearCompanyDomainsPendingBackend);
    backendBroadcasts.subscribe(BACKEND_BROADCAST_VALUES.SET_HIDDEN_EVENTS, this.hideEvent);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadGooglePlacesScript(0);
    this.loadAppTimeZonesFromSettings();
    this.setMasterAccountSettings(); // update account info
  }

  componentWillUnmount() {
    this._isMounted = false;

    AppBroadcast.unsubscribe("FETCH_TIMEZONE_BY_GEOCODE");
    AppBroadcast.unsubscribe("AUTHENTICATE_ZOOM");
    AppBroadcast.unsubscribe(APP_BROADCAST_VALUES.LOGOUT_ZOOM);
    AppBroadcast.unsubscribe(APP_BROADCAST_VALUES.UPDATE_CURRENT_USER_ACCESS_TOKEN);
    AppBroadcast.unsubscribe("UPDATE_ZOOM_LINK");
    AppBroadcast.unsubscribe("SET_CUSTOM_CONFERENCING_LINK");
    Broadcast.unsubscribe("UPDATE_RECENTLY_SEARCHED_TIME_ZONES");
    AppBroadcast.unsubscribe("CLICK_LOG_OUT");
    Broadcast.unsubscribe("ENABLE_DARK_MODE");
    Broadcast.unsubscribe("DISABLE_DARK_MODE");
    Broadcast.unsubscribe("SET_COLOR_SCHEME_ON_OS_BACKEND");
    AppBroadcast.unsubscribe(APP_BROADCAST_VALUES.UPDATE_ACCOUNT_STATE);
    AppBroadcast.unsubscribe(APP_BROADCAST_VALUES.INIT_TRIALED_SUBSCRIPTION);
    AppBroadcast.unsubscribe(APP_BROADCAST_VALUES.UPDATE_MASTER_ACCOUNT);
    AppBroadcast.unsubscribe("UPDATE_SUBSCRIPTION");
    AppBroadcast.unsubscribe("ADD_PROMOTION_CODE");
    backendBroadcasts.unsubscribe("CHECK_FOR_PROMOTIONS_NOT_SEEN");
    backendBroadcasts.unsubscribe("CHECK_FOR_PROMOTIONS_UNAPPLIED");
    backendBroadcasts.unsubscribe("UPDATE_SEEN_PROMOTION");
    backendBroadcasts.unsubscribe("GET_SUBSCRIPTION_DETAILS");
    availabilityBroadcast.unsubscribe("SAVE_SLOTS_SETTINGS");
    availabilityBroadcast.unsubscribe("SEND_GROUP_VOTE_INVITES");
    backendBroadcasts.unsubscribe(BACKEND_BROADCAST_VALUES.GET_BILLING_INFO);
    backendBroadcasts.unsubscribe("UPDATE_CALENDAR_PROPERTIES");
    backendBroadcasts.unsubscribe("TRACK_WINDOW_SIZE");
    backendBroadcasts.unsubscribe("GET_DEFAULT_OUTLOOK_CONFERENCING");
    ContactBroadcast.unsubscribe(CONTACT_BROADCAST_VALUES.FETCH_OUTLOOK_DOMAIN_USERS);
    ContactBroadcast.unsubscribe(CONTACT_BROADCAST_VALUES.FETCH_OUTLOOK_CONTACTS);
    backendBroadcasts.unsubscribe("CANCEL_SUBSCRIPTION");
    routeBroadcast.unsubscribe(ROUTE_BROADCAST_VALUES.ROUTE_ON_AUTH);
    backendBroadcasts.unsubscribe("APPLY_MASTER_ACCOUNT_SETTINGS");
    backendBroadcasts.unsubscribe("UPDATE_PHONE_NUMBER");
    backendBroadcasts.unsubscribe(
      BACKEND_BROADCAST_VALUES.STORE_USER_INFORMATION,
    );
    backendBroadcasts.unsubscribe("GET_LATEST_MASTER_ACCOUNT");
    backendBroadcasts.unsubscribe("UPGRADE_FROM_MONTHLY_TO_ANNUAL");
    backendBroadcasts.unsubscribe("GET_NEED_CARD");
    backendBroadcasts.unsubscribe("SEND_EMAIL");
    backendBroadcasts.unsubscribe("SEND_LLM_FEEDBACK");
    backendBroadcasts.unsubscribe(BACKEND_BROADCAST_VALUES.FETCH_DOMAIN_SUMMARIES);
    backendBroadcasts.unsubscribe("FETCH_AI_PERMISSION");
    backendBroadcasts.unsubscribe("OPEN_TYPE_FORM_AND_REROUTE");
    backendBroadcasts.unsubscribe("GET_CALENDLY_LINK_DATA");
    backendBroadcasts.unsubscribe("GET_VIMCAL_LINK_DATA");
    backendBroadcasts.unsubscribe(BACKEND_BROADCAST_VALUES.UPDATE_USER_SMART_TAGS);
    backendBroadcasts.unsubscribe("DELETE_USER_SMART_TAG");
    backendBroadcasts.unsubscribe("FORWARD_OUTLOOK_EVENT");
    backendBroadcasts.unsubscribe("LOGOUT_OF_SINGLE_ACCOUNT");
    backendBroadcasts.unsubscribe("SEND_FEEDBACK");
    backendBroadcasts.unsubscribe("DELETE_OUTLOOK_CALENDAR");
    routeBroadcast.unsubscribe("GO_TO_LOGIN_PAGE");
    routeBroadcast.unsubscribe(ROUTE_BROADCAST_VALUES.GO_TO_DESKTOP_LOGIN);
    routeBroadcast.unsubscribe("GO_TO_HOME");
    backendBroadcasts.unsubscribe("UPSERT_SPECIAL_TAGS");
    backendBroadcasts.unsubscribe("CHECK_FOR_METRICS");
    backendBroadcasts.unsubscribe(BACKEND_BROADCAST_VALUES.INITIAL_CONNECTED_USERS_SYNC);
    backendBroadcasts.unsubscribe(
      BACKEND_BROADCAST_VALUES.UPDATE_PERSONAL_LINK_TRIPS,
    );
    backendBroadcasts.unsubscribe("UPDATE_RECENTLY_SEARCHED_CONTACTS");
    backendBroadcasts.unsubscribe("UPDATE_RECENT_CONTACTS");
    backendBroadcasts.unsubscribe(BACKEND_BROADCAST_VALUES.UPDATE_SOCIAL_LINKS);
    backendBroadcasts.unsubscribe(
      BACKEND_BROADCAST_VALUES.FETCH_OUTSTANDING_SLOTS,
    );
    backendBroadcasts.unsubscribe(
      BACKEND_BROADCAST_VALUES.PATCH_OUTSTANDING_SLOT,
    );
    backendBroadcasts.unsubscribe(
      BACKEND_BROADCAST_VALUES.RELOAD_GOOGLE_PLACES_SCRIPT,
    );
    backendBroadcasts.unsubscribe(
      BACKEND_BROADCAST_VALUES.FETCH_OUTLOOK_CATEGORIES,
    );
    backendBroadcasts.unsubscribe(
      BACKEND_BROADCAST_VALUES.FETCH_EVENT_ATTACHMENTS,
    );
    mainCalendarBroadcast.unsubscribe(
      MAIN_CALENDAR_BROADCAST_VALUES.UPDATE_CALENDAR_VIEW,
    );
    backendBroadcasts.unsubscribe(BACKEND_BROADCAST_VALUES.GET_DISTRO_LISTS);
    routeBroadcast.unsubscribe(ROUTE_BROADCAST_VALUES.GO_TO_NEW_USER_SSO_LOGIN);
    holdsBroadcast.unsubscribe(HOLDS_BROADCAST_VALUES.CREATE_LINKABLE_HOLDS);
    holdsBroadcast.unsubscribe(HOLDS_BROADCAST_VALUES.REMOVE_MIMIC_EVENTS);
    backendBroadcasts.unsubscribe(BACKEND_BROADCAST_VALUES.CLEAR_COMPANY_DOMAINS_PENDING_BACKEND);
    backendBroadcasts.unsubscribe(BACKEND_BROADCAST_VALUES.SET_HIDDEN_EVENTS);
  }

  render() {
    return null;
  }

  resetAndReloadGooglePlacesScript() {
    if (doesGooglePlacesScriptExist()) {
      return;
    }
    this._googleScriptLoadedFailed = false;
    this.loadGooglePlacesScript();
  }

  async loadGooglePlacesScript(loadCount = 0) {
    if (loadCount > 5) {
      this._googleScriptLoadedFailed = true;
      return;
    }

    let script;
    let resolveLoadNewScript; // so we can remove it properly. Otherwise, if it's normal arrow function `const a = () => {}`, it won't be able to remove it becasue there's no reference
    let rejectNewScript;

    // Helper functions below
    const loadScript = () => {
      return new Promise((resolve, reject) => {
        script = document.createElement("script");

        script.src = GOOGLE_PLACES_SCRIPT_SRC;
        script.async = true;
        script.defer = true;
        script.id = GOOGLE_PLACES_ID;

        resolveLoadNewScript = () => {
          resolve();
        };

        rejectNewScript = (e) => {
          // goes into catch
          reject(e);
        };

        script.addEventListener("load", resolveLoadNewScript);
        script.addEventListener("error", rejectNewScript);

        if (doesGooglePlacesScriptExist()) {
          // more of sanity check so we don't append another script
          // check if it already exists
          resolve();
          return; // early return;
        }
        document.body.appendChild(script);
      });
    };

    const removeScriptListeners = () => {
      if (!script) {
        return;
      }
      if (resolveLoadNewScript) {
        script.removeEventListener("load", resolveLoadNewScript);
      }

      if (rejectNewScript) {
        script.removeEventListener("error", rejectNewScript);
      }
      script = null;
      resolveLoadNewScript = null;
      rejectNewScript = null;
    };

    const reloadScript = () => {
      removeScriptListeners();
      setTimeout(() => {
        if (!this._isMounted) {
          return;
        }
        this.loadGooglePlacesScript(loadCount + 1); // try again
      }, SECOND_IN_MS * (loadCount + 1)); // try again in a few sec
    };
    // Helper functions above

    // Main logic below
    if (doesGooglePlacesScriptExist()) {
      // already exists
      return;
    }

    try {
      await loadScript();
      if (!this._isMounted) {
        return;
      }

      removeScriptListeners(); // script has been loaded
    } catch (error) {
      handleError(error);
      if (!this._isMounted) {
        return;
      }

      removeScriptListeners();
      // Try again
      if (loadCount <= 5) {
        reloadScript();
      } else {
        this._googleScriptLoadedFailed = true;
      }
    }
  }

  async fetchGeoCode(address) {
    try {
      const response = await getGeoCodeFromAddress(address);
      const output = await response.json();
      if (!this._isMounted) {
        return;
      }

      if (
        output?.status === GOOGLE_GEOCODING_RESPONSE_STATUS.OK &&
        output.results?.[0]?.geometry?.location
      ) {
        // if API reports everything was returned successfully
        this.fetchTimeZone(output.results[0].geometry.location, address);
      } else {
        Broadcast.publish(
          SET_DISAPPEARING_NOTIFICATION_MESSAGE,
          GENERIC_ERROR_MESSAGE,
        );

        sendMessageToSentry("error on fetchGeoCode", "output.status !== ok");
      }
    } catch (error) {
      handleError(error);
    }
  }

  async fetchTimeZone(location, city) {
    try {
      const response = await getTimeZoneFromLocation(location); // const { lat, lng } = location;
      const output = await response.json();
      if (!this._isMounted) {
        return;
      }

      if (
        output?.status === GOOGLE_TIME_ZONE_RESPONSE_STATUS.OK &&
        output.timeZoneId
      ) {
        this.updateRecentlyUpdatedTimeZone(city, output.timeZoneId);

        if (isOnboardingMode()) {
          Broadcast.publish("SELECT_TIME_ZONE", {
            timeZone: output.timeZoneId,
          });
          return;
        }

        if (this.props.currentTimeZone !== output.timeZoneId) {
          if (output.timeZoneId !== this.props.defaultBrowserTimeZone) {
            let timeZone = output.timeZoneId;
            Broadcast.publish("SELECT_TIME_ZONE", { timeZone });
          } else {
            Broadcast.publish("RETURN_TO_DEFAULT_TIME_ZONE");
          }
        } else {
          Broadcast.publish(
            SET_DISAPPEARING_NOTIFICATION_MESSAGE,
            `All your events are now in ${output.timeZoneName}`,
          );
        }
      } else {
        Broadcast.publish(
          SET_DISAPPEARING_NOTIFICATION_MESSAGE,
          GENERIC_ERROR_MESSAGE,
        );

        sendMessageToSentry("error on fetchTimeZone", "output.status !== ok");
      }
    } catch (error) {
      handleError(error);
      if (!this._isMounted) {
        return;
      }
      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        GENERIC_ERROR_MESSAGE,
      );
    }
  }

  updateRecentlyUpdatedTimeZone(newCity, cityTimeZone) {
    const currentTimeZoneList = getRecentlySearchedTimeZones(
      this.props.currentUser,
    );
    const filteredValue = currentTimeZoneList.filter(
      (t) => t.label !== newCity,
    );
    const updatedRecentTimeZones = [
      {
        isTimeZone: false,
        value: cityTimeZone,
        label: newCity,
        ts: new Date().toISOString(),
      },
    ].concat(filteredValue);

    this.updateRecentTimeZones(updatedRecentTimeZones);
  }

  updateRecentTimeZones(updatedTimeZones, isTimeZoneGroup = false) {
    try {
      if (!updatedTimeZones || !Array.isArray(updatedTimeZones)) {
        throw new Error("Exception message updateRecentTimeZones");
      }

      const {
        currentUser,
      } = this.props;

      if (isEmptyObjectOrFalsey(currentUser)) {
        return;
      }

      const formattedTimeZones = updatedTimeZones.slice(0, 7);

      const path = "recent_time_zones";
      const url = constructRequestURL(path);

      const body = isTimeZoneGroup
        ? JSON.stringify({ recent_time_zone_groups: formattedTimeZones })
        : JSON.stringify({ recent_time_zones: formattedTimeZones });
      const payloadData = { body };

      fetcherPost({
        url,
        payloadData,
        email: getUserEmail(currentUser),
        connectedAccountToken: getUserConnectedAccountToken({ user: currentUser }),
      })
        .then((response) => {
          if (!this._isMounted || !response || !response.user || isErrorResponse(response)) {
            return;
          }
          const {
            user,
          } = response;
          this.storeUserInformation(user);
        })
        .catch((err) => {
          handleError(err);
        });
    } catch (err) {
      handleError(err);
    }
  }

  onClickZoomLogin(user) {
    const { redirectUri, clientId } = determineZoomAuthInfo();
    const { masterAccount } = this.props.masterAccount;

    const zoomSchedulers = getZoomSchedulers(this.props.zoomSchedulers);
    const selectedUser = getZoomSchedulerUser({
      schedulers: zoomSchedulers,
      user: user || this.props.currentUser,
      masterAccount,
    });

    const selectedUserEmail = getUserEmail(selectedUser);
    setZoomLoginUserName({ userEmail: selectedUserEmail });

    const determineZoomAuthReroute = () => {
      if (isElectron()) {
        const env = determineElectronLoginEnv();
        return `vimcal-${env}://zoom=`;
      } else {
        return `${window.location.origin}/zoom-login`;
      }
    };
    const zoomLoginURL =
      "https://zoom.us/oauth/authorize?response_type=code" +
      "&redirect_uri=" +
      redirectUri +
      "&client_id=" +
      clientId +
      "&no_redirect=true" + // always show the account option. Otherwise user can't log into multiple zoom accounts
      "&state=" +
      encodeURIComponent(
        JSON.stringify({
          u: selectedUserEmail,
          t: getUserToken(selectedUser),
          r: determineZoomAuthReroute(),
        }),
      );

    openSignInWindow(zoomLoginURL, "Zoom OAuth Login");
  }

  zoomLogout(userEmail) {
    if (!userEmail) {
      return;
    }
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    const { setSchedulers, schedulers } = this.props.zoomSchedulers;

    const deleteUserEmailFromSchedulers = () => {
      const updatedSchedulers = produce(schedulers, (draft) => {
        Object.keys(draft).forEach((key) => {
          if (isSameEmail(getObjectEmail(draft[key]), userEmail)) {
            delete draft[key];
          }
        });
      });
      setSchedulers(updatedSchedulers);
      conferencingBroadcasts.publish("FETCH_ZOOM_SCHEDULERS"); // refetch just in case
    };

    const forceRemoveZoomAccessToken = () => {
      const matchingUser = getMatchingUserFromAllUsers({ allUsers: allLoggedInUsers, userEmail });
      if (isEmptyObjectOrFalsey(matchingUser)) {
        return;
      }
      const updatedUser = { ...matchingUser, has_zoom_access: false };
      deleteUserEmailFromSchedulers();
      this.storeUserInformation(updatedUser);
    };

    const removeZoomAccessToken = (user) => {
      if (isEmptyObjectOrFalsey(user)) {
        return;
      }
      if (getDefaultZoomMode({ user })) {
        Broadcast.publish(
          "TOGGLE_ALWAYS_USE_PERSONAL_LINK_SETTING",
          isDefaultZoomPersonalLink(user),
          true,
        );
      }
      deleteUserEmailFromSchedulers();
      this.storeUserInformation(user);
    };

    ApiClient.logoutZoom(userEmail)
      .then((response) => {
        if (!this._isMounted) {
          return;
        }

        if (isEmptyObjectOrFalsey(response) || !response.user) {
          forceRemoveZoomAccessToken();
          return;
        }

        removeZoomAccessToken(response.user);
      })
      .catch((error) => {
        handleError(error);
      });
  }

  addZoomAccessToken({ personalLink, defaultZoomMode }) {
    const {
      currentUser,
    } = this.props;
    const {
      masterAccount,
    } = this.props.masterAccount;
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    if (
      isEmptyObjectOrFalsey(currentUser) ||
      isEmptyObjectOrFalsey(masterAccount)
    ) {
      return;
    }

    const zoomUser =
      getZoomLoginUserName(allLoggedInUsers) ?? currentUser; // so we can set it based on the user that the

    const updatedUser = {
      ...zoomUser,
      has_zoom_access: true,
      settings: zoomUser.settings && defaultZoomMode
        ? {
          ...zoomUser.settings,
          default_zoom_mode: defaultZoomMode,
        }
        : zoomUser.settings,
      zoom_link: !validateZoomUrl(personalLink) ? personalLink : zoomUser.zoom_link,
    };

    // Publish event only if settings.default_zoom_mode is updated
    if (zoomUser.settings && defaultZoomMode) {
      Broadcast.publish(
        "TOGGLE_ALWAYS_USE_PERSONAL_LINK_SETTING",
        defaultZoomMode === ZOOM_PERSONAL_LINK,
        true,
      );
    }

    this.storeUserInformation(updatedUser);

    conferencingBroadcasts.publish("FETCH_ZOOM_SCHEDULERS");

    const {
      actionMode,
    } = this.props;

    if (isOnboardingMode()) {
      AppBroadcast.publish("SET_ONBOARDING_ZOOM", updatedUser);
    } else if (isActionModeUpsertEvent(actionMode)) {
      Broadcast.publish("SET_EVENT_CONFERENCE", {
        value: CONFERENCING_TYPE.ZOOM_STRING,
      });
    } else if (isActionModeCreateAvailability(actionMode)) {
      Broadcast.publish(
        "SET_CREATE_AVAILABILITY_CONFERENCE",
        CONFERENCING_TYPE.ZOOM_STRING,
      );
    }
  }

  async updateZoomLink({ link, user }) {
    const path = ZOOM_LINK_ENDPOINT;
    const url = constructRequestURL(path);
    const params = {
      zoom_link: link,
    };
    const payloadData = {
      body: JSON.stringify(params),
    };
    const { currentUser } = this.props;

    try {
      const response = await fetcherPatch({
        authorizationRequired: true,
        connectedAccountToken: getUserConnectedAccountToken({ user }),
        email: getUserEmail(user) || getUserEmail(currentUser),
        payloadData,
        url,
      });
      if (!this._isMounted) {
        return;
      }

      this.storeUserInformation(response?.user);
    } catch(e) {
      handleError(e);
    }
  }

  storeUserInformation(updatedUser) {
    if (isEmptyObjectOrFalsey(updatedUser)) {
      return;
    }
    if (!isValidUser(updatedUser)) {
      return;
    }
    const {
      currentUser,
    } = this.props;
    const { masterAccount } = this.props.masterAccount;

    if (
      isSameEmail(
        getUserEmail(updatedUser),
        getUserEmail(currentUser),
      )
    ) {
      // only set it to current user if it's the same user
      this.props.storeUserData(updatedUser);
      this.loadAppTimeZonesFromSettings(masterAccount, updatedUser);
    } else {
      settingsBroadcast.publish("UPDATE_USER_LIST_IN_SETTINGS", updatedUser);
    }

    // set in zustand
    const { allLoggedInUsers, setAllLoggedInUsers } =
      this.props.allLoggedInUsers;

    const updatedAllLoggedInUsers = updateAllLoggedInUsers(allLoggedInUsers, [
      updatedUser,
    ]);

    setAllLoggedInUsers(updatedAllLoggedInUsers);
    Broadcast.publish("REFRESH_MENU_BAR_APP");
    const { selectedUser, setSelectedUser } = this.props.availabilityStore;
    if (!isEmptyObjectOrFalsey(selectedUser)) {
      setSelectedUser(updatedUser);
    }
  }

  updateCustomConferencingLink({
    conferencingURL,
    conferencingName,
    user,
  }) {
    updateSettingsBroadcast.publish(
      UPDATE_SETTINGS_BROADCAST_VALUES.UPDATE_SETTINGS_PROPERTY,
      {
        settings: {
          custom_conferencing_name: conferencingName,
          custom_conferencing_url: conferencingURL,
        },
        user,
      },
    );
  }

  onClickLogout(skipRouteChange = false) {
    if (this._hasStartedLogout) {
      // don't want to log out multiple times
      return;
    }
    mainCalendarBroadcast.publish(
      MAIN_CALENDAR_BROADCAST_VALUES.REMOVE_ALL_EVENTS,
    );

    this._hasStartedLogout = true;
    const serverLogOut = () => {
      const path = "logout";
      const url = constructRequestURL(path);
      if (isEmptyObjectOrFalsey(this.props.currentUser)) {
        return;
      }

      Fetcher.delete(url, {}, true, getUserEmail(this.props.currentUser)).catch(
        (response) => {
          handleError(response);
        },
      );
    };

    const clientLogOut = async (skipRouteChange = false) => {
      const loggedInAccounts = getAllLoggedInAccountDetails();

      // reset zustand stores
      const { resetPromotions } = this.props.promotionsStore;
      const { resetSubscription } = this.props.subscriptionStore;
      const { resetAllGroupLinks } = this.props.groupVoteStore;
      const { resetDefaultPaymentMethod } =
        this.props.defaultPaymentMethodStore;
      const { resetTeamPlan } = this.props.teamPlan;
      const { resetCharges } = this.props.charges;
      const { resetStripePaymentMethods } = this.props.stripePaymentMethods;
      const { resetStripeSubScriptions } = this.props.stripeSubscriptions;
      const { resetStripeUpcomingInvoices } = this.props.stripeUpcomingInvoices;
      const { resetBillingHasBeenFetched } = this.props.hasBillingBeenFetched;
      const { resetStripeCustomerEmail } = this.props.stripeCustomerEmail;
      const { resetAllCalendars } = this.props.allCalendars;
      const { resetAllLoggedInUsers, resetCollapsedUserCalendarList } =
        this.props.allLoggedInUsers;
      const { resetAllUserDomains } = this.props.allUserDomains;
      const { resetMasterAccount } = this.props.masterAccount;
      const { resetMenuBarDisplaySections } = this.props.menuBarDisplaySections;
      const { resetMenuBarDarkMode } = this.props.isMenuBarDarkMode;
      const { resetEnrichedContacts } = this.props.enrichContacstStore;
      const { resetRightHandSidebar } = this.props.hideRightHandSideBar;
      const { resetHiddenEventsIDs } = this.props.hiddenEventsIDs;
      const { clearStoredEmails } = this.props.storedContactsStore;
      const { resetEnrichedCompanies } = this.props.enrichedCompanyStore;
      const { resetPermissions } = this.props.permissionsStore;
      const { resetAvailabilityState } = this.props.availabilityStore;
      const { resetUserCodes } = this.props.userCodes;
      const { resetSchedulers } = this.props.zoomSchedulers;
      const { resetTimeZoneData } = this.props.appTimeZone;
      const { resetReferralStore } = this.props.referralStore;
      const { resetAccountActivity } = this.props.accountActivityStore;
      const { resetSettings } = this.props.appSettings;
      const { resetMetricsData } = this.props.metricsStore;
      const { resetTutorialWizard } = this.props.tutorialWizard;
      const { resetFeatureFlags } = this.props.featureFlags;
      const { resetOutstandingSlots } = this.props.outstandingSlotsStore;
      const { resetRecentlyUsedConferenceRooms } =
        this.props.conferenceRoomStore;
      const { clearOutlookCategories } = this.props.outlookCategoriesStore;
      const { resetOOOBusyEventsColorAndRange } =
        this.props.OOOBusyEventsDictionaryStore;
      const { resetDistroListDictionary } = this.props.distroListDictionary;
      const {
        resetUserTimeZoneIndex,
      } = this.props.userTimeZoneIndexStore;
      const { resetConnectedAccountTokens, resetMagicLinkToken } = this.props;

      resetMetricsData();
      resetMixPanel();
      resetPromotions();
      resetSubscription();
      resetAllGroupLinks();
      resetDefaultPaymentMethod();
      resetTeamPlan();
      resetCharges();
      resetStripePaymentMethods();
      resetStripeSubScriptions();
      resetStripeUpcomingInvoices();
      resetBillingHasBeenFetched();
      resetStripeCustomerEmail();
      resetAllCalendars();
      resetAllLoggedInUsers();
      resetCollapsedUserCalendarList();
      resetAllUserDomains();
      resetMasterAccount();
      resetMenuBarDisplaySections();
      resetMenuBarDarkMode();
      resetEnrichedContacts();
      resetRightHandSidebar();
      resetHiddenEventsIDs();
      clearStoredEmails();
      resetEnrichedCompanies();
      resetPermissions();
      resetAvailabilityState();
      resetUserCodes();
      resetSchedulers();
      resetAccountActivity();
      resetSettings();
      resetTutorialWizard();
      resetTimeZoneData();
      resetReferralStore();
      resetFeatureFlags();
      resetOutstandingSlots();
      resetRecentlyUsedConferenceRooms();
      clearOutlookCategories();
      resetOOOBusyEventsColorAndRange();
      resetDistroListDictionary();
      resetUserTimeZoneIndex();
      resetConnectedAccountTokens();
      resetMagicLinkToken();

      try {
        await db.logOut(loggedInAccounts);
        if (!this._isMounted) {
          return;
        }

        removeUserData(skipRouteChange);
      } catch (error) {
        handleError(error);
        if (!this._isMounted) {
          return;
        }
        removeUserData(skipRouteChange);
      }
    };

    const removeUserData = (skipRouteChange = false) => {
      let domElement = document.getElementById(WEB_STYLES_ID);
      if (domElement) {
        domElement.innerHTML = `body {
          background-color: #FFFFFF;
        }`;
      }
      domElement = null;

      localData(LOCAL_DATA_ACTION.CLEAR); // clear local storage on logout

      this.props.userLogout();
      if (!skipRouteChange) {
        this.goToLoginPage();
      }

      if (isElectron() && window?.vimcal?.onClickLogout) {
        window.vimcal.onClickLogout();
      }

      loadTheme(LIGHT_MODE_THEME);
      this._hasStartedLogout = false;
    };

    serverLogOut();
    clientLogOut(skipRouteChange);
  }

  goToLoginPage() {
    this.props.history.push("/login");
  }

  goToDesktopLoginPage() {
    this.props.history.push("/desktop-login");
  }

  enableDarkMode() {
    AppBroadcast.publish("SET_APP_DARK_MODE");
    const { masterAccount } = this.props.masterAccount;
    updateMasterAccountSettingsForFrontendAndBackend({
      masterAccount,
      updatedSettings: {
        [BACKEND_SETTINGS_NAMES.DARK_MODE]: true,
        match_os_scheme_desktop: false,
      },
    });
  }

  disableDarkMode() {
    const { masterAccount } = this.props.masterAccount;
    AppBroadcast.publish("SET_APP_LIGHT_MODE");

    updateMasterAccountSettingsForFrontendAndBackend({
      masterAccount,
      updatedSettings: {
        [BACKEND_SETTINGS_NAMES.DARK_MODE]: false,
        [BACKEND_SETTINGS_NAMES.MATCH_OS_SCHEME_DESKTOP]: false,
      },
    });
  }

  setColorSchemeBasedOnOS() {
    const { masterAccount } = this.props.masterAccount;

    updateMasterAccountSettingsForFrontendAndBackend({
      masterAccount,
      updatedSettings: {
        [BACKEND_SETTINGS_NAMES.MATCH_OS_SCHEME_DESKTOP]: true,
      },
    });
  }

  updateAccountState(state) {
    if (!state) {
      return;
    }

    const { masterAccount } = this.props.masterAccount;

    const path = `users/onboarding_state/${masterAccount.id}`;
    const url = constructRequestURL(path);
    const param = { state: state };

    if (state === ACCOUNT_STATE_PAYING) {
      // only set completed to true if the user is now a paying user.
      //! This should only be hit for pre-paid users
      param["completed_desktop_onboarding"] = true;
    }
    const payloadData = {
      body: JSON.stringify(param),
    };

    Fetcher.patch(url, payloadData, true, getUserEmail(this.props.currentUser))
      .then((masterAccount) => {
        if (!this._isMounted || isEmptyObjectOrFalsey(masterAccount)) {
          return;
        }

        this.updateMasterAccount(masterAccount);
      })
      .catch(handleError);
  }

  initTrialedSubscription() {
    const path = "users/subscription/";
    const url = constructRequestURL(path);
    const payloadData = {
      body: JSON.stringify({
        trial: true,
        completed_desktop_onboarding: true,
      }),
    };

    Fetcher.post(url, payloadData, true, getUserEmail(this.props.currentUser))
      .then((response) => {
        if (!this._isMounted || isEmptyObjectOrFalsey(response)) {
          return;
        }
        const { subscription, master_account: masterAccount } = response;
        this.updateMasterAccount(masterAccount);
        this.updateSubscription(subscription);
        // Billing info will likely be outdated now, so refetch.
        this.fetchBillingInfo();
      })
      .catch(handleError);
  }

  updateMasterAccount(inputMasterAccount) {
    if (isEmptyObjectOrFalsey(inputMasterAccount)) {
      return;
    }

    const { masterAccount, setMasterAccount } = this.props.masterAccount;

    let shouldUpdateMenuBar = false;

    // The settings fields is actually a representation of the Settings record associated with this Master Account.
    // As such it has it’s own created_at and updated_at values
    if (
      shouldUpdateLocalMasterAccountSettings({
        currMasterAccount: masterAccount,
        newMasterAccount: inputMasterAccount,
      })
    ) {
      shouldUpdateMenuBar = true;
      this.loadAppTimeZonesFromSettings(inputMasterAccount);
      this.setMasterAccountSettings(inputMasterAccount);
    }

    if (
      shouldUpdateMasterAccount({
        currMasterAccount: masterAccount,
        newMasterAccount: inputMasterAccount,
      })
    ) {
      // if updated is less that current or if updated is null and there's a current master account, don't update
      shouldUpdateMenuBar = true;
      setMasterAccount(inputMasterAccount);
    }

    if (shouldUpdateMenuBar) {
      Broadcast.publish("REFRESH_MENU_BAR_APP");
    }
  }

  loadAppTimeZonesFromSettings(inputMasterAccount, inputCurrentUser) {
    const { masterAccount } = this.props.masterAccount;
    const { currentUser } = this.props;

    const updatedMasterAccount = inputMasterAccount ?? masterAccount;
    const updatedCurrentUser = inputCurrentUser ?? currentUser;

    const defaultUserTimeZone = getDefaultUserTimeZone({
      masterAccount: updatedMasterAccount,
      user: updatedCurrentUser,
    });

    batch(() => {
      if (defaultUserTimeZone !== this.props.defaultBrowserTimeZone) {
        this.props.setDefaultBrowserTimeZone(defaultUserTimeZone);
        if (isEmptyArray(this.props.temporaryTimeZones)) {
          Broadcast.publish("SELECT_TIME_ZONE", {
            timeZone: defaultUserTimeZone,
            isToggleTimeZone: true,
          });
        }
      }

      const anchorTimeZones = getAnchorTimeZonesInSettings({
        user: updatedCurrentUser,
        masterAccount: updatedMasterAccount,
      });
      if (!isStringArraysEqual(this.props.anchorTimeZones, anchorTimeZones)) {
        this.props.setAnchorTimeZones(anchorTimeZones);
      }
    });
    const {
      setOrderedTimeZones,
      orderedTimeZones,
      lastOrderedTimeZonesUpdatedAt,
    } = this.props.appTimeZone;
    const accountOrderedTimeZones = getUserAccountOrderedTimeZone({
      currentUser: updatedCurrentUser,
      masterAccount: updatedMasterAccount,
    });
    const lastUpdatedAtOrderedTimeZones = getUserAccountUpdatedAtTime({
      currentUser: updatedCurrentUser,
      masterAccount: updatedMasterAccount,
    });
    if (
      !isStringArraysEqual(accountOrderedTimeZones, orderedTimeZones) &&
      shouldUpdateOrderedTimeZonesFromBackend({
        backendUpdatedAt: lastUpdatedAtOrderedTimeZones,
        localUpdatedAt: lastOrderedTimeZonesUpdatedAt,
      })
    ) {
      // so we don't override
      setOrderedTimeZones(accountOrderedTimeZones);
    }
  }

  setMasterAccountSettings(inputMasterAccount) {
    const { currentUser } = this.props;
    const { masterAccount } = this.props.masterAccount;
    const updatedMasterAccount = inputMasterAccount ?? masterAccount;
    if (isEmptyObjectOrFalsey(updatedMasterAccount)) {
      return; // no reason to update if the master account is empty
    }

    clearEventStyleCache();
    batch(() => {
      const updatedWeekStart = getAccountStartOfWeek({
        masterAccount: updatedMasterAccount,
      });
      const updatedDateField = getAccountDateFormat({
        masterAccount: updatedMasterAccount,
        user: currentUser,
      });
      if (
        this.props.dateFieldOrder !== updatedDateField ||
        this.props.weekStart !== updatedWeekStart
      ) {
        this.props.setDateFieldOrder(updatedDateField);
        this.props.setWeekStart(updatedWeekStart);
        Broadcast.publish("SET_WEEK_START_WEEKLY_CALENDAR", updatedWeekStart);
      }

      const updated24HourFormat = getIsAccountIn24HourFormat({
        masterAccount: updatedMasterAccount,
        user: currentUser,
      });
      if (this.props.format24HourTime !== updated24HourFormat) {
        this.props.set24HourFormat(updated24HourFormat);
      }

      const updatedShowDeclinedEvents = showAccountDeclinedEvents({
        masterAccount: updatedMasterAccount,
      });
      if (this.props.showDeclinedEvents !== updatedShowDeclinedEvents) {
        this.props.setShowDeclinedEvents(updatedShowDeclinedEvents);
        setTimeout(() => {
          if (!this._isMounted) {
            return;
          }

          mainCalendarBroadcast.publish(
            MAIN_CALENDAR_BROADCAST_VALUES.INITIALIZE_EVENTS_IN_WEEKLY_CALENDAR,
          );
        }, 0.5 * SECOND_IN_MS);
      }

      const updatedDarkMode = isUserInDarkMode({
        masterAccount: updatedMasterAccount,
      });
      if (shouldMatchOSSetting({ masterAccount: updatedMasterAccount })) {
        Broadcast.publish("SET_COLOR_SCHEME_BASED_ON_OS");
      } else if (this.props.isDarkMode !== updatedDarkMode) {
        if (updatedDarkMode) {
          AppBroadcast.publish("SET_APP_DARK_MODE");
        } else {
          AppBroadcast.publish("SET_APP_LIGHT_MODE");
        }
      }

      // need to show reverse of hide weekend
      const showOnlyWorkWeek = getAccountHideWeekend({
        masterAccount: updatedMasterAccount,
      });
      if (this.props.shouldOnlyShowWorkWeek !== showOnlyWorkWeek) {
        this.props.setWorkWeek(showOnlyWorkWeek);
        mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.REMOUNT_CALENDAR_WITH_DELAY);
      }

      const updatedCalendarView = getAccountCalendarView({
        masterAccount: updatedMasterAccount,
      });
      this.updateCalendarView(updatedCalendarView);
    });
  }

  updateCalendarView(updatedCalendarView) {
    if (!isValidCalendarView(updatedCalendarView)) {
      return;
    }
    if (this.props.selectedCalendarView === updatedCalendarView) {
      return;
    }
    this.props.setSelectedCalendarView(updatedCalendarView);
    Broadcast.publish("RESET_EVENTS", updatedCalendarView);
    mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.REMOUNT_CALENDAR_WITH_DELAY);
  }

  updateDefaultPaymentMethod(defaultPaymentMethod) {
    const { setDefaultPaymentMethod } = this.props.defaultPaymentMethodStore;
    setDefaultPaymentMethod(defaultPaymentMethod);
    storeDefaultPaymentMethod(defaultPaymentMethod);
  }

  updateSubscription(subscription) {
    const { setSubscription } = this.props.subscriptionStore;
    setSubscription(subscription);
  }

  checkForPromotionsNotSeen() {
    const { currentUser } = this.props;

    if (isEmptyObjectOrFalsey(currentUser)) {
      return;
    }

    const lastSetPromotionTime = getLastSetPromotionTime(currentUser);
    if (
      lastSetPromotionTime &&
      differenceInMinutes(new Date(), lastSetPromotionTime) < 1
    ) {
      // avoid calling this right after getting promo code
      return;
    }

    // Retrieve list of unspent promotions (applied_on = nil)
    const url = constructRequestURL("promotions/unseen");
    Fetcher.get(url, {}, true, currentUser.email)
      .then((result) => {
        if (!this._isMounted || !result?.promotions) {
          return;
        }

        const { promotions } = result;
        const { setUnseenPromotions } = this.props.promotionsStore;
        const unseenPromotions = getUnseenPromotions(promotions);
        setUnseenPromotions(unseenPromotions);
        if (unseenPromotions.length > 0) {
          Broadcast.publish("DISPLAY_PROMOTION_MODAL", unseenPromotions);
        }
      })
      .catch(handleError);
  }

  addPromotionCode(promotionCode) {
    if (isEmptyObjectOrFalsey(this.props.currentUser) || !promotionCode) {
      return;
    }

    const path = "promotions/apply";
    const url = constructRequestURL(path);
    const body = { code: promotionCode };
    const payloadData = {
      body: JSON.stringify(body),
    };

    Fetcher.post(url, payloadData, true, getUserEmail(this.props.currentUser))
      .then((result) => {
        if (!this._isMounted || !result?.promotions) {
          return;
        }

        this.checkForPromotionsNotSeen();
      })
      .catch(handleError);
  }

  updateSeenPromotion(promotionCode) {
    if (!promotionCode) {
      return;
    }
    // Tell the backend to update the promotion last_displayed field
    const path = "promotions/last_displayed";
    const url = constructRequestURL(path);
    const body = { code: promotionCode };
    let payloadData = {
      body: JSON.stringify(body),
    };

    Fetcher.patch(url, payloadData, true, getUserEmail(this.props.currentUser))
      .then((result) => {
        if (!this._isMounted || !result?.promotions) {
          return;
        }
        const { promotions } = result;
        // promotions should be array of objects with applied_on timestamp nil
        const { setUnappliedPromotions, setUnseenPromotions } =
          this.props.promotionsStore;
        const unseenPromotions = getUnseenPromotions(promotions);
        const unappliedPromotions = getUnappliedPromotions(promotions);
        setUnappliedPromotions(unappliedPromotions);
        setUnseenPromotions(unseenPromotions);
      })
      .catch(handleError);
  }

  checkForPromotionsThatHaveNotBeenApplied() {
    if (
      isEmptyObjectOrFalsey(this.props.currentUser) ||
      ALL_CANCELLED_STATE.includes(this.masterAccount?.state)
    ) {
      return;
    }

    // Retrieve list of unapplied promotions (applied_on = nil)
    const url = constructRequestURL("promotions/unapplied");
    Fetcher.get(url, {}, true, getUserEmail(this.props.currentUser))
      .then((result) => {
        if (!this._isMounted || !result?.promotions) {
          return;
        }

        const { promotions } = result;
        const { setUnappliedPromotions, setUnseenPromotions } =
          this.props.promotionsStore;
        const unseenPromotions = getUnseenPromotions(promotions);
        const unappliedPromotions = getUnappliedPromotions(promotions);
        setUnseenPromotions(unseenPromotions);
        setUnappliedPromotions(unappliedPromotions);
      })
      .catch(handleError);
  }

  getSubscriptionDetails() {
    this.getUserNeedCard();

    fetcherGet({
      url: constructRequestURL(STRIPE_ENDPOINTS.SUBSCRIPTION_DETAILS),
      email: getUserEmail(this.props.currentUser),
      connectedAccountToken: getUserConnectedAccountToken({ user: this.props.currentUser }),
    })
      .then((response) => {
        if (!this._isMounted || isErrorResponse(response)) {
          return;
        }

        if (isEmptyObjectOrFalsey(response)) {
          // if (initSubscriptionIfFail) {
          // this.initTrialedSubscription(); //! don't add back until this is safe
          // }

          return;
        }

        const { subscription, default_payment_method } = response;
        this.updateSubscription(subscription);

        if (!isEmptyObjectOrFalsey(default_payment_method)) {
          this.updateDefaultPaymentMethod(default_payment_method);
        }
      })
      .catch((error) => {
        handleError(error);
        // sendMessageToSentry(
        //   "Update Stripe Details",
        //   "unable to update subscription and default payment method"
        // );
      });
  }

  saveSlotsSettings(updatedState, selectedUser) {
    if (
      isEmptyObjectOrFalsey(updatedState) ||
      isEmptyObjectOrFalsey(this.props.currentUser)
    ) {
      return;
    }

    const url = constructRequestURL(UPDATE_SLOTS_SETTINGS_ENDPOINT, isVersionV2());
    const payloadData = {
      body: JSON.stringify({ slots_settings: updatedState }),
    };
    const { currentUser } = this.props;

    return fetcherPatch({
      authorizationRequired: true,
      connectedAccountToken: getUserConnectedAccountToken({ user: selectedUser }),
      email: getUserEmail(selectedUser) || getUserEmail(currentUser),
      payloadData,
      url,
    })
      .then((response) => {
        if (
          !this._isMounted ||
          isEmptyObjectOrFalsey(response) ||
          !response.availability_settings
        ) {
          return;
        }

        settingsBroadcast.publish("SHOW_SETTINGS_CONFIRMATION");
        const { availability_settings } = response;
        const { actionMode } = this.props;
        this.saveAvailabilitySettings(availability_settings, selectedUser);
        if (isActionModeCreateAvailability(actionMode)) {
          availabilityBroadcast.publish("REFRESH_ALL_USERS_IN_SELECT");
        }
      })
      .catch(handleError);
  }

  saveAvailabilitySettings(availabilitySettings, selectedUser) {
    const { currentUser, actionMode } = this.props;
    if (
      isEmptyObjectOrFalsey(currentUser) ||
      isEmptyObjectOrFalsey(availabilitySettings)
    ) {
      return;
    }

    const updatedUser = selectedUser ?? currentUser;

    let updatedCurrentUser;
    if (isUserFromMagicLink({ updatedUser })) {
      updatedCurrentUser = {
        ...updatedUser,
        connected_account_details: {
          ...updatedUser.connected_account_details ?? {},
          availability_settings: availabilitySettings,
        },
      };
    } else {
      updatedCurrentUser = {
        ...updatedUser,
        availability_settings: availabilitySettings,
      };
    }

    this.storeUserInformation(updatedCurrentUser);
    if (isActionModeCreateAvailability(actionMode)) {
      availabilityBroadcast.publish(
        "UPDATE_AVAILABILITY_SELECTION",
        updatedCurrentUser,
      );
    }
  }

  sendGroupVoteInvites({ token, attendees, message, link, selectedUser }) {
    const isSpreadsheet = link.includes("/gs/");
    const baseRoute = isSpreadsheet
      ? GROUP_SPREADSHEET_LINKS_ENDPOINT
      : LATEST_GROUP_VOTE_LINKS;
    const path = `${baseRoute}/${token}/email_invite`;
    const url = constructRequestURL(path, isVersionV2());
    const param = {
      [isSpreadsheet ? "group_spreadsheet_link" : "group_vote_link"]: {
        attendees: attendees,
        message,
        url: link,
      },
    };

    const payloadData = { body: JSON.stringify(param) };
    Fetcher.post(
      url,
      payloadData,
      true,
      selectedUser?.email ?? getUserEmail(this.props.currentUser),
    )
      .then((response) => {
        if (!this._isMounted) {
          return;
        }

        if (!response || Object.keys(response).length !== 0) {
          // failed to send
          Broadcast.publish(
            SET_DISAPPEARING_NOTIFICATION_MESSAGE,
            `${pluralize(attendees.length, "Email")} failed to send.`,
          );
        } else {
          // succeeded
          Broadcast.publish(
            SET_DISAPPEARING_NOTIFICATION_MESSAGE,
            `${pluralize(attendees.length, "Email")} sent!`,
          );
        }
      })
      .catch(handleError);
  }

  // update settings like hide, etc
  updateCalendarProperties({ calendarID, updatedProperties = {}, userEmail }) {
    if (isNullOrUndefined(calendarID)) {
      return;
    }

    const path = `calendars/${calendarID}`;
    const url = constructRequestURL(path, isVersionV2());
    const payloadData = {
      body: JSON.stringify({ calendar: updatedProperties }),
    };

    const { allCalendars, setAllCalendars } = this.props.allCalendars;

    return Fetcher.patch(
      url,
      payloadData,
      true,
      userEmail || getUserEmail(this.props.currentUser),
    )
      .then((response) => {
        if (!this._isMounted || !response.calendar || isErrorResponse(response)) {
          return;
        }

        const responseUserCalendarID = getCalendarUserCalendarID(response);

        if (!isValidCalendar(allCalendars[responseUserCalendarID])) {
          return;
        }

        const updatedAllCalendars = produce(allCalendars, (draftState) => {
          draftState[responseUserCalendarID].calendar = response.calendar;
        });

        setAllCalendars(
          updatedAllCalendars,
          this.props.currentUser,
          "setAllCalendars_0",
        );
        Broadcast.publish("REFRESH_MENU_BAR_APP");

        if (
          getActiveCalendarsIDsFromAllCalendars({
            allCalendars,
            currentUserEmail: getUserEmail(this.props.currentUser),
          }).includes(calendarID)
        ) {
          // remove cache and update main calendar colors
          if (isVersionV2() && allCalendars[calendarID]) {
            clearEventStyleCache();
            clearEventStaticCache();
            const matchingCalendar = allCalendars[calendarID];
            backendBroadcasts.publish(
              BACKEND_BROADCAST_VALUES.FETCH_EVENTS_FOR_CALENDAR,
              { calendar: matchingCalendar },
            );
          } else {
            Broadcast.publish("REFORMAT_ALL_EVENTS");
          }
        }
      })
      .catch((error) => {
        handleError(error);
      });
  }

  saveUpdatedTeamPlan(teamPlan) {
    const { teamPlan: oldTeamPlan, setTeamPlan } = this.props.teamPlan;
    if (!_.isEqual(teamPlan, oldTeamPlan)) {
      setTeamPlan(teamPlan || {});
    }
  }

  /**
   * Our backend makes multiple requests to Stripe when this is called. While Stripe's rate
   * limit is not too strict, it could be eaten up quickly if we make this request more than
   * necessary. Calling when first opening the app can be particularly risky since there's a
   * good chance multiple people are all signing in around the same time when the work day
   * starts.
   */
  fetchBillingInfo() {
    const path = STRIPE_ENDPOINTS.BILLING;
    const url = constructRequestURL(path, true);
    if (!getUserEmail(this.props.currentUser) || this._hasStartedLogout) {
      return;
    }

    // This is a heavy request, so prevent multiple simultaneous requests.
    if (this._isFetchingBilling) {
      return;
    }

    this._isFetchingBilling = true;

    return fetcherGet({
      url,
      email: getUserEmail(this.props.currentUser),
      connectedAccountToken: getUserConnectedAccountToken({ user: this.props.currentUser }),
    })
      .then((response) => {
        if (
          !this._isMounted ||
          isEmptyObjectOrFalsey(response) ||
          !response.billing ||
          this._hasStartedLogout
        ) {
          return;
        }

        const {
          team_plan,
          charges,
          payment_methods,
          subscription,
          upcoming_invoice,
          stripe_customer_email,
          annual_upgrade_prorated_invoice,
          annual_upgrade_non_prorated_invoice,
          backend_subscription,
        } = response.billing;

        this.saveUpdatedTeamPlan(team_plan);

        const { charges: oldCharges, setCharges } = this.props.charges;
        if (!_.isEqual(charges, oldCharges)) {
          setCharges(charges || []);
        }

        const {
          stripePaymentMethods: oldPaymentMethods,
          setStripePaymentMethods,
        } = this.props.stripePaymentMethods;
        if (!_.isEqual(payment_methods, oldPaymentMethods)) {
          setStripePaymentMethods(payment_methods || []);
        }

        const { stripeSubscriptions: oldSubscription, setStripeSubscriptions } = this.props.stripeSubscriptions;
        if (!_.isEqual(subscription, oldSubscription)) {
          setStripeSubscriptions(subscription || {});
        }

        const { stripeUpcomingInvoices: oldInvoice, setStripeUpcomingInvoices } = this.props.stripeUpcomingInvoices;
        if (!_.isEqual(upcoming_invoice, oldInvoice)) {
          setStripeUpcomingInvoices(upcoming_invoice || {});
        }

        const { stripeCustomerEmail: oldEmail, setStripeCustomerEmail } = this.props.stripeCustomerEmail;
        if (!_.isEqual(stripe_customer_email, oldEmail)) {
          setStripeCustomerEmail(stripe_customer_email);
        }

        const {
          annualUpgradeProratedInvoice: oldAnnualUpgradeProratedInvoice,
          annualUpgradeNonProratedInvoice: oldAnnualUpgradeNonProratedInvoice,
          setAnnualUpgradeInvoices,
        } = this.props.annualUpgradeInvoicesStore;
        if (
          !_.isEqual(annual_upgrade_prorated_invoice, oldAnnualUpgradeProratedInvoice) ||
          !_.isEqual(annual_upgrade_non_prorated_invoice, oldAnnualUpgradeNonProratedInvoice)
        ) {
          setAnnualUpgradeInvoices({
            annualUpgradeProratedInvoice: annual_upgrade_prorated_invoice,
            annualUpgradeNonProratedInvoice: annual_upgrade_non_prorated_invoice,
          });
        }

        const { backendSubscription: oldBackendSubscription, setBackendSubscription } = this.props.backendSubscriptionStore;
        if (!_.isEqual(backend_subscription, oldBackendSubscription)) {
          setBackendSubscription(backend_subscription);
        }

        const { setBillingHasBeenFetched } = this.props.hasBillingBeenFetched;
        setBillingHasBeenFetched(true);
      })
      .catch(handleError)
      .finally(() => {
        this._isFetchingBilling = false;
      });
  }

  fetchOutlookContacts({ user, nextLink = "", tries = 0 }) {
    if (!this._isMounted || isEmptyObjectOrFalsey(user) || tries > 3) {
      return;
    }

    if (!shouldFetchOutlookContact(user)) {
      return;
    }

    const params = {};
    if (nextLink) {
      params["next_link"] = nextLink;
    }

    const queryParams = constructQueryParams(params);
    const path = "contacts/outlook";
    const url = `${constructRequestURL(path, true)}?${queryParams}`;

    const retryFetch = () => {
      const updatedTries = tries + 1;
      setTimeout(() => {
        if (!this._isMounted) {
          return;
        }
        this.fetchOutlookContacts({ user, nextLink, tries: updatedTries });
      }, SECOND_IN_MS * updatedTries * updatedTries);
    };

    return Fetcher.get(url, {}, true, getUserEmail(user))
      .then((response) => {
        if (!this._isMounted || isEmptyObjectOrFalsey(response)) {
          return;
        }

        if (isErrorResponse(response)) {
          const {
            error,
          } = response;
          if (safeCheckStringIncludes(error, "insufficientPermissions")) {
            Broadcast.publish("OPEN_PERMISSIONS_MODAL", {
              modalContent: "UPDATE_CONTACTS_PERMISSIONS",
              permissionProvider: response.user?.provider,
              permissionEmail:
                getUserEmail(response.user) ?? getUserEmail(user),
            });
            return;
          } else if (safeCheckStringIncludes(response, "failedPrecondition")) {
            this.fetchOutlookContacts({ user, tries: tries + 1 });
            return;
          } else {
            // different error message
            retryFetch();
            return;
          }
        }

        ContactBroadcast.publish("SAVE_CONTACTS_INTO_DB", {
          user,
          contacts: response.contacts,
        });

        if (response.next_link) {
          this.fetchOutlookContacts({ user, nextLink: response.next_link });
        } else {
          saveLastFetchOutlookContactTime(user);
        }
      })
      .catch((error) => {
        handleError(error);
        retryFetch();
      });
  }

  trackWindowSize() {
    const { currentUser } = this.props;

    if (isEmptyObjectOrFalsey(currentUser)) {
      return;
    }

    const getWindowSizeCategory = () => {
      if (!window) {
        return "window_does_not_exist";
      }

      const windowWidth = window.innerWidth;
      if (windowWidth <= 600) {
        return "<=600px";
      } else if (windowWidth <= 800) {
        return "<=800px";
      } else if (windowWidth <= 1000) {
        return "<=1000px";
      } else if (windowWidth <= 1200) {
        return "<=1200px";
      } else if (windowWidth <= 1600) {
        return "<=1600px";
      } else {
        return ">1600px";
      }
    };

    trackEvent({
      category: "App - mount",
      action: getWindowSizeCategory(),
      label: `window_size_${window?.innerWidth}x${window?.innerHeight}`,
      userToken: getUserToken(currentUser),
    });
  }

  fetchDefaultOutlookConferencing() {
    const { currentUser } = this.props;
    if (isEmptyObjectOrFalsey(currentUser) || !isOutlookUser(currentUser)) {
      return;
    }

    const path = "outlook/default_conferencing";
    const url = constructRequestURL(path, isVersionV2());

    return Fetcher.get(url, {}, true, currentUser.email)
      .then((response) => {
        if (
          !this._isMounted ||
          isEmptyObjectOrFalsey(response) ||
          isErrorResponse(response)
        ) {
          return;
        }

        const { default_online_meeting_provider } = response;

        if (
          !default_online_meeting_provider ||
          !isOutlookConferencingOption(default_online_meeting_provider)
        ) {
          // checks if the default conferencing option is valid
          return;
        }

        const updatedCurrentUser = {
          ...currentUser,
          settings: currentUser.settings
            ? {
              ...currentUser.settings,
              default_conferencing_option: default_online_meeting_provider,
            }
            : currentUser.settings,
        };
        if (currentUser.settings) {
          this.storeUserInformation(updatedCurrentUser);
        }
      })
      .catch(handleError);
  }

  fetchOutlookDomainUsers({
    user,
    nextLink = null,
    requestCount = 0,
    shouldFetchDomainUsers,
    tries = 0,
  }) {
    // taken care of in the publish in calendarHomeView
    if (!this._isMounted) {
      return;
    }
    if (tries > 3) {
      return;
    }
    if (!getUserEmail(user)) {
      return;
    }
    if (!shouldFetchDomainUsers) {
      return;
    }

    // MAX REQUEST COUNT
    if (requestCount >= 15) {
      ContactBroadcast.publish("SET_DOMAIN_RESOURCE_TS", user);
    }

    const params = {};
    if (nextLink) {
      params["next_link"] = nextLink;
    }

    const queryParams = constructQueryParams(params);
    const path = "domain_users";
    const url = `${constructRequestURL(path, isVersionV2())}?${queryParams}`;
    const payloadData = {
      headers: getDefaultHeaders(),
    };

    const retryFetch = () => {
      const updatedTries = tries + 1;
      setTimeout(() => {
        if (!this._isMounted) {
          return;
        }
        this.fetchOutlookDomainUsers({
          user,
          nextLink,
          requestCount,
          shouldFetchDomainUsers,
          retryAttempt: updatedTries,
        });
      }, SECOND_IN_MS * updatedTries * updatedTries);
    };

    return Fetcher.get(url, payloadData, true, getUserEmail(user))
      .then((response) => {
        if (!this._isMounted || isEmptyObjectOrFalsey(response)) {
          return;
        }

        if (isErrorResponse(response)) {
          retryFetch();
          return;
        }

        if (response.skip_domain_resources_sync) {
          // Question: how does this get set to false?
          localData(
            LOCAL_DATA_ACTION.SET,
            getShouldSkipDomainResourcesSync(getUserEmail(user)),
            true,
          );
          return;
        }

        const { contacts, next_link: nextLink } = response;

        ContactBroadcast.publish("SAVE_CONTACTS_INTO_DB", { user, contacts });

        if (nextLink) {
          this.fetchOutlookDomainUsers({
            user,
            nextLink,
            requestCount: requestCount++,
          });
        } else {
          ContactBroadcast.publish("SET_DOMAIN_RESOURCE_TS", user);
        }
      })
      .catch((error) => {
        handleError(error);
        retryFetch();
      });
  }

  async cancelSubscription({ message, user }) {
    const path = STRIPE_ENDPOINTS.CANCEL_SUBSCRIPTION;
    const url = constructRequestURL(path);
    const payload = {
      body: JSON.stringify({ message }),
    };
    const { currentUser } = this.props;
    const inputUser = user ?? currentUser;
    trackEvent({
      category: "cancel subscription",
      action: "cancelSubscription_2",
      label: "backendContainer.js",
      userToken: getUserToken(inputUser),
    });

    try {
      const response = await fetcherPatch({
        url,
        payloadData: payload,
        email: getUserEmail(inputUser),
        connectedAccountToken: getUserConnectedAccountToken({ user: inputUser }),
      });
      if (response?.subscriptions[0]?.cancel_at_period_end) {
        Broadcast.publish(
          SET_DISAPPEARING_NOTIFICATION_MESSAGE,
          "Subscripton successfully cancelled",
        );
        // Some of the billing info will be out of date and needs to be refetched.
        this.fetchBillingInfo();
      } else {
        throw new Error(
          "Failed to cancel subscription; please contact support.",
        );
      }
    } catch (e) {
      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        "Failed to cancel subscription",
      );
      sendMessageToSentry(e.message);
    }
  }

  // Need to put this here because we wrap the App.js with router so we can't do it in the same component
  routeOnAuth(masterAccount) {
    /* Assume magic link flow if token is stored */
    /* We remove this if: */
    /* 1) User connected magic link account */
    /* 2) User moved out of /magic-link */
    if (this.props.magicLinkToken) {
      this.props.history.push(`/${MAGIC_LINK_PATH}`);
      return;
    }

    if (isMobile()) {
      this.props.history.push(`/${MOBILE_DOWNLOAD_PATH}`);
      return;
    }
    if (isEmptyObjectOrFalsey(masterAccount)) {
      this.goToHome();
      return;
    }

    if (isSelfServeOpen()) {
      if (isInOnboarding(masterAccount)) {
        this.goToWelcomeFlow();
      } else {
        this.goToHome();
      }
    } else {
      // route based on user type
      // Gated access by states
      // Paying, trial, vip, backstage, onboarding
      //   Access app -> Route to home

      // Waitlist, freemium, prepaid
      //   First page of self-serve onboarding where we schedule onboarding call -> Route to /welcome

      // Remove self onboarding button
      //   After book -> show thank you page

      // If a regular user logs in, reroute them to Regular User Typeform
      //   Thanks for joining waitlist and we look forward to onboarding you

      // Trial_ended, trial_churned, abandoned, cancelled, cancelled_keep_updates, did_not_buy
      //   Show Paywall modal
      //   What we have now -> route to /home and backend will trigger modal (should not have to add additional logic here)

      if (isWaitingToBeOnboarded(masterAccount)) {
        this.goToWelcomeFlow();
        return;
      }

      if (userNeedsTypeForm(masterAccount)) {
        this.openTypeFormLinkAndReroute();
        return;
      }

      if (isAccountReferred(masterAccount)) {
        this.goToWelcomeFlow();
        return;
      }

      this.goToHome();
    }
  }

  openTypeFormLinkAndReroute(inputMasterAccount) {
    const masterAccount =
      inputMasterAccount ?? this.props.masterAccount.masterAccount;
    if (isUserMaestroUser(masterAccount)) {
      if (isSafari()) {
        openLinkOnSamePage(TYPEFORM_LINKS.MAESTRO);
      } else {
        OpenLink(TYPEFORM_LINKS.MAESTRO);
      }
    } else {
      if (isSafari()) {
        openLinkOnSamePage(TYPEFORM_LINKS.NORMAL);
      } else {
        OpenLink(TYPEFORM_LINKS.NORMAL);
      }
    }

    this.props.history.push(`/${TYPEFORM_ROUTE}`);
  }

  goToWelcomeFlow() {
    const { masterAccount } = this.props.masterAccount;
    if (
      isOnHomePage() ||
      isOnAddNewEventPage() ||
      isUserMaestroUser(masterAccount)
    ) {
      // not need to route to onboarding if user is /home or/new
      return;
    }

    if (isOnboardingMode()) {
      return;
    }

    this.props.history.push("/welcome");
  }

  goToNewUserSSOLogin() {
    this.props.history.push(`/${SSO_NEW_USER_LOGIN}`);
  }

  goToHome() {
    if (this.props.location.pathname === "/home") {
      return;
    }

    this.props.history.push("/home");
  }

  async updateUserSmartTags({
    shouldUpdateEventTags = false, // only true in tagsCommandCenter on create new tag
    smartTags,
    user,
  }) {
    if (isEmptyObjectOrFalsey(smartTags)) {
      return;
    }

    const { currentUser } = this.props;
    const path = "users/smart_tags_with_users";
    const url = constructRequestURL(path, true);
    const payloadData = {
      body: JSON.stringify({ smart_tags: smartTags }),
    };

    try {
      const response = await Fetcher.patch(
        url,
        payloadData,
        true,
        getUserEmail(user) ?? getUserEmail(currentUser),
      );
      if (
        !this._isMounted
        || isEmptyObjectOrFalsey(response)
        || isErrorResponse(response)
        || isEmptyArrayOrFalsey(response?.users)
      ) {
        return;
      }
      const {
        users,
      } = response;
      const existingUserEmails = users.map((user) => getUserEmail(user));
      const filteredUsers = users.filter((user) => existingUserEmails.includes(getUserEmail(user)));
      if (isEmptyArrayOrFalsey(filteredUsers)) {
        return;
      }
      this.updateListOfUsers(filteredUsers);

      const matchingUser = filteredUsers.find((inputUser) => {
        return isSameEmail(getUserEmail(inputUser), getUserEmail(user || currentUser));
      });
      const matchingUserSmartTags = getUserSmartTags(matchingUser);
      if (isColorTagSettingsShowing()) {
        tagsBroadcast.publish(UPDATE_PAINT_SETTINGS);
      }

      if (shouldUpdateEventTags && matchingUserSmartTags) {
        const updatedTag = smartTags[smartTags.length - 1];
        const newestTagIndex = matchingUserSmartTags.findIndex((tag) => tag.name === updatedTag.name && tag.color === updatedTag.color && tag.color_id === updatedTag.color_id);
        const newestTag = matchingUserSmartTags[newestTagIndex];
        tagsBroadcast.publish(
          UPDATE_EVENT_TAGS,
          newestTag,
          newestTagIndex,
        );
      }
    } catch (error) {
      handleError(error);
    }
  }

  async deleteUserSmartTag({ userSmartTagId, user }) {
    const { currentUser } = this.props;
    const path = `users/smart_tags_with_users/${userSmartTagId}`;
    const url = constructRequestURL(path, true);

    try {
      const response = await Fetcher.delete(
        url,
        {},
        true,
        getUserEmail(user || currentUser),
      );

      if (
        !this._isMounted ||
        isEmptyObjectOrFalsey(response) ||
        isErrorResponse(response) ||
        isEmptyArrayOrFalsey(response?.users)
      ) {
        return;
      }

      const {
        users,
      } = response;
      this.updateListOfUsers(users);
    } catch (error) {
      handleError(error);
    }
  }

  updatePhoneNumber({ phoneInfo, user }) {
    const path = "phone_number";
    const url = constructRequestURL(path);
    const payloadData = {
      body: JSON.stringify(phoneInfo),
    };
    const { currentUser } = this.props;

    return fetcherPost({
      authorizationRequired: true,
      connectedAccountToken: getUserConnectedAccountToken({ user }),
      email: getUserEmail(user) || getUserEmail(currentUser),
      payloadData,
      url,
    })
      .then((response) => {
        if (
          !this._isMounted ||
          isEmptyObjectOrFalsey(response) ||
          isErrorResponse(response)
        ) {
          return;
        }

        this.storeUserInformation(response.user);
        const { actionMode } = this.props;
        if (isActionModeCreateAvailability(actionMode)) {
          availabilityBroadcast.publish("REFRESH_ALL_USERS_IN_SELECT");
        }

        if (isActionModeUpsertEvent(actionMode)) {
          Broadcast.publish("SET_EVENT_CONFERENCE", {
            value: CONFERENCING_TYPE.PHONE_NUMBER_CONFERENCE,
          });
        } else if (isActionModeCreateAvailability(actionMode)) {
          Broadcast.publish(
            "SET_CREATE_AVAILABILITY_CONFERENCE",
            CONFERENCING_TYPE.PHONE_NUMBER_CONFERENCE,
          );
        }
      })
      .catch((error) => {
        handleError(error);
      });
  }

  getLatestMasterAccount(checkForReroute = false) {
    const url = constructRequestURL(GET_LATEST_MASTER_ACCOUNT_ENDPOINT, true);
    const { currentUser } = this.props;

    return fetcherGet({
      authorizationRequired: true,
      connectedAccountToken: getUserConnectedAccountToken({ user: currentUser }),
      email: getUserEmail(currentUser),
      url,
    })
      .then((response) => {
        if (!this._isMounted || !response?.master_account) {
          return;
        }

        const { master_account } = response;
        this.updateMasterAccount(master_account);

        if (checkForReroute) {
          if (isSelfServeOpen()) {
            // nothing
          } else {
            if (!isEmptyObjectOrFalsey(master_account)) {
              // can't use this.route on auth becuase of auto opening links, etc
              if (userNeedsTypeForm(master_account)) {
                this.props.history.push(`/${TYPEFORM_ROUTE}`);
                return;
              }

              if (isAccountReferred(master_account)) {
                this.goToWelcomeFlow();
                return;
              }

              if (isWaitingToBeOnboarded(master_account)) {
                this.goToWelcomeFlow();
                return;
              }

              this.goToHome();
            }
          }
        }
      })
      .catch(handleError);
  }

  upgradeFromMonthlyToAnnual() {
    const path = STRIPE_ENDPOINTS.UPGRADE_SUBSCRIPTION;
    const url = constructRequestURL(path);
    const { currentUser } = this.props;

    trackEvent({
      category: "upgrade to annual plan",
      action: "onClickUpgradeToAnnualPlan_2",
      label: "backendContainer.js",
      userToken: getUserToken(currentUser),
    });

    return fetcherPost({
      url,
      email: getUserEmail(currentUser),
      connectedAccountToken: getUserConnectedAccountToken({ user: currentUser }),
    })
      .then((response) => {
        if (!response?.subscription) {
          return;
        }

        const { subscription, default_payment_method } = response;

        if (!isEmptyObjectOrFalsey(subscription)) {
          this.updateSubscription(subscription);
          this.fetchBillingInfo();
        }

        if (!isEmptyObjectOrFalsey(default_payment_method)) {
          this.updateDefaultPaymentMethod(default_payment_method);
        }
      })
      .catch(handleError);
  }

  getUserNeedCard() {
    const path = STRIPE_ENDPOINTS.USER_NEEDS_CARD;
    const url = constructRequestURL(path);
    const { currentUser } = this.props;

    const { setNeedCard } = this.props.defaultPaymentMethodStore;

    return fetcherGet({
      url,
      email: getUserEmail(currentUser),
      connectedAccountToken: getUserConnectedAccountToken({ user: currentUser }),
    })
      .then((response) => {
        if (!isNullOrUndefined(response?.user_needs_card)) {
          const { user_needs_card } = response;
          setNeedCard(user_needs_card);
        }
      })
      .catch(handleError);
  }

  sendEmail(data) {
    if (isEmptyObjectOrFalsey(data)) {
      return;
    }

    const { user, userEmail, emailData } = data;
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    const userForRequest = user ?? this.props.currentUser;
    /* If we don't have the Outlook draft_email_id, default to Vimcal mailer */
    const shouldUseNativeOutlook =
      isOutlookUser(userForRequest) && emailData.draft_email_id;
    const path = shouldUseNativeOutlook
      ? "finalize_draft_and_send_outlook_email"
      : "email_attendees";
    const url = constructRequestURL(path, shouldUseNativeOutlook);
    const payloadData = {
      body: JSON.stringify(emailData),
    };

    trackEvent({
      category: "web_tracking",
      action: "sendEmail",
      label: "feature_tracking",
      userToken: getUserToken(this.props.currentUser),
    });
    const emailForBackend = getUserEmail(userForRequest) ?? userEmail;

    Fetcher.post(url, payloadData, true, emailForBackend)
      .then((response) => {
        if (!this._isMounted) {
          return;
        }

        /* Handle missing permissions error */
        if (isErrorResponse(response)) {
          const {
            error,
          } = response;
          handleError(error);

          broadcastOpenPermissionModal({
            error,
            userEmail: getUserEmail(response.user) || emailForBackend,
            provider: response.user?.provider,
            allLoggedInUsers,
          });

          return;
        }

        Broadcast.publish(SET_DISAPPEARING_NOTIFICATION_MESSAGE, "Email sent!");
      })
      .catch((error) => {
        handleError(error);

        if (!this._isMounted) {
          return;
        }

        Broadcast.publish(
          SET_DISAPPEARING_NOTIFICATION_MESSAGE,
          "An error occurred while sending the email!",
        );
      });
  }

  onClickLLMFeedback({ completionID, quality }) {
    if (!completionID) {
      return;
    }
    // quality is 0 or 1  (0 = thumbs down, 1 = thumbs up)
    const path = "vellum/reverse_slots/feedback";
    const url = constructRequestURL(path, true);
    const { currentUser } = this.props;
    const param = {
      completion_id: completionID,
      quality,
    };
    const payloadData = {
      body: JSON.stringify(param),
    };

    return Fetcher.post(url, payloadData, true, currentUser.email)
      .then(() => {})
      .catch(handleError);
  }

  fetchEnrichDomains(domains) {
    if (isEmptyArrayOrFalsey(domains)) {
      return;
    }

    const deduppedDomains = removeDuplicatesFromArray(domains);

    const {
      enrichedCompanies,
      addCompanies,
      companyDomainsPendingBackend,
      addCompanyDomainsPendingBackend,
    } = this.props.enrichedCompanyStore;

    // filter out domains that have already been enriched
    const companyDomainsPendingBackendSet = new Set(companyDomainsPendingBackend);
    const filteredDomains = deduppedDomains.filter(
      (domain) => !enrichedCompanies[domain] && !companyDomainsPendingBackendSet.has(domain),
    );
    if (filteredDomains.length === 0) {
      return;
    }

    const url = getEnrichedCompaniesURL();
    const payloadData = {
      body: JSON.stringify({ domains: filteredDomains }),
    };

    addCompanyDomainsPendingBackend(filteredDomains); // so we don't repeatedly call this endpoint

    return Fetcher.post(
      url,
      payloadData,
      true,
      getUserEmail(this.props.currentUser),
    )
      .then((response) => {
        if (
          !this._isMounted ||
          isEmptyObjectOrFalsey(response) ||
          isErrorResponse(response)
        ) {
          return;
        }
        addCompanies(response);
      })
      .catch((error) => {
        handleError(error);
      });
  }

  fetchAIPermission() {
    this.fetchReverseSlotsPermission();
    this.fetchEnrichedCompaniesPermission();
    // this.fetchFTFModalPermission();
  }

  fetchReverseSlotsPermission() {
    const { reverseSlotsPermission, setReverseSlotspermission } =
      this.props.permissionsStore;

    if (reverseSlotsPermission) {
      return;
    }

    const { currentUser } = this.props;

    if (isEmailAVimcalDomain(currentUser?.email)) {
      setReverseSlotspermission(true);
      return;
    }

    const path = "settings/reverse_slots_permission";
    const url = constructRequestURL(path, true);

    Fetcher.get(url, {}, true, currentUser.email)
      .then((response) => {
        if (!this._isMounted) {
          return;
        }

        if (
          convertTrueFalseStringIntoValue(response?.permission_to_reverse_slot)
        ) {
          setReverseSlotspermission(true);
        }
      })
      .catch(handleError);
  }

  fetchFTFModalPermission() {
    return;
    // const { hasSeenFTFModal, setHasSeenFTFModal } = this.props.permissionsStore;
    // if (hasSeenFTFModal) {
    //   return;
    // }

    // const { currentUser } = this.props;

    // const path = "settings/ftf_modal_permission";
    // const url = constructRequestURL(path, true);

    // Fetcher.get(url, {}, true, currentUser.email)
    //   .then((response) => {
    //     if (!this._isMounted) {
    //       return;
    //     }

    //     if (
    //       convertTrueFalseStringIntoValue(
    //         response?.permission_to_show_ftf_modal,
    //       )
    //     ) {
    //       layoutBroadcast.publish("OPEN_FREE_TIME_FINDER_NOTIFICATION");
    //       setHasSeenFTFModal(true);
    //     }
    //   })
    //   .catch(handleError);
  }

  fetchEnrichedCompaniesPermission() {
    const { enrichCompaniesPermission, setEnrichCompaniesPermission } =
      this.props.permissionsStore;

    if (!enrichCompaniesPermission) {
      setEnrichCompaniesPermission(true);
    }
  }

  handleErrorOnParsingBookingLinkDataForFTF() {
    Broadcast.publish("CLOSE_LAYOUT_MODAL");
    broadcast.publish(
      SET_DISAPPEARING_NOTIFICATION_MESSAGE,
      "We could not get availability for this link",
    );
  }

  async getVimcalLinkData(urlString) {
    // sample vimcal booking links
    // this.getVimcalLinkData("https://book-dogfood.vimcal.com/t/mikey/45-min-3b9ac0");
    // this.getVimcalLinkData("https://book-staging.vimcal.com/t/michaelzhao/25-min-c6bde1") // staging slots
    // "https://book-staging.vimcal.com/p/SUUykGfXrgHxLegV"
    // this.getVimcalLinkData("https://book.vimcal.com/p/SUUykGfXrgHxLegV") // staging personall links
    // this.getVimcalLinkData("https://book-dev.vimcal.com/t/mikey/30-min-05d398");
    // this.getVimcalLinkData("https://book-dogfood.vimcal.com/p/mikey/30minutes"); // personal link
    // this.getVimcalLinkData("https://book-dev.vimcal.com/rs/534869b56f9af62da03b3820"); // reschedule slots
    // this.getVimcalLinkData("https://book-dogfood.vimcal.com/r/bea642425338b735953373e6"); // rescheudle personal links
    // this.getVimcalLinkData("localhost:3000/t/asdkalsjdlf");
    const startTime = new Date(); // to time things
    const vimcalData = getVimcalAvailabilityTypeAndToken(urlString);
    if (!vimcalData) {
      this.handleErrorOnParsingBookingLinkDataForFTF();
      return;
    }

    const { type, userName, slug, token } = vimcalData;
    let bookingProjectData;
    const { masterAccount } = this.props.masterAccount;
    const { currentUser, currentTimeZone } = this.props;

    switch (type) {
      case "t":
        // slots
        // token and slug
        bookingProjectData = await fetchBookingProjectSlotsData({
          userName,
          slug,
          token,
          masterAccount,
          currentUser,
          currentTimeZone,
        });
        break;
      case "p":
        // personal links
        // token and slug
        bookingProjectData = await fetchBookingProjectPersonalLinksData({
          userName,
          slug,
          token,
          masterAccount,
          currentUser,
          currentTimeZone,
        });
        break;
      case "g":
        // group vote
        // token and slug
        break;
      case "r":
        // reschedule personal link with token
        bookingProjectData = await fetchBookingProjectPersonalLinksData({
          userName,
          slug,
          token,
          masterAccount,
          currentUser,
          initialPath: "slots/appointment",
          currentTimeZone,
        });
        break;
      case "rs":
        // reschedule slots with token
        bookingProjectData = await fetchBookingProjectRescheduleSlots({
          userName,
          slug,
          token,
          masterAccount,
          currentUser,
          currentTimeZone,
        });
        break;
      default:
        // slots
        break;
    }

    if (!bookingProjectData) {
      this.handleErrorOnParsingBookingLinkDataForFTF();
      return;
    }

    const { title, duration, description, user, splittedEvents } =
      bookingProjectData;

    if (isAISchedulingModalOpen()) {
      pasteBroadcast.publish("SET_PASTED_POTENTIAL_EVENTS", {
        splittedEvents,
        completionID: null, // no completion_id since we're not using ai here
        rawText: urlString,
        parsedEvents: splittedEvents,
        startTime,
        duration: duration ?? 30,
        senderTimeZone: currentTimeZone,
      });
      const { setBookingLinkState } = this.props.temporaryStateStore;
      setBookingLinkState({
        title,
        description,
        sender_name: getSelectedUserName({ user }).fullName,
        sender_email: user?.email,
      });
    }
  }

  getCalendlyLinkData({ calendlyURL, isTryNextMonth }) {
    // sample calendly links
    // this.getCalendlyLinkData("https://calendly.com/mike-vimcal/30min-1"); // work calendly
    // this.getCalendlyLinkData("https://calendly.com/mchlzhao2/15min?month=2023-05"); // personal calendly
    if (!calendlyURL) {
      return;
    }

    const path = "vellum/get_calendly_link_data";
    const url = constructRequestURL(path, true);
    const { currentUser } = this.props;
    const { masterAccount } = this.props.masterAccount;

    const startTime = new Date();
    const param = {
      url: calendlyURL,
    };
    if (isTryNextMonth) {
      param["range_start"] = formatISO(
        startOfMonth(addMonths(new Date(), 1)),
        ISO_DATE_FORMAT,
      );
      param["range_end"] = formatISO(
        endOfMonth(addMonths(new Date(), 1)),
        ISO_DATE_FORMAT,
      );
    }
    const payloadData = { body: JSON.stringify(param) };

    Fetcher.post(url, payloadData, true, getUserEmail(currentUser))
      .then((response) => {
        if (!this._isMounted) {
          return;
        }

        if (
          isEmptyObjectOrFalsey(response) ||
          isErrorResponse(response) ||
          !response.calendly_response
        ) {
          this.handleErrorOnParsingBookingLinkDataForFTF();
        }
        const { currentTimeZone } = this.props;
        const parsedData = getParsedEventsAndMetaDataFromCalendlyLink({
          response,
          currentTimeZone,
          currentUser,
          masterAccount,
        });
        if (!parsedData) {
          this.handleErrorOnParsingBookingLinkDataForFTF();
          return; // early return
        }

        const { parsedEvents, duration, title, location } = parsedData;

        if (isAISchedulingModalOpen()) {
          pasteBroadcast.publish("SET_PASTED_POTENTIAL_EVENTS", {
            splittedEvents: parsedEvents,
            completionID: null, // no completion_id since we're not using ai here
            rawText: calendlyURL,
            parsedEvents,
            startTime,
            duration,
            isCalendly: true,
            calendlyURL,
            isTryNextMonth,
            // senderTimeZone
          });
          const { setBookingLinkState } = this.props.temporaryStateStore;
          setBookingLinkState({
            title,
            location,
          });
        }
      })
      .catch(handleError);
  }

  async forwardOutlookEvent({
    recipients, // [{email, name}]
    message,
    isRecurring, // if we pass this back inside param, it means this is to forward all events in the series
    event,
  }) {
    mainCalendarBroadcast.publish("REMOVE_PREVIEW_EVENT");
    const showError = () => {
      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        "An error occured forwarding the event",
      );
    };

    const onSuccess = () => {
      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        "Successfully forwarded the event",
      );
    };

    if (!recipients || recipients.length === 0) {
      return;
    }

    const userCalendarID = getEventUserCalendarID(event);
    const providerID = isRecurring
      ? getEventMasterEventID(event)
      : getEventID(event);
    if (!providerID) {
      showError();
      return;
    }

    Broadcast.publish(
      SET_DISAPPEARING_NOTIFICATION_MESSAGE,
      "Forwarding event...",
    );

    const path = `events/forward/${providerID}`;
    const url = constructRequestURL(path, true);

    const param = {
      recipients,
      user_calendar_id: userCalendarID,
    };

    if (message?.trim()) {
      param["comment"] = message.trim();
    }

    const payloadData = { body: JSON.stringify(param) };
    const userEmail = event.userEmail ?? getUserEmail(this.props.currentUser);

    try {
      const response = await Fetcher.post(url, payloadData, true, userEmail);
      if (!this._isMounted) {
        return;
      }

      if (isEmptyObjectOrFalsey(response) || isErrorResponse(response)) {
        showError();
        return;
      }

      const { currentTimeZone, currentUser } = this.props;
      const { allCalendars } = this.props.allCalendars;

      const { event: updatedEvent } = response;

      // single event
      if (updatedEvent) {
        onSuccess();
        const formattedEvent = formatEventForReactBigCalendar({
          event: updatedEvent,
          currentTimeZone,
          calendarId: getEventUserCalendarID(updatedEvent),
        });
        await addEventsIntoIndexDB({
          userEmail: event?.userEmail, // the input event
          events: [formattedEvent],
        });
        if (
          getActiveCalendarsIDsFromAllCalendars({
            allCalendars,
            currentUserEmail: currentUser.email,
          }).includes(userCalendarID)
        ) {
          const matchingCalendar = getCalendarFromUserCalendarID({
            userCalendarID,
            allCalendars,
          });

          mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.UPDATE_EVENT_INTO_WEEKLY_CALENDAR, {
            event: formattedEvent,
            calendar: matchingCalendar,
            removeTemporaryEvent: false,
            timeZone: currentTimeZone,
          });
        }
      }
    } catch (error) {
      handleError(error);
    }
  }

  async logOutOfSingleAccount({ user, e }) {
    // remove from all logged in accounts
    // refresh menu bar
    // remove calendars from all calendars
    if (!getUserEmail(user)) {
      return;
    }

    hasStopEventPropagation(e);
    hasEventPreventDefault(e);

    /* TODO: Use main_master_account_id when refactored */
    const { masterAccount } = this.props.masterAccount;
    const payloadData = {
      main_master_account_id: masterAccount.id,
    };

    const backendAccountLogout = async (updatedCurrentUser) => {
      const logoutURL = constructRequestURL("logout_single_account", true); // TODO: user new endpoint that returns list of user

      await Fetcher.post(
        logoutURL,
        { body: JSON.stringify(payloadData) },
        !isEmptyObjectOrFalsey(user),
        getUserEmail(user),
      );
      this.getAllUsers(updatedCurrentUser);
    };

    const { allCalendars, setAllCalendars } = this.props.allCalendars;

    const { allLoggedInUsers, setAllLoggedInUsers } =
      this.props.allLoggedInUsers;
    const { currentUser } = this.props;

    const filteredUsers = allLoggedInUsers.filter(
      (u) => !equalAfterTrimAndLowerCased(getUserEmail(u), getUserEmail(user)),
    );

    if (filteredUsers.length === 0) {
      // no logged in users -> log out
      appBroadcast.publish("CLICK_LOG_OUT");
      return;
    }

    setAllLoggedInUsers(filteredUsers);

    let filteredCalendars = {};
    Object.keys(allCalendars).forEach((k) => {
      if (
        !isSameEmail(
          getCalendarUserEmail(allCalendars[k]),
          getUserEmail(user),
        )
      ) {
        filteredCalendars[k] = allCalendars[k];
      }
    });
    deleteCalendarSelectedOverridePerUser(user);

    try {
      await db.singleAccountLogout(getUserEmail(user));
      // refetch events inside main calendar
      if (
        isSameEmail(
          getUserEmail(currentUser),
          getUserEmail(user),
        )
      ) {
        // set next all logged in users as current user
        const updatedCurrentUser = filteredUsers[0];
        if (!updatedCurrentUser) {
          return;
        }

        Broadcast.publish("SWITCH_ACCOUNTS", { account: updatedCurrentUser });
        backendAccountLogout(updatedCurrentUser);
        return;
      }

      setAllCalendars(filteredCalendars, currentUser, "setAllCalendars_1");
      Broadcast.publish("SWITCH_ACCOUNTS", { account: currentUser });
    } catch (error) {
      handleError(error);
    }

    backendAccountLogout();
  }

  deleteOutlookCalendar(calendar) {
    const onError = () => {
      if (!this._isMounted) {
        return;
      }
      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        "Failed to delete calendar",
      );
    };
    const { removeCalendar, allCalendars } = this.props.allCalendars;
    const userCalendarID = getCalendarUserCalendarID(calendar);
    const shouldResetMainCalendar = getActiveCalendarsIDsFromAllCalendars({
      allCalendars,
      currentUserEmail: getUserEmail(this.props.currentUser),
    }).includes(userCalendarID);

    if (!userCalendarID) {
      onError();
      return;
    }

    const path = `calendars/${userCalendarID}`;
    const url = constructRequestURL(path, true);

    return Fetcher.delete(
      url,
      {},
      true,
      getCalendarUserEmail(calendar) ?? getUserEmail(this.props.currentUser),
    )
      .then((response) => {
        // if response is {}, then it's successful
        if (!this._isMounted) {
          return;
        }

        if (isErrorResponse(response)) {
          onError();
          return;
        }

        removeCalendar(userCalendarID, this.props.currentUser);
        const { allCalendars } = this.props.allCalendars;

        Broadcast.publish(
          SET_DISAPPEARING_NOTIFICATION_MESSAGE,
          "Successfully deleted calendar",
        );
        Broadcast.publish("REFORMAT_AGENDA_INDEX_WITH_NEXT_EVENT");
        Broadcast.publish("REFRESH_MENU_BAR_APP");
        if (shouldResetMainCalendar) {
          // if calendar is currently showing
          mainCalendarBroadcast.publish(
            MAIN_CALENDAR_BROADCAST_VALUES.INITIALIZE_EVENTS_IN_WEEKLY_CALENDAR,
            allCalendars,
          );
        }
      })
      .catch((err) => {
        handleError(err);
        onError();
      });
  }

  sendFeedback(param) {
    const path = "feedback";
    const url = constructRequestURL(path);

    const { feedbackData, type } = param;
    const payloadData = {
      body: JSON.stringify(feedbackData),
    };

    const getToast = () => {
      switch (type) {
        case FEEDBACK_TYPE.FEEDBACK:
          return "Thank you for submitting your feedback!";
        case FEEDBACK_TYPE.BUG_REPORT:
          return "Thank you for submitting your bug report!";
        case FEEDBACK_TYPE.FEATURE_REQUEST:
          return "Thank you for submitting your feature request!";
        case FEEDBACK_TYPE.QUESTION:
          return "Thank you for submitting your question!";
        default:
          return "Thank you for submitting your feedback!";
      }
    };

    return Fetcher.post(
      url,
      payloadData,
      true,
      getUserEmail(this.props.currentUser),
    )
      .then(() => {
        if (!this._isMounted) {
          return;
        }

        Broadcast.publish(SET_DISAPPEARING_NOTIFICATION_MESSAGE, getToast());
      })
      .catch((err) => {
        handleError(err);
      });
  }

  async checkForMetrics() {
    const { currentUser } = this.props;
    if (isEmptyObjectOrFalsey(currentUser)) {
      return;
    }
    const {
      setMetricsData,
      setLatestSuccessfullyFetchedWeek,
      latestSuccessfullyFetchedWeek,
      metricsData,
      setLast12Weeks,
      lastFetchHistoricalMetricsTime,
      setLastFetchHistoricalMetricsTime,
    } = this.props.metricsStore;

    const getLast12Weeks = async () => {
      try {
        const hasInternet = await hasInternetConnection();
        if (!hasInternet) {
          // so we don't cache anything if there's no internet
          return;
        }
        this._isFetchingLast12WeeksMetrics = true;
        const reports = await bulkFetchMetricsReports({
          period: METRICS_PERIOD.WEEKLY,
          currentUser,
        });
        this._isFetchingLast12WeeksMetrics = false;
        const updatedTime = (new Date()).toISOString();
        setLastFetchHistoricalMetricsTime(updatedTime); // always save the last fetch time.
        if (isEmptyArrayOrFalsey(reports)) {
          return;
        }
        // the data from the bulk fetch endpoint returns data in a different format
        if (Array.isArray(reports) && reports?.every(report => isMetricsReady(report))) {
          setLast12Weeks(reports);
        }
      } catch (error) {
        handleError(error);
      }
    };

    if (this._isFetchingLast12WeeksMetrics) {
      // do nothing -> already fetching
    } else {
      const parsedLastFetchTime = guardedParseISO(lastFetchHistoricalMetricsTime);
      if (!parsedLastFetchTime) {
        await getLast12Weeks();
      } else if (!isSameWeekMonday(parsedLastFetchTime, new Date()) // check based on monday
      ) {
        // if different week
        setLast12Weeks([]); // reset the cache here so it's empty array. This will cause metricsLineGraph.js to refetch the data
        await getLast12Weeks();
      }
    }

    if (!isMonday(new Date())) {
      // only show modal on monday
      return;
    }

    if (isUserDelegatedUser(currentUser)) {
      // no need to cache for delegated user
      return;
    }

    const { masterAccount } = this.props.masterAccount;

    // always fetch latest metrics
    const response = await getMetricsResponse({
      currentUser,
      masterAccount,
    });
    const fetchedWeekNumber = getMetricsWeekNumber(response);
    if (!isMetricsReady(response)) {
      return;
    }
    if (
      !isNullOrUndefined(fetchedWeekNumber) &&
      latestSuccessfullyFetchedWeek === fetchedWeekNumber &&
      getMetricsDataID(metricsData) === getMetricsDataID(response) // if id changes -> show again
    ) {
      return;
    }
    setMetricsData(response);
    const { isTutorialWizardMinimized, isTutorialWizardShowing } =
      this.props.tutorialWizard;
    if (isModalOpen()) {
      return;
    }
    if (isTutorialWizardShowing && !isTutorialWizardMinimized) {
      // do not show if there's modal
      // do not show if the tutoriali wizard is showing and not minimized
      return;
    }

    if (isUserHideMetricsNotifications(masterAccount)) {
      // user doesn't want to see modal
      return;
    }

    modalBroadcast.publish(
      "SET_BOTTOM_MODAL_CONTENT",
      MODAL_TYPES.METRICS_IS_AVAILABLE_MODAL,
    );
    setLatestSuccessfullyFetchedWeek(fetchedWeekNumber);
  }

  upsertSpecialTags({
    specialTags, // array of special tags
    user,
  }) {
    const { currentUser } = this.props;
    const path = "users/special_smart_tags_with_users";
    const url = constructRequestURL(path, true);
    const param = {
      special_tags: specialTags,
    };

    const payloadData = {
      body: JSON.stringify(param),
    };

    let inputUser;
    if (isUserDelegatedUser(user)) {
      inputUser = user;
    } else {
      inputUser = currentUser;
    }

    return Fetcher.post(
      url,
      payloadData,
      true,
      getObjectEmail(inputUser) || getObjectEmail(currentUser),
    )
      .then((response) => {
        if (!this._isMounted) {
          return;
        }

        if (isErrorResponse(response) || isEmptyArrayOrFalsey(response?.users)) {
          settingsBroadcast.publish(
            "SET_INTERNAL_EXTERNAL_WARNING",
            "An error occurred while saving your tags.",
          );
          return;
        }
        const {
          users,
        } = response;
        this.updateListOfUsers(users);

        settingsBroadcast.publish("CLOSE_INTERNAL_EXTERNAL_TAGS");
        settingsBroadcast.publish(SETTINGS_BROADCAST_VALUES.CLOSE_SOLO_TAGS);
        settingsBroadcast.publish("SHOW_SETTINGS_CONFIRMATION");
        backendBroadcasts.publish("FETCH_EVENTS_FOR_CALENDAR_WITHIN_WINDOW");
        backendBroadcasts.publish("INITIAL_USER_SYNC");
      })
      .catch((err) => {
        handleError(err);
      });
  }

  async connectedUserSync() {
    const { allLoggedInUsers, setAllLoggedInUsers } = this.props.allLoggedInUsers;
    const { allUserDomains, setAllUserDomains } = this.props.allUserDomains;
    const { masterAccount } = this.props.masterAccount;

    const allLoggedInUserTokens = allLoggedInUsers.map((user) =>
      getUserToken(user),
    );
    const url = constructRequestURL("initial_connected_users_sync", true);
    const payloadData = {
      body: JSON.stringify({
        main_master_account_id: masterAccount.id,
        user_tokens: allLoggedInUserTokens,
      }),
    };

    const response = await Fetcher.post(
      url,
      payloadData,
      true,
      getUserEmail(this.props.currentUser),
    );

    if (
      !this._isMounted ||
      isEmptyObjectOrFalsey(response) ||
      isErrorResponse(response)
    ) {
      return;
    }

    const {
      connected_account_tokens,
      connected_users,
      connected_user_domains,
    } = response;

    /* Fallback for connected account tokens getting reset */
    if (!isEmptyObjectOrFalsey(connected_account_tokens)) {
      this.props.setConnectedAccountTokens(connected_account_tokens);
    }

    if (isEmptyArrayOrFalsey(connected_users)) {
      return;
    }

    const updatedAllLoggedInUsers = updateAllLoggedInUsers(allLoggedInUsers, connected_users);
    setAllLoggedInUsers(updatedAllLoggedInUsers);

    if (!isEmptyObjectOrFalsey(connected_user_domains)) {
      const updatedAllUserDomains = produce(allUserDomains, (draftState) => {
        if (!isEmptyObjectOrFalsey(connected_user_domains)) {
          // Backend returns an object of email: domain where email is the key and domain is the value
          Object.keys(connected_user_domains).forEach((emailKey) => {
            draftState[emailKey] = connected_user_domains[emailKey];
          });
        }
      });

      setAllUserDomains(updatedAllUserDomains);
    }

    backendBroadcasts.publish("INITIAL_USER_SYNC");
    broadcast.publish("SYNC_ALL_LOGGED_IN_CALENDARS_AND_EVENTS");
  }

  async updatePersonalLinkTrips({ trips, user, isUpdatingExecutiveProfile }) {
    if (!trips) {
      return;
    }
    const { currentUser } = this.props;
    const { masterAccount } = this.props.masterAccount;

    const selectedUser = user ?? currentUser;

    if (isEmptyObjectOrFalsey(selectedUser)) {
      return;
    }

    updateMasterAccountSettingsForFrontendAndBackend({
      masterAccount,
      updatedSettings: { trips },
      user: selectedUser,
      isUpdatingExecutiveProfile,
    });
  }

  updateRecentlySearchedContacts(updatedContacts) {
    if (isLocal()) {
      // so we don't update backend if testing locally
      return;
    }
    if (!updatedContacts || !Array.isArray(updatedContacts)) {
      return;
    }
    const filteredContacts = filterOutInvalidContacts(updatedContacts);
    if (isEmptyArray(filteredContacts)) {
      return;
    }
    const path = "recent_meet_with_contacts";
    const url = constructRequestURL(path);
    const payloadData = {
      body: JSON.stringify({ recent_meet_with_contacts: filteredContacts }),
    };
    const { currentUser } = this.props;

    fetcherPost({
      url,
      payloadData,
      email: getUserEmail(currentUser),
      connectedAccountToken: getUserConnectedAccountToken({ user: currentUser }),
    })
      .then((response) => {
        if (
          !this._isMounted ||
          isEmptyArray(response?.user?.recent_meet_with_contacts)
        ) {
          return;
        }

        this.storeUserInformation(response.user);
      })
      .catch((error) => {
        handleError(error);
      });
  }

  async updateRecentContacts(emailList, userEmail) {
    if (isEmptyArrayOrFalsey(emailList)) {
      return;
    }

    const { currentUser } = this.props;
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const { masterAccount } = this.props.masterAccount;
    const selectedUser =
      getMatchingUserFromAllUsers({
        allUsers: allLoggedInUsers,
        userEmail,
      }) ?? currentUser;

    if (isEmptyObjectOrFalsey(selectedUser)) {
      return;
    }

    const { contactResponse, domainResponse } = await getDomainAndContacts({
      allLoggedInUsers,
      masterAccount,
      currentUser: selectedUser,
      userEmail: getUserEmail(selectedUser),
      searchArray: emailList,
    });

    const isMatchingContact = (contact, email) => {
      if (!getObjectEmail(contact)) {
        return;
      }
      return (
        isSameEmail(getObjectEmail(contact), email) && !!contact?.name
      ); // prioritize contact with name
    };

    const getMatchingContactForEachEmail = () => {
      let matchingContacts = [];
      emailList.forEach((email) => {
        const matchingDomainResponse = domainResponse.find((contact) =>
          isMatchingContact(contact, email),
        );
        const matchingContactResponse = contactResponse.find((contact) =>
          isMatchingContact(contact, email),
        );
        if (matchingDomainResponse) {
          matchingContacts = matchingContacts.concat({
            fullName: matchingDomainResponse.name,
            email: matchingDomainResponse.email,
            updated: new Date().toISOString(),
          });
        } else if (matchingContactResponse) {
          matchingContacts = matchingContacts.concat({
            fullName: matchingContactResponse.name,
            email: matchingContactResponse.email,
            updated: new Date().toISOString(),
          });
        } else {
          // create contact
          matchingContacts = matchingContacts.concat({
            email,
            fullName: "",
            updated: new Date().toISOString(),
          });
        }
      });
      return matchingContacts;
    };

    const existingRecentContacts = getRecentContacts(selectedUser).filter(
      (contact) => !emailList.includes(contact?.email),
    );
    const updatedContacts = getMatchingContactForEachEmail()
      .concat(existingRecentContacts)
      .slice(0, 20); // Only get first 20 contacts

    const path = "recent_contacts";
    const url = constructRequestURL(path);
    const payloadData = {
      body: JSON.stringify({ recent_contacts: updatedContacts }),
    };

    fetcherPost({
      url,
      payloadData,
      email: getUserEmail(selectedUser),
      connectedAccountToken: getUserConnectedAccountToken({ user: selectedUser }),
    })
      .then((response) => {
        if (!this._isMounted || !response?.user?.recent_contacts) {
          return;
        }

        this.storeUserInformation(response.user);
      })
      .catch((error) => {
        handleError(error);
      });
  }

  updateSocialLinks({ socialLinks, user, isUpdatingExecutiveProfile }) {
    const userEmail = getUserEmail(user);
    const url = constructRequestURL("settings/social_links", true);
    const param = {
      social_links: socialLinks,
      update_executive_profile: isUpdatingExecutiveProfile,
      delegated_user_email: isUpdatingExecutiveProfile ? userEmail : undefined,
    };
    const payloadData = {
      body: JSON.stringify(param),
    };
    Fetcher.patch(
      url,
      payloadData,
      true,
      userEmail ?? getUserEmail(this.props.currentUser),
    )
      .then((response) => {
        if (!this._isMounted || isEmptyObjectOrFalsey(response)) {
          return;
        }

        const { user: updatedUser, master_account } = response;

        if (!isEmptyObjectOrFalsey(updatedUser)) {
          // for delegate user
          this.storeUserInformation(updatedUser);
        } else if (!isEmptyObjectOrFalsey(master_account)) {
          if (isUserFromMagicLink({ user })) {
            const updatedUser = {
              ...user,
              social_links: master_account.social_links,
            };

            backendBroadcasts.publish(
              BACKEND_BROADCAST_VALUES.STORE_USER_INFORMATION,
              updatedUser,
            );

            return;
          }

          // for normal users
          this.updateMasterAccount(master_account);
        }
        appBroadcast.publish(APP_BROADCAST_VALUES.REFETCH_ENRICHED_CONTACTS);
        settingsBroadcast.publish(
          BACKEND_BROADCAST_VALUES.SHOW_SETTINGS_CONFIRMATION,
        );
      })
      .catch(handleError);
  }

  async syncOutlookCategories() {
    const { allLoggedInUsers } = this.props.allLoggedInUsers;
    const { currentUser } = this.props;
    const { allCalendars } = this.props.allCalendars;
    const { masterAccount } = this.props.masterAccount;
    const { clearOutlookCategories, setOutlookCategories } =
      this.props.outlookCategoriesStore;

    // Check any logged in user, not just the current user.
    if (!allLoggedInUsers.some(isSyncingCategories)) {
      clearOutlookCategories();
      return;
    }

    const { outlookUsers } = getOutlookAndGoogleUsers({
      allLoggedInUsers,
      currentUser,
      allCalendars,
      masterAccount,
    });
    const usersToFetchCategoriesFor = outlookUsers
      .filter((u) => !isUserLimitedAccess(u));
    if (isEmptyArrayOrFalsey(usersToFetchCategoriesFor)) {
      return;
    }
    const promises = usersToFetchCategoriesFor
      .map(fetchOutlookCategories);

    const outlookCategories = await Promise.all(promises);

    if (!this._isMounted) {
      return;
    }

    setOutlookCategories(...outlookCategories);
  }

  async getAllUsers(updatedCurrentUser) {
    try {
      const fetchURL = constructRequestURL("users/smart_tags", true);
      const response = await Fetcher.get(
        fetchURL,
        {},
        true,
        getUserEmail(updatedCurrentUser || this.props.currentUser),
      );
      if (
        !this._isMounted ||
        isEmptyObjectOrFalsey(response) ||
        isErrorResponse(response) ||
        isEmptyArrayOrFalsey(response?.users)
      ) {
        return;
      }
      const {
        users,
      } = response;
      this.updateListOfUsers(users);
    } catch (error) {
      handleError(error);
    }
  }

  updateListOfUsers(users) {
    if (isEmptyArrayOrFalsey(users)) {
      return;
    }
    const {
      allLoggedInUsers,
    } = this.props.allLoggedInUsers;
    const existingUserEmails = allLoggedInUsers.map((user) => getUserEmail(user));
    const filteredUsers = users.filter((user) => existingUserEmails.includes(getUserEmail(user)));
    if (isEmptyArrayOrFalsey(filteredUsers)) {
      return;
    }
    filteredUsers.forEach((user) => {
      this.storeUserInformation(user);
    });
  }

  // update settings like hide, etc
  fetchEventAttachments({ calendarProviderID, eventProviderID, userEmail }) {
    if (
      isNullOrUndefined(calendarProviderID) ||
      isNullOrUndefined(eventProviderID)
    ) {
      return;
    }

    const path = `calendars/${calendarProviderID}/events/${eventProviderID}/fetch_attachments`;
    const url = constructRequestURL(path, isVersionV2());

    return Fetcher.get(
      url,
      {},
      true,
      userEmail || getUserEmail(this.props.currentUser),
    )
      .then((response) => {
        if (
          !response ||
          !this._isMounted ||
          isEmptyArray(response?.attachments)
        ) {
          return;
        }
        if (isErrorResponse(response)) {
          trackError({
            category: "attachment error",
            errorMessage: JSON.stringify(isErrorResponse(response)),
            userToken: getUserToken(this.props.currentUser),
          });
          return;
        }
        const { attachments } = response;

        Broadcast.publish("UPDATE_EVENT_ATTACHMENTS", {
          attachments,
          eventProviderID,
        });
      })
      .catch((error) => {
        handleError(error);
      });
  }

  async getDistroList(user) {
    const userEmail =
      getUserEmail(user);
    if (!userEmail) {
      return;
    }
    if (!hasPermissionToViewDistroList(user)) {
      return;
    }
    try {
      const fetchDistroLists = isOutlookUser(user)
        ? fetchOutlookDistributionLists
        : fetchGoogleDistributionLists;
      const distroLists = await fetchDistroLists(user);

      // We want empty arrays to still call updateDistroLists, otherwise users may have
      // cached distro lists in the app that don't actually exist.
      if (!this._isMounted || !distroLists || !Array.isArray(distroLists)) {
        return;
      }

      updateDistroLists({ distroLists, userEmail });
    } catch(error) {
      handleError(error);
    }
  }

  createMimicHoldEvents({ calendar, holdEvents }) {
    if (isEmptyObjectOrFalsey(calendar) || isEmptyObjectOrFalsey(holdEvents)) {
      return;
    }

    const { mimicEventsList, setMimicEventsList, temporaryEvents } = this.props;
    const holdId = getFrontendHoldEventsHoldsID(holdEvents);
    const mimicEventTitle = holdEvents?.events?.[0]?.title || "";

    const determineTimeoutDuration = () => {
      const holdEventsCount = temporaryEvents.length;

      /* 5 - 10 events */
      if (holdEventsCount <= 10) {
        return 30;
      }

      /* 11 - 20 events */
      if (holdEventsCount <= 20) {
        return 45;
      }

      /* 21 or more events */
      return 60;
    };

    const mimicEvents = temporaryEvents.map((temporaryEvent, index) =>
      createMimicEventFromEvent({
        event: {
          eventEnd: temporaryEvent.eventEnd,
          eventStart: temporaryEvent.eventStart,
          hold_details: {
            vholds_id: holdId,
          },
          rbcEventEnd: temporaryEvent.rbcEventEnd,
          summaryUpdatedWithVisibility: mimicEventTitle,
          title: mimicEventTitle,
          user_calendar_id: getCalendarUserCalendarID(calendar),
          user_event_id: `${holdId}-mimic-${index}`,
        },
      }),
    ) || [];

    setMimicEventsList([...addDefaultToArray(mimicEventsList), ...mimicEvents]);
    setTimeout(() => {
      if (!this._isMounted) {
        return;
      }

      this.removeMimicHoldEvents([holdId]);
      fetchBroadcast.publish(FETCH_BROADCAST_VALUES.FETCH_EVENTS_FOR_CURRENT_WINDOW);
    }, determineTimeoutDuration() * SECOND_IN_MS);
  }

  removeMimicHoldEvents(eventHoldIds) {
    if (!this._isMounted) {
      return;
    }

    const { mimicEventsList } = this.props;

    if (isEmptyArrayOrFalsey(eventHoldIds) || isEmptyArrayOrFalsey(mimicEventsList)) {
      return;
    }

    const updatedList = mimicEventsList.filter((e) => !eventHoldIds.includes(getEventHoldID(e)));

    if (updatedList.length === mimicEventsList.length) {
      return;
    }

    this.props.setMimicEventsList(updatedList);
  }

  async createHoldsFromLinkable({
    calendar,
    holdEvents,
    linkableToken,
    linkableType,
    user,
  }) {
    const url = constructRequestURLV2(HOLDS_ENDPOINT.CREATE_HOLD_EVENTS);
    const body = {
      hold_events: holdEvents,
      linkable_token: linkableToken,
      linkable_type: linkableType,
    };

    const payloadData = {
      headers: getDefaultHeaders(),
      body: JSON.stringify(body),
    };

    const onError = (error) => {
      handleError(error);

      if (!this._isMounted) {
        return;
      }

      trackEvent({
        category: `create-${linkableType}-hold`,
        action: "error_create_hold_catch",
        label: `create-${linkableType}-hold`,
        userToken: getUserToken(user),
      });

      this.removeMimicHoldEvents([getFrontendHoldEventsHoldsID(holdEvents)]);

      Broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        "Failed to create hold event(s).",
      );
    };

    this.createMimicHoldEvents({ calendar, holdEvents });

    const response = await fetcherPost({
      email: getUserEmail(user),
      onError,
      payloadData,
      url,
    });

    if (!this._isMounted) {
      return;
    }

    if (!isEmptyArrayOrFalsey(response?.hold_events)) {
      /* Get the hold id from the first response event and use it to remove mimic events */
      const eventHoldIds = response?.hold_events?.map(event => getEventHoldID(event));
      this.removeMimicHoldEvents(eventHoldIds);

      Broadcast.publish(
        BROADCAST_VALUES.PARSE_MULTIPLE_EVENTS_RESPONSE,
        {
          calendar: getCalendarObject(calendar),
          events: response.hold_events,
        },
        "createSlotHolds",
      );
    }
  }

  clearCompanyDomainsPendingBackend() {
    try {
      const { clearCompanyDomainsPendingBackend } = this.props.enrichedCompanyStore;
      clearCompanyDomainsPendingBackend();
    } catch (error) {
      handleError(error);
    }
  }

  // toggle is on type of HIDDEN_EVENT_TOGGLE
  async hideEvent({event, toggle}) {
    try {
      const userEventID = getEventUserEventID(event);
      const path = `events/${userEventID}/toggle_hidden`;
      const url = constructRequestURLV2(path);
      const userEmail = getEventUserEmail(event);
      const {
        currentUser,
      } = this.props;

      const payloadData = {
        body: JSON.stringify({ event, is_hidden: toggle === HIDDEN_EVENT_TOGGLE.ON ? true : false }),
      };
      const response = await Fetcher.patch(
        url,
        payloadData,
        true,
        userEmail || getUserEmail(currentUser),
      );
      if (isEmptyObjectOrFalsey(response)) {
        // successfully hid event
        return;
      }
      if (isErrorResponse(response)) {
        // put event back 
        toggleEventHiddenEventPropertyOnBackendFailed(event);
        Broadcast.publish(
          SET_DISAPPEARING_NOTIFICATION_MESSAGE,
          "Failed to save hidden event.",
        );
        return;
      }
    } catch (error) {
      handleError(error);
    }
  }
}

function mapStateToProps(state) {
  let {
    currentTimeZone,
    defaultBrowserTimeZone,
    recentlySearchedTimeZones,
    currentUser,
    format24HourTime,
    weekStart,
    showDeclinedEvents,
    dateFieldOrder,
    isDarkMode,
    shouldOnlyShowWorkWeek,
    selectedCalendarView,
    anchorTimeZones,
    currentTimeZoneLabel,
    actionMode,
    temporaryEvents,
    mimicEventsList,
  } = state;

  return {
    currentTimeZone,
    defaultBrowserTimeZone,
    recentlySearchedTimeZones,
    currentUser,
    format24HourTime,
    weekStart,
    showDeclinedEvents,
    dateFieldOrder,
    isDarkMode,
    shouldOnlyShowWorkWeek,
    selectedCalendarView,
    anchorTimeZones,
    currentTimeZoneLabel,
    actionMode,
    temporaryEvents,
    mimicEventsList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    storeUserData: (user) => dispatch({ data: user, type: "STORE_USER_DATA" }),
    setRecentlySearchedTimeZones: (array) =>
      dispatch({ data: array, type: "SET_RECENTLY_SEARCHED_TIME_ZONES" }),
    userLogout: () => dispatch({ type: "USER_LOGOUT" }),
    set24HourFormat: (data) =>
      dispatch({ data: data, type: "SET_FORMAT_24_HOUR_TIME" }),
    setWeekStart: (data) => dispatch({ data: data, type: "SET_WEEK_START" }),
    setShowDeclinedEvents: (data) =>
      dispatch({ data: data, type: "SET_SHOW_DECLINED_EVENTS" }),
    setDateFieldOrder: (data) =>
      dispatch({ data: data, type: "SET_DATE_FIELD_ORDER" }),
    setWorkWeek: (data) =>
      dispatch({ data: data, type: "SET_ONLY_SHOW_WORK_WEEK" }),
    setSelectedCalendarView: (data) =>
      dispatch({ data: data, type: "SET_CUSTOM_NUMBER_OF_DAYS" }),
    setDefaultBrowserTimeZone: (timeZone) =>
      dispatch({ data: timeZone, type: "STORE_DEFAULT_BROWSER_TIME_ZONE" }),
    setAnchorTimeZones: (data) =>
      dispatch({ data: data, type: "SET_ANCHOR_TIME_ZONES" }),
    setMimicEventsList: (data) =>
      dispatch({ data, type: "SET_MIMIC_EVENTS_LIST" }),
  };
}

const withStore = (BaseComponent) => (props) => {
  const promotionsStore = usePromotionsStore();
  const subscriptionStore = useSubscriptionStore();
  const groupVoteStore = useGroupVoteStore();
  const defaultPaymentMethodStore = useDefaultPaymentMethod();
  const teamPlan = useTeamPlan();
  const charges = useCharges();
  const stripePaymentMethods = useStripePaymentMethods();
  const stripeSubscriptions = useStripeSubscriptions();
  const stripeUpcomingInvoices = useStripeUpcomingInvoices();
  const hasBillingBeenFetched = useHasBillingBeenFetched();
  const stripeCustomerEmail = useStripeCustomerEmail();
  const allCalendars = useAllCalendars();
  const allLoggedInUsers = useAllLoggedInUsers();
  const allUserDomains = useAllUserDomains();
  const masterAccount = useMasterAccount();
  const menuBarDisplaySections = useMenuBarDisplaySections();
  const isMenuBarDarkMode = useIsMenuBarDarkMode();
  const enrichContacstStore = useEnrichedContactsStore();
  const hideRightHandSideBar = useHideRightHandSidebar();
  const hiddenEventsIDs = useHiddenEventsIDs();
  const storedContactsStore = useStoredContactsStore();
  const enrichedCompanyStore = useEnrichedCompanyStore();
  const permissionsStore = usePermissionsStore();
  const temporaryStateStore = useTemporaryStateStore();
  const availabilityStore = useAvailabilityStore();
  const userCodes = useUserCodes();
  const zoomSchedulers = useZoomSchedulers();
  const accountActivityStore = useAccountActivity();
  const metricsStore = useMetricsStore();
  const appSettings = useAppSettings();
  const tutorialWizard = useTutorialWizard();
  const appTimeZone = useAppTimeZones();
  const referralStore = useReferralStore();
  const featureFlags = useFeatureFlags();
  const outstandingSlotsStore = useOutstandingSlotsStore();
  const conferenceRoomStore = useConferenceRoomStore();
  const outlookCategoriesStore = useOutlookCategoriesStore();
  const OOOBusyEventsDictionaryStore = useOOOBusyEventsDictionaryStore();
  const {
    magicLinkToken,
    setConnectedAccountTokens,
    resetConnectedAccountTokens,
    resetMagicLinkToken,
  } = useMagicLink();
  const distroListDictionary = useDistroListDictionary();
  const annualUpgradeInvoicesStore = useAnnualUpgradeInvoicesStore();
  const backendSubscriptionStore = useBackendSubscriptionStore();
  const userTimeZoneIndexStore = useUserTimeZoneIndexStore();

  return (
    <BaseComponent
      {...props}
      promotionsStore={promotionsStore}
      subscriptionStore={subscriptionStore}
      groupVoteStore={groupVoteStore}
      defaultPaymentMethodStore={defaultPaymentMethodStore}
      teamPlan={teamPlan}
      charges={charges}
      stripePaymentMethods={stripePaymentMethods}
      stripeSubscriptions={stripeSubscriptions}
      stripeUpcomingInvoices={stripeUpcomingInvoices}
      hasBillingBeenFetched={hasBillingBeenFetched}
      stripeCustomerEmail={stripeCustomerEmail}
      allCalendars={allCalendars}
      allLoggedInUsers={allLoggedInUsers}
      allUserDomains={allUserDomains}
      masterAccount={masterAccount}
      menuBarDisplaySections={menuBarDisplaySections}
      isMenuBarDarkMode={isMenuBarDarkMode}
      enrichContacstStore={enrichContacstStore}
      hideRightHandSideBar={hideRightHandSideBar}
      hiddenEventsIDs={hiddenEventsIDs}
      storedContactsStore={storedContactsStore}
      enrichedCompanyStore={enrichedCompanyStore}
      permissionsStore={permissionsStore}
      temporaryStateStore={temporaryStateStore}
      availabilityStore={availabilityStore}
      userCodes={userCodes}
      zoomSchedulers={zoomSchedulers}
      appSettings={appSettings}
      accountActivityStore={accountActivityStore}
      metricsStore={metricsStore}
      tutorialWizard={tutorialWizard}
      appTimeZone={appTimeZone}
      referralStore={referralStore}
      featureFlags={featureFlags}
      outstandingSlotsStore={outstandingSlotsStore}
      conferenceRoomStore={conferenceRoomStore}
      outlookCategoriesStore={outlookCategoriesStore}
      OOOBusyEventsDictionaryStore={OOOBusyEventsDictionaryStore}
      magicLinkToken={magicLinkToken}
      distroListDictionary={distroListDictionary}
      annualUpgradeInvoicesStore={annualUpgradeInvoicesStore}
      backendSubscriptionStore={backendSubscriptionStore}
      userTimeZoneIndexStore={userTimeZoneIndexStore}
      setConnectedAccountTokens={setConnectedAccountTokens}
      resetConnectedAccountTokens={resetConnectedAccountTokens}
      resetMagicLinkToken={resetMagicLinkToken}
    />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withStore(BackendContainer)));
