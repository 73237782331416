import classNames from "classnames";
import React, { useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import settingsBroadcast from "../../../broadcasts/settingsBroadcast";
import { getMasterAccountEmail, getMasterAccountUser, getPrimaryUser, getUserEmail, getUserProfilePhotoUrl } from "../../../lib/userFunctions";
import { useIsMounted } from "../../../services/customHooks/useIsMounted";
import {
  getAllExecutives,
  isUserDelegatedUser,
  isUserExecutiveUser,
  isUserMaestroUser,
  isUserMagicLinkUser,
} from "../../../services/maestroFunctions";
import {
  useAllLoggedInUsers,
  useMasterAccount,
} from "../../../services/stores/SharedAccountData";
import { CustomSelect, type SelectOptionType } from "../../select";
import DropdownIndicator from "../../select/dropDownIndicator";
import { getReactSelectBaseStyle } from "../../select/styles";
import ProfilePictureThroughURL from "../../profilePictureThroughURL";
import { isSameEmail } from "../../../lib/stringFunctions";
import { getObjectEmail } from "../../../lib/objectFunctions";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey } from "../../../services/typeGuards";
import type { StylesConfig, ValueType } from "react-select";
import ExecutiveLabel from "../../../../components/executiveLabel";
import AccountLabel from "../accountLabel";

const ALL_USERS = "ALL_USERS";
const EXECUTIVES_ONLY = "EXECUTIVES_ONLY";
const EXECUTIVE_AND_MASTER_ACCOUNT = "EXECUTIVE_AND_MASTER_ACCOUNT";
const PRIMARY_AND_EXECUTIVES = "PRIMARY_AND_EXECUTIVES";
const ME_AND_MAGIC_LINK_USERS = "ME_AND_MAGIC_LINK_USERS";
export const SELECT_USER_TYPE = {
  ALL_USERS: ALL_USERS,
  EXECUTIVES_ONLY: EXECUTIVES_ONLY,
  EXECUTIVE_AND_MASTER_ACCOUNT: EXECUTIVE_AND_MASTER_ACCOUNT,
  PRIMARY_AND_EXECUTIVES: PRIMARY_AND_EXECUTIVES,
  ME_AND_MAGIC_LINK_USERS: ME_AND_MAGIC_LINK_USERS,
} as const;

type UserOptionType = SelectOptionType<User>

interface SelectUserProps {
  setSelectedUserIndex: StateSetter<number>
  id?: string
  selectedUser: User
  inputContainerClassName?: string
  selectUserType?: ValueOf<typeof SELECT_USER_TYPE>
  addExecutiveLabel?: boolean
  addMeLabel?: boolean
  eventUserEmail?: string
  overrideStyles?: StylesConfig<UserOptionType, false>
  useAccountProfileAsSelectedValue?: boolean
  onMenuClose?: () => void
  inputOptions?: UserOptionType[]
  showAccountLabel?: boolean
}

// TODO: refactor this so we don't have to rely on index, instead pass in actual user object
export function SelectUser({
  setSelectedUserIndex,
  id,
  selectedUser,
  inputContainerClassName,
  selectUserType,
  addExecutiveLabel,
  addMeLabel,
  eventUserEmail,
  overrideStyles,
  useAccountProfileAsSelectedValue,
  onMenuClose,
  inputOptions,
  showAccountLabel = false,
}: SelectUserProps) {
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const currentUser = useSelector((state) => state.currentUser);
  const allLoggedInUsers = useAllLoggedInUsers(
    (state) => state.allLoggedInUsers,
  );
  const masterAccount = useMasterAccount((state) => state.masterAccount);
  const userOptions = useRef(
    getAllUsers({
      currentUser,
      allLoggedInUsers,
      masterAccount,
      selectUserType,
      addExecutiveLabel,
      addMeLabel,
      eventUserEmail,
    }),
  );

  const getOptions = () => {
    return inputOptions ?? userOptions.current;
  };

  const onChangeUser = (e: ValueType<UserOptionType, false>) => {
    // setSelectedUser(e.value);
    if (!e) {
      return;
    }
    const options = getOptions();
    const userIndex = options.findIndex(
      (user) => isSameEmail(getUserEmail(user.value), getUserEmail(e.value)),
    );
    if (userIndex === -1) {
      return;
    }
    setSelectedUserIndex(userIndex);
  };

  const componentIsMounted = useIsMounted();

  const updateSelectedUsersList = (updatedUser: User) => {
    if (!getUserEmail(updatedUser) || !componentIsMounted.current) {
      return;
    }

    const updatedUsersList: UserOptionType[] = [];
    userOptions.current.forEach((option) => {
      if (isSameEmail(getUserEmail(option.value), getUserEmail(updatedUser))) {
        updatedUsersList.unshift({
          value: updatedUser,
          label: getLabel({user: updatedUser, masterAccount, addMeLabel, addExecutiveLabel}),
        });
      } else {
        updatedUsersList.push(option);
      }
    });

    userOptions.current = updatedUsersList;
  };

  useEffect(() => {
    settingsBroadcast.subscribe(
      "UPDATE_USER_LIST_IN_SETTINGS",
      updateSelectedUsersList,
    );

    return () => {
      settingsBroadcast.unsubscribe("UPDATE_USER_LIST_IN_SETTINGS");
    };
  }, []);

  const avatarUrl = useMemo(() => getUserProfilePhotoUrl({user: selectedUser, masterAccount}), [selectedUser, masterAccount]);

  const constructSelectValue = (): UserOptionType => {
    if (useAccountProfileAsSelectedValue) {
      return {
        label: (
          <ProfilePictureThroughURL
            avatarUrl={avatarUrl}
            email={getUserEmail(selectedUser)}
            className="smaller-profile-picture-inside-select font-size-12-important select-none"
          />
        ),
        value: selectedUser,
      };
    }
    if (isUserMaestroUser(masterAccount) && isUserExecutiveUser({user: selectedUser})) {
      return { label: getLabel({user: selectedUser, masterAccount, addMeLabel, addExecutiveLabel}), value: selectedUser };
    }
    return { label: getUserEmail(selectedUser), value: selectedUser };
  };

  return (
    <div
      id={id ?? ""}
      className={classNames(inputContainerClassName ?? "mb-2 flex justify-end", showAccountLabel ? "flex items-center" : "")}
    >
      {showAccountLabel ? <AccountLabel /> : null}
      <CustomSelect<UserOptionType, false>
        components={{ DropdownIndicator }}
        openMenuOnFocus={true}
        isMulti={false}
        overrideStyles={
          overrideStyles ??
          getReactSelectBaseStyle({
            isDarkMode,
            showBorder: true,
            controlWidth: "234px",
            menuListMaxHeight: 200,
          })
        }
        options={getOptions()}
        value={constructSelectValue()}
        onChange={onChangeUser}
        classNamePrefix="dark-mode"
        alwaysShowIndicator={true}
        isSearchable={false}
        onMenuClose={onMenuClose}
      />
    </div>
  );
}

interface GetAllUsersOptions {
  currentUser: User | undefined
  allLoggedInUsers: User[]
  masterAccount: MasterAccount | Record<string, never>
  selectUserType?: ValueOf<typeof SELECT_USER_TYPE>
  addExecutiveLabel?: boolean
  addMeLabel?: boolean
  eventUserEmail?: string
}

export function getAllUsers({
  currentUser,
  allLoggedInUsers,
  masterAccount,
  selectUserType = ALL_USERS,
  addExecutiveLabel = false,
  addMeLabel = false,
  eventUserEmail,
}: GetAllUsersOptions) {
  if (isEmptyArrayOrFalsey(allLoggedInUsers) || !currentUser || isEmptyObjectOrFalsey(masterAccount)) {
    return [];
  }

  // cases:
  // either show every user
  // or show only executives
  // or show only executives and the current user
  let users: User[] = [];
  switch (selectUserType) {
    case ALL_USERS:
      users = allLoggedInUsers;
      break;
    case EXECUTIVES_ONLY:
      users = getAllExecutives({ allLoggedInUsers });
      break;
    case EXECUTIVE_AND_MASTER_ACCOUNT: {
      // fake master account as a user
      const masterAccountUser = getMasterAccountUser({
        allLoggedInUsers,
        masterAccount,
        currentUser,
      });
      if (masterAccountUser) {
        users = getAllExecutives({ allLoggedInUsers }).concat(masterAccountUser);
      }
      break;
    }
    case ME_AND_MAGIC_LINK_USERS: {
      const masterAccountUser = getMasterAccountUser({
        allLoggedInUsers,
        masterAccount,
        currentUser,
      });
      if (!isUserMaestroUser(masterAccount)) {
        users = masterAccountUser ? [masterAccountUser] : [];
        break;
      }
      const magicLinkUsers = allLoggedInUsers.filter((user) => isUserMagicLinkUser({user}));
      if (isEmptyArrayOrFalsey(magicLinkUsers)) {
        // if no magic link user -> just return primary
        users = masterAccountUser ? [masterAccountUser] : [];
      } else {
        users = masterAccountUser ? [masterAccountUser, ...magicLinkUsers] : magicLinkUsers;
      }
      break;
    }
    case PRIMARY_AND_EXECUTIVES: {
      const matchingPrimaryUser = getPrimaryUser({
        allLoggedInUsers,
        masterAccount,
      });
      const matchingExec = getAllExecutives({ allLoggedInUsers }).filter(
        (exec) => isSameEmail(getObjectEmail(exec), eventUserEmail),
      );
      if (isEmptyObjectOrFalsey(matchingPrimaryUser)) {
        users = matchingExec;
      } else {
        users = matchingExec.concat(matchingPrimaryUser);
      }
      break;
    }
    default:
      users = allLoggedInUsers;
  }

  const accounts: UserOptionType[] = [];
  users.forEach((user) => {
    if (!getUserEmail(user)) {
      return;
    }

    if (isSameEmail(getUserEmail(user), getUserEmail(currentUser))) {
      accounts.unshift({ label: getLabel({user, masterAccount, addMeLabel, addExecutiveLabel}), value: user });
    } else {
      accounts.push({ label: getLabel({user, masterAccount, addMeLabel, addExecutiveLabel}), value: user });
    }
  });

  return accounts;
}

const getLabel = ({user, masterAccount, addMeLabel, addExecutiveLabel}): string | React.ReactNode => {
  const isMaestroAccount = isUserMaestroUser(masterAccount);
  if (!isMaestroAccount) {
    return getUserEmail(user);
  }

  if (addMeLabel && isSameEmail(getUserEmail(user), getMasterAccountEmail({ masterAccount }))) {
    return `${getUserEmail(user)} (Me)`;
  } else if (addExecutiveLabel && isUserDelegatedUser(user)) {
    return (
      <div className="flex items-center gap-2">
        <div className="truncate max-width-200px">{getUserEmail(user)}</div>
        <ExecutiveLabel />
      </div>
    );
  }

  return getUserEmail(user);
};
